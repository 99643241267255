import React, { useContext } from "react";
import styles from "./styles.module.css";
import NewsArrowsLeft from "../../../../../assets/hub/NewsArrowsLeft.svg";
import NewsArrowsRight from "../../../../../assets/hub/NewsArrowsRight.svg";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../components/spacing/component";
import { IExecutiveHubFooterItemData } from "../../../../../shared/interfaces/hub-footer-item-data.interface";
import RLink from "../../../../../components/link/RLink";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { renderWealthScoreIcon } from "../../../ExecutivesHubMain/ExecutivesHubMarkerPopup/HubMarkerPopupExecutive/HubMarkerPopupExecutive";
import useAppColors from "../../../../../hooks/app-colors.hook";

interface IProps {
  item: IExecutiveHubFooterItemData;
}

export default function NewsItem({ item }: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  return (
    <Flex className={styles.newsItem}>
      {item.executive.rating >= 3 && (
        <>
          <div
            className={styles.scoreCircle}
            style={{
              border: `2px solid ${colors.secondaryDarkColor}`,
            }}
          >
            {renderWealthScoreIcon(
              item.executive.rating,
              { color: colors.secondaryDarkColor },
              { useOnlyDarkIcon: true },
            )}
          </div>
          <Spacing pr="15" />
        </>
      )}
      <Flex column justifyContentSpaceBetween>
        <RLink
          path={{
            pathname: `/home/search/executive-dossier/${item.executive.id}`,
            state: { fromHub: true },
          }}
        >
          <RText fontSize={isSmallerScreen ? "11" : "13"} immutableWhite>
            {item.executive.name}
          </RText>
        </RLink>
        <Spacing pb="4" />
        <RLink
          path={{
            pathname: `/home/search/corporate-dossier/${item.company.id}`,
            state: { fromHub: true },
          }}
        >
          <RText fontSize={isSmallerScreen ? "11" : "13"} bold immutableWhite>
            {item.company.name}
          </RText>
        </RLink>

        <Spacing pb="4" />

        {item.company.newsTitle && (
          <div className={styles.newsTitleContainer}>
            <img src={NewsArrowsRight} alt="" className={styles.arrowIcon} />
            <RText immutableWhite fontSize={isSmallerScreen ? "11" : "13"}>
              {`${item.company.newsTitle.substring(0, 60)}...`}
            </RText>
            <img src={NewsArrowsLeft} alt="" className={styles.arrowIcon} />
          </div>
        )}
      </Flex>
    </Flex>
  );
}
