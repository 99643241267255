import { QueryEntity } from "@datorama/akita";
import {
  ExecutiveTagsState,
  executiveTagsStore,
  ExecutiveTagsStore,
} from "./executive-tags.store";

export class ExecutiveTagsQuery extends QueryEntity<ExecutiveTagsState> {
  constructor(protected store: ExecutiveTagsStore) {
    super(store);
  }
}

export const executiveTagsQuery = new ExecutiveTagsQuery(executiveTagsStore);
