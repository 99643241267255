import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { FolderIcon } from "../../../../../../../assets/company-dossier/relationship-indicator/icons";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../../../components/spacing/component";
import { formatNumberWithDotSeparator } from "../../../../../../../helpers/number.helper";
import { RELATIONSHIP_NAVIGATION_ITEM_TYPE } from "../../../../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/Navigation";
import InfoPopup from "../../../../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/InfoPopup";
import { IFirstLine } from "../../../../../../../entities/executive-dossier/models/relationship-indicator.model";
import RLink from "../../../../../../../components/link/RLink";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import CorporatesTags from "../../../../../CorporatesTags/CorporatesTags";

interface IProps {
  selected: RELATIONSHIP_NAVIGATION_ITEM_TYPE;
  firstLineData: IFirstLine[];
  refetch: () => void;
}

export default function FirstLineRelationship({ selected, firstLineData, refetch }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  if (!firstLineData?.length) {
    return null;
  }

  const renderLine = (data: IFirstLine) => (
    <Flex alignItemsCenter className={styles.firstLineContainer}>
      {data.company && (
        <>
          <Flex>
            <div className={styles.blackDot} />
          </Flex>

          <Spacing pr="6" />
          <Flex
            flex
            alignItemsCenter
            className={`
                            ${styles.lineContainer}
                            ${styles.lineContainerBackgroundGreen}
                        `}
          >
            <div className={`${styles.horizontalLine} ${styles.horizontalLineLarge}`} />
            <InfoPopup explanationData={data.explanation1} />
            <div className={`${styles.horizontalLine} ${styles.horizontalLineLarge}`} />
          </Flex>
          <Spacing pr="6" />

          <Flex className={styles.companyContainer}>
            <FolderIcon color={colors.backgroundBlackColor} className={styles.iconSize} />
            <Flex column alignItemsCenter className={styles.companyDescriptionContainer}>
              <CorporatesTags
                companyId={data.company.id.toString()}
                entityTags={data.company.tags}
                refetch={refetch}
              />
              <Spacing pt="10" />
              <RLink path={`/home/search/corporate-dossier/${data.company.id.toString()}`}>
                <RText
                  fontSize={isSmallerScreen ? "11" : "14"}
                  bold
                  ellipsis
                  textTooltip={data.company.name}
                >
                  {data.company.name}
                </RText>
                <Spacing pt="10" />
                <Flex justifyContentCenter>
                  <StarRating affinity={data.company.affinity} spacing="5" iconHeight="12" />
                </Flex>
              </RLink>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );

  return (
    <>
      {selected === RELATIONSHIP_NAVIGATION_ITEM_TYPE.FIRST_LINE && (
        <>
          <Spacing pt="15" />
          <Flex justifyContentEnd>
            <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
              {`${formatNumberWithDotSeparator(firstLineData.length)} ${t("relations")}`}
            </RText>
          </Flex>
          <Spacing pt={isSmallerScreen ? "20" : "40"} />
        </>
      )}
      {firstLineData.map((data, index) => (
        <Fragment key={`first-line-data-${index}`}>
          {renderLine(data)}
          {index < firstLineData.length - 1 && <Spacing pt="150" />}
        </Fragment>
      ))}
    </>
  );
}
