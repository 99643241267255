import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spacing } from "../../../../../../../components/spacing/component";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import { formatNumberWithDotSeparator } from "../../../../../../../helpers/number.helper";
import FirstLineRelationship from "./FirstLineRelationship";
import SecondLineRelationship from "./SecondLineRelationship";
import { RELATIONSHIP_NAVIGATION_ITEM_TYPE } from "../../../../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/Navigation";
import Header from "./Header";
import { IRelationshipIndicatorResponse } from "../../../../../../../entities/executive-dossier/models/relationship-indicator.model";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../../../../../hooks/session.hook";
import { IFilter } from "../../../../../../../components/filter/types";
import { ExecutiveDossierContext } from "../../../../../../../entities/executive-dossier/state/executive-dossier.context";
import axios from "axios";

interface IProps {
  executiveRelationshipIndicator: IRelationshipIndicatorResponse | null;
  executiveId: string;
}

export default function RelationshipIndicator({
  executiveRelationshipIndicator,
  executiveId,
}: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const executiveDossierContext = useContext(ExecutiveDossierContext);
  const { isB2CUser } = useSession();
  const cancelToken = useRef(axios.CancelToken.source());

  const [selected, setSelected] = useState(RELATIONSHIP_NAVIGATION_ITEM_TYPE.ALL);

  let firstLineData = isB2CUser ? [] : executiveRelationshipIndicator?.first_line;
  let secondLineData = executiveRelationshipIndicator?.second_line;

  const [filterValue, setFilterValue] = useState<IFilter>({
    influencerScore: [],
    promoterScore: [],
    productAffinity: [],
    wealthRating: [],
    tagIds: [],
  });

  if (!executiveRelationshipIndicator) {
    return null;
  }

  if (filterValue.promoterScore.length > 0) {
    firstLineData = [];
    secondLineData = secondLineData.filter((lineData) => {
      for (let i = 0; i < filterValue.promoterScore.length; i++) {
        if (
          filterValue.promoterScore[i] >= lineData.person2.promoter_score &&
          filterValue.promoterScore[i] - 20 <= lineData.person2.promoter_score
        ) {
          return true;
        }
      }

      return false;
    });
  }

  if (filterValue.productAffinity.length > 0) {
    firstLineData = firstLineData.filter((lineData) => {
      for (let i = 0; i < filterValue.productAffinity.length; i++) {
        if (filterValue.productAffinity[i] === lineData.company.affinity) {
          return true;
        }
      }

      return false;
    });
    secondLineData = secondLineData.filter((lineData) => {
      for (let i = 0; i < filterValue.productAffinity.length; i++) {
        if (filterValue.productAffinity[i] === lineData.company.affinity) {
          return true;
        }
      }

      return false;
    });
  }

  if (filterValue.wealthRating.length > 0) {
    firstLineData = [];
    secondLineData = secondLineData.filter((lineData) => {
      for (let i = 0; i < filterValue.wealthRating.length; i++) {
        if (filterValue.wealthRating[i] === lineData.person2.wealth_score) {
          return true;
        }
      }

      return false;
    });
  }

  if (filterValue.tagIds.length > 0) {
    firstLineData = firstLineData.filter((lineData) => {
      const lineDataTagIds: number[] = [];
      lineData.person1?.tags.forEach(({ tag: { id } }) => lineDataTagIds.push(id));
      lineData.company?.tags.forEach(({ tag: { id } }) => lineDataTagIds.push(id));
      for (let i = 0; i < filterValue.tagIds.length; i++) {
        if (lineDataTagIds.includes(filterValue.tagIds[i])) {
          return true;
        }
      }

      return false;
    });
    secondLineData = secondLineData.filter((lineData) => {
      const lineDataTagIds: number[] = [];
      lineData.person1?.tags.forEach(({ tag: { id } }) => lineDataTagIds.push(id));
      lineData.person2?.tags.forEach(({ tag: { id } }) => lineDataTagIds.push(id));
      lineData.company?.tags.forEach(({ tag: { id } }) => lineDataTagIds.push(id));
      for (let i = 0; i < filterValue.tagIds.length; i++) {
        if (lineDataTagIds.includes(filterValue.tagIds[i])) {
          return true;
        }
      }

      return false;
    });
  }

  const refetch = () => {
    executiveDossierContext.fetchExecutiveRelationshipIndicator(
      executiveId,
      cancelToken.current.token,
      false,
    );
  };

  const renderFirstLineContent = () => (
    <FirstLineRelationship
      selected={selected}
      firstLineData={firstLineData}
      refetch={refetch}
    />
  );

  const renderSecondLineContent = () => (
    <SecondLineRelationship
      selected={selected}
      secondLineData={secondLineData}
      refetch={refetch}
    />
  );

  const renderContent = () => {
    switch (selected) {
      case RELATIONSHIP_NAVIGATION_ITEM_TYPE.ALL:
        return (
          <>
            {!isB2CUser ? (
              <>
                {renderFirstLineContent()}
                <Spacing pb="150" />
              </>
            ) : null}
            {secondLineData?.length > 0 && (
              <>
                {/* <Spacing pt={isSmallerScreen ? "90" : "150"} /> */}
                {renderSecondLineContent()}
              </>
            )}
          </>
        );
      case RELATIONSHIP_NAVIGATION_ITEM_TYPE.FIRST_LINE:
        return renderFirstLineContent();
      case RELATIONSHIP_NAVIGATION_ITEM_TYPE.SECOND_LINE:
        return renderSecondLineContent();
      default:
        return null;
    }
  };

  return (
    <>
      <RText fontSize="20" bold>
        {t("relationshipIndicator")}
      </RText>
      <Spacing pt="28" />
      <Header
        selected={selected}
        setSelected={setSelected}
        firstLineExist={firstLineData?.length > 0}
        secondLineExist={secondLineData?.length > 0}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        rateKey={isB2CUser ? "wealthRating" : "productAffinity"}
      />
      {selected === RELATIONSHIP_NAVIGATION_ITEM_TYPE.ALL && (
        <>
          <Spacing pt="15" />
          <Flex justifyContentEnd>
            <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
              {`
                                ${formatNumberWithDotSeparator(
                                  firstLineData.length + secondLineData.length,
                                )} ${t("relations")}
                            `}
            </RText>
          </Flex>
          <Spacing pt={isSmallerScreen ? "20" : "40"} />
        </>
      )}
      {renderContent()}
      <Spacing pt={isSmallerScreen ? "150" : "200"} />
    </>
  );
}
