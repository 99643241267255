import { Query } from "@datorama/akita";
import {
  CorporateDossierState,
  CorporateDossierStore,
  corporateDossierStore,
} from "./corporate-dossier.store";

export class CorporateDossierQuery extends Query<CorporateDossierState> {
  constructor(protected store: CorporateDossierStore) {
    super(store);
  }
}

export const corporateDossierQuery = new CorporateDossierQuery(corporateDossierStore);
