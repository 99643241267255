import React from "react";
import CorporatesSearchResults from "./CorporatesSearchResults";
import CorporatesFiltersPanel from "./CorporatesFiltersPanel";
import SearchResultsFor from "../SearchResultsFor/SearchResultsFor";
import CorporatesSelections from "./CorporatesSelections";
import CorporatesSearchExport from "./CorporatesSearchExport";

const CorporatesPage = () => {
  return (
    <>
      <SearchResultsFor />

      <CorporatesFiltersPanel />

      <CorporatesSelections />

      <CorporatesSearchExport />

      <CorporatesSearchResults />
    </>
  );
};

export default CorporatesPage;
