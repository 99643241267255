import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { OpportunitiesFiltersContext } from "../state/opportunities-filters.context";

interface UseOpportunitiesSearchHitsHook {
  totalCount: number;
}

export function useOpportunitiesSearchHits(): UseOpportunitiesSearchHitsHook {
  const opportunitiesFiltersService = useContext(OpportunitiesFiltersContext);

  const [totalCount] = useObservable(
    opportunitiesFiltersService.searchResultTotalCount$,
    opportunitiesFiltersService.getResultCount(),
  );

  return { totalCount };
}
