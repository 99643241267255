import { Query } from "@datorama/akita";
import {
  ExecutivesHubState,
  executivesHubStore,
  ExecutivesHubStore,
} from "./executives-hub.store";

export class ExecutivesHubQuery extends Query<ExecutivesHubState> {
  constructor(protected store: ExecutivesHubStore) {
    super(store);
  }
}

export const executivesHubQuery = new ExecutivesHubQuery(executivesHubStore);
