export interface IPeopleManagement {
  company_id: number;
  from_date: string;
  to_date: string;
  function_rank: number;
  company_name: string;
  affinity_score: number;
  relation_type: string;
  position: string;
  from_quality: number;
  to_quality?: number;
  procura?: any;
  street: string;
  city: string;
  zip_code: number;
  country: string;
  industry_sector: string;
  employee_count: number;
  turnover: number;
  ee_score: number;
  function_interaction_rank: number;
  score: number;
}

export function createPeopleManagement(params: Partial<IPeopleManagement>) {
  return {
    company_id: null,
    from_date: null,
    to_date: null,
    function_rank: null,
    company_name: null,
    affinity_score: null,
    relation_type: null,
    position: null,
    from_quality: null,
    to_quality: null,
    procura: null,
    street: null,
    city: null,
    zip_code: null,
    country: null,
    industry_sector: null,
    employee_count: null,
    turnover: null,
    ee_score: null,
    function_interaction_rank: null,
    score: null,
    ...params,
  } as IPeopleManagement;
}
