import { useObservable } from "@mindspace-io/react";
import { useContext, useEffect } from "react";
import { HubContext } from "../../../modules/hub/state/hub.context";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";

interface IAnniversariesHook {
  hubAnniversariesFooterItems: IHubFooterItemData[];
  loadingAnniversaries: boolean;
}

export const useAnniversaries = (): IAnniversariesHook => {
  const hubService = useContext(HubContext);

  const [hubAnniversariesFooterItems] = useObservable(
    hubService.hubAnniversariesFooterItems$,
    null,
  );

  useEffect(() => {
    hubService.fetchAnniversaries(); //eslint-disable-next-line
  }, [])


  const [loadingAnniversaries] = useObservable(hubService.loadingAnniversaries$, true);

  return {
    hubAnniversariesFooterItems,
    loadingAnniversaries,
  };
};
