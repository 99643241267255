import React from "react";
import { scroller, animateScroll } from "react-scroll";
import styles from "./styles.module.css";
import { ReactComponent as ScrollToTopIcon } from "../../assets/ScrollToTopIcon.svg";
import { useCorporateSideNavigationActiveAnchor } from "../../hooks/corporates.hook";
import { useExecutiveSideNavigationActiveAnchor } from "../../entities/executive-dossier/hooks/executive-dossier.hook";
import {
  useIsCorporateDossier,
  useIsExecutiveDossier,
  useIsHub,
} from "../../utils/route.util";
import useAppColors from "../../hooks/app-colors.hook";

const ScrollToTop = () => {
  const { colors } = useAppColors();
  const isCorporateDossier = useIsCorporateDossier();
  const isExecutiveDossier = useIsExecutiveDossier();
  const isHub = useIsHub();

  const { corporateSideNavigationActiveAnchor } = useCorporateSideNavigationActiveAnchor();
  const { executiveSideNavigationActiveAnchor } = useExecutiveSideNavigationActiveAnchor();

  if (isHub) {
    return null;
  }

  const handleScrollToTop = () => {
    if (isCorporateDossier || isExecutiveDossier) {
      scroller.scrollTo(
        isCorporateDossier
          ? corporateSideNavigationActiveAnchor
          : executiveSideNavigationActiveAnchor,
        {
          containerId: "contentContainerElement",
          duration: 500,
          delay: 0,
          smooth: "easeInOutQuart",
        },
      );
    }

    animateScroll.scrollToTop();
  };

  return (
    <div className={styles.component} onClick={handleScrollToTop}>
      <ScrollToTopIcon color={colors.secondaryDarkColor} />
    </div>
  );
};

export default ScrollToTop;
