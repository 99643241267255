import React, { Fragment, useContext } from "react";
import styles from "./styles.module.css";
import Select from "./Select";
import { IActiveOption } from "./types";
import { useUserTags } from "../../entities/custom-tags/hooks/user-tags.hook";
import { Flex } from "../containers/Flex/Flex";
import Tag from "./Tag";
import { ReactComponent as AddIcon } from "../../assets/AddIcon.svg";
import { Spacing } from "../spacing/component";
import useAppColors from "../../hooks/app-colors.hook";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  values: number[];
  setValues: (values: number[]) => void;
}

const TagFilter = ({ values, setValues }: IProps) => {
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { userTags } = useUserTags();
  const options = userTags.map((tag) => ({
    label: tag.name,
    value: tag.id,
  }));

  const Title = () => (
    <div className={styles.dropdownTitle}>
      <Flex alignItemsCenter style={{ minWidth: 20 }}>
        {values.length === 0 ? (
          <Flex
            style={{
              border: "1px solid var(--color_background_black)",
            }}
            className={`${styles.defaultTag} ${
              isSmallerScreen && styles.defaultTag__Responsive
            }`}
            alignItemsCenter
            justifyContentCenter
          >
            <AddIcon color={colors.backgroundBlackColor} className={styles.addIcon} />
          </Flex>
        ) : (
          values.map((value: number, index: number) => {
            const tag = userTags.find((item) => item.id === value);
            return (
              <Fragment key={tag.id}>
                <div
                  className={`${styles.defaultTag} ${
                    isSmallerScreen && styles.defaultTag__Responsive
                  }`}
                  style={{
                    backgroundColor: tag?.color_code ?? colors.backgroundWhiteColor,
                  }}
                />
                {index < values.length - 1 && <Spacing pr="5" />}
              </Fragment>
            );
          })
        )}
      </Flex>
    </div>
  );

  const Option = ({ value, active, handleOption }: IActiveOption) => {
    const tag = userTags.find((item) => item.id === value);
    return (
      <Flex
        onClick={() => handleOption(value)}
        className={`${styles.dropdownOption} ${active && styles.dropdownOption__Active}`}
      >
        <Tag name={tag.name} color={tag.color_code} />
      </Flex>
    );
  };

  return (
    <Select
      values={values}
      handleValues={setValues}
      options={options}
      Title={Title}
      SelectOption={Option}
    />
  );
};

export default TagFilter;
