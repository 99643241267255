import React, { CSSProperties, ReactNode } from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import useAppColors from "../../../hooks/app-colors.hook";
import AnimatedProgressProvider from "../../../pages/hub/HubHeader/HubKPIs/AnimatedProgressProvider";
import { Flex } from "../../containers/Flex/Flex";
import { easeOutQuad } from "../count-up-animation/CountUpAnimation";
import styles from "./styles.module.css";

interface IProps {
  value?: number;
  showValuePercentage?: boolean;
  outerStyle?: CSSProperties;
  innerStyle?: CSSProperties;
  percentageContainerStyle?: CSSProperties;
  pathColor?: string;
  trailColor?: string;
  className?: string;
  children?: ReactNode;
}

export default function AnimatedCircleIcon({
  value = 100,
  showValuePercentage,
  outerStyle,
  innerStyle,
  percentageContainerStyle,
  pathColor,
  trailColor,
  className,
  children,
}: IProps) {
  const { colors } = useAppColors();

  return (
    <div className={`${styles.animatedCircleIconContainer} ${className}`} style={outerStyle}>
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={value}
        duration={0}
        easingFunction={easeOutQuad}
      >
        {(value: number) => {
          return (
            <CircularProgressbarWithChildren
              value={value}
              strokeWidth={4}
              styles={buildStyles({
                pathTransition: "none",
                strokeLinecap: "butt",
                rotation: 0.5,
                pathColor: pathColor ?? colors.primaryDarkColor,
                trailColor: trailColor ?? colors.primaryLightColor,
              })}
            >
              <Flex
                alignItemsCenter
                justifyContentCenter
                style={innerStyle}
                className={styles.circleContainer}
              >
                {showValuePercentage && (
                  <Flex
                    alignItemsCenter
                    justifyContentCenter
                    className={styles.percentageContainer}
                    style={percentageContainerStyle}
                  >
                    %
                  </Flex>
                )}
                {children}
              </Flex>
            </CircularProgressbarWithChildren>
          );
        }}
      </AnimatedProgressProvider>
    </div>
  );
}
