import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { IExecutive } from "./executive.model";

export interface ExecutivesState
  extends EntityState<IExecutive, string>,
    ActiveState<string> {}

@StoreConfig({
  name: "executives",
})
export class ExecutivesStore extends EntityStore<ExecutivesState> {}

export const executivesStore = new ExecutivesStore();
