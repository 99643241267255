import React, { Fragment, useContext } from "react";
import { ReactComponent as ChevronLeftBlueIcon } from "../../../../../../../assets/ChevronLeftBlue.svg";
import { ReactComponent as ChevronRightBlueIcon } from "../../../../../../../assets/ChevronRightBlue.svg";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import VerticalDivider from "../../../../../../../components/dividers/VerticalDivider/VerticalDivider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import styles from "./styles.module.css";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  paginationNumbersLength: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

export default function Pagination({
  paginationNumbersLength,
  currentPage,
  onPageChange,
}: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const paginationNumbers = [];
  for (let i = 1; i <= paginationNumbersLength; ++i) {
    paginationNumbers.push(i);
  }

  const onPrevClick = () => {
    if (currentPage <= 1) {
      return;
    }

    onPageChange(currentPage - 1);
  };

  const onNextClick = () => {
    if (currentPage >= paginationNumbers.length) {
      return;
    }

    onPageChange(currentPage + 1);
  };

  return (
    <Flex alignItemsCenter justifyContentCenter>
      <Flex alignItemsCenter pointer wrap>
        <ChevronLeftBlueIcon
          color={colors.secondaryDarkColor}
          className={`
                        ${styles.paginationIcon}
                        ${currentPage <= 1 ? styles.disabledArrowIcon : ""}
                    `}
          onClick={onPrevClick}
        />
        <Spacing pr="15" />
        {paginationNumbers.map((pageNumber, index) => {
          return (
            <Fragment key={pageNumber}>
              <div onClick={() => onPageChange(pageNumber)}>
                <RText
                  fontSize={isSmallerScreen ? "13" : "16"}
                  className={`
                                            ${styles.paginationNumbers}
                                            ${
                                              currentPage === pageNumber
                                                ? styles.activePage
                                                : ""
                                            }
                                        `}
                >
                  {pageNumber.toString()}
                </RText>
              </div>
              {index < paginationNumbers.length - 1 && (
                <>
                  <Spacing pr="5" />
                  <VerticalDivider primaryBlue className={styles.verticalDivider} />
                  <Spacing pr="5" />
                </>
              )}
            </Fragment>
          );
        })}
        <Spacing pr="15" />
        <ChevronRightBlueIcon
          color={colors.secondaryDarkColor}
          className={`
                        ${styles.paginationIcon}
                        ${
                          currentPage >= paginationNumbers.length
                            ? styles.disabledArrowIcon
                            : ""
                        }
                    `}
          onClick={onNextClick}
        />
      </Flex>
    </Flex>
  );
}
