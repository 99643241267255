import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IFoundationReachResponse } from "../../../../../../../entities/executive-dossier/models/foundation-reach.model";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import Navigation, {
  RELATIONSHIP_NAVIGATION_ITEM_TYPE,
} from "../../../../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/Navigation";
import FirstLine from "./FirstLine";
import SecondLine from "./SecondLine";
import Divider from "../../../../../../../components/dividers/Divider/Divider";

interface IProps {
  companies: IFoundationReachResponse;
}

export default function FoundationReach({ companies }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const [selected, setSelected] = useState(RELATIONSHIP_NAVIGATION_ITEM_TYPE.ALL);

  const firstLineData = useMemo(() => companies?.first_line, [companies]);
  const secondLineData = useMemo(() => companies?.second_line, [companies]);

  const renderContent = () => {
    switch (selected) {
      case RELATIONSHIP_NAVIGATION_ITEM_TYPE.ALL:
        return (
          <>
            {firstLineData && (
              <>
                <FirstLine firstLineData={firstLineData} />
                <Spacing pt="20" />
              </>
            )}
            <SecondLine secondLineData={secondLineData} />
          </>
        );
      case RELATIONSHIP_NAVIGATION_ITEM_TYPE.FIRST_LINE:
        return <FirstLine firstLineData={firstLineData} />;
      case RELATIONSHIP_NAVIGATION_ITEM_TYPE.SECOND_LINE:
        return <SecondLine secondLineData={secondLineData} />;
      default:
        return null;
    }
  };

  return (
    <>
      <RText fontSize="20" bold>
        {t("foundationReach")}
      </RText>
      <Spacing pt={isSmallerScreen ? "14" : "28"} />
      <Navigation
        selected={selected}
        setSelected={setSelected}
        firstLineExist={firstLineData?.length > 0}
        secondLineExist={secondLineData?.length > 0}
      />
      <Divider />
      <Spacing pt={isSmallerScreen ? "14" : "28"} pb={isSmallerScreen ? "20" : "50"}>
        {renderContent()}
      </Spacing>
    </>
  );
}

export const getFoundationReachCount = (data: IFoundationReachResponse) => {
  let count = 0;

  if (!data) {
    return count;
  }

  if (data.first_line) {
    count += data.first_line.length;
  }

  if (data.second_line) {
    count += data.second_line.length;
  }

  return count;
};
