import { useContext } from "react";
import { SessionContext } from "../state/akita/session/session.context";
import { useObservable } from "@mindspace-io/react";
import { IUser } from "../models/login/user.model";
import { THEME_MODE } from "../state/akita/session/session.model";
import { IClient } from "../models/login/client.model";
import { sessionFacade } from "../state/akita/session/session.facade";

interface ISessionHook {
  currentUser: IUser;
  fetchCurrentUser: () => void;
  isB2CUser: boolean;
  isDarkTheme: boolean;
}

export function useSession(): ISessionHook {
  const sessionContext = useContext(SessionContext);

  const [currentUser] = useObservable(
    sessionContext.currentUser$,
    sessionContext.getCurrentUser(),
  );
  const [currentTheme] = useObservable(sessionContext.theme$, sessionContext.getTheme());
  const fetchCurrentUser = sessionContext.fetchCurrentUser.bind(sessionContext);
  const isB2CUser = sessionContext.isB2CUser(currentUser);

  return {
    currentUser,
    fetchCurrentUser,
    isB2CUser,
    isDarkTheme: currentTheme === THEME_MODE.DARK,
  };
}

export function useCurrentClient(): IClient {
  const [currentClient] = useObservable(
    sessionFacade.client$,
    sessionFacade.getCurrentClient(),
  );
  return currentClient;
}
