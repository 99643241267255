import React, { useContext, useState } from "react";
import Downshift, { ControllerStateAndHelpers } from "downshift";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { Flex } from "../../../../components/containers/Flex/Flex";
import FilterAutosuggestElement from "./FilterAutosuggestElement";
import styles from "./FilterAutosuggestRange.module.css";
import { classNames } from "../../../../helpers/string.helper";
import { SelectedValueTypes } from "../../../../shared/interfaces/selected-value-types.interface";
import { SELECTED_VALUES_TYPE } from "../../../../shared/enums/selected-values-type.enum";

interface IProps<T> {
  filterId: string;
  items: Array<T>;
  inputChangeCallback?: (oldValue: string, newValue: string, items: Array<T>) => string;
  getDisplayValue: (item: T) => string;
  getSearchValue: (item: T) => string;
  getReferenceValue: (item: T) => string;
  getRangeValue: (item: T) => string;
  selectFilterValue: (filterId: string, filterValueId: SelectedValueTypes) => void;
  sortFunction: (a: T, b: T) => number;
  suggestionsFunction: (
    inputValue: string,
    items: Array<T>,
  ) => { suggestions: Array<T>; highlightedIdx: number };
}

const resetBothInputs = <T extends unknown>(
  firstActionElement: ControllerStateAndHelpers<T>,
  secondActionElement: ControllerStateAndHelpers<T>,
) => {
  if (firstActionElement)
    firstActionElement.reset({
      type: Downshift.stateChangeTypes.keyDownEscape,
      ...{ selectedItem: null, inputValue: "" },
    });
  if (secondActionElement)
    secondActionElement.reset({
      type: Downshift.stateChangeTypes.keyDownEscape,
      ...{ selectedItem: null, inputValue: "" },
    });
};

const FilterAutosuggestRange = <T extends unknown>({
  filterId,
  items,
  inputChangeCallback,
  getDisplayValue,
  getSearchValue,
  getReferenceValue,
  getRangeValue,
  selectFilterValue,
  sortFunction,
  suggestionsFunction,
}: IProps<T>) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const [firstSelection, setFirstSelection] = useState<T>(null);
  const firstActionRef = React.useRef<ControllerStateAndHelpers<T>>();
  const secondActionRef = React.useRef<ControllerStateAndHelpers<T>>();

  const secondInputRef = React.useRef<HTMLInputElement>();

  // sort items by numerical value
  const sortedItems = React.useMemo(() => {
    return Array.from(items).sort(sortFunction);
  }, [items, sortFunction]);

  // calculate items for the second input based on the first input - only
  // items with values greater than the first input value
  const secondItems = React.useMemo(() => {
    if (!firstSelection) return [];
    return sortedItems.filter((item) => sortFunction(item, firstSelection) >= 0);
  }, [firstSelection, sortFunction, sortedItems]);

  const selectCallback = (selectedItem: T) => {
    if (firstSelection === undefined || firstSelection === null) {
    } else if (selectedItem === undefined || selectedItem === null) {
      //it's a single item selection
      selectFilterValue(filterId, {
        type: SELECTED_VALUES_TYPE.REFERENCE,
        ref: getReferenceValue(firstSelection),
      });
    } else {
      if (getDisplayValue(selectedItem) === getDisplayValue(firstSelection)) {
        // a newly selected item has the same start and end value so it's a single value, not a range
        selectFilterValue(filterId, {
          type: SELECTED_VALUES_TYPE.REFERENCE,
          ref: getReferenceValue(selectedItem),
        });
      } else {
        // a newly selected item has different start and end values and constitutes a dynamic range
        selectFilterValue(filterId, {
          type: SELECTED_VALUES_TYPE.RANGE,
          from: getRangeValue(firstSelection),
          to: getRangeValue(selectedItem),
        });
      }
    }
    resetBothInputs(firstActionRef?.current, secondActionRef?.current);
  };

  const inputPlaceHolder = t("search");

  return (
    <Flex
      alignItemsCenter
      className={classNames(styles)}
      style={{ fontSize: isSmallerScreen ? 13 : 16 }}
    >
      <FilterAutosuggestElement
        items={sortedItems}
        inputChangeCallback={inputChangeCallback}
        getDisplayValue={getDisplayValue}
        getSearchValue={getSearchValue}
        onChange={(selectedItem) => {
          setFirstSelection(selectedItem);

          if (secondActionRef.current) {
            // when an item is deselected in first input reset the second input since it depends on the first
            if (!selectedItem) {
              secondActionRef.current.reset({
                type: Downshift.stateChangeTypes.keyDownEscape,
                ...{ selectedItem: null, inputValue: "" },
              });

              // when an item is selected in the first input, switch to the second input field
            } else if (secondInputRef.current) {
              const el = secondInputRef.current;
              // 0-delay needed to skip the current rendering cycle
              // and render the element before trying to focus on it
              setTimeout(() => {
                el.focus();
              }, 0);
            }
          }
        }}
        actionRef={firstActionRef}
        inputPlaceHolder={inputPlaceHolder}
        suggestionsFunction={suggestionsFunction}
      />
      <span style={{ opacity: secondItems.length > 0 ? 1 : 0 }}>
        &nbsp;{t("to").toLowerCase()}&nbsp;
      </span>
      <FilterAutosuggestElement
        disabled={secondItems.length < 1}
        items={secondItems}
        inputChangeCallback={inputChangeCallback}
        getDisplayValue={getDisplayValue}
        getSearchValue={getSearchValue}
        onChange={(selectedItem) => {
          if (selectedItem !== null && selectedItem !== undefined) {
            selectCallback(selectedItem);
          }
        }}
        actionRef={secondActionRef}
        inputRef={secondInputRef}
        onKeyPress={(ev) => {
          if (ev.key === "Enter" && !secondInputRef?.current?.value) {
            selectCallback(null);
          }
        }}
        inputPlaceHolder={inputPlaceHolder}
        suggestionsFunction={suggestionsFunction}
      />
    </Flex>
  );
};

export default FilterAutosuggestRange;
