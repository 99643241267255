import { createContext } from "react";
import { IExecutiveNotesContext, ExecutiveNotesFacade } from "./executive-notes.facade";
import { executiveNotesStore } from "./executive-notes.store";
import { executiveNotesQuery } from "./executive-notes.query";
import { executivesFacade } from "../../executives/state/executive.context";

const executiveNotesFacade = new ExecutiveNotesFacade(
  executiveNotesStore,
  executiveNotesQuery,
  executivesFacade,
);

export const ExecutiveNotesContext =
  createContext<IExecutiveNotesContext>(executiveNotesFacade);
