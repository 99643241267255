import { ICorporateTag } from "../state/corporate-tags.model";
import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { CorporateTagsContext } from "../state/corporate-tags.context";

interface ICorporateTagsHook {
  all: ICorporateTag[];
}

export function useCorporateTags(): ICorporateTagsHook {
  const corporateTagsContext = useContext(CorporateTagsContext);

  const [all] = useObservable(corporateTagsContext.all$, null);

  return { all };
}
