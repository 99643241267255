import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ExecutiveHubContext } from "../state/executives-hub.context";

interface IExecutivesHubHook {
  hubErrorNoData: boolean;
  fetchHubExecutives: () => Promise<void>;
}

interface IHubFirstLoadHook {
  firstLoad: boolean;
}

export const useExecutivesHub = (): IExecutivesHubHook => {
  const executivesHubService = useContext(ExecutiveHubContext);

  const [hubErrorNoData] = useObservable(executivesHubService.hubErrorNoData$, false);

  return {
    hubErrorNoData,
    fetchHubExecutives: () => executivesHubService.fetchHubExecutives(),
  };
};

export const useExecutivesHubFirstLoad = (): IHubFirstLoadHook => {
  const executivesHubService = useContext(ExecutiveHubContext);

  const [firstLoad] = useObservable(executivesHubService.firstLoad$, false);

  return {
    firstLoad,
  };
};
