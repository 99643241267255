import axios, { AxiosInstance } from "axios";
import { API_URL_V1 } from "../constants/urls";

export default class HttpClient {
  private static instance: AxiosInstance;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  public static getInstance(): AxiosInstance {
    if (!HttpClient.instance) {
      HttpClient.instance = axios.create({
        baseURL: API_URL_V1,
        timeout: 120000,
      });
    }

    return HttpClient.instance;
  }
}
