import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useExecutiveHubMarkers } from "../../../modules/executives-hub/hooks/executives-hub-markers.hook";
import Spinner from "../../../components/other/Spinner/Spinner";
import RText from "../../../components/fonts/RText/RText";
import styles from "./styles.module.css";
import { Flex } from "../../../components/containers/Flex/Flex";
import ExecutivesHubMap from "./ExecutivesHubMap/ExecutivesHubMap";
import { calculateMapCenterAsAverageFromMultipleLocations } from "../../../helpers/map.helper";
import {
  Paper,
  ClickAwayListener,
  Autocomplete,
  TextField,
  Stack,
  Typography,
  Slider,
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Collapse,
  Grid,
  Hidden,
  IconButton,
  List,
  SwipeableDrawer,
} from "@mui/material";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { MapContext } from "../../../context/MapContext";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Close,
  FilterList,
  GpsNotFixed,
} from "@mui/icons-material";
import ExecutivesHubHeader from "../ExecutivesHubHeader/ExecutivesHubHeader";
import useAppColors from "../../../hooks/app-colors.hook";

import HubFiltersGroup from "../../hub/HubHeader/HubFiltersGroup/HubFiltersGroup";
import HubMultiLevelFilterValuesContainer from "../../hub/HubHeader/HubFiltersGroup/HubMultiLevelFilterValuesContainer/HubMultiLevelFilterValuesContainer";

import ExecutivesHubSidePanel from "./ExecutivesHubSidePanel/ExecutivesHubSidePanel";
import { useExecutivesFilterWithHubUIGroups } from "../../../entities/executives-filters/hooks/executives-filter-with-ui-groups.hook";

const provider = new OpenStreetMapProvider();

const SwipeLine = () => (
  <Box
    sx={{
      width: 50,
      height: 4,
      background: "var(--color_gray)",
      margin: "0 auto",
      borderRadius: 3,
    }}
  />
);

const ExecutivesHubMain = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const { state, setRadius, setCenter } = useContext(MapContext);
  const swissURL = window.location.pathname.includes("/ch"); // to be changed when refactoring
  const [hubMarkersCenter, setHubMarkersCenter] = useState(null);
  const { loadingHubExecutives, executiveHubMarkers } = useExecutiveHubMarkers();
  const { colors } = useAppColors();
  const { filterWithUIGroups } = useExecutivesFilterWithHubUIGroups();

  const [showStats, setShowStats] = useState<boolean>(upMd);

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const openFiltersDrawer = () => setShowFiltersDrawer(true);
  const closeFiltersDrawer = () => setShowFiltersDrawer(false);

  const [showResultsDrawer, setShowResultsDrawer] = useState(false);
  const openResultsDrawer = () => setShowResultsDrawer(true);
  const closeResultsDrawer = () => setShowResultsDrawer(false);

  const [showCircleRadius, setShowCircleRadius] = useState(upMd);

  useEffect(() => {
    let isMounted = true;

    if (!executiveHubMarkers || !isMounted) {
      return;
    }

    const _hubMarkersCenter = calculateMapCenterAsAverageFromMultipleLocations(
      executiveHubMarkers.map(({ coordinates }) => coordinates),
    );

    setHubMarkersCenter(_hubMarkersCenter);

    return () => {
      isMounted = false;
    };
  }, [executiveHubMarkers]);

  const hubExecutivesExist = executiveHubMarkers && hubMarkersCenter;

  const [placeInput, setPlaceInput] = useState<string | null>(null);
  const autocompleteRef = useRef(null);

  const getCoordinates = async (query: string) => {
    const res = await provider.search({ query });

    const lat = res[0].y;
    const lng = res[0].x;

    return { lng, lat };
  };

  const flyTo = async () => {
    const coordinates = await getCoordinates(
      `${placeInput} ${swissURL ? "Switzerland" : "Germany"}`,
    );

    setCenter(coordinates);
    setRadius(5);
  };

  useEffect(() => {
    placeInput && flyTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeInput]);

  const onSearchInputChange = async (params: any, value: any) => {
    if (!value) {
      setRadius(null);
      setCenter(null);

      return;
    }

    setPlaceInput(value.id ?? value.label ?? value);
  };

  const onCircleRadiusChange = (event: Event, value: any, activeThumb: number): void => {
    setRadius(value);
  };

  const renderRadiusDrawer = () => (
    <Paper
      elevation={3}
      sx={{
        background: "var(--color_gray)",
        position: "absolute",
        top: upMd ? (showStats ? 150 : 5) : 70,
        left: 20,
        zIndex: 401,
        minWidth: 250,
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          const { value } = autocompleteRef.current;

          if (value.length) setPlaceInput(value);
        }}
      >
        <Autocomplete
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={autocompleteRef}
              sx={{
                background: "var(--color_gray_light)",
                borderRadius: "999px",
                ".MuiOutlinedInput-root": {
                  borderRadius: "999px",
                  py: 0,
                },
                input: {
                  padding: 0,
                  fontSize: "13px",
                },
              }}
              placeholder={t("enterTheCityOrZipCode")}
            />
          )}
          onChange={onSearchInputChange}
          freeSolo
        />
      </ClickAwayListener>
      <Stack spacing={3} direction="row" alignItems="center" px={2}>
        <Typography sx={{ fontSize: 13 }}>Radius</Typography>
        <Slider
          disabled={!state.center}
          min={1}
          max={100}
          // step={10}
          value={state.radius}
          valueLabelDisplay="auto"
          onChange={onCircleRadiusChange}
          marks={[
            { value: 1 },
            { value: 2 },
            { value: 3 },
            { value: 4 },
            { value: 5 },
            { value: 10 },
            { value: 15 },
            { value: 20 },
            { value: 25 },
            { value: 30 },
            { value: 40 },
            { value: 50 },
            { value: 60 },
            { value: 70 },
            { value: 90 },
            { value: 100 },
          ]}
        />
      </Stack>
    </Paper>
  );

  const renderHubMap = () => {
    if (loadingHubExecutives || loadingHubExecutives === null) return <Spinner />;

    if (!hubExecutivesExist) {
      return (
        <RText className={styles.noDataText} fontSize="30">
          {t("noHubExecutives")}
        </RText>
      );
    }

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <div className={styles.mapShadow} />
        <ExecutivesHubMap
          width="100%"
          height="100%"
          zoom={9}
          hubMarkers={executiveHubMarkers}
          center={hubMarkersCenter}
          showStats={showStats}
        />
        {showCircleRadius && renderRadiusDrawer()}
      </div>
    );
  };

  return (
    <div className={styles.component}>
      <Flex alignItemsCenter justifyContentCenter className={styles.mapContainer}>
        <Box>
          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              sx={{
                position: "absolute",
                top: 15,
                left: 15,
                zIndex: 99999999,
                background: "white",
              }}
              onClick={openFiltersDrawer}
              disableRipple
            >
              <FilterList style={{ color: "black" }} />
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                top: 15,
                left: 75,
                zIndex: 99999999,
                background: "white",
              }}
              onClick={() => setShowCircleRadius((prevState) => !prevState)}
              disableRipple
            >
              <GpsNotFixed style={{ color: "black" }} />
            </IconButton>
            <Button
              sx={{
                position: "absolute",
                bottom: 15,
                left: "50%",
                zIndex: 99999999,
                background: "white",
                transform: "translate(-50%)",
                borderRadius: 5000,
                padding: "15px 25px",
                ":hover": {
                  background: "white",
                },
                ":focus": {
                  background: "white",
                },
              }}
              startIcon={<List />}
              onClick={openResultsDrawer}
            >
              Show List ({executiveHubMarkers && executiveHubMarkers.length})
            </Button>
            <SwipeableDrawer
              anchor="top"
              open={showFiltersDrawer}
              onClose={closeFiltersDrawer}
              onOpen={openFiltersDrawer}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                px={2}
                pt={2}
                m={0}
              >
                <Grid item>
                  <Grid container alignItems="center" gap={1}>
                    <Grid item>
                      <FilterList />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" fontWeight="bold">
                        {t("filter")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeFiltersDrawer}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Box
                sx={{
                  padding: "20px 20px 20px 0",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {loadingHubExecutives ? (
                  <div />
                ) : (
                  filterWithUIGroups &&
                  filterWithUIGroups.map((filterGroup: any) => (
                    <Fragment key={filterGroup.title}>
                      <HubFiltersGroup filtersGroup={filterGroup} />
                    </Fragment>
                  ))
                )}
              </Box>
              <Box>
                {filterWithUIGroups &&
                  filterWithUIGroups.map((filterGroup) => {
                    return (
                      <HubMultiLevelFilterValuesContainer
                        key={filterGroup.title}
                        filtersGroup={filterGroup}
                      />
                    );
                  })}
              </Box>
              <Box sx={{ pt: 3, pb: 1 }}>
                <SwipeLine />
              </Box>
              {/* <Flex alignItemsCenter justifyContentSpaceBetween className={styles.filterResultsContainer}>
                                <RText bold fontSize="13">
                                    {companies?.length && `${formatNumberWithDotSeparator(companies.length)} ${t(
                                        "companies"
                                    )}`}
                                </RText>
                                <ExecutivesHubSidePanel sortBy={sortBy} onSortByName={onSortByName} />
                            </Flex> */}
            </SwipeableDrawer>
            <SwipeableDrawer
              anchor="bottom"
              open={showResultsDrawer}
              onClose={closeResultsDrawer}
              onOpen={openResultsDrawer}
            >
              <Box sx={{ pt: 1, pb: 3 }} m={0}>
                <SwipeLine />
              </Box>
              <ExecutivesHubSidePanel executiveHubMarkers={executiveHubMarkers} hideFilter />
            </SwipeableDrawer>
          </Hidden>
        </Box>
        <Box
          position="absolute"
          top={0}
          minWidth="100%"
          zIndex={1001}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Collapse in={showStats}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 0,
                borderBottom: "1px solid #D6D6D6",
                backgroundColor: "var(--color_background_white)",
              }}
            >
              <ExecutivesHubHeader />
            </Paper>
          </Collapse>
          <Button
            onClick={() => setShowStats((prevState) => !prevState)}
            sx={{
              position: "absolute",
              left: "50%",
              borderRadius: "0 0 6px 6px",
              backgroundColor: colors.backgroundWhiteColor,
              color: colors.backgroundBlackColor,

              "&:hover": {
                backgroundColor: colors.backgroundWhiteColor,
              },
            }}
          >
            {showStats ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
        </Box>
        {renderHubMap()}
      </Flex>
      {hubExecutivesExist && (
        <div className={`${styles.mapShadow} ${styles.mapShadowBottom}`} />
      )}
    </div>
  );
};

export default ExecutivesHubMain;
