import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spacing } from "../../../../../../../components/spacing/component";
import RText from "../../../../../../../components/fonts/RText/RText";
// import Divider from "../../../../../../../components/dividers/Divider/Divider";
import { IShareholderCompanies } from "../../../../../../../entities/corporates/models/shareholders-companies.model";
import { IShareholderPeople } from "../../../../../../../entities/corporates/models/shareholders-people.model";
import Navigation, { INVESTMENT_NAVIGATION_ITEM_TYPE } from "./Navigation";
import Controlling from "./Controlling";
import Minorities from "./Minorities";
import SecondLine from "./SecondLine";
import Divider from "../../../../../../../components/dividers/Divider/Divider";

interface IProps {
  shareholdersCompanies: IShareholderCompanies[];
  componentTitle?: string;
  shareholdersPeople?: IShareholderPeople[];
}

export default function Investment({
  shareholdersCompanies,
  componentTitle,
  shareholdersPeople,
}: IProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(INVESTMENT_NAVIGATION_ITEM_TYPE.ALL);

  const { controlling: controllingCompanies, minorities: minoritiesCompanies } =
    getControllingAndMinoritiesData(shareholdersCompanies) as IControllingAndMinorities<
      IShareholderCompanies[]
    >;

  const { controlling: controllingPeople, minorities: minoritiesPeople } =
    getControllingAndMinoritiesData(shareholdersPeople) as IControllingAndMinorities<
      IShareholderPeople[]
    >;

  const renderInvestmentContent = () => {
    switch (selected) {
      case INVESTMENT_NAVIGATION_ITEM_TYPE.ALL:
        return (
          <>
            <Controlling companies={controllingCompanies} people={controllingPeople} />
            <Minorities companies={minoritiesCompanies} people={minoritiesPeople} />
            {/* <Spacing pv="25">
                            <Divider />
                        </Spacing>
                        <SecondLine /> */}
          </>
        );
      case INVESTMENT_NAVIGATION_ITEM_TYPE.CONTROLLING:
        return <Controlling companies={controllingCompanies} people={controllingPeople} />;
      case INVESTMENT_NAVIGATION_ITEM_TYPE.MINORITIES:
        return <Minorities companies={minoritiesCompanies} people={minoritiesPeople} />;
      case INVESTMENT_NAVIGATION_ITEM_TYPE.SECOND_LINE:
        return <SecondLine />;
      default:
        return null;
    }
  };

  return (
    <>
      <RText fontSize="20" bold>
        {componentTitle ?? t("investment")}
      </RText>
      <Spacing pt="28" />
      <Navigation
        selected={selected}
        setSelected={setSelected}
        exist={{
          controlling: controllingCompanies.length + controllingPeople.length > 0,
          minorities: minoritiesCompanies.length + minoritiesPeople.length > 0,
          secondLine: false,
        }}
      />
      <Divider />
      <Spacing pv="50">{renderInvestmentContent()}</Spacing>
    </>
  );
}

type ShareholderEntity = IShareholderCompanies | IShareholderPeople;

interface IControllingAndMinorities<T> {
  controlling: T;
  minorities: T;
}

export function getControllingAndMinoritiesData(
  entities: ShareholderEntity[],
): IControllingAndMinorities<ShareholderEntity[]> {
  let controlling: ShareholderEntity[] = [];
  let minorities: ShareholderEntity[] = [];

  entities?.forEach((entity: ShareholderEntity) => {
    if (entity.share_perc >= 50) {
      controlling.push(entity);
    } else {
      minorities.push(entity);
    }
  });

  return {
    controlling,
    minorities,
  };
}

export const getInvestmentCount = (
  shareholdersCompanies: IShareholderCompanies[] | null,
  shareholdersPeople?: IShareholderPeople[] | null,
) => {
  let totalCount = 0;

  if (shareholdersCompanies?.length > 0) {
    totalCount += shareholdersCompanies.length;
  }

  if (shareholdersPeople?.length > 0) {
    totalCount += shareholdersPeople.length;
  }

  return totalCount;
};
