import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ICorporate } from "../models/corporate.model";

export interface ICorporatesState
  extends EntityState<ICorporate, string>,
    ActiveState<string> {}

@StoreConfig({
  name: "corporates",
})
export class CorporatesStore extends EntityStore<ICorporatesState> {}

export const corporatesStore = new CorporatesStore();
