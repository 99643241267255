import React, { useState, useEffect } from "react";

// An ease-out function that slows the count as it progresses
export const easeOutQuad = (t: any) => t * (2 - t);
// Calculate how long each "frame" should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;

interface IProps {
  countTo: number;
  duration: number;
}

const CountUpAnimation = ({ countTo, duration }: IProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let frame = 0;
    let progress = 0;

    // Use that to calculate how many frames we need to complete the animation
    const totalFrames = Math.round(duration / frameDuration);
    const counter = setInterval(() => {
      frame++;

      if (totalFrames > 0) {
        progress = easeOutQuad(frame / totalFrames);
      }

      setCount(countTo * progress);

      // If we’ve reached our last frame, stop the animation
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);

    return () => clearInterval(counter);
  }, []); // eslint-disable-line

  return <>{count < 10 ? count.toFixed(1) + "%" : Math.round(count) + "%"}</>;
};

export default React.memo(CountUpAnimation);
