import { IFilterByProductTrendsResponse } from "../../entities/corporates/responses/filter-by-product-trends.response";
import { IBatchNewsCountResponse } from "../interfaces/batch-news-count-response.interface";
import { INewsCountResponse } from "../interfaces/news-count-response.interface";
import { IProductTrendsRequest } from "../interfaces/product-trends-request.interface";
import { ITopNewsResponse } from "../interfaces/top-news-response.interface";
import { ITopProductTrendsResponse } from "../interfaces/top-product-trends-response.interface";
import ApiService from "./api.service";

export interface IHubApiService {
  fetchTopProductTrends(req: IProductTrendsRequest): Promise<ITopProductTrendsResponse>;
  filterByProductTrends(req: IProductTrendsRequest): Promise<IFilterByProductTrendsResponse>;
  fetchCumulativeNewsCount(req: IProductTrendsRequest): Promise<INewsCountResponse>;
  fetchNewsCountForCompany(companyId: string): Promise<INewsCountResponse>;
  fetchFooterNewsCount(req: IProductTrendsRequest): Promise<IBatchNewsCountResponse>;
  fetchTrendsCountForCompany(companyId: string): Promise<any>;
  fetchTopNews(req: IProductTrendsRequest): Promise<ITopNewsResponse>;
}

class HubApiService extends ApiService {
  fetchSalesRegionsCorporates(): Promise<any> {
    return this.get(`/sales-region`);
  }

  fetchTrends(req: IProductTrendsRequest): Promise<any> {
    return this.post("/companies/trends", req);
  }

  async fetchTopProductTrends(req: IProductTrendsRequest): Promise<ITopProductTrendsResponse> {
    const {
      data: { internal_company_ids },
    } = await this.fetchTrends(req);
    const request = {
      ...req,
      companies: internal_company_ids,
    };

    return this.post("/companies/signals/batch", request);
  }

  filterByProductTrends(req: IProductTrendsRequest): Promise<IFilterByProductTrendsResponse> {
    return this.post("/companies/signals/filter", req);
  }

  fetchCumulativeNewsCount(req: IProductTrendsRequest): Promise<INewsCountResponse> {
    return this.post("/companies/news/batch/cumulative/count", req);
  }

  fetchNewsCountForCompany(companyId: string): Promise<INewsCountResponse> {
    return this.get(`/companies/${companyId}/news/count`);
  }

  fetchFooterNewsCount(req: IProductTrendsRequest): Promise<IBatchNewsCountResponse> {
    return this.post("/companies/news/batch/count", req);
  }

  fetchTrendsCountForCompany(companyId: string): Promise<INewsCountResponse> {
    return this.get(`/companies/${companyId}/signals/count`);
  }

  fetchTopNews(req: IProductTrendsRequest): Promise<ITopNewsResponse> {
    return this.post("/companies/news/batch", req);
  }
}

export const hubApiService = new HubApiService();
