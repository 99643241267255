import React, { useContext } from "react";
import { IExecutiveMarkerData } from "../../../../../entities/executives/interfaces/executive-marker-data.interface";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import styles from "./styles.module.css";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../components/spacing/component";
import RLink from "../../../../../components/link/RLink";
import RText from "../../../../../components/fonts/RText/RText";
import { getExecutiveActiveCompany } from "../../../../../helpers/dossier.helper";
import {
  getWealthScoreName,
  renderWealthScoreIcon,
} from "../../ExecutivesHubMarkerPopup/HubMarkerPopupExecutive/HubMarkerPopupExecutive";
import { ExecutiveHubContext } from "../../../../../modules/executives-hub/state/executives-hub.context";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { useTranslation } from "react-i18next";
import ExecutivesTags from "../../../../home/ExecutivesTags/ExecutivesTags";

interface IProps {
  executive: IExecutiveMarkerData;
}

const ExecutiveHubSidePanelItem = ({ executive }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return isSmallerScreen ? (
    <SmallItemLayout executive={executive} />
  ) : (
    <DefaultItemLayout executive={executive} />
  );
};

const DefaultItemLayout = ({ executive }: IProps) => {
  const { setHubRolloverExecutive } = useContext(ExecutiveHubContext);
  const { colors } = useAppColors();
  const { t } = useTranslation();

  return (
    <div
      className={styles.executiveContainer}
      onMouseEnter={() => setHubRolloverExecutive(executive.executiveId)}
    >
      <Flex justifyContentSpaceBetween>
        <Flex column pointer className={styles.infoContainer}>
          <StarRating
            affinity={executive.wealth_score}
            iconHeight="18"
            spacing="2"
            emptyIconSize="10"
          />
          <Spacing pt="10" />
          <RLink
            path={{
              pathname: `/home/search/executive-dossier/${executive.executiveId}`,
              state: { fromHub: true },
            }}
          >
            <RText bold fontSize="15">
              {executive.name}
            </RText>
          </RLink>
          <Spacing pt="5" />
          <RText bold fontSize="15">
            {
              getExecutiveActiveCompany(
                executive.management,
                executive.main_company_id,
                executive.shareholder,
              )?.company_name
            }
          </RText>
          <Spacing pt="10" />
          <Flex flex>
            {executive.zipCode && (
              <>
                <RText fontSize="14">{executive.zipCode.toString()}</RText>
                <Spacing pl="10" />
              </>
            )}
            <RText fontSize="14">{executive.city}</RText>
          </Flex>
          <Spacing pt="10" />
          <ExecutivesTags executiveId={executive.executiveId} />
        </Flex>

        <Flex column className={styles.insightsContainer}>
          {executive.wealth_score >= 3 && (
            <>
              <Flex justifyContentSpaceBetween>
                <RText fontSize="12">{getWealthScoreName(executive.wealth_score)}</RText>
                <Spacing pr="10" />
                {renderWealthScoreIcon(executive.wealth_score, {
                  color: colors.backgroundBlackColor,
                  width: "15px",
                  height: "15px",
                })}
              </Flex>
              <Spacing pb="8" />
            </>
          )}
          <Flex justifyContentSpaceBetween>
            <RText fontSize="12">{t("promoterScore")}</RText>
            <Spacing pr="10" />
            <RText fontSize="12">{executive.promoterScore + "%"}</RText>
          </Flex>
          {executive.wealthReach > 0 && (
            <>
              <Spacing pt="8" />
              <Flex justifyContentSpaceBetween>
                <RText fontSize="12">{t("foundationReach")}</RText>
                <Spacing pr="10" />
                <RText fontSize="12">{executive.wealthReach.toString()}</RText>
              </Flex>
            </>
          )}
          {![null, undefined].includes(executive.eeScore) && (
            <>
              <Spacing pt="8" />
              <Flex justifyContentSpaceBetween>
                <RText fontSize="12">{t("eAndE")}</RText>
                <Spacing pr="10" />
                <RText fontSize="12">{executive.eeScore.toString()}</RText>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

const SmallItemLayout = ({ executive }: IProps) => {
  const { setHubRolloverExecutive } = useContext(ExecutiveHubContext);

  return (
    <div
      className={styles.executiveContainer}
      onMouseEnter={() => setHubRolloverExecutive(executive.executiveId)}
    >
      <Flex column justifyContentSpaceBetween>
        <Flex alignItemsCenter>
          <StarRating
            affinity={executive.wealth_score}
            iconHeight="14"
            spacing="6"
            emptyIconSize="10"
          />
          <Spacing pl="10" />
          {executive.wealth_score >= 3 && (
            <Flex alignItemsCenter className={styles.wealthScoreContainer__Responsive}>
              <RText fontSize="11" bold>
                {getWealthScoreName(executive.wealth_score)}
              </RText>
            </Flex>
          )}
        </Flex>
        <Spacing pt="5" />
        <RLink
          path={{
            pathname: `/home/search/executive-dossier/${executive.executiveId}`,
            state: { fromHub: true },
          }}
        >
          <RText bold fontSize="11">
            {executive.name}
          </RText>
        </RLink>
        <Spacing pt="5" />
        <RText bold fontSize="11">
          {
            getExecutiveActiveCompany(
              executive.management,
              executive.main_company_id,
              executive.shareholder,
            )?.company_name
          }
        </RText>
        <Spacing pt="5" />
        <Flex flex>
          {executive.zipCode && (
            <>
              <RText fontSize="11">{executive.zipCode.toString()}</RText>
              <Spacing pl="10" />
            </>
          )}
          <RText fontSize="11">{executive.city}</RText>
        </Flex>
        <Spacing pt="10" />
        <ExecutivesTags executiveId={executive.executiveId} />
      </Flex>
    </div>
  );
};

export default ExecutiveHubSidePanelItem;
