import React from "react";
import { IFilter } from "../../../../../../../components/filter/types";
import Navigation, { RELATIONSHIP_NAVIGATION_ITEM_TYPE } from "./Navigation";
import Filter from "../../../../../../../components/filter/Filter";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import Divider from "../../../../../../../components/dividers/Divider/Divider";

interface IProps {
  selected: RELATIONSHIP_NAVIGATION_ITEM_TYPE;
  setSelected: (type: RELATIONSHIP_NAVIGATION_ITEM_TYPE) => void;
  firstLineExist: boolean;
  secondLineExist: boolean;
  filterValue: IFilter;
  setFilterValue: (filter: IFilter) => void;
  rateKey: "wealthRating" | "productAffinity";
}

const Header = ({
  selected,
  setSelected,
  firstLineExist,
  secondLineExist,
  filterValue,
  setFilterValue,
  rateKey,
}: IProps) => {
  return (
    <>
      <Flex>
        <Navigation
          selected={selected}
          setSelected={setSelected}
          firstLineExist={firstLineExist}
          secondLineExist={secondLineExist}
        />
        <Filter filterValue={filterValue} setFilterValue={setFilterValue} rateKey={rateKey} />
      </Flex>
      <Divider />
    </>
  );
};

export default Header;
