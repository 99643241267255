import { createContext } from "react";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { corporatesFacade } from "../../corporates/state/corporates.context";
import { executivesFacade } from "../../executives/state/executive.context";
import { sessionFacade } from "../../../state/akita/session/session.facade";
import {
  OpportunitiesFiltersFacade,
  IOpportunitiesFiltersContext,
} from "./opportunities-filters.facade";
import { opportunitiesFiltersQuery } from "./opportunities-filters.query";
import { opportunitiesFiltersStore } from "./opportunities-filters.store";

export const opportunitiesFiltersFacade = new OpportunitiesFiltersFacade(
  opportunitiesFiltersStore,
  opportunitiesFiltersQuery,
  filtersApiService,
  corporatesFacade,
  executivesFacade,
  sessionFacade,
);

export const OpportunitiesFiltersContext = createContext<IOpportunitiesFiltersContext>(
  opportunitiesFiltersFacade,
);
