import { QueryEntity } from "@datorama/akita";
import {
  ExecutiveNotesStore,
  ExecutiveNotesState,
  executiveNotesStore,
} from "./executive-notes.store";

export class ExecutiveNotesQuery extends QueryEntity<ExecutiveNotesState> {
  constructor(protected store: ExecutiveNotesStore) {
    super(store);
  }
}

export const executiveNotesQuery = new ExecutiveNotesQuery(executiveNotesStore);
