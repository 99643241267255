import React, { useContext } from "react";
import Divider from "../../../components/dividers/Divider/Divider";
import SearchResultsFor from "../SearchResultsFor/SearchResultsFor";
import InterestsFiltersHeader from "./InterestsFiltersHeader";
import InterestsSearchResults from "./InterestsSearchResults";
import { Spacing } from "../../../components/spacing/component";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";

const InterestsPage = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <>
      <SearchResultsFor />

      <InterestsFiltersHeader />

      <Spacing pt={isSmallerScreen ? "12" : "24"} />

      <Divider />

      <InterestsSearchResults />
    </>
  );
};

export default InterestsPage;
