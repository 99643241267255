import { FILTER_CATEGORY } from "../../../api/enums/filter-category.enum";
import { IFilter } from "../../../shared/interfaces/filter.interface";
import {
  createCorporatesFilterValueFromResponse,
  ICorporatesFilterValue,
} from "./corporates-filter-value.model";

export interface ICorporatesFilter {
  id: string;
  title: string;
  title_key: string;
  type: string;
  category: FILTER_CATEGORY;
  doc_count?: number;
  values: ICorporatesFilterValue[];
  filteredEntityIds: string[];
}

export function getFilterIdFromCategory(category: FILTER_CATEGORY, title_key: string) {
  return `${category}_${title_key}`;
}

export function createCorporatesFilter(params: Partial<ICorporatesFilter>) {
  return { ...params } as ICorporatesFilter;
}

export function createCorporatesFilterFromResponse(
  { values, ...params }: IFilter,
  category: FILTER_CATEGORY,
) {
  return {
    ...params,
    values: values.map(createCorporatesFilterValueFromResponse),
    id: getFilterIdFromCategory(category, params.title_key),
    doc_count: params.doc_count || null,
    category,
  } as ICorporatesFilter;
}
