import ApiService from "./api.service";
import { ILastVisitedDossier } from "../../entities/last-dossier/state/last-dossier.model";

class LastDossierApiService extends ApiService {
  public getLastVisitedDossiers(): Promise<ILastVisitedDossier[]> {
    return this.get(`/me/last-dossiers`);
  }

  public saveLastDossier(
    lastVisitedDossier: ILastVisitedDossier,
  ): Promise<ILastVisitedDossier> {
    return this.post(`/last-dossier`, lastVisitedDossier);
  }
}

export const lastDossierApiService = new LastDossierApiService();
