export interface IConnectedPeople {
  person_id: number;
  company_id: number;
  company_name: string;
  from_date: string;
  to_date: string;
  score: number;
  full_name: string;
}

export function createConnectedPeople(params: Partial<IConnectedPeople>) {
  return {
    person_id: null,
    company_id: null,
    company_name: null,
    from_date: null,
    to_date: null,
    score: null,
    full_name: null,
    ...params,
  } as IConnectedPeople;
}
