import axios, { CancelTokenSource } from "axios";
import ApiService from "./services/api.service";

const CancelToken = axios.CancelToken;

export interface ICancelTokenHandler {
  handleRequestCancellation: () => CancelTokenSource;
}

interface ICancelTokenRequestHandler {
  cancelToken?: CancelTokenSource;
}

/**
 * Used for cancelling api requests. It takes an object which contains
 * all the methods for sending requests and cancels the request if
 * the same method was called twice
 * Taken from here:
 * https://codeburst.io/automatically-canceling-previous-requests-in-javascript-606943e2834
 * @param apiObject
 */
export function createCancelTokenHandlerWithApiObject(apiObject: ApiService) {
  // initializing the cancel token handler object
  const cancelTokenHandler: any = {};

  // for each property in apiObject, i.e. for each request
  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler: ICancelTokenRequestHandler = {
      cancelToken: undefined,
    };

    // associating the cancel token handler to the request name
    cancelTokenHandler[propertyName] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        cancelTokenRequestHandler.cancelToken &&
          cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`);

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = CancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
}

/**
 * Same as createCancelTokenHandlerWithApiObject but modified
 * to use urls instead of objects.
 * It takes an array which contains all the api endpoints
 * and cancels the request if the same endpoint was called twice
 * https://codeburst.io/automatically-canceling-previous-requests-in-javascript-606943e2834
 * @param endpoints
 */
export function createCancelTokenHandlerWithEndpoints(endpoints: string[]) {
  // initializing the cancel token handler object
  const cancelTokenHandler: Record<string, ICancelTokenHandler> = {};

  // for each endpoint
  endpoints.forEach((endpoint) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler: ICancelTokenRequestHandler = {
      cancelToken: undefined,
    };

    // associating the cancel token handler to the request name
    cancelTokenHandler[endpoint] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        cancelTokenRequestHandler.cancelToken &&
          cancelTokenRequestHandler.cancelToken.cancel(`${endpoint} canceled`);

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = CancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
}
