import { useObservable } from "@mindspace-io/react";
import { hubFacade } from "../../../modules/hub/state/hub.context";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";

interface ITopProductTrendsHook {
  hubProductTrendsFooterItems: IHubFooterItemData[] | null;
  loadingTopProductTrends: boolean;
}

export const useTopProductTrends = (storeVariable = "default"): ITopProductTrendsHook => {
  const productTrends = hubFacade.productTrendsforStoreVariable(storeVariable);
  const [hubProductTrendsFooterItems] = useObservable(productTrends.items$, null);

  const [loadingTopProductTrends] = useObservable(productTrends.loading$, true);

  return {
    hubProductTrendsFooterItems,
    loadingTopProductTrends,
  };
};

export const useAllTopProductTrends = () => {
  const [trends] = useObservable(hubFacade.productTrendsforAllStoreVariables$, []);
  return trends;
};
