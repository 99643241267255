import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Spinner from "../../../components/other/Spinner/Spinner";
import Selections from "../selections/Selections/Selections";
import { useUserTags } from "../../../entities/custom-tags/hooks/user-tags.hook";
import { useCorporatesSearchResponse } from "../../../entities/corporates-filters/hooks/corporates-search-response.hook";
import { CorporatesFiltersContext } from "../../../entities/corporates-filters/state/corporates-filters.context";
import { useCorporatesSearchHits } from "../../../entities/corporates-filters/hooks/corporates-total-count.hook";
import { ILocationState } from "../../../shared/interfaces/location.interface";

const CorporatesSelections = () => {
  const { t } = useTranslation();
  const { userTags } = useUserTags();
  const { assignTag } = useCorporatesSearchResponse();
  const corporatesFiltersContext = useContext(CorporatesFiltersContext);
  const selectionTags = userTags.filter((item) => item.type === "selection");
  const { totalCount } = useCorporatesSearchHits();

  const searchByTag = (tagName: string) => {
    corporatesFiltersContext.deselectAllSearchFilterValues();
    corporatesFiltersContext.selectSearchFilterValue("companies_tags", tagName);
  };

  const location = useLocation<ILocationState>();
  useEffect(() => {
    if (location.state && location.state.tagName) {
      searchByTag(location.state.tagName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (loadingSelections) {
  //     return <Spinner />;
  // }

  return (
    <Selections
      tags={selectionTags}
      assignTag={assignTag}
      searchByTag={searchByTag}
      totalCount={totalCount}
      tagLabel={t("companies")}
    />
  );
};

export default CorporatesSelections;
