import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { HubContext } from "../state/hub.context";

interface IHubCorporatesHook {
  hubErrorNoData: boolean;
  fetchHubCorporates: () => Promise<void>;
}

interface IHubFirstLoadHook {
  firstLoad: boolean;
}

export const useHubCorporates = (): IHubCorporatesHook => {
  const hubService = useContext(HubContext);

  const [hubErrorNoData] = useObservable(hubService.hubErrorNoData$, false);

  return {
    hubErrorNoData,
    fetchHubCorporates: () => hubService.fetchHubCorporates(),
  };
};

export const useHubFirstLoad = (): IHubFirstLoadHook => {
  const hubService = useContext(HubContext);

  const [firstLoad] = useObservable(hubService.firstLoad$, false);

  return {
    firstLoad,
  };
};
