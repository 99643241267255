import { arrayAdd } from "@datorama/akita";
import { Observable } from "rxjs";
import { format, subDays } from "date-fns";
import { FiltersApiService } from "../../../api/services/filters-api.service";
import { RESULTS_PER_PAGE } from "../../../constants/filters";
import { StartupsFiltersQuery } from "./startups-filters.query";
import { StartupsFiltersStore } from "./startups-filters.store";
import { ICorporatesContext } from "../../corporates/state/corporates.facade";
import { ICorporate } from "../../corporates/models/corporate.model";
import { IQueryRanges } from "../../../shared/interfaces/query-ranges.interface";
import { IObjectWithArrayOfStringsProps } from "../../../helpers/array.helper";

export interface IStartupsFiltersContext {
  loading$: Observable<boolean>;
  searching$: Observable<boolean>;
  searchResultTotalCount$: Observable<number>;
  searchResultIds$: Observable<string[]>;

  getLastSearchTerm(): string;

  getCorporates(corporateIds?: string[]): ICorporate[];

  search(query: string): Promise<void>;

  searchNextPage(): Promise<void>;

  getResultCount(): number;
}

export class StartupsFiltersFacade implements IStartupsFiltersContext {
  readonly loading$ = this._query.selectLoading();
  readonly searching$ = this._query.ui.select("searching");
  readonly searchResultTotalCount$ = this._query.ui.select("searchResultTotalCount");
  readonly searchResultIds$ = this._query.ui.select("searchResultIds");

  constructor(
    private _store: StartupsFiltersStore,
    private _query: StartupsFiltersQuery,
    private _filtersApiService: FiltersApiService,
    private _corporatesService: ICorporatesContext,
  ) {}

  getResultCount(): number {
    const { searchResultTotalCount } = this._query.ui.getValue();

    return searchResultTotalCount;
  }

  getLastSearchTerm(): string {
    const { lastSearchTerm } = this._query.ui.getValue();

    return lastSearchTerm ?? "";
  }

  getCorporates(corporateIds?: string[]): ICorporate[] {
    if (!corporateIds) {
      const { searchResultIds } = this._query.ui.getValue();
      corporateIds = searchResultIds;
    }

    return this._corporatesService.getCorporatesFromIds(corporateIds);
  }

  async search(query: string) {
    this._store.ui.update({
      searching: true,
      lastSearchTerm: query,
    });

    try {
      const page = 1;

      const { corporates, searchResultTotalCount } = await this._getSearchResults(query, page);

      this._corporatesService.addCorporates(corporates);

      this._store.ui.update({
        lastSearchTerm: query,
        nextSearchPage: page + 1,
        searchResultTotalCount: searchResultTotalCount,
        searchResultIds: corporates.map(({ id }) => id),
      });
    } catch (e) {
      console.warn("Error while performing search for startups: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  async searchNextPage() {
    try {
      this._store.ui.update({ searching: true });

      const { nextSearchPage: page, searchResultIds } = this._query.ui.getValue();

      const query = this.getLastSearchTerm();

      const { corporates, searchResultTotalCount } = await this._getSearchResults(query, page);

      this._corporatesService.addCorporates(corporates);

      this._store.ui.update({
        nextSearchPage: page + 1,
        searchResultTotalCount: searchResultTotalCount,
        searchResultIds: arrayAdd(
          searchResultIds,
          corporates.map(({ id }) => id),
        ),
      });
    } catch (e) {
      console.warn("Error while performing next page search for startups: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  private async _getSearchResults(query: string, page: number) {
    const filters = {} as IObjectWithArrayOfStringsProps;

    const todayDate = new Date();
    const sixMonthsAgo = subDays(todayDate, 180);

    const ranges = [
      {
        founding_date: [
          {
            from: format(sixMonthsAgo, "yyyy-MM-dd"),
            to: format(todayDate, "yyyy-MM-dd"),
          },
        ],
      },
    ] as IQueryRanges[];

    const results = await this._filtersApiService.paginateCorporates({
      page,
      limit: RESULTS_PER_PAGE,
      query,
      filters,
      ranges,
    });

    return {
      corporates: results.corporates,
      searchResultTotalCount: results.total,
    };
  }
}
