import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import tagsStyles from "../styles.module.css";
import { ReactComponent as AddIcon } from "../../../assets/AddIcon.svg";
import { ReactComponent as MinusIcon } from "../../../assets/MinusIcon.svg";
import { Flex } from "../../containers/Flex/Flex";
import RText from "../../fonts/RText/RText";
import { Spacing } from "../../spacing/component";
import { ITag, IEntityTag } from "../../../entities/custom-tags/state/custom-tag.model";
import { CustomTagsContext } from "../../../entities/custom-tags/state/custom-tags.context";
import DeleteTagPopup from "./DeleteTagPopup";
import useAppColors from "../../../hooks/app-colors.hook";
import { AUTOMATION_TEST_KEY } from "../../../constants/general";

const automationTag = (name: string, isSelected: boolean) => ({
  [AUTOMATION_TEST_KEY]: `usertags-tag-${name?.replace(/[^a-zA-Z0-9]/g, "")}${
    isSelected ? "-selected" : ""
  }`,
});

interface IProps {
  entityId: string;
  tag: ITag;
  isSelected: boolean;
  entityTags: IEntityTag[];
  setSelectedTagId: (tagId: any) => void;
  isTagAddedToSelected: boolean;
  addTagToEntity: (entityId: string, tagId: number) => void;
  removeTagFromEntity: (entityId: string, tagWrapper: IEntityTag) => void;
  tagSelectedColor: string;
}

const UserTag = ({
  entityId,
  tag,
  isSelected,
  entityTags,
  setSelectedTagId,
  isTagAddedToSelected,
  addTagToEntity,
  removeTagFromEntity,
  tagSelectedColor,
}: IProps) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();
  const customTagsContext = useContext(CustomTagsContext);

  const entityTag = entityTags.find((et: IEntityTag) => et && et?.tag && et.tag.id === tag.id);

  const onSelectTag = () => {
    setSelectedTagId(tag.id);

    if (isTagAddedToSelected) {
      entityTag && removeTagFromEntity(entityId, entityTag);
    } else {
      addTagToEntity(entityId, tag.id);
    }
  };

  const onDelete = () => {
    entityTag && removeTagFromEntity(entityId, entityTag);
    customTagsContext.deleteCustomTag(tag.id);
  };

  return (
    <Flex
      alignItemsCenter
      justifyContentSpaceBetween
      className={styles.usersTagMainContainer}
      style={{ backgroundColor: tagSelectedColor }}
    >
      <Flex
        {...automationTag(tag.name, isSelected)}
        alignItemsCenter
        pointer
        className={tagsStyles.userTagsContentContainer}
        onClick={tag.type && tag.id && onSelectTag}
      >
        <div
          className={tagsStyles.defaultTag}
          style={{
            backgroundColor: tag.color_code,
          }}
        />
        <Spacing pr="15" />
        <RText fontSize="13">{tag.name === "Client" ? t("client") : tag.name}</RText>
      </Flex>
      {isSelected && (
        <Flex>
          <Flex alignItemsCenter className={styles.tagActionContainer}>
            <Flex
              alignItemsCenter
              justifyContentCenter
              className={styles.iconContainer}
              style={{
                border: "2px solid var(--color_background_black)",
              }}
            >
              {isTagAddedToSelected ? (
                <MinusIcon color={colors.backgroundBlackColor} style={{ width: "20px" }} />
              ) : (
                <AddIcon color={colors.backgroundBlackColor} style={{ width: "8px" }} />
              )}
            </Flex>
          </Flex>
          {(tag.type === "custom" || tag.type === "selection") && (
            <>
              <Spacing pr="10" />
              <DeleteTagPopup deleteTag={onDelete} />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default UserTag;
