import React from "react";
import { ReactComponent as ChevronUpIcon } from "../../../../assets/ChevronUp.svg";
import { ReactComponent as ChevronDownIcon } from "../../../../assets/ChevronDown.svg";
import SquareButton from "../../../../components/buttons/SquareButton/SquareButton";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { FlexChild } from "../../../../components/containers/FlexChild/FlexChild";
import { FILTERS_PER_ROW } from "../../../../constants/filters";
import { IFilterWithUIRow } from "../../../../shared/interfaces/filters-row.interface";
import Filter from "../Filter/Filter";
import styles from "./styles.module.css";
import useAppColors from "../../../../hooks/app-colors.hook";
import { SelectedValueTypes } from "../../../../shared/interfaces/selected-value-types.interface";

interface IProps {
  filterRow?: IFilterWithUIRow;
  allFiltersExpanded: boolean;
  filterRowIndex: number;
  selectFilterValue: (filterId: string, filterValueId: SelectedValueTypes) => void;
  toggleRowExpanded: (rowIndex: number) => void;
}

const FilterRow = ({
  filterRow,
  allFiltersExpanded,
  filterRowIndex,
  selectFilterValue,
  toggleRowExpanded,
}: IProps) => {
  const { colors } = useAppColors();

  // Calculates how many empty elements it needs to create to fill the row
  // This is done because each row needs to have the same number
  // of elements, all of them the same size
  const requiredEmptyElements = FILTERS_PER_ROW - (filterRow?.filtersWithUI.length || 0);

  const renderEmptyElements = () => {
    const elementsToRender = [];

    for (let i = 0; i < requiredEmptyElements; i++) {
      elementsToRender.push(<FlexChild key={i} flex1 />);
    }

    return elementsToRender;
  };

  const handleToggleRowExpanded = () => {
    toggleRowExpanded(filterRowIndex);
  };

  return (
    <>
      <Flex justifyContentSpaceBetween>
        {filterRow &&
          filterRow.filtersWithUI.map(({ filter, filterUI }) => {
            return (
              <Filter
                key={filter.id}
                filter={filter}
                filterUI={filterUI}
                allFiltersExpanded={allFiltersExpanded}
                rowExpanded={filterRow.rowExpanded}
                selectFilterValue={selectFilterValue}
              />
            );
          })}

        {requiredEmptyElements > 0 && renderEmptyElements()}

        <FlexChild collapsed={!allFiltersExpanded}>
          <div className={styles.filterRowControls}>
            <div className={styles.filterRowControl}>
              <SquareButton
                text=""
                Icon={<ChevronDownIcon color={colors.secondaryDarkColor} />}
                IconActive={<ChevronUpIcon color={colors.secondaryDarkColor} />}
                active={filterRow?.rowExpanded}
                borderless
                onClick={handleToggleRowExpanded}
              />
            </div>
          </div>
        </FlexChild>
      </Flex>
    </>
  );
};

export default FilterRow;
