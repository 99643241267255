import React from "react";
import SearchResultsFor from "../SearchResultsFor/SearchResultsFor";
import ExecutivesSearchResults from "./ExecutivesSearchResults";
import ExecutivesFiltersPanel from "./ExecutivesFilterPanel";
import ExecutivesSelections from "./ExecutivesSelections";
import ExecutivesSearchExport from "./ExecutivesSearchExport";

const ExecutivesPage = () => {
  return (
    <>
      <SearchResultsFor />

      <ExecutivesFiltersPanel />

      <ExecutivesSelections />

      <ExecutivesSearchExport />

      <ExecutivesSearchResults />
    </>
  );
};

export default ExecutivesPage;
