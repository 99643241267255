import React, { useContext } from "react";
import { Flex } from "../../../components/containers/Flex/Flex";
import { Spacing } from "../../../components/spacing/component";
import { CentralSearchContext } from "../HomePage";
import { useTranslation } from "react-i18next";
import RText from "../../../components/fonts/RText/RText";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";

const SearchResultsFor = () => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  const { searchTerm } = useContext(CentralSearchContext);

  if (!searchTerm) return null;

  return (
    <>
      <Flex>
        <RText fontSize={isSmallerScreen ? "18" : "25"}>{t("searchResultsFor")}</RText>
        <Spacing pl="4" />
        <RText bold fontSize={isSmallerScreen ? "18" : "25"}>{`'${searchTerm}'`}</RText>
      </Flex>

      <Spacing pb={isSmallerScreen ? "20" : "40"} />
    </>
  );
};

export default SearchResultsFor;
