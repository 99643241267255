import { useContext } from "react";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";
import { Flex } from "../../../components/containers/Flex/Flex";
import styles from "./styles.module.css";
import ExecutivesHubKPIs from "./ExecutivesHubKPIs/ExecutivesHubKPIs";

const ExecutivesHubHeader = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  return (
    <Flex
      justifyContentSpaceBetween
      className={`${styles.component} ${isSmallerScreen && styles.component__Responsive}`}
    >
      <ExecutivesHubKPIs />
    </Flex>
  );
};

export default ExecutivesHubHeader;
