import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { classNames } from "../../helpers/string.helper";

interface IPath {
  pathname: string;
  state: {
    [key: string]: string | boolean;
  };
}

interface IProps {
  path: string | IPath;
  children: React.ReactNode;
  condition?: boolean;
  className?: string;
  onClick?: () => void;
}

const RLink = ({ path, children, condition = true, className, onClick }: IProps) => {
  return condition ? (
    <Link to={path} className={classNames(styles, { className })} onClick={onClick}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default RLink;
