import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Flex } from "../../../containers/Flex/Flex";
import RText from "../../../fonts/RText/RText";
import StarRating from "../../../other/StarRating/StarRating";
import { Spacing } from "../../../spacing/component";
import {
  buildCompanyCityAndZipCodeString,
  IResultItem,
  SEARCH_RESULT_TYPE,
} from "../ExecutivesSearchResultsListItem/ExecutivesSearchResultsListItem";
import { useWindowSize } from "../../../../hooks/window.hook";
import CorporatesTags from "../../../../pages/home/CorporatesTags/CorporatesTags";
import CorporatesSelectionTags from "../../../../pages/home/CorporatesTags/CorporatesSelectionTags";
import { ICorporate } from "../../../../entities/corporates/models/corporate.model";
import RLink from "../../../link/RLink";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { formatIndustrySectorForTranslation } from "../../../../helpers/translation.helper";
import { selectAffinityIconSize } from "../../../../helpers/icons.helper";
import { tagEntityFromStringTag } from "../../../../utils/tags.util";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../../../hooks/local-storage.hook";

interface IProps {
  item: IResultItem;
}

export const buildCompanyStreet = (street: string) => {
  if (!street || street === "null") return;
  return street;
};

export const mapCorporatesSearchResponseIntoResultItems = (
  corporates: ICorporate[],
): IResultItem[] => {
  return corporates.map((corporate) => ({
    id: corporate.id,
    title: corporate.company_name,
    textLine1: buildCompanyStreet(corporate.street),
    textLine2: buildCompanyCityAndZipCodeString(corporate.city, corporate.zip_code),
    affinity: corporate.affinity_score,
    type: corporate.industry_sector,
    searchResultType: SEARCH_RESULT_TYPE.CORPORATE,
    tags: corporate.tags.map((t) =>
      typeof t === "string" ? tagEntityFromStringTag(t).tag : t.tag,
    ),
  }));
};

function fixTags(company: any) {
  return company.tags.map((t: any) => {
    if (t?.name.includes("color_code")) {
      return { ...t, ...JSON.parse(t.name) };
    }

    return t;
  });
}

const CorporatesSearchResultsListItem = ({ item }: IProps) => {
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const history = useHistory();
  const [scrollY, setScrollY] = useLocalStorage("scrollY", "");

  const tags = fixTags(item);

  const starRatingIconSize = selectAffinityIconSize(windowSize.width);

  useEffect(() => {
    if (scrollY) {
      window.scroll(0, Number(scrollY));

      setScrollY("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <div className={`${styles.component} ${isSmallerScreen && styles.component__Responsive}`}>
      <Spacing pt="20" />
      <div style={{ marginLeft: 10, display: "inline-grid" }}>
        <CorporatesSelectionTags tags={tags} isSearchResult />
      </div>
      <Spacing p="20">
        <Flex>
          <RLink
            path={{
              pathname: `/home/search/corporate-dossier/${item.id}`,
              state: { fromSearchResults: true },
            }}
            className={styles.company}
            onClick={() => {
              const addToScroll = localStorage.getItem("corporatesFiltersOpen") === "1";
              setScrollY(
                addToScroll
                  ? document.documentElement.scrollTop - 2555
                  : document.documentElement.scrollTop,
              );
            }}
          >
            <Flex column justifyContentCenter>
              <Flex alignItemsCenter>
                <RText fontSize={isSmallerScreen ? "18" : "24"}>{item.title}</RText>
              </Flex>
              <Spacing pb="12" />
              <RText fontSize={isSmallerScreen ? "11" : "16"}>{item.textLine1 || ""}</RText>
              <Spacing pb="4" />
              <RText fontSize={isSmallerScreen ? "11" : "16"}>{item.textLine2 || ""}</RText>
            </Flex>
          </RLink>

          <div className={`${styles.tags} ${isSmallerScreen && styles.tags__Responsive}`}>
            <CorporatesTags companyId={item.id} />
          </div>

          <div className={`${styles.value} ${isSmallerScreen && styles.value__Responsive}`}>
            <Flex justifyContentCenter alignItemsCenter className={styles.affinityContainer}>
              <StarRating
                affinity={item.affinity}
                spacing={starRatingIconSize.spacing}
                iconHeight={starRatingIconSize.height}
                emptyIconSize={isSmallerScreen ? "8" : "12"}
              />
            </Flex>
          </div>

          <div className={styles.industry}>
            <RText fontSize={isSmallerScreen ? "13" : "16"}>
              {item.type && formatIndustrySectorForTranslation(item.type, t)}
            </RText>
            <Spacing pb="8" />
            <RText fontSize="12">{item.typeLine2 || ""}</RText>
          </div>
        </Flex>
      </Spacing>
    </div>
  );
};

export default CorporatesSearchResultsListItem;
