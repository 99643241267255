import React from "react";
import styles from "./styles.module.css";
import { FolderIcon } from "../../../../../../../assets/company-dossier/relationship-indicator/icons";
import { Spacing } from "../../../../../../../components/spacing/component";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";

export default function SecondLine() {
  return (
    <>
      <RText fontSize="16" bold>
        {"2nd Line"}
      </RText>
      <Spacing pt="25" />
      <Flex alignItemsCenter className={styles.secondLineContainer}>
        <div className={styles.blackDot} />
        <Spacing pr="6" />
        <div className={styles.horizontalLine} />

        <Flex
          className={styles.companyContainer}
          // pointer
          // onClick={showCompanyDossier(data.company.id.toString())}
        >
          <FolderIcon
            // color={colors.backgroundBlackColor}
            className={styles.iconLarge}
          />
          <Flex column alignItemsCenter className={styles.companyDescriptionContainer}>
            <RText fontSize="14" bold>
              {"100%"}
            </RText>
            <Spacing pt="10" />
            <StarRating affinity={4} spacing="5" iconHeight="12" />
            <Spacing pt="5" />
            <RText fontSize="14">{"Surface AG"}</RText>
          </Flex>
        </Flex>

        <Flex flex alignItemsCenter className={styles.lineContainer}>
          <div className={styles.horizontalLine} />
        </Flex>

        <Flex
          className={styles.companyContainer}
          // pointer
          // onClick={showCompanyDossier(data.company.id.toString())}
        >
          <FolderIcon
            // color={colors.backgroundBlackColor}
            className={styles.iconSmall}
          />
          <Flex column alignItemsCenter className={styles.companyDescriptionContainer}>
            <RText fontSize="14" bold>
              {"78%"}
            </RText>
            <Spacing pt="10" />
            <StarRating affinity={3} spacing="5" iconHeight="12" />
            <Spacing pt="5" />
            <RText fontSize="14">{"CCC AG"}</RText>
          </Flex>
        </Flex>
      </Flex>
      <Spacing pt="100" />
    </>
  );
}
