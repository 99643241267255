import React, { FC, useContext } from "react";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import RLink from "../../../../../components/link/RLink";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../components/spacing/component";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { IFooterEntity } from "../../../../../shared/interfaces/hub-footer-item-data.interface";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import {
  getWealthScoreName,
  renderWealthScoreIcon,
} from "../../../ExecutivesHubMain/ExecutivesHubMarkerPopup/HubMarkerPopupExecutive/HubMarkerPopupExecutive";
import styles from "./styles.module.css";

interface IProps {
  item: IFooterEntity;
}

const TopLeads: FC<IProps> = ({ item }) => {
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex className={styles.leadItem}>
      <Flex column alignItemsCenter>
        <div
          className={styles.scoreCircle}
          style={{
            border: `2px solid ${colors.secondaryDarkColor}`,
          }}
        >
          {renderWealthScoreIcon(
            item.rating,
            { color: colors.secondaryDarkColor },
            { useOnlyDarkIcon: true },
          )}
        </div>
        <Spacing pb="8" />
        <RText primaryBlue fontSize={isSmallerScreen ? "9" : "11"}>
          {getWealthScoreName(item.rating)}
        </RText>
      </Flex>
      <Spacing pr="15" />
      <Flex column>
        <StarRating affinity={item.rating} spacing="5" iconHeight="12" />
        <Spacing pb="8" />
        <RLink
          path={{
            pathname: `/home/search/executive-dossier/${item.id}`,
            state: { fromHub: true },
          }}
        >
          <RText ellipsis fontSize={isSmallerScreen ? "11" : "13"} bold immutableWhite>
            {item.name}
          </RText>
        </RLink>
        <Spacing pb="6" />
        <RText fontSize={isSmallerScreen ? "11" : "13"} immutableWhite>
          {item.city}
        </RText>
      </Flex>
    </Flex>
  );
};

export default TopLeads;
