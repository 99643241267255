import React, { useContext } from "react";
import { Flex } from "../../../components/containers/Flex/Flex";
import { Spacing } from "../../../components/spacing/component";
import ExecutivesFiltersHeader from "./ExecutivesFiltersHeader";
import ExecutivesFilters from "./ExecutivesFilters";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";

const ExecutivesFiltersPanel = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex column>
      <ExecutivesFiltersHeader />

      <Spacing pt={isSmallerScreen ? "12" : "24"} />

      <ExecutivesFilters />
    </Flex>
  );
};

export default ExecutivesFiltersPanel;
