import { Store, StoreConfig } from "@datorama/akita";
import {
  IIndustrySectorCode,
  INews,
  IRelationshipIndicatorResponse,
  ISignals,
} from "./corporate-dossier.model";

export interface CorporateDossierState {
  industrySectorCodeData: IIndustrySectorCode | null;
  loadingIndustryCodeData: boolean;
  corporateLookALikes: string[] | null;
  totalCorporateLookALikes: number | null;
  corporateNews: INews[] | null;
  corporateProductTrends: ISignals[] | null;
  totalCorporateProductTrends: number | null;
  corporateRelationshipIndicator: IRelationshipIndicatorResponse | null;
  openFullscreenMapModal: boolean;
  corporateSideNavigationActiveAnchor: string;
}

export function createInitialState(): CorporateDossierState {
  return {
    industrySectorCodeData: null,
    loadingIndustryCodeData: false,
    corporateLookALikes: null,
    totalCorporateLookALikes: null,
    corporateNews: null,
    corporateProductTrends: null,
    totalCorporateProductTrends: null,
    corporateRelationshipIndicator: null,
    openFullscreenMapModal: false,
    corporateSideNavigationActiveAnchor: "productTrends",
  };
}

@StoreConfig({
  name: "corporates-dossier",
})
export class CorporateDossierStore extends Store<CorporateDossierState> {
  constructor() {
    super(createInitialState());
  }
}

export const corporateDossierStore = new CorporateDossierStore();
