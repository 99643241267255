import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IManagement } from "../../../../../../../entities/corporates/models/management.model";
import Navigation, { MANAGEMENT_NAVIGATION_ITEM_TYPE } from "./Navigation";
import { IPeopleManagement } from "../../../../../../../entities/executives/interfaces/people-management.interface";
import { removeDuplicateObjects } from "../../../../../../../helpers/array.helper";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import ManagementTable from "./ManagementTable";
import { IShareholder } from "../../../../../../../entities/executives/interfaces/shareholder.interface";
import {
  IFirstLine,
  ISecondLine,
} from "../../../../../../../entities/executive-dossier/models/foundation-reach.model";
import Divider from "../../../../../../../components/dividers/Divider/Divider";

interface IProps {
  managementData: IManagement[] | null;
}

export default function Management({ managementData }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  const [selected, setSelected] = useState(MANAGEMENT_NAVIGATION_ITEM_TYPE.ALL);

  if (!managementData) {
    return null;
  }

  const { ownershipData, executivesData, boardData, operativeData, othersData } =
    getFilteredManagementData(managementData, t);

  const managementDataExist = {
    ownership: ownershipData.length > 0,
    board: boardData.length > 0,
    executives: executivesData.length > 0,
    operative: operativeData.length > 0,
    others: othersData.length > 0,
  };

  const renderManagementContent = () => {
    switch (selected) {
      case MANAGEMENT_NAVIGATION_ITEM_TYPE.ALL:
        return (
          <>
            <ManagementTable tableTitle={t("ownership")} data={ownershipData} t={t} />
            <ManagementTable tableTitle={t("board")} data={boardData} t={t} />
            <ManagementTable
              tableTitle={t("executivesCorporateManagement")}
              data={executivesData}
              t={t}
            />
            <ManagementTable tableTitle={t("operative")} data={operativeData} t={t} />
            <ManagementTable tableTitle={t("others")} data={othersData} t={t} />
          </>
        );
      case MANAGEMENT_NAVIGATION_ITEM_TYPE.OWNERSHIP:
        return <ManagementTable tableTitle={t("ownership")} data={ownershipData} t={t} />;
      case MANAGEMENT_NAVIGATION_ITEM_TYPE.BOARD:
        return <ManagementTable tableTitle={t("board")} data={boardData} t={t} />;
      case MANAGEMENT_NAVIGATION_ITEM_TYPE.EXECUTIVES:
        return (
          <ManagementTable
            tableTitle={t("executivesCorporateManagement")}
            data={executivesData}
            t={t}
          />
        );
      case MANAGEMENT_NAVIGATION_ITEM_TYPE.OPERATIVE:
        return <ManagementTable tableTitle={t("operative")} data={operativeData} t={t} />;
      case MANAGEMENT_NAVIGATION_ITEM_TYPE.OTHERS:
        return <ManagementTable tableTitle={t("others")} data={othersData} t={t} />;
      default:
        return null;
    }
  };

  return (
    <>
      <RText fontSize={isSmallerScreen ? "20" : "20"} bold>
        {t("management")}
      </RText>
      <Spacing pt="28" />
      <Navigation
        selected={selected}
        setSelected={setSelected}
        managementDataExist={managementDataExist}
      />
      <Divider />
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
      {renderManagementContent()}
    </>
  );
}

// TODO Should use only one data type, needs refactor!
type PeriodDateData =
  | IManagement
  | IPeopleManagement
  | IShareholder
  | IFirstLine
  | ISecondLine;

export const formatPeriodDate = (data: PeriodDateData, t: CallableFunction) => {
  const { from_date, from_quality, to_date, to_quality } = data;

  let fromYear = "";
  let toYear = "";

  if (from_date && from_date !== "1900-01-01" && from_quality !== 3) {
    fromYear = new Date(from_date).getFullYear().toString();
  }

  if (to_date && to_date !== "1900-01-01" && to_quality !== 3) {
    toYear = new Date(to_date).getFullYear().toString();
  }

  return renderUserMandateRange(fromYear, toYear, t);
};

export const renderUserMandateRange = (from: string, to: string, t: CallableFunction) => {
  if (!from && !to) {
    return t("today");
  }

  if (!from && to) {
    return t("until") + " " + to;
  }

  if (from && !to) {
    return from + " - " + t("today");
  }

  return from + " - " + to;
};

export interface IManagementPerson {
  personId: string;
  fullName: string;
  position: string;
  period: string;
  rank: number;
  toDate: string;
  promoterScore: number;
  interactionRank: number;
  score: number;
  fromDate: string;
  wealthScore: number;
}

const mapManagementData = (
  managementData: IManagement[],
  t: CallableFunction,
): IManagementPerson[] => {
  return managementData.map((person: IManagement) => {
    return {
      personId: person.person_id?.toString(),
      fullName: person.full_name,
      position: person.position ?? "",
      period: formatPeriodDate(person, t),
      rank: person.function_rank,
      toDate: person.to_date,
      promoterScore: person.promoter_score,
      interactionRank: person.function_interaction_rank,
      score: person.score,
      fromDate: person.from_date,
      wealthScore: person.wealth_score,
    };
  });
};

const filterByRank = (data: IManagementPerson[], rank: number) => {
  return data.filter((person: IManagementPerson) => person.rank === rank);
};

/**
 * Filters management data in order to remove duplicate data for the same person.
 * Same person can show up max two times:
 *  1) In ownership data (rank 1)
 *  2) In the one of other rank types (2,3,4,5).
 *
 * Returns filtered data for each rank type and total count.
 */
export function getFilteredManagementData(managementData: IManagement[], t: CallableFunction) {
  const sortedData = [...mapManagementData(managementData, t)].sort((a, b) => {
    return (
      (b.toDate ? 0 : 1) - (a.toDate ? 0 : 1) ||
      a.interactionRank - b.interactionRank ||
      (a.fromDate ? 1 : 0) - (b.fromDate ? 1 : 0)
    );
  });

  const uniqueOwnershipUsers = removeDuplicateObjects(
    [...sortedData].filter((person: IManagementPerson) => person.rank === 1),
    "personId",
  );

  const uniqueOtherUsers = removeDuplicateObjects(
    [...sortedData].filter((person: IManagementPerson) => person.rank > 1),
    "personId",
  );

  return {
    ownershipData: uniqueOwnershipUsers,
    executivesData: filterByRank(uniqueOtherUsers, 2),
    boardData: filterByRank(uniqueOtherUsers, 3),
    operativeData: filterByRank(uniqueOtherUsers, 4),
    othersData: filterByRank(uniqueOtherUsers, 5),
    totalCount: uniqueOwnershipUsers.length + uniqueOtherUsers.length,
  };
}
