import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { Chart, renderInfoData } from "../headcount/Headcount";
import { ITurnoverHistoric } from "../../../../../../../entities/corporates/models/turnover-historic.model";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

const formatChartData = (chartData: ITurnoverHistoric[]) => {
  return chartData.map((item: ITurnoverHistoric) => ({
    year: item.year,
    count: item.turnover,
  }));
};

interface IProps {
  chartData: ITurnoverHistoric[] | null;
}

export default function Turnover({ chartData }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  if (!chartData) {
    return null;
  }

  const formattedChartData = formatChartData(chartData);
  const lastThreeYearsChartData = formattedChartData.slice(
    Math.max(formattedChartData.length - 3, 0),
  );
  const infoData = renderInfoData(lastThreeYearsChartData);

  return (
    <>
      <RText fontSize={isSmallerScreen ? "20" : "20"} bold>
        {t("turnover")}
      </RText>
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
      <Chart chartData={formattedChartData} infoData={infoData} />
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
    </>
  );
}
