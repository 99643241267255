import React from "react";
import { useCorporatesSearchResponse } from "../../../entities/corporates-filters/hooks/corporates-search-response.hook";
import Export from "../../../components/export/Export";
import { useSession } from "../../../hooks/session.hook";

const CorporatesSearchExport = () => {
  const { exportResults, searchResultTotalCount } = useCorporatesSearchResponse();
  const { currentUser } = useSession();

  if (
    currentUser.export_permission &&
    searchResultTotalCount <= +currentUser.export_permission
  ) {
    return <Export exportResults={exportResults} />;
  }
  return null;
};

export default CorporatesSearchExport;
