import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Spacing } from "../../spacing/component";
import { Flex } from "../../containers/Flex/Flex";
import RText from "../../fonts/RText/RText";
import { ITag } from "../../../entities/custom-tags/state/custom-tag.model";
import { CustomTagsContext } from "../../../entities/custom-tags/state/custom-tags.context";
import ColorsPopup from "./ColorsPopup";
import { AUTOMATION_TEST_KEY } from "../../../constants/general";

const automationCreateTagOk = { [AUTOMATION_TEST_KEY]: "createtag-ok-btn" };

interface IProps {
  createTag: (tag: ITag) => void;
}

const CreateTag = ({ createTag }: IProps) => {
  const { t } = useTranslation();
  const customTagsContext = useContext(CustomTagsContext);

  const [selectedColor, setSelectedColor] = useState("#FFF");
  const [tagName, setTagName] = useState("");

  const onInputChange = (event: any) => setTagName(event.target.value);

  const onAddTag = () => {
    if (tagName.trim() === "") {
      return;
    }

    customTagsContext
      .saveCustomTag(tagName, selectedColor)
      .then((tag: ITag) => createTag(tag));

    setTagName("");
    setSelectedColor("#FFF");
  };

  return (
    <Flex alignItemsCenter className={styles.createTagMainContainer}>
      <Flex
        alignItemsCenter
        justifyContentCenter
        className={styles.addIconContainer}
        style={{
          border: "2px solid var(--color_background_black)",
        }}
      ></Flex>
      <Spacing pr="10" />
      <input
        type="text"
        maxLength={20}
        className={styles.addTagInput}
        placeholder={t("createNewTag")}
        onChange={onInputChange}
        style={{
          border: "1px solid #B1AEAE",
        }}
      />
      <Spacing pr="6" />
      <ColorsPopup selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
      <Spacing pr="3" />
      <Flex
        {...automationCreateTagOk}
        className={styles.createTagButton}
        pointer
        onClick={onAddTag}
      >
        <RText bold fontSize="15">
          OK
        </RText>
      </Flex>
      <Spacing pr="6" />
    </Flex>
  );
};

export default CreateTag;
