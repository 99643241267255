import { QueryEntity } from "@datorama/akita";
import { CorporatesStore, corporatesStore, ICorporatesState } from "./corporates.store";

export class CorporatesQuery extends QueryEntity<ICorporatesState> {
  constructor(protected store: CorporatesStore) {
    super(store);
  }
}

export const corporatesQuery = new CorporatesQuery(corporatesStore);
