import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ICorporateHubMarker } from "../../../entities/corporates/interfaces/hub-marker.interface";
import { ICorporate } from "../../../entities/corporates/models/corporate.model";
import { buildCompanyAddress, formatZipCode } from "../../../helpers/map.helper";
import { HubContext } from "../state/hub.context";
import { ICorporateMarkerData } from "../../../entities/corporates/interfaces/corporate-marker-data.interface";
import { IGeoLocation } from "../../../shared/interfaces/geo-location.interface";

// TODO: export into some shared file,
//  since executives hub also uses this method
/**
 * Turns IGeoLocation object into comma separated string
 * @param geoLocation
 */
export const geoLocationToString = (geoLocation: IGeoLocation): string => {
  return `${geoLocation.lat},${geoLocation.lon}`;
};

// TODO: export into some shared file,
//  since executives hub also uses this method
/**
 * Turns comma separated string into IGeoLocation object
 * @param str
 */
export const stringToGeoLocation = (str: string): IGeoLocation => {
  const lat = +str.split(",")[0];
  const lon = +str.split(",")[1];

  return {
    lat,
    lon,
  };
};

export const mapCorporatesToHubMarkers = (corporates: ICorporate[]): ICorporateHubMarker[] => {
  const corporatesGroupedByLocation: Map<string, ICorporateMarkerData[]> = new Map();

  corporates.forEach((corporate) => {
    if (
      !corporate ||
      !corporate?.geo_location ||
      !corporate.geo_location?.lat ||
      !corporate.geo_location?.lon
    )
      return;

    const locationString = geoLocationToString(corporate.geo_location);

    if (corporatesGroupedByLocation.has(locationString)) {
      // For existing location key, append new corporate into array
      corporatesGroupedByLocation.set(locationString, [
        ...corporatesGroupedByLocation.get(locationString),
        mapCorporateIntoCorporateMarkerData(corporate),
      ]);
    } else {
      // Create new array and fill it with current corporate
      corporatesGroupedByLocation.set(locationString, [
        mapCorporateIntoCorporateMarkerData(corporate),
      ]);
    }
  });

  return createHubMarkersFromCorporatesGroupedByLocation(corporatesGroupedByLocation);
};

const createHubMarkersFromCorporatesGroupedByLocation = (
  corporatesGroupedByLocation: Map<string, ICorporateMarkerData[]>,
): ICorporateHubMarker[] => {
  const hubMarkers: ICorporateHubMarker[] = [];

  corporatesGroupedByLocation.forEach((value, key) => {
    hubMarkers.push({
      coordinates: stringToGeoLocation(key),
      corporatesData: value,
    });
  });

  return hubMarkers;
};

export const mapCorporateIntoCorporateMarkerData = (
  corporate: ICorporate,
): ICorporateMarkerData => {
  if (!corporate) return null;

  return {
    corporateId: corporate.id,
    title: corporate.company_name,
    affinity: corporate.affinity_score,
    successScore: corporate.success_score?.toString(),
    promoterScore: corporate.promoter_score?.toString(),
    address: buildCompanyAddress(corporate.street, corporate.zip_code, corporate.city),
    city: corporate.city,
    street: corporate.street,
    zipCode: formatZipCode(corporate.zip_code),
    tags: corporate.tags.map((tagWrapper) => tagWrapper.tag),
    news: 2,
    relationships: 2,
  };
};

interface IHubMarkersHook {
  loadingHubCorporates: boolean;
  hubMarkers: ICorporateHubMarker[];
}

export const useHubMarkers = (): IHubMarkersHook => {
  const hubService = useContext(HubContext);

  const [loadingHubCorporates] = useObservable(hubService.loadingHubCorporates$, null);
  const [hubMarkers] = useObservable(
    hubService.hubCorporatesMarkers$,
    hubService.getHubCorporatesMarkers(),
  );

  return {
    loadingHubCorporates,
    hubMarkers,
  };
};
