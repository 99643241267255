import React from "react";
import { useTranslation } from "react-i18next";
import RNavigation, {
  INavigationItem,
} from "../../../../../../../components/navigation/RNavigation";

export enum INVESTMENT_NAVIGATION_ITEM_TYPE {
  ALL,
  CONTROLLING,
  MINORITIES,
  SECOND_LINE,
}

interface IProps {
  selected: INVESTMENT_NAVIGATION_ITEM_TYPE;
  setSelected: (type: INVESTMENT_NAVIGATION_ITEM_TYPE) => void;
  exist: any;
}

export default function Navigation({ selected, setSelected, exist }: IProps) {
  const { t } = useTranslation();

  const navigationItems: INavigationItem[] = [
    {
      title: t("all"),
      type: INVESTMENT_NAVIGATION_ITEM_TYPE.ALL,
      exist: true,
    },
    {
      title: t("controlling"),
      type: INVESTMENT_NAVIGATION_ITEM_TYPE.CONTROLLING,
      exist: exist.controlling,
    },
    {
      title: t("minorities"),
      type: INVESTMENT_NAVIGATION_ITEM_TYPE.MINORITIES,
      exist: exist.minorities,
    },
    {
      title: "2nd Line",
      type: INVESTMENT_NAVIGATION_ITEM_TYPE.SECOND_LINE,
      exist: exist.secondLine,
    },
  ];

  const onSelectItem = (item: INavigationItem) => {
    if (!item.exist) {
      return;
    }

    setSelected(item.type as INVESTMENT_NAVIGATION_ITEM_TYPE);
  };

  return (
    <RNavigation
      navigationItems={navigationItems}
      selectedItem={selected}
      onSelectItem={onSelectItem}
    />
  );
}
