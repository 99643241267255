import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { Spacing } from "../../../../../../../components/spacing/component";
import RText from "../../../../../../../components/fonts/RText/RText";
import { IShareholderCompanies } from "../../../../../../../entities/corporates/models/shareholders-companies.model";
import { IShareholderPeople } from "../../../../../../../entities/corporates/models/shareholders-people.model";
import RowData from "./RowData";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../../../../../hooks/session.hook";

interface IProps {
  companies: IShareholderCompanies[];
  people: IShareholderPeople[];
}

export default function Minorities({ companies, people }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser } = useSession();

  if (!companies?.length && !people?.length) {
    return null;
  }

  return (
    <>
      <RText fontSize={isSmallerScreen ? "16" : "16"} bold>
        {t("minorities")}
      </RText>
      <Spacing pt={isSmallerScreen ? "10" : "25"} />

      {companies?.map((company, index) => (
        <Fragment key={index}>
          <RowData
            nameLabel={t("company")}
            nameValue={company.company_name}
            nameLinkPath={`/home/search/corporate-dossier/${company.company_id}`}
            industry={company.industry_sector ?? ""}
            shareSinceDate={
              company.from_date && company.from_date !== "1900-01-01"
                ? format(new Date(company.from_date), "yyyy")
                : ""
            }
            shareUntilDate={
              company.to_date &&
              (company.to_date !== "1900-01-01"
                ? format(new Date(company.to_date), "yyyy")
                : t("unknown"))
            }
            participationScore={company.share_perc}
            starRating={company.affinity_score}
          />
          <Spacing pb="25" />
        </Fragment>
      )) ?? null}

      {people?.map((person, index) => (
        <Fragment key={index}>
          <RowData
            nameLabel={t("person")}
            nameValue={person.full_name}
            nameLinkPath={`/home/search/executive-dossier/${person.person_id}`}
            industry={person.industry_sector ?? ""}
            shareSinceDate={
              person.from_date && person.from_date !== "1900-01-01"
                ? format(new Date(person.from_date), "yyyy")
                : ""
            }
            shareUntilDate={
              person.to_date &&
              (person.to_date !== "1900-01-01"
                ? format(new Date(person.to_date), "yyyy")
                : t("unknown"))
            }
            participationScore={person.share_perc}
            starRating={isB2CUser ? person.wealth_score : null}
          />
          <Spacing pb="25" />
        </Fragment>
      )) ?? null}
    </>
  );
}
