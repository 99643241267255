import { EntityState, EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";
import { ICorporatesFilter } from "../models/corporates-filter.model";
import { SelectedValueTypes } from "../../../shared/interfaces/selected-value-types.interface";

export interface ICorporatesFilterUI {
  id: string;

  hubSelected: boolean;
  hubSelectedValues: SelectedValueTypes[];

  searchSelected: boolean;
  searchSelectedValues: SelectedValueTypes[];
}

export interface CorporatesFiltersState extends EntityState<ICorporatesFilter> {}
export interface CorporatesFiltersUIState extends EntityState<ICorporatesFilterUI> {
  searching: boolean;
  lastSearchTerm: string;
  nextSearchPage: number;
  searchResultTotalCount: number;
  searchResultIds: string[];
  closedSearchRowIndexes: number[];
}

@StoreConfig({
  name: "corporates-filters",
})
export class CorporatesFiltersStore extends EntityStore<CorporatesFiltersState> {
  ui: EntityUIStore<CorporatesFiltersUIState>;

  constructor() {
    super({ loading: null });

    this.createUIStore({
      searching: null,
      lastSearchTerm: "",
      nextSearchPage: 1,
      searchResultTotalCount: null,
      searchResultIds: null,
      closedSearchRowIndexes: [],
    }).setInitialEntityState({
      hubSelected: false,
      hubSelectedValues: [],

      searchSelected: false,
      searchSelectedValues: [],
    });
  }
}

export const corporatesFiltersStore = new CorporatesFiltersStore();
