import { AxiosRequestConfig, AxiosResponse } from "axios";
import initializeApiInterceptors from "../apiInterceptors";
import HttpClient from "../HttpClient";

export default class ApiService {
  private _httpClient = HttpClient.getInstance();

  public constructor() {
    initializeApiInterceptors(this._httpClient);
  }

  private _responseBody = (response: AxiosResponse) => response.data;

  public get(url: string, config?: AxiosRequestConfig): Promise<any> {
    return this._httpClient.get(url, config).then(this._responseBody);
  }

  public post<T = any>(url: string, body: {}, config?: {}): Promise<T> {
    return this._httpClient.post(url, body, config).then(this._responseBody);
  }

  public put(url: string, body: {}): Promise<any> {
    return this._httpClient.put(url, body).then(this._responseBody);
  }

  public delete(url: string): Promise<any> {
    return this._httpClient.delete(url).then(this._responseBody);
  }
}
