import CentralSearch from "./central-search/CentralSearch";
import Profile from "./profile/Profile";
// import RLink from "../../../components/link/RLink";
import useAppLogo from "../../../hooks/app-logo.hook";
import { useSession } from "../../../hooks/session.hook";
import { useTranslation } from "react-i18next";
import { useIsHub } from "../../../utils/route.util";
import { Grid, useTheme, Box, Typography, Hidden } from "@mui/material";
import { Menu, Close, ArrowBack } from "@mui/icons-material";
import { useState } from "react";

const Header = () => {
  const { t } = useTranslation();
  const { logo } = useAppLogo();
  const { currentUser } = useSession();
  const isHub = useIsHub();
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const isSwiss = window.location.href.includes("/ch/") ? "/ch" : "";

  const handleMenuButton = () => setIsMenuOpen((prevState: boolean) => !prevState);

  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          backgroundColor: theme.palette.primary.main,
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 5,
          paddingRight: 5,
          zIndex: 2,
          minHeight: 70,
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent={{ xs: "space-between", md: "unset" }}
        >
          <Hidden only={["md", "lg", "xl"]}>
            <Grid item>
              <Box onClick={handleMenuButton}>
                {isMenuOpen && <Close style={{ color: "white" }} />}
                {!isMenuOpen && <Menu style={{ color: "white" }} />}
              </Box>
            </Grid>
          </Hidden>
          <Grid item md={4}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item>
                {isMenuOpen && <Profile />}
                {!isMenuOpen && logo && (
                  <a href={`${isSwiss}/home`} style={{ textDecoration: "none" }}>
                    {/* <RLink path="/"> */}
                    <img src={logo} alt="" height={32} />
                    {/* </RLink> */}
                  </a>
                )}
                {!isMenuOpen &&
                  !logo &&
                  logo !== "" &&
                  window.location.pathname !== "/home" &&
                  window.location.pathname !== "/ch/home" && (
                    <a
                      href={`${isSwiss}/home`}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ArrowBack />
                      <span>{t("goToHub")}</span>
                    </a>
                  )}
              </Grid>
              {isHub && (
                <Hidden only={["xs", "sm"]}>
                  <Grid item>
                    <a href={`${isSwiss}/home`} style={{ textDecoration: "none" }}>
                      <Typography fontSize={13} color={theme.palette.common.white}>
                        {t("salesRegionOf")}
                      </Typography>
                      <Typography fontSize={13} color={theme.palette.common.white} mt={-0.5}>
                        {currentUser.first_name} {currentUser.last_name}
                      </Typography>
                    </a>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </Grid>
          <Hidden only={["xs", "sm"]}>
            <Grid item md={4}>
              <CentralSearch />
            </Grid>
            <Grid item md={4}>
              <Profile />
            </Grid>
          </Hidden>
          {!isMenuOpen && (
            <Hidden only={["md", "lg"]}>
              <Grid item></Grid>
            </Hidden>
          )}
        </Grid>
      </Box>
      <Box>
        <Hidden only={["md", "lg", "xl"]}>
          <CentralSearch light />
        </Hidden>
      </Box>
    </>
  );
};

export default Header;
