import React, { SVGProps, useContext } from "react";
import { IExecutiveMarkerData } from "../../../../../entities/executives/interfaces/executive-marker-data.interface";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useParams } from "react-router-dom";
import { IRouteParams } from "../../../../../shared/interfaces/route-params.interface";
import { useIsExecutiveDossier } from "../../../../../utils/route.util";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../components/spacing/component";
import CountBox from "../../../../../components/other/CountBox/CountBox";
import NewsIcon from "../../../../../assets/hub/NewsIcon.svg";
import RelationshipsIcon from "../../../../../assets/hub/RelationshipsIcon.svg";
import Divider from "../../../../../components/dividers/Divider/Divider";
import RLink from "../../../../../components/link/RLink";
import styles from "../styles.module.css";
import { ReactComponent as HNWIIcon } from "../../../../../assets/executives-hub/HNWIIcon.svg";
import { ReactComponent as PBIcon } from "../../../../../assets/executives-hub/PBIcon.svg";
import { ReactComponent as PBIconDark } from "../../../../../assets/executives-hub/PBIconDark.svg";
import { getExecutiveActiveCompany } from "../../../../../helpers/dossier.helper";
import useAppColors from "../../../../../hooks/app-colors.hook";
import AnimatedCircleIcon from "../../../../../components/animations/animated-circle-icon/AnimatedCircleIcon";
import { sessionFacade } from "../../../../../state/akita/session/session.facade";

export const getWealthScoreName = (wealth_score: number) => {
  if (wealth_score >= 4) return "HNWI";
  if (wealth_score === 3) return "PB";

  return "";
};

export const renderWealthScoreIcon = (
  wealth_score: number,
  style?: SVGProps<SVGSVGElement>,
  options?: { useOnlyLightIcon?: boolean; useOnlyDarkIcon?: boolean },
) => {
  if (wealth_score >= 4) return <HNWIIcon color="#fff" {...style} />;

  if (wealth_score === 3) {
    if (options?.useOnlyLightIcon) {
      return <PBIcon color="#fff" {...style} />;
    }

    if (options?.useOnlyDarkIcon) {
      return <PBIconDark color="#fff" {...style} />;
    }

    return sessionFacade.isDarkTheme() ? (
      <PBIconDark color="#fff" {...style} />
    ) : (
      <PBIcon color="#fff" {...style} />
    );
  }

  return null;
};

interface IProps {
  markerExecutive: IExecutiveMarkerData;
}

const HubMarkerPopupExecutive = ({ markerExecutive }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { id: executiveId } = useParams<IRouteParams>();
  const isExecutiveDossier = useIsExecutiveDossier();

  const shouldOpenExecutiveDossier = () => {
    if (isExecutiveDossier && executiveId === markerExecutive.executiveId) {
      return false;
    }

    return true;
  };

  return (
    <Flex column>
      <Flex justifyContentEnd className={styles.newsAndRelationsContainer}>
        <Flex alignItemsCenter>
          <RText immutableBlack fontSize="11">
            {t("news")}
          </RText>
          <Spacing pl="5" />
          <CountBox count={0} icon={NewsIcon} />
          <Spacing pl="10" />
          <RText immutableBlack fontSize="11">
            {t("relationships")}
          </RText>
          <Spacing pl="5" />
          <CountBox count={0} icon={RelationshipsIcon} />
        </Flex>
      </Flex>

      <Divider />

      <Divider />

      <RLink
        path={{
          pathname: `/home/search/executive-dossier/${markerExecutive.executiveId}`,
          state: { fromHub: true },
        }}
        condition={!!shouldOpenExecutiveDossier()}
      >
        <Flex column className={styles.executiveNameContainer}>
          <RText immutableBlack bold fontSize={isSmallerScreen ? "13" : "18"}>
            {markerExecutive.name}
          </RText>
          <Spacing pb="5" />
          <RText immutableBlack fontSize={isSmallerScreen ? "11" : "13"}>
            {getExecutiveActiveCompany(
              markerExecutive.management,
              markerExecutive.main_company_id,
              markerExecutive.shareholder,
            )?.company_name ?? ""}
          </RText>
        </Flex>
      </RLink>

      <Divider />

      <Spacing ph="15" pt="15" pb={isSmallerScreen ? "16" : "35"}>
        <Flex alignItemsCenter justifyContentCenter>
          {markerExecutive.wealth_score >= 3 && (
            <>
              <Flex column alignItemsCenter>
                <RText immutableBlack bold>
                  {getWealthScoreName(markerExecutive.wealth_score)}
                </RText>
                <Spacing pb="10" />
                <div className={styles.scoreContainer}>
                  <AnimatedCircleIcon>
                    {renderWealthScoreIcon(
                      markerExecutive.wealth_score,
                      {
                        color: "var(--immutable_background_black)",
                      },
                      { useOnlyLightIcon: true },
                    )}
                  </AnimatedCircleIcon>
                </div>
              </Flex>
              <Spacing pr="30" />
            </>
          )}

          <Flex column alignItemsCenter>
            <RText immutableBlack bold>
              {t("promoterScore")}
            </RText>
            <Spacing pb="10" />
            <div className={styles.scoreContainer}>
              <AnimatedCircleIcon
                value={markerExecutive.promoterScore}
                showValuePercentage={true}
                pathColor={colors.secondaryDarkColor}
                trailColor={colors.secondaryLightColor}
              >
                <RText immutableBlack bold fontSize="12">
                  {markerExecutive.promoterScore?.toString()}
                </RText>
              </AnimatedCircleIcon>
            </div>
          </Flex>
        </Flex>
      </Spacing>
    </Flex>
  );
};

export default HubMarkerPopupExecutive;
