import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { CorporatesFiltersContext } from "../state/corporates-filters.context";

interface UseCorporatesSearchHitsHook {
  totalCount: number;
}

export function useCorporatesSearchHits(): UseCorporatesSearchHitsHook {
  const corporatesFiltersService = useContext(CorporatesFiltersContext);

  const [totalCount] = useObservable(
    corporatesFiltersService.searchResultTotalCount$,
    corporatesFiltersService.getResultCount(),
  );

  return { totalCount };
}
