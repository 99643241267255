import React, { useContext } from "react";
import styles from "./styles.module.css";
import { BrightModeIcon, DarkModeIcon } from "../../../../assets/dark-mode/icons";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { Spacing } from "../../../../components/spacing/component";
import useAppColors from "../../../../hooks/app-colors.hook";
import { SessionContext } from "../../../../state/akita/session/session.context";
import { THEME_MODE } from "../../../../state/akita/session/session.model";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";

const automationMarkerLight = { [AUTOMATION_TEST_KEY]: "hdr-light-mode-btn" };
const automationMarkerDark = { [AUTOMATION_TEST_KEY]: "hdr-dark-mode-btn" };

const DarkModeSwitcher = () => {
  const { colors } = useAppColors();
  const sessionContext = useContext(SessionContext);

  return (
    <Flex alignItemsCenter>
      <BrightModeIcon
        {...automationMarkerLight}
        color={colors.primaryDarkColor}
        opacity={sessionContext.getTheme() === THEME_MODE.LIGHT ? 1 : 0.3}
        className={styles.icon}
        onClick={() => sessionContext.setTheme(THEME_MODE.LIGHT)}
      />
      <Spacing pr="10" />
      <DarkModeIcon
        {...automationMarkerDark}
        color={colors.primaryDarkColor}
        opacity={sessionContext.getTheme() === THEME_MODE.DARK ? 1 : 0.3}
        className={styles.icon}
        onClick={() => sessionContext.setTheme(THEME_MODE.DARK)}
      />
    </Flex>
  );
};

export default DarkModeSwitcher;
