import React, { useContext } from "react";
import { ReactComponent as NavIconExecutives } from "../../../assets/NavIconExecutives.svg";
import { useExecutivesSearchHits } from "../../../entities/executives-filters/hooks/executives-total-count.hook";
import FiltersHeader from "../filters/FiltersHeader/FiltersHeader";
import { CentralSearchContext } from "../HomePage";
import { ExecutivesFiltersContext } from "../../../entities/executives-filters/state/executives-filters.context";
import { useTranslation } from "react-i18next";

const ExecutivesFiltersHeader = () => {
  const { t } = useTranslation();

  const { executivesFiltersOpen, toggleExecutivesFiltersOpen } =
    useContext(CentralSearchContext);

  const executivesFiltersContext = useContext(ExecutivesFiltersContext);

  const { totalCount } = useExecutivesSearchHits();

  return (
    <FiltersHeader
      title={t("executives")}
      Icon={NavIconExecutives}
      resultsCount={totalCount ?? null}
      hasFilters={true}
      onMyFiltersClick={toggleExecutivesFiltersOpen}
      onClearAllClick={() => executivesFiltersContext.deselectAllSearchFilterValues()}
      filtersOpened={executivesFiltersOpen}
    />
  );
};

export default ExecutivesFiltersHeader;
