import React, { useContext, useEffect, useState } from "react";
import { Flex } from "../../../components/containers/Flex/Flex";
import HomeNavigationItem from "./navigation-item/HomeNavigationItem";
import { ReactComponent as NavIconAll } from "../../../assets/NavIconAll.svg";
import { ReactComponent as NavIconCorporates } from "../../../assets/NavIconCorporates.svg";
import { ReactComponent as NavIconExecutives } from "../../../assets/NavIconExecutives.svg";
import { ReactComponent as NavIconInterests } from "../../../assets/NavIconInterests.svg";
import { ReactComponent as NavIconOpportunities } from "../../../assets/NavIconOpportunities.svg";
import { ReactComponent as NavIconStartUps } from "../../../assets/NavIconStartUps.svg";
import Divider from "../../../components/dividers/Divider/Divider";
import { Spacing } from "../../../components/spacing/component";
import NavigationItemCounter from "./NavigationItemCounter/NavigationItemCounter";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../hooks/session.hook";
import { useTranslation } from "react-i18next";

export enum HOME_NAVIGATION_ITEM_TYPE {
  ALL,
  CORPORATES,
  EXECUTIVES,
  INTERESTS,
  OPPORTUNITIES,
  STARTUPS,
}

export interface INavigationBarItem {
  label: string;
  url: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  type: HOME_NAVIGATION_ITEM_TYPE;
}

const HomeNavigationBar = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser } = useSession();
  const { t } = useTranslation();

  const [navItems, setNavItems] = useState<INavigationBarItem[]>([]);
  const [navItemsB2C, setNavItemsB2C] = useState<INavigationBarItem[]>([]);

  useEffect(() => {
    setNavItems([
      {
        label: t("all"),
        url: "all",
        Icon: NavIconAll,
        type: HOME_NAVIGATION_ITEM_TYPE.ALL,
      },
      {
        label: t("corporates"),
        url: "corporates",
        Icon: NavIconCorporates,
        type: HOME_NAVIGATION_ITEM_TYPE.CORPORATES,
      },
      {
        label: t("executives"),
        url: "executives",
        Icon: NavIconExecutives,
        type: HOME_NAVIGATION_ITEM_TYPE.EXECUTIVES,
      },
      {
        label: t("interests"),
        url: "interests",
        Icon: NavIconInterests,
        type: HOME_NAVIGATION_ITEM_TYPE.INTERESTS,
      },
      {
        label: t("opportunities"),
        url: "opportunities",
        Icon: NavIconOpportunities,
        type: HOME_NAVIGATION_ITEM_TYPE.OPPORTUNITIES,
      },
      {
        label: t("navStartups"),
        url: "startups",
        Icon: NavIconStartUps,
        type: HOME_NAVIGATION_ITEM_TYPE.STARTUPS,
      },
    ]);

    setNavItemsB2C([
      {
        label: t("all"),
        url: "all",
        Icon: NavIconAll,
        type: HOME_NAVIGATION_ITEM_TYPE.ALL,
      },
      {
        label: t("executives"),
        url: "executives",
        Icon: NavIconExecutives,
        type: HOME_NAVIGATION_ITEM_TYPE.EXECUTIVES,
      },
      {
        label: t("corporates"),
        url: "corporates",
        Icon: NavIconCorporates,
        type: HOME_NAVIGATION_ITEM_TYPE.CORPORATES,
      },
      {
        label: t("interests"),
        url: "interests",
        Icon: NavIconInterests,
        type: HOME_NAVIGATION_ITEM_TYPE.INTERESTS,
      },
      {
        label: t("opportunities"),
        url: "opportunities",
        Icon: NavIconOpportunities,
        type: HOME_NAVIGATION_ITEM_TYPE.OPPORTUNITIES,
      },
    ]);
  }, [t]);

  const navigationItems = isB2CUser === null ? [] : isB2CUser ? navItemsB2C : navItems;

  return (
    <>
      <Flex justifyContentCenter>
        {navigationItems.map((item) => {
          return (
            <React.Fragment key={item.type}>
              <HomeNavigationItem item={item} />

              <Spacing pl="12" />

              {!isSmallerScreen && <NavigationItemCounter itemType={item.type} />}

              <Spacing pl="20" />
            </React.Fragment>
          );
        })}
      </Flex>
      <Divider />
    </>
  );
};

export default HomeNavigationBar;
