import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { HubContext } from "../../../modules/hub/state/hub.context";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";

interface ITopNewsHook {
  hubNewsFooterItems: IHubFooterItemData[] | null;
  loadingTopNews: boolean;
}

export const useTopNews = (): ITopNewsHook => {
  const hubContext = useContext(HubContext);

  const [hubNewsFooterItems] = useObservable(hubContext.hubNewsFooterItems$, null);

  const [loadingTopNews] = useObservable(hubContext.loadingTopNews$, true);

  return {
    hubNewsFooterItems,
    loadingTopNews,
  };
};
