import { IExecutive } from "../../../entities/executives/state/executive.model";
import { IExecutiveHubMarker } from "../../../entities/corporates/interfaces/hub-marker.interface";
import { IExecutiveMarkerData } from "../../../entities/executives/interfaces/executive-marker-data.interface";
import { geoLocationToString, stringToGeoLocation } from "../../hub/hooks/hub-markers.hook";
import { useContext } from "react";
import { ExecutiveHubContext } from "../state/executives-hub.context";
import { useObservable } from "@mindspace-io/react";
import { formatZipCode, getDefaultMapCoordinates } from "../../../helpers/map.helper";

export const mapExecutivesToHubMarkers = (executives: IExecutive[]): IExecutiveHubMarker[] => {
  const executivesGroupedByLocation: Map<string, IExecutiveMarkerData[]> = new Map();
  const defaultMapCoordinates = getDefaultMapCoordinates();

  executives.forEach((executive) => {
    // Search order to determine executive's coordinates
    // 1. own coordinates (geo_location)
    // 2. main company coordiantes (main_company_geo_location)
    // 3. default map coordinates (defaultMapCoordinates)
    // TODO: see what to do otherwise, for now use default map coordinates
    const locationString = executive.geo_location
      ? geoLocationToString(executive.geo_location)
      : executive.main_company_geo_location
      ? geoLocationToString(executive.main_company_geo_location)
      : geoLocationToString({
          lat: defaultMapCoordinates.lat,
          lon: defaultMapCoordinates.lng,
        });

    if (executivesGroupedByLocation.has(locationString)) {
      // For existing location key, append new executive into array
      executivesGroupedByLocation.set(locationString, [
        ...executivesGroupedByLocation.get(locationString),
        mapExecutiveIntoExecutiveMarkerData(executive),
      ]);
    } else {
      // Create new array and fill it with current corporate
      executivesGroupedByLocation.set(locationString, [
        mapExecutiveIntoExecutiveMarkerData(executive),
      ]);
    }
  });

  return createHubMarkersFromExecutivesGroupedByLocation(executivesGroupedByLocation);
};

const createHubMarkersFromExecutivesGroupedByLocation = (
  executivesGroupedByLocation: Map<string, IExecutiveMarkerData[]>,
): IExecutiveHubMarker[] => {
  const hubMarkers: IExecutiveHubMarker[] = [];

  executivesGroupedByLocation.forEach((value, key) => {
    hubMarkers.push({
      coordinates: stringToGeoLocation(key),
      executivesData: value,
    });
  });

  return hubMarkers;
};

export const mapExecutiveIntoExecutiveMarkerData = (
  executive: IExecutive,
): IExecutiveMarkerData => {
  if (!executive) return null;

  return {
    executiveId: executive.id,
    name: executive.full_name,
    // affinity: corporate.affinity_score,
    // successScore: corporate.success_score?.toString(),
    // promoterScore: corporate.promoter_score?.toString(),
    // address: buildCompanyAddress(
    //     corporate.street,
    //     corporate.zip_code,
    //     corporate.city
    // ),
    city: executive.city,
    // street: corporate.street,
    zipCode: formatZipCode(executive.city_zip_code),
    tags: executive.tags.map((tagWrapper) => tagWrapper.tag),
    wealth_score: executive.wealth_score,
    promoterScore: executive.promoter_score,
    management: executive.management,
    shareholder: executive.shareholder,
    main_company_id: executive.main_company_id,
    eeScore: executive.ee_score,
    wealthReach: executive.foundation_count,
    // news: 2,
    // relationships: 2,
  };
};

interface IExecutivesHubMarkersHook {
  loadingHubExecutives: boolean;
  executiveHubMarkers: IExecutiveHubMarker[];
}

export const useExecutiveHubMarkers = (): IExecutivesHubMarkersHook => {
  const executivesHubService = useContext(ExecutiveHubContext);

  const [loadingHubExecutives] = useObservable(
    executivesHubService.loadingHubExecutives$,
    null,
  );

  const [executiveHubMarkers] = useObservable(
    executivesHubService.hubExecutiveMarkers$,
    executivesHubService.getHubExecutiveMarkers(),
  );

  return {
    loadingHubExecutives,
    executiveHubMarkers,
  };
};
