import i18next from "i18next";
import { initReactI18next, getI18n, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//order is important, first element is default language
export const supportedLanguages = ["en", "de", "fr", "it"] as const;

export type ISupportedLanguages = typeof supportedLanguages[number];

type IResources = {
  [key in ISupportedLanguages]: { translation: { [key: string]: string } };
};

const resources: IResources = {
  en: {
    translation: {
      appName: "Axeed MOVE",

      // Login/Register/Forgot Password pages
      mobilizeYourSalesForce: "Mobilize your sales force",
      moveAhead: "move ahead",
      email: "E-mail",
      password: "Password",
      confirmPassword: "Confirm Password",
      forgotPassword: "Forgot Password?",
      loginIncorrectCredentials: "Incorrect email or password",
      showRegister: "Don't have an account? Sign Up!",
      showLogin: "Already have an account? Sign In!",
      login: "Login",
      register: "Register",
      submit: "Submit",
      rememberMe: "Remember Me",
      forgotPasswordInputLabel: "Please enter the e-mail address you use for login.",
      forgotPasswordResponseMessage:
        "An email with instructions for resetting the password has been sent to your email address, in case you have a valid account.",
      backToLogin: "Back to Login",
      send: "Send",
      fillInputField: "Please fill out this field",
      invalidEmailFormat: "Email address in invalid format",
      invalidPasswordFormat: "Invalid password format",
      passwordMismatch: "Password mismatch",

      // Header
      logout: "Log out",
      lastSearches: "Last Searches",
      searchPlaceholder: "Search for Corporates or Executives",
      goTo: "Go To ",
      search: "Search",
      searchResults: "Search results",
      goToHub: "Go to Hub",

      // Hub page
      noDataAvailableForCurrentUser: "No data available for current user",
      tryAgain: "Try again",
      hub: "HUB",
      salesRegionOf: "Sales region of",
      noHubCorporates: "No corporates match current region or filter",
      noHubExecutives: "No executives match current region or filter",
      filterResults: "Filter Results",
      results: "Results",
      top: "TOP",
      marketDrivers: "Market Drivers",
      startups: "Start Ups",
      anniversaries: "Anniversaries",
      bankruptcies: "Bankruptcies",
      companies: "Companies",
      relationships: "Relationships",
      potentialClients: "Potential clients",
      targets: "Targets",
      founding: "Founding",
      daysAgo: "Days ago",
      dayOfBankruptcy: "Day of bankruptcy",
      insolvencyOpened: "Insolvency Opened",
      hnwi: "HNWI",
      pb: "PB",
      privateBanking: "Private Banking",
      male: "Male",
      female: "Female",
      companyNews: "Company News",
      leads: "Leads",
      contactEvent: "Contact Event",
      marketInsight: "Market Insight",

      //VKB HUB Footer
      topLeads: "Top Leads",
      topClients: "Top Clients",
      industryTrends: "Industry Trends",
      milestoneText: "Celebrates {{milestone_year}}th anniversary in {{month}}.",

      // Hub sidebar list item
      noData: "No data",

      // Search screen navigation
      all: "All",
      corporates: "Corporates",
      executives: "Executives",
      executivesFilterGroup: "Executives",
      executivesCorporateManagement: "Executives",
      interests: "Interests",
      opportunities: "Opportunities",
      navStartups: "Start-Ups",

      // Search results & filtering
      enterTheCityOrZipCode: "Enter the city or zip code",
      searchResultsFor: "Search results for: ",
      clearAll: "Clear all",
      filter: "Filter",
      myFilters: "My Filters",
      saveSelection: "Tag & Save",
      noSearchResults: "No search results for given criteria",
      showAllResults: "Show all results",

      // Search list item
      company: "Company",
      name: "Name",
      productAffinity: "Product Affinity",
      affinity: "Affinity",
      industry: "Industry",

      // Corporate dossier
      corporateDossier: "Corporate Dossier",
      comparedTo: "Compared to",
      // comparedToLastYear: "Compared to last year",
      // comparedToLastTwoYears: "Compared to last two years",
      tagThisCompany: "TAG THIS COMPANY",
      // mainIndustry: "Main Industry",
      headcount: "Headcount",
      turnover: "Turnover",
      shareholders: "Shareholders",
      shareholder: "Shareholder",
      investment: "Investment",
      management: "Management",
      relationshipIndicator: "Business Relations",
      lookALikes: "Look a Likes",
      executiveLookALikes: "Look a Likes",
      productRecommendation: "Product recommendation",
      noResultsMatchCriteria: "No results match your search criteria",
      ownership: "Ownership",
      board: "Board",
      operative: "Operative",
      others: "Others",
      otherIndustries: "Other Industries",
      promoter: "Promoter",
      influencer: "Influencer",
      relations: "Relations",
      similarCompaniesTo: "Similar companies to",
      similarExecutivesTo: "Similar executives to",
      notes: "Notes",
      position: "Position",
      period: "Period",
      role: "Role",
      from: "From",
      to: "To",
      searchNewsPlaceholder: "Search for News",
      count: "Count",
      relationshipOverview: "Relationship Overview",
      undefined: "Undefined",
      today: "Today",
      foundationDate: "Foundation Date",
      controlling: "Controlling",
      minorities: "Minorities",
      shareSince: "Share since",
      shareUntil: "Share until",
      // levelOfParticipation: "Level of Participation",
      impuls: "Impuls",
      readMore: "Read more",
      open: "Open",
      close: "Close",
      factsheet: "Factsheet",
      industryClassification: "Industry Classification",
      corporateMission: "Corporate Mission",
      identifier: "Identifier",
      registrationNumber: "Registration Number",
      vatId: "VAT Id",
      commercialRegister: "Commercial Register",
      dunsNumber: "DUNS Number",
      until: "until",
      newNotePlaceholder: "New note",
      addToRoute: "Add to Route",
      person: "Person",
      unknown: "Unknown",

      // Executive dossier
      personDossier: "Person Dossier",
      career: "Career",
      tagThisPerson: "TAG THIS PERSON",
      years: "Years",
      year: "Year",
      entrepreneursAndExecutives: "Entrepreneurs  & Executives",

      // Shared on multiple screens
      trend: "Trend",
      promoterScore: "Promoter Score",
      successScore: "Success Score",
      wealthScore: "Wealth Score",
      foundationReach: "Foundation Reach",
      eAndE: "E&E",
      productTrends: "Product Trends",
      news: "News",
      category: "Category",
      mapNotAvailable: "Map not available",

      // Generic
      loading: "Loading...",
      noCompaniesMatchingTags: "There are no upcoming anniversaries.",
      noDataAvailable: "No data available",
      more: "More",
      date: "Date",
      new: "New",

      // Number measurement units
      k: "K",
      m: "M",
      b: "B",
      t: "T",

      // Tags
      tag: "Tag",
      untag: "Untag",
      createNewTag: "Create new Tag",
      tagFinallyDeleted: "Tag is finally deleted",
      confirm: "Confirm",
      cancel: "Cancel",
      tagNamePlaceholder: "Enter Tag Name",
      client: "Client",
      lead: "Lead",

      // Product Trends popup
      source: "Source",
      publishDate: "Publication date",

      // Three dot popup
      newsSubscription: "News Subscription",
      addToSelection: "Add to Selection",
      downloadDossier: "Download Dossier",

      // News popup
      noteArticle: "Note Article",
      markAsRead: "Mark as read",
      notInteresting: "Not interesting",
      delete: "Delete",

      // Company dossier - Industry sectors
      administration: "Administration",
      agriculture: "Agriculture",
      construction: "Construction",
      education: "Education",
      energySupply: "Energy supply",
      entertainment: "Entertainment",
      finance: "Finance",
      health: "Health",
      hospitality: "Hospitality",
      it: "IT",
      trade: "Trade",
      other: "Other",
      science: "Science",
      services: "Services",
      realEstate: "Real Estate",
      logistics: "Logistics",
      mining: "Mining",
      insurance: "Insurance",
      manufacturing: "Manufacturing",
      waterSupply: "Water supply",

      hubGoTo: "Hub",
      dossierGoTo: "Dossier",

      // Upload Contact
      uploadContactsTitle: "Upload your contacts",
      uploadContactsDescription: "Match and tag your company and business contacts",
      uploadContactsDetail:
        "Please download the template and fill the file with the contacts you would like to use in move. A separate file should be used for companies and business contacts. The file structure and the file name must be retained.",
      uploadCompleted: "Upload completed",
      uploadContactsFound: "contacts were found and tagged in move.",
      uploadContactsTag: "Please tag your contacts",

      // Label Status
      labelInsolvent: "Insolvent",
      labelLiquidation: "Liquidation",
      labelDeleted: "Deleted",
      labelMerged: "Merged",

      // PDF Specific
      insights: "Insights",
      mainIndustry: "Main Industry",
    },
  },
  de: {
    translation: {
      appName: "Axeed MOVE",

      // Login/Register/Forgot Password pages
      mobilizeYourSalesForce: "Mobilisieren Sie Ihren Vertrieb",
      moveAhead: "move ahead",
      email: "E-mail",
      password: "Passwort",
      confirmPassword: "Passwort bestätigen",
      forgotPassword: "Passwort vergessen?",
      loginIncorrectCredentials: "Ungültige E-Mail-Adresse oder falsches Passwort",
      showRegister: "Noch kein Benutzerkonto? Hier Registrieren!",
      showLogin: "Benutzerkonto vorhanden? Hier anmelden!",
      login: "Anmelden",
      register: "Registrieren",
      submit: "Senden",
      rememberMe: "Angemeldet bleiben",
      forgotPasswordInputLabel:
        "Bitte geben Sie die E-Mail-Adresse ein, die Sie für die Anmeldung verwenden.",
      forgotPasswordResponseMessage:
        "Eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet, für den Fall, dass Sie ein gültiges Konto haben.",
      backToLogin: "Zurück zur Anmeldung",
      send: "Senden",
      fillInputField: "Dieses Feld bitte ausfüllen",
      invalidEmailFormat: "Die E-Mail Adresse ist ungültig",
      invalidPasswordFormat: "Das Passwort ist ungültig",
      passwordMismatch: "Falsches Passwort",

      // Header
      logout: "Abmelden",
      lastSearches: "Letzte Suchanfragen",
      searchPlaceholder: "Suche nach Unternehmen oder Führungskräften",
      goTo: "Gehe zu ",
      search: "Suche",
      searchResults: "Suchergebnisse",
      goToHub: "Zurück zum Hub",

      // Hub page
      noDataAvailableForCurrentUser: "Für diesen Benutzer sind keine Daten verfügbar",
      tryAgain: "Erneut versuchen",
      hub: "HUB",
      salesRegionOf: "Verkaufsregion von",
      noHubCorporates:
        "Keine Unternehmen in der Verkaufsregion oder auf Basis der aktuellen Filterkriterien gefunden",
      noHubExecutives:
        "Keine Führungskräfte in der Verkaufsregion oder auf Basis der aktuellen Filterkriterien gefunden",
      filterResults: "Filterergebnisse",
      results: "Ergebnisse",
      top: "TOP",
      marketDrivers: "Marktindikatoren",
      startups: "Neugründungen",
      bankruptcies: "Insolvenzen",
      companies: "Unternehmen",
      anniversaries: "Jubiläen",
      relationships: "Beziehungen",
      potentialClients: "Potenziale",
      targets: "Zielkunden",
      founding: "Gründung vor", // original translation is "Gründung", modified because of design for startups on footer
      daysAgo: "Tagen", // original translation is "vor Tagen", modified because of design for startups on footer
      dayOfBankruptcy: "In Insolvenz seit",
      insolvencyOpened: "Insolvenz Eröffnet",
      hnwi: "HNWI",
      pb: "PB",
      privateBanking: "Private Banking",
      male: "M\u00e4nnlich",
      female: "Weiblich",
      companyNews: "Unternehmens-News",
      leads: "Leads",
      contactEvent: "Contact Event",
      marketInsight: "Market Insight",

      //VKB HUB Footer
      topLeads: "Top Leads",
      topClients: "Top Kunden",
      industryTrends: "Branchentrends",
      milestoneText: "Feiert {{milestone_year}}-jähriges Jubiläum im {{month}}",

      // Hub sidebar list item
      noData: "Keine Daten verfügbar",

      // Search screen navigation
      all: "Alle",
      corporates: "Unternehmen",
      executives: "Führungskräfte",
      executivesFilterGroup: "Führungskräfte",
      executivesCorporateManagement: "Geschäftsleitung",
      interests: "Interessen",
      opportunities: "Geschäftschancen",
      navStartups: "Neugründungen",

      // Search results & filtering
      enterTheCityOrZipCode: "Geben Sie die Stadt oder Postleitzahl ein",
      searchResultsFor: "Suchergebnisse zu: ",
      clearAll: "Alle löschen",
      filter: "Filter",
      myFilters: "Meine Filter",
      saveSelection: "Selektion speichern",
      noSearchResults: "Die definierten Filterkriterien führen zu keinem Suchergebnis",
      showAllResults: "Alle Ergebnisse anzeigen",

      // Search list item
      company: "Unternehmen",
      name: "Name",
      productAffinity: "Produkt-Affinität",
      affinity: "Affinität",
      industry: "Branche",

      // Corporate dossier
      corporateDossier: "Unternehmens-Dossier",
      comparedTo: "Gegenüber",
      // comparedToLastYear: "Gegenüber dem Vorjahr",
      // comparedToLastTwoYears: "Im Vergleich zu den letzten zwei Jahren",
      tagThisCompany: "Unternehmen markieren",
      // mainIndustry: "Schwerpunkt-Industrie",
      headcount: "Beschäftigte",
      turnover: "Umsatz",
      shareholders: "Anteilseigner",
      shareholder: "Anteilseigner",
      investment: "Beteiligungen",
      management: "Management",
      relationshipIndicator: "Geschäftsbeziehungen",
      lookALikes: "Ähnliche Unternehmen",
      executiveLookALikes: "Ähnliche Führungskräfte",
      productRecommendation: "Produktempfehlung",
      noResultsMatchCriteria: "Ihre Suchkriterien führen zu keinem Ergebnis",
      ownership: "Inhaber",
      board: "Aufsichtsgremium",
      operative: "Operativ",
      others: "Sonstige",
      otherIndustries: "Other industries",
      promoter: "Promotor",
      influencer: "Impulsgeber",
      relations: "Geschäftsbeziehungen",
      similarCompaniesTo: "Ähnliche Unternehmen zu",
      similarExecutivesTo: "Ähnliche Führungskräfte zu",
      notes: "Notizen",
      position: "Position",
      period: "Zeitraum",
      role: "Rolle",
      from: "Von",
      to: "Bis",
      searchNewsPlaceholder: "Suche nach News",
      count: "Zähler",
      relationshipOverview: "Beziehungsübersicht",
      undefined: "Nicht definiert",
      today: "Heute",
      foundationDate: "Gründungsdatum",
      controlling: "Mehrheit",
      minorities: "Minderheit",
      shareSince: "Beteiligung seit",
      shareUntil: "Beteiligung bis",
      // levelOfParticipation: "Beteiligungshöhe",
      impuls: "Impuls",
      readMore: "Details",
      open: "Öffnen",
      close: "Schliessen",
      factsheet: "Factsheet",
      industryClassification: "Branchenzuordnung",
      corporateMission: "Unternehmenszweck",
      identifier: "Unternehmensidentifikatoren",
      registrationNumber: "Handelsregisternummer",
      vatId: "Mehrwertsteuer ID",
      commercialRegister: "Handelsregister",
      dunsNumber: "DUNS Nummer",
      until: "bis",
      newNotePlaceholder: "Neue Notiz",
      addToRoute: "Zur Route hinzufügen",
      person: "Person",
      unknown: "Unbekannt",

      // Executive dossier
      personDossier: "Manager Dossier",
      career: "Karriere",
      tagThisPerson: "PERSON MARKIEREN",
      years: "Jahre",
      year: "Jahr",
      entrepreneursAndExecutives: "Entrepreneurs  & Executives",

      // Shared on multiple screens
      trend: "Trend",
      promoterScore: "Promotor Score",
      successScore: "Success Score",
      productTrends: "Produkt-Trends",
      wealthScore: "Wealth Score",
      foundationReach: "Foundation Reach",
      eAndE: "E&E",
      news: "News",
      category: "Kategorie",
      mapNotAvailable: "Map nicht verfügbar",

      // Generic
      loading: "Daten werden geladen...",
      noCompaniesMatchingTags: "Es stehen keine Jubiläen an",
      noDataAvailable: "Keine Daten verfügbar",
      more: "Mehr",
      date: "Datum",
      new: "Neu",

      // Number measurement units
      k: "Tsd.",
      m: "Mio.",
      b: "Mrd.",
      t: "Trill.",

      // Tags
      tag: "Markieren",
      untag: "Entmarkieren",
      createNewTag: "Neue Markierung",
      tagFinallyDeleted: "Markierung wird endgültig gelöscht",
      confirm: "Bestätigen",
      cancel: "Abbrechen",
      tagNamePlaceholder: "Bezeichnung",
      client: "Kunde",
      lead: "Lead",

      // Product Trends popup
      source: "Quelle",
      publishDate: "Datum der Veröffentlichung",

      // Three dot popup
      newsSubscription: "News abonnieren",
      addToSelection: "Zu Selektion hinzufügen",
      downloadDossier: "Dossier herunterladen",

      // News popup
      noteArticle: "Artikel merken",
      markAsRead: "Als gelesen markieren",
      notInteresting: "Nicht interessant",
      delete: "Löschen",

      // Company dossier - Industry sectors
      administration: "Administration",
      agriculture: "Landwirtschaft",
      construction: "Bau",
      education: "Bildung",
      energySupply: "Energieversorgung",
      entertainment: "Unterhaltung",
      finance: "Finanzen",
      health: "Gesundheitswesen",
      hospitality: "Gastgewerbe",
      it: "IT",
      trade: "Handel",
      other: "Sonstige",
      science: "Wissenschaft",
      services: "Dienstleistungen",
      realEstate: "Immobilien",
      logistics: "Logistik",
      mining: "Bergbau",
      insurance: "Versicherung",
      manufacturing: "Fabrikation",
      waterSupply: "Wasserversorgung",

      hubGoTo: "Hub",
      dossierGoTo: "Dossier",

      // Upload Contact
      uploadContactsTitle: "Kontakte hochladen",
      uploadContactsDescription: "Abgleich und Markierung Ihrer Firmen- und Personenkontakte",
      uploadContactsDetail:
        "Bitte laden Sie das Template herunter und befüllen die Datei mit den Kontakten, welche Sie in move nutzen möchten. Für Firmen und Ansprechpersonen, ist jeweils eine separate Datei zu verwenden. Die Dateistruktur sowie der Dateiname, müssen zwingend beibehalten werden.",
      uploadCompleted: "Hochladen abgeschlossen",
      uploadContactsFound: "Kontakte wurden in move gefunden und markiert.",
      uploadContactsTag: "Bitte Kontakte markieren",

      // Label Status
      labelInsolvent: "Insolvent",
      labelLiquidation: "Liquidation",
      labelDeleted: "Erloschen",
      labelMerged: "Fusioniert",

      // PDF Specific
      insights: "Unternehmensfakten",
      mainIndustry: "Hauptbranche",
    },
  },
  /*eslint quote-props: ["error", "as-needed", { "numbers": true }]*/

  fr: {
    translation: {
      appName: "Axeed MOVE",

      // Login/Register/Forgot Password pages
      mobilizeYourSalesForce: "Mobilisez votre force de vente",
      moveAhead: "move ahead",
      email: "E-mail",
      password: "Mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      forgotPassword: "Mot de passe oublié?",
      loginIncorrectCredentials: "Adresse e-mail non valable ou mot de passe incorrect",
      showRegister: "Pas encore de compte utilisateur? S’enregistrer ici!",
      showLogin: "Y a-t-il un compte utilisateur? Se connecter ici!",
      login: "Se connecter",
      register: "S’enregistrer",
      submit: "Envoyer",
      rememberMe: "Rester connecté(e)",
      forgotPasswordInputLabel:
        "Veuillez saisir l’adresse e-mail que vous utilisez pour vous connecter.",
      forgotPasswordResponseMessage:
        "Un e-mail contenant des instructions de réinitialisation du mot de passe a été envoyé à votre adresse e-mail si vous avez un compte valide.",
      backToLogin: "Retour à la connexion",
      send: "Envoyer",
      fillInputField: "Merci de compléter ce champ",
      invalidEmailFormat: "L’adresse e-mail n’est pas valable",
      invalidPasswordFormat: "Le mot de passe est non valide",
      passwordMismatch: "Mot de passe incorrect",

      // Header
      logout: "Se déconnecter",
      lastSearches: "Dernières recherches",
      searchPlaceholder: "Recherche d’entreprises ou de supérieur(e)s",
      goTo: "Aller à ",
      search: "Recherche",
      searchResults: "Résultats de la recherche",
      goToHub: "Go to Hub",

      // Hub page
      noDataAvailableForCurrentUser: "Aucune donnée disponible pour cet utilisateur",
      tryAgain: "Réessayer",
      hub: "HUB",
      salesRegionOf: "Région de vente de",
      noHubCorporates:
        "Aucune entreprise trouvée dans la région de vente ou sur la base des critères de filtrage actuels",
      noHubExecutives:
        "Aucun(e) supérieur(e) trouvé(e) dans la région de vente ou sur la base des critères de filtrage actuels",
      filterResults: "Résultats du filtrage",
      results: "Résultats",
      top: "TOP",
      marketDrivers: "Indicateurs de marché",
      startups: "Créations d’entreprises",
      bankruptcies: "Insolvabilités",
      companies: "Entreprise",
      relationships: "Relations",
      potentialClients: "Potentiels",
      targets: "Clients cibles",
      founding: "Fondation avant",
      daysAgo: "Jours",
      dayOfBankruptcy: "En situation d’insolvabilité depuis",
      insolvencyOpened: "Procédure d’insolvabilité ouverte",
      hnwi: "HNWI",
      pb: "PB",
      privateBanking: "Private Banking",
      male: "Homme",
      female: "Femme",
      companyNews: "Actualités de l’entreprise",
      leads: "Leads",
      contactEvent: "Contact Event",
      marketInsight: "Insight de marché",

      //VKB HUB Footer
      topLeads: "Top Leads",
      topClients: "Meilleurs clients",
      industryTrends: "Tendances sectorielles",

      // Hub sidebar list item
      noData: "Aucune donnée disponible",

      // Search screen navigation
      all: "Toutes",
      corporates: "Entreprise",
      executives: "Supérieur(e)s",
      executivesFilterGroup: "Supérieur(e)s",
      executivesCorporateManagement: "Direction générale",
      interests: "Intérêts",
      opportunities: "Opportunités d’affaires",
      navStartups: "Créations d’entreprises",

      // Search results & filtering
      enterTheCityOrZipCode: "Entrez la ville ou le code postal",
      searchResultsFor: "Résultats de la recherche pour: ",
      clearAll: "Tout effacer",
      filter: "Filtre",
      myFilters: "Mes filtres",
      saveSelection: "Sauvegarder la sélection",
      noSearchResults:
        "Les critères de filtrage définis ne donnent aucun résultat de recherche",
      showAllResults: "Afficher tous les résultats",

      // Search list item
      company: "Entreprise",
      name: "Nom",
      productAffinity: "Affinité produit",
      affinity: "Affinité",
      industry: "Branche",

      // Corporate dossier
      corporateDossier: "Dossier d’entreprise",
      comparedTo: "Interlocuteur",
      // comparedToLastYear: "Compared to last year",
      // comparedToLastTwoYears: "Compared to last two years",
      tagThisCompany: "Marquer l’entreprise",
      // mainIndustry: "Main Industry",
      headcount: "Employés",
      turnover: "Chiffre d’affaires",
      shareholders: "Actionnaires",
      shareholder: "Actionnaires",
      investment: "Participations",
      management: "Management",
      relationshipIndicator: "Indice des relations d’affaires",
      lookALikes: "Entreprises similaires",
      executiveLookALikes: "Supérieur(e)s similaires",
      productRecommendation: "Recommandation de produit",
      noResultsMatchCriteria: "Vos critères de recherche ne donnent aucun résultat",
      ownership: "Propriétaires",
      board: "Conseil de surveillance prudentielle",
      operative: "Opérationnel",
      others: "Autres",
      otherIndustries: "Autres Industries",
      promoter: "Promoteur",
      influencer: "Donneurs d’impulsions",
      relations: "Relations d’affaires",
      similarCompaniesTo: "Entreprises similaires à",
      similarExecutivesTo: "Supérieur(e)s similaires à",
      notes: "Notes",
      position: "Position",
      period: "Période",
      role: "Rôle",
      from: "Du",
      to: "Au",
      searchNewsPlaceholder: "Recherche en fonction des actualités",
      count: "Compteur",
      relationshipOverview: "Aperçu des relations",
      undefined: "Non défini",
      today: "Aujourd’hui",
      foundationDate: "Date de fondation",
      controlling: "Majorité",
      minorities: "Minorité",
      shareSince: "Participation depuis",
      shareUntil: "Participation jusqu’à",
      // levelOfParticipation: "Level of Participation",
      impuls: "Moteur",
      readMore: "Détails",
      open: "Ouvrir",
      close: "Fermer",
      factsheet: "Factsheet",
      industryClassification: "Attribution à une branche",
      corporateMission: "But de l’entreprise",
      identifier: "Identifiants d’entreprises",
      registrationNumber: "Numéro immatriculation au registre du commerce",
      vatId: "ID du la taxe sur la valeur ajoutée",
      commercialRegister: "Registre du commerce",
      dunsNumber: "Numéro DUNS",
      until: "jusqu’à",
      newNotePlaceholder: "Nouvelle note",
      addToRoute: "Ajouter à l’itinéraire",
      person: "Personne",
      unknown: "Inconnue",

      // Executive dossier
      personDossier: "Dossier Manager",
      career: "Carrière",
      tagThisPerson: "MARQUER LA PERSONNE",
      years: "Ans",
      year: "Année",
      entrepreneursAndExecutives: "Entrepreneurs et chefs d’entreprise",

      // Shared on multiple screens
      trend: "Tendance",
      promoterScore: "Score du promoteur",
      successScore: "Score de réussite",
      wealthScore: "Score de richesse",
      foundationReach: "Foundation Reach",
      eAndE: "E&E",
      productTrends: "Tendances produits",
      news: "Actualités",
      category: "Catégorie",
      mapNotAvailable: "Carte non disponible",

      // Generic
      loading: "Chargement des données en cours......",
      noDataAvailable: "Aucune donnée disponible",
      more: "Plus",
      date: "Date",
      new: "Nouveau",

      // Number measurement units
      k: "Milliers",
      m: "Millions",
      b: "Milliards",
      t: "Billions",

      // Tags
      tag: "Sélectionner",
      untag: "Désélectionner",
      createNewTag: "Nouvelle sélection",
      tagFinallyDeleted: "La sélection est définitivement supprimée",
      confirm: "Confirmer",
      cancel: "Annuler",
      tagNamePlaceholder: "Désignation",
      client: "Client",
      lead: "Lead",

      // Product Trends popup
      source: "Source",
      publishDate: "Date de publication",

      // Three dot popup
      newsSubscription: "S’abonner aux actualités",
      addToSelection: "Ajouter à la sélection",
      downloadDossier: "Télécharger le dossier",

      // News popup
      noteArticle: "Noter l’article",
      markAsRead: "Marquer comme lu",
      notInteresting: "Pas intéressant",
      delete: "Supprimer",

      // Company dossier - Industry sectors
      administration: "Administration",
      agriculture: "Agriculture",
      construction: "Construction",
      education: "Formation",
      energySupply: "Approvisionnement en énergie",
      entertainment: "Divertissement",
      finance: "Finances",
      health: "Santé",
      hospitality: "Hôtellerie/restauration",
      it: "IT",
      trade: "Commerce",
      other: "Autres",
      science: "Sciences",
      services: "Services",
      realEstate: "Immobilier",
      logistics: "Logistique",
      mining: "Industrie minière",
      insurance: "Assurance",
      manufacturing: "Fabrication",
      waterSupply: "Approvisionnement en eau",

      hubGoTo: "Hub",
      dossierGoTo: "Dossier",

      // Upload Contact
      uploadContactsTitle: "Télécharger des contacts",
      uploadContactsDescription:
        "Comparaison et sélection de vos contacts d’entreprise et de personnes",
      uploadContactsDetail:
        "Veuillez télécharger le modèle et remplir le fichier avec les contacts que vous souhaitez utiliser dans move. Un fichier séparé doit être utilisé pour les entreprises et les interlocuteurs. La structure et le nom du fichier doivent impérativement être conservés.",
      uploadCompleted: "Téléchargement terminé",
      uploadContactsFound: "Des contacts ont été trouvés et sélectionnés dans move.",
      uploadContactsTag: "Veuillez sélectionner les contacts",

      // Label Status
      labelInsolvent: "Insolvable",
      labelLiquidation: "Liquidation",
      labelDeleted: "Éteints",
      labelMerged: "Fusionnés",

      // PDF Specific
      insights: "Connaissances",
      mainIndustry: "Industrie principale",
    },
  },
  it: {
    translation: {
      appName: "Axeed MOVE",

      // Login/Register/Forgot Password pages
      mobilizeYourSalesForce: "Mobilita la tua forza vendita",
      moveAhead: "move ahead",
      email: "E-mail",
      password: "Password",
      confirmPassword: "Confermare la password",
      forgotPassword: "Password dimenticata?",
      loginIncorrectCredentials: "Indirizzo e-mail non valido o password errata",
      showRegister: "Non avete ancora un account utente? Registrarsi qui!",
      showLogin: "Account utente disponibile? Login qui!",
      login: "Login",
      register: "Registrarsi",
      submit: "Inviare",
      rememberMe: "Rimanere connessi",
      forgotPasswordInputLabel:
        "Si prega di inserire l’indirizzo e-mail utilizzato per il login.",
      forgotPasswordResponseMessage:
        "Un’e-mail con le istruzioni per ripristinare la password è stata inviata al vostro indirizzo di posta elettronica, nel caso abbiate un account valido.",
      backToLogin: "Tornare alla registrazione",
      send: "Inviare",
      fillInputField: "Si prega di compilare questo campo",
      invalidEmailFormat: "L’indirizzo e-mail non è valido",
      invalidPasswordFormat: "La password non è valida",
      passwordMismatch: "Password errata",

      // Header
      logout: "Logout",
      lastSearches: "Ultime ricerche",
      searchPlaceholder: "Ricerca imprese o dirigenti",
      goTo: "Vai a ",
      search: "Ricerca",
      searchResults: "Risultati della ricerca",
      goToHub: "Go to Hub",

      // Hub page
      noDataAvailableForCurrentUser: "Nessun dato disponibile per questo utente",
      tryAgain: "Riprovare",
      hub: "HUB",
      salesRegionOf: "Regione di vendita di",
      noHubCorporates:
        "Nessuna impresa trovata nella regione di vendita o in base ai criteri di filtro applicati",
      noHubExecutives:
        "Nessun dirigente trovato nella regione di vendita o in base ai criteri di filtro applicati",
      filterResults: "Risultati filtrati",
      results: "Risultati",
      top: "TOP",
      marketDrivers: "Indicatori di mercato",
      startups: "Costituzione società",
      bankruptcies: "Insolvenze",
      companies: "Imprese",
      relationships: "Relazioni",
      potentialClients: "Potenziali",
      targets: "Clienti target",
      founding: "Costituzione",
      daysAgo: "giorni fa",
      dayOfBankruptcy: "Insolvente dal",
      insolvencyOpened: "Apertura insolvenza",
      hnwi: "HNWI",
      pb: "PB",
      privateBanking: "Private Banking",
      male: "Maschile",
      female: "Femminile",
      companyNews: "News sull’azienda",
      leads: "Leads",
      contactEvent: "Contact Event",
      marketInsight: "Market Insight",

      //VKB HUB Footer
      topLeads: "Top Leads",
      topClients: "Migliori clienti",
      industryTrends: "Tendenze settoriali",

      // Hub sidebar list item
      noData: "Nessun dato disponibile",

      // Search screen navigation
      all: "Tutti",
      corporates: "Imprese",
      executives: "Dirigenti",
      executivesFilterGroup: "Dirigenti",
      executivesCorporateManagement: "Direzione generale",
      interests: "Interessi",
      opportunities: "Opportunità commerciali",
      navStartups: "Costituzione società",

      // Search results & filtering
      enterTheCityOrZipCode: "Inserisci la città o il CAP",
      searchResultsFor: "Risultati della ricerca su: ",
      clearAll: "Cancellare tutti",
      filter: "Filtro",
      myFilters: "I miei filtri",
      saveSelection: "Salvare selezione",
      noSearchResults: "I criteri di filtro definiti non producono alcun risultato di ricerca",
      showAllResults: "Mostrare tutti i risultati",

      // Search list item
      company: "Imprese",
      name: "Cognome",
      productAffinity: "Affinità dei prodotti",
      affinity: "Affinità",
      industry: "Settore",

      // Corporate dossier
      corporateDossier: "Dossier aziendale",
      comparedTo: "Interlocutore",
      // comparedToLastYear: "Compared to last year",
      // comparedToLastTwoYears: "Compared to last two years",
      tagThisCompany: "Selezionare imprese",
      // mainIndustry: "Main Industry",
      headcount: "Collaboratori",
      turnover: "Fatturato",
      shareholders: "Azionisti",
      shareholder: "Azionisti",
      investment: "Partecipazioni",
      management: "Management",
      relationshipIndicator: "Indice relazioni d’affari",
      lookALikes: "Imprese simili",
      executiveLookALikes: "Dirigenti simili",
      productRecommendation: "Raccomandazione prodotto",
      noResultsMatchCriteria: "I criteri di ricerca non producono alcun risultato",
      ownership: "Titolare",
      board: "Consiglio di vigilanza",
      operative: "Operativa",
      others: "Altro",
      otherIndustries: "Altre Industrie",
      promoter: "Promotore",
      influencer: "Chi ha dato l’impulso",
      relations: "Relazioni d’affari",
      similarCompaniesTo: "Imprese simili a",
      similarExecutivesTo: "Dirigenti simili a",
      notes: "Appunti",
      position: "Posizione",
      period: "Periodo",
      role: "Ruolo",
      from: "Dal",
      to: "Al",
      searchNewsPlaceholder: "Ricerca news",
      count: "Contatore",
      relationshipOverview: "Riepilogo relazioni",
      undefined: "Non definito",
      today: "Oggi",
      foundationDate: "Data costituzione",
      controlling: "Maggioranza",
      minorities: "Minoranza",
      shareSince: "Partecipazione dal",
      shareUntil: "Partecipazione fino al",
      // levelOfParticipation: "Level of Participation",
      impuls: "Impulso",
      readMore: "Dettagli",
      open: "Aprire",
      close: "Chiudere",
      factsheet: "Factsheet",
      industryClassification: "Attribuzione settoriale",
      corporateMission: "Oggetto sociale",
      identifier: "Identificatori aziendali",
      registrationNumber: "N. del registro di commercio",
      vatId: "Imposta sul valore aggiunto ID",
      commercialRegister: "Registro di commercio",
      dunsNumber: "Numero DUNS",
      until: "fino a",
      newNotePlaceholder: "Nuovo appunto",
      addToRoute: "Aggiungere alla rotta",
      person: "Persona",
      unknown: "Sconosciuta",

      // Executive dossier
      personDossier: "Dossier manageriale",
      career: "Carriera",
      tagThisPerson: "SELEZIONARE PERSONA",
      years: "Anni",
      year: "Anno",
      entrepreneursAndExecutives: "Entrepreneurs  & Executives",

      // Shared on multiple screens
      trend: "Tendenza",
      promoterScore: "Promotor Score",
      successScore: "Success Score",
      wealthScore: "Wealth Score",
      foundationReach: "Foundation Reach",
      eAndE: "E&E",
      productTrends: "Tendenze prodotti",
      news: "News",
      category: "Categoria",
      mapNotAvailable: "Mappa non disponibile",

      // Generic
      loading: "Caricamento dati...",
      noDataAvailable: "Nessun dato disponibile",
      more: "Più",
      date: "Data",
      new: "Nuovo",

      // Number measurement units
      k: "Migliaia",
      m: "Milioni",
      b: "Miliardi",
      t: "Trilioni",

      // Tags
      tag: "Selezionare",
      untag: "Deselezionare",
      createNewTag: "Nuova selezione",
      tagFinallyDeleted: "La selezione viene cancellata definitivamente",
      confirm: "Confermare",
      cancel: "Annullare",
      tagNamePlaceholder: "Denominazione",
      client: "Cliente",
      lead: "Lead",

      // Product Trends popup
      source: "Fonte",
      publishDate: "Data di pubblicazione",

      // Three dot popup
      newsSubscription: "Abbonarsi alle news",
      addToSelection: "Aggiungere alla selezione",
      downloadDossier: "Scaricare il dossier",

      // News popup
      noteArticle: "Ricordare l’articolo",
      markAsRead: "Contrassegnare come già letto",
      notInteresting: "Non interessante",
      delete: "Cancellare",

      // Company dossier - Industry sectors
      administration: "Amministrazione",
      agriculture: "Agricoltura",
      construction: "Edilizia",
      education: "Formazione",
      energySupply: "Approvvigionamento energetico",
      entertainment: "Intrattenimento",
      finance: "Finanze",
      health: "Sanità",
      hospitality: "Settore alberghiero",
      it: "IT",
      trade: "Commercio",
      other: "Altro",
      science: "Scienza",
      services: "Servizi",
      realEstate: "Immobili",
      logistics: "Logistica",
      mining: "Industria mineraria",
      insurance: "Assicurazione",
      manufacturing: "Fabbricazione",
      waterSupply: "Approvigionamento idrico",

      hubGoTo: "Hub",
      dossierGoTo: "Dossier",

      // Upload Contact
      uploadContactsTitle: "Caricare contatti",
      uploadContactsDescription:
        "Allineamento e selezione dei vostri contatti aziendali e personali",
      uploadContactsDetail:
        "Scaricare il template e compilare il file con i contatti che si desiderano utilizzare in move. Per le aziende e gli interlocutori deve essere utilizzato un file separato. Struttura e nome del file devono rimanere invariati.",
      uploadCompleted: "Caricamento completato",
      uploadContactsFound: "Contatti trovati e selezionati in move.",
      uploadContactsTag: "Selezionare i contatti",

      // Label Status
      labelInsolvent: "Insolvente",
      labelLiquidation: "In liquidazione",
      labelDeleted: "Estinta",
      labelMerged: "Fusionato",

      // PDF Specific
      insights: "Approfondimenti",
      mainIndustry: "Settore principale",
    },
  },
};

/**
 * React Hook which detects browser language in
 * format en-GB, strips the "-GB" part and leaves only "en"
 */
export const useLngString = (): ISupportedLanguages => {
  const { i18n } = useTranslation();

  if (!i18n) return supportedLanguages[0];

  const lng = i18n.language?.split("-")[0];
  if (!lng) return supportedLanguages[0];

  return lng as ISupportedLanguages;
};

/**
 * Detects browser language in format en-GB,
 * strips the "-GB" part and leaves only "en"
 *
 * If you need to detect the language inside of a
 * React component, use the hook above instead
 */
export function getLngString(): ISupportedLanguages {
  const i18n = getI18n();
  if (!i18n) return supportedLanguages[0];

  const lng = i18n.language.split("-")[0];
  if (!lng) return supportedLanguages[0];

  return lng as ISupportedLanguages;
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "de",
    fallbackLng: "en",
    supportedLngs: supportedLanguages as unknown as string[],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["sessionStorage", "navigator"],
      caches: [],
    },
  });

const t = i18next.t.bind(i18next);
export { t };

export default i18next;
