import { createContext } from "react";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { executivesFacade } from "../../executives/state/executive.context";
import {
  ExecutivesFiltersFacade,
  IExecutivesFiltersContext,
} from "./executives-filters.facade";
import { executivesFiltersQuery } from "./executives-filters.query";
import { executivesFiltersStore } from "./executives-filters.store";

export const executivesFiltersFacade = new ExecutivesFiltersFacade(
  executivesFiltersStore,
  executivesFiltersQuery,
  filtersApiService,
  executivesFacade,
);

export const ExecutivesFiltersContext =
  createContext<IExecutivesFiltersContext>(executivesFiltersFacade);
