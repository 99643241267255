import { EntityUIQuery, QueryEntity } from "@datorama/akita";
import {
  OpportunitiesFiltersState,
  opportunitiesFiltersStore,
  OpportunitiesFiltersStore,
  OpportunitiesFiltersUIState,
} from "./opportunities-filters.store";

export class OpportunitiesFiltersQuery extends QueryEntity<OpportunitiesFiltersState> {
  ui: EntityUIQuery<OpportunitiesFiltersUIState>;

  constructor(protected store: OpportunitiesFiltersStore) {
    super(store);
    this.createUIQuery();
  }
}

export const opportunitiesFiltersQuery = new OpportunitiesFiltersQuery(
  opportunitiesFiltersStore,
);
