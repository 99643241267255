import styled from "styled-components";
import { Popup } from "react-leaflet";
import L from "leaflet";

interface IProps {
  isSmallerScreen?: boolean;
}

export const StyledPopup = styled(Popup).attrs((props: any) => ({
  closeButton: false,
  offset: L.point(props.isSmallerScreen ? 190 : 230, props.isSmallerScreen ? 220 : 260), // Positions popup on the right side of the marker
}))<IProps>`
  .leaflet-popup-content {
    position: relative;
    width: ${(props) => (props.isSmallerScreen ? "280px" : "350px")} !important;
    height: ${(props) => (props.isSmallerScreen ? "230px" : "270px")} !important;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .leaflet-popup-content::-webkit-scrollbar {
    display: none;
  }

  .leaflet-popup-content p {
    margin: 0px;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 5px;
  }

  /* Styles for positioning popup arrow on the left side of the popup container */

  .leaflet-popup-tip-container {
    width: 20px;
    height: 40px;
    top: 40%;
    transform: translate(0, calc(-40% - 20px));
    left: -5%;
    margin-left: 0px;
  }

  .leaflet-popup-tip {
    margin-top: 10px;
    margin-left: 11px;
  }
`;
