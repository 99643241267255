import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ShareSinceUntil.module.css";

interface IProps {
  shareSinceDate: string;
  shareUntilDate: string;
}

export const ShareSinceUntil = ({ shareSinceDate, shareUntilDate }: IProps) => {
  const { t } = useTranslation();

  if (true)
    return (
      <div className={styles.container}>
        <div className={styles.sinceLabel}>{t("shareSince")}</div>
        <div className={styles.untilLabel}>{t("shareUntil")}</div>

        <div className={styles.sinceBulletContainer}>
          <div className={styles.bullet}></div>
        </div>
        <div className={styles.durationBar}></div>
        <div className={styles.durationLineContainer}>
          <div className={styles.durationLine}></div>
        </div>
        <div className={styles.untilBulletContainer}>
          <div className={styles.bullet}></div>
        </div>
        <div className={styles.sinceValue}>{shareSinceDate}</div>
        <div className={styles.untilValue}>{shareUntilDate ? shareUntilDate : t("today")}</div>
      </div>
    );
};
