import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ICorporate } from "../models/corporate.model";
import { CorporatesContext } from "../state/corporates.context";

interface ActiveCorporateHook {
  activeCorporate: ICorporate;
  setActiveCorporate: (corporateId: string) => void;
}

export function useActiveCorporate(): ActiveCorporateHook {
  const corporatesService = useContext(CorporatesContext);
  const [activeCorporate] = useObservable(corporatesService.activeCorporate$, null);

  return {
    activeCorporate,
    setActiveCorporate: (...args) => corporatesService.setActiveCorporate(...args),
  };
}
