import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ICorporate } from "../../corporates/models/corporate.model";
import { CorporatesFiltersContext } from "../state/corporates-filters.context";
import { EXPORT_TYPE } from "../../../shared/enums/export-type.enum";

interface UseCorporatesSearchResponseHook {
  searchResultTotalCount: number;
  searchResults: ICorporate[];
  fetchMore(): void;
  exportResults(type: EXPORT_TYPE): void;
  assignTag(tagId: number): Promise<void>;
  loading: boolean;
}

export function useCorporatesSearchResponse(): UseCorporatesSearchResponseHook {
  const corporatesFiltersService = useContext(CorporatesFiltersContext);

  const [searchResultTotalCount] = useObservable(
    corporatesFiltersService.searchResultTotalCount$,
    null,
  );

  const [searchResultIds] = useObservable(corporatesFiltersService.searchResultIds$, []);

  const [searching] = useObservable(corporatesFiltersService.searching$, true);

  const fetchMore = () => corporatesFiltersService.searchNextPage();

  const exportResults = (type: EXPORT_TYPE) => corporatesFiltersService.export(type);

  const assignTag = async (tagId: number) => await corporatesFiltersService.assignTag(tagId);

  return {
    searchResultTotalCount,
    searchResults: corporatesFiltersService.getCorporates(searchResultIds),
    fetchMore,
    exportResults,
    assignTag,
    loading: searching,
  };
}
