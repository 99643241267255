import React, { useContext, useMemo } from "react";
import format from "date-fns/format";
import styles from "./styles.module.css";
import { ReactComponent as DateIcon } from "../../../../../../assets/DateIcon.svg";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../components/spacing/component";
import { ICorporate } from "../../../../../../entities/corporates/models/corporate.model";
import {
  buildCompanyAddress,
  calculateMapCenterFromCompanyCoordinates,
} from "../../../../../../helpers/map.helper";
import ExecutivesTags from "../../../../ExecutivesTags/ExecutivesTags";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import StarRating from "../../../../../../components/other/StarRating/StarRating";
import { useSession } from "../../../../../../hooks/session.hook";
import useAppColors from "../../../../../../hooks/app-colors.hook";
import {
  calculateYearDifference,
  validateURL,
} from "../../../../../../helpers/dossier.helper";
import StreetViewIcon from "../../../../../../assets/company-dossier/web-social/StreetView.svg";
import { getCurrentTenant } from "../../../../../../utils/tenant.util";

interface IProps {
  id: string;
  fullName: string;
  city: string;
  executivePosition: string | null;
  activeCorporate: ICorporate;
  personBirthday: string | null;
  wealthScore: number;
}

const currentTenant = getCurrentTenant().tenant;

export default function PersonInfo({
  id,
  fullName,
  city,
  executivePosition,
  activeCorporate,
  personBirthday,
  wealthScore,
}: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser } = useSession();
  const { colors } = useAppColors();

  const activeCompanyAddress = buildCompanyAddress(
    activeCorporate?.street,
    activeCorporate?.zip_code,
    activeCorporate?.city,
  );
  const webLink = useMemo(
    () =>
      validateURL(
        activeCorporate?.contact_info
          ?.find((item) => item.contact_type === "web")
          ?.value?.trim(),
      ),
    [activeCorporate],
  );

  const [personBirthdayDate, personYear] = useMemo(() => {
    const personBdayDate = personBirthday && new Date(personBirthday);
    return [personBdayDate, calculateYearDifference(personBdayDate)];
  }, [personBirthday]);

  const streetViewCoordinates =
    activeCorporate && calculateMapCenterFromCompanyCoordinates(activeCorporate);

  return (
    <div>
      {isB2CUser && (
        <>
          <StarRating
            affinity={wealthScore}
            spacing={isSmallerScreen ? "6" : "8"}
            iconHeight={isSmallerScreen ? "10" : "22"}
            emptyIconSize={isSmallerScreen ? "8" : "12"}
          />
          <Spacing pt="12" />
        </>
      )}

      <RText bold fontSize={isSmallerScreen ? "13" : "23"}>
        {fullName}
      </RText>

      <Spacing pt="10" />

      <RText fontSize={isSmallerScreen ? "11" : "16"}>{city}</RText>

      {personBirthdayDate && (
        <>
          <Spacing pt="10" />
          <Flex alignItemsCenter>
            <div className={styles.dateIconContainer}>
              <DateIcon color={colors.backgroundBlackColor} />
              <RText fontSize="11" className={styles.date}>
                {personBirthdayDate.getDate() < 10
                  ? "0" + personBirthdayDate.getDate()
                  : personBirthdayDate.getDate().toString()}
              </RText>
            </div>
            <Spacing pr="15" />
            <RText fontSize={isSmallerScreen ? "11" : "15"} bold>
              {format(personBirthdayDate, "dd.MM.yyyy")}
            </RText>
            <Spacing ph="10">
              <RText bold>|</RText>
            </Spacing>
            <RText fontSize={isSmallerScreen ? "11" : "15"} bold>
              {personYear}
            </RText>
          </Flex>
        </>
      )}

      <Spacing pt="30" />

      <ExecutivesTags executiveId={id} />
      <Spacing pt={isSmallerScreen ? "15" : "30"} />

      {activeCorporate && (
        <>
          <Flex column>
            <RText fontSize="16" bold>
              {executivePosition ?? ""}
            </RText>
            <Spacing pt="10" />
            <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
              {activeCorporate.company_name}
            </RText>
          </Flex>

          <Spacing pt={isSmallerScreen ? "15" : "30"} />

          <Flex column>
            <RText fontSize={isSmallerScreen ? "11" : "16"}>
              <span>
                {activeCompanyAddress ?? ""}
                {streetViewCoordinates && streetViewCoordinates.actualCoordinates && (
                  <span className={styles.streetViewContainer}>
                    <a
                      className={styles.streetViewLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.google.${currentTenant}/maps/@?api=1&map_action=pano&viewpoint=${streetViewCoordinates.lat}%2C${streetViewCoordinates.lng}`}
                    >
                      <img
                        className={styles.streetViewIcon}
                        src={StreetViewIcon}
                        alt="Google StreetView logo"
                      />
                    </a>
                  </span>
                )}
              </span>
            </RText>
            <Spacing pt="10" />
            {webLink && (
              <a
                href={webLink}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.companyUrl} ${
                  isSmallerScreen && styles.companyUrl__Responsive
                }`}
              >
                {webLink}
              </a>
            )}
          </Flex>
        </>
      )}
    </div>
  );
}
