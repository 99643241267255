import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import { IExecutiveMarkerData } from "../../../../entities/executives/interfaces/executive-marker-data.interface";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useExecutivesFilterWithHubUIGroups } from "../../../../entities/executives-filters/hooks/executives-filter-with-ui-groups.hook";
import HubMultiLevelFilterValuesContainer from "../../../hub/HubHeader/HubFiltersGroup/HubMultiLevelFilterValuesContainer/HubMultiLevelFilterValuesContainer";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import { Spacing } from "../../../../components/spacing/component";
import Divider from "../../../../components/dividers/Divider/Divider";
import { HUB_SIDE_PANEL_SORT_BY } from "../../../hub/HubMain/HubSidePanel/HubSidePanelHeader/HubSidePanelSortBy/HubSidePanelSortBy";
import HubSidePanelHeader from "../../../hub/HubMain/HubSidePanel/HubSidePanelHeader/HubSidePanelHeader";
import ExecutiveHubSidePanelItem from "./ExecutiveHubSidePanelItem/ExecutiveHubSidePanelItem";
import { ExecutiveHubContext } from "../../../../modules/executives-hub/state/executives-hub.context";
import { IExecutiveHubMarker } from "../../../../entities/corporates/interfaces/hub-marker.interface";
import { useTopLeads } from "../../../../modules/executives-hub/hooks/top-leads.hook";
import { getExecutiveActiveCompany } from "../../../../helpers/dossier.helper";
import HubFiltersGroup from "../../../hub/HubHeader/HubFiltersGroup/HubFiltersGroup";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { ExpandMore, FilterList } from "@mui/icons-material";
import useAppColors from "../../../../hooks/app-colors.hook";

interface IProps {
  executiveHubMarkers: IExecutiveHubMarker[];
  hideFilter?: boolean;
}

const ExecutivesHubSidePanel = ({ executiveHubMarkers, hideFilter }: IProps) => {
  const { colors } = useAppColors();

  const filteredResults = useMemo(
    () => mapMarkerDataForSidePanel(executiveHubMarkers),
    [executiveHubMarkers],
  );

  const { hubLeadsFooterItems } = useTopLeads();

  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const executiveHubContext = useContext(ExecutiveHubContext);

  const [executives, setExecutives] = useState<IExecutiveMarkerData[]>([]);
  const [sortBy, setSortBy] = useState<HUB_SIDE_PANEL_SORT_BY>(
    HUB_SIDE_PANEL_SORT_BY.NAME_DESC,
  );

  const [searchQuery, setSearchQuery] = useState("");
  const { filterWithUIGroups, loadingExecutivesFilters } =
    useExecutivesFilterWithHubUIGroups();

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) {
      return;
    }

    setExecutives([...filteredResults]);

    return () => {
      isMounted = false;
    };
  }, [filteredResults]);

  const getSortedExecutives = () => {
    const executivesWithClientTag = executives.filter((e) =>
      e.tags.find((t) => t.name === "Client"),
    );

    const executivesWithLeadTag = executives.filter(
      (e) => e.tags.find((t) => t.name === "Lead") && !executivesWithClientTag.includes(e),
    );

    const executivesWithTrends = executives.filter(
      (e) =>
        hubLeadsFooterItems.find((h) => h.executive.id === e.executiveId) &&
        !executivesWithClientTag.includes(e) &&
        !executivesWithLeadTag.includes(e),
    );

    const executivesInLiquidation = executives.filter((e) =>
      getExecutiveActiveCompany(e.management, e.main_company_id, e.shareholder)
        ?.company_name.toLowerCase()
        .includes("in liquidation"),
    );

    const userSalesReachExecutives = executives.filter(
      (e) =>
        !executivesWithClientTag.includes(e) &&
        !executivesWithLeadTag.includes(e) &&
        !executivesWithTrends.includes(e) &&
        !executivesInLiquidation.includes(e),
    );

    const sortedExecutives = [
      ...sortByName(executivesWithClientTag),
      ...sortByName(executivesWithLeadTag),
      ...sortByName(executivesWithTrends),
      ...sortByName(userSalesReachExecutives),
      ...sortByName(executivesInLiquidation),
    ];

    return sortedExecutives;
  };

  const sortByName = (executives: IExecutiveMarkerData[]) => {
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_ASC) {
      const sortedByName = executives.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1,
      );
      return [...sortedByName];
    }

    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_DESC) {
      const sortedByName = executives.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
      return [...sortedByName];
    }
  };

  const onInputChange = (event: any) => setSearchQuery(event.target.value);

  const onSearchExecutives = () => {
    const trimmedSearchTerm = searchQuery?.trim() ?? "";

    if (trimmedSearchTerm.length === 0) {
      // setExecutives([...filteredResults]);
      executiveHubContext.updateHubMapExecutiveMarkers({
        showAll: true,
      });

      return;
    }

    const results = [...filteredResults].filter((executive: IExecutiveMarkerData) =>
      executive.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    // setExecutives([...results]);
    executiveHubContext.updateHubMapExecutiveMarkers({
      hubExecutiveIds: [...results].map(({ executiveId }) => executiveId),
    });
  };

  const onSortByName = () => {
    if (sortBy === HUB_SIDE_PANEL_SORT_BY.NAME_DESC) {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.NAME_ASC);
    } else {
      setSortBy(HUB_SIDE_PANEL_SORT_BY.NAME_DESC);
    }
  };

  const onSearchQueryDelete = () => {
    setSearchQuery("");
    // setExecutives([...filteredResults]);
    executiveHubContext.updateHubMapExecutiveMarkers({
      showAll: true,
    });
  };

  const { setHubRolloverExecutive } = useContext(ExecutiveHubContext);
  const sortedExecutives = getSortedExecutives();

  const [accordionState, setAccordionState] = useState<boolean>(true);
  const handleAccordion = () => setAccordionState((prevState) => !prevState);

  return (
    <div
      className={`${styles.sidePanelContainer} ${
        isSmallerScreen && styles.sidePanelContainer__Responsive
      }`}
    >
      {!hideFilter && (
        <>
          <Accordion
            expanded={accordionState}
            onChange={handleAccordion}
            elevation={0}
            sx={{ backgroundColor: "var(--color_background_white)" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: colors.secondaryDarkColor }} />}
            >
              <Flex alignItemsCenter>
                <FilterList sx={{ mr: 1.3, color: "var(--color_text_dark)" }} />
                <Typography fontWeight="bold" color="var(--color_text_dark)">
                  {t("filter")}
                </Typography>
              </Flex>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  padding: "20px 20px 20px 0",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {loadingExecutivesFilters ? (
                  <div />
                ) : (
                  filterWithUIGroups &&
                  filterWithUIGroups.map((filterGroup) => (
                    <Fragment key={filterGroup.title}>
                      <HubFiltersGroup filtersGroup={filterGroup} isExecutivesHub />
                    </Fragment>
                  ))
                )}
              </Box>
            </AccordionDetails>
          </Accordion>

          {isSmallerScreen &&
            filterWithUIGroups &&
            filterWithUIGroups.map((filterGroup) => {
              return (
                <HubMultiLevelFilterValuesContainer
                  key={filterGroup.title}
                  filtersGroup={filterGroup}
                />
              );
            })}
        </>
      )}

      <Flex alignItemsCenter className={styles.filterResultsContainer}>
        <RText bold fontSize="13">
          {`${formatNumberWithDotSeparator(executives.length)} ${t("executives")}`}
        </RText>
      </Flex>

      <Spacing pt={isSmallerScreen ? "0" : "20"} />

      <HubSidePanelHeader
        searchQuery={searchQuery}
        sortBy={sortBy}
        onInputChange={onInputChange}
        onSearch={onSearchExecutives}
        onSortByName={onSortByName}
        onSearchQueryDelete={onSearchQueryDelete}
      />

      <Spacing ph="10" pt="5">
        <Divider />
      </Spacing>

      <Spacing ph="20" pt="25" className={styles.executivesContainer}>
        {sortedExecutives.length > 0 ? (
          <div
            className={styles.executivesContainer}
            onMouseLeave={() => setHubRolloverExecutive(null)}
          >
            {sortedExecutives.map((executive, index) => {
              return (
                <Fragment key={executive.executiveId}>
                  <ExecutiveHubSidePanelItem executive={executive} />
                  {index < executives.length - 1 ? <Divider /> : <Spacing pb="200" />}
                </Fragment>
              );
            })}
          </div>
        ) : (
          <Flex>
            <Spacing pr="10" />
            <RText fontSize="16">{t("noHubExecutives")}</RText>
          </Flex>
        )}
      </Spacing>
    </div>
  );
};

export default ExecutivesHubSidePanel;

const mapMarkerDataForSidePanel = (
  hubMarkers: IExecutiveHubMarker[],
): IExecutiveMarkerData[] => {
  return hubMarkers.flatMap((marker: IExecutiveHubMarker) => [...marker.executivesData]);
};
