/**
 * ============================================================================
 * SVGs for leaflet marker icons can be used in two ways.
 *
 * One is to just create new L.Icon and give it the url to the svg icon. This
 * shows the icon on the map, but the icon isn't customizable.
 *
 * The other way, partially in this file and partially in DossierMap.tsx,
 * is to use pure svg string, but instead
 * of hardcoding some of it's properties like fill color, they can be made
 * into variables and modified when creating leaflet icon like this:
 *
 *      const iconOptions = {
            mapIconUrl: generateMarkerIconBasedOnMarkerData(),
            markerStrokeColor: "#ffffff",
            markerFillColor: isPurple ? "#9E00C6" : "#000000",
            starFillColor: starFill,
            strokeWidth: isPurple ? 2 : 0.5,
            markerPath: markerPath,
            starPath: starPath,
        };

        return new L.DivIcon({
            className: "leaflet-data-marker",
            html: L.Util.template(iconOptions.mapIconUrl, iconOptions),
            iconSize: [80, 80],
            popupAnchor: [0, -10],
        });
 *
 *
 *
 * String for svg for this particular icon is copied from files:
 * L_MARKER_L_STAR
 * L_MARKER_M_STAR
 * ... etc.
 *
 * As there is a lot of combinations for marker sizes and star sizes, these
 * things are also properties which are modified depending on number of companies
 * in each marker and their collective product affinity.
 *
 * generateMarkerIconBasedOnMarkerData method creates parts of svg string
 * which are the same for every possible icon variation, and then new L.DivIcon
 * fill certain properties dynamically.
 *
 * For example fill="{starFillColor}" d="{starPath} are filled dynamically with
 * star color and star svg path.
 *
 * ============================================================================
 */

export enum StarIconSize {
  S_STAR = "S_STAR",
  M_STAR = "M_STAR",
  L_STAR = "L_STAR",
}

export enum MarkerIconSize {
  S_MARKER = "S_MARKER",
  M_MARKER = "M_MARKER",
  L_MARKER = "L_MARKER",
}

export const getCorrectPath = (
  markerSize: MarkerIconSize,
  starSize: StarIconSize,
): { markerPath: string; starPath: string } => {
  let markerPath = "";
  let starPath = "";

  if (markerSize === MarkerIconSize.L_MARKER) {
    markerPath = LMarkerPath;
    if (starSize === StarIconSize.L_STAR) starPath = LStarPathOnLMarker;
    if (starSize === StarIconSize.M_STAR) starPath = MStarPathOnLMarker;
    if (starSize === StarIconSize.S_STAR) starPath = SStarPathOnLMarker;
  }

  if (markerSize === MarkerIconSize.M_MARKER) {
    markerPath = MMarkerPath;
    if (starSize === StarIconSize.L_STAR) starPath = LStarPathOnMMarker;
    if (starSize === StarIconSize.M_STAR) starPath = MStarPathOnMMarker;
    if (starSize === StarIconSize.S_STAR) starPath = SStarPathOnMMarker;
  }

  if (markerSize === MarkerIconSize.S_MARKER) {
    markerPath = SMarkerPath;
    if (starSize === StarIconSize.L_STAR) starPath = LStarPathOnSMarker;
    if (starSize === StarIconSize.M_STAR) starPath = MStarPathOnSMarker;
    if (starSize === StarIconSize.S_STAR) starPath = SStarPathOnSMarker;
  }

  return { markerPath, starPath };
};

export const generateMarkerIconBasedOnMarkerData = () => {
  return `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 24.1.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 56.7 56.7" style="enable-background:new 0 0 56.7 56.7;" xml:space="preserve">
<style type="text/css">
	.st0{
	stroke-miterlimit:10;
	}
</style>
<g>
	<path class="st0" stroke="{markerStrokeColor}" stroke-width="{strokeWidth}" fill="{markerFillColor}" d="{markerPath}"/>
	<g>
		<path id="Pfad_3460" fill="{starFillColor}" d="{starPath}"/>
	</g>
</g>
</svg>`;
};

// Taken from L_MARKER_ icons
const LMarkerPath = `
M44.7,15.7c-0.2-0.4-0.4-0.7-0.6-1.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.5-0.4-0.7
c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.3-0.3-0.4-0.4
c-0.2-0.2-0.3-0.3-0.5-0.5c-0.3-0.3-0.7-0.6-1-0.9c-0.1-0.1-0.2-0.2-0.4-0.3C39.5,9.3,39.2,9.2,39,9c-0.1-0.1-0.2-0.2-0.3-0.2
c-0.3-0.2-0.5-0.3-0.8-0.5c-0.1-0.1-0.2-0.1-0.3-0.2C37.3,8,37,7.9,36.7,7.7c-0.1,0-0.1,0-0.2-0.1c-1.4-0.7-3-1.1-4.7-1.4h-0.1
c-0.4-0.1-0.8-0.1-1.2-0.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0-1.3,0l0,0c-2.1,0-4.1,0.3-5.9,1c-0.1,0-0.1,0-0.2,0.1
c-4.4,1.6-7.8,4.9-9.5,8.6c-2.1,4.5-2.5,9.2-0.4,13.9c3.1,7.1,7.8,12.9,13.6,18c0.8,0.7,1.6,1,2.4,1c0.4,0,0.9,0,1.3-0.2l0,0
c0.2-0.1,0.4-0.2,0.5-0.3h0.1c0.2-0.1,0.4-0.3,0.6-0.4c5.7-5.1,10.5-10.9,13.6-18C47.2,24.9,46.8,20.2,44.7,15.7z
`;

// Taken from M_MARKER_ icons
const MMarkerPath = `
M41.6,18c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.2-0.2-0.2-0.3
c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.3-0.4-0.4
c-0.3-0.2-0.5-0.5-0.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.3-0.6-0.4
c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.5-0.2-0.7-0.4c0,0-0.1,0-0.1-0.1c-1.1-0.5-2.4-0.9-3.7-1.1h-0.1c-0.3,0-0.6-0.1-1-0.1h-0.1
c-0.3,0-0.7,0-1,0l0,0c-1.7,0-3.3,0.3-4.7,0.8h-0.1c-3.6,1.3-6.3,3.9-7.6,6.9c-1.7,3.6-2,7.3-0.3,11.1c2.5,5.7,6.3,10.3,10.8,14.4
c0.6,0.5,1.3,0.8,1.9,0.8c0.3,0,0.7,0,1-0.2l0,0c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0,0,0.1,0c0.2-0.1,0.3-0.2,0.5-0.4
c4.6-4.1,8.4-8.7,10.8-14.4C43.6,25.4,43.3,21.6,41.6,18z
`;

// Taken from S_MARKER_ icons
const SMarkerPath = `
M39.1,18.9c-0.1-0.2-0.2-0.5-0.4-0.7c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.4s-0.1-0.2-0.2-0.3
c-0.1-0.1-0.2-0.3-0.3-0.4s-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
c-0.2-0.2-0.4-0.4-0.6-0.6c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3h-0.1c-0.9-0.4-1.9-0.7-3-0.9h-0.1c-0.3,0-0.5-0.1-0.8-0.1h-0.1
c-0.3,0-0.5,0-0.8,0l0,0c-1.4,0-2.6,0.2-3.8,0.6h-0.1c-2.8,1-5,3.1-6.1,5.5c-1.3,2.9-1.6,5.9-0.3,8.9c2,4.6,5,8.3,8.7,11.5
c0.5,0.4,1,0.6,1.6,0.6c0.3,0,0.6,0,0.8-0.1l0,0c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0,0.1,0c0.1-0.1,0.3-0.2,0.4-0.3
c3.7-3.3,6.7-7,8.7-11.5C40.7,24.8,40.4,21.8,39.1,18.9z
`;

// Taken from L_MARKER_L_STAR icon
const LStarPathOnLMarker = `
M29.1,13.2l2.8,7.5h8.8l-7.5,4.6l3,8.6l-6.9-5.5l-7.8,5.5l3.4-8.6l-7.3-4.6h8.9L29.1,13.2z`;

// Taken from M_MARKER_L_STAR icon
const LStarPathOnMMarker = `
M29.1,16l2.2,6h7l-6,3.7l2.4,6.9l-5.5-4.4L23,32.6l2.7-6.9L19.9,22H27L29.1,16z`;

// Taken from S_MARKER_L_STAR icon
const LStarPathOnSMarker = `
M29.1,17.3l1.8,4.8h5.6L31.7,25l1.9,5.5L29.2,27l-5,3.5l2.2-5.5l-4.7-2.9h5.7L29.1,17.3z`;

// Taken from L_MARKER_M_STAR icon
const MStarPathOnLMarker = `
M29.1,15.2l2.2,6h7l-6,3.7l2.4,6.9l-5.5-4.4L23,31.8l2.7-6.9l-5.8-3.7H27L29.1,15.2z`;

// Taken from M_MARKER_M_STAR icon
const MStarPathOnMMarker = `
M29.1,17.6l1.8,4.8h5.6l-4.8,2.9l1.9,5.5l-4.4-3.5l-5,3.5l2.2-5.5l-4.7-2.9h5.7L29.1,17.6z`;

// Taken from S_MARKER_M_STAR icon
const MStarPathOnSMarker = `
M28.1,19.6l1.4,3.8H34l-3.8,2.4l1.5,4.4l-3.5-2.8l-4,2.8l1.7-4.4l-3.7-2.4h4.6L28.1,19.6z`;

// Taken from L_MARKER_S_STAR icon
const SStarPathOnLMarker = `
M29.1,15.9l1.8,4.8h5.6l-4.8,2.9l1.9,5.5l-4.4-3.5l-5,3.5l2.2-5.5l-4.7-2.9h5.7L29.1,15.9z`;

// Taken from M_MARKER_S_STAR icon
const SStarPathOnMMarker = `
M29.1,19l1.4,3.8H35l-3.8,2.4l1.5,4.4l-3.5-2.8l-4,2.8l1.7-4.4l-3.7-2.4h4.6L29.1,19z`;

// Taken from S_MARKER_S_STAR icon
const SStarPathOnSMarker = `
M28.1,20.6l1.1,3.1h3.6l-3.1,1.9l1.2,3.5l-2.8-2.3L25,29.1l1.4-3.5l-3-1.9H27L28.1,20.6z`;
