import { IUserResponse } from "../../models/login/user.model";
import {
  IForgotPasswordFormData,
  ILoginFormData,
  ILoginResponseModel,
  IRegisterFormData,
} from "../../state/akita/session/session.model";
import ApiService from "./api.service";

class UserService extends ApiService {
  public current(): Promise<IUserResponse> {
    return this.get("/me");
  }

  public login(user: ILoginFormData): Promise<ILoginResponseModel> {
    return this.post<ILoginResponseModel>("/token", user).then((res: any) => {
      // No user found for given credentials, incorrect email or password
      if (res.code && res.code !== 200) {
        return null;
      }

      return res;
    });
  }

  public register(user: IRegisterFormData): Promise<IUserResponse> {
    return this.post("/register", user);
  }

  public resetPassword(userEmail: IForgotPasswordFormData): Promise<[]> {
    return this.post("/reset-password", userEmail);
  }
}

export const userService = new UserService();
