import React, { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as LookALikesMapIcon } from "../../../../../../../assets/company-dossier/LookALikesMap.svg";
import { ReactComponent as LookALikesMapDarkIcon } from "../../../../../../../assets/company-dossier/LookALikesMapDark.svg";
import {
  FoundationReachIcon,
  FoundationReachDarkIcon,
} from "../../../../../../../assets/executive-dossier/kpi/icons";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { abbreviateNumber } from "../../../../../../../helpers/number.helper";
import { IExecutive } from "../../../../../../../entities/executives/state/executive.model";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { useOpenExecutiveFullscreenMapModal } from "../../../../../../../entities/executive-dossier/hooks/executive-dossier.hook";
import { useSession } from "../../../../../../../hooks/session.hook";
import { getExecutiveActiveCompany } from "../../../../../../../helpers/dossier.helper";
import { IPeopleManagement } from "../../../../../../../entities/executives/interfaces/people-management.interface";
import TableRow from "../../../shared/TableRow";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import EntityInfo, { EntityType } from "../../../shared/EntityInfo";
import { ScoreIconContainer } from "../../../../../corporates/dossier/components/score/Score";
import AnimatedCircleIcon from "../../../../../../../components/animations/animated-circle-icon/AnimatedCircleIcon";
import ExecutivesTags from "../../../../../ExecutivesTags/ExecutivesTags";
import { IShareholder } from "../../../../../../../entities/executives/interfaces/shareholder.interface";

interface IExecutiveInfoData {
  id: string;
  executiveName: string;
  city: string;
  mainIndustry: string;
  wealthScore: string;
  promoterScore: string;
  foundationReach: string;
  activeCompany: IPeopleManagement | IShareholder | null;
}

const mapExecutiveLookALikes = (executiveLookALikes: IExecutive[]): IExecutiveInfoData[] => {
  return (
    executiveLookALikes?.map((executive: IExecutive): IExecutiveInfoData => {
      return {
        id: executive.id,
        executiveName: executive.full_name ?? "",
        city: executive.city,
        mainIndustry: executive.industry_sector ?? "",
        wealthScore: executive.wealth_score?.toString(),
        promoterScore: executive.promoter_score?.toString(),
        foundationReach: executive.foundation_count?.toString(),
        activeCompany:
          getExecutiveActiveCompany(
            executive.management,
            executive.main_company_id,
            executive.shareholder,
          ) ?? null,
      };
    }) ?? []
  );
};

interface IProps {
  executiveName: string;
  executiveLookALikes: IExecutive[] | null;
  totalExecutiveLookALikes: number | null;
}

export default function LookALikes({
  executiveName,
  executiveLookALikes,
  totalExecutiveLookALikes,
}: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();

  const formattedLookALikesData = useMemo(
    () => mapExecutiveLookALikes(executiveLookALikes),
    [executiveLookALikes],
  );

  const { setOpenExecutiveFullscreenMapModal } = useOpenExecutiveFullscreenMapModal();

  return (
    <>
      <Flex alignItemsCenter>
        <Flex flex>
          <RText fontSize="22" bold>
            {`${t("similarExecutivesTo")} ${executiveName}`}
          </RText>
        </Flex>
        <Spacing pr="20" />
        <Flex pointer onClick={() => setOpenExecutiveFullscreenMapModal(true)}>
          {isDarkTheme ? (
            <LookALikesMapDarkIcon
              color={colors.secondaryDarkColor}
              className={styles.lookALikesMapIcon}
            />
          ) : (
            <LookALikesMapIcon
              color={colors.secondaryDarkColor}
              className={styles.lookALikesMapIcon}
            />
          )}
        </Flex>
      </Flex>

      <Spacing pt="40" />

      <TableRow
        col1Data={t("name")}
        col2Data={t("foundationReach")}
        col3Data={t("promoterScore")}
        header
      />
      <Spacing pt="20" />
      <div className={styles.executivesContainer}>
        {formattedLookALikesData?.map((executive, index) => (
          <Fragment key={executive.id}>
            <TableRow
              col1Data={
                <>
                  <EntityInfo
                    entityId={executive.id}
                    entityName={executive.executiveName}
                    entityType={EntityType.EXECUTIVE}
                    ratingScore={+executive.wealthScore}
                    subTitle={executive.activeCompany?.company_name}
                    headcount={
                      executive.activeCompany?.employee_count
                        ? abbreviateNumber(executive.activeCompany.employee_count)
                        : null
                    }
                    turnover={
                      executive.activeCompany?.turnover
                        ? abbreviateNumber(executive.activeCompany.turnover)
                        : null
                    }
                  />
                  <Spacing pt="10" style={{ width: "350px" }}>
                    <ExecutivesTags executiveId={executive.id} />
                  </Spacing>
                </>
              }
              col2Data={
                executive.foundationReach ? (
                  <ScoreIconContainer
                    scoreValue={executive.foundationReach}
                    ScoreLightIcon={FoundationReachIcon}
                    ScoreDarkIcon={FoundationReachDarkIcon}
                    className={
                      isSmallerScreen
                        ? styles.scoreIconContainer__Responsive
                        : styles.scoreIconContainer
                    }
                  />
                ) : (
                  t("noDataAvailable")
                )
              }
              col3Data={
                <AnimatedCircleIcon
                  value={+executive.promoterScore}
                  showValuePercentage={true}
                  percentageContainerStyle={{
                    left: isSmallerScreen ? "45px" : "58px",
                    backgroundColor: colors.backgroundWhiteColor,
                    color: colors.textDarkColor,
                  }}
                  innerStyle={{
                    border: `1px solid ${colors.backgroundBlackColor}`,
                    width: "60%",
                    height: "60%",
                  }}
                  className={
                    isSmallerScreen
                      ? styles.scoreIconContainer__Responsive
                      : styles.scoreIconContainer
                  }
                  trailColor={
                    isDarkTheme ? "var(--color_gray_light)" : colors.primaryLightColor
                  }
                >
                  <RText fontSize={isSmallerScreen ? "16" : "20"}>
                    {executive.promoterScore}
                  </RText>
                </AnimatedCircleIcon>
              }
            />

            {index < formattedLookALikesData.length - 1 && <Divider />}
          </Fragment>
        )) ?? <></>}
      </div>
      <Spacing pt="20" />
    </>
  );
}
