import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as LookALikesMapIcon } from "../../../../../../../assets/company-dossier/LookALikesMap.svg";
import { ReactComponent as LookALikesMapDarkIcon } from "../../../../../../../assets/company-dossier/LookALikesMapDark.svg";
import ColoredContainer from "../../../../../../../components/containers/ColoredContainer/ColoredContainer";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import VerticalDivider from "../../../../../../../components/dividers/VerticalDivider/VerticalDivider";
import RText from "../../../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../../../components/spacing/component";
import { ICorporate } from "../../../../../../../entities/corporates/models/corporate.model";
import { abbreviateNumber } from "../../../../../../../helpers/number.helper";
import { buildCompanyAddress } from "../../../../../../../helpers/map.helper";
import { corporatesApiService } from "../../../../../../../api/services/corporates-api.service";
import { useOpenFullscreenMapModal } from "../../../../../../../hooks/corporates.hook";
import RLink from "../../../../../../../components/link/RLink";
import { formatIndustrySectorForTranslation } from "../../../../../../../helpers/translation.helper";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../../../hooks/session.hook";
import CorporatesTags from "../../../../../CorporatesTags/CorporatesTags";

interface ICompanyInfoData {
  id: string;
  affinity: number;
  corporateName: string;
  countryZipCodeAndCity: string;
  mainIndustry: string;
  headcount: string;
  turnover: string;
  promoterScore: string;
}

const mapCorporateLookALikes = (corporateLookALikes: ICorporate[]): ICompanyInfoData[] => {
  return (
    corporateLookALikes?.map((corporate: ICorporate): ICompanyInfoData => {
      const formattedAddress = buildCompanyAddress(
        corporate.street,
        corporate.zip_code,
        corporate.city,
      );

      return {
        id: corporate.id,
        affinity: corporate.affinity_score ?? 0,
        corporateName: corporate.company_name ?? "",
        countryZipCodeAndCity: formattedAddress,
        mainIndustry: corporate.industry_sector ?? "",
        headcount: corporate.employee_count ? abbreviateNumber(corporate.employee_count) : "",
        turnover: corporate.turnover ? abbreviateNumber(corporate.turnover) + " €" : "",
        promoterScore: corporate.promoter_score?.toString() ?? "0",
      };
    }) ?? []
  );
};

interface IProps {
  companyName: string;
  corporateLookALikes: ICorporate[] | null;
  totalCorporateLookALikes: number | null;
}

export default function LookALikes({
  companyName,
  corporateLookALikes,
  totalCorporateLookALikes,
}: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();

  const [corporatesProductTrendsCount, setCorporatesProductTrendsCount] = useState<
    ICorporateProductTrendsCount[]
  >([]);

  useEffect(() => {
    let isMounted = true;

    corporatesApiService
      .getCorporatesProductTrendsCount({
        companies: corporateLookALikes.map((company: ICorporate) => +company.id),
      })
      .then((res: any) => {
        isMounted &&
          setCorporatesProductTrendsCount(mapCorporatesProductTrendsCount(res.data.companies));
      })
      .catch((e) => console.log(e));

    return () => {
      isMounted = false;
    };
  }, [corporateLookALikes]); // eslint-disable-line

  const findCorporateProductTrendsCount = (companyId: string) => {
    return (
      corporatesProductTrendsCount.find((company: ICorporateProductTrendsCount) => {
        return company.companyId === companyId;
      }) ?? null
    );
  };

  const renderCompanyInfo = (
    {
      id,
      affinity,
      corporateName,
      countryZipCodeAndCity,
      mainIndustry,
      headcount,
      turnover,
      promoterScore,
    }: ICompanyInfoData,
    productTrendsCount: string,
    index: number,
  ) => {
    return (
      <Fragment key={index}>
        <StarRating affinity={affinity} spacing="8" emptyIconSize="12" />
        <Spacing pt="8" />
        <RLink path={`/home/search/corporate-dossier/${id}`}>
          <RText fontSize={isSmallerScreen ? "16" : "23"} bold>
            {corporateName}
          </RText>
        </RLink>
        <Spacing pt="8" />
        <RText fontSize={isSmallerScreen ? "11" : "16"}>{countryZipCodeAndCity}</RText>

        <Spacing pt="10" pb={isSmallerScreen ? "14" : "28"} style={{ width: "350px" }}>
          <CorporatesTags companyId={id} />
        </Spacing>

        <Flex className={styles.infoContainer}>
          <Flex column justifyContentSpaceBetween className={styles.firstColumn}>
            <Flex>
              <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.text}>
                {`${t("industry")}:`}
              </RText>
              <Spacing pr={isSmallerScreen ? "20" : "46"} />
              <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
                {formatIndustrySectorForTranslation(mainIndustry, t)}
              </RText>
            </Flex>
            <Flex>
              <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.text}>
                {`${t("headcount")}:`}
              </RText>
              <Spacing pr={isSmallerScreen ? "20" : "46"} />
              <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
                {headcount}
              </RText>
            </Flex>
            <Flex>
              <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.text}>
                {`${t("turnover")}:`}
              </RText>
              <Spacing pr={isSmallerScreen ? "20" : "46"} />
              <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
                {turnover}
              </RText>
            </Flex>
          </Flex>

          <VerticalDivider />

          <Flex column justifyContentCenter alignItemsCenter flex>
            <RText fontSize={isSmallerScreen ? "16" : "38"} bold>
              {productTrendsCount ?? "0"}
            </RText>
            <Spacing pt="5" />
            <RText fontSize={isSmallerScreen ? "11" : "16"}>{t("productTrends")}</RText>
          </Flex>

          <VerticalDivider />

          <Flex column justifyContentCenter alignItemsCenter flex>
            <RText fontSize={isSmallerScreen ? "16" : "38"} bold>
              {promoterScore + "%"}
            </RText>
            <Spacing pt="5" />
            <RText fontSize={isSmallerScreen ? "11" : "16"}>{t("promoterScore")}</RText>
          </Flex>
        </Flex>
        <Spacing pt={isSmallerScreen ? "30" : "60"} />
      </Fragment>
    );
  };

  const { setOpenFullscreenMapModal } = useOpenFullscreenMapModal();

  return (
    <>
      <Flex>
        <Flex flex>
          <RText fontSize="22" bold>
            {`${t("similarCompaniesTo")} ${companyName}`}
          </RText>
        </Flex>
        <Spacing pr="20" />
        <Flex column>
          {totalCorporateLookALikes && (
            <ColoredContainer primaryGreen size="30">
              <RText immutableBlack fontSize="18">
                {abbreviateNumber(totalCorporateLookALikes)}
              </RText>
            </ColoredContainer>
          )}
          <Spacing pt="10" />
          <Flex pointer onClick={() => setOpenFullscreenMapModal(true)}>
            {isDarkTheme ? (
              <LookALikesMapDarkIcon
                color={colors.secondaryDarkColor}
                className={styles.lookALikesMapIcon}
              />
            ) : (
              <LookALikesMapIcon
                color={colors.secondaryDarkColor}
                className={styles.lookALikesMapIcon}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      <Spacing pt="60" />
      <div className={styles.companiesContainer}>
        {corporateLookALikes &&
          mapCorporateLookALikes(corporateLookALikes).map((company, index) =>
            renderCompanyInfo(
              company,
              findCorporateProductTrendsCount(company.id)?.productTrendsCount,
              index,
            ),
          )}
      </div>
      <Spacing pt="20" />
    </>
  );
}

interface ICorporateProductTrendsCount {
  companyId: string;
  productTrendsCount: string;
}

const mapCorporatesProductTrendsCount = (results: any): ICorporateProductTrendsCount[] => {
  return (
    results?.map((companyData: any) => {
      return {
        companyId: companyData.company?.internal_company_id?.toString(),
        productTrendsCount: companyData?.count?.toString(),
      };
    }) ?? []
  );
};
