import { ISaveLastSearchResponse } from "../../entities/last-search/interfaces/save-last-search.interface";
import { ILastSearch } from "../../entities/last-search/state/last-search.model";
import ApiService from "./api.service";

interface ISaveLastSearchTerm {
  term: string;
}

class LastSearchApiService extends ApiService {
  public getUserLastSearchTerms(): Promise<ILastSearch[]> {
    return this.get(`/me/last-searches`);
  }

  public saveLastSearchTerm(
    lastSearchTerm: ISaveLastSearchTerm,
  ): Promise<ISaveLastSearchResponse> {
    return this.post(`/last-search`, lastSearchTerm);
  }

  public deleteLastSearchTerm(id: number): Promise<void> {
    return this.delete(`/last-search/${id}`);
  }
}

export const lastSearchApiService = new LastSearchApiService();
