import React, { Fragment, useContext } from "react";
import styles from "./styles.module.css";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import Spinner from "../../../../../../../components/other/Spinner/Spinner";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IIndustrySectorCode } from "../../../../../../../entities/corporate-dossier/corporate-dossier.model";
import VerticalDivider from "../../../../../../../components/dividers/VerticalDivider/VerticalDivider";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useLngString } from "../../../../../../../localization/i18n";

interface IProps {
  industrySectorCodeData: IIndustrySectorCode | null;
  loadingIndustryCodeData: boolean;
}

export default function NavItemContent({
  industrySectorCodeData,
  loadingIndustryCodeData,
}: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const browserLng = useLngString();

  if (!industrySectorCodeData?.sector_code) {
    return null;
  }

  return loadingIndustryCodeData ? (
    <Spinner />
  ) : (
    renderIndustryCodeContent(industrySectorCodeData, browserLng, isSmallerScreen)
  );
}

const renderIndustryCodeContent = (
  { lvl1, lvl2, lvl3, lvl4, lvl5 }: IIndustrySectorCode,
  browserLng: string,
  isSmallerScreen: boolean,
) => {
  const industryCodeItems = [[lvl1, lvl2], [lvl3, lvl4], [lvl5]];

  return (
    <>
      {industryCodeItems.map((levels, index) => (
        <Fragment key={index}>
          <Flex>
            <Flex column flex>
              <RText fontSize={isSmallerScreen ? "18" : "36"} bold>
                {levels[0].code}
              </RText>
              <Spacing pt={isSmallerScreen ? "10" : "20"} />
              <RText fontSize={isSmallerScreen ? "13" : "18"} className={styles.text}>
                {browserLng === "en" ? levels[0].title_en : levels[0].title_de}
              </RText>
            </Flex>
            <Spacing pr="30" />
            {levels.length > 1 ? (
              <>
                <Flex column>
                  <VerticalDivider />
                </Flex>
                <Spacing pr="30" />
                <Flex column flex>
                  <RText fontSize={isSmallerScreen ? "18" : "36"} bold>
                    {levels[1].code}
                  </RText>
                  <Spacing pt={isSmallerScreen ? "10" : "20"} />
                  <RText fontSize={isSmallerScreen ? "13" : "18"} className={styles.text}>
                    {browserLng === "en" ? levels[1].title_en : levels[1].title_de}
                  </RText>
                </Flex>
              </>
            ) : (
              <Flex flex />
            )}
          </Flex>
          <Spacing pt={isSmallerScreen ? "20" : "40"} />
        </Fragment>
      ))}
    </>
  );
};
