import React, { Fragment, useState, useEffect, useContext } from "react";
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import styles from "./styles.module.css";
import {
  AncorUp,
  AncorDown,
  // Circle,
} from "../../../../../../../assets/company-dossier/news-section/icons";
import { INews } from "../../../../../../../entities/corporate-dossier/corporate-dossier.model";
import { Spacing } from "../../../../../../../components/spacing/component";
import SquareButton from "../../../../../../../components/buttons/SquareButton/SquareButton";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import Header from "./Header";
import Pagination from "./Pagination";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { Grid } from "@mui/material";

export interface INote {
  title: string;
  description: string;
  date: Date;
  url: string;
}

interface IProps {
  corporateNews: INews[] | null;
}

export default function NotesList({ corporateNews }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const [notes, setNotes] = useState<INote[]>([]);
  const [sortByName, setSortByName] = useState("asc");
  const [SortByNameIcon, setSortByNameIcon] =
    useState<React.FC<React.SVGProps<SVGSVGElement>>>(AncorUp);
  const [sortByDate, setSortByDate] = useState("desc");
  const [SortByDateIcon, setSortByDateIcon] =
    useState<React.FC<React.SVGProps<SVGSVGElement>>>(AncorDown);

  useEffect(() => {
    if (!corporateNews) {
      return;
    }

    setNotes(mapCorporateNews(corporateNews));
  }, [corporateNews]);

  // Pagination data
  const maxNotesPerPage: number = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastNote = currentPage * maxNotesPerPage;
  const indexOfFirstNote = indexOfLastNote - maxNotesPerPage;
  const currentNotesOnPage = notes.slice(indexOfFirstNote, indexOfLastNote);
  const paginationNumbersLength = Math.ceil(notes.length / maxNotesPerPage);

  /**
   * If we search for some news then pagination numbers will be updated according results,
   * so we need to set current page again on 1
   */
  useEffect(() => {
    setCurrentPage(1);
  }, [paginationNumbersLength]);

  const onSortByName = () => {
    let sortedByName: INote[] = [...notes];

    if (sortByName === "asc") {
      sortedByName = sortedByName.sort((a: INote, b: INote) =>
        a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1,
      );
    } else {
      sortedByName = sortedByName.sort((a: INote, b: INote) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
      );
    }

    setSortByNameIcon((icon: React.FC<React.SVGProps<SVGSVGElement>>) =>
      icon === AncorUp ? AncorDown : AncorUp,
    );
    setSortByName((sortType: string) => (sortType === "asc" ? "desc" : "asc"));
    setNotes([...sortedByName]);
  };

  const onSortByDate = () => {
    let sortedByDate: INote[] = [...notes];

    if (sortByDate === "asc") {
      sortedByDate = sortedByDate.sort((a: INote, b: INote) => (a.date < b.date ? 1 : -1));
    } else {
      sortedByDate = sortedByDate.sort((a: INote, b: INote) => (a.date > b.date ? 1 : -1));
    }

    setSortByDateIcon((icon: React.FC<React.SVGProps<SVGSVGElement>>) =>
      icon === AncorDown ? AncorUp : AncorDown,
    );
    setSortByDate((sortType: string) => (sortType === "desc" ? "asc" : "desc"));
    setNotes([...sortedByDate]);
  };

  const onPageChange = (pageNumber: number) => {
    scroller.scrollTo("news", {
      containerId: "contentContainerElement",
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    setCurrentPage(pageNumber);
  };

  const renderNotesItem = (item: INote, index: any) => (
    <Grid item md={6} key={index}>
      <Flex>
        {/* <Spacing pr="30" /> */}
        <RText fontSize={isSmallerScreen ? "13" : "18"}>
          {format(item.date, "dd.MM.yyyy")}
        </RText>
      </Flex>
      <Spacing pb="8" />
      <Flex>
        {/* <Circle color={colors.backgroundBlackColor} /> */}
        {/* <Spacing pr="12" /> */}
        <RText fontSize={isSmallerScreen ? "13" : "18"} bold className={styles.title}>
          {item.title}
        </RText>
      </Flex>
      <Spacing pb="8" />
      <Flex>
        {/* <Spacing pr="30" /> */}
        <RText fontSize={isSmallerScreen ? "13" : "18"} className={styles.description}>
          {item.description}
        </RText>
      </Flex>
      <Spacing pb="12" />
      <Flex>
        {/* <Spacing pr="30" /> */}
        <SquareButton
          text={"Read more"}
          leftPadding={false}
          borderless
          onClick={() => window.open(item.url, "_blank")}
        />
      </Flex>
      <Spacing pb="40" />
    </Grid>
  );

  if (!corporateNews) {
    return null;
  }

  return (
    <>
      <Header orginalNotesList={mapCorporateNews(corporateNews)} setNotes={setNotes} />

      <Spacing pt="15" />
      <Divider />
      <Spacing pt="30" />

      <Flex alignItemsCenter>
        {/* <Spacing pr="30" /> */}
        <Flex alignItemsCenter pointer onClick={onSortByName}>
          <RText fontSize={isSmallerScreen ? "11" : "18"}>A - Z</RText>
          <Spacing pr="10" />
          <SortByNameIcon color={colors.backgroundBlackColor} className={styles.ancorIcon} />
        </Flex>
        <Spacing pr="20" />
        <Flex alignItemsCenter pointer onClick={onSortByDate}>
          <RText fontSize={isSmallerScreen ? "11" : "18"}>{t("date")}</RText>
          <Spacing pr="10" />
          <SortByDateIcon color={colors.backgroundBlackColor} className={styles.ancorIcon} />
        </Flex>
      </Flex>

      <Spacing pt="28" />
      <Flex>
        <Spacing pr="30" />
      </Flex>
      <Spacing pt="28" />

      <Grid container spacing={5}>
        {currentNotesOnPage.length > 0 ? (
          currentNotesOnPage.map(renderNotesItem)
        ) : (
          <RText fontSize={isSmallerScreen ? "13" : "18"}>{t("noResultsMatchCriteria")}</RText>
        )}
      </Grid>

      {notes.length <= maxNotesPerPage ? null : (
        <>
          <Divider />
          <Spacing pt="22" />
          <Pagination
            paginationNumbersLength={paginationNumbersLength}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </>
      )}
      <Spacing pt="50" />
    </>
  );
}

const mapCorporateNews = (corporateNews: INews[]) => {
  return corporateNews.map((item: INews) => ({
    title: item.title,
    description: item.snippet,
    date: formatDate(item.publishedDate),
    url: item.url,
  }));
};

export const formatDate = (date: Date | string) => {
  let newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};
