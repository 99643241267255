import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import tagsStyles from "../tags/styles.module.css";
import { Spacing } from "../spacing/component";
import RText from "../fonts/RText/RText";
import { Flex } from "../containers/Flex/Flex";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  name: string;
  color: string;
}

const Tag = ({ name, color }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  return (
    <Flex alignItemsCenter pointer>
      <div
        className={tagsStyles.defaultTag}
        style={{
          backgroundColor: color,
        }}
      />
      <Spacing pr="15" />
      <RText fontSize={isSmallerScreen ? "13" : "16"}>
        {name === "Client" ? t("client") : name}
      </RText>
    </Flex>
  );
};

export default Tag;
