import { Query } from "@datorama/akita";
import {
  ExecutiveDossierStore,
  IExecutiveDossierState,
  executiveDossierStore,
} from "./executive-dossier.store";

export class ExecutiveDossierQuery extends Query<IExecutiveDossierState> {
  constructor(protected store: ExecutiveDossierStore) {
    super(store);
  }
}

export const executiveDossierQuery = new ExecutiveDossierQuery(executiveDossierStore);
