import React, { useContext } from "react";
import RNotes from "../../../../../../components/notes/RNotes";
import { useExecutiveNotes } from "../../../../../../entities/executive-notes/hooks/executive-notes.hook";
import { ExecutiveNotesContext } from "../../../../../../entities/executive-notes/state/executive-notes.context";

interface IProps {
  executiveId: string;
}

const Notes = ({ executiveId }: IProps) => {
  const { executiveNotes } = useExecutiveNotes();
  const executiveNotesContext = useContext(ExecutiveNotesContext);

  return (
    <RNotes
      entityId={executiveId}
      notes={executiveNotes}
      addNote={executiveNotesContext.addNote}
      updateNote={executiveNotesContext.updateNote}
      deleteNote={executiveNotesContext.deleteNote}
    />
  );
};

export default React.memo(Notes);
