import React, { useContext } from "react";
import styles from "./styles.module.css";
import { Flex } from "../../../containers/Flex/Flex";
import { Spacing } from "../../../spacing/component";
import RText from "../../../fonts/RText/RText";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  column1: string;
  column2: string;
  column3: string;
}

const ExecutivesSearchResultsListHeader = ({ column1, column2, column3 }: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Spacing ph="20">
      <Flex>
        <div className={styles.column1}>
          <RText fontSize={isSmallerScreen ? "13" : "16"} bold>
            {column1}
          </RText>
        </div>

        <div className={`${styles.tags} ${isSmallerScreen && styles.tags__Responsive}`}>
          <RText bold fontSize={isSmallerScreen ? "13" : "16"}>
            {t("tag")}
          </RText>
        </div>

        <div className={styles.column2}>
          <RText fontSize={isSmallerScreen ? "13" : "16"} bold>
            {column2}
          </RText>
        </div>

        <div className={styles.column3}>
          <RText fontSize={isSmallerScreen ? "13" : "16"} bold>
            {column3}
          </RText>
        </div>
      </Flex>
    </Spacing>
  );
};

export default ExecutivesSearchResultsListHeader;
