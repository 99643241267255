import React from "react";
import { useExecutivesSearchResponse } from "../../../entities/executives-filters/hooks/executives-search-response.hook";
import Export from "../../../components/export/Export";
import { useSession } from "../../../hooks/session.hook";

const ExecutivesSearchExport = () => {
  const { exportResults, searchResultTotalCount } = useExecutivesSearchResponse();
  const { currentUser } = useSession();

  if (
    currentUser.export_permission &&
    searchResultTotalCount <= +currentUser.export_permission
  ) {
    return <Export exportResults={exportResults} />;
  }
  return null;
};

export default ExecutivesSearchExport;
