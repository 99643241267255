import React from "react";
import { TileLayer } from "react-leaflet";
import {
  MAP_CITIES_AND_PLACES_LAYER,
  MAP_SATELLITE_LAYER,
} from "../../../../../../../constants/urls";

const MapSatelliteLayer = () => {
  return (
    <>
      <TileLayer
        url={MAP_SATELLITE_LAYER}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      <TileLayer
        url={MAP_CITIES_AND_PLACES_LAYER}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
    </>
  );
};

export default MapSatelliteLayer;
