import React, { useContext } from "react";
import { ExecutiveHubContext } from "../../../../modules/executives-hub/state/executives-hub.context";
import { IExecutiveHubFooterItemData } from "../../../../shared/interfaces/hub-footer-item-data.interface";
import { HUB_FOOTER_NAVIGATION_TYPE } from "../ExecutivesHubFooter";
import CompanyNews from "./CompanyNews/CompanyNews";
import TopLeads from "./TopLeads/TopLeads";

interface IProps {
  item: IExecutiveHubFooterItemData;
  itemType: HUB_FOOTER_NAVIGATION_TYPE;
}

const FooterItems = ({ item, itemType }: IProps) => {
  const { setHubRolloverExecutive } = useContext(ExecutiveHubContext);

  const renderFooterItemContent = () => {
    switch (itemType) {
      case HUB_FOOTER_NAVIGATION_TYPE.LEADS:
        return <TopLeads item={item.executive} />;
      case HUB_FOOTER_NAVIGATION_TYPE.COMPANY_NEWS:
        return <CompanyNews item={item} />;
      case HUB_FOOTER_NAVIGATION_TYPE.CONTACT_EVENT:
        return <></>;
      case HUB_FOOTER_NAVIGATION_TYPE.MARKET_INSIGHT:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div
      className="hubFooterItemContainer"
      onMouseEnter={() => setHubRolloverExecutive(item.executive.id)}
    >
      {renderFooterItemContent()}
    </div>
  );
};

export default FooterItems;
