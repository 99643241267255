import { Observable } from "rxjs";
import { lastSearchApiService } from "../../../api/services/last-search-api.service";
import { createLastSearch, ILastSearch } from "./last-search.model";
import { LastSearchQuery } from "./last-search.query";
import { LastSearchStore } from "./last-search.store";

export interface ILastSearchContext {
  lastSearchTerms$: Observable<ILastSearch[]>;

  fetchUserLastSearchTerms: () => Promise<void>;
  saveLastSearchTerm: (term: string) => Promise<void>;
  deleteLastSearchTerm: (termId: number) => Promise<void>;
}

export class LastSearchFacade implements ILastSearchContext {
  lastSearchTerms$: Observable<ILastSearch[]> = this._query.selectAll();

  constructor(private _store: LastSearchStore, private _query: LastSearchQuery) {}

  async fetchUserLastSearchTerms(): Promise<void> {
    try {
      const res = await lastSearchApiService.getUserLastSearchTerms();

      this._store.set(res);
    } catch (error) {
      console.warn("Error while fetching user last search terms: ", error);
    }
  }

  async saveLastSearchTerm(term: string): Promise<void> {
    try {
      const res = await lastSearchApiService.saveLastSearchTerm({ term });

      this._store.add(
        createLastSearch({ id: res.id, term: res.term }),
        { prepend: true }, // Adds search term to the top of the last search term list
      );
    } catch (error) {
      console.warn("Error while saving last search term: ", error);
    }
  }

  async deleteLastSearchTerm(termId: number): Promise<void> {
    try {
      await lastSearchApiService.deleteLastSearchTerm(termId);

      this._store.remove(termId);
    } catch (error) {
      console.warn("Error while deleting last search term: ", error);
    }
  }
}
