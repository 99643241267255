import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ExecutivesContext } from "./../state/executive.context";
import { IExecutive } from "../state/executive.model";

interface ActiveExecutiveHook {
  activeExecutive: IExecutive;
}

export function useActiveExecutive(): ActiveExecutiveHook {
  const corporatesService = useContext(ExecutivesContext);
  const [activeExecutive] = useObservable(corporatesService.activeExecutive$, null);

  return {
    activeExecutive,
  };
}
