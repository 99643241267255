import { ReactComponent as SideNavProductTrendsIcon } from "./SideNavProductTrendsIcon.svg";
import { ReactComponent as SideNavNewsIcon } from "./SideNavNewsIcon.svg";
import { ReactComponent as SideNavIndustryIcon } from "./SideNavIndustryIcon.svg";
import { ReactComponent as SideNavHeadcountIcon } from "./SideNavHeadcountIcon.svg";
import { ReactComponent as SideNavTurnoverIcon } from "./SideNavTurnoverIcon.svg";
import { ReactComponent as SideNavTurnoverDarkIcon } from "./SideNavTurnoverDarkIcon.svg";
import { ReactComponent as SideNavInvestmentsIcon } from "./SideNavInvestmentsIcon.svg";
import { ReactComponent as SideNavManagementIcon } from "./SideNavManagementIcon.svg";
import { ReactComponent as SideNavRelationshipIcon } from "./SideNavRelationshipIcon.svg";
import { ReactComponent as SideNavLookIcon } from "./SideNavLookIcon.svg";
import { ReactComponent as SideNavShareholdersIcon } from "./SideNavShareholdersIcon.svg";

export {
  SideNavProductTrendsIcon,
  SideNavNewsIcon,
  SideNavIndustryIcon,
  SideNavHeadcountIcon,
  SideNavTurnoverIcon,
  SideNavTurnoverDarkIcon,
  SideNavInvestmentsIcon,
  SideNavManagementIcon,
  SideNavRelationshipIcon,
  SideNavLookIcon,
  SideNavShareholdersIcon,
};
