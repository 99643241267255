import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Spacing } from "../../../../../../../components/spacing/component";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RLink from "../../../../../../../components/link/RLink";
import { formatIndustrySectorForTranslation } from "../../../../../../../helpers/translation.helper";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";
import { ShareSinceUntil } from "./ShareSinceUntil";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

interface IProps {
  nameLabel: string;
  nameValue: string;
  nameLinkPath: string;
  industry: string;
  shareSinceDate: string;
  shareUntilDate: string;
  participationScore: number | null;
  starRating: number | null;
}

function RowData({
  nameLabel,
  nameValue,
  nameLinkPath,
  industry,
  shareSinceDate,
  shareUntilDate,
  participationScore,
  starRating,
}: IProps) {
  const { t } = useTranslation();
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex alignItemsCenter justifyContentSpaceBetween>
      <Flex column className={styles.infoContainer}>
        <Flex alignItemsEnd>
          <RText fontSize={isSmallerScreen ? "11" : "16"} bold className={styles.text}>
            {nameLabel + ":"}
          </RText>
          <Spacing pr="5" />
          <Flex column>
            {![null, undefined].includes(starRating) && (
              <>
                <StarRating affinity={starRating} spacing="6" iconHeight="14" />
                <Spacing pt="5" />
              </>
            )}
            <RLink path={nameLinkPath}>
              <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
                {nameValue}
              </RText>
            </RLink>
          </Flex>
        </Flex>
        <Spacing pt="10" />
        <Flex>
          <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.text}>
            {t("industry") + ":"}
          </RText>
          <Spacing pr="5" />
          <RText fontSize={isSmallerScreen ? "11" : "16"}>
            {formatIndustrySectorForTranslation(industry, t)}
          </RText>
        </Flex>
        <Spacing pt="10" />
        <ShareSinceUntil shareSinceDate={shareSinceDate} shareUntilDate={shareUntilDate} />
      </Flex>
      {participationScore > 0 && (
        <>
          <Spacing pr="20" />
          <Flex flex column alignItemsCenter>
            <div className={styles.scoreAnimationContainer}>
              {/* <ScoreAnimation
                                score={participationScore}
                                scoreCountFontSize={20}
                            /> */}
              <CircularProgressbar
                value={participationScore}
                maxValue={100}
                text={`${participationScore}%`}
                circleRatio={0.8}
                styles={buildStyles({
                  pathTransition: "none",
                  rotation: 1 / 2 + 1 / 10,
                  strokeLinecap: "butt",
                  textColor: colors.textDarkColor,
                  pathColor: "var(--color_secondary_dark)",
                  // trailColor:
                  //     "#0e67ef",
                })}
              />
            </div>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default React.memo(RowData);
