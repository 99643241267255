import React, { useContext, useEffect } from "react";
import { useSession } from "../../../../hooks/session.hook";
import { SessionContext } from "../../../../state/akita/session/session.context";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { CentralSearchContext } from "../../HomePage";
import { useHistory } from "react-router-dom";
import DarkModeSwitcher from "../dark-mode-switcher/DarkModeSwitcher";
import UploadSidePanel from "../upload-side-panel/UploadSidePanel";
import { Spacing } from "../../../../components/spacing/component";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import { ReactComponent as LogoutIcon } from "../../../../assets/Logout.svg";
import styles from "./styles.module.css";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";
import { useMediaQuery, useTheme } from "@mui/material";

const automationMarker = { [AUTOMATION_TEST_KEY]: "hdr-logout-btn" };

const Profile = () => {
  const { currentUser, fetchCurrentUser } = useSession();
  const history = useHistory();
  const { logout } = useContext(SessionContext);
  const { setSearchTerm } = useContext(CentralSearchContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!currentUser) {
      fetchCurrentUser();
    }
  }, [currentUser, fetchCurrentUser]);

  const handleLogout = () => {
    setSearchTerm("");
    logout();
    history.push("/login");
  };

  return (
    <Flex alignItemsCenter justifyContentEnd>
      <LanguageSwitcher />
      <Spacing pr="20" />
      <UploadSidePanel />
      <Spacing pr={isDesktop ? "40" : "20"} />
      <DarkModeSwitcher />
      <Spacing pr={isDesktop ? "50" : "20"} />
      <button {...automationMarker} className={styles.button} onClick={handleLogout}>
        <LogoutIcon className={styles.logoutIcon} />
      </button>
    </Flex>
  );
};

export default Profile;
