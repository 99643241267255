import ApiService from "./api.service";
import { ICorporateNote } from "../../entities/corporate-notes/state/corporate-notes.model";

interface ICorporateNoteRequest {
  elastic_company_id?: number;
  note: string;
}

class CorporateNotesApiService extends ApiService {
  public addNote(note: ICorporateNoteRequest): Promise<ICorporateNote> {
    return this.post(`/companies-notes`, note);
  }

  public updateNote(noteId: number, note: ICorporateNoteRequest): Promise<ICorporateNote> {
    return this.put(`/companies-notes/${noteId}`, note);
  }

  public deleteNote(noteId: number): Promise<void> {
    return this.delete(`/companies-notes/${noteId}`);
  }
}

export const corporateNotesApiService = new CorporateNotesApiService();
