import {
  Document,
  Page,
  View,
  Text,
  Image,
  Link,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { ICorporate } from "../../../../entities/corporates/models/corporate.model";
import { useIndustrySectorCodeData } from "../../../../hooks/corporates.hook";
import { MenuItem } from "./CorporateDossier";
import { getCurrentTenant } from "../../../../utils/tenant.util";

const currentTenant = getCurrentTenant().tenant;

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "30px 30px 50px 30px",
    marginBottom: 70,
    fontFamily: "Open Sans",
  },
  textXsm: { fontSize: 7 },
  textSm: { fontSize: 8 },
  textMd: { fontSize: 10 },
  textLg: { fontSize: 16, fontWeight: "bold" },
  mtSm: { marginTop: 8 },
  mtMd: { marginTop: 16 },
  mtXlg: { marginTop: 64 },
  mbSm: { marginBottom: 8 },
  mbMd: { marginBottom: 16 },
  mbLg: { marginBottom: 32 },
  lhMd: { lineHeight: 1.5 },
  mrXs: { marginRight: 4 },
  mrSm: { marginRight: 8 },
  mrMd: { marginRight: 16 },
  headline: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 6,
  },
  fontBold: { fontWeight: "bold" },
  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    position: "relative",
  },
  col: { width: "50%" },
  textRight: { textAlign: "right" },
  textCenter: { textAlign: "center" },
  inline: {
    display: "flex",
    flexDirection: "row",
  },
  link: {
    color: "black",
  },
  logo: {
    width: 70,
    marginBottom: 16,
  },
  externalIconLg: {
    height: 12,
    width: 12,
  },
  imgSm: {
    width: 12,
    height: 12,
  },
  imgMailIcon: {
    width: 12,
    height: 9,
  },
  blackDot: {
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#000",
    position: "relative",
  },
  line: {
    width: 70,
    height: 1,
    backgroundColor: "#000",
  },
  pageNumber: {
    position: "absolute",
    bottom: 32,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 8,
  },
  hw: { width: "50%" },
  oneFourth: { width: "25%" },
  threeFourth: { width: "75%" },
  spaceBetween: {
    justifyContent: "space-between",
  },
  externalLg: {
    width: 12,
    height: 12,
  },
  textGray: {
    color: "#999",
  },
});

interface Props {
  activeCorporate: ICorporate;
  menuItems: MenuItem[];
  locale: string;
}

const PDFDocument = ({ activeCorporate, menuItems, locale }: Props) => {
  const { t } = useTranslation();
  const {
    company_name,
    employee_counts_historic: headcountHistoric,
    turnover_historic: turnoverHistoric,
    shareholders_companies_in,
    shareholders_companies_out,
    management,
    industry_sector_codes,
  } = activeCorporate;

  const activeControllingShareholders = shareholders_companies_in
    ? shareholders_companies_in.filter((val) => !val.to_date && val.share_perc >= 50)
    : [];
  const activeMinoritiesShareholders = shareholders_companies_in
    ? shareholders_companies_in.filter((val) => !val.to_date && val.share_perc < 50)
    : [];
  const activeControllingInvestments = shareholders_companies_out
    ? shareholders_companies_out.filter((val) => !val.to_date && val.share_perc >= 50)
    : [];
  const activeMinoritiesInvestments = shareholders_companies_out
    ? shareholders_companies_out.filter((val) => !val.to_date && val.share_perc >= 50)
    : [];
  const activeManagementOwnership = management
    ? management.filter((val) => !val.to_date && val.function_rank === 1)
    : [];
  const activeManagementBoard = management
    ? management.filter((val) => !val.to_date && val.function_rank === 3)
    : [];
  const activeManagementExecutives = management
    ? management.filter((val) => !val.to_date && val.function_rank === 2)
    : [];

  const age = new Date().getFullYear() - new Date(activeCorporate.founding_date).getFullYear();

  const { industrySectorCodeData } = useIndustrySectorCodeData();

  const industrySectorCodeDataLang: "en" | "de" = locale === "de" ? "de" : "en";

  const socialMedia = [
    {
      icon: "LinkedIn.png",
      url: createGoogleLinkForCompanyAndSite(company_name, "linkedin.com"),
    },
    { icon: "Xing.png", url: createGoogleLinkForCompanyAndSite(company_name, "xing.com") },
    {
      icon: "Facebook.png",
      url: createGoogleLinkForCompanyAndSite(company_name, "facebook.com"),
    },
    {
      icon: "Twitter.png",
      url: createGoogleLinkForCompanyAndSite(company_name, "twitter.com"),
    },
    {
      icon: "Google.png",
      url: `https://www.google.${currentTenant}/search?q=${encodeURIComponent(company_name)}`,
    },
    {
      icon: "GoogleNews.png",
      url: `https://news.google.${currentTenant}/search?q=${encodeURIComponent(company_name)}`,
    },
    {
      icon: "Kununu.png",
      url: `https://www.kununu.com/${currentTenant}/search?q=${encodeURIComponent(
        company_name,
      )}&country=${currentTenant}`,
    },
    ...[
      currentTenant === "ch" && [
        {
          icon: "Jobs.png",
          url: `https://www.jobs.ch/de/stellenangebote/?term=${encodeURIComponent(
            company_name,
          )}`,
        },
        {
          icon: "Indeed.png",
          url: `https://de.indeed.com/jobs?q=${encodeURIComponent(company_name)}`,
        },
      ],
    ],
  ];

  if (!industrySectorCodeData) return null;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.mbSm}>
          <Image src="/pdf/move.png" style={styles.logo} />
          <View style={styles.inline}>
            {activeCorporate.affinity_score &&
              new Array(activeCorporate.affinity_score)
                .fill("")
                .map(() => (
                  <Image src="/pdf/affinity-star.png" style={[styles.imgSm, styles.mrXs]} />
                ))}
          </View>
          <View style={styles.flex}>
            <Text style={[styles.textLg, styles.mrMd]}>{activeCorporate.company_name}</Text>
            <Link
              src={`https://sales-hero.ai/home/search/corporate-dossier/${activeCorporate.id}`}
              style={[styles.externalLg, styles.link]}
            >
              <Image src="/pdf/external-link.png" style={styles.externalIconLg} />
            </Link>
          </View>
        </View>
        <View style={styles.mbMd}>
          <View style={styles.mtSm}>
            <View style={styles.mbSm}>
              <Text style={styles.textSm}>
                {activeCorporate.founding_date} | {age} {t("years")}
              </Text>
            </View>

            <View style={styles.mbSm}>
              <Text style={styles.textSm}>{activeCorporate.street}</Text>
              <Text style={styles.textSm}>
                {activeCorporate.zip_code} {activeCorporate.city}
              </Text>
            </View>

            <View style={[styles.flex, styles.mbSm]}>
              <View style={styles.hw}>
                {activeCorporate?.contact_info &&
                  activeCorporate.contact_info.length > 0 &&
                  activeCorporate.contact_info.map((c, i) => {
                    if (c.contact_type === "phone")
                      return (
                        <View style={[styles.flex, styles.mbSm]}>
                          <Image
                            src="/pdf/PhoneIcon.png"
                            style={[styles.imgSm, styles.mrSm]}
                          />
                          <Link src={`tel:${c.value}`} style={[styles.hw, styles.link]}>
                            <Text key={c + String(i)} style={styles.textSm}>
                              {c.value}
                            </Text>
                          </Link>
                        </View>
                      );
                    if (c.contact_type === "web")
                      return (
                        <View style={[styles.flex, styles.mbSm]}>
                          <Image src="/pdf/WebIcon.png" style={[styles.imgSm, styles.mrSm]} />
                          <Link src={c.value} style={[styles.hw, styles.link]}>
                            <Text key={c + String(i)} style={styles.textSm}>
                              {c.value}
                            </Text>
                          </Link>
                        </View>
                      );
                    if (c.contact_type === "email")
                      return (
                        <View style={[styles.flex, styles.mbSm]}>
                          <Image
                            src="/pdf/MailIcon.png"
                            style={[styles.imgMailIcon, styles.mrSm]}
                          />
                          <Link src={`mailto:${c.value}`} style={[styles.hw, styles.link]}>
                            <Text key={c + String(i)} style={styles.textSm}>
                              {c.value}
                            </Text>
                          </Link>
                        </View>
                      );

                    return null;
                  })}
                <View style={[styles.flex]}>
                  {socialMedia.map(({ icon, url }: any) => (
                    <Link src={url}>
                      <Image
                        src={`/pdf/${icon}`}
                        style={{
                          height: 12,
                          marginRight: 4,
                        }}
                      />
                    </Link>
                  ))}
                </View>
              </View>

              <View style={[styles.hw, styles.flex]}>
                <View style={[styles.hw, styles.textCenter]}>
                  <Text style={styles.textMd}>{t("successScore")}</Text>
                  <Text style={styles.textLg}>{activeCorporate.success_score}%</Text>
                </View>
                <View style={[styles.hw, styles.textCenter]}>
                  <Text style={styles.textMd}>{t("promoterScore")}</Text>
                  <Text style={styles.textLg}>{activeCorporate.promoter_score}%</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {industrySectorCodeData && (
          <View style={styles.mbMd}>
            <View>
              <Text style={[styles.headline]}>{t("mainIndustry")}</Text>
            </View>

            <View style={[styles.flex, styles.mbMd]}>
              {Object.values(industrySectorCodeData).map(
                (data) =>
                  data.code && (
                    <Text
                      style={[styles.textMd, styles.mrSm, styles.fontBold, styles.textGray]}
                    >
                      {data.code}
                    </Text>
                  ),
              )}
            </View>

            <View>
              <View style={[styles.mbSm, styles.hw]}>
                <Text style={[styles.headline]}>{industrySectorCodeData.lvl5.code}</Text>
                <Text style={styles.textSm}>
                  {industrySectorCodeData.lvl5[`title_${industrySectorCodeDataLang}`]}
                </Text>
                {industry_sector_codes.length > 1 && (
                  <>
                    <Text style={[styles.headline, styles.mtSm]}>{t("otherIndustries")}</Text>
                    <Text style={[styles.textSm]}>
                      {industry_sector_codes.map((c, i) => (
                        <Text>
                          {c}
                          {i !== industry_sector_codes.length - 1 && ", "}
                        </Text>
                      ))}
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        )}
        <View style={styles.mbMd}>
          <Text style={[styles.headline]}>{t("corporateMission")}</Text>
          <Text style={styles.textSm}>{activeCorporate.purpose ?? ""}</Text>
        </View>
        <View style={[styles.flex, styles.spaceBetween, styles.mbMd]}>
          <View style={[styles.mbSm]}>
            <Text style={[styles.headline]}>{t("registrationNumber")}</Text>
            <Text style={styles.textSm}>{activeCorporate.registration_num ?? ""}</Text>
          </View>
          <View style={[styles.mbSm]}>
            <Text style={[styles.headline]}>{t("commercialRegister")}</Text>
            <Text style={styles.textSm}>{`${activeCorporate.registration_zip ?? ""} ${
              activeCorporate.registration_city ?? ""
            }`}</Text>
          </View>
          <View style={[styles.mbSm]}>
            <Text style={[styles.headline]}>{t("vatId")}</Text>
            <Text style={styles.textSm}>{activeCorporate.vat_id ?? ""}</Text>
          </View>
        </View>
        <View style={[styles.mbMd]}>
          <Text style={[styles.headline]}>{t("insights")}</Text>
          <View style={styles.flex}>
            {menuItems.map(
              (item: MenuItem) =>
                item.counter && (
                  <View style={[styles.flex, styles.mbSm, styles.hw]}>
                    <Link
                      src={`https://sales-hero.ai/home/search/corporate-dossier/${activeCorporate.id}#${item.anchor}`}
                      style={[styles.hw, styles.link]}
                    >
                      <View style={styles.flex}>
                        <Image
                          src={`/pdf/${item.title}.png`}
                          style={{ width: 18, height: 18, marginRight: 10 }}
                        />
                        <Text style={[styles.threeFourth, styles.textSm]}>
                          {t(item.title)}
                          {item.title === "headcount" && headcountHistoric && (
                            <Text>
                              {" "}
                              ({headcountHistoric[headcountHistoric.length - 1].year})
                            </Text>
                          )}
                          {item.title === "turnover" && turnoverHistoric && (
                            <Text>
                              {" "}
                              ({turnoverHistoric[turnoverHistoric.length - 1].year})
                            </Text>
                          )}
                        </Text>
                      </View>
                    </Link>
                    <Text style={[styles.oneFourth, styles.textSm]}>{item.counter}</Text>
                  </View>
                ),
            )}
          </View>
        </View>
        {(activeControllingShareholders.length || activeMinoritiesShareholders.length) && (
          <View style={[styles.mbMd]}>
            <Text style={[styles.headline]}>{t("shareholders")}</Text>
            {activeControllingShareholders && activeControllingShareholders.length && (
              <Text style={[styles.headline]}>{t("controlling")}</Text>
            )}
            {activeControllingShareholders &&
              activeControllingShareholders.length &&
              activeControllingShareholders.map((company) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>{company.company_name}</Text>
                  <Text style={[styles.textSm]}>{company.share_perc}%</Text>
                  <Text style={[styles.textSm]}>
                    {t("industry")}: {company.industry_sector}
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("shareSince")} {company.from_date}
                  </Text>
                </View>
              ))}
            {activeMinoritiesShareholders && activeMinoritiesShareholders.length && (
              <Text style={[styles.headline]}>{t("minorities")}</Text>
            )}
            {activeMinoritiesShareholders &&
              activeMinoritiesShareholders.length &&
              activeMinoritiesShareholders.map((company) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>{company.company_name}</Text>
                  <Text style={[styles.textSm]}>{company.share_perc}%</Text>
                  <Text style={[styles.textSm]}>
                    {t("industry")}: {company.industry_sector}
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("shareSince")} {company.from_date}
                  </Text>
                </View>
              ))}
          </View>
        )}
        {(activeControllingShareholders.length || activeMinoritiesShareholders.length) && (
          <View style={[styles.mbMd]}>
            <Text style={[styles.headline]}>{t("investment")}</Text>
            {activeControllingInvestments && activeControllingInvestments.length && (
              <Text style={[styles.headline]}>{t("controlling")}</Text>
            )}
            {activeControllingInvestments &&
              activeControllingInvestments.length &&
              activeControllingInvestments.map((company) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>{company.company_name}</Text>
                  <Text style={[styles.textSm]}>{company.share_perc}%</Text>
                  <Text style={[styles.textSm]}>
                    {t("industry")}: {company.industry_sector}
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("shareSince")} {company.from_date}
                  </Text>
                </View>
              ))}
            {activeMinoritiesInvestments && activeMinoritiesInvestments.length && (
              <Text style={[styles.headline]}>{t("minorities")}</Text>
            )}
            {activeMinoritiesInvestments &&
              activeMinoritiesInvestments.length &&
              activeMinoritiesInvestments.map((company) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>{company.company_name}</Text>
                  <Text style={[styles.textSm]}>{company.share_perc}%</Text>
                  <Text style={[styles.textSm]}>
                    {t("industry")}: {company.industry_sector}
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("shareSince")} {company.from_date}
                  </Text>
                </View>
              ))}
          </View>
        )}
        {(activeManagementOwnership.length ||
          activeManagementBoard.length ||
          activeManagementExecutives.length) && (
          <View style={[styles.mbMd]}>
            <Text style={[styles.headline]}>{t("management")}</Text>
            {activeManagementOwnership.length && (
              <Text style={[styles.headline]}>{t("ownership")}</Text>
            )}
            {activeManagementOwnership.length &&
              activeManagementOwnership.map((manager) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>
                    {manager.full_name}, {manager.position}
                  </Text>
                  <Text style={[styles.textSm]}>
                    {t("promoterScore")}: {manager.promoter_score}%
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("from")} {manager.from_date}
                  </Text>
                </View>
              ))}
            {activeManagementExecutives.length && (
              <Text style={[styles.headline]}>{t("executives")}</Text>
            )}
            {activeManagementExecutives.length &&
              activeManagementExecutives.map((manager) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>
                    {manager.full_name}, {manager.position}
                  </Text>
                  <Text style={[styles.textSm]}>
                    {t("promoterScore")}: {manager.promoter_score}%
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("from")} {manager.from_date}
                  </Text>
                </View>
              ))}
            {activeManagementBoard.length && (
              <Text style={[styles.headline]}>{t("board")}</Text>
            )}
            {activeManagementBoard.length &&
              activeManagementBoard.map((manager) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>
                    {manager.full_name}, {manager.position}
                  </Text>
                  <Text style={[styles.textSm]}>
                    {t("promoterScore")}: {manager.promoter_score}%
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("from")} {manager.from_date}
                  </Text>
                </View>
              ))}
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDFDocument;

const createGoogleLinkForCompanyAndSite = (companyName: string, siteName: string) =>
  `https://www.google.${currentTenant}/search?q=${encodeURIComponent(
    companyName,
  )}%20site%3A${siteName}`;
