import { QueryEntity } from "@datorama/akita";
import { CustomTagsState, CustomTagsStore, customTagsStore } from "./custom-tags.store";

export class CustomTagsQuery extends QueryEntity<CustomTagsState> {
  constructor(protected store: CustomTagsStore) {
    super(store);
  }
}

export const customTagsQuery = new CustomTagsQuery(customTagsStore);
