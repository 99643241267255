import { tagsApiService } from "../../../api/services/tags.service";
import { ExecutiveTagsStore } from "./executive-tags.store";
import { ExecutiveTagsQuery } from "./executive-tags.query";
import { IExecutivesContext } from "../../executives/state/executives.facade";
import { Observable } from "rxjs";
import { IExecutiveTag } from "./executive-tags.model";
import { map } from "rxjs/operators";
import { IEntityTag } from "../../custom-tags/state/custom-tag.model";

export interface IExecutiveTagsContext {
  all$: Observable<IExecutiveTag[]>;

  savePersonTag: (elasticPersonId: string, tagId: number) => Promise<IEntityTag>;
  deletePersonTag: (elasticPersonId: string, tagWrapper: IEntityTag) => Promise<void>;
  getPeopleTags: (elasticPersonId: number) => void;
}

export class ExecutiveTagsFacade implements IExecutiveTagsContext {
  constructor(
    private store: ExecutiveTagsStore,
    private query: ExecutiveTagsQuery,
    private executivesFacade: IExecutivesContext,
  ) {}

  readonly all$ = this.executivesFacade.executives$.pipe(
    map((executives) => {
      return executives.map((e) => {
        const executiveTag: IExecutiveTag = {
          id: e.id,
          tags: e.tags,
        };

        return executiveTag;
      });
    }),
  );

  savePersonTag = async (elasticPersonId: string, tagId: number): Promise<IEntityTag> => {
    try {
      const res = await tagsApiService.savePersonTag(+elasticPersonId, tagId);

      this.executivesFacade.addTagToExecutive(elasticPersonId, res);

      return res;
    } catch (error) {
      console.warn("Error while saving custom tag: ", error);
    }
  };

  deletePersonTag = async (elasticPersonId: string, tagWrapper: IEntityTag): Promise<void> => {
    try {
      await tagsApiService.deletePersonTag(tagWrapper.id);

      this.executivesFacade.removeTagFromExecutive(elasticPersonId, tagWrapper);
    } catch (error) {
      console.warn("Error while deleting custom tag: ", error);
    }
  };

  async getPeopleTags(elasticPersonId: number): Promise<void> {
    try {
      await tagsApiService.getCompanyTags(elasticPersonId);

      // this.store.upsertMany(res);
    } catch (error) {
      console.warn("Error while getting tags for current user: ", error);
    }
  }
}
