import React, { useState } from "react";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import styles from "./styles.module.css";
import { classNames } from "../../../helpers/string.helper";
import { Spacing } from "../../spacing/component";
import { IInputProps } from "../../types/component.models";
import Label from "../../fonts/Label/Label";

interface ILoginInput extends IInputProps<string> {
  label?: string;
}

export const LoginInput = ({
  error,
  placeholder,
  disabled,
  type,
  autoFocus,
  value = "",
  onChange,
  style,
  name,
  inputRef,
  label,
}: ILoginInput) => {
  const [inputType, setInputType] = useState(type);

  return (
    <>
      <div className={styles.container} {...{ style }}>
        {label && <label className={styles.label}>{label}</label>}
        <input
          ref={inputRef}
          name={name}
          className={classNames(styles, { error })}
          type={inputType}
          onChange={onChange && ((evt) => onChange(evt.target.value))}
          {...{ disabled, value, placeholder, autoFocus }}
          style={label && { marginTop: 8 }}
        />
        {type === "password" && (
          <Spacing
            ph="20"
            pv="8"
            className={styles.showPasswordIcon}
            onClick={() => setInputType(inputType === "password" ? "text" : "password")}
          >
            {inputType === "password" ? (
              <VisibilityOffOutlined style={{ color: "white" }} />
            ) : (
              <VisibilityOutlined style={{ color: "white" }} />
            )}
          </Spacing>
        )}
      </div>
      {error && (
        <>
          <Spacing pt="4" />
          <Label error>{error}</Label>
          <Spacing pb="4" />
        </>
      )}
    </>
  );
};
