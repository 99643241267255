import { EntityUIQuery, QueryEntity } from "@datorama/akita";
import {
  StartupsFiltersState,
  startupsFiltersStore,
  StartupsFiltersStore,
  StartupsFiltersUIState,
} from "./startups-filters.store";

export class StartupsFiltersQuery extends QueryEntity<StartupsFiltersState> {
  ui: EntityUIQuery<StartupsFiltersUIState>;

  constructor(protected store: StartupsFiltersStore) {
    super(store);
    this.createUIQuery();
  }
}

export const startupsFiltersQuery = new StartupsFiltersQuery(startupsFiltersStore);
