import { createContext } from "react";
import { ExecutivesFacade, IExecutivesContext } from "./executives.facade";
import { executivesQuery } from "./executives.query";
import { executivesStore } from "./executives.store";
import { customTagsFacade } from "../../custom-tags/state/custom-tags.context";

export const executivesFacade = new ExecutivesFacade(
  executivesStore,
  executivesQuery,
  customTagsFacade,
);

export const ExecutivesContext = createContext<IExecutivesContext>(executivesFacade);
