import { Observable } from "rxjs";
import { FILTER_CATEGORY } from "../../../api/enums/filter-category.enum";
import { RELATIONS_COUNT } from "../../../api/enums/relationship-indicator.enum";
import { IRelationshipIndicatorResponse } from "../models/relationship-indicator.model";
import { executiveApiService } from "../../../api/services/executive-api.service";
import { ExecutiveDossierQuery } from "./executive-dossier.query";
import { ExecutiveDossierStore } from "./executive-dossier.store";
import { IExecutivesContext } from "../../executives/state/executives.facade";
import { createExecutiveFromHit, IExecutive } from "../../executives/state/executive.model";
import { map } from "rxjs/operators";
import { IFoundationReachResponse } from "../models/foundation-reach.model";
import { IEAndE } from "../models/e-and-e.model";
import { CancelToken } from "axios";

export interface IExecutiveDossierFacade {
  executiveRelationshipIndicator$: Observable<IRelationshipIndicatorResponse>;
  executiveSideNavigationActiveAnchor$: Observable<string>;
  executiveLookALikes$: Observable<IExecutive[]>;
  totalExecutiveLookALikes$: Observable<number>;
  openExecutiveFullscreenMapModal$: Observable<boolean>;
  executiveFoundationReach$: Observable<IFoundationReachResponse>;
  entrepreneursAndExecutives$: Observable<IEAndE[]>;
  totalEntrepreneursAndExecutives$: Observable<number>;

  fetchExecutiveData: (executiveId: string) => Promise<void>;
  fetchExecutiveRelationshipIndicator: (
    executiveId: string,
    cancelToken?: CancelToken,
    resetOnFetch?: boolean,
  ) => Promise<boolean>;
  fetchExecutiveLookALikes: (
    executiveId: string,
    cancelToken?: CancelToken,
  ) => Promise<boolean>;
  fetchExecutiveFoundationReach: (
    executiveId: string,
    cancelToken?: CancelToken,
  ) => Promise<boolean>;
  fetchEntrepreneursAndExecutives: (
    executiveId: string,
    cancelToken?: CancelToken,
  ) => Promise<boolean>;
  setExecutiveSideNavigationActiveAnchor: (anchor: string) => void;
  setOpenExecutiveFullscreenMapModal: (open: boolean) => void;
}

export class ExecutiveDossierFacade implements IExecutiveDossierFacade {
  executiveRelationshipIndicator$ = this._query.select("executiveRelationshipIndicator");
  executiveSideNavigationActiveAnchor$ = this._query.select(
    "executiveSideNavigationActiveAnchor",
  );
  executiveLookALikes$ = this._query
    .select("executiveLookALikes")
    .pipe(map((ids) => this._executivesService.getExecutivesFromIds(ids)));
  totalExecutiveLookALikes$ = this._query.select("totalExecutiveLookALikes");
  openExecutiveFullscreenMapModal$ = this._query.select("openExecutiveFullscreenMapModal");
  executiveFoundationReach$ = this._query.select("executiveFoundationReach");
  entrepreneursAndExecutives$ = this._query.select("entrepreneursAndExecutives");
  totalEntrepreneursAndExecutives$ = this._query.select("totalEntrepreneursAndExecutives");

  constructor(
    private _store: ExecutiveDossierStore,
    private _query: ExecutiveDossierQuery,
    private _executivesService: IExecutivesContext,
  ) {}

  fetchExecutiveData = async (executiveId: string) => {
    try {
      this._executivesService.setActiveExecutive(null);
      const res = await executiveApiService.getExecutiveData(executiveId);

      res.id = res.person_id?.toString();

      this._executivesService.addExecutives([res]);
      this._executivesService.setActiveExecutive(res.id);
    } catch (e) {
      console.warn("Error while fetching executive data: ", e);
    }
  };

  fetchExecutiveRelationshipIndicator = async (
    executiveId: string,
    cancelToken?: CancelToken,
    resetOnFetch = true,
  ) => {
    try {
      if (resetOnFetch) {
        this._store.update({
          executiveRelationshipIndicator: null,
        });
      }

      const res = await executiveApiService.getExecutiveRelationshipIndicator(
        FILTER_CATEGORY.EXECUTIVES,
        executiveId,
        RELATIONS_COUNT.FALSE,
        cancelToken,
      );

      this._store.update({
        executiveRelationshipIndicator: res,
      });

      // Returns true if data exist, otherwise false
      return !(
        (Array.isArray(res) && res.length <= 0) ||
        (res.first_line.length <= 0 && res.second_line.length <= 0)
      );
    } catch (e) {
      console.warn("Error while fetching executive relationship indicator data: ", e);
    }
  };

  fetchExecutiveLookALikes = async (executiveId: string, cancelToken?: CancelToken) => {
    try {
      this._store.update({
        executiveLookALikes: null,
        totalExecutiveLookALikes: null,
      });

      const res = await executiveApiService.getExecutiveLookALikes(
        FILTER_CATEGORY.EXECUTIVES,
        executiveId,
        cancelToken,
      );

      this._executivesService.addExecutives(
        res.hits.hits
          .map(createExecutiveFromHit)
          .filter((executive) => executive.id !== this._executivesService.getActiveId()),
      );

      this._store.update({
        executiveLookALikes: res.hits?.hits.map(({ _id }) => _id),
        totalExecutiveLookALikes: res.hits?.total.value,
      });

      return res.hits?.total.value > 0;
    } catch (e) {
      console.warn("Error while fetching executive look a likes: ", e);

      // if not possible to retrieve and/or parse results, clear possible previous look-alikes
      this._store.update({
        executiveLookALikes: [],
        totalExecutiveLookALikes: 0,
      });
    }
  };

  fetchExecutiveFoundationReach = async (executiveId: string, cancelToken?: CancelToken) => {
    try {
      this._store.update({
        executiveFoundationReach: null,
      });

      const res = await executiveApiService.getExecutiveFoundationReach(
        executiveId,
        cancelToken,
      );

      this._store.update({
        executiveFoundationReach: res,
      });

      return !(
        (Array.isArray(res) && res?.length <= 0) ||
        (res.first_line.length <= 0 && res.second_line.length <= 0)
      );
    } catch (e) {
      console.warn("Error while fetching executive foundation reach: ", e);
    }
  };

  fetchEntrepreneursAndExecutives = async (executiveId: string, cancelToken?: CancelToken) => {
    try {
      this._store.update({
        entrepreneursAndExecutives: null,
        totalEntrepreneursAndExecutives: null,
      });

      const res = await executiveApiService.getEntrepreneursAndExecutives(
        FILTER_CATEGORY.EXECUTIVES,
        executiveId,
        cancelToken,
      );

      this._store.update({
        entrepreneursAndExecutives: res.hits?.hits?.map(({ _source }) => _source) ?? [],
        totalEntrepreneursAndExecutives: res.hits?.total.value,
      });

      return res.hits?.total.value > 0;
    } catch (e) {
      console.warn("Error while fetching entrepreneurs and executives: ", e);
    }
  };

  setExecutiveSideNavigationActiveAnchor = (anchor: string) =>
    this._store.update({ executiveSideNavigationActiveAnchor: anchor });

  setOpenExecutiveFullscreenMapModal = (open: boolean) =>
    this._store.update({ openExecutiveFullscreenMapModal: open });
}
