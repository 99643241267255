import React, { useState } from "react";
import { Flex } from "../../containers/Flex/Flex";
import ColoredTag from "./ColoredTag";
import useTagColors from "../../../hooks/tag-colors.hook";

interface IProps {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
}

const ColorsPopup = ({ selectedColor, setSelectedColor }: IProps) => {
  const { tagColors } = useTagColors();
  const [toggle, setToggle] = useState(false);

  const onToggle = () => {
    setToggle((visible) => !visible);
  };

  const handleSelectedColor = (color: string) => {
    setSelectedColor(color);
    onToggle();
  };

  return (
    <Flex
      pointer
      onClick={onToggle}
      style={{
        position: "relative",
        minWidth: "12px",
      }}
    >
      <ColoredTag initialTag color={selectedColor} />
      {toggle && (
        <div
          style={{
            display: "grid",
            gap: "1px",
            gridTemplateColumns: "repeat(5, 1fr)",
            border: "1px solid var(--color_background_black)",
            background: "var(--color_background_white)",
            borderRadius: "5px",
            padding: "5px",
            position: "absolute",
            top: "20px",
            left: "-10px",
          }}
        >
          {tagColors?.map((color, index) => (
            <ColoredTag
              key={index}
              color={color}
              setSelectedColor={handleSelectedColor}
              closePopup={onToggle}
            />
          ))}
        </div>
      )}
    </Flex>
  );
};

export default ColorsPopup;
