import React, { Fragment, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import styles from "./styles.module.css";
import { ReactComponent as NotesIcon } from "../../assets/company-dossier/NotesIcon.svg";
import { ReactComponent as NotesNumberIcon } from "../../assets/company-dossier/NotesNumberIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/DeleteIcon.svg";
import { ReactComponent as CreateNoteIcon } from "../../assets/company-dossier/CreateNoteIcon.svg";
import { Spacing } from "../spacing/component";
import { Flex } from "../containers/Flex/Flex";
import RText from "../fonts/RText/RText";
import Divider from "../dividers/Divider/Divider";
import VerticalDivider from "../dividers/VerticalDivider/VerticalDivider";
import { ICorporateNote } from "../../entities/corporate-notes/state/corporate-notes.model";
import { IExecutiveNote } from "../../entities/executive-notes/state/executive-notes.model";
import useAppColors from "../../hooks/app-colors.hook";
import { useLngString } from "../../localization/i18n";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";

type NoteType = ICorporateNote | IExecutiveNote;

interface IProps {
  entityId: string;
  notes: NoteType[];
  addNote: (entityId: number, newNote: string) => void;
  updateNote: (noteId: number, updatedNote: string) => void;
  deleteNote: (noteId: number) => void;
}

const RNotes = ({ entityId, notes, addNote, updateNote, deleteNote }: IProps) => {
  const { t } = useTranslation();
  const browserLng = useLngString();
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);

  const [noteText, setNoteText] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);

  const onNoteClick = (note: NoteType) => updateState(note, note.note);
  const onNoteTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNoteText(event.target.value);
  const onCreateNoteIconClick = () => updateState(null, "");

  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    saveNote();
  };

  const saveNote = () => {
    if (!noteText.trim().length) {
      return;
    }

    if (!selectedNote) {
      addNote(+entityId, noteText.trim());
    } else {
      updateNote(selectedNote.id, noteText.trim());
    }

    updateState(null, "");
  };

  const onDeleteIconClick = () => {
    deleteNote(selectedNote.id);
    updateState(null, "");
  };

  const createNoteIconRef = useRef(null);
  const deleteNoteIconRef = useRef(null);
  const notesListContainerRef = useRef(null);
  const textAreaRef = useRef(null);

  const onNotesMainContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      createNoteIconRef.current?.contains(event.target) ||
      deleteNoteIconRef.current?.contains(event.target) ||
      notesListContainerRef.current?.contains(event.target) ||
      textAreaRef.current?.contains(event.target)
    ) {
      return;
    }

    saveNote();
  };

  const updateState = (selectedNote: NoteType, noteText: string) => {
    setSelectedNote(selectedNote);
    setNoteText(noteText);
    textAreaRef.current?.focus();
  };

  return (
    <Popup
      trigger={
        <div>
          <Flex pointer alignItemsCenter>
            <NotesIcon
              color={colors.backgroundBlackColor}
              className={isSmallerScreen ? styles.notesIconSmaller : styles.notesIcon}
            />
            <Flex
              alignItemsCenter
              justifyContentCenter
              className={styles.notesNumberContainer}
            >
              <NotesNumberIcon
                color={colors.primaryDarkColor}
                className={
                  isSmallerScreen ? styles.notesNumberIconSmaller : styles.notesNumberIcon
                }
              />
              <RText
                immutableBlack
                fontSize={isSmallerScreen ? "12" : "16"}
                bold
                className={styles.notesNumber}
              >
                {`${notes.length}`}
              </RText>
            </Flex>
            <Spacing pr="5" />
            <RText fontSize={isSmallerScreen ? "15" : "18"}>{t("notes")}</RText>
          </Flex>
        </div>
      }
      position="bottom right"
      offsetX={browserLng === "en" ? -42 : -48}
      offsetY={5}
      contentStyle={{
        zIndex: 1111,
        width: "auto",
        border: "1px solid var(--color_background_black)",
        backgroundColor: "var(--color_background_white)",
        borderRadius: "5px",
        color: "var(--color_background_black)",
        padding: "0px",
      }}
      arrowStyle={{
        stroke: "var(--color_background_black)",
        color: "var(--color_background_white)",
        filter: "none",
      }}
      closeOnEscape={false}
      onClose={() => saveNote()}
    >
      <div onClick={onNotesMainContainerClick} className={styles.notesContentContainer}>
        <Flex justifyContentEnd className={styles.createNoteIconContainer}>
          <Flex elementRef={createNoteIconRef} pointer onClick={onCreateNoteIconClick}>
            <CreateNoteIcon />
          </Flex>
        </Flex>
        <Divider />
        <Flex className={styles.notesDataContainer}>
          <Flex
            elementRef={notesListContainerRef}
            column
            className={styles.notesListContainer}
          >
            {notes.map((note: NoteType) => {
              return (
                <Fragment key={note.id}>
                  <Flex
                    pointer
                    onClick={() => onNoteClick(note)}
                    className={`
                                                ${styles.noteTextWrapper}
                                                ${
                                                  selectedNote?.id === note.id
                                                    ? styles.activeNote
                                                    : ""
                                                }
                                            `}
                  >
                    <RText fontSize="16" noWrap ellipsis>
                      {note.note}
                    </RText>
                  </Flex>
                  <Divider />
                </Fragment>
              );
            })}
          </Flex>
          <Flex column>
            <VerticalDivider />
          </Flex>
          <Flex column className={styles.noteInputContainer}>
            <Spacing ph="15" pv="20">
              <RText fontSize="16">{`${noteText.length}/500`}</RText>
              <Spacing pb="10" />
              <Flex alignItemsStart>
                <textarea
                  ref={textAreaRef}
                  placeholder={t("newNotePlaceholder")}
                  className={styles.noteTextarea}
                  maxLength={500}
                  spellCheck={false}
                  value={noteText}
                  onChange={onNoteTextChange}
                  onKeyDown={onEnterKeyPress}
                />
                {selectedNote && (
                  <>
                    <Spacing pr="10" />
                    <Flex elementRef={deleteNoteIconRef} pointer onClick={onDeleteIconClick}>
                      <DeleteIcon className={styles.deleteNoteIcon} />
                    </Flex>
                  </>
                )}
              </Flex>
            </Spacing>
          </Flex>
        </Flex>
      </div>
    </Popup>
  );
};

export default RNotes;
