import { QueryEntity } from "@datorama/akita";
import {
  CorporateNotesStore,
  CorporateNotesState,
  corporateNotesStore,
} from "./corporate-notes.store";

export class CorporateNotesQuery extends QueryEntity<CorporateNotesState> {
  constructor(protected store: CorporateNotesStore) {
    super(store);
  }
}

export const corporateNotesQuery = new CorporateNotesQuery(corporateNotesStore);
