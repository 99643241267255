import { capitalizeFirstLetter } from "./string.helper";

export function formatIndustrySectorForTranslation(
  industrySector: string,
  t: CallableFunction,
) {
  if (!industrySector || industrySector.length === 0) return "";

  const numberOfWords = industrySector.split(" ").length;

  if (numberOfWords > 1) {
    const stringArray = industrySector.toLowerCase().split(" ");
    let camelCaseString = "";

    for (let i = 0; i < stringArray.length; ++i) {
      if (i === 0) {
        camelCaseString += stringArray[i];
        continue;
      }

      camelCaseString += capitalizeFirstLetter(stringArray[i]);
    }

    return t(camelCaseString);
  }

  return t(industrySector.toLowerCase());
}

export function formatGenderForTranslation(gender: string, t: CallableFunction) {
  if (!gender || gender.length === 0) return "";

  if (gender === "M") return t("male");
  if (gender === "F") return t("female");

  return "";
}
