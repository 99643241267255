import React, { Fragment, useContext, useEffect, useState } from "react";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IPeopleManagement } from "../../../../../../../entities/executives/interfaces/people-management.interface";
import { abbreviateNumber } from "../../../../../../../helpers/number.helper";
import { t } from "../../../../../../../localization/i18n";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { formatPeriodDate } from "../../../../../corporates/dossier/components/side-navigation-item-content/management/Management";
import EAndEScore from "../../../shared/EAndEScore/EAndEScore";
import EntityInfo, { EntityType } from "../../../shared/EntityInfo";
import TableRow from "../../../shared/TableRow";
import { IEAndE } from "../../../../../../../entities/executive-dossier/models/e-and-e.model";
import { getExecutiveActiveCompany } from "../../../../../../../helpers/dossier.helper";
import { executiveApiService } from "../../../../../../../api/services/executive-api.service";
import { IShareholder } from "../../../../../../../entities/executives/interfaces/shareholder.interface";

interface IProps {
  people: IEAndE[];
}

const EntrepreneursAndExecutives = ({ people }: IProps) => {
  const [formattedPeopleData, setFormattedPeopleData] = useState<IPeople[]>([]);
  const { isSmallerScreen } = useContext(ResolutionContext);

  useEffect(() => {
    mapPeople(people).then((data) => {
      setFormattedPeopleData(data);
    });
  }, [people]);

  return (
    <>
      <RText fontSize="20" bold>
        {t("entrepreneursAndExecutives")}
      </RText>
      <Spacing pt={isSmallerScreen ? "14" : "28"} />
      <TableRow
        col1Data={t("name")}
        col2Data={t("position")}
        col3Data={t("period")}
        col4Data={t("eAndE")}
        header
      />
      <Spacing pt="20" />
      {formattedPeopleData?.map((person, index) => {
        return (
          <Fragment key={person.id}>
            <TableRow
              col1Data={
                <EntityInfo
                  entityId={person.id}
                  entityType={EntityType.EXECUTIVE}
                  entityName={person.fullName}
                  ratingScore={person.wealthScore}
                  subTitle={person.company ? person.company.company_name : ""}
                  headcount={
                    person.company
                      ? person.company.employee_count
                        ? abbreviateNumber(person.company.employee_count)
                        : null
                      : ""
                  }
                  turnover={
                    person.company
                      ? person.company.turnover
                        ? abbreviateNumber(person.company.turnover)
                        : null
                      : ""
                  }
                />
              }
              col2Data={person.company ? person.company.position : ""}
              col3Data={person.company ? formatPeriodDate(person.company, t) : ""}
              col4Data={<EAndEScore score={person.eeScore} />}
            />

            {index < formattedPeopleData.length - 1 && <Divider />}
          </Fragment>
        );
      }) ?? <></>}
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
    </>
  );
};

export default EntrepreneursAndExecutives;

interface IPeople {
  id: string;
  fullName: string;
  wealthScore: number;
  eeScore: string;
  company?: IPeopleManagement | IShareholder;
}

const mapPeople = async (people: IEAndE[]) => {
  return await Promise.all(
    people?.map(async (person) => {
      return await getPersonCompany(person).then((data) => {
        return {
          id: person.person_id?.toString(),
          fullName: person.full_name,
          wealthScore: person.wealth_score,
          eeScore: person.ee_score?.toString(),
          company: data,
        };
      });
    }) ?? [],
  );
};

/**
 * Searches the management field for executives active company,
 * if its null then it gets executive in question and searches
 * its shareholder field for active company
 */
const getPersonCompany = async (person: IEAndE): Promise<IPeopleManagement | IShareholder> => {
  const companyFromManagement = getExecutiveActiveCompany(
    person.management,
    person.main_company_id,
  );
  if (companyFromManagement === null) {
    var companyFromShareholders: IPeopleManagement | IShareholder = null;
    await executiveApiService.getExecutiveData(person.person_id.toString()).then((data) => {
      companyFromShareholders = getExecutiveActiveCompany(
        person.management,
        person.main_company_id,
        data.shareholder,
      );
    });
    return companyFromShareholders;
  } else {
    return companyFromManagement;
  }
};
