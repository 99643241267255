import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { IExecutiveHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";
import { ExecutiveHubContext } from "../state/executives-hub.context";

interface ITopLeadsHook {
  hubLeadsFooterItems: IExecutiveHubFooterItemData[];
}

export const useTopLeads = (): ITopLeadsHook => {
  const executiveHubContext = useContext(ExecutiveHubContext);

  const [hubLeadsFooterItems] = useObservable(
    executiveHubContext.hubLeadsFooterItems$,
    executiveHubContext.getHubLeadsFooterItems(),
  );

  return {
    hubLeadsFooterItems,
  };
};
