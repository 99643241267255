import React from "react";
import { useTranslation } from "react-i18next";
import { IShareholderCompanies } from "../../../../../../../entities/corporates/models/shareholders-companies.model";
import { IShareholderPeople } from "../../../../../../../entities/corporates/models/shareholders-people.model";
import Investment from "../investment/Investment";

interface IProps {
  shareholdersCompanies: IShareholderCompanies[];
  shareholdersPeople: IShareholderPeople[];
}

export default function Shareholders({ shareholdersCompanies, shareholdersPeople }: IProps) {
  const { t } = useTranslation();

  if (!shareholdersCompanies?.length && !shareholdersPeople?.length) {
    return null;
  }

  return (
    <Investment
      shareholdersCompanies={shareholdersCompanies}
      componentTitle={t("shareholders")}
      shareholdersPeople={shareholdersPeople}
    />
  );
}
