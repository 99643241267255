export const spaceWithName = (name: string) => {
  switch (name) {
    case "page-top":
      return 48;
    case "page-sides":
      return 160;
    case "page-sides-small":
      return 60;

    default:
      return +name;
  }
};
