import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import styles from "./styles.module.css";
import { ISubSignal } from "../../../../../../../entities/corporate-dossier/corporate-dossier.model";
import { useCloseOnClickOutside } from "../../../../../../../hooks/componentRefs.hook";

export const ProductTrendPopup = ({ signal }: { signal: ISubSignal }) => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [ref] = useCloseOnClickOutside(
    () => {
      setTimeout(() => {
        setIsPopupOpen(false);
      }, 0);
    },
    { capture: true },
  );

  const publicationDate = signal.publicationDate ? new Date(signal.publicationDate) : null;

  return (
    <div className={styles.impulseLinkContainer}>
      <button
        className={`${styles.button} ${styles.impulseLink}`}
        onClick={
          isPopupOpen
            ? () => {
                setTimeout(() => {
                  setIsPopupOpen(false);
                }, 0);
              }
            : () => {
                setTimeout(() => {
                  setIsPopupOpen(true);
                }, 0);
              }
        }
      >
        {t("readMore")}
      </button>
      <div
        ref={ref}
        className={styles.internalImpulseContainer}
        style={{ visibility: isPopupOpen ? "visible" : "hidden" }}
      >
        {signal.snippet}
        <div className={styles.internalImpulseDivider}></div>
        <span className={styles.internalImpulseMetadata}>
          <b>{t("source")}:</b> Handelsregister
          <br />
          {publicationDate && (
            <span>
              <b>{t("publishDate")}: </b>
              {format(publicationDate, "dd.MM.yyyy")}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};
