import React from "react";
import styles from "./styles.module.css";
import { Flex } from "../../containers/Flex/Flex";
import ColoredContainer from "../../containers/ColoredContainer/ColoredContainer";
import RText from "../../fonts/RText/RText";

interface IProps {
  icon: string;
  count: number;
}

const CountBox = ({ icon, count }: IProps) => {
  return (
    <Flex alignItemsCenter className={styles.countBoxContainer}>
      <img style={{ height: "24px" }} src={icon} alt="" />
      <ColoredContainer
        primaryGreenDark
        circular
        size="18"
        className={styles.coloredContainer}
      >
        <RText immutableBlack bold fontSize="9">
          {count >= 0 ? count?.toString() : ""}
        </RText>
      </ColoredContainer>
    </Flex>
  );
};

export default CountBox;
