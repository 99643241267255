import React, { useContext } from "react";
import RText from "../fonts/RText/RText";
import styles from "./styles.module.css";
import Select from "./Select";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";
import { IActiveOption, IOption } from "./types";

interface IProps {
  title: string;
  values: number[];
  setValues: (values: number[]) => void;
}

const ScoreFilter = ({ title, values, setValues }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const options: IOption[] = [
    {
      label: "0 - 20",
      value: 20,
    },
    {
      label: "21 - 40",
      value: 40,
    },
    {
      label: "41 - 60",
      value: 60,
    },
    {
      label: "61 - 80",
      value: 80,
    },
    {
      label: "81 - 100",
      value: 100,
    },
  ];

  const Title = () => (
    <RText fontSize={isSmallerScreen ? "13" : "16"} className={styles.dropdownTitle}>
      {title}
    </RText>
  );

  const Option = ({ label, value, active, handleOption }: IActiveOption) => (
    <div
      onClick={() => handleOption(value)}
      className={`${styles.dropdownOption} ${active && styles.dropdownOption__Active}`}
    >
      <RText fontSize={isSmallerScreen ? "13" : "16"}>{label}</RText>
    </div>
  );

  return (
    <Select
      values={values}
      handleValues={setValues}
      options={options}
      Title={Title}
      SelectOption={Option}
    />
  );
};

export default ScoreFilter;
