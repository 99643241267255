import React from "react";
import { classNames } from "../../../helpers/string.helper";
import styles from "./styles.module.css";

interface IProps {
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children?: React.ReactNode;
  column?: boolean;
  justifyContentSpaceBetween?: boolean;
  justifyContentSpaceEvenly?: boolean;
  justifyContentCenter?: boolean;
  justifyContentEnd?: boolean;
  alignItemsStart?: boolean;
  alignItemsCenter?: boolean;
  alignItemsEnd?: boolean;
  alignSelfStart?: boolean;
  grow?: boolean;
  noShrink?: boolean;
  wrap?: boolean;
  flex?: boolean;
  pointer?: boolean;
  className?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  id?: string;
  elementRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

export const Flex = ({
  onClick,
  onMouseEnter,
  onMouseLeave,
  column,
  justifyContentSpaceBetween,
  justifyContentSpaceEvenly,
  justifyContentCenter,
  justifyContentEnd,
  alignItemsStart,
  alignItemsCenter,
  alignItemsEnd,
  alignSelfStart,
  grow,
  noShrink,
  wrap,
  flex,
  pointer,
  className,
  children,
  fullWidth,
  fullHeight,
  id,
  elementRef,
  style,
  ...rest
}: IProps) => (
  <div
    {...rest}
    id={id}
    ref={elementRef}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    style={style}
    className={classNames(styles, {
      column,
      justifyContentSpaceBetween,
      justifyContentSpaceEvenly,
      justifyContentCenter,
      justifyContentEnd,
      alignItemsStart,
      alignItemsCenter,
      alignItemsEnd,
      alignSelfStart,
      grow,
      noShrink,
      wrap,
      flex,
      pointer,
      className,
      fullWidth,
      fullHeight,
    })}
    {...rest}
    {...{ children }}
    {...(onClick
      ? {
          role: "button",
          tabIndex: 0,
        }
      : {})}
  />
);
