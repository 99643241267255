import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
// import Spinner from "../../../components/other/Spinner/Spinner";
import Selections from "../selections/Selections/Selections";
import { useUserTags } from "../../../entities/custom-tags/hooks/user-tags.hook";
import { useExecutivesSearchResponse } from "../../../entities/executives-filters/hooks/executives-search-response.hook";
import { ExecutivesFiltersContext } from "../../../entities/executives-filters/state/executives-filters.context";
import { useExecutivesSearchHits } from "../../../entities/executives-filters/hooks/executives-total-count.hook";

const ExecutivesSelections = () => {
  const { t } = useTranslation();
  const { userTags } = useUserTags();
  const { assignTag } = useExecutivesSearchResponse();
  const executivesFiltersContext = useContext(ExecutivesFiltersContext);
  const selectionTags = userTags.filter((item) => item.type === "selection");
  const { totalCount } = useExecutivesSearchHits();

  const searchByTag = (tagName: string) => {
    executivesFiltersContext.deselectAllSearchFilterValues();
    executivesFiltersContext.selectSearchFilterValue("people_tags", tagName);
  };

  // if (loadingSelections) {
  //     return <Spinner />;
  // }

  return (
    <Selections
      tags={selectionTags}
      assignTag={assignTag}
      searchByTag={searchByTag}
      totalCount={totalCount}
      tagLabel={t("executives")}
    />
  );
};

export default ExecutivesSelections;
