import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  ICorporateData,
  IIndustrySectorCode,
  INews,
  IProductTrendsResponse,
  IRelationshipIndicatorResponse,
  ISignals,
} from "./corporate-dossier.model";
import { CorporateDossierQuery } from "./corporate-dossier.query";
import { CorporateDossierStore } from "./corporate-dossier.store";
import {
  createCorporateFromHit,
  createCorporateFromHitSource,
  ICorporate,
} from "../corporates/models/corporate.model";
import { ICorporatesContext } from "../corporates/state/corporates.facade";
import { corporatesApiService } from "../../api/services/corporates-api.service";
import { FILTER_CATEGORY } from "../../api/enums/filter-category.enum";
import { RELATIONS_COUNT } from "../../api/enums/relationship-indicator.enum";
import { CancelToken } from "axios";

export enum CORPORATE_SOURCE {
  HUB,
  CENTRAL_SEARCH,
}

export interface ICorporateDossierFacade {
  industrySectorCodeData$: Observable<IIndustrySectorCode>;
  loadingIndustryCodeData$: Observable<boolean>;
  corporateLookALikes$: Observable<ICorporate[]>;
  totalCorporateLookALikes$: Observable<number>;
  corporateNews$: Observable<INews[]>;
  corporateProductTrends$: Observable<ISignals[]>;
  totalCorporateProductTrends$: Observable<number>;
  corporateRelationshipIndicator$: Observable<IRelationshipIndicatorResponse>;
  openFullscreenMapModal$: Observable<boolean>;
  corporateSideNavigationActiveAnchor$: Observable<string>;

  fetchCorporateData: (corporateId: string) => Promise<void>;
  fetchIndustrySectorCodeData: (sectorCode: string) => Promise<void>;
  fetchCorporateLookALikes: (
    companyId: string,
    cancelToken?: CancelToken,
    fullResponse?: boolean,
  ) => Promise<boolean | any>;
  fetchCorporateNews: (
    companyId: string,
    companyData: ICorporateData,
    cancelToken?: CancelToken,
  ) => Promise<boolean>;
  fetchCorporateProductTrends: (
    companyId: string,
    companyData: ICorporateData,
    cancelToken?: CancelToken,
  ) => Promise<boolean>;
  fetchCorporateRelationshipIndicator: (
    companyId: string,
    cancelToken?: CancelToken,
    resetOnFetch?: boolean,
  ) => Promise<boolean>;
  setOpenFullscreenMapModal: (open: boolean) => void;
  setCorporateSideNavigationActiveAnchor: (anchor: string) => void;
}

export class CorporateDossierFacade implements ICorporateDossierFacade {
  industrySectorCodeData$ = this._query.select("industrySectorCodeData");
  loadingIndustryCodeData$ = this._query.select("loadingIndustryCodeData");
  corporateLookALikes$ = this._query
    .select("corporateLookALikes")
    .pipe(map((ids) => this._corporatesService.getCorporatesFromIds(ids)));
  totalCorporateLookALikes$ = this._query.select("totalCorporateLookALikes");
  corporateNews$ = this._query.select("corporateNews");
  corporateProductTrends$ = this._query.select("corporateProductTrends");
  totalCorporateProductTrends$ = this._query.select("totalCorporateProductTrends");
  corporateRelationshipIndicator$ = this._query.select("corporateRelationshipIndicator");
  openFullscreenMapModal$ = this._query.select("openFullscreenMapModal");
  corporateSideNavigationActiveAnchor$ = this._query.select(
    "corporateSideNavigationActiveAnchor",
  );

  constructor(
    private _store: CorporateDossierStore,
    private _query: CorporateDossierQuery,
    private _corporatesService: ICorporatesContext,
  ) {}

  fetchCorporateData = async (corporateId: string) => {
    try {
      this._corporatesService.setActiveCorporate(null);
      const res = await corporatesApiService.getById(corporateId);

      const corporate = createCorporateFromHitSource(res);

      this._corporatesService.addCorporates([corporate]);
      this._corporatesService.setActiveCorporate(corporate.id);
    } catch (e) {
      console.warn("Error while fetching corporate data: ", e);
    }
  };

  fetchIndustrySectorCodeData = async (sectorCode: string) => {
    try {
      this._store.update({
        loadingIndustryCodeData: true,
        industrySectorCodeData: null,
      });

      const res = await corporatesApiService.getIndustrySectorCodeData(sectorCode);

      this._store.update({
        industrySectorCodeData: res,
        loadingIndustryCodeData: false,
      });
    } catch (e) {
      this._store.update({ loadingIndustryCodeData: false });
      console.warn("Error while fetching industry sector code data: ", e);
    }
  };

  fetchCorporateLookALikes = async (
    companyId: string,
    cancelToken?: CancelToken,
    fullResponse?: boolean,
  ) => {
    try {
      // On each fetch we need to reset data first
      this._store.update({
        corporateLookALikes: null,
        totalCorporateLookALikes: null,
      });

      const res = await corporatesApiService.getCorporateLookALikes(
        FILTER_CATEGORY.CORPORATES,
        companyId,
        cancelToken,
      );

      this._corporatesService.addCorporates(
        res.hits.hits
          .map(createCorporateFromHit)
          .filter((corporate) => corporate.id !== this._corporatesService.getActiveId()),
      );

      this._store.update({
        corporateLookALikes: res.hits?.hits.map(({ _id }) => _id),
        totalCorporateLookALikes: res.hits?.total.value,
      });

      return fullResponse ? res.hits : res.hits?.total.value > 0;
    } catch (e) {
      console.warn("Error while fetching corporate look a likes: ", e);
    }
  };

  fetchCorporateNews = async (
    companyId: string,
    companyData: ICorporateData,
    cancelToken?: CancelToken,
  ) => {
    try {
      this._store.update({
        corporateNews: null,
      });

      const res = await corporatesApiService.getCorporateNews(
        companyId,
        companyData,
        cancelToken,
      );

      this._store.update({
        corporateNews: res?.data?.news,
      });

      return res?.data?.news?.length > 0;
    } catch (e) {
      console.warn("Error while fetching corporate news: ", e);
    }
  };

  fetchCorporateProductTrends = async (
    companyId: string,
    companyData: ICorporateData,
    cancelToken?: CancelToken,
  ) => {
    try {
      this._store.update({
        corporateProductTrends: null,
        totalCorporateProductTrends: null,
      });

      const res: IProductTrendsResponse = await corporatesApiService.getCorporateProductTrends(
        companyId,
        companyData,
        cancelToken,
      );

      this._store.update({
        corporateProductTrends: res.data?.signals,
        totalCorporateProductTrends: res.data?.count,
      });

      return res.data?.count > 0;
    } catch (e) {
      console.warn("Error while fetching corporate product trends: ", e);
    }
  };

  fetchCorporateRelationshipIndicator = async (
    companyId: string,
    cancelToken?: CancelToken,
    resetOnFetch = true,
  ) => {
    try {
      if (resetOnFetch) {
        this._store.update({
          corporateRelationshipIndicator: null,
        });
      }

      const res = await corporatesApiService.getCorporateRelationshipIndicator(
        FILTER_CATEGORY.CORPORATES,
        companyId,
        RELATIONS_COUNT.FALSE,
        cancelToken,
      );

      this._store.update({
        corporateRelationshipIndicator: res,
      });

      return !(
        (Array.isArray(res) && res.length <= 0) ||
        (res.first_line.length <= 0 && res.second_line.length <= 0)
      );
    } catch (e) {
      console.warn("Error while fetching corporate relationship indicator data: ", e);
    }
  };

  setOpenFullscreenMapModal = (open: boolean) =>
    this._store.update({ openFullscreenMapModal: open });

  setCorporateSideNavigationActiveAnchor = (anchor: string) =>
    this._store.update({ corporateSideNavigationActiveAnchor: anchor });
}
