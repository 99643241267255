import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { IExecutive } from "../../executives/state/executive.model";
import { ExecutivesFiltersContext } from "../state/executives-filters.context";
import { EXPORT_TYPE } from "../../../shared/enums/export-type.enum";

interface UseExecutivesSearchResponseHook {
  searchResultTotalCount: number;
  searchResults: IExecutive[];
  fetchMore(): void;
  exportResults(type: EXPORT_TYPE): void;
  assignTag(tagId: number): Promise<void>;
  loading: boolean;
}

export function useExecutivesSearchResponse(): UseExecutivesSearchResponseHook {
  const executivesFiltersService = useContext(ExecutivesFiltersContext);

  const [searchResultTotalCount] = useObservable(
    executivesFiltersService.searchResultTotalCount$,
    null,
  );

  const [searchResultIds] = useObservable(executivesFiltersService.searchResultIds$, []);

  const [searching] = useObservable(executivesFiltersService.searching$, true);

  const fetchMore = () => executivesFiltersService.searchNextPage();

  const exportResults = (type: EXPORT_TYPE) => executivesFiltersService.export(type);

  const assignTag = async (tagId: number) => await executivesFiltersService.assignTag(tagId);

  return {
    searchResultTotalCount: searchResultTotalCount,
    searchResults: executivesFiltersService.getExecutives(searchResultIds),
    fetchMore,
    exportResults,
    assignTag,
    loading: searching,
  };
}
