import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Spacing } from "../../spacing/component";
import { Flex } from "../../containers/Flex/Flex";
import RText from "../../fonts/RText/RText";
import { ITag } from "../../../entities/custom-tags/state/custom-tag.model";
import { CustomTagsContext } from "../../../entities/custom-tags/state/custom-tags.context";
import useAppColors from "../../../hooks/app-colors.hook";
import ColorsPopup from "../create-tag/ColorsPopup";

interface IProps {
  searchByTag: (tagName: string) => void;
  addTagToEntity: (tagId: number) => Promise<void>;
}

const SearchSaveWithTag = ({ addTagToEntity, searchByTag }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();
  const customTagsContext = useContext(CustomTagsContext);

  const [selectedColor, setSelectedColor] = useState(colors.backgroundWhiteColor);
  const [tagName, setTagName] = useState("");

  const onInputChange = (event: any) => setTagName(event.target.value);

  const onAddTag = async () => {
    if (tagName.trim() === "") {
      return;
    }

    const tag: ITag = await customTagsContext.saveCustomTag(
      tagName,
      selectedColor,
      "selection",
    );
    await addTagToEntity(tag.id);
    await searchByTag(tagName);

    setSelectedColor(colors.backgroundWhiteColor);
  };

  return (
    <Flex alignItemsCenter>
      <Spacing pr="10" />
      <Flex alignItemsCenter grow>
        <input
          type="text"
          maxLength={20}
          className={styles.addTagInput}
          placeholder={t("tagNamePlaceholder")}
          onChange={onInputChange}
          style={{
            border: "1px solid var(--color_background_black)",
          }}
        />
        <Spacing pr="10" />
        <ColorsPopup selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
        <Spacing pr="10" />
        <Flex className={styles.createTagButton} pointer onClick={onAddTag}>
          <RText bold fontSize="15">
            OK
          </RText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchSaveWithTag;
