import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ICorporate } from "./../../corporates/models/corporate.model";
import { StartupsFiltersContext } from "../state/startups-filters.context";

interface UseStartupsSearchResponseHook {
  searchResultTotalCount: number;
  searchResults: ICorporate[];
  fetchMore(): void;
  loading: boolean;
}

export function useStartupsSearchResponse(): UseStartupsSearchResponseHook {
  const startupsFiltersService = useContext(StartupsFiltersContext);

  const [searchResultTotalCount] = useObservable(
    startupsFiltersService.searchResultTotalCount$,
    null,
  );

  const [searchResultIds] = useObservable(startupsFiltersService.searchResultIds$, []);

  const [searching] = useObservable(startupsFiltersService.searching$, true);

  const fetchMore = () => startupsFiltersService.searchNextPage();

  return {
    searchResultTotalCount: searchResultTotalCount,
    searchResults: startupsFiltersService.getCorporates(searchResultIds),
    fetchMore,
    loading: searching,
  };
}
