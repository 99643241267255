import { useContext, useEffect, useState } from "react";
import ExecutivesHubMain from "./ExecutivesHubMain/ExecutivesHubMain";
import ExecutivesHubFooter from "./ExecutivesHubFooter/ExecutivesHubFooter";
import { useTranslation } from "react-i18next";
import { Flex } from "../../components/containers/Flex/Flex";
import RHeading4 from "../../components/fonts/RHeading4/RHeading4";
import Text from "../../components/fonts/Text/Text";
import {
  useExecutivesHub,
  useExecutivesHubFirstLoad,
} from "../../modules/executives-hub/hooks/executives-hub.hook";
import { ExecutivesFiltersContext } from "../../entities/executives-filters/state/executives-filters.context";
import { Box, Button, Collapse, Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import ExecutivesHubSidePanel from "./ExecutivesHubMain/ExecutivesHubSidePanel/ExecutivesHubSidePanel";
import { useExecutiveHubMarkers } from "../../modules/executives-hub/hooks/executives-hub-markers.hook";
import { MapContext } from "../../context/MapContext";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import useAppColors from "../../hooks/app-colors.hook";

const ExecutivesHubPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const { state } = useContext(MapContext);
  const { colors } = useAppColors();

  const [showNews, setShowNews] = useState<boolean>(upMd);

  const { executiveHubMarkers } = useExecutiveHubMarkers();
  const [filteredHubMarkers, setFilteredHubMarkers] = useState(null);

  const { fetchHubExecutives, hubErrorNoData } = useExecutivesHub();
  const { firstLoad } = useExecutivesHubFirstLoad();
  const executivesFiltersService = useContext(ExecutivesFiltersContext);

  function arePointsNear(checkPoint: any, centerPoint: any, km: any) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lon) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  useEffect(() => {
    if (!state.center || !state.center?.lat || !state.center?.lng) {
      setFilteredHubMarkers(executiveHubMarkers);

      return;
    }

    setFilteredHubMarkers(() =>
      executiveHubMarkers.filter((item: any) =>
        arePointsNear(item.coordinates, state.center, state.radius),
      ),
    );
  }, [executiveHubMarkers, state.radius, state.center, state]);

  useEffect(() => {
    if (firstLoad) {
      // Fetch execuitves after filters, because executives need to
      // update filter facets AFTER filters are already saved to the store
      executivesFiltersService.fetchExecutivesHubFilters().then(() => {
        fetchHubExecutives();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  if (hubErrorNoData) {
    return (
      <Flex fullHeight justifyContentCenter alignItemsCenter column>
        <RHeading4>{t("noDataAvailableForCurrentUser")}</RHeading4>
        <Flex pointer onClick={fetchHubExecutives}>
          <Text primaryBlue underlinePrimary>
            {t("tryAgain")}
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item md={8} xs={12} minHeight={`calc(100vh - ${upMd ? "70px" : "120px"})`}>
          <ExecutivesHubMain />
        </Grid>
        <Grid
          item
          md={4}
          maxHeight="calc(100vh - 70px)"
          overflow="scroll"
          sx={{ overflowX: "hidden", display: { xs: "none", md: "block" } }}
        >
          <ExecutivesHubSidePanel
            hideFilter={false}
            executiveHubMarkers={filteredHubMarkers ?? executiveHubMarkers}
          />
        </Grid>
      </Grid>
      <Box
        position="absolute"
        bottom={0}
        width="100%"
        zIndex={1002}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Button
          onClick={() => setShowNews((prevState) => !prevState)}
          sx={{
            position: "absolute",
            left: (window.innerWidth / 12) * 4,
            top: -35,
            borderRadius: "6px 6px 0 0",
            backgroundColor: colors.backgroundBlackColor,
            color: colors.backgroundWhiteColor,

            "&:hover": {
              backgroundColor: colors.backgroundBlackColor,
            },
          }}
        >
          {showNews ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </Button>
        <Collapse in={showNews}>
          <Paper
            elevation={10}
            sx={{ position: "relative", backgroundColor: "var(--color_background_white)" }}
          >
            <ExecutivesHubFooter />
          </Paper>
        </Collapse>
      </Box>
      {/* <ExecutivesHubHeader /> */}

      {/* <ExecutivesHubFooter /> */}
    </>
  );
};

export default ExecutivesHubPage;
