import { useContext } from "react";
import { HubContext } from "../state/hub.context";
import { useObservable } from "@mindspace-io/react";
import useDebounce from "../../../hooks/debounce.hook";

interface IHubRolloverCorporateHook {
  hubRolloverCorporateId: string;
}

export const useHubRolloverCorporate = (): IHubRolloverCorporateHook => {
  const hubService = useContext(HubContext);

  const [hubRolloverCorporateId] = useObservable(hubService.hubRolloverCorporateId$, null);
  const debouncedHubRolloverCorporateId = useDebounce(hubRolloverCorporateId, 300);

  return {
    hubRolloverCorporateId: debouncedHubRolloverCorporateId,
  };
};
