import { useMemo } from "react";
import { object, string, ref } from "yup";
import i18n from "i18next";

export function useLoginValidationSchema() {
  return useMemo(
    () =>
      object().shape({
        email: string().required(i18n.t("fillInputField")).email(i18n.t("invalidEmailFormat")),
        password: string().required(i18n.t("fillInputField")),
      }),
    [],
  );
}

export function useForgotPasswordValidationSchema() {
  return useMemo(
    () =>
      object().shape({
        email: string().required(i18n.t("fillInputField")).email(i18n.t("invalidEmailFormat")),
      }),
    [],
  );
}

export function useRegistrationValidationSchema() {
  return useMemo(
    () =>
      object().shape({
        email: string().required(i18n.t("fillInputField")).email(i18n.t("invalidEmailFormat")),
        password: string()
          .required(i18n.t("fillInputField"))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            i18n.t("invalidPasswordFormat"),
          ),
        confirmPassword: string()
          .required(i18n.t("fillInputField"))
          .oneOf([ref("password")], i18n.t("passwordMismatch")),
      }),
    [],
  );
}
