import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { HubContext } from "../state/hub.context";

interface IHubAnimationsHook {
  hubCompaniesWithTrendsCount: number;
  hubCompaniesWithNewsCount: number;
  hubCompaniesWithTrendsAndNewsCount: number;
  unfilteredHubCorporatesCount: number;
}

export const useHubAnimations = (): IHubAnimationsHook => {
  const hubService = useContext(HubContext);

  const [hubCompaniesWithTrends] = useObservable(hubService.hubCompaniesWithTrends$, []);

  const [hubCompaniesWithNews] = useObservable(hubService.hubCompaniesWithNews$, []);

  const [hubCompaniesWithTrendsAndNews] = useObservable(
    hubService.hubCompaniesWithTrendsAndNews$,
    [],
  );

  const [unfilteredHubCorporatesCount] = useObservable(
    hubService.unfilteredHubCorporatesCount$,
    null,
  );

  return {
    hubCompaniesWithTrendsCount: hubCompaniesWithTrends?.length ?? null,
    hubCompaniesWithNewsCount: hubCompaniesWithNews?.length ?? null,
    hubCompaniesWithTrendsAndNewsCount: hubCompaniesWithTrendsAndNews?.length ?? null,
    unfilteredHubCorporatesCount,
  };
};
