import { ICorporate } from "../entities/corporates/models/corporate.model";
import {
  DEFAULT_GERMANY_COORDINATES,
  DEFAULT_SWITZERLAND_COORDINATES,
} from "../constants/map";
import { ICoordinates } from "../shared/interfaces/coordinates.interface";
import { IExecutive } from "../entities/executives/state/executive.model";
import { IGeoLocation } from "../shared/interfaces/geo-location.interface";
import { getCurrentTenant } from "../utils/tenant.util";

export const buildCompanyAddress = (street: string, zipCode: number, city: string) => {
  let address = "";

  if (street) address = street;

  if (zipCode) {
    if (address !== "") {
      address = address + ", " + formatZipCode(zipCode);
    } else {
      address = formatZipCode(zipCode);
    }
  }

  if (city) address = address + " " + city;

  return address;
};

/**
 * Address for geocoding should either be "street city" or null if some of
 * the data is not available
 */
export const buildCompanyAddressForGeoCoding = (street: string, city: string) => {
  if (!street || !city) return null;
  return `${street} ${city}`;
};

/**
 * DossierMap needs to have some coordinate to show at a center point.
 * If the company has coordinates, map center should be on those coordinates,
 * otherwise we show center of Germany or Switzerland.
 * @param corporate
 */
export const calculateMapCenterFromCompanyCoordinates = (
  corporate: ICorporate,
): ICoordinates & { actualCoordinates?: boolean } => {
  if (!corporate?.geo_location || !corporate.geo_location.lat || !corporate.geo_location.lon) {
    return getDefaultMapCoordinates();
  }

  return {
    lat: corporate.geo_location.lat,
    lng: corporate.geo_location.lon,
    actualCoordinates: true,
  };
};

/**
 * Checks if given coordinates are equal to default map coordinates.
 * This happens when a company or executive has no coordinates and we
 * default to the center of Germany or Switzerland.
 * @param coordinates
 */
export const areCoordinatesDefault = (coordinates: ICoordinates) => {
  const defaultMapCoordinates = getDefaultMapCoordinates();

  return (
    coordinates.lat === defaultMapCoordinates.lat &&
    coordinates.lng === defaultMapCoordinates.lng
  );
};

/**
 * Calculates coordinates for executive.
 *
 * In swiss data we have "geo_location", but in german not,
 * so in that case we return coordinates of active corporate in which executive has active status.
 *
 * @param executive
 * @param activeCorporate
 */
export const calculateMapCenterForExecutive = (
  executive: IExecutive,
  activeCorporate: ICorporate,
) => {
  if (executive?.geo_location) {
    return {
      lat: executive.geo_location.lat,
      lng: executive.geo_location.lon,
    };
  }

  return calculateMapCenterFromCompanyCoordinates(activeCorporate);
};

/**
 * Finds central point between all available corporates by
 * calculating the average value of all coordinates
 */
export const calculateMapCenterAsAverageFromMultipleLocations = (
  geoLocations: IGeoLocation[],
): ICoordinates => {
  const geoLocationsLength = geoLocations.length;
  if (geoLocationsLength === 0) {
    return null;
  }

  let lonSum = 0;
  let latSum = 0;

  geoLocations.forEach(({ lon, lat }) => {
    lonSum = lonSum + lon;
    latSum = latSum + lat;
  });

  const lng = lonSum / geoLocationsLength;
  const lat = latSum / geoLocationsLength;

  return {
    lat,
    lng,
  };
};

/**
 * Returns default map coordinates depending on user domain (DE or CH).
 */
export const getDefaultMapCoordinates = (): ICoordinates => {
  return getCurrentTenant().tenant === "ch"
    ? DEFAULT_SWITZERLAND_COORDINATES
    : DEFAULT_GERMANY_COORDINATES;
};

/**
 * Returns formatted zip code as string.
 */
export const formatZipCode = (zipCode: number | string): string => {
  if (!zipCode) {
    return "";
  }
  if (getCurrentTenant().tenant === "de") {
    const zipCodeStr = zipCode.toString();
    return zipCodeStr.length < 5
      ? new Array(5 - zipCodeStr.length).fill(0).join("") + zipCodeStr
      : zipCodeStr;
  }
  return zipCode.toString();
};
