import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ICorporate } from "./../../corporates/models/corporate.model";
import { OpportunitiesFiltersContext } from "../state/opportunities-filters.context";
import { IExecutive } from "../../executives/state/executive.model";

interface UseOpportunitiesSearchResponseHook {
  searchResultTotalCount: number;
  searchResults: ICorporate[] | IExecutive[];
  fetchMore(): void;
  loading: boolean;
}

export function useOpportunitiesSearchResponse(): UseOpportunitiesSearchResponseHook {
  const opportunitiesFiltersService = useContext(OpportunitiesFiltersContext);

  const [searchResultTotalCount] = useObservable(
    opportunitiesFiltersService.searchResultTotalCount$,
    opportunitiesFiltersService.getResultCount(),
  );

  const [searching] = useObservable(opportunitiesFiltersService.searching$, true);

  const fetchMore = () => opportunitiesFiltersService.searchNextPage();

  return {
    searchResultTotalCount: searchResultTotalCount,
    searchResults: opportunitiesFiltersService.getResults(),
    fetchMore,
    loading: searching,
  };
}
