import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ICorporate } from "./../../corporates/models/corporate.model";
import { InterestsFiltersContext } from "../state/interests-filters.context";
import { IExecutive } from "../../executives/state/executive.model";

interface UseInterestsSearchResponseHook {
  searchResultTotalCount: number;
  searchResults: ICorporate[] | IExecutive[];
  fetchMore(): void;
  loading: boolean;
}

export function useInterestsSearchResponse(): UseInterestsSearchResponseHook {
  const interestsFiltersService = useContext(InterestsFiltersContext);

  const [searchResultTotalCount] = useObservable(
    interestsFiltersService.searchResultTotalCount$,
    interestsFiltersService.getResultCount(),
  );

  const [searching] = useObservable(interestsFiltersService.searching$, true);

  const fetchMore = () => interestsFiltersService.searchNextPage();

  return {
    searchResultTotalCount: searchResultTotalCount,
    searchResults: interestsFiltersService.getResults(),
    fetchMore,
    loading: searching,
  };
}
