import {
  createConnectedPeople,
  IConnectedPeople,
} from "../interfaces/connected-people.interface";
import { IExecutivesHit } from "../interfaces/executives-hit.interface";
import {
  createPeopleManagement,
  IPeopleManagement,
} from "../interfaces/people-management.interface";
import { createShareholder, IShareholder } from "../interfaces/shareholder.interface";
import { IEntityTag } from "../../custom-tags/state/custom-tag.model";
import { IInnerHits } from "../interfaces/inner-hits.interface";
import { IExecutiveNote } from "../../executive-notes/state/executive-notes.model";
import { IGeoLocation } from "../../../shared/interfaces/geo-location.interface";
import { tagEntityFromStringTag } from "../../../utils/tags.util";

export interface IExecutive {
  id: string;
  person_id: number;
  vendor_id: any;
  gender: string;
  date_of_birth: string;
  street?: any;
  city: string;
  city_zip_code?: any;
  country: string;
  date_of_birth_quality: string;
  full_name: string;
  management: IPeopleManagement[];
  shareholder: IShareholder[];
  region_lvl1: string;
  region_lvl2: string;
  connected_people: IConnectedPeople[];
  industry_sector: string;
  tags: IEntityTag[];
  promoter_score: number;
  wealth_score: number;
  inner_hits: IInnerHits;
  notes: IExecutiveNote[];
  geo_location: IGeoLocation;
  main_company_id?: number;
  main_company_geo_location?: IGeoLocation;
  ee_score: number;
  foundation_count: number;
}

export function createExecutive({
  management,
  shareholder,
  connected_people,
  geo_location,
  ...params
}: Partial<IExecutive>) {
  return {
    id: null,
    person_id: null,
    vendor_id: null,
    gender: null,
    date_of_birth: null,
    city: null,
    country: null,
    date_of_birth_quality: null,
    full_name: null,
    management: management?.map(createPeopleManagement) ?? null,
    shareholder: shareholder?.map(createShareholder) ?? null,
    region_lvl1: null,
    region_lvl2: null,
    connected_people: connected_people?.map(createConnectedPeople) ?? null,
    industry_sector: null,
    tags: [],
    promoter_score: null,
    wealth_score: null,
    inner_hits: null,
    notes: null,
    geo_location: geo_location ?? null,
    ee_score: null,
    foundation_count: null,
    ...params,
  } as IExecutive;
}

export function createExecutiveFromHit(hit: IExecutivesHit) {
  const executive = createExecutive({ ...hit._source, id: hit._id });

  if (hit.tags) {
    executive.tags = hit.tags.map((tag) =>
      typeof tag === "string" ? tagEntityFromStringTag(tag) : tag,
    );
  }

  if (hit.inner_hits) {
    executive.inner_hits = hit.inner_hits;
  }

  return executive;
}
