import ApiService from "./api.service";
import { IExecutive } from "../../entities/executives/state/executive.model";
import { FILTER_CATEGORY } from "../enums/filter-category.enum";
import { RELATIONS_COUNT } from "../enums/relationship-indicator.enum";
import { IRelationshipIndicatorResponse } from "../../entities/executive-dossier/models/relationship-indicator.model";
import { IExecutivesSearchResponse } from "../interfaces/executives-search-response.interface";
import { IFoundationReachResponse } from "../../entities/executive-dossier/models/foundation-reach.model";
import { IEAndEResponse } from "../../entities/executive-dossier/models/e-and-e.model";
import { CancelToken } from "axios";

export class ExecutiveApiService extends ApiService {
  getExecutiveData(id: string): Promise<IExecutive> {
    return this.get(`/people/${id}`);
  }

  getExecutiveRelationshipIndicator(
    type: FILTER_CATEGORY,
    executiveId: string,
    count: RELATIONS_COUNT, // if TRUE only relations count will be returned in response
    cancelToken?: CancelToken,
  ): Promise<IRelationshipIndicatorResponse> {
    return this.get(`/relationship-explorer/${type}/${executiveId}/${count}`, {
      cancelToken,
    });
  }

  getExecutiveLookALikes(
    type: FILTER_CATEGORY,
    executiveId: string,
    cancelToken?: CancelToken,
  ): Promise<IExecutivesSearchResponse> {
    return this.get(`/look-a-likes/${type}/${executiveId}`, {
      cancelToken,
    });
  }

  getExecutiveFoundationReach(
    executiveId: string,
    cancelToken?: CancelToken,
  ): Promise<IFoundationReachResponse> {
    return this.get(`/people/${executiveId}/foundation-reach`, {
      cancelToken,
    });
  }

  getEntrepreneursAndExecutives(
    type: FILTER_CATEGORY,
    executiveId: string,
    cancelToken?: CancelToken,
  ): Promise<IEAndEResponse> {
    return this.get(`/e-and-e/${type}/${executiveId}`, { cancelToken });
  }
}

export const executiveApiService = new ExecutiveApiService();
