import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import { ReactComponent as B2CMoveLogoTextIcon } from "../../assets/B2CMoveLogoTextIcon.svg";
// import { ReactComponent as MoveLogoTextIcon } from "../../assets/MoveLogoTextIcon.svg";
import { Flex } from "../../components/containers/Flex/Flex";
import RText from "../../components/fonts/RText/RText";
import { LoginInput } from "../../components/inputs/LoginInput/LoginInput";
import { Spacing } from "../../components/spacing/component";
import { useLoginValidationSchema } from "../../hooks/formValidationSchema.hook";
// import { useSession } from "../../hooks/session.hook";
import { SessionContext } from "../../state/akita/session/session.context";
import { ILoginFormData } from "../../state/akita/session/session.model";
import styles from "./styles.module.css";
interface IProps {
  showRegister: () => void;
  showForgotPassword: () => void;
}

const LoginForm: React.FC<IProps> = ({ showRegister, showForgotPassword }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const { isB2CUser } = useSession();

  const { login } = useContext(SessionContext);
  const loginValidationSchema = useLoginValidationSchema();
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [userExist, setUserExist] = useState(true);

  const onSubmit = (data: ILoginFormData) => {
    const lastVisitedPage =
      localStorage.getItem("lastVisitedPage") === "/"
        ? "/home"
        : localStorage.getItem("lastVisitedPage");

    setUserExist(true);
    setLoading(true);

    login(data)
      .then((res) => {
        if (!res) {
          setUserExist(false);
          return;
        }

        if (res.user.country === "Schweiz") {
          res.token_key && history.push(`/ch${lastVisitedPage}`);

          window.location.reload();
          return;
        }

        res.token_key && history.push(lastVisitedPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <Flex alignItemsCenter justifyContentCenter>
                <div className={styles.horizontalLine} />
                <Spacing pr="30" />
                {!isB2CUser ? (
                  <MoveLogoTextIcon className={styles.moveLogoTextIcon} />
                  ) : (
                    <B2CMoveLogoTextIcon className={styles.moveLogoTextIcon} />
                )}
                <Spacing pl="30" />
                <div className={styles.horizontalLine} />
            </Flex>
            <Spacing pt="50" /> */}
      <Box position="relative">
        <div className={styles.formWrapper}>
          {!userExist && (
            <>
              <Flex
                alignItemsCenter
                justifyContentCenter
                className={styles.loginIncorrectCredentialsContainer}
              >
                <RText fontSize="16" immutableWhite>
                  {t("loginIncorrectCredentials")}
                </RText>
              </Flex>
              <Spacing pb="10" />
            </>
          )}

          <form>
            <Controller
              defaultValue=""
              name="email"
              control={control}
              render={({ ref, ...props }) => (
                <LoginInput
                  inputRef={ref}
                  error={errors.email?.message || !userExist}
                  placeholder={t("email")}
                  label={t("email")}
                  {...props}
                />
              )}
            />
            <Spacing pb="16" />
            <Controller
              defaultValue=""
              name="password"
              control={control}
              render={({ ref, ...props }) => (
                <LoginInput
                  inputRef={ref}
                  type="password"
                  error={errors.password?.message || !userExist}
                  placeholder={t("password")}
                  label={t("password")}
                  {...props}
                />
              )}
            />
            <Spacing pb="66" />
            <button
              className={styles.submitButton}
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              {loading && <CircularProgress sx={{ color: "white" }} size={16} />}
              {!loading && <>{t("login")}</>}
            </button>

            <Spacing pt="28" />

            <Box display="flex" alignItems="center" justifyContent="space-between">
              <label className={styles.checkboxContainer}>
                <RText immutableWhite fontSize="12">
                  {t("rememberMe")}
                </RText>
                <input type="checkbox" />
                <span className={styles.checkmark} />
              </label>

              <Flex onClick={showForgotPassword} pointer className={styles.link}>
                <RText immutableWhite fontSize="12" underlined>
                  {t("forgotPassword")}
                </RText>
              </Flex>
            </Box>
          </form>

          <Spacing pb="30" />
          <Flex onClick={showRegister} pointer className={styles.link}>
            <RText fontSize="12" white underlined>
              {t("showRegister")}
            </RText>
          </Flex>
        </div>
      </Box>
    </>
  );
};

export default LoginForm;
