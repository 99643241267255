import React, { useContext } from "react";
import { ReactComponent as NavIconCorporates } from "../../../assets/NavIconCorporates.svg";
import { useCorporatesSearchHits } from "../../../entities/corporates-filters/hooks/corporates-total-count.hook";
import FiltersHeader from "../filters/FiltersHeader/FiltersHeader";
import { CentralSearchContext } from "../HomePage";
import { CorporatesFiltersContext } from "../../../entities/corporates-filters/state/corporates-filters.context";
import { useTranslation } from "react-i18next";

const CorporatesFiltersHeader = () => {
  const { t } = useTranslation();

  const { corporatesFiltersOpen, toggleCorporatesFiltersOpen } =
    useContext(CentralSearchContext);

  const corporatesFiltersContext = useContext(CorporatesFiltersContext);

  const { totalCount } = useCorporatesSearchHits();

  return (
    <FiltersHeader
      title={t("corporates")}
      Icon={NavIconCorporates}
      resultsCount={totalCount}
      hasFilters={true}
      onMyFiltersClick={toggleCorporatesFiltersOpen}
      onClearAllClick={() => corporatesFiltersContext.deselectAllSearchFilterValues()}
      filtersOpened={corporatesFiltersOpen}
    />
  );
};

export default CorporatesFiltersHeader;
