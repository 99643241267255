import { FILTERS_PER_ROW } from "../constants/filters";
import { ICorporatesFilter } from "../entities/corporates-filters/models/corporates-filter.model";
import { FILTER_TYPE } from "../shared/enums/filter-type.enum";
import { IFilterUI } from "../shared/interfaces/filter-ui.interface";
import { IFilter } from "../shared/interfaces/filter.interface";
import { IFilterWithUIRow } from "../shared/interfaces/filters-row.interface";
import { IQueryRanges } from "../shared/interfaces/query-ranges.interface";
import { IObjectWithArrayOfStringsProps } from "./array.helper";
import {
  ISelectedValuesRange,
  ISelectedValuesRef,
} from "../shared/interfaces/selected-value-types.interface";
import { SELECTED_VALUES_TYPE } from "../shared/enums/selected-values-type.enum";

export interface IFilterWithUI {
  filter: IFilter;
  filterUI: IFilterUI;
}

export interface ICorporatesFilterWithUI extends IFilterWithUI {
  filter: ICorporatesFilter;
}

interface ISelectedFilters {
  filtersWithValues: IObjectWithArrayOfStringsProps;
  filtersWithoutValues: string[];
  ranges: IQueryRanges[];
  productTrendsFilters: string[];
}

export const getSelectedFilters = (entities: IFilterWithUI[]): ISelectedFilters => {
  const {
    filtersWithValues,
    filtersWithoutValues,
    ranges,
    productTrendsFilters,
  }: ISelectedFilters = {
    filtersWithValues: {},
    filtersWithoutValues: [] as string[],
    ranges: [],
    productTrendsFilters: [] as string[],
  };

  entities.forEach(({ filter, filterUI }) => {
    if (filter.type === FILTER_TYPE.PRODUCT_TRENDS) {
      //TODO switch all selectedValues to the new object format instead of raw strings
      productTrendsFilters.push(...(filterUI.selectedValues as string[]));
    } else if (
      filter.type !== FILTER_TYPE.AUTOSUGGEST_RANGE &&
      filter.type !== FILTER_TYPE.RANGE &&
      filter.type !== FILTER_TYPE.SINGLE_RANGE &&
      filter.type !== FILTER_TYPE.AGE_RANGE
    ) {
      // Select all non-range types
      filtersWithValues[filter.title_key] = [];

      if (filter.type === FILTER_TYPE.SINGLE_BASIC && filterUI.selected) {
        const key = filter.title_key.split("_")[0];
        const value = filter.title_key.split("_")[1];

        if (!filtersWithValues[key]) filtersWithValues[key] = [];
        filtersWithValues[key].push(value);

        if (!filtersWithValues[key].length) {
          delete filtersWithValues[key];
        }
      } else if (filter.type === FILTER_TYPE.SINGLE_WITH_VALUES && filterUI.selected) {
        const key = filter.title_key.split("_")[0];
        if (!filtersWithValues[key]) filtersWithValues[key] = [];

        filter.values.forEach((v) => {
          if (filter.title_key === "management.function_rank") {
            filtersWithValues[key].push(v.label);
          } else {
            filtersWithValues[key].push(v.value_key);
          }
        });

        if (!filtersWithValues[key].length) {
          delete filtersWithValues[key];
        }
      } else {
        filter.values.forEach((value) => {
          //TODO switch all selectedValues to the new object format instead of raw strings
          if ((filterUI.selectedValues as string[]).indexOf(value.label) !== -1) {
            if (filter.title_key === "management.function_rank") {
              filtersWithValues[filter.title_key].push(value.label);
            } else {
              filtersWithValues[filter.title_key].push(value.value_key);
            }
          }
        });
      }

      if (!filtersWithValues[filter.title_key].length) {
        delete filtersWithValues[filter.title_key];
      }
    } else {
      // Select all range types
      let rangesForQueryObj: IQueryRanges = {};

      rangesForQueryObj[filter.title_key] = [];

      if (
        filter.type === FILTER_TYPE.RANGE &&
        filter.title_key === "founding_date" &&
        filterUI.selected
      ) {
        const from = String(new Date().getFullYear() - 1);
        const to = String(new Date().getFullYear());
        rangesForQueryObj[filter.title_key] = [{ from, to }];
      }

      if (filter.type === FILTER_TYPE.AGE_RANGE) {
        const sV: any = filterUI.selectedValues[filterUI.selectedValues.length - 1];

        if (sV && sV.from && sV.to) {
          const { from, to } = sV;
          if (to === "2024" && from === "1900") {
            rangesForQueryObj[filter.title_key] = [];
            return;
          }

          rangesForQueryObj[filter.title_key].push({
            from,
            to,
          });
        }
      } else if (filter.type === FILTER_TYPE.AUTOSUGGEST_RANGE) {
        //TODO switch all selectedValues to the new object format instead of raw strings
        (filterUI.selectedValues as Array<ISelectedValuesRange | ISelectedValuesRef>).forEach(
          (selectedValue) => {
            if (selectedValue?.type === SELECTED_VALUES_TYPE.RANGE) {
              rangesForQueryObj[filter.title_key].push({
                to: selectedValue.to,
                from: selectedValue.from,
              });
            } else if (selectedValue?.type === SELECTED_VALUES_TYPE.REFERENCE) {
              const referencedFilter = filter.values.find(
                (filterValue) => filterValue.label === selectedValue.ref,
              );
              if (referencedFilter) {
                const currentFilterValues = filtersWithValues[filter.title_key] ?? [];
                currentFilterValues.push(referencedFilter.value_key);
                filtersWithValues[filter.title_key] = currentFilterValues;
              }
            }
          },
        );
      } else {
        filter.values.forEach((value) => {
          if ((filterUI.selectedValues as string[]).indexOf(value.label) !== -1) {
            rangesForQueryObj[filter.title_key].push({
              to: value.to!,
              from: value.from!,
            });
          }
        });
      }

      if (rangesForQueryObj[filter.title_key].length) {
        ranges.push(rangesForQueryObj);
      }
    }
  });

  return {
    filtersWithValues,
    filtersWithoutValues,
    ranges,
    productTrendsFilters,
  };
};

export const extractFiltersIntoRows = (
  filterWithUIs: ICorporatesFilterWithUI[],
  collapsedRowIndexes: number[],
): IFilterWithUIRow[] => {
  if (!filterWithUIs) return [];

  const filterRows: IFilterWithUIRow[] = [];

  let filtersCopy = [...filterWithUIs];
  let count = 0;
  while (filtersCopy.length) {
    const filterRow = {
      rowExpanded: !collapsedRowIndexes.includes(count),
      filtersWithUI: filtersCopy.splice(0, FILTERS_PER_ROW),
    };

    filterRows.push(filterRow);

    count++;
  }

  return filterRows;
};
