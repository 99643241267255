import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Flex } from "../containers/Flex/Flex";
import RText from "../fonts/RText/RText";
import Spinner from "../other/Spinner/Spinner";
import styles from "./styles.module.css";
import { ReactComponent as DropzoneIcon } from "../../assets/DropzoneIcon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/SuccessIcon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/ErrorIcon.svg";
import { useIsHub } from "../../utils/route.util";
import { ITag } from "../../entities/custom-tags/state/custom-tag.model";
import useAppColors from "../../hooks/app-colors.hook";
import { useSession } from "../../hooks/session.hook";

interface IProps {
  onUpload: (
    files: File[],
  ) => Promise<{ success: boolean; error?: string; totalInFile?: number }>;
  tag: ITag;
  onShowTag: () => void;
}

const Dropzone = ({ onUpload, tag, onShowTag }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();
  const { isB2CUser } = useSession();
  const [loading, setLoading] = useState(false);
  const [totalInFile, setTotalInFile] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const isHub = useIsHub();
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setLoading(true);
        setTotalInFile(0);
        setErrorMessage("");
        const result = await onUpload(acceptedFiles);
        setTotalInFile(result.totalInFile || 0);
        if (!result.success) {
          setErrorMessage(result.error);
        }
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        setErrorMessage(e.message);
      }
    },
    [onUpload],
  );

  useEffect(() => {
    if (errorMessage || totalInFile) {
      setTimeout(() => {
        setErrorMessage("");
        setTotalInFile(0);
        onShowTag();
      }, 3000);
    }
  }, [errorMessage, totalInFile, onShowTag]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    multiple: false,
    onDrop,
  });

  return (
    <Flex column alignItemsCenter flex style={{ height: isHub ? 530 : 400 }}>
      {loading && <Spinner />}
      {!!totalInFile && (
        <div className={styles.content}>
          <SuccessIcon />
          <div className={styles.successText}>
            <b>{t("uploadCompleted")}</b>
            <br />
            <br />
            {`${totalInFile} ${t("uploadContactsFound")}`}
            <Flex>
              <div
                style={{
                  borderRadius: "50%",
                  backgroundColor: tag.color_code,
                  width: 12,
                  height: 12,
                  marginRight: 4,
                }}
              />
              {tag.name}
            </Flex>
          </div>
        </div>
      )}
      {!!errorMessage && (
        <div className={styles.content}>
          <ErrorIcon />
          <div className={styles.successText}>
            <b>{errorMessage}</b>
            <br />
          </div>
        </div>
      )}
      {!loading && !totalInFile && !errorMessage && (
        <>
          <div
            {...getRootProps()}
            className={`${styles.content} ${!tag && styles.contentInactive}`}
            style={{
              borderBottom: tag ? `2px solid ${colors.secondaryDarkColor}` : 0,
            }}
          >
            <input disabled={!tag} {...getInputProps()} />
            <DropzoneIcon />
            <p className={styles.dragText}>Drag & Drop your File</p>
          </div>
          <RText fontSize="13" className={styles.orText}>
            or
          </RText>
          <button
            disabled={!tag}
            type="button"
            onClick={open}
            className={styles.uploadButton}
            style={{
              backgroundColor: isB2CUser ? colors.secondaryDarkColor : "#0e67ef",
            }}
          >
            Upload
          </button>
        </>
      )}
    </Flex>
  );
};

export default Dropzone;
