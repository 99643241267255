import { EntityState, EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";
import { ICorporatesFilter } from "../../corporates-filters/models/corporates-filter.model";
import { SelectedValueTypes } from "../../../shared/interfaces/selected-value-types.interface";

export interface IExecutivesFilterUI {
  id: string;

  hubSelected: boolean;
  hubSelectedValues: SelectedValueTypes[];

  searchSelected: boolean;
  searchSelectedValues: SelectedValueTypes[];
}

export interface ExecutivesFiltersState extends EntityState<ICorporatesFilter> {}
export interface ExecutivesFiltersUIState extends EntityState<IExecutivesFilterUI> {
  searching: boolean;
  lastSearchTerm: string;
  nextSearchPage: number;
  searchResultTotalCount: number;
  searchResultIds: string[];
  closedSearchRowIndexes: number[];
}

@StoreConfig({
  name: "executives-filters",
})
export class ExecutivesFiltersStore extends EntityStore<ExecutivesFiltersState> {
  ui: EntityUIStore<ExecutivesFiltersUIState>;

  constructor() {
    super({ loading: null });

    this.createUIStore({
      searching: null,
      lastSearchTerm: "",
      nextSearchPage: 1,
      searchResultTotalCount: null,
      searchResultIds: null,
      closedSearchRowIndexes: [],
    }).setInitialEntityState({
      hubSelected: false,
      hubSelectedValues: [],

      searchSelected: false,
      searchSelectedValues: [],
    });
  }
}

export const executivesFiltersStore = new ExecutivesFiltersStore();
