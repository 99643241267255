import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as SearchIcon } from "../../../assets/SearchIcon.svg";
import { ReactComponent as SearchIconDark } from "../../../assets/SearchIconDark.svg";
import { ReactComponent as CloseIcon } from "../../../assets/CloseIcon.svg";
import { ReactComponent as CloseIconBlack } from "../../../assets/CloseIconBlack.svg";
import { classNames } from "../../../helpers/string.helper";
import Label from "../../fonts/Label/Label";
import { Spacing } from "../../spacing/component";
import { IInputProps } from "../../types/component.models";

interface ICentralSearchInput extends IInputProps<string> {
  light?: boolean;
}

export const CentralSearchInput = ({
  error,
  disabled,
  autoFocus,
  value,
  onChange,
  style,
  name,
  onCTAClick,
  active,
  onCloseIconClick,
  onKeyUp,
  onDelete,
  light,
}: ICentralSearchInput) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const toggleInputFocus = () => {
    if (inputRef !== null && inputRef.current !== null) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <div
        className={light ? styles.containerLight : styles.container}
        {...{ style }}
        onClick={toggleInputFocus}
      >
        {active && (
          <>
            <SearchIcon className={styles.icon} />
            <Spacing pr="10" />
          </>
        )}
        <input
          id="centralSearchInput"
          ref={inputRef}
          name={name}
          className={classNames(styles, { error })}
          placeholder={t("searchPlaceholder")}
          spellCheck="false"
          autoComplete="off"
          onChange={onChange && ((evt) => onChange(evt.target.value))}
          onKeyUp={onKeyUp}
          {...{ disabled, value, autoFocus }}
        />
        {!active ? (
          <>
            {value?.length > 0 && (
              <>
                <Spacing pl="10" />
                {light ? (
                  <CloseIconBlack className={styles.deleteValueIcon} onClick={onDelete} />
                ) : (
                  <CloseIcon className={styles.deleteValueIcon} onClick={onDelete} />
                )}
                <Spacing pl="10" />
              </>
            )}
            <Spacing pl="10" />
            {light ? (
              <SearchIconDark className={styles.icon} onClick={onCTAClick} />
            ) : (
              <SearchIcon className={styles.icon} onClick={onCTAClick} />
            )}
          </>
        ) : (
          <>
            <Spacing pl="10" />
            {light ? (
              <CloseIconBlack className={styles.icon} onClick={onCloseIconClick} />
            ) : (
              <CloseIcon className={styles.icon} onClick={onCloseIconClick} />
            )}
          </>
        )}
      </div>
      {error && (
        <>
          <Spacing pt="4" />
          <Label className={styles.redColor}>{error}</Label>
        </>
      )}
    </>
  );
};
