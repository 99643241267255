import { Store, StoreConfig } from "@datorama/akita";
import { IExecutive } from "../../executives/state/executive.model";
import { IRelationshipIndicatorResponse } from "../models/relationship-indicator.model";
import { IFoundationReachResponse } from "./../models/foundation-reach.model";
import { IEAndE } from "../models/e-and-e.model";

export interface IExecutiveDossierState {
  executiveData: IExecutive;
  executiveRelationshipIndicator: IRelationshipIndicatorResponse;
  executiveSideNavigationActiveAnchor: string;
  executiveLookALikes: string[];
  totalExecutiveLookALikes: number;
  openExecutiveFullscreenMapModal: boolean;
  executiveFoundationReach: IFoundationReachResponse;
  entrepreneursAndExecutives: IEAndE[];
  totalEntrepreneursAndExecutives: number;
}

export function createInitialState(): IExecutiveDossierState {
  return {
    executiveData: null,
    executiveRelationshipIndicator: null,
    executiveSideNavigationActiveAnchor: "career",
    executiveLookALikes: null,
    totalExecutiveLookALikes: null,
    openExecutiveFullscreenMapModal: false,
    executiveFoundationReach: null,
    entrepreneursAndExecutives: null,
    totalEntrepreneursAndExecutives: null,
  };
}

@StoreConfig({ name: "executive-dossier" })
export class ExecutiveDossierStore extends Store<IExecutiveDossierState> {
  constructor() {
    super(createInitialState());
  }
}

export const executiveDossierStore = new ExecutiveDossierStore();
