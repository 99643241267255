import { ReactComponent as AncorUp } from "./AncorUp.svg";
import { ReactComponent as AncorDown } from "./AncorDown.svg";
import { ReactComponent as Circle } from "./Circle.svg";
import { ReactComponent as Calendar } from "./Calendar.svg";
import { ReactComponent as Magnifier } from "./Magnifier.svg";
import BlackDot from "./BlackDot.svg";
import BlackDotActive from "./BlackDotActive.svg";
import GreenDot from "./GreenDot.svg";
import GreenDotActive from "./GreenDotActive.svg";
import RedDot from "./RedDot.svg";
import RedDotActive from "./RedDotActive.svg";
import Garbage from "./Garbage.svg";
import Plus from "./Plus.svg";

export {
  AncorUp,
  AncorDown,
  Circle,
  Magnifier,
  Calendar,
  BlackDot,
  BlackDotActive,
  GreenDot,
  GreenDotActive,
  RedDot,
  RedDotActive,
  Garbage,
  Plus,
};
