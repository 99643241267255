import { ExecutivesHubFacade, IExecutivesHubContext } from "./executives-hub.facade";
import { executivesHubStore } from "./executives-hub.store";
import { executivesHubQuery } from "./executives-hub.query";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { hubApiService } from "../../../api/services/hub-api.service";
import { executivesFacade } from "../../../entities/executives/state/executive.context";
import { executivesFiltersFacade } from "../../../entities/executives-filters/state/executives-filters.context";
import { createContext } from "react";

const executivesHubFacade = new ExecutivesHubFacade(
  executivesHubStore,
  executivesHubQuery,
  filtersApiService,
  hubApiService,
  executivesFacade,
  executivesFiltersFacade,
);

export const ExecutiveHubContext = createContext<IExecutivesHubContext>(executivesHubFacade);
