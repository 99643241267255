import React, { useContext, useEffect, useState } from "react";
import { IEntityTag } from "../../../entities/custom-tags/state/custom-tag.model";
import { ExecutiveTagsContext } from "../../../entities/executive-tags/state/executive-tags.context";
import Tags from "../../../components/tags/Tags";
import { useExecutiveTags } from "../../../entities/executive-tags/hooks/executive-tags.hook";
import { tagEntityFromStringTag } from "../../../utils/tags.util";
import TAG_TYPE from "../../../api/enums/tags.enum";

interface IProps {
  executiveId: string;
  entityTags?: IEntityTag[];
  refetch?: () => void;
}

const ExecutivesTags = ({ executiveId, entityTags, refetch }: IProps) => {
  const executiveTagsContext = useContext(ExecutiveTagsContext);
  const { all } = useExecutiveTags();

  const [tags, setTags] = useState<IEntityTag[]>([]);

  useEffect(() => {
    if (entityTags) {
      setTags(entityTags);
    } else if (all !== null) {
      const executiveTag = all.find((t) => t.id === executiveId);
      if (executiveTag) {
        const tags = executiveTag.tags.map((tag) =>
          typeof tag === "string" ? tagEntityFromStringTag(tag) : tag,
        );
        setTags(tags);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all, entityTags]);

  const addTagToEntity = async (entityId: string, tagId: number) => {
    try {
      await executiveTagsContext.savePersonTag(entityId, tagId);
      if (entityTags) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeTagFromEntity = async (entityId: string, tagWrapper: IEntityTag) => {
    try {
      await executiveTagsContext.deletePersonTag(entityId, tagWrapper);
      if (entityTags) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Tags
      tagType={TAG_TYPE.PEOPLE}
      entityId={executiveId}
      entityTags={tags}
      addTagToEntity={addTagToEntity}
      removeTagFromEntity={removeTagFromEntity}
      hideTagLabel={!!refetch}
    />
  );
};

export default ExecutivesTags;
