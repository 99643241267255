import React, { useContext } from "react";
import styles from "./styles.module.css";
import { ReactComponent as EAndEListIcon } from "../../../../../../assets/executive-dossier/EAndEListIcon.svg";
import { ReactComponent as EAndEListIconDark } from "../../../../../../assets/executive-dossier/EAndEListIconDark.svg";
import RText from "../../../../../../components/fonts/RText/RText";
import useAppColors from "../../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../../hooks/session.hook";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  score: string;
}

export default function EAndEScore({ score }: IProps) {
  const { isDarkTheme } = useSession();
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);

  return [null, undefined].includes(score) ? (
    <></>
  ) : (
    <div
      className={styles.scoreContainer}
      style={{
        minWidth: isSmallerScreen ? "40px" : "60px",
        maxWidth: isSmallerScreen ? "40px" : "60px",
      }}
    >
      {isDarkTheme ? (
        <EAndEListIconDark width={isSmallerScreen ? "40px" : "60px"} />
      ) : (
        <EAndEListIcon
          color={colors.primaryLightColor}
          width={isSmallerScreen ? "40px" : "60px"}
        />
      )}
      <RText
        elementStyle={{
          bottom: isSmallerScreen ? "19px" : "16px",
          right: isSmallerScreen ? "10px" : "14px",
        }}
        className={styles.scoreValue}
        fontSize={isSmallerScreen ? "12" : "16"}
      >
        {score}
      </RText>
    </div>
  );
}
