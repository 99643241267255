import { QueryEntity } from "@datorama/akita";
import { ExecutivesState, ExecutivesStore, executivesStore } from "./executives.store";

export class ExecutivesQuery extends QueryEntity<ExecutivesState> {
  constructor(protected store: ExecutivesStore) {
    super(store);
  }
}

export const executivesQuery = new ExecutivesQuery(executivesStore);
