import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { StartupsFiltersContext } from "../state/startups-filters.context";

interface UseStartupsSearchHitsHook {
  totalCount: number;
}

export function useStartupsSearchHits(): UseStartupsSearchHitsHook {
  const startupsFiltersService = useContext(StartupsFiltersContext);

  const [totalCount] = useObservable(
    startupsFiltersService.searchResultTotalCount$,
    startupsFiltersService.getResultCount(),
  );

  return { totalCount };
}
