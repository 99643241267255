import React from "react";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import "reactjs-popup/dist/index.css";
import "../../popups/popupDefaultOverride.styles.css";
import styles from "./styles.module.css";
import { ReactComponent as DeleteIcon } from "../../../assets/DeleteIcon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/ErrorIcon.svg";
import { Flex } from "../../containers/Flex/Flex";
import { Spacing } from "../../spacing/component";
import RText from "../../fonts/RText/RText";
import useAppColors from "../../../hooks/app-colors.hook";

interface IProps {
  deleteTag: () => void;
}

const DeleteTagPopup = ({ deleteTag }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();

  const onConfirm = (closePopup: CallableFunction) => () => {
    deleteTag();
    closePopup();
  };

  const onCancel = (closePopup: CallableFunction) => () => closePopup();

  return (
    <Popup
      trigger={
        <div>
          <Flex alignItemsCenter pointer className={styles.deleteIconContainer}>
            <DeleteIcon color={colors.backgroundBlackColor} className={styles.deleteTagIcon} />
          </Flex>
        </div>
      }
      position="left center"
      contentStyle={{
        width: "auto",
        border: "1px solid var(--color_gray)",
        borderRadius: "unset",
        padding: "15px",
        backgroundColor: "var(--color_gray_light)",
      }}
      arrowStyle={{
        stroke: "var(--color_gray)",
        color: "var(--color_gray_light)",
        filter: "none",
      }}
    >
      {(close: () => void) => (
        <Flex column>
          <Flex alignItemsCenter>
            <ErrorIcon className={styles.errorIcon} />
            <Spacing pr="10" />
            <RText fontSize="13" className={styles.deleteTagText}>
              {t("tagFinallyDeleted")}
            </RText>
          </Flex>
          <Spacing pt="20" />
          <Flex alignItemsCenter justifyContentCenter>
            <Flex pointer onClick={onConfirm(close)}>
              <RText primaryBlue bold fontSize="13">
                {t("confirm")}
              </RText>
            </Flex>
            <Spacing pr="20" />
            <Flex pointer onClick={onCancel(close)}>
              <RText immutableBlack fontSize="13">
                {t("cancel")}
              </RText>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Popup>
  );
};

export default DeleteTagPopup;
