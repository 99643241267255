import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  FolderIcon,
  PersonIcon,
} from "../../../../../../../assets/company-dossier/relationship-indicator/icons";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../../../components/spacing/component";
import { formatNumberWithDotSeparator } from "../../../../../../../helpers/number.helper";
import { RELATIONSHIP_NAVIGATION_ITEM_TYPE } from "../../../../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/Navigation";
import InfoPopup from "../../../../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/InfoPopup";
import { ISecondLine } from "../../../../../../../entities/executive-dossier/models/relationship-indicator.model";
import RLink from "../../../../../../../components/link/RLink";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../../../hooks/session.hook";
import CorporatesTags from "../../../../../CorporatesTags/CorporatesTags";
import ExecutivesTags from "../../../../../ExecutivesTags/ExecutivesTags";

interface IProps {
  selected: RELATIONSHIP_NAVIGATION_ITEM_TYPE;
  secondLineData: ISecondLine[];
  refetch: () => void;
}

export default function SecondLineRelationship({ selected, secondLineData, refetch }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isB2CUser } = useSession();

  if (!secondLineData?.length) {
    return null;
  }

  const renderLine = (data: ISecondLine) => (
    <Flex alignItemsCenter className={styles.secondLineContainer}>
      <Flex>
        <div className={styles.blackDot} />
      </Flex>

      <Spacing pr="6" />
      <Flex
        flex
        alignItemsCenter
        className={`
                    ${styles.lineContainer}
                    ${styles.lineContainerBackgroundGreen}
                `}
      >
        <div className={`${styles.horizontalLine} ${styles.horizontalLineLarge}`} />
        <InfoPopup explanationData={data.explanation1} />
        <div className={`${styles.horizontalLine} ${styles.horizontalLineLarge}`} />
      </Flex>
      <Spacing pr="6" />

      <Flex className={styles.personContainer}>
        {!isB2CUser && (
          <RText fontSize={isSmallerScreen ? "11" : "14"} className={styles.personTypeText}>
            {t("promoter")}
          </RText>
        )}
        <Flex alignItemsCenter>
          <PersonIcon color={colors.backgroundBlackColor} className={styles.iconSize} />
          <Flex
            alignItemsCenter
            justifyContentCenter
            className={styles.promoterScoreContainer}
          >
            <RText white fontSize="12">
              {data.person2.promoter_score + "%"}
            </RText>
          </Flex>
        </Flex>
        <Flex column alignItemsCenter className={styles.personDescriptionContainer}>
          <ExecutivesTags
            executiveId={data.person2.id.toString()}
            entityTags={data.person2.tags}
            refetch={refetch}
          />
          <Spacing pt="10" />
          <RLink path={`/home/search/executive-dossier/${data.person2.id.toString()}`}>
            <RText
              fontSize={isSmallerScreen ? "11" : "14"}
              ellipsis
              textTooltip={data.person2.name}
            >
              {data.person2.name}
            </RText>
            <Spacing pt="10" />
            <RText
              fontSize={isSmallerScreen ? "11" : "14"}
              ellipsis
              textTooltip={data.person2.position}
            >
              {data.person2.position}
            </RText>
            <Spacing pt="10" />
            <Flex justifyContentCenter>
              <StarRating affinity={data.person2.wealth_score} spacing="5" iconHeight="12" />
            </Flex>
          </RLink>
        </Flex>
      </Flex>

      <Spacing pr="6" />

      {data.company && (
        <>
          <Flex
            flex
            alignItemsCenter
            className={`
                            ${styles.lineContainer}
                            ${styles.lineContainerBackgroundBlue}
                        `}
          >
            <div className={styles.horizontalLine} />
            <InfoPopup explanationData={data.explanation2} />
            <div className={styles.horizontalLine} />
          </Flex>
          <Spacing pr="6" />

          <Flex className={styles.companyContainer}>
            <FolderIcon color={colors.backgroundBlackColor} className={styles.iconSize} />
            <Flex column alignItemsCenter className={styles.companyDescriptionContainer}>
              <CorporatesTags
                companyId={data.company.id.toString()}
                entityTags={data.company.tags}
                refetch={refetch}
              />
              <Spacing pt="10" />
              <RLink path={`/home/search/corporate-dossier/${data.company.id.toString()}`}>
                <RText
                  fontSize={isSmallerScreen ? "11" : "14"}
                  bold
                  ellipsis
                  textTooltip={data.company.name}
                >
                  {data.company.name}
                </RText>
                <Spacing pt="10" />
                <Flex justifyContentCenter>
                  <StarRating affinity={data.company.affinity} spacing="5" iconHeight="12" />
                </Flex>
              </RLink>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );

  return (
    <>
      {selected === RELATIONSHIP_NAVIGATION_ITEM_TYPE.SECOND_LINE && (
        <>
          <Spacing pt="15" />
          <Flex justifyContentEnd>
            <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
              {`${formatNumberWithDotSeparator(secondLineData.length)} ${t("relations")}`}
            </RText>
          </Flex>
          <Spacing pt={isSmallerScreen ? "20" : "40"} />
        </>
      )}
      {secondLineData.map((data, index) => (
        <Fragment key={`second-line-data-${index}`}>
          {renderLine(data)}
          {index < secondLineData.length - 1 && <Spacing pt="150" />}
        </Fragment>
      ))}
    </>
  );
}
