import { createContext } from "react";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { corporatesFacade } from "../../corporates/state/corporates.context";
import { StartupsFiltersFacade, IStartupsFiltersContext } from "./startups-filters.facade";
import { startupsFiltersQuery } from "./startups-filters.query";
import { startupsFiltersStore } from "./startups-filters.store";

export const startupsFiltersFacade = new StartupsFiltersFacade(
  startupsFiltersStore,
  startupsFiltersQuery,
  filtersApiService,
  corporatesFacade,
);

export const StartupsFiltersContext =
  createContext<IStartupsFiltersContext>(startupsFiltersFacade);
