import React, { Fragment } from "react";
import { Spacing } from "../../../../../../components/spacing/component";
import MenuItem from "./MenuItem";

export enum SIDE_NAVIGATION_ITEM_TYPE {
  CAREER,
  RELATIONSHIP_INDICATOR,
  INVESTMENT,
  LOOK_A_LIKES,
  FOUNDATION_REACH,
  ENTREPRENEURS_AND_EXECUTIVES,
}

export interface IMenuItem {
  title: string;
  value: string | null;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  type: SIDE_NAVIGATION_ITEM_TYPE;
  anchor: string;
}

interface IProps {
  menuItems: IMenuItem[];
  selectedItem: SIDE_NAVIGATION_ITEM_TYPE | null;
  onSetActiveItem: (item: IMenuItem) => (activeAnchor: string) => void;
}

export default function SideNavigation({ menuItems, selectedItem, onSetActiveItem }: IProps) {
  return (
    <>
      {menuItems.map((item) => {
        return (
          <Fragment key={item.anchor}>
            <MenuItem
              item={item}
              selected={selectedItem === item.type}
              onSetActiveItem={onSetActiveItem}
            />
          </Fragment>
        );
      })}
      <Spacing pt="25" />
    </>
  );
}
