import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { akitaDevtools, enableAkitaProdMode, persistState } from "@datorama/akita";
import { debounceTime } from "rxjs/operators";
import * as Sentry from "@sentry/browser";
import { SENTRY_DSN } from "./constants/urls";
import App from "./pages/app/App";
import "./localization/i18n";
import * as localForage from "localforage";
import theme from "./theme";

const persistentAkitaStores = [
  "corporates",
  "corporates-filters",
  "UI/corporates-filters",

  "executives",
  "executives-filters",
  "UI/executives-filters",

  "interests-filters",
  "UI/interests-filters",

  "opportunities-filters",
  "UI/opportunities-filters",

  "startups-filters",
  "UI/startups-filters",
];

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  localForage.config({
    driver: localForage.INDEXEDDB,
    name: "AkitaDev",
    version: 1.0,
    storeName: "akita-storage-dev",
  });

  // dev code
  akitaDevtools();

  persistState({
    include: [...persistentAkitaStores],
    storage: localForage,
  });

  persistState({
    include: ["session"],
  });
} else {
  localForage.config({
    driver: localForage.INDEXEDDB,
    name: "Akita",
    version: 1.0,
    storeName: "akita-storage",
  });

  // prod code
  Sentry.init({ dsn: SENTRY_DSN });

  enableAkitaProdMode();

  persistState({
    include: [...persistentAkitaStores],
    storage: localForage,
    preStorageUpdateOperator: () => debounceTime(2000),
  });

  persistState({
    include: ["session"],
    preStorageUpdateOperator: () => debounceTime(2000),
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  // </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorker.unregister();
