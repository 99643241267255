import React, { Fragment, useContext } from "react";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RLink from "../../../../../../../components/link/RLink";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import { IManagementPerson } from "./Management";
import { useSession } from "../../../../../../../hooks/session.hook";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";

interface IProps {
  tableTitle: string;
  data: IManagementPerson[];
  t: CallableFunction;
}

const ManagementTable = ({ tableTitle, data, t }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme, isB2CUser } = useSession();
  const { colors } = useAppColors();

  const tableRow = (
    col1Name: string | { fullName: string; wealthScore: number },
    col2Name: string,
    col3Name: string,
    col4Name: string,
    personId?: string,
  ) => {
    const isHeader = !(personId !== undefined);

    return (
      <Flex
        alignItemsCenter
        style={
          isHeader
            ? {
                height: isSmallerScreen ? "30px" : "50px",
                padding: "0px 15px",
                backgroundColor: isDarkTheme
                  ? "var(--immutable_gray_dark)"
                  : isB2CUser
                  ? colors.primaryLightColor
                  : "var(--color_gray_light)",
              }
            : {
                padding: "25px 15px",
              }
        }
      >
        <Flex column flex>
          {typeof col1Name === "string" ? (
            <RText
              bold={isHeader}
              fontSize={isSmallerScreen ? "11" : "16"}
              textTooltip={col1Name}
              ellipsis
            >
              {col1Name}
            </RText>
          ) : (
            <>
              {isB2CUser && ![null, undefined].includes(col1Name.wealthScore) && (
                <>
                  <StarRating affinity={col1Name.wealthScore} spacing="6" iconHeight="14" />
                  <Spacing pb="7" />
                </>
              )}
              <RLink path={`/home/search/executive-dossier/${personId}`} condition={!isHeader}>
                <RText bold={isHeader} fontSize={isSmallerScreen ? "11" : "16"}>
                  {col1Name.fullName}
                </RText>
              </RLink>
            </>
          )}
        </Flex>
        <Spacing pr="10" />
        <Flex column flex>
          <RText
            bold={isHeader}
            fontSize={isSmallerScreen ? "11" : "16"}
            textTooltip={col2Name}
            ellipsis
          >
            {col2Name}
          </RText>
        </Flex>
        <Spacing pr="10" />
        <Flex column flex>
          <RText
            bold={isHeader}
            fontSize={isSmallerScreen ? "11" : "16"}
            textTooltip={col3Name}
            ellipsis
          >
            {col3Name}
          </RText>
        </Flex>
        <Spacing pr="10" />
        <Flex column flex>
          <RText
            bold={isHeader}
            fontSize={isSmallerScreen ? "11" : "16"}
            textTooltip={col4Name}
            ellipsis
          >
            {col4Name}
          </RText>
        </Flex>
      </Flex>
    );
  };

  return data.length > 0 ? (
    <>
      <RText fontSize={isSmallerScreen ? "13" : "20"}>{tableTitle}</RText>
      <Spacing pt={isSmallerScreen ? "20" : "40"} />
      {tableRow(t("name"), t("role"), t("period"), t("promoterScore"))}
      <Spacing pt={isSmallerScreen ? "10" : "20"} />
      {data.map((person, index) => {
        return (
          <Fragment key={person.personId}>
            {tableRow(
              {
                fullName: person.fullName,
                wealthScore: person.wealthScore,
              },
              person.position,
              person.period,
              person.promoterScore + "%",
              person.personId,
            )}
            {index < data.length - 1 && <Divider />}
          </Fragment>
        );
      })}
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
    </>
  ) : null;
};

export default ManagementTable;
