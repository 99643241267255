import { QueryEntity } from "@datorama/akita";
import { LastSearchStore, LastSearchState, lastSearchStore } from "./last-search.store";

export class LastSearchQuery extends QueryEntity<LastSearchState> {
  constructor(protected store: LastSearchStore) {
    super(store);
  }
}

export const lastSearchQuery = new LastSearchQuery(lastSearchStore);
