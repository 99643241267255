import React from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../components/dividers/Divider/Divider";
import RText from "../../../../components/fonts/RText/RText";
import ExecutivesSearchResultsListHeader from "../../../../components/lists/SearchResultsList/ExecutivesSearchResultsListHeader/ExecutivesSearchResultsListHeader";
import ExecutivesSearchResultsListItem, {
  mapExecutivesSearchResponseIntoResultItems,
} from "../../../../components/lists/SearchResultsList/ExecutivesSearchResultsListItem/ExecutivesSearchResultsListItem";
import { Spacing } from "../../../../components/spacing/component";
import { useExecutivesSearchResponse } from "../../../../entities/executives-filters/hooks/executives-search-response.hook";
import { IExecutive } from "../../../../entities/executives/state/executive.model";
import Spinner from "../../../../components/other/Spinner/Spinner";
import { useSession } from "../../../../hooks/session.hook";

const ExecutivesLimitedSearchResults = () => {
  const { t } = useTranslation();
  const { searchResults, loading } = useExecutivesSearchResponse();
  const { isB2CUser } = useSession();

  if (!searchResults?.length) {
    if (loading) {
      return <Spinner />;
    }

    return (
      <Spacing pt="12">
        <RText fontSize="28">{t("noSearchResults")}</RText>
      </Spacing>
    );
  }

  const limitSearchResponseList = (): IExecutive[] => {
    if (searchResults.length > 5) {
      let arrayCopy = [...searchResults];
      return arrayCopy.splice(0, 5);
    }

    return searchResults;
  };

  return (
    <Spacing pt="12">
      <ExecutivesSearchResultsListHeader
        column1={t("name")}
        column2={isB2CUser ? t("wealthScore") : t("promoterScore")}
        column3={t("company")}
      />

      <Spacing pt="12" />
      <Divider />
      {mapExecutivesSearchResponseIntoResultItems(limitSearchResponseList()).map((item) => {
        return (
          <React.Fragment key={item.id}>
            <ExecutivesSearchResultsListItem key={item.id} item={item} />
            <Divider />
          </React.Fragment>
        );
      })}
    </Spacing>
  );
};

export default ExecutivesLimitedSearchResults;
