export const DEFAULT_GERMANY_COORDINATES = {
  lat: 51.1975,
  lng: 13.2429,
};

export const DEFAULT_SWITZERLAND_COORDINATES = {
  lat: 46.8182,
  lng: 8.2275,
};

export const ROUTE_COLOR = "#9E00C6";
