import { EntityUIQuery, QueryEntity, QueryConfig, Order } from "@datorama/akita";
import {
  ExecutivesFiltersState,
  executivesFiltersStore,
  ExecutivesFiltersStore,
  ExecutivesFiltersUIState,
} from "./executives-filters.store";

@QueryConfig({
  sortBy: "order",
  sortByOrder: Order.ASC,
})
export class ExecutivesFiltersQuery extends QueryEntity<ExecutivesFiltersState> {
  ui: EntityUIQuery<ExecutivesFiltersUIState>;

  constructor(protected store: ExecutivesFiltersStore) {
    super(store);
    this.createUIQuery();
  }
}

export const executivesFiltersQuery = new ExecutivesFiltersQuery(executivesFiltersStore);
