import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  FoundationReachIcon,
  FoundationReachDarkIcon,
  EAndEIcon,
  EAndEDarkIcon,
} from "../../../../../../assets/executive-dossier/kpi/icons";
import { ReactComponent as HNWIIcon } from "../../../../../../assets/executives-hub/HNWIIcon.svg";
import { ReactComponent as PBIcon } from "../../../../../../assets/executives-hub/PBIcon.svg";
import { ReactComponent as PBIconDark } from "../../../../../../assets/executives-hub/PBIconDark.svg";
import AnimatedCircleIcon from "../../../../../../components/animations/animated-circle-icon/AnimatedCircleIcon";
import { Spacing } from "../../../../../../components/spacing/component";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../components/fonts/RText/RText";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../../../../hooks/session.hook";
import useAppColors from "../../../../../../hooks/app-colors.hook";
import { IColors } from "../../../../../../models/login/client.model";
import {
  useIsCorporateDossier,
  useIsExecutiveDossier,
} from "../../../../../../utils/route.util";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

interface IProps {
  promoterScore: number;
  successScore?: number;
  wealthScore?: number;
  foundationReachScore?: number;
  eAndEScore?: number;
}

export default function Score({
  promoterScore,
  successScore,
  wealthScore,
  foundationReachScore,
  eAndEScore,
}: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser, isDarkTheme } = useSession();
  const { colors } = useAppColors();
  const isExecutiveDossier = useIsExecutiveDossier();
  const isCorporateDossier = useIsCorporateDossier();

  const renderScoreAnimation = (title: string, score: number) => {
    if (!score) {
      return null;
    }

    return (
      <Flex column alignItemsCenter>
        <RText bold fontSize={isSmallerScreen ? "12" : "20"}>
          {title}
        </RText>
        <Spacing pt="15" />
        {isB2CUser && isExecutiveDossier ? (
          <AnimatedCircleIcon
            value={score}
            showValuePercentage={true}
            percentageContainerStyle={{
              left: isSmallerScreen ? "50px" : "58px",
              backgroundColor: colors.backgroundWhiteColor,
              color: colors.textDarkColor,
            }}
            innerStyle={{
              border: `1px solid ${colors.backgroundBlackColor}`,
              width: "60%",
              height: "60%",
            }}
            className={
              isSmallerScreen
                ? styles.animatedIconContainer__Mobile
                : styles.animatedIconContainer
            }
            trailColor={
              isDarkTheme ? "var(--color_gray_light)" : "var(--color_secondary_dark)"
            }
          >
            <RText fontSize={isSmallerScreen ? "16" : "20"}>{score.toString()}</RText>
          </AnimatedCircleIcon>
        ) : (
          <div
            className={
              isSmallerScreen
                ? styles.animatedIconContainer__Mobile
                : styles.scoreAnimationContainer
            }
          >
            {/* <ScoreAnimation score={score} /> */}
            <CircularProgressbar
              value={score}
              maxValue={100}
              text={`${score}%`}
              circleRatio={0.8}
              styles={buildStyles({
                pathTransition: "none",
                rotation: 1 / 2 + 1 / 10,
                strokeLinecap: "butt",
                textColor: colors.textDarkColor,
                pathColor: "var(--color_secondary_dark)",
                // trailColor:
                //     "#0e67ef",
              })}
            />
          </div>
        )}
      </Flex>
    );
  };

  const renderScoreAnimationForB2CUser = (score: number, title: keyof IProps) => {
    if (!isB2CUser || !score) {
      return null;
    }

    const scoreIconProps = getB2CScoreIconProps(score, title, colors, isDarkTheme, t);

    if (!scoreIconProps) {
      return null;
    }

    return (
      <Flex column alignItemsCenter>
        <RText bold fontSize={isSmallerScreen ? "12" : "20"}>
          {scoreIconProps.title}
        </RText>
        <Spacing pt={isCorporateDossier && !isSmallerScreen ? "30" : "15"} />
        <div
          className={
            isSmallerScreen
              ? styles.animatedIconContainer__Mobile
              : styles.animatedIconContainer
          }
        >
          {scoreIconProps.Icon}
        </div>
      </Flex>
    );
  };

  return (
    <>
      <Spacing pt={isSmallerScreen ? "18" : "40"} />
      <Flex justifyContentSpaceEvenly>
        {renderScoreAnimationForB2CUser(wealthScore, "wealthScore")}
        {renderScoreAnimation(t("successScore"), successScore)}
        {renderScoreAnimation(t("promoterScore"), promoterScore)}
        {renderScoreAnimationForB2CUser(foundationReachScore, "foundationReachScore")}
        {renderScoreAnimationForB2CUser(eAndEScore, "eAndEScore")}
      </Flex>
    </>
  );
}

const getB2CScoreIconProps = (
  score: number,
  propTitle: keyof IProps,
  colors: IColors,
  isDarkTheme: boolean,
  t: CallableFunction,
) => {
  if (propTitle === "foundationReachScore") {
    return {
      title: t("foundationReach"),
      Icon: (
        <ScoreIconContainer
          scoreValue={score?.toString()}
          ScoreLightIcon={FoundationReachIcon}
          ScoreDarkIcon={FoundationReachDarkIcon}
        />
      ),
    };
  }

  if (propTitle === "eAndEScore") {
    return {
      title: t("eAndE"),
      Icon: (
        <ScoreIconContainer
          scoreValue={score?.toString()}
          ScoreLightIcon={EAndEIcon}
          ScoreDarkIcon={EAndEDarkIcon}
        />
      ),
    };
  }

  if (propTitle === "wealthScore" && score === 3) {
    return {
      title: "PB",
      Icon: (
        <AnimatedCircleIcon
          innerStyle={{
            border: `1px solid ${colors.backgroundBlackColor}`,
            width: "60%",
            height: "60%",
          }}
        >
          {isDarkTheme ? (
            <PBIconDark
              color={isDarkTheme ? colors.secondaryDarkColor : "#000"}
              height="35px"
              width="35px"
            />
          ) : (
            <PBIcon
              color={isDarkTheme ? colors.secondaryDarkColor : "#000"}
              height="35px"
              width="35px"
            />
          )}
        </AnimatedCircleIcon>
      ),
    };
  }

  if (propTitle === "wealthScore" && [4, 5].includes(score)) {
    return {
      title: "HNWI",
      Icon: (
        <AnimatedCircleIcon
          innerStyle={{
            border: `1px solid ${colors.backgroundBlackColor}`,
            width: "60%",
            height: "60%",
          }}
        >
          <HNWIIcon
            color={isDarkTheme ? colors.secondaryDarkColor : "#000"}
            height="35px"
            width="35px"
          />
        </AnimatedCircleIcon>
      ),
    };
  }

  return null;
};

interface ScoreIconContainerProps {
  scoreValue: string;
  ScoreLightIcon: FC<React.SVGProps<SVGSVGElement>>;
  ScoreDarkIcon: FC<React.SVGProps<SVGSVGElement>>;
  className?: string;
}

export const ScoreIconContainer: FC<ScoreIconContainerProps> = ({
  scoreValue,
  ScoreLightIcon,
  ScoreDarkIcon,
  className,
}) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme } = useSession();
  const { colors } = useAppColors();

  return (
    <div className={`${styles.scoreIconContainer} ${className}`}>
      <RText
        fontSize={isSmallerScreen ? "16" : "20"}
        className={styles.scoreIconContainerValue}
      >
        {scoreValue}
      </RText>
      {isDarkTheme ? (
        <ScoreDarkIcon color={colors.secondaryDarkColor} />
      ) : (
        <ScoreLightIcon color={colors.secondaryDarkColor} />
      )}
    </div>
  );
};
