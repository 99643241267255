import { createContext } from "react";
import { corporatesApiService } from "../../../api/services/corporates-api.service";
import { CorporatesFacade, ICorporatesContext } from "./corporates.facade";
import { corporatesQuery } from "./corporates.query";
import { corporatesStore } from "./corporates.store";
import { customTagsFacade } from "../../custom-tags/state/custom-tags.context";

export const corporatesFacade = new CorporatesFacade(
  corporatesStore,
  corporatesQuery,
  corporatesApiService,
  customTagsFacade,
);

export const CorporatesContext = createContext<ICorporatesContext>(corporatesFacade);
