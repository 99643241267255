import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IFirstLine } from "../../../../../../../entities/executive-dossier/models/foundation-reach.model";
import { abbreviateNumber } from "../../../../../../../helpers/number.helper";
import { formatIndustrySectorForTranslation } from "../../../../../../../helpers/translation.helper";
import { formatPeriodDate } from "../../../../../../../pages/home/corporates/dossier/components/side-navigation-item-content/management/Management";
import EAndEScore from "../../../shared/EAndEScore/EAndEScore";
import EntityInfo, { EntityType } from "../../../shared/EntityInfo";
import TableRow from "../../../shared/TableRow";

interface IProps {
  firstLineData: IFirstLine[];
}

export default function FirstLine({ firstLineData }: IProps) {
  const { t } = useTranslation();

  return !firstLineData?.length ? (
    <></>
  ) : (
    <>
      <TableRow
        col1Data={t("company")}
        col2Data={t("position")}
        col3Data={t("period")}
        col4Data={t("eAndE")}
        header
      />
      <Spacing pt="20" />
      {firstLineData.map((company, index) => {
        return (
          <Fragment key={company.company_id + index}>
            <TableRow
              col1Data={
                <EntityInfo
                  entityId={company.company_id?.toString()}
                  entityType={EntityType.CORPORATE}
                  entityName={company.company_name}
                  ratingScore={company.affinity_score}
                  subTitle={formatIndustrySectorForTranslation(company.industry_sector, t)}
                  headcount={
                    company.employee_count ? abbreviateNumber(company.employee_count) : null
                  }
                  turnover={company.turnover ? abbreviateNumber(company.turnover) : null}
                />
              }
              col2Data={company.position ?? ""}
              col3Data={formatPeriodDate(company, t)}
              col4Data={<EAndEScore score={company.ee_score?.toString()} />}
            />

            {index < firstLineData.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </>
  );
}
