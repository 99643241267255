import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { SessionContext } from "../state/akita/session/session.context";

const useAppLogo = () => {
  const sessionContext = useContext(SessionContext);
  const [logo] = useObservable(sessionContext.logo$, sessionContext.getLogo());

  return { logo };
};

export default useAppLogo;
