import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ExecutivesFiltersContext } from "../state/executives-filters.context";

interface UseExecutivesSearchHitsHook {
  totalCount: number;
}

export function useExecutivesSearchHits(): UseExecutivesSearchHitsHook {
  const executivesFiltersService = useContext(ExecutivesFiltersContext);

  const [totalCount] = useObservable(
    executivesFiltersService.searchResultTotalCount$,
    executivesFiltersService.getResultCount(),
  );

  return { totalCount };
}
