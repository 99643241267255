import React from "react";
import { Spacing } from "../spacing/component";
import { Flex } from "../containers/Flex/Flex";
import { ReactComponent as CsvDownloadIcon } from "../../assets/CsvDownloadIcon.svg";
import { ReactComponent as XlsDownloadIcon } from "../../assets/XlsDownloadIcon.svg";
import { EXPORT_TYPE } from "../../shared/enums/export-type.enum";
import styles from "./styles.module.css";

interface IProps {
  exportResults: (type: EXPORT_TYPE) => void;
}

const Export = ({ exportResults }: IProps) => (
  <Spacing pv="8">
    <Flex justifyContentEnd>
      <div
        role="button"
        tabIndex={0}
        onClick={() => exportResults(EXPORT_TYPE.CSV)}
        className={styles.exportButton}
      >
        <CsvDownloadIcon />
      </div>
      <Spacing pl="20" />
      <div
        role="button"
        tabIndex={0}
        onClick={() => exportResults(EXPORT_TYPE.XLS)}
        className={styles.exportButton}
      >
        <XlsDownloadIcon />
      </div>
    </Flex>
  </Spacing>
);

export default Export;
