import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import {
  FooterNavigationIcon,
  FooterNewsIcon,
  GreenCircle,
  GreenDot,
} from "../../../assets/hub/footer-icons/icons";
import { ReactComponent as ChevronRightLargeBlue } from "../../../assets/hub/ChevronRightLargeBlue.svg";
import { ReactComponent as ChevronLeftLargeBlue } from "../../../assets/hub/ChevronLeftLargeBlue.svg";
import { Flex } from "../../../components/containers/Flex/Flex";
import RText from "../../../components/fonts/RText/RText";
import { Spacing } from "../../../components/spacing/component";
import { useTranslation } from "react-i18next";
import useAppColors from "../../../hooks/app-colors.hook";
import HorizontallyScrollable from "../../../components/containers/HorizontallyScrollable/HorizontallyScrollable";
import { useTopNews } from "../../../modules/executives-hub/hooks/top-news.hook";
import { useWindowSize } from "../../../hooks/window.hook";
import { ExecutiveHubContext } from "../../../modules/executives-hub/state/executives-hub.context";
import { useLeftArrowHandler, useRightArrowHandler } from "../../../hooks/hub-footer.hook";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";
import FooterItems from "./FooterItems/FooterItems";
import { INavigationIconProps, renderBounceContent } from "../../hub/HubFooter/HubFooter";
import { ReactComponent as TopLeadIcon } from "../../../assets/executives-hub/TopLeadIcon.svg";
import FooterNavigation from "./FooterNavigation/FooterNavigation";
import { useTopLeads } from "../../../modules/executives-hub/hooks/top-leads.hook";
import { IExecutiveHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";

export enum HUB_FOOTER_NAVIGATION_TYPE {
  LEADS,
  COMPANY_NEWS,
  CONTACT_EVENT,
  MARKET_INSIGHT,
}

export interface INavigationItem {
  title: string;
  type: HUB_FOOTER_NAVIGATION_TYPE;
  exist: boolean;
}

const ExecutivesHubFooter = () => {
  const { t } = useTranslation();
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { setHubRolloverExecutive } = useContext(ExecutiveHubContext);

  const { hubNewsFooterItems, loadingTopNews } = useTopNews();
  const { hubLeadsFooterItems } = useTopLeads();

  const navigationItems = useRef<INavigationItem[]>([
    {
      title: "leads",
      type: HUB_FOOTER_NAVIGATION_TYPE.LEADS,
      exist: true,
    },
    {
      title: "companyNews",
      type: HUB_FOOTER_NAVIGATION_TYPE.COMPANY_NEWS,
      exist: true,
    },
    {
      title: "contactEvent",
      type: HUB_FOOTER_NAVIGATION_TYPE.CONTACT_EVENT,
      exist: true,
    },
    {
      title: "marketInsight",
      type: HUB_FOOTER_NAVIGATION_TYPE.MARKET_INSIGHT,
      exist: true,
    },
  ]);

  const noDataAvailable = !Boolean(navigationItems.current.find(({ exist }) => exist));
  const allContentLoaded = !loadingTopNews;

  const [selectedNavItem, setSelectedNavItem] = useState(null);

  useEffect(() => {
    if (!allContentLoaded) {
      return;
    }

    navigationItems.current = navigationItems.current.map((item: INavigationItem) => {
      if (item.type === HUB_FOOTER_NAVIGATION_TYPE.LEADS) {
        return {
          ...item,
          exist: hubLeadsFooterItems?.length > 0,
        };
      } else if (item.type === HUB_FOOTER_NAVIGATION_TYPE.COMPANY_NEWS) {
        return {
          ...item,
          exist: hubNewsFooterItems?.length > 0,
        };
      } else if (item.type === HUB_FOOTER_NAVIGATION_TYPE.CONTACT_EVENT) {
        return {
          ...item,
          exist: false,
        };
      }

      return {
        ...item,
        exist: false,
      };
    });

    if (noDataAvailable) {
      return;
    }

    for (let i = 0; i < navigationItems.current.length; i++) {
      const { type, exist } = navigationItems.current[i];

      if (exist) {
        setSelectedNavItem(type);
        return;
      }
    }
  }, [allContentLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const [repeatAnimation, setRepeatAnimation] = useState(false);

  const onNavigationItemClick = (item: INavigationItem) => {
    if (!item.exist || !allContentLoaded) {
      return;
    }

    setRepeatAnimation(true);
    setSelectedNavItem(item.type);
  };

  const onAnimationOver = () => {
    const nextActiveNavItem = getNextActiveNavigationItem(
      selectedNavItem,
      navigationItems.current,
    );

    setSelectedNavItem(nextActiveNavItem);
  };

  const windowSize = useWindowSize();
  // Explanation in corporate -> HubFooter
  const minAnimationContentWidth = isSmallerScreen
    ? windowSize.width - 320
    : windowSize.width - 430;

  const hubLeadsWidth = useMemo(
    () => 100 * hubLeadsFooterItems?.length,
    [hubLeadsFooterItems],
  );
  const hubNewsWidth = useMemo(() => 100 * hubNewsFooterItems?.length, [hubNewsFooterItems]);

  const onAnimationRepeat = () => {
    setRepeatAnimation(false);

    const contentInitialXAxisPosition = {
      start: 0,
      // Explanation in corporate -> HubFooter
      end: minAnimationContentWidth - 30,
    };

    return contentInitialXAxisPosition;
  };

  const leftArrowHandler = useLeftArrowHandler();
  const rightArrowHandler = useRightArrowHandler();

  const renderFooterNavigationContent = () => {
    if (noDataAvailable) {
      return (
        <HorizontallyScrollable>
          <RText immutableWhite fontSize="16">
            {t("noDataAvailable")}
          </RText>
        </HorizontallyScrollable>
      );
    }

    switch (selectedNavItem) {
      case HUB_FOOTER_NAVIGATION_TYPE.LEADS:
        return renderBounceContent(
          renderFooterItems(
            hubLeadsFooterItems,
            HUB_FOOTER_NAVIGATION_TYPE.LEADS,
            hubLeadsWidth < minAnimationContentWidth
              ? hubLeadsWidth
              : minAnimationContentWidth,
          ),
          onAnimationOver,
          repeatAnimation,
          onAnimationRepeat,
          leftArrowHandler,
          rightArrowHandler,
        );
      case HUB_FOOTER_NAVIGATION_TYPE.COMPANY_NEWS:
        return renderBounceContent(
          renderFooterItems(
            hubNewsFooterItems,
            HUB_FOOTER_NAVIGATION_TYPE.COMPANY_NEWS,
            hubNewsWidth < minAnimationContentWidth ? hubNewsWidth : minAnimationContentWidth,
          ),
          onAnimationOver,
          repeatAnimation,
          onAnimationRepeat,
          leftArrowHandler,
          rightArrowHandler,
        );
      case HUB_FOOTER_NAVIGATION_TYPE.CONTACT_EVENT:
        return <></>;
      case HUB_FOOTER_NAVIGATION_TYPE.MARKET_INSIGHT:
        return <></>;
      default:
        return null;
    }
  };

  const selectedNavigationItemIconProps = getSelectedNavigationItemIconProps(selectedNavItem);

  return (
    <Flex
      justifyContentSpaceBetween
      alignItemsCenter
      className={isSmallerScreen ? styles.component__Responsive : styles.component}
      onMouseLeave={() => setHubRolloverExecutive(null)}
    >
      <Flex
        alignItemsCenter
        className={`${styles.footerNavigationContainer} ${
          isSmallerScreen && styles.footerNavigationContainer__Responsive
        }`}
      >
        {!isSmallerScreen && (
          <div className={styles.footerNavigationIconContainer}>
            <RText fontSize="13" bold immutableWhite className={styles.footerText}>
              {t("top")}
            </RText>

            {selectedNavigationItemIconProps ? (
              <>
                {selectedNavigationItemIconProps.renderIcon({
                  color: colors.primaryDarkColor,
                  className: styles.selectedNavItemIcon,
                })}
              </>
            ) : (
              <GreenCircle
                color={colors.primaryDarkColor}
                className={styles.selectedNavItemIcon}
              />
            )}

            <FooterNavigationIcon
              color={colors.primaryDarkColor}
              className={styles.footerNavigationIcon}
            />

            {selectedNavigationItemIconProps?.dotIconPosition && (
              <GreenDot
                style={{
                  position: "absolute",
                  color: colors.primaryDarkColor,
                  ...selectedNavigationItemIconProps.dotIconPosition,
                }}
              />
            )}
          </div>
        )}

        <Spacing pr="30" />

        <FooterNavigation
          selectedNavItem={selectedNavItem}
          navigationItems={navigationItems.current}
          onItemClick={onNavigationItemClick}
        />

        <div className={styles.verticalDivider} style={{ marginRight: "15px" }} />
        <ChevronLeftLargeBlue
          color={colors.secondaryDarkColor}
          className={styles.arrowIcon}
          {...leftArrowHandler.clickHandlers}
        />
        <div
          className={styles.verticalDivider}
          style={{ marginLeft: "15px", marginRight: "15px" }}
        />
      </Flex>

      {!allContentLoaded ? (
        <HorizontallyScrollable>
          <RText immutableWhite fontSize="16">
            {t("loading")}
          </RText>
        </HorizontallyScrollable>
      ) : (
        renderFooterNavigationContent()
      )}

      <Flex alignItemsCenter justifyContentEnd className={styles.rightArrowIconContainer}>
        <div className={styles.verticalDivider} style={{ margin: "0px 15px" }} />
        <ChevronRightLargeBlue
          color={colors.secondaryDarkColor}
          className={styles.arrowIcon}
          {...rightArrowHandler.clickHandlers}
        />
      </Flex>
    </Flex>
  );
};

export default React.memo(ExecutivesHubFooter);

const renderFooterItems = (
  items: IExecutiveHubFooterItemData[],
  itemType: HUB_FOOTER_NAVIGATION_TYPE,
  minAnimationContentWidth: number,
) => {
  return (
    <div
      className={styles.footerItemsContainer}
      style={{
        minWidth: minAnimationContentWidth + "px",
      }}
    >
      {items?.map((item: IExecutiveHubFooterItemData, index: number) => {
        return (
          <Flex alignItemsCenter key={index}>
            <FooterItems item={item} itemType={itemType} />
            {index < items.length - 1 && (
              <div className={styles.verticalDivider} style={{ margin: "0px 10px" }} />
            )}
          </Flex>
        );
      }) ?? <></>}
    </div>
  );
};

function getNextActiveNavigationItem(
  currentActiveItem: HUB_FOOTER_NAVIGATION_TYPE,
  navigationItems: INavigationItem[],
) {
  const lastExistItemIndex = navigationItems.map(({ exist }) => exist).lastIndexOf(true);
  const nextActiveItem = navigationItems
    .filter(({ type, exist }) => exist && type !== currentActiveItem)
    .find(({ type }) => {
      return currentActiveItem === lastExistItemIndex
        ? type < currentActiveItem
        : type > currentActiveItem;
    });

  return !nextActiveItem ? currentActiveItem : nextActiveItem.type;
}

function getSelectedNavigationItemIconProps(selectedItem: HUB_FOOTER_NAVIGATION_TYPE) {
  switch (selectedItem) {
    case HUB_FOOTER_NAVIGATION_TYPE.LEADS:
      return {
        dotIconPosition: { top: "2px", right: "15px" },
        renderIcon: ({ color, className }: INavigationIconProps) => (
          <TopLeadIcon color={color} className={className} />
        ),
      };
    case HUB_FOOTER_NAVIGATION_TYPE.COMPANY_NEWS:
      return {
        dotIconPosition: { top: "24px", right: "0px" },
        renderIcon: ({ color, className }: INavigationIconProps) => (
          <FooterNewsIcon color={color} className={className} />
        ),
      };
    case HUB_FOOTER_NAVIGATION_TYPE.CONTACT_EVENT:
      return null;
    case HUB_FOOTER_NAVIGATION_TYPE.MARKET_INSIGHT:
      return null;
    default:
      return null;
  }
}
