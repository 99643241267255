import { useContext } from "react";
import { Spacing } from "../../../../components/spacing/component";
import { classNames } from "../../../../helpers/string.helper";
import { IFilterUI } from "../../../../shared/interfaces/filter-ui.interface";
import FilterValue from "../FilterValue/FilterValue";
import styles from "./styles.module.css";
import { ICorporatesFilter } from "../../../../entities/corporates-filters/models/corporates-filter.model";
import RText from "../../../../components/fonts/RText/RText";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { FILTER_TYPE } from "../../../../shared/enums/filter-type.enum";
import {
  ISelectedValuesRange,
  ISelectedValuesRef,
  SelectedValueTypes,
  isSelectedValueEqualToFilterValue,
} from "../../../../shared/interfaces/selected-value-types.interface";
import { SELECTED_VALUES_TYPE } from "../../../../shared/enums/selected-values-type.enum";
import FilterAutosuggestRange from "../FilterAutosuggest/FilterAutosuggestRange";
import {
  getDisplayValue,
  getSortFunction,
  getSuggestionsFunction,
  getInputChangeCallback,
  getDynamicRangeFilterCount,
  getDynamicRangeLabel,
  getDynamicRangeSelectionReferents,
} from "../../../../utils/filters";
import inputStyles from "../FilterAutosuggest/FilterAutosuggestElement.module.css";
import { useTranslation } from "react-i18next";

interface IProps {
  filter: ICorporatesFilter;
  filterUI: IFilterUI;
  allFiltersExpanded?: boolean;
  rowExpanded: boolean;
  selectFilterValue: (filterId: string, filterValueId: SelectedValueTypes) => void;
}

const Filter = ({
  filter,
  filterUI,
  allFiltersExpanded,
  selectFilterValue,
  rowExpanded,
}: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { t } = useTranslation();

  let filterContents: Array<JSX.Element>;
  const getDisplayValueByFilterKey = getDisplayValue(filter.title_key);
  const getSortFunctionByFilterKey = getSortFunction(filter.title_key);
  const getSuggestionsFunctionByFilterKey = getSuggestionsFunction(filter.title_key);
  const getInputChangeCallbackByFilterKey = getInputChangeCallback(filter.title_key);
  const getDynamicRangeFilterCountByFilterKey = getDynamicRangeFilterCount(filter.title_key);
  const getDynamicRangeLabelByFilterKey = getDynamicRangeLabel(filter.title_key);
  const getDynamicRangeSelectionReferentsByFilterKey = getDynamicRangeSelectionReferents(
    filter.title_key,
  );

  switch (filter.type) {
    case FILTER_TYPE.AUTOSUGGEST_RANGE:
      // map entries for already selected values
      const existingFilterContents = filterUI.selectedValues
        // remove selected values with raw strings since
        // those are legacy and not dynamic ranges
        .filter((sV): sV is ISelectedValuesRef | ISelectedValuesRange => {
          if (typeof sV === "string" || sV instanceof String) return false;
          return true;
        })
        .map((sV) => {
          let clickHandler: () => void;
          let label: string | [string, string];
          let filterCount: number;

          switch (sV.type) {
            case SELECTED_VALUES_TYPE.RANGE:
              const { fromReferent, toReferent } =
                getDynamicRangeSelectionReferentsByFilterKey(sV, filter.values);
              label = getDynamicRangeLabelByFilterKey(fromReferent, toReferent, sV);
              clickHandler = () =>
                selectFilterValue(filter.id, {
                  type: SELECTED_VALUES_TYPE.RANGE,
                  from: sV.from,
                  to: sV.to,
                });
              filterCount = getDynamicRangeFilterCountByFilterKey(filter.values, sV);
              break;
            case SELECTED_VALUES_TYPE.REFERENCE:
              const referredValue = filter.values.find(
                (filterValue) => sV.ref === filterValue.label,
              );
              if (!referredValue) return undefined;
              label = getDisplayValueByFilterKey(referredValue);
              filterCount = referredValue.doc_count;
              clickHandler = () =>
                selectFilterValue(filter.id, {
                  type: SELECTED_VALUES_TYPE.REFERENCE,
                  ref: referredValue.label,
                });
              break;
            default:
              break;
          }

          return (
            <Spacing pv="4" key={`selected-${label}`}>
              <FilterValue
                filterLabel={label}
                filterCount={filterCount}
                filterType={filter.type}
                selected={true}
                handleItemClick={clickHandler}
              />
            </Spacing>
          );
        })
        .filter((element) => element);

      // map remaining non-selected items to components
      let remainingCount = 20 - existingFilterContents.length;
      const remainingFilterContents =
        remainingCount > 0 ? new Array(remainingCount) : undefined;
      for (
        let insertedFilterValues = 0, filterIdx = 0;
        insertedFilterValues < remainingCount && filterIdx < filter.values.length;
        filterIdx++
      ) {
        if (
          !filterUI.selectedValues.some((sV: any) =>
            isSelectedValueEqualToFilterValue(sV, filter.values[filterIdx]),
          )
        ) {
          const filterValue = filter.values[filterIdx];
          let filterLabel = getDisplayValueByFilterKey(filterValue);

          remainingFilterContents[insertedFilterValues++] = (
            <Spacing pv="4" key={`unselected-${filterLabel}`}>
              <FilterValue
                filterLabel={filterLabel}
                filterCount={filterValue.doc_count}
                filterType={filter.type}
                selected={false}
                handleItemClick={() =>
                  selectFilterValue(filter.id, {
                    type: SELECTED_VALUES_TYPE.REFERENCE,
                    ref: filterValue.label,
                  })
                }
              />
            </Spacing>
          );
        }
      }

      filterContents = [].concat(
        [
          <FilterAutosuggestRange
            key="filter-entry"
            filterId={filter.id}
            items={filter.values}
            inputChangeCallback={getInputChangeCallbackByFilterKey}
            getDisplayValue={getDisplayValueByFilterKey}
            getSearchValue={(item) => {
              const value: unknown = item.label;
              return value === undefined || value === null
                ? ""
                : getDisplayValueByFilterKey(item);
            }}
            getReferenceValue={(item) => item.label}
            getRangeValue={(item) => item.value_key}
            selectFilterValue={selectFilterValue}
            sortFunction={getSortFunctionByFilterKey}
            suggestionsFunction={getSuggestionsFunctionByFilterKey}
          />,
        ],
        existingFilterContents,
        remainingFilterContents,
      );
      break;
    case FILTER_TYPE.AGE_RANGE:
      const secondClickHandler = (e: any) => {
        e.preventDefault();
        const fd = new FormData(e.target);
        const data = Object.fromEntries(fd);
        console.log(data, data.from ? data.from : "1900", data.to ? data.to : "2024");

        selectFilterValue(filter.id, {
          type: SELECTED_VALUES_TYPE.RANGE,
          from: data.from ? String(data.from) : "1900",
          to: data.to ? String(data.to) : "2024",
        });
      };

      filterContents = [].concat([
        <form
          onSubmit={secondClickHandler}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            marginRight: 30,
          }}
        >
          <div className={inputStyles.inputContainer} style={{ marginBottom: 10, width: 110 }}>
            <input
              key="age_filter_from"
              name="from"
              type="number"
              min={1900}
              max={2024}
              className={classNames(inputStyles)}
              placeholder={t("year")}
            />
          </div>
          <div>-</div>
          <div className={inputStyles.inputContainer} style={{ marginBottom: 10, width: 110 }}>
            <input
              key="age_filter_to"
              name="to"
              type="number"
              min={1900}
              max={2024}
              className={classNames(inputStyles)}
              placeholder={t("year")}
            />
          </div>
          <input type="submit" hidden />
        </form>,
      ]);
      break;
    default:
      filterContents = filter.values.map((filterValue: any) => {
        return (
          <Spacing pv="4" key={filterValue.id}>
            <FilterValue
              filterLabel={filterValue.label}
              filterCount={
                filterValue.doc_count?.value >= 0
                  ? filterValue.doc_count?.value
                  : filterValue.doc_count
              }
              filterType={filter.type}
              selected={(filterUI.selectedValues as Array<string>).includes(filterValue.label)}
              handleItemClick={() => selectFilterValue(filter.id, filterValue.label)}
            />
          </Spacing>
        );
      });
      break;
  }

  return (
    <div
      className={classNames(styles, {
        collapsed: !allFiltersExpanded,
      })}
    >
      <div className={styles.title}>
        <RText fontSize={isSmallerScreen ? "13" : "16"} bold>
          {filter.title}
        </RText>
      </div>
      {rowExpanded && (
        <>
          <Spacing pb="16" />
          {filterContents}
        </>
      )}
    </div>
  );
};

export default Filter;
