import React, { Fragment, ReactElement, useContext, useEffect } from "react";
import { Element } from "react-scroll";
import styles from "./styles.module.css";
import Divider from "../../../../../../components/dividers/Divider/Divider";
import { Spacing } from "../../../../../../components/spacing/component";
import { ICorporate } from "../../../../../../entities/corporates/models/corporate.model";
import {
  useCorporateLookALikes,
  useCorporateNews,
  useCorporateProductTrends,
  useCorporateRelationshipIndicator,
} from "../../../../../../hooks/corporates.hook";
import Headcount from "./headcount/Headcount";
import Investment, { getInvestmentCount } from "./investment/Investment";
import LookALikes from "./look-a-likes/LookALikes";
import MainIndustry from "./main-industry/MainIndustry";
import Management from "./management/Management";
import NotesList from "./notes-list/NotesList";
import ProductTrends from "./product-trends/ProductTrends";
import RelationshipIndicator from "./relationship-indicator/RelationshipIndicator";
import Turnover from "./turnover/Turnover";
import Shareholders from "./shareholders/Shareholders";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

export interface INavItemContent {
  exist: boolean;
  anchorName: string;
  Component: ReactElement;
}

interface IProps {
  activeCorporate: ICorporate;
}

export default function SideNavigationItemContent({ activeCorporate }: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const onScroll = () => document.getElementById("popup-root")?.remove();

  /**
   * While scrolling inside container, popup when open can not be positioned well for some reason,
   * so we hide it on scroll for now.
   *
   * TODO Delete this after new scroll feature is implemented
   */
  useEffect(() => {
    const contentContainerElement = document.getElementById("contentContainerElement");
    contentContainerElement?.addEventListener("scroll", onScroll);

    return () => contentContainerElement?.removeEventListener("scroll", onScroll);
  }, []);

  const {
    id,
    company_name,
    industry_sector,
    employee_count: headcount,
    employee_counts_historic: headcountChartData,
    turnover,
    turnover_historic: turnoverChartData,
    management,
    shareholders_companies_in,
    shareholders_companies_out,
    shareholders_people,
  } = activeCorporate;

  if (!id) {
    console.warn("id parameter does not exist for this company");
  }

  const { corporateProductTrends, totalCorporateProductTrends } = useCorporateProductTrends();

  const { corporateNews } = useCorporateNews();
  const { corporateRelationshipIndicator } = useCorporateRelationshipIndicator();
  const { corporateLookALikes, totalCorporateLookALikes } = useCorporateLookALikes();

  const shareholdersCount = getInvestmentCount(shareholders_companies_in, shareholders_people);
  const investmentCount = getInvestmentCount(shareholders_companies_out);

  const itemsContent: INavItemContent[] = [
    {
      exist: Boolean(totalCorporateProductTrends && totalCorporateProductTrends > 0),
      anchorName: "productTrends",
      Component: <ProductTrends productTrends={corporateProductTrends} />,
    },
    {
      exist: Boolean(corporateNews && corporateNews.length > 0),
      anchorName: "news",
      Component: <NotesList corporateNews={corporateNews} />,
    },
    {
      exist: Boolean(industry_sector),
      anchorName: "factsheet",
      Component: <MainIndustry />,
    },
    {
      exist: Boolean(headcount),
      anchorName: "headcount",
      Component: <Headcount chartData={headcountChartData} />,
    },
    {
      exist: Boolean(turnover),
      anchorName: "turnover",
      Component: <Turnover chartData={turnoverChartData} />,
    },
    {
      exist: Boolean(shareholdersCount > 0),
      anchorName: "shareholders",
      Component: (
        <Shareholders
          shareholdersCompanies={shareholders_companies_in}
          shareholdersPeople={shareholders_people}
        />
      ),
    },
    {
      exist: Boolean(investmentCount > 0),
      anchorName: "investment",
      Component: <Investment shareholdersCompanies={shareholders_companies_out} />,
    },
    {
      exist: Boolean(management && management.length > 0),
      anchorName: "management",
      Component: <Management managementData={management} />,
    },
    {
      exist: Boolean(
        corporateRelationshipIndicator &&
          (corporateRelationshipIndicator?.first_line?.length > 0 ||
            corporateRelationshipIndicator?.second_line?.length > 0),
      ),
      anchorName: "relationshipIndicator",
      Component: (
        <RelationshipIndicator
          corporateRelationshipIndicator={corporateRelationshipIndicator}
          activeCorporateId={activeCorporate.id}
        />
      ),
    },
    {
      exist: Boolean(totalCorporateLookALikes && totalCorporateLookALikes > 0),
      anchorName: "lookALikes",
      Component: (
        <LookALikes
          companyName={company_name}
          corporateLookALikes={corporateLookALikes}
          totalCorporateLookALikes={totalCorporateLookALikes}
        />
      ),
    },
  ];

  const existingItemsList = itemsContent.filter(({ exist }) => exist);

  return (
    <Element
      name="contentContainer"
      id="contentContainerElement"
      className={`${!isSmallerScreen && styles.contentContainer}`}
    >
      {existingItemsList.map((item, index) => (
        <Fragment key={item.anchorName}>
          <Element id={item.anchorName} name={item.anchorName}>
            {item.Component}
          </Element>
          {index < existingItemsList.length - 1 && (
            <>
              <Divider black className={styles.divider} />
              <Spacing pt="30" />
            </>
          )}
        </Fragment>
      ))}
    </Element>
  );
}
