import ApiService from "./api.service";
import { IAutosuggestResponse } from "../interfaces/autosuggest-response.interface";

interface IAutosuggestQuery {
  query: string;
}

class AutosuggestService extends ApiService {
  public getAutosuggestions(query: IAutosuggestQuery): Promise<IAutosuggestResponse> {
    return this.post(`/autosuggest`, query);
  }
}

export const autosuggestService = new AutosuggestService();
