import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ICorporateNote } from "../state/corporate-notes.model";
import { CorporateNotesContext } from "../state/corporate-notes.context";

interface ICorporateNotesHook {
  corporateNotes: ICorporateNote[];
}

export function useCorporateNotes(): ICorporateNotesHook {
  const corporateNotesService = useContext(CorporateNotesContext);

  const [corporateNotes] = useObservable(corporateNotesService.corporateNotes$, []);

  return {
    corporateNotes,
  };
}
