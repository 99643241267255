import React, { useContext, useEffect, useMemo, HTMLProps } from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as DateIcon } from "../../../../../../assets/DateIcon.svg";
import { ReactComponent as PhoneIcon } from "../../../../../../assets/PhoneIcon.svg";
import { ReactComponent as WebIcon } from "../../../../../../assets/WebIcon.svg";
import { ReactComponent as MailIcon } from "../../../../../../assets/MailIcon.svg";
import { ReactComponent as LinkedIn } from "../../../../../../assets/company-dossier/web-social/LinkedIn.svg";
import { ReactComponent as LinkedInDark } from "../../../../../../assets/company-dossier/web-social/LinkedInDark.svg";
import { ReactComponent as Xing } from "../../../../../../assets/company-dossier/web-social/Xing.svg";
import { ReactComponent as XingDark } from "../../../../../../assets/company-dossier/web-social/XingDark.svg";
import { ReactComponent as Facebook } from "../../../../../../assets/company-dossier/web-social/Facebook.svg";
import { ReactComponent as FacebookDark } from "../../../../../../assets/company-dossier/web-social/FacebookDark.svg";
import { ReactComponent as Twitter } from "../../../../../../assets/company-dossier/web-social/Twitter.svg";
import { ReactComponent as TwitterDark } from "../../../../../../assets/company-dossier/web-social/TwitterDark.svg";
import { ReactComponent as Google } from "../../../../../../assets/company-dossier/web-social/Google.svg";
import { ReactComponent as GoogleDark } from "../../../../../../assets/company-dossier/web-social/GoogleDark.svg";
import { ReactComponent as GoogleNews } from "../../../../../../assets/company-dossier/web-social/GoogleNews.svg";
import { ReactComponent as GoogleNewsDark } from "../../../../../../assets/company-dossier/web-social/GoogleNewsDark.svg";
import { ReactComponent as Kununu } from "../../../../../../assets/company-dossier/web-social/Kununu.svg";
import { ReactComponent as KununuDark } from "../../../../../../assets/company-dossier/web-social/KununuDark.svg";
import { ReactComponent as Jobs } from "../../../../../../assets/company-dossier/web-social/Jobs.svg";
import { ReactComponent as JobsDark } from "../../../../../../assets/company-dossier/web-social/JobsDark.svg";
import { ReactComponent as Indeed } from "../../../../../../assets/company-dossier/web-social/Indeed.svg";
import { ReactComponent as IndeedDark } from "../../../../../../assets/company-dossier/web-social/IndeedDark.svg";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../../components/spacing/component";
import { IContactInfo } from "../../../../../../entities/corporates/models/contact-info.model";
import { useActiveCorporate } from "../../../../../../entities/corporates/hooks/active.corporate.hook";
import { CorporateTagsContext } from "../../../../../../entities/corporate-tags/state/corporate-tags.context";
import CorporatesTags from "../../../../CorporatesTags/CorporatesTags";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../hooks/app-colors.hook";
import {
  calculateYearDifference,
  validateURL,
} from "../../../../../../helpers/dossier.helper";
import CorporatesSelectionTags from "../../../../CorporatesTags/CorporatesSelectionTags";
import { useLngString } from "../../../../../../localization/i18n";
import { useSession } from "../../../../../../hooks/session.hook";
import { ICoordinates } from "../../../../../../shared/interfaces/coordinates.interface";
import StreetViewIcon from "../../../../../../assets/company-dossier/web-social/StreetView.svg";
import { tagEntityFromStringTag } from "../../../../../../utils/tags.util";
import { getCurrentTenant } from "../../../../../../utils/tenant.util";

const currentTenant = getCurrentTenant().tenant;

interface IProps {
  companyName: string;
  title: string;
  address1: string;
  address2: string;
  affinity: number;
  contactInfo: IContactInfo[] | null;
  foundingDate: string | null;
  status: string;
  streetViewCoordinates?: ICoordinates & { actualCoordinates?: boolean };
}

type ContactLink = string | null | undefined;

const socialIcons: {
  [key in "light" | "dark"]: {
    [key in
      | "LinkedInIcon"
      | "XingIcon"
      | "FacebookIcon"
      | "TwitterIcon"
      | "GoogleIcon"
      | "GoogleNewsIcon"
      | "KununuIcon"
      | "JobsIcon"
      | "IndeedIcon"]: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string }
    >;
  };
} = {
  light: {
    LinkedInIcon: LinkedIn,
    XingIcon: Xing,
    FacebookIcon: Facebook,
    TwitterIcon: Twitter,
    GoogleIcon: Google,
    GoogleNewsIcon: GoogleNews,
    KununuIcon: Kununu,
    JobsIcon: Jobs,
    IndeedIcon: Indeed,
  },
  dark: {
    LinkedInIcon: LinkedInDark,
    XingIcon: XingDark,
    FacebookIcon: FacebookDark,
    TwitterIcon: TwitterDark,
    GoogleIcon: GoogleDark,
    GoogleNewsIcon: GoogleNewsDark,
    KununuIcon: KununuDark,
    JobsIcon: JobsDark,
    IndeedIcon: IndeedDark,
  },
};

export default function CompanyInfo({
  companyName,
  title,
  address1,
  address2,
  affinity,
  contactInfo,
  foundingDate,
  status,
  streetViewCoordinates,
}: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const currentLanguage = useLngString();
  const { isDarkTheme } = useSession();

  const webLink: ContactLink = useMemo(
    () => validateURL(contactInfo?.find((item) => item.contact_type === "web")?.value?.trim()),
    [contactInfo],
  );
  const emailLink: ContactLink = useMemo(
    () => contactInfo?.find((item) => item.contact_type === "email")?.value?.trim(),
    [contactInfo],
  );
  const phoneLink: ContactLink = useMemo(
    () => contactInfo?.find((item) => item.contact_type === "phone")?.value?.trim(),
    [contactInfo],
  );

  const [companyFoundationDate, companyYear] = useMemo(() => {
    const cmpnyFoundDate = foundingDate && new Date(foundingDate);
    return [cmpnyFoundDate, calculateYearDifference(cmpnyFoundDate)];
  }, [foundingDate]);

  const { activeCorporate } = useActiveCorporate();
  const companyTagsContext = useContext(CorporateTagsContext);

  useEffect(() => {
    activeCorporate && companyTagsContext.getCompanyTags(+activeCorporate.id);
  }, [activeCorporate]); // eslint-disable-line

  const {
    LinkedInIcon,
    XingIcon,
    FacebookIcon,
    TwitterIcon,
    GoogleIcon,
    GoogleNewsIcon,
    KununuIcon,
    JobsIcon,
    IndeedIcon,
  } = isDarkTheme ? socialIcons.dark : socialIcons.light;

  let statusLabel;
  switch (status) {
    case "Filed for Insolvency":
    case "Insolvency":
      statusLabel = "Insolvent";
      break;

    case "Liquidation":
      statusLabel = "Liquidation";
      break;

    case "Deleted":
      statusLabel = "Deleted";
      break;

    case "Merged":
      statusLabel = "Merged";
      break;
  }

  const renderFoundationDate = () => {
    if (!companyFoundationDate) {
      return <></>;
    }

    return (
      <>
        <Spacing pt="12" />
        <Flex alignItemsCenter>
          <div className={styles.dateIconContainer}>
            <DateIcon color={colors.backgroundBlackColor} />
            <RText fontSize="11" className={styles.date}>
              {companyFoundationDate.getDate() < 10
                ? "0" + companyFoundationDate.getDate()
                : companyFoundationDate.getDate().toString()}
            </RText>
          </div>
          <Spacing pr="15" />
          <RText fontSize={isSmallerScreen ? "11" : "15"} bold>
            {format(companyFoundationDate, "dd.MM.yyyy")}
          </RText>
          <Spacing ph="10">
            <RText bold>|</RText>
          </Spacing>
          <RText fontSize={isSmallerScreen ? "11" : "15"} bold>
            {companyYear}
          </RText>
        </Flex>
      </>
    );
  };

  return (
    <div>
      <Flex alignItemsCenter>
        <StarRating
          affinity={affinity}
          spacing={isSmallerScreen ? "6" : "8"}
          iconHeight={isSmallerScreen ? "10" : "22"}
          emptyIconSize={isSmallerScreen ? "8" : "12"}
        />

        {!!statusLabel && (
          <>
            <Spacing pr="50" />
            <Flex alignItemsCenter justifyContentCenter className={styles.companyStatus}>
              <RText fontSize={isSmallerScreen ? "11" : "14"} white>
                {t(`label${statusLabel}`)}
              </RText>
            </Flex>
          </>
        )}
      </Flex>

      <Spacing pt="12" />

      <span>
        <RText bold fontSize={isSmallerScreen ? "13" : "23"} className={styles.companyTitle}>
          {title}
        </RText>

        {activeCorporate && (
          <CorporatesSelectionTags
            tags={activeCorporate.tags.map((tag) =>
              typeof tag === "string" ? tagEntityFromStringTag(tag).tag : tag.tag,
            )}
          />
        )}
      </span>

      {renderFoundationDate()}

      {activeCorporate && (
        <>
          <Spacing pt="25" />
          <CorporatesTags companyId={activeCorporate.id} />
        </>
      )}

      <Spacing pt="25" />

      <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.companyAddress}>
        {address1}
      </RText>
      <Spacing pt="4" />
      <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.companyAddress}>
        <span>
          {address2}
          {streetViewCoordinates && streetViewCoordinates.actualCoordinates && (
            <span className={styles.streetViewContainer}>
              <a
                className={styles.streetViewLink}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.${currentTenant}/maps/@?api=1&map_action=pano&viewpoint=${streetViewCoordinates.lat}%2C${streetViewCoordinates.lng}`}
              >
                <img
                  className={styles.streetViewIcon}
                  src={StreetViewIcon}
                  alt="Google StreetView logo"
                />
              </a>
            </span>
          )}
        </span>
      </RText>

      <Spacing pt="20" />
      <div className={styles.contactsTable}>
        {phoneLink && (
          <div className={styles.contactsRow}>
            <span className={styles.contactsIconCell}>
              <PhoneIcon />
            </span>
            <span className={styles.contactsCell}>
              <a
                href={`tel:${phoneLink}`}
                className={`${styles.companyUrls} ${
                  isSmallerScreen && styles.companyUrls__Responsive
                }`}
              >
                {phoneLink}
              </a>
            </span>
          </div>
        )}

        {webLink && (
          <div className={styles.contactsRow}>
            <span className={styles.contactsIconCell}>
              <WebIcon />
            </span>
            <span className={styles.contactsCell}>
              <a
                href={webLink}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.companyUrls} ${
                  isSmallerScreen && styles.companyUrls__Responsive
                }`}
              >
                {webLink}
              </a>
            </span>
          </div>
        )}

        {emailLink && (
          <div className={styles.contactsRow}>
            <span className={styles.contactsIconCell}>
              <MailIcon />
            </span>
            <span className={styles.contactsCell}>
              <a
                href={`mailto:${emailLink}`}
                className={`${styles.companyUrls} ${
                  isSmallerScreen && styles.companyUrls__Responsive
                }`}
              >
                {emailLink}
              </a>
            </span>
          </div>
        )}
      </div>

      <div className={styles.socialContainer}>
        <SocialLink
          title="LinkedIn"
          url={createGoogleLinkForCompanyAndSite(companyName, "linkedin.com")}
          icon={<LinkedInIcon className={styles.socialIcon} />}
        />
        <SocialLink
          title="Xing"
          url={createGoogleLinkForCompanyAndSite(companyName, "xing.com")}
          icon={<XingIcon className={styles.socialIcon} />}
        />
        <SocialLink
          title="facebook"
          url={createGoogleLinkForCompanyAndSite(companyName, "facebook.com")}
          icon={<FacebookIcon className={styles.socialIcon} />}
        />
        <SocialLink
          title="Twitter"
          url={createGoogleLinkForCompanyAndSite(companyName, "twitter.com")}
          icon={<TwitterIcon className={styles.socialIcon} />}
          style={{ marginRight: 30 }}
        />
        <SocialLink
          title="Google"
          url={`https://www.google.${currentTenant}/search?q=${encodeURIComponent(
            companyName,
          )}`}
          icon={<GoogleIcon className={styles.socialIcon} />}
        />
        <SocialLink
          title="Google News"
          url={`https://news.google.${currentTenant}/search?q=${encodeURIComponent(
            companyName,
          )}`}
          icon={<GoogleNewsIcon className={styles.socialIcon} />}
        />
        <SocialLink
          title="kununu"
          url={`https://www.kununu.com/${currentTenant}/search?q=${encodeURIComponent(
            companyName,
          )}&country=${currentTenant}`}
          icon={<KununuIcon className={styles.socialIcon} />}
        />
        {currentTenant === "ch" ? (
          <SocialLink
            title="jobs"
            url={`https://www.jobs.ch/${
              currentLanguage === "de" ? "de/stellenangebote" : "en/vacancies"
            }/?term=${encodeURIComponent(companyName)}`}
            icon={<JobsIcon className={styles.socialIcon} />}
          />
        ) : (
          <SocialLink
            title="indeed"
            url={`https://de.indeed.com/jobs?q=${encodeURIComponent(companyName)}`}
            icon={<IndeedIcon className={styles.socialIcon} />}
          />
        )}
      </div>

      <Spacing pb="20" />
    </div>
  );
}

const SocialLink = ({
  url,
  icon,
  title,
  ...rest
}: {
  url: string;
  icon: JSX.Element;
  title?: string;
} & HTMLProps<HTMLAnchorElement>) => (
  <a
    {...rest}
    title={title}
    className={styles.socialLink}
    target="_blank"
    rel="noopener noreferrer"
    href={url}
  >
    {icon}
  </a>
);

const createGoogleLinkForCompanyAndSite = (companyName: string, siteName: string) =>
  `https://www.google.${currentTenant}/search?q=${encodeURIComponent(
    companyName,
  )}%20site%3A${siteName}`;
