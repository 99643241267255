import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ILastVisitedDossier } from "../state/last-dossier.model";
import { LastDossierContext } from "../state/last-dossier.context";

interface ILastDossierHook {
  lastDossiers: ILastVisitedDossier[];
}

export function useLastDossiers(): ILastDossierHook {
  const lastDossierService = useContext(LastDossierContext);

  const [lastDossiers] = useObservable(lastDossierService.lastDossiers$, []);

  return {
    lastDossiers,
  };
}
