import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import styles from "./styles.module.css";
import { ReactComponent as NavIconCorporates } from "../../../../assets/NavIconCorporates.svg";
import { ReactComponent as PDFFile } from "../../../../assets/PDFFile.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import Divider from "../../../../components/dividers/Divider/Divider";
import RText from "../../../../components/fonts/RText/RText";
import { buildCompanyCityAndZipCodeString } from "../../../../components/lists/SearchResultsList/ExecutivesSearchResultsListItem/ExecutivesSearchResultsListItem";
import { Spacing } from "../../../../components/spacing/component";
import { useActiveCorporate } from "../../../../entities/corporates/hooks/active.corporate.hook";
import { abbreviateNumber } from "../../../../helpers/number.helper";
import {
  useCorporateLookALikes,
  useCorporateNews,
  useCorporateProductTrends,
  useCorporateRelationshipIndicator,
} from "../../../../hooks/corporates.hook";
import CompanyInfo from "./components/company-info/CompanyInfo";
import DossierMap from "./components/map/DossierMap";
import Notes from "./components/notes/Notes";
import Score from "./components/score/Score";
import { getRelationshipIndicatorCount } from "./components/side-navigation-item-content/relationship-indicator/RelationshipIndicator";
import { IRouteParams } from "../../../../shared/interfaces/route-params.interface";
import { getFilteredManagementData } from "./components/side-navigation-item-content/management/Management";
import { CorporateDossierContext } from "../../../../entities/corporate-dossier/corporate-dossier.context";
import { getInvestmentCount } from "./components/side-navigation-item-content/investment/Investment";
import { FILTER_CATEGORY } from "../../../../api/enums/filter-category.enum";
import { LastDossierContext } from "../../../../entities/last-dossier/state/last-dossier.context";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import CorporateDossierContentContainer from "./CorporateDossierContentContainer/CorporateDossierContentContainer";
import useAppColors from "../../../../hooks/app-colors.hook";
import { calculateMapCenterFromCompanyCoordinates } from "../../../../helpers/map.helper";
import PDFDocument from "./PDFDocument";
import { KeyboardArrowDown } from "@mui/icons-material";
import Toolbar from "../../Toolbar/Toolbar";
import { IMenuItem } from "./components/side-navigation/SideNavigation";
import {
  SideNavHeadcountIcon,
  SideNavIndustryIcon,
  SideNavInvestmentsIcon,
  SideNavLookIcon,
  SideNavManagementIcon,
  SideNavNewsIcon,
  SideNavProductTrendsIcon,
  SideNavRelationshipIcon,
  SideNavShareholdersIcon,
  SideNavTurnoverIcon,
} from "../../../../assets/company-dossier/sidenav/icons";
import { useSession } from "../../../../hooks/session.hook";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface MenuItem {
  title: string;
  Icon: any;
  anchor: string;
  counter?: string | number | null;
}

const CorporateDossier = () => {
  const { t, i18n } = useTranslation();
  const {
    currentUser: { export_company_permission },
  } = useSession();
  const exportPermission = export_company_permission && export_company_permission === "yes";

  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const { id } = useParams<IRouteParams>();
  const corporateDossierContext = useContext(CorporateDossierContext);
  const lastDossierContext = useContext(LastDossierContext);

  const { activeCorporate } = useActiveCorporate();
  const [visibility, setVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAnchor, setCurrentAnchor] = useState<string | null>(null);
  const handleDropdownOpen = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const selector = document.querySelector(`div[name=${currentAnchor}]`);
    if (currentAnchor && selector) {
      selector.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [currentAnchor]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo(0, 0);
      corporateDossierContext.fetchCorporateData(id);
      setVisibility(false);
      setTimeout(() => setVisibility(true));
    }

    return () => {
      isMounted = false;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const industrySectorCodes = [...(activeCorporate?.industry_sector_codes || [])].sort();

    if (!activeCorporate?.industry_sector_codes.length && !industrySectorCodes.length) return;
  }, [activeCorporate]);

  const { corporateLookALikes, totalCorporateLookALikes } = useCorporateLookALikes();
  const { corporateNews } = useCorporateNews();

  const { totalCorporateProductTrends } = useCorporateProductTrends();

  const { corporateRelationshipIndicator } = useCorporateRelationshipIndicator();
  const relationshipIndicatorCount = getRelationshipIndicatorCount(
    corporateRelationshipIndicator,
  );

  const managementData =
    activeCorporate?.management && getFilteredManagementData(activeCorporate.management, t);

  const shareholdersCount = getInvestmentCount(
    activeCorporate?.shareholders_companies_in,
    activeCorporate?.shareholders_people,
  );

  const investmentCount = getInvestmentCount(activeCorporate?.shareholders_companies_out);

  if (!activeCorporate) return null;

  lastDossierContext.saveLastDossier({
    dossier_id: +activeCorporate.id,
    dossier_title: activeCorporate.company_name,
    type: FILTER_CATEGORY.CORPORATES,
  });

  const companyCoordinates =
    activeCorporate && calculateMapCenterFromCompanyCoordinates(activeCorporate);

  const menuItems: MenuItem[] = [
    {
      title: "productTrends",
      // value: productTrends,
      Icon: SideNavProductTrendsIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.PRODUCT_TRENDS,
      anchor: "productTrends",
      counter:
        totalCorporateProductTrends && totalCorporateProductTrends > 0
          ? abbreviateNumber(totalCorporateProductTrends)
          : null,
    },
    {
      title: "news",
      // value: news,
      Icon: SideNavNewsIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.NEWS,
      anchor: "news",
      counter:
        corporateNews && corporateNews.length > 0
          ? abbreviateNumber(corporateNews.length)
          : null,
    },
    {
      title: "factsheet",
      // value:
      //     mainIndustry &&
      //     formatIndustrySectorForTranslation(mainIndustry, t),
      Icon: SideNavIndustryIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.MAIN_INDUSTRY,
      anchor: "factsheet",
    },
    {
      title: "headcount",
      // value: headcount,
      Icon: SideNavHeadcountIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.HEADCOUNT,
      anchor: "headcount",
      counter: activeCorporate.employee_count
        ? abbreviateNumber(activeCorporate.employee_count)
        : null,
    },
    {
      title: "turnover",
      // value: turnover && `${turnover} ${isSwiss ? " CHF" : " €"}`,
      Icon: SideNavTurnoverIcon, // isDarkTheme ? SideNavTurnoverDarkIcon :
      // type: SIDE_NAVIGATION_ITEM_TYPE.TURNOVER,
      anchor: "turnover",
      counter: activeCorporate.turnover ? abbreviateNumber(activeCorporate.turnover) : null,
    },
    {
      title: "shareholders",
      // value: shareholders,
      Icon: SideNavShareholdersIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.SHAREHOLDERS,
      anchor: "shareholders",
      counter: shareholdersCount > 0 ? abbreviateNumber(shareholdersCount) : null,
    },
    {
      title: "investment",
      // value: investment,
      Icon: SideNavInvestmentsIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.INVESTMENT,
      anchor: "investment",
      counter: investmentCount > 0 ? abbreviateNumber(investmentCount) : null,
    },
    {
      title: "management",
      // value: management,
      Icon: SideNavManagementIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.MANAGEMENT,
      anchor: "management",
      counter:
        managementData?.totalCount && managementData?.totalCount > 0
          ? abbreviateNumber(managementData.totalCount)
          : null,
    },
    {
      title: "relationshipIndicator",
      // value: relationshipIndicator,
      Icon: SideNavRelationshipIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR,
      anchor: "relationshipIndicator",
      counter:
        relationshipIndicatorCount && +relationshipIndicatorCount > 0
          ? abbreviateNumber(+relationshipIndicatorCount)
          : null,
    },
    {
      title: "lookALikes",
      // value: lookALikes,
      Icon: SideNavLookIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.LOOK_A_LIKES,
      anchor: "lookALikes",
      counter: totalCorporateLookALikes ? abbreviateNumber(totalCorporateLookALikes) : null,
    },
  ];

  const handleDropdownClose = (name: string) => {
    setAnchorEl(null);
    const namesList = menuItems.map((item: Partial<IMenuItem>) => item.anchor);

    if (name && namesList.includes(name)) {
      setCurrentAnchor(name);
    }
  };

  const renderDefaultMainInfo = () => {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <NavIconCorporates
                  color={colors.backgroundBlackColor}
                  className={
                    isSmallerScreen ? styles.corporateIconSmaller : styles.corporateIcon
                  }
                />
              </Grid>
              <Grid item>
                <RText bold fontSize={isSmallerScreen ? "20" : "25"}>
                  {t("corporateDossier")}
                </RText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Notes corporateId={activeCorporate.id} />
              </Grid>
              {exportPermission && (
                <Grid item>
                  {(!activeCorporate || !menuItems.length) && <CircularProgress />}
                  {activeCorporate && menuItems.length ? (
                    <PDFDownloadLink
                      document={
                        <PDFDocument
                          activeCorporate={activeCorporate}
                          menuItems={menuItems}
                          locale={i18n.language}
                        />
                      }
                      fileName={`MOVE, Dossier of ${activeCorporate.company_name}.pdf`}
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PDFFile height={32} style={{ marginRight: 8 }} />
                      <RText fontSize={isSmallerScreen ? "15" : "18"}>
                        {t("downloadDossier")}
                      </RText>
                    </PDFDownloadLink>
                  ) : null}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Spacing pt="25" />
        <Divider black />

        <Spacing pt="25" />

        <Flex wrap>
          <Flex
            column
            className={`
                            ${isSmallerScreen ? styles.leftColumnSmaller : styles.leftColumn}
                            ${styles.columnSection}
                        `}
          >
            {activeCorporate && (
              <CompanyInfo
                companyName={activeCorporate.company_name}
                title={activeCorporate.company_name}
                address1={activeCorporate.street ?? ""}
                address2={
                  buildCompanyCityAndZipCodeString(
                    activeCorporate.city,
                    activeCorporate.zip_code,
                  ) ?? ""
                }
                streetViewCoordinates={companyCoordinates}
                affinity={activeCorporate.affinity_score}
                contactInfo={activeCorporate.contact_info}
                foundingDate={activeCorporate.founding_date}
                status={activeCorporate.status}
              />
            )}
          </Flex>
          <Spacing pr={isSmallerScreen ? "20" : "70"} />
          <Flex
            column
            className={`
                            ${isSmallerScreen ? styles.rightColumnSmaller : styles.rightColumn}
                            ${styles.columnSection}
                        `}
          >
            {activeCorporate && (
              <>
                <DossierMap
                  height="130px"
                  width="100%"
                  corporate={activeCorporate}
                  corporateLookALikes={
                    corporateLookALikes ? [...corporateLookALikes].slice(0, 5) : null
                  }
                />
                <Score
                  successScore={activeCorporate.success_score}
                  promoterScore={activeCorporate.promoter_score}
                  eAndEScore={activeCorporate.ee_score}
                />
              </>
            )}
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          sx={{ position: "sticky", top: 20, zIndex: 1000 }}
        >
          <Grid item sx={{ display: { xs: "none", md: "block" } }}>
            <Toolbar />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              endIcon={<KeyboardArrowDown />}
              sx={{ ".MuiButton-endIcon": { marginLeft: 9 } }}
              onClick={handleDropdownClick}
            >
              Insights
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={handleDropdownOpen}
              onClose={handleDropdownClose}
            >
              {menuItems.map((item, index) => {
                const { title, Icon, anchor, counter } = item;
                const ItemIcon = Icon;

                if (!counter) return null;

                return (
                  <MenuItem
                    onClick={() => handleDropdownClose(anchor)}
                    key={title}
                    sx={{
                      gap: 2,
                      borderBottom:
                        index !== menuItems.length - 1 && "1px solid var(--color_gray_light)",
                      py: 1.25,
                    }}
                  >
                    <ListItemIcon>
                      <ItemIcon style={{ width: 36, height: 36 }} />
                    </ListItemIcon>
                    <ListItemText>{t(title)}</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      {counter}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
        </Grid>

        <Divider />

        <Spacing pt="15" />
        {renderDefaultMainInfo()}
        <Spacing pt="25" />

        {!visibility ? null : (
          <CorporateDossierContentContainer
            activeCorporate={activeCorporate}
            productTrends={
              totalCorporateProductTrends && totalCorporateProductTrends > 0
                ? abbreviateNumber(totalCorporateProductTrends)
                : null
            }
            news={
              corporateNews && corporateNews.length > 0
                ? abbreviateNumber(corporateNews.length)
                : null
            }
            mainIndustry={activeCorporate.industry_sector ?? null}
            headcount={
              activeCorporate.employee_count
                ? abbreviateNumber(activeCorporate.employee_count)
                : null
            }
            turnover={
              activeCorporate.turnover ? abbreviateNumber(activeCorporate.turnover) : null
            }
            shareholders={shareholdersCount > 0 ? abbreviateNumber(shareholdersCount) : null}
            investment={investmentCount > 0 ? abbreviateNumber(investmentCount) : null}
            management={
              managementData?.totalCount && managementData?.totalCount > 0
                ? abbreviateNumber(managementData.totalCount)
                : null
            }
            relationshipIndicator={
              relationshipIndicatorCount && +relationshipIndicatorCount > 0
                ? abbreviateNumber(+relationshipIndicatorCount)
                : null
            }
            lookALikes={
              totalCorporateLookALikes ? abbreviateNumber(totalCorporateLookALikes) : null
            }
          />
        )}
      </Container>
    </>
  );
};

export default CorporateDossier;
