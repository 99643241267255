import React, { useContext } from "react";
import ColoredContainer from "../../../../components/containers/ColoredContainer/ColoredContainer";
import { Flex } from "../../../../components/containers/Flex/Flex";
import StarRating from "../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../components/spacing/component";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import { classNames } from "../../../../helpers/string.helper";
import { FILTER_TYPE } from "../../../../shared/enums/filter-type.enum";
import styles from "./styles.module.css";
import RText from "../../../../components/fonts/RText/RText";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";
import { useSession } from "../../../../hooks/session.hook";
interface IProps {
  filterLabel: string | [string, string];
  filterCount?: number;
  filterType: string;
  selected: boolean;
  handleItemClick: () => void;
}

const stringifyDocCount = (filterCount: number): string => {
  if (filterCount === undefined || filterCount === null) return "N/A";
  return formatNumberWithDotSeparator(filterCount);
};

const FilterValue = ({
  filterLabel,
  filterCount,
  filterType,
  selected,
  handleItemClick,
}: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme } = useSession();

  let filterLabelElement;

  switch (filterType) {
    case FILTER_TYPE.AFFINITY:
      filterLabelElement = (
        <StarRating
          iconHeight={isSmallerScreen ? "18" : "22"}
          affinity={+filterLabel}
          emptyIconSize="12"
          {...{
            [AUTOMATION_TEST_KEY]: `filter-affinity-${filterLabel}`,
          }}
        />
      );
      break;
    case FILTER_TYPE.BASIC:
    case FILTER_TYPE.RANGE:
    case FILTER_TYPE.AUTOSUGGEST_RANGE:
    case FILTER_TYPE.SINGLE_RANGE:
      let filterLabelContent;
      if (Array.isArray(filterLabel)) {
        filterLabelContent = (
          <span style={{ display: "inline" }}>
            <span style={{ display: "inline-block" }}>{filterLabel[0]}&nbsp;-&nbsp;</span>
            <span style={{ display: "inline-block" }}>{filterLabel[1]}</span>
          </span>
        );
      } else {
        filterLabelContent = filterLabel;
      }
      filterLabelElement = (
        <RText
          fontSize={isSmallerScreen ? "13" : "16"}
          noWrap
          ellipsis
          bold={selected}
          elementStyle={{ whiteSpace: "normal" }}
        >
          {filterLabelContent}
        </RText>
      );
      break;
    //ignore other/unknown filter types
    default:
      break;
  }

  return (
    <Flex
      alignItemsCenter
      onClick={handleItemClick}
      className={classNames(styles, { selected })}
    >
      {/*https://codepen.io/chriscoyier/pen/zqedEr*/}
      <div className={styles.ellipsisContainer}>{filterLabelElement}</div>

      {filterCount >= 0 && (
        <>
          <Spacing pl="20" />
          <ColoredContainer
            primaryGreen={!isDarkTheme}
            size="24"
            componentStyle={
              filterCount === undefined || filterCount === null
                ? {
                    visibility: "hidden",
                  }
                : undefined
            }
          >
            <RText immutableBlack fontSize={isSmallerScreen ? "13" : "16"}>
              {stringifyDocCount(filterCount)}
            </RText>
          </ColoredContainer>
        </>
      )}
    </Flex>
  );
};

export default FilterValue;
