import React, { Component } from "react";
import { ILeftArrowHandler, IRightArrowHandler } from "../../../hooks/hub-footer.hook";
import styles from "./styles.module.css";

enum Direction {
  RTL = 1,
  LTR = -1,
}

interface IBounceContentProps {
  content: any;
  velocity?: number;
  onAnimationOver: () => void;
  repeatAnimation: boolean;
  onAnimationRepeat: () => { start: number; end: number };
  leftArrowHandler: ILeftArrowHandler;
  rightArrowHandler: IRightArrowHandler;
}

interface IBounceContentState {
  tickRequested: boolean;
  lastTimestamp: number | null;
  xAxisPosition: number;
}

class BounceContent extends Component<IBounceContentProps, IBounceContentState> {
  _isMounted = false;
  private outerDiv: any;
  private innerDiv: any;
  private animationFrame: any;
  private timeout: any;

  constructor(props: IBounceContentProps) {
    super(props);

    this.state = {
      tickRequested: false,
      lastTimestamp: null,
      xAxisPosition: 0,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.startContentAnimation();

    document.addEventListener("visibilitychange", this.onVisibilityChange);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.stopAnimation();
    clearTimeout(this.timeout);

    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  };

  setOuterRef = (ref: any) => (this.outerDiv = ref);
  setInnerRef = (ref: any) => (this.innerDiv = ref);

  /**
   * Detects if current active browser tab is out of focus, in this case our application.
   *
   * If we switch to another tab, we will pause the animation to avoid unwanted UI behavior.
   * On returning back, we run animation again.
   */
  onVisibilityChange = (event: Event) => {
    if (document.visibilityState === "hidden") {
      this.stopAnimation();
      this.setState({ lastTimestamp: null });
    } else {
      this.runAnimation();
    }
  };

  startContentAnimation = () => {
    if (!this.outerDiv || !this.innerDiv || this.state.tickRequested) {
      return;
    }

    this.delayStartContentAnimation();
  };

  delayStartContentAnimation = (delay: number = 500) => {
    if (!this._isMounted) {
      return;
    }

    this.timeout = setTimeout(() => {
      if (!this.animationFrame) {
        this.runAnimation();
      }

      this.setState({ tickRequested: true });
    }, delay);
  };

  onLeftArrowClick = () => {
    const { setMoveLeft, averageFooterItemWidth } = this.props.leftArrowHandler;

    setMoveLeft(false);

    if (!averageFooterItemWidth) return;

    this.resetAnimation(this.state.xAxisPosition - averageFooterItemWidth);
    this.delayStartContentAnimation(300);
  };

  onRightArrowClick = () => {
    const { setMoveRight, averageFooterItemWidth } = this.props.rightArrowHandler;

    setMoveRight(false);

    if (!averageFooterItemWidth) return;

    this.resetAnimation(this.state.xAxisPosition + averageFooterItemWidth);
    this.delayStartContentAnimation(300);
  };

  tick = (timestamp: any) => {
    if (this.props.leftArrowHandler.moveLeft) {
      this.onLeftArrowClick();

      return;
    }

    if (this.props.rightArrowHandler.moveRight) {
      this.onRightArrowClick();

      return;
    }

    if (this.props.repeatAnimation) {
      const contentInitialXAxisPosition = this.props.onAnimationRepeat();

      this.resetAnimation(contentInitialXAxisPosition.start);
      this.delayStartContentAnimation();

      return;
    }

    if (!this.outerDiv || !this.innerDiv) {
      this.setState({ tickRequested: false });
      return;
    }

    if (this.state.lastTimestamp !== null) {
      if (this.props.rightArrowHandler.fastBackward) {
        this.updateAnimation(timestamp - this.state.lastTimestamp, Direction.LTR);
      } else {
        this.updateAnimation(timestamp - this.state.lastTimestamp);
      }
    }

    this.setState({ lastTimestamp: timestamp });
    this.runAnimation();
    this.setState({ lastTimestamp: timestamp });
  };

  updateAnimation = (deltaT: any, direction: Direction = Direction.RTL) => {
    let { xAxisPosition } = this.state;

    xAxisPosition -= direction * deltaT * this.getVelocity();

    if (xAxisPosition + this.innerDiv.clientWidth < 0) {
      xAxisPosition += this.innerDiv.clientWidth + this.outerDiv.clientWidth;

      this.onAnimationOver();
    } else if (xAxisPosition > this.outerDiv.clientWidth) {
      xAxisPosition = 0;

      this.onAnimationOver();
    }

    this.setState({ xAxisPosition });

    this.calculateTransform();
  };

  resetState = (initialXAxisPosition = 0) => {
    this.setState({
      tickRequested: false,
      lastTimestamp: null,
      xAxisPosition: initialXAxisPosition,
    });
  };

  onAnimationOver = () => {
    this.resetState();
    this.props.onAnimationOver();
  };

  getVelocity = () => this.props.velocity ?? 0.06;

  calculateTransform = () => {
    if (!this.innerDiv) {
      return;
    }

    this.innerDiv.style.transform = `translateX(${this.state.xAxisPosition}px)`;
  };

  runAnimation = () => (this.animationFrame = window.requestAnimationFrame(this.tick));
  stopAnimation = () => window.cancelAnimationFrame(this.animationFrame);
  resetAnimation = (initialXAxisPosition = 0) => {
    this.stopAnimation();
    this.resetState(initialXAxisPosition);
    this.calculateTransform();

    this.animationFrame = undefined;
    this.timeout = undefined;
  };

  // Pause animation
  onMouseEnter = () => {
    this.stopAnimation();
    this.setState({ lastTimestamp: null });
  };
  // Continue animation
  onMouseLeave = () => this.runAnimation();

  render() {
    return (
      <div
        ref={this.setOuterRef}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={styles.bounceContentParent}
      >
        <div ref={this.setInnerRef} className={styles.bounceContent}>
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default BounceContent;
