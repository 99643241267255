import React, { Fragment, useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Spacing } from "../../../../components/spacing/component";
import RText from "../../../../components/fonts/RText/RText";
import { ILastSearch } from "../../../../entities/last-search/state/last-search.model";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { useLastSearchTerms } from "../../../../entities/last-search/hooks/last-search-terms.hook";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"; // useRouteMatch
import { usePerformCentralSearch } from "../../../../hooks/perform-central-search.hook";
import { CentralSearchContext } from "../../HomePage";
import { LastSearchContext } from "../../../../entities/last-search/state/last-search.context";
import Divider from "../../../../components/dividers/Divider/Divider";
// import { ReactComponent as ChevronDownBlack } from "../../../../assets/ChevronDownBlack.svg";
import { useLastDossiers } from "../../../../entities/last-dossier/hooks/last-dossier.hook";
import { ILastVisitedDossier } from "../../../../entities/last-dossier/state/last-dossier.model";
import { FILTER_CATEGORY } from "../../../../api/enums/filter-category.enum";
import Popup from "reactjs-popup";
import { useIsHub, useIsSearchPage } from "../../../../utils/route.util";
import { useSession } from "../../../../hooks/session.hook";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";
import { Button } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

const automationMarker = { [AUTOMATION_TEST_KEY]: "toolbar-goto-btn" };
const automationMarkerCorporates = {
  [AUTOMATION_TEST_KEY]: "toolbar-corporates-btn",
};
const automationMarkerExecutives = {
  [AUTOMATION_TEST_KEY]: "toolbar-executives-btn",
};

const GoTo = () => {
  const { t } = useTranslation();
  const [performCentralSearch] = usePerformCentralSearch();
  const { setSearchTerm } = useContext(CentralSearchContext);
  const lastSearchContext = useContext(LastSearchContext);
  const isSwiss = window.location.href.includes("/ch/") ? "/ch" : "";
  const history = useHistory();
  const isHub = useIsHub();
  const isSearchPage = useIsSearchPage();
  const { isB2CUser } = useSession();

  const { lastSearchTerms } = useLastSearchTerms();
  const { lastDossiers } = useLastDossiers();

  const [goToOpen, setGoToOpen] = useState(false);

  const clearSearch = () => {
    setSearchTerm("");
    performCentralSearch("");
  };

  const openGoTo = () => {
    setGoToOpen(true);
  };

  const closeGoTo = () => {
    setGoToOpen(false);
  };

  useEffect(() => {
    lastSearchContext.fetchUserLastSearchTerms();
  }, []); // eslint-disable-line

  // const goToHub = () => {
  //     closeGoTo();
  //     history.push("/");
  // };

  const goToCorporates = () => {
    closeGoTo();
    clearSearch();
    history.push(`/home/search/corporates`); // ${match.path}
  };

  const goToExecutives = () => {
    closeGoTo();
    clearSearch();
    history.push(`/home/search/executives`); // ${match.path}
  };

  const onLastSearchTermClick = (searchTerm: string) => () => {
    closeGoTo();

    setSearchTerm(searchTerm);
    performCentralSearch(searchTerm);

    if (isSearchPage) {
      return;
    }

    history.push(`${isSwiss}/home/search`);
  };

  const onLastDossierTermClick = (lastDossier: ILastVisitedDossier) => () => {
    closeGoTo();

    if (lastDossier.type === FILTER_CATEGORY.CORPORATES) {
      history.push(`/home/search/corporate-dossier/${lastDossier.dossier_id}`, {
        fromHub: isHub,
      });
    }

    if (lastDossier.type === FILTER_CATEGORY.EXECUTIVES) {
      history.push(`/home/search/executive-dossier/${lastDossier.dossier_id}`, {
        fromHub: isHub,
      });
    }
  };

  return (
    <Popup
      trigger={
        <div {...automationMarker}>
          {/* <Flex pointer style={{ alignItems: "center" }}>
                        <RText fontSize="15">{t("goTo")}</RText>
                        <Spacing pr="12" />
                        <ChevronDownBlack
                            color={isB2CUser ? "#d5c599" : "#5379bf"}
                        />
                    </Flex> */}
          <Button variant="outlined" endIcon={<KeyboardArrowDown />}>
            Go To
          </Button>
        </div>
      }
      open={goToOpen}
      position={"bottom left"}
      arrow={false}
      offsetY={-5}
      contentStyle={{
        zIndex: 1111,
        width: "auto",
        border: "solid 1px var(--color_gray_dark)",
        // borderTop: "none",
        borderRadius: "0 0 10px 10px",
        padding: "0 23px",
      }}
      onOpen={openGoTo}
      onClose={closeGoTo}
      closeOnEscape={false}
    >
      <div>
        <Spacing pb="12" />

        <Flex className={styles.goToItem}>
          <RText immutableBlack fontSize="15" bold>
            {t("search")}
          </RText>
        </Flex>

        {isB2CUser ? (
          <div className={styles.goToItemsContainer}>
            <Flex
              {...automationMarkerExecutives}
              pointer
              onClick={goToExecutives}
              className={styles.goToSubItem}
            >
              <RText immutableBlack fontSize="15">
                {t("executives")}
              </RText>
            </Flex>
            <Flex
              {...automationMarkerCorporates}
              pointer
              onClick={goToCorporates}
              className={styles.goToSubItem}
            >
              <RText immutableBlack fontSize="15">
                {t("corporates")}
              </RText>
            </Flex>
          </div>
        ) : (
          <div className={styles.goToItemsContainer}>
            <Flex
              {...automationMarkerCorporates}
              pointer
              onClick={goToCorporates}
              className={styles.goToSubItem}
            >
              <RText immutableBlack fontSize="15">
                {t("corporates")}
              </RText>
            </Flex>
            <Flex
              {...automationMarkerExecutives}
              pointer
              onClick={goToExecutives}
              className={styles.goToSubItem}
            >
              <RText immutableBlack fontSize="15">
                {t("executives")}
              </RText>
            </Flex>
          </div>
        )}

        <Flex className={styles.goToItem}>
          <RText immutableBlack fontSize="15" bold>
            {t("lastSearches")}
          </RText>
        </Flex>

        <div className={styles.goToItemsContainer}>
          {lastSearchTerms.map((searchTerm: ILastSearch, index: number) => {
            if (!searchTerm) return null;
            return (
              <Fragment key={searchTerm.id}>
                <Flex
                  pointer
                  onClick={onLastSearchTermClick(searchTerm.term)}
                  className={styles.goToSubItem}
                >
                  <RText immutableBlack fontSize="15">
                    {searchTerm.term}
                  </RText>
                </Flex>
                {index < lastSearchTerms.length - 1 && <Spacing pt="5" />}
              </Fragment>
            );
          })}
        </div>

        <Divider />

        <Spacing pb="5" />

        <Flex className={styles.goToItem} pointer>
          <a href={`${isSwiss}/home`} style={{ textDecoration: "none" }}>
            <RText immutableBlack fontSize="15" bold>
              {t("hubGoTo")}
            </RText>
          </a>
        </Flex>

        <Spacing pb="5" />

        <Divider />

        <Spacing pb="5" />

        <Flex className={styles.goToItem}>
          <RText immutableBlack fontSize="15" bold>
            {t("dossierGoTo")}
          </RText>
        </Flex>

        {lastDossiers && (
          <div className={styles.goToItemsContainer}>
            {lastDossiers.map((lastVisitedDossier: ILastVisitedDossier, index: number) => {
              if (!lastVisitedDossier) return null;
              return (
                <Fragment key={index}>
                  <Flex
                    pointer
                    onClick={onLastDossierTermClick(lastVisitedDossier)}
                    className={styles.goToSubItem}
                  >
                    <RText immutableBlack fontSize="15">
                      {lastVisitedDossier.dossier_title}
                    </RText>
                  </Flex>
                  {index < lastSearchTerms.length - 1 && <Spacing pt="5" />}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </Popup>
  );
};

export default GoTo;
