import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import Divider from "../../../../components/dividers/Divider/Divider";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { Spacing } from "../../../../components/spacing/component";
import RText from "../../../../components/fonts/RText/RText";
import { ReactComponent as UploadIcon } from "../../../../assets/UploadIcon.svg";
import { ReactComponent as CsvDownloadIcon } from "../../../../assets/CsvDownloadIcon.svg";
import { ReactComponent as XlsDownloadIcon } from "../../../../assets/XlsDownloadIcon.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/Checkmark.svg";
import Dropzone from "../../../../components/dropzone/Dropzone";
import { uploadApiServiceService } from "../../../../api/services/upload-api.service";
import SingleTag from "../../../../components/tags/SingleTag";
import { ReactComponent as CloseIconBlue } from "../../../../assets/CloseIconBlue.svg";
import useAppColors from "../../../../hooks/app-colors.hook";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";
import { Alert, Box, Typography } from "@mui/material";

const automationMarker = { [AUTOMATION_TEST_KEY]: "hdr-upload-btn" };

const UploadSidePanel = () => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const [showUploadingPanel, setShowUploadingPanel] = useState(false);
  const [tag, setTag] = useState(null);
  const [showTag, setShowTag] = useState(true);
  const [errors, setErrors] = useState<string[][]>([]);

  const handleUpload = async (files: File[]) => {
    setShowTag(false);
    if (!tag || !tag.id) {
      return {
        success: false,
        error: "Please select tag to upload file",
      };
    }
    if (files.length === 0) {
      return {
        success: false,
        error: "No File",
      };
    }
    let type = "";
    const fileName = files[0].name.toLowerCase();
    if (fileName.includes("company") || fileName.includes("companies")) {
      type = "companies";
    } else if (fileName.includes("people") || fileName.includes("person")) {
      type = "people";
    } else {
      return {
        success: false,
        error: "Invalid Type",
      };
    }

    const { total_in_file, unmatched_entities = [] } =
      await uploadApiServiceService.uploadContact(files[0], type, tag.id);

    if (unmatched_entities.length) setErrors(unmatched_entities);

    return {
      success: true,
      totalInFile: total_in_file - unmatched_entities.length,
    };
  };

  const onToggleUploadPanel = () => {
    setShowUploadingPanel(!showUploadingPanel);
    setTag(null);
  };

  const handleShowTag = () => {
    setTag(null);
    setShowTag(true);
  };

  return (
    <>
      <div
        {...automationMarker}
        role="button"
        tabIndex={0}
        onClick={onToggleUploadPanel}
        className={styles.actionButton}
      >
        <UploadIcon />
      </div>
      <div
        className={`${styles.sidePanelContainer} ${
          isSmallerScreen && styles.sidePanelContainer__Responsive
        } ${!showUploadingPanel && styles.sidePanelContainer__Hide}`}
      >
        <div>
          <div className={styles.title}>
            <RText bold fontSize="24">
              {t("uploadContactsTitle")}
            </RText>
            <CloseIconBlue
              color={colors.secondaryDarkColor}
              role="button"
              tabIndex={0}
              className={styles.closeIcon}
              onClick={onToggleUploadPanel}
            />
          </div>
          <div className={styles.description}>
            <RText fontSize="12" bold>
              {t("uploadContactsDescription")}
            </RText>
            <RText fontSize="12">{t("uploadContactsDetail")}</RText>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.scrollView}>
          <Spacing pv="16">
            <Flex alignItemsCenter>
              <a
                href="/examples/exampleCompanyMatching.csv"
                target="_blank"
                download
                style={{ color: "var(--color_text_dark)" }}
              >
                <CsvDownloadIcon />
              </a>
              <Spacing pl="8" />
              <a
                href="/examples/exampleCompanyMatching.xlsx"
                target="_blank"
                download
                style={{ color: "var(--color_text_dark)" }}
              >
                <XlsDownloadIcon />
              </a>
              <Spacing pl="16" />
              <RText fontSize="13">{t("company")}</RText>
            </Flex>
          </Spacing>
          <Divider className={styles.divider_opacity} />
          <Spacing pv="16">
            <Flex alignItemsCenter>
              <a
                href="/examples/examplePeopleMatching.csv"
                target="_blank"
                download
                style={{ color: "var(--color_text_dark)" }}
              >
                <CsvDownloadIcon />
              </a>
              <Spacing pl="8" />
              <a
                href="/examples/examplePeopleMatching.xlsx"
                target="_blank"
                download
                style={{ color: "var(--color_text_dark)" }}
              >
                <XlsDownloadIcon />
              </a>
              <Spacing pl="16" />
              <RText fontSize="13">{t("person")}</RText>
            </Flex>
          </Spacing>
          <Divider className={styles.divider_opacity} />
          <Spacing pv="16">
            <Flex
              style={{
                borderBottom: tag
                  ? "1px solid var(--color_text_dark)"
                  : `2px solid ${colors.secondaryDarkColor}`,
                paddingBottom: 10,
                visibility: showTag ? "visible" : "hidden",
                display: showTag ? "block" : "none",
              }}
              justifyContentSpaceBetween
              alignItemsEnd
            >
              <SingleTag tag={tag} onSetTag={setTag} />
              {!tag && <RText fontSize="15">{t("uploadContactsTag")}</RText>}
              <div style={{ width: 40 }}>{tag && <CheckmarkIcon color="#6afc9e" />}</div>
            </Flex>
          </Spacing>
          {errors.length ? (
            <Box mb={4}>
              <Alert severity="error">
                {errors.map((error) => (
                  <Typography>{JSON.stringify(error, null, 2)}</Typography>
                ))}
              </Alert>
            </Box>
          ) : null}
          <Dropzone onUpload={handleUpload} tag={tag} onShowTag={handleShowTag} />
        </div>
      </div>
    </>
  );
};

export default React.memo(UploadSidePanel);
