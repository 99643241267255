import React, { useContext, useEffect, useState } from "react";
import { CorporateTagsContext } from "../../../entities/corporate-tags/state/corporate-tags.context";
import { IEntityTag } from "../../../entities/custom-tags/state/custom-tag.model";
import Tags from "../../../components/tags/Tags";
import { useCorporateTags } from "../../../entities/corporate-tags/hooks/corporate-tags.hook";
import { tagEntityFromStringTag } from "../../../utils/tags.util";
import TAG_TYPE from "../../../api/enums/tags.enum";

interface IProps {
  companyId: string;
  entityTags?: IEntityTag[];
  refetch?: () => void;
}

function fixTags(tags: any) {
  return tags.map((t: any) => {
    if (t?.tag?.name.includes("color_code")) {
      return {
        ...t,
        tag: {
          ...t.tag,
          ...JSON.parse(t.tag.name),
        },
      };
    }

    return t;
  });
}

const CorporatesTags = ({ companyId, entityTags, refetch }: IProps) => {
  const companyTagsContext = useContext(CorporateTagsContext);
  const { all } = useCorporateTags();

  const [tags, setTags] = useState<IEntityTag[]>([]);

  useEffect(() => {
    if (entityTags) {
      setTags(entityTags);
    } else if (all !== null) {
      const corporateTag = all.find((t) => t.id === companyId);
      if (corporateTag) {
        const tags = corporateTag.tags.map((tag) =>
          typeof tag === "string" ? tagEntityFromStringTag(tag) : tag,
        );
        setTags(fixTags(tags));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all, entityTags]);

  const addTagToEntity = async (entityId: string, tagId: number) => {
    try {
      await companyTagsContext.saveCompanyTag(entityId, tagId);
      if (entityTags) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeTagFromEntity = async (entityId: string, tagWrapper: IEntityTag) => {
    try {
      await companyTagsContext.deleteCompanyTag(entityId, tagWrapper);
      if (entityTags) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Tags
      tagType={TAG_TYPE.COMPANIES}
      entityId={companyId}
      entityTags={tags}
      addTagToEntity={addTagToEntity}
      removeTagFromEntity={removeTagFromEntity}
      hideTagLabel={!!refetch}
    />
  );
};

export default CorporatesTags;
