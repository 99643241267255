import React, { useContext, useCallback } from "react";
import styles from "./styles.module.css";
import ColoredContainer from "../../../../components/containers/ColoredContainer/ColoredContainer";
import { Spacing } from "../../../../components/spacing/component";
import { useCorporatesSearchHits } from "../../../../entities/corporates-filters/hooks/corporates-total-count.hook";
import { useExecutivesSearchHits } from "../../../../entities/executives-filters/hooks/executives-total-count.hook";
import { useInterestsSearchHits } from "../../../../entities/interests-filters/hooks/interests-total-count.hook";
import { useOpportunitiesSearchHits } from "../../../../entities/opportunities-filters/hooks/opportunities-total-count.hook";
import { useStartupsSearchHits } from "../../../../entities/startups-filters/hooks/startups-total-count.hook";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import { HOME_NAVIGATION_ITEM_TYPE } from "../HomeNavigationBar";
import RText from "../../../../components/fonts/RText/RText";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useTranslation } from "react-i18next";
import { useSession } from "../../../../hooks/session.hook";

interface IProps {
  itemType: HOME_NAVIGATION_ITEM_TYPE;
}

const NavigationItemCounter = ({ itemType }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme } = useSession();
  const { t } = useTranslation();

  const { totalCount: corporatesTotalCount } = useCorporatesSearchHits();
  const { totalCount: executivesTotalCount } = useExecutivesSearchHits();
  const { totalCount: interestsTotalCount } = useInterestsSearchHits();
  const { totalCount: opportunitiesTotalCount } = useOpportunitiesSearchHits();
  const { totalCount: startupsTotalCount } = useStartupsSearchHits();

  const getTotalCount = useCallback(() => {
    let sum = 0;

    if (
      !corporatesTotalCount &&
      !executivesTotalCount &&
      !interestsTotalCount &&
      !opportunitiesTotalCount &&
      !startupsTotalCount
    ) {
      return sum;
    }

    if (corporatesTotalCount) sum = sum + corporatesTotalCount;
    if (executivesTotalCount) sum = sum + executivesTotalCount;
    if (interestsTotalCount) sum = sum + interestsTotalCount;
    if (opportunitiesTotalCount) sum = sum + opportunitiesTotalCount;
    if (startupsTotalCount) sum = sum + startupsTotalCount;

    return sum;
  }, [
    corporatesTotalCount,
    executivesTotalCount,
    interestsTotalCount,
    opportunitiesTotalCount,
    startupsTotalCount,
  ]);

  const renderCountContainer = useCallback(
    (count: number | null) => {
      if ([null, undefined].includes(count)) return null;
      let rawCount = +count;
      let prefix = "";
      if (rawCount > 999999) {
        rawCount = Math.round(rawCount / 100000) / 10;
        prefix = t("m");
      } else if (rawCount > 99999) {
        rawCount = Math.round(rawCount / 100) / 10;
        prefix = t("k");
      }
      const formattedCount = formatNumberWithDotSeparator(rawCount);

      return (
        <ColoredContainer
          primaryGreen={!isDarkTheme}
          size={isSmallerScreen ? "15" : "30"}
          componentStyle={{ whiteSpace: "nowrap" }}
        >
          <RText immutableBlack fontSize={isSmallerScreen ? "11" : "18"}>
            <span>
              {formattedCount} <span style={{ fontSize: "0.75em" }}>{prefix}</span>
            </span>
          </RText>
        </ColoredContainer>
      );
    },
    [isSmallerScreen, isDarkTheme, t],
  );

  const renderCountForSelectedTab = useCallback(() => {
    switch (itemType) {
      case HOME_NAVIGATION_ITEM_TYPE.ALL:
        return renderCountContainer(getTotalCount());
      case HOME_NAVIGATION_ITEM_TYPE.CORPORATES:
        return renderCountContainer(corporatesTotalCount);
      case HOME_NAVIGATION_ITEM_TYPE.EXECUTIVES:
        return renderCountContainer(executivesTotalCount);
      case HOME_NAVIGATION_ITEM_TYPE.INTERESTS:
        return renderCountContainer(interestsTotalCount);
      case HOME_NAVIGATION_ITEM_TYPE.OPPORTUNITIES:
        return renderCountContainer(opportunitiesTotalCount);
      case HOME_NAVIGATION_ITEM_TYPE.STARTUPS:
        return renderCountContainer(startupsTotalCount);
    }
  }, [
    corporatesTotalCount,
    executivesTotalCount,
    getTotalCount,
    interestsTotalCount,
    itemType,
    opportunitiesTotalCount,
    renderCountContainer,
    startupsTotalCount,
  ]);

  return (
    <div className={styles.component}>
      <Spacing pb={isSmallerScreen ? "0" : "82"} />
      {renderCountForSelectedTab()}
    </div>
  );
};

export default NavigationItemCounter;
