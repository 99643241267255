import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as NavIconOpportunities } from "../../../assets/NavIconOpportunities.svg";
import { useOpportunitiesSearchHits } from "../../../entities/opportunities-filters/hooks/opportunities-total-count.hook";
import FiltersHeader from "../filters/FiltersHeader/FiltersHeader";

const OpportunitiesFiltersHeader = () => {
  const { t } = useTranslation();
  const { totalCount } = useOpportunitiesSearchHits();

  return (
    <FiltersHeader
      title={t("opportunities")}
      Icon={NavIconOpportunities}
      resultsCount={totalCount}
      onMyFiltersClick={null}
      onClearAllClick={() => null}
      filtersOpened={null}
    />
  );
};

export default OpportunitiesFiltersHeader;
