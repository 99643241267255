import React from "react";
import styles from "./styles.module.css";
import { classNames } from "../../../helpers/string.helper";
import RText from "../../fonts/RText/RText";

interface IProps {
  text: string;
  className?: string;
  onClick?: () => void;
}

const RoundedButton = ({ text, className, onClick }: IProps) => {
  return (
    <div className={classNames(styles, { className })} onClick={onClick}>
      <RText fontSize="16" immutableWhite>
        {text}
      </RText>
    </div>
  );
};

export default RoundedButton;
