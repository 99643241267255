// Selects proper star icon size depending on screen width
export const selectAffinityIconSize = (screenWidth: number) => {
  if (screenWidth <= 1366) {
    return {
      height: "16",
      spacing: "3",
    };
  } else if (screenWidth > 1366 && screenWidth <= 1440) {
    return {
      height: "18",
      spacing: "5",
    };
  } else if (screenWidth > 1440 && screenWidth <= 1536) {
    return {
      height: "20",
      spacing: "7",
    };
  }

  return {
    height: "22",
    spacing: "9",
  };
};

export const setFavicon = (isB2CUser: boolean) => {
  try {
    const appleFavicon = document.getElementById("apple-favicon") as HTMLLinkElement;
    const favicon16 = document.getElementById("favicon-16") as HTMLLinkElement;
    const favicon32 = document.getElementById("favicon-32") as HTMLLinkElement;
    if (isB2CUser) {
      appleFavicon.href = "/apple-touch-icon-b2c.png";
      favicon16.href = "/favicon-b2c-16x16.png";
      favicon32.href = "/favicon-b2c-32x32.png";
    } else {
      appleFavicon.href = "/apple-touch-icon.png";
      favicon16.href = "/favicon-16x16.png";
      favicon32.href = "/favicon-32x32.png";
    }
  } catch (e) {
    console.warn("Couldn't set favicon", e);
  }
};
