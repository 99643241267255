import { createContext } from "react";
import { corporatesFacade } from "../corporates/state/corporates.context";
import { CorporateDossierFacade, ICorporateDossierFacade } from "./corporate-dossier.facade";
import { corporateDossierStore } from "./corporate-dossier.store";
import { corporateDossierQuery } from "./corporate-dossier.query";

export const corporateDossierFacade = new CorporateDossierFacade(
  corporateDossierStore,
  corporateDossierQuery,
  corporatesFacade,
);

export const CorporateDossierContext =
  createContext<ICorporateDossierFacade>(corporateDossierFacade);
