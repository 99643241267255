import React, { useContext } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import styles from "./styles.module.css";
import { INavigationBarItem } from "../HomeNavigationBar";
import { Spacing } from "../../../../components/spacing/component";
import RLink from "../../../../components/link/RLink";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import NavigationItemCounter from "../NavigationItemCounter/NavigationItemCounter";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../hooks/app-colors.hook";

interface IProps {
  item: INavigationBarItem;
}

const HomeNavigationItem = ({ item }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const match = useRouteMatch();
  const location = useLocation();

  const isActive = () => {
    return location.pathname.split("/").includes(item.url);
  };

  const NavIcon = item.Icon;

  return (
    <RLink path={`${match.path}/${item.url}`} condition={!isActive()}>
      <Flex column alignItemsCenter className={isActive() ? styles.active : ""}>
        <Spacing pt={isSmallerScreen ? "25" : "40"} />

        <Flex alignItemsCenter>
          <NavIcon
            color={colors.backgroundBlackColor}
            className={`${styles.itemIcon} ${isSmallerScreen && styles.itemIcon__Responsive}`}
          />

          {isSmallerScreen && (
            <>
              <Spacing pl="4" />
              <NavigationItemCounter itemType={item.type} />
            </>
          )}
        </Flex>

        <Spacing pb={isSmallerScreen ? "4" : "12"} />

        <div className={styles.textContainer}>
          <RText
            pureBlack={isActive()}
            disabled={!isActive()}
            fontSize={isSmallerScreen ? "13" : "20"}
          >
            {item.label}
          </RText>
        </div>

        <Spacing pb={isSmallerScreen ? "4" : "24"} />
      </Flex>
    </RLink>
  );
};

export default HomeNavigationItem;
