import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../components/spacing/component";
import { ILastSearch } from "../../../../entities/last-search/state/last-search.model";

interface IProps {
  lastSearchTerms: ILastSearch[];
  onLastSearchTermClick: (lastSearchTerm: string) => () => void;
}

const LastSearch = ({ lastSearchTerms, onLastSearchTermClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.lastSearchContainer}>
      <Spacing pt="15" pb="20" ph="25">
        <RText immutableBlack fontSize="13">
          {t("lastSearches")}
        </RText>
      </Spacing>
      <div className={styles.lastSearchTermsContainer}>
        {lastSearchTerms.map((searchTerm: ILastSearch, index: number) => {
          if (!searchTerm) return null;
          return (
            <Fragment key={searchTerm.id}>
              <Flex
                pointer
                onClick={onLastSearchTermClick(searchTerm.term)}
                className={styles.lastSearchTerm}
              >
                <RText immutableBlack fontSize="15">
                  {searchTerm.term}
                </RText>
              </Flex>
              {index < lastSearchTerms.length - 1 && <Spacing pt="4" />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default LastSearch;
