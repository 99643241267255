import { createContext } from "react";
import { ExecutiveDossierFacade, IExecutiveDossierFacade } from "./executive-dossier.facade";
import { executiveDossierQuery } from "./executive-dossier.query";
import { executiveDossierStore } from "./executive-dossier.store";
import { executivesFacade } from "../../executives/state/executive.context";

export const executiveDossierFacade = new ExecutiveDossierFacade(
  executiveDossierStore,
  executiveDossierQuery,
  executivesFacade,
);

export const ExecutiveDossierContext =
  createContext<IExecutiveDossierFacade>(executiveDossierFacade);
