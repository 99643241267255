import React from "react";
import { Spacing } from "../../spacing/component";
import Text from "../../fonts/Text/Text";
import styles from "./styles.module.css";
import { classNames } from "../../../helpers/string.helper";

interface IProps {
  text: string;
  Icon?: React.ReactNode;
  IconActive?: React.ReactNode;
  borderless?: boolean;
  active?: boolean;
  leftPadding?: boolean;
  className?: string;
  onClick?: () => void;
}

const SquareButton = ({
  text,
  Icon,
  IconActive,
  borderless,
  leftPadding = true,
  active,
  className,
  onClick,
  ...rest
}: IProps) => {
  return (
    <div
      className={classNames(styles, {
        borderless,
        className,
      })}
      onClick={onClick}
      {...rest}
    >
      {leftPadding && <Spacing pl="12" />}
      <Text primaryBlue>{text}</Text>
      <Spacing pl="12" />
      {active ? (
        <div className={styles.iconContainer}>{IconActive}</div>
      ) : (
        <div className={styles.iconContainer}>{Icon}</div>
      )}
      <Spacing pl="8" />
    </div>
  );
};

export default SquareButton;
