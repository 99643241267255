import { differenceInMonths } from "date-fns";
import isURL from "validator/lib/isURL";
import { t } from "../localization/i18n";
import { ICorporate } from "../entities/corporates/models/corporate.model";
import { ICorporateData } from "../entities/corporate-dossier/corporate-dossier.model";
import { IContactInfo } from "../entities/corporates/models/contact-info.model";
import { IPeopleManagement } from "../entities/executives/interfaces/people-management.interface";
import { IShareholder } from "../entities/executives/interfaces/shareholder.interface";
import { formatZipCode } from "./map.helper";

export interface IContactDataType {
  [key: string]: string | null;
}

export function mapCorporateToDossierCorporateData(corporateData: ICorporate): ICorporateData {
  const contactData: IContactDataType = {
    url: null,
    telephone: null,
    fax: null,
    email: null,
  };

  if (corporateData.contact_info) {
    const findContactTypeValue = (contactInfo: IContactInfo[], type: string) => {
      const contactTypeObject = contactInfo.find(
        (item: IContactInfo) => item.contact_type === type,
      );

      return contactTypeObject ? contactTypeObject.value : null;
    };

    contactData.url = findContactTypeValue(corporateData.contact_info, "web");
    contactData.telephone = findContactTypeValue(corporateData.contact_info, "phone");
    contactData.fax = findContactTypeValue(corporateData.contact_info, "fax");
    contactData.email = findContactTypeValue(corporateData.contact_info, "email");
  }

  return {
    vat_id: corporateData.vat_id ?? null,
    register_id: corporateData.registration_num ?? null,
    register_location: corporateData.registration_city ?? null,
    company_name: corporateData.company_name,
    country: corporateData.country ?? null,
    zip: corporateData.zip_code ? formatZipCode(corporateData.zip_code) : null,
    street: corporateData.street ?? null,
    region: corporateData.region_lvl1 ?? null,
    location: corporateData.city ?? null,
    url: contactData.url,
    telephone: contactData.telephone,
    fax: contactData.fax,
    email: contactData.email,
  };
}

export function getExecutiveActiveCompany(
  managementData: IPeopleManagement[],
  main_company_id: number,
): IPeopleManagement;

export function getExecutiveActiveCompany(
  managementData: IPeopleManagement[],
  main_company_id: number,
  shareholderData: IShareholder[],
): IPeopleManagement | IShareholder;

/**
 * Returns company in which person have active status (to_date = null),
 * according function rank.
 */
export function getExecutiveActiveCompany(
  managementData: IPeopleManagement[],
  main_company_id: number,
  shareholderData?: IShareholder[],
): IPeopleManagement | IShareholder {
  if (!managementData) {
    return;
  }

  if (main_company_id) {
    const foundMngmtCompany = managementData.find(
      (company) => company.company_id === main_company_id,
    );
    if (foundMngmtCompany) return foundMngmtCompany;

    if (shareholderData) {
      const foundOwnedCompany = shareholderData.find(
        (company) => company.company_id === main_company_id,
      );
      if (foundOwnedCompany) return foundOwnedCompany;
    }
  }

  return null;
}

/**
 * Calculates how many years are between current and some other date.
 */
export const calculateYearDifference = (date: Date) => {
  if (!date) {
    return null;
  }

  const MONTHS_IN_YEAR = 12;
  const currentDate = new Date();
  const monthsDifference = differenceInMonths(currentDate, date);

  if (monthsDifference < MONTHS_IN_YEAR) {
    return `< 1 ${t("year")}`;
  }

  const yearsDifference = Math.floor(monthsDifference / MONTHS_IN_YEAR);

  return `${yearsDifference} ${yearsDifference > 1 ? t("years") : t("year")}`;
};

export const validateURL = (url: string) => {
  if (!url?.length) {
    return null;
  }

  let validatedURL = "";

  try {
    /**
     * URL will be valid only if contains one of protocol scheme (http, https, ...).
     */
    validatedURL = new URL(url).href;
  } catch (e) {
    /**
     * In case we get "www.example.com" as URL, which is also valid,
     * we need to add default protocol as prefix.
     */
    validatedURL = "https://" + url;
  }

  /**
   * Check if created string (validatedURL) is real URL.
   */
  return isURL(validatedURL) ? validatedURL : null;
};
