import React from "react";
import { FullWidth } from "../../containers/FullWidth/FullWidth";
import styles from "./styles.module.css";
import { classNames } from "../../../helpers/string.helper";

interface IProps {
  primaryGreen?: boolean;
  primaryBlue?: boolean;
  gray?: boolean;
  black?: boolean;
  immutableBlack?: boolean;
  opacity?: boolean;
  className?: string;
}

const Divider = ({
  primaryGreen,
  primaryBlue,
  gray,
  black,
  immutableBlack,
  opacity,
  className,
}: IProps) => {
  return (
    <FullWidth>
      <div
        className={classNames(styles, {
          primaryGreen,
          primaryBlue,
          gray,
          black,
          immutableBlack,
          opacity,
          className,
        })}
      />
    </FullWidth>
  );
};

export default Divider;
