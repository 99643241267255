import { combineQueries } from "@datorama/akita";
import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { map } from "rxjs/operators";
import { extractFiltersIntoRows } from "../../../helpers/filters.helper";
import { IFilterWithUIRow } from "../../../shared/interfaces/filters-row.interface";
import { CorporatesFiltersContext } from "../state/corporates-filters.context";

interface IFilterWithUIRowsHook {
  filterWitUIRows: IFilterWithUIRow[];
  // loadingCorporatesFilters: boolean;
  toggleFilterRowExpanded(rowIndex: number): void;
  getAll: any[];
}

export const useCorporatesFilterWithSearchUIRows = (): IFilterWithUIRowsHook => {
  const corporatesFiltersService = useContext(CorporatesFiltersContext);

  // const [loadingCorporatesFilters] = useObservable(
  //     corporatesFiltersService.loading$,
  //     null
  // );

  const [filterWitUIRows] = useObservable(
    combineQueries([
      corporatesFiltersService.allWithSearchUI$,
      corporatesFiltersService.closedSearchRowIndexes$,
    ]).pipe(
      map(([allWithSearchUI, openedSearchRowIndexes]) =>
        extractFiltersIntoRows(allWithSearchUI, openedSearchRowIndexes),
      ),
    ),
    [],
  );

  const getAll = corporatesFiltersService.getAll();

  return {
    filterWitUIRows,
    // loadingCorporatesFilters,
    toggleFilterRowExpanded: (index: number) =>
      corporatesFiltersService.toggleFilterRowExpanded(index),
    getAll,
  };
};
