import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ILastSearch } from "./last-search.model";

export interface LastSearchState extends EntityState<ILastSearch> {}

@StoreConfig({
  name: "last-search",
})
export class LastSearchStore extends EntityStore<LastSearchState> {}

export const lastSearchStore = new LastSearchStore();
