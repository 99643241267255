import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ILastSearch } from "../state/last-search.model";
import { LastSearchContext } from "../state/last-search.context";

interface LastSearchHook {
  lastSearchTerms: ILastSearch[];
}

export function useLastSearchTerms(): LastSearchHook {
  const lastSearchService = useContext(LastSearchContext);

  const [lastSearchTerms] = useObservable(lastSearchService.lastSearchTerms$, []);

  return {
    lastSearchTerms,
  };
}
