import React from "react";
import { TileLayer } from "react-leaflet";
import { MAP_TRANSPORT_LAYER } from "../../../../../../../constants/urls";

const MapTransportLayer = () => {
  return (
    <TileLayer
      url={MAP_TRANSPORT_LAYER}
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    />
  );
};

export default MapTransportLayer;
