import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ITag } from "./custom-tag.model";

export interface CustomTagsState extends EntityState<ITag> {}

@StoreConfig({
  name: "custom-tags",
})
export class CustomTagsStore extends EntityStore<CustomTagsState> {}

export const customTagsStore = new CustomTagsStore();
