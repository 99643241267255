import L_MARKER_L_STAR from "./L_MARKER_L_STAR.svg";
import L_MARKER_M_STAR from "./L_MARKER_M_STAR.svg";
import L_MARKER_S_STAR from "./L_MARKER_S_STAR.svg";
import M_MARKER_L_STAR from "./M_MARKER_L_STAR.svg";
import M_MARKER_M_STAR from "./M_MARKER_M_STAR.svg";
import M_MARKER_S_STAR from "./M_MARKER_S_STAR.svg";
import S_MARKER_L_STAR from "./S_MARKER_L_STAR.svg";
import S_MARKER_M_STAR from "./S_MARKER_M_STAR.svg";
import S_MARKER_S_STAR from "./S_MARKER_S_STAR.svg";

import PURPLE_L_MARKER_L_STAR from "./PURPLE_L_MARKER_L_STAR.svg";
import PURPLE_L_MARKER_M_STAR from "./PURPLE_L_MARKER_M_STAR.svg";
import PURPLE_L_MARKER_S_STAR from "./PURPLE_L_MARKER_S_STAR.svg";
import PURPLE_M_MARKER_L_STAR from "./PURPLE_M_MARKER_L_STAR.svg";
import PURPLE_M_MARKER_M_STAR from "./PURPLE_M_MARKER_M_STAR.svg";
import PURPLE_M_MARKER_S_STAR from "./PURPLE_M_MARKER_S_STAR.svg";
import PURPLE_S_MARKER_L_STAR from "./PURPLE_S_MARKER_L_STAR.svg";
import PURPLE_S_MARKER_M_STAR from "./PURPLE_S_MARKER_M_STAR.svg";
import PURPLE_S_MARKER_S_STAR from "./PURPLE_S_MARKER_S_STAR.svg";

import STARTING_MARKER from "./STARTING_MARKER.svg";

export const getIconByName = (iconName: string) => {
  switch (iconName) {
    case "L_MARKER_L_STAR":
      return L_MARKER_L_STAR;
    case "L_MARKER_M_STAR":
      return L_MARKER_M_STAR;
    case "L_MARKER_S_STAR":
      return L_MARKER_S_STAR;
    case "M_MARKER_L_STAR":
      return M_MARKER_L_STAR;
    case "M_MARKER_M_STAR":
      return M_MARKER_M_STAR;
    case "M_MARKER_S_STAR":
      return M_MARKER_S_STAR;
    case "S_MARKER_L_STAR":
      return S_MARKER_L_STAR;
    case "S_MARKER_M_STAR":
      return S_MARKER_M_STAR;
    case "S_MARKER_S_STAR":
      return S_MARKER_S_STAR;
    case "PURPLE_L_MARKER_L_STAR":
      return PURPLE_L_MARKER_L_STAR;
    case "PURPLE_L_MARKER_M_STAR":
      return PURPLE_L_MARKER_M_STAR;
    case "PURPLE_L_MARKER_S_STAR":
      return PURPLE_L_MARKER_S_STAR;
    case "PURPLE_M_MARKER_L_STAR":
      return PURPLE_M_MARKER_L_STAR;
    case "PURPLE_M_MARKER_M_STAR":
      return PURPLE_M_MARKER_M_STAR;
    case "PURPLE_M_MARKER_S_STAR":
      return PURPLE_M_MARKER_S_STAR;
    case "PURPLE_S_MARKER_L_STAR":
      return PURPLE_S_MARKER_L_STAR;
    case "PURPLE_S_MARKER_M_STAR":
      return PURPLE_S_MARKER_M_STAR;
    case "PURPLE_S_MARKER_S_STAR":
      return PURPLE_S_MARKER_S_STAR;
    case "STARTING_MARKER":
      return STARTING_MARKER;
    default:
      return M_MARKER_L_STAR;
  }
};
