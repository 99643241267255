import { ReactComponent as HNWIDossierIcon } from "./HNWIDossierIcon.svg";
import { ReactComponent as HNWIDossierDarkIcon } from "./HNWIDossierDarkIcon.svg";
import { ReactComponent as PBDossierIcon } from "./PBDossierIcon.svg";
import { ReactComponent as PBDossierDarkIcon } from "./PBDossierDarkIcon.svg";
import { ReactComponent as FoundationReachIcon } from "./FoundationReach.svg";
import { ReactComponent as FoundationReachDarkIcon } from "./FoundationReachDark.svg";
import { ReactComponent as EAndEIcon } from "./EAndE.svg";
import { ReactComponent as EAndEDarkIcon } from "./EAndEDark.svg";

export {
  HNWIDossierIcon,
  HNWIDossierDarkIcon,
  PBDossierIcon,
  PBDossierDarkIcon,
  FoundationReachIcon,
  FoundationReachDarkIcon,
  EAndEIcon,
  EAndEDarkIcon,
};
