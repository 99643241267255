import { useTranslation } from "react-i18next";
import React, { Fragment, useContext } from "react";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../hooks/app-colors.hook";
import { ReactComponent as BlackCube } from "../../../../assets/hub/BlackCube.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import styles from "../../../hub/HubHeader/HubKPIs/styles.module.css";
import AnimatedProgressProvider from "../../../hub/HubHeader/HubKPIs/AnimatedProgressProvider";
import { easeOutQuad } from "../../../../components/animations/count-up-animation/CountUpAnimation";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Spacing } from "../../../../components/spacing/component";
import {
  AnimationDescription,
  IAnimation,
  PROGRESS_BAR_TYPE,
  VerticalProgressBar,
} from "../../../hub/HubHeader/HubKPIs/HubKPIs";
import { useExecutivesHubAnimations } from "../../../../modules/executives-hub/hooks/executives-hub-animations.hook";
import { ReactComponent as PotentialsIcon } from "../../../../assets/executives-hub/PotentialsIcon.svg";
import { ReactComponent as PotentialsIconBlack } from "../../../../assets/executives-hub/PotentialsIconBlack.svg";
import { ReactComponent as PBIcon } from "../../../../assets/executives-hub/PBIcon.svg";
import { ReactComponent as PBIconDark } from "../../../../assets/executives-hub/PBIconDark.svg";
import { ReactComponent as HNWIIcon } from "../../../../assets/executives-hub/HNWIIcon.svg";
import { useSession } from "../../../../hooks/session.hook";
import GoTo from "../../../home/Toolbar/GoTo/GoTo";

const ExecutivesHubKPIs = () => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();

  const { executivesPBCount, executivesHNWICount, unfilteredHubExecutivesCount } =
    useExecutivesHubAnimations();

  const calculatePercentage = (count: number | null) => {
    if (
      count &&
      count > 0 &&
      unfilteredHubExecutivesCount &&
      unfilteredHubExecutivesCount > 0
    ) {
      return Math.round((count * 100) / unfilteredHubExecutivesCount);
    }

    return 0;
  };

  const executiveKPIs: IAnimation[] = [
    {
      title: t("potentialClients"),
      count: executivesPBCount + executivesHNWICount ?? 0,
      percentage: calculatePercentage(executivesPBCount + executivesHNWICount),
      pathColor: colors.secondaryDarkColor,
      trailColor: colors.secondaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.CIRCULAR,
    },
    {
      title: t("privateBanking"),
      count: executivesPBCount ?? 0,
      percentage: calculatePercentage(executivesPBCount),
      pathColor: colors.secondaryDarkColor,
      trailColor: colors.secondaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.CIRCULAR,
    },
    {
      title: t("hnwi"),
      count: executivesHNWICount ?? 0,
      percentage: calculatePercentage(executivesHNWICount),
      pathColor: colors.secondaryDarkColor,
      trailColor: colors.secondaryLightColor,
      Icon: <BlackCube color={colors.backgroundBlackColor} />,
      type: PROGRESS_BAR_TYPE.CIRCULAR,
    },
  ];

  const renderIcon = (title: string) => {
    if (title === t("hnwi")) {
      return (
        <HNWIIcon color={colors.backgroundBlackColor} className={styles.iconInsideCircle} />
      );
    }

    if (title === t("privateBanking")) {
      return isDarkTheme ? (
        <PBIconDark color={colors.backgroundBlackColor} className={styles.iconInsideCircle} />
      ) : (
        <PBIcon color={colors.backgroundBlackColor} className={styles.iconInsideCircle} />
      );
    }

    return isDarkTheme ? (
      <PotentialsIcon className={styles.iconInsideCircle} />
    ) : (
      <PotentialsIconBlack className={styles.iconInsideCircle} />
    );
  };

  return (
    <Flex justifyContentSpaceEvenly alignItemsCenter flex className={styles.animations}>
      <GoTo />
      {executiveKPIs.map((animation, index: number) => {
        return (
          <Fragment key={index}>
            <Flex alignItemsCenter>
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={animation.percentage}
                duration={1000}
                easingFunction={easeOutQuad}
              >
                {(value: number) => {
                  // const roundedValue = value % 1 ? value.toFixed(1) : value;
                  const roundedValue = Math.round(value);

                  return animation.type === PROGRESS_BAR_TYPE.CIRCULAR ? (
                    <div
                      className={`${styles.circularAnimationContainer} ${
                        isSmallerScreen && styles.circularAnimationContainer__Responsive
                      }`}
                    >
                      <CircularProgressbarWithChildren
                        value={value}
                        maxValue={100}
                        circleRatio={0.8}
                        strokeWidth={5}
                        styles={buildStyles({
                          pathTransition: "none",
                          rotation: 1 / 2 + 1 / 10,
                          strokeLinecap: "butt",
                          textColor: colors.textDarkColor,
                          pathColor: animation.pathColor,
                          trailColor: animation.trailColor,
                        })}
                      >
                        {renderIcon(animation.title)}
                      </CircularProgressbarWithChildren>
                    </div>
                  ) : (
                    <VerticalProgressBar
                      value={roundedValue}
                      trailColor={animation.trailColor}
                      pathColor={animation.pathColor}
                    />
                  );
                }}
              </AnimatedProgressProvider>
              <Spacing pr="15" />
              <AnimationDescription
                title={animation.title}
                count={animation.count}
                Icon={animation.Icon}
              />
            </Flex>
            {index < executiveKPIs.length - 1 && <Spacing pr="20" />}
          </Fragment>
        );
      })}
    </Flex>
  );
};

export default React.memo(ExecutivesHubKPIs);
