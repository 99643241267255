import React from "react";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import styles from "./styles.module.css";
import { Spacing } from "../../../../../../../components/spacing/component";

interface IProps {
  onClick: () => void;
  active?: boolean;
}

const SatelliteLayerToggle = ({ active, onClick }: IProps) => {
  return (
    <Flex onClick={onClick}>
      <RText fontSize="13" bold immutableBlack={!active} immutableWhite={active}>
        Satellite
      </RText>

      <Spacing pl="8" />

      <div className={`${styles.container} ${active ? styles.containerActive : ""}`}>
        <div className={`${styles.switch}`} />
      </div>
    </Flex>
  );
};

export default SatelliteLayerToggle;
