import { Store, StoreConfig } from "@datorama/akita";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";

export interface HubState {
  firstLoad: boolean;

  corporateIds: string[];
  loadingCorporates: boolean;
  hubErrorNoData: boolean;

  rolloverCorporateId: string;

  [key: `topPTFooterItems_${string}`]: IHubFooterItemData[];
  [key: `loadingTPT_${string}`]: boolean;

  cumulativeNewsCount: number | null;
  // loadingCumulativeNewsCount: boolean; // TODO: not used anywhere? shuold it be used to show a spinner?

  hubNewsFooterItems: IHubFooterItemData[] | null;
  loadingTopNews: boolean;

  hubStartUpsFooterItems: IHubFooterItemData[];
  loadingTopStartUps: boolean;

  hubAnniversariesFooterItems: IHubFooterItemData[];
  loadingAnniversaries: boolean;

  hubBankruptciesFooterItems: IHubFooterItemData[];
  loadingTopBankruptcies: boolean;

  companiesWithTrendsIds: string[];
  companiesWithNewsIds: string[];
  companiesWithTrendsAndNewsIds: string[];

  unfilteredHubCorporatesIds: string[];
  unfilteredHubCorporatesCount: number | null;
}

const createInitialState = (): HubState => {
  return {
    firstLoad: true,
    corporateIds: null,
    loadingCorporates: null,
    hubErrorNoData: false,

    rolloverCorporateId: null,

    cumulativeNewsCount: null,
    // loadingCumulativeNewsCount: false,

    hubNewsFooterItems: null,
    loadingTopNews: true,

    hubStartUpsFooterItems: null,
    loadingTopStartUps: true,

    hubAnniversariesFooterItems: null,
    loadingAnniversaries: true,

    hubBankruptciesFooterItems: null,
    loadingTopBankruptcies: true,

    companiesWithTrendsIds: null,
    companiesWithNewsIds: null,
    companiesWithTrendsAndNewsIds: null,

    unfilteredHubCorporatesIds: null,
    unfilteredHubCorporatesCount: null,
  };
};

@StoreConfig({
  name: "hub",
})
export class HubStore extends Store<HubState> {
  constructor() {
    super(createInitialState());
  }
}

export const hubStore = new HubStore();
