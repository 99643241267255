export interface IShareholderCompanies {
  affinity_score?: number;
  capital_currency?: any;
  capital?: any;
  company_id: number;
  company_name: string;
  from_date: string;
  from_quality: number;
  industry_sector: string;
  position?: any;
  relation_class?: string;
  relation_type: string;
  share_perc?: any;
  to_date?: any;
  to_quality?: any;
}

export function createShareholderCompanies(params: Partial<IShareholderCompanies>) {
  return {
    affinity_score: null,
    company_id: null,
    company_name: null,
    industry_sector: null,
    from_date: null,
    share_perc: null,
    ...params,
  } as IShareholderCompanies;
}
