import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { IExecutive } from "../../executives/state/executive.model";
import { IFoundationReachResponse } from "../models/foundation-reach.model";
import { getFoundationReachCount } from "../../../pages/home/executives/dossier/components/side-navigation-item-content/foundation-reach/FoundationReach";
import { IRelationshipIndicatorResponse } from "../models/relationship-indicator.model";
import { ExecutiveDossierContext } from "../state/executive-dossier.context";
import { IEAndE } from "../models/e-and-e.model";

interface IRelationshipIndicator {
  executiveRelationshipIndicator: IRelationshipIndicatorResponse;
}

export function useExecutiveRelationshipIndicator(): IRelationshipIndicator {
  const executiveDossierContext = useContext(ExecutiveDossierContext);

  const [executiveRelationshipIndicator] = useObservable(
    executiveDossierContext.executiveRelationshipIndicator$,
    null,
  );

  return {
    executiveRelationshipIndicator,
  };
}

interface IExecutiveSideNavigationActiveAnchor {
  executiveSideNavigationActiveAnchor: string;
}

export function useExecutiveSideNavigationActiveAnchor(): IExecutiveSideNavigationActiveAnchor {
  const executiveDossierContext = useContext(ExecutiveDossierContext);

  const [executiveSideNavigationActiveAnchor] = useObservable(
    executiveDossierContext.executiveSideNavigationActiveAnchor$,
    "career",
  );

  return {
    executiveSideNavigationActiveAnchor,
  };
}

interface IExecutiveLookALikes {
  executiveLookALikes: IExecutive[];
  totalExecutiveLookALikes: number;
}

export function useExecutiveLookALikes(): IExecutiveLookALikes {
  const executivesContext = useContext(ExecutiveDossierContext);

  const [executiveLookALikes] = useObservable(executivesContext.executiveLookALikes$, null);

  const [totalExecutiveLookALikes] = useObservable(
    executivesContext.totalExecutiveLookALikes$,
    null,
  );

  return {
    executiveLookALikes,
    totalExecutiveLookALikes,
  };
}

interface IOpenExecutiveFullscreenMapModal {
  openExecutiveFullscreenMapModal: boolean;
  setOpenExecutiveFullscreenMapModal: (open: boolean) => void;
}

export function useOpenExecutiveFullscreenMapModal(): IOpenExecutiveFullscreenMapModal {
  const executivesContext = useContext(ExecutiveDossierContext);

  const [openExecutiveFullscreenMapModal] = useObservable(
    executivesContext.openExecutiveFullscreenMapModal$,
    false,
  );

  return {
    openExecutiveFullscreenMapModal,
    setOpenExecutiveFullscreenMapModal: executivesContext.setOpenExecutiveFullscreenMapModal,
  };
}

interface IFoundationReach {
  executiveFoundationReach: IFoundationReachResponse;
  executiveFoundationReachCount: number;
}

export function useExecutiveFoundationReach(): IFoundationReach {
  const executiveDossierContext = useContext(ExecutiveDossierContext);

  const [executiveFoundationReach] = useObservable(
    executiveDossierContext.executiveFoundationReach$,
    null,
  );

  return {
    executiveFoundationReach,
    executiveFoundationReachCount: getFoundationReachCount(executiveFoundationReach),
  };
}

interface IEntrepreneursAndExecutives {
  entrepreneursAndExecutives: IEAndE[];
  totalEntrepreneursAndExecutives: number;
}

export function useEntrepreneursAndExecutives(): IEntrepreneursAndExecutives {
  const executivesContext = useContext(ExecutiveDossierContext);

  const [entrepreneursAndExecutives] = useObservable(
    executivesContext.entrepreneursAndExecutives$,
    null,
  );

  const [totalEntrepreneursAndExecutives] = useObservable(
    executivesContext.totalEntrepreneursAndExecutives$,
    null,
  );

  return {
    entrepreneursAndExecutives,
    totalEntrepreneursAndExecutives,
  };
}
