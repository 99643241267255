import React, { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IPeopleManagement } from "../../../../../../../entities/executives/interfaces/people-management.interface";
import { formatPeriodDate } from "../../../../../corporates/dossier/components/side-navigation-item-content/management/Management";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { formatIndustrySectorForTranslation } from "../../../../../../../helpers/translation.helper";
import { abbreviateNumber } from "../../../../../../../helpers/number.helper";
import EAndEScore from "../../../shared/EAndEScore/EAndEScore";
import EntityInfo, { EntityType } from "../../../shared/EntityInfo";
import TableRow from "../../../shared/TableRow";
import { useSession } from "../../../../../../../hooks/session.hook";

interface IProps {
  careerData: IPeopleManagement[];
}

export default function Career({ careerData }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser } = useSession();

  const formattedCareerData = useMemo(() => mapCareerData(careerData, t), [careerData, t]);

  return (
    <>
      <RText fontSize="20" bold>
        {t("career")}
      </RText>
      <Spacing pt={isSmallerScreen ? "14" : "28"} />
      {formattedCareerData?.length > 0 ? (
        <>
          <TableRow
            col1Data={t("company")}
            col2Data={t("position")}
            col3Data={t("period")}
            col4Data={isB2CUser ? t("eAndE") : null}
            header
          />
          <Spacing pt="20" />
          {formattedCareerData.map((career, index) => {
            return (
              <Fragment key={index}>
                <TableRow
                  col1Data={
                    <EntityInfo
                      entityId={career.companyData.companyId}
                      entityType={EntityType.CORPORATE}
                      entityName={career.companyData.companyName}
                      ratingScore={career.companyData.affinityScore}
                      subTitle={career.companyData.industrySector}
                      headcount={career.companyData.headcount}
                      turnover={career.companyData.turnover}
                    />
                  }
                  col2Data={career.position}
                  col3Data={career.period}
                  col4Data={isB2CUser ? <EAndEScore score={career.eAndE} /> : null}
                />

                {index < formattedCareerData.length - 1 && <Divider />}
              </Fragment>
            );
          })}
          <Spacing pt={isSmallerScreen ? "20" : "50"} />
        </>
      ) : null}
    </>
  );
}

interface ICareer {
  companyData: any;
  position: string;
  period: string;
  eAndE: string;
}

const mapCareerData = (careerData: IPeopleManagement[], t: CallableFunction): ICareer[] => {
  return [...careerData]
    .sort(
      (a, b) =>
        b.score - a.score ||
        a.function_rank - b.function_rank ||
        (b.to_date ? 0 : 1) - (a.to_date ? 0 : 1) ||
        (a.from_date ? 1 : 0) - (b.from_date ? 1 : 0),
    )
    .map((data: IPeopleManagement) => ({
      companyData: {
        companyId: data.company_id?.toString(),
        companyName: data.company_name,
        affinityScore: data.affinity_score,
        industrySector: formatIndustrySectorForTranslation(data.industry_sector, t),
        headcount: data.employee_count ? abbreviateNumber(data.employee_count) : null,
        turnover: data.turnover ? abbreviateNumber(data.turnover) : null,
      },
      position: data.position ?? "",
      period: formatPeriodDate(data, t),
      eAndE: data.ee_score?.toString(),
    }));
};
