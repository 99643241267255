import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { IExecutiveHubMarker } from "../../../../entities/corporates/interfaces/hub-marker.interface";
import {
  createExecutiveHubDivIconFromMarkerData,
  isPurpleExecutiveMarker,
} from "../../../../helpers/mapMarker.helper";
import { useHubRolloverExecutive } from "../../../../modules/executives-hub/hooks/hub-rollover-executive";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { StyledPopup } from "../../../home/corporates/dossier/components/map/StyledPopup";
import ExecutivesHubMarkerPopup from "../ExecutivesHubMarkerPopup/ExecutivesHubMarkerPopup";

interface IProps {
  hubMarkers: IExecutiveHubMarker[];
}

const ExecutivesHubMapMarkers = ({ hubMarkers }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  if (!hubMarkers) {
    return null;
  }

  return (
    <>
      {hubMarkers.map((hm) => (
        <MarkerWrapper key={hm.executivesData[0]?.executiveId} marker={hm}>
          <StyledPopup isSmallerScreen={isSmallerScreen}>
            <ExecutivesHubMarkerPopup markerExecutives={hm.executivesData} />
          </StyledPopup>
        </MarkerWrapper>
      ))}
    </>
  );
};

export default ExecutivesHubMapMarkers;

interface IMarkerWrapperProps {
  marker: IExecutiveHubMarker;
  children: React.ReactNode;
}

const MarkerWrapper = React.memo(({ marker, children }: IMarkerWrapperProps) => {
  const { hubRolloverExecutiveId } = useHubRolloverExecutive();
  const markerRef = useRef(null);
  const isActiveMarker: boolean = useMemo(
    () => isPurpleExecutiveMarker(marker, hubRolloverExecutiveId),
    [hubRolloverExecutiveId, marker],
  );

  useEffect(() => {
    if (!markerRef.current) {
      return;
    }

    markerRef.current.closePopup();

    if (isActiveMarker) {
      markerRef.current.openPopup();
    }
  }, [isActiveMarker]);

  const icon = useMemo(
    () => createExecutiveHubDivIconFromMarkerData(marker, hubRolloverExecutiveId),
    [hubRolloverExecutiveId, marker],
  );

  const position = useMemo(
    () => ({
      lat: marker.coordinates.lat,
      lng: marker.coordinates.lon,
    }),
    [marker.coordinates],
  );

  return (
    <Marker
      ref={markerRef}
      position={position}
      icon={icon}
      zIndexOffset={isActiveMarker ? 100000 : 1}
    >
      {children}
    </Marker>
  );
});
