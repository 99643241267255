import { Observable } from "rxjs";
import { ILastVisitedDossier } from "./last-dossier.model";
import { LastDossierStore } from "./last-dossier.store";
import { LastDossierQuery } from "./last-dossier.query";
import { lastDossierApiService } from "../../../api/services/last-visited-dossier.service";

export interface ILastDossierContext {
  lastDossiers$: Observable<ILastVisitedDossier[]>;

  fetchUserLastDossiers: () => Promise<void>;
  saveLastDossier: (dossier: ILastVisitedDossier) => Promise<void>;
}

export class LastDossierFacade implements ILastDossierContext {
  lastDossiers$: Observable<ILastVisitedDossier[]> = this._query.selectAll();

  constructor(private _store: LastDossierStore, private _query: LastDossierQuery) {}

  async fetchUserLastDossiers(): Promise<void> {
    try {
      const res = await lastDossierApiService.getLastVisitedDossiers();

      this._store.set(res);
    } catch (error) {
      console.warn("Error while fetching user last dossiers: ", error);
    }
  }

  async saveLastDossier(dossier: ILastVisitedDossier): Promise<void> {
    try {
      const res = await lastDossierApiService.saveLastDossier(dossier);

      this._store.add(
        res,
        { prepend: true }, // Adds dossier to the top of dossier list
      );
    } catch (error) {
      console.warn("Error while saving last search term: ", error);
    }
  }
}
