import React, { useContext } from "react";
import { Flex } from "../../../../components/containers/Flex/Flex";
import ColoredContainer from "../../../../components/containers/ColoredContainer/ColoredContainer";
import { Spacing } from "../../../../components/spacing/component";
import SquareButton from "../../../../components/buttons/SquareButton/SquareButton";
import { ReactComponent as ChevronUpIcon } from "../../../../assets/ChevronUp.svg";
import { ReactComponent as ChevronDownIcon } from "../../../../assets/ChevronDown.svg";
import { useTranslation } from "react-i18next";
import RText from "../../../../components/fonts/RText/RText";
import { formatNumberWithDotSeparator } from "../../../../helpers/number.helper";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../hooks/app-colors.hook";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";
import { useSession } from "../../../../hooks/session.hook";

const automationMarkerClearAll = {
  [AUTOMATION_TEST_KEY]: "filters-clearall-btn",
};
const automationMarkerMyFilters = {
  [AUTOMATION_TEST_KEY]: "filters-myfilters-btn",
};

interface IProps {
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  resultsCount: number;
  hasFilters?: boolean;
  onMyFiltersClick: () => void;
  onClearAllClick: () => void;
  filtersOpened?: boolean;
}

const FiltersHeader = ({
  title,
  Icon,
  resultsCount,
  hasFilters,
  onMyFiltersClick,
  onClearAllClick,
  filtersOpened,
}: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();

  return (
    <Flex justifyContentSpaceBetween>
      <Flex alignItemsCenter>
        <Icon
          color={colors.backgroundBlackColor}
          style={{
            width: isSmallerScreen ? 40 : 45,
            height: isSmallerScreen ? 30 : 35,
          }}
        />
        <Spacing pl={isSmallerScreen ? "8" : "16"} />
        <RText fontSize={isSmallerScreen ? "18" : "28"}>{title}</RText>
        <Spacing pl={isSmallerScreen ? "12" : "20"} />
        {resultsCount === null || resultsCount === undefined ? null : (
          <ColoredContainer size={isSmallerScreen ? "30" : "40"} primaryGreen={!isDarkTheme}>
            <RText immutableBlack fontSize={isSmallerScreen ? "18" : "25"}>
              {formatNumberWithDotSeparator(resultsCount)}
            </RText>
          </ColoredContainer>
        )}
      </Flex>
      {hasFilters && (
        <Flex alignItemsCenter>
          <SquareButton
            text={t("clearAll")}
            borderless
            onClick={onClearAllClick}
            {...automationMarkerClearAll}
          />
          <Spacing pl="12" />
          <SquareButton
            text={t("myFilters")}
            borderless
            active={filtersOpened}
            Icon={<ChevronDownIcon color={colors.secondaryDarkColor} />}
            IconActive={<ChevronUpIcon color={colors.secondaryDarkColor} />}
            onClick={onMyFiltersClick}
            {...automationMarkerMyFilters}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default FiltersHeader;
