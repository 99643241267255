import React, { useContext } from "react";
import { useCorporateNotes } from "../../../../../../entities/corporate-notes/hooks/corporate-notes.hook";
import { CorporateNotesContext } from "../../../../../../entities/corporate-notes/state/corporate-notes.context";
import RNotes from "../../../../../../components/notes/RNotes";

interface IProps {
  corporateId: string;
}

const Notes = ({ corporateId }: IProps) => {
  const { corporateNotes } = useCorporateNotes();
  const corporateNotesContext = useContext(CorporateNotesContext);

  return (
    <RNotes
      entityId={corporateId}
      notes={corporateNotes}
      addNote={corporateNotesContext.addNote}
      updateNote={corporateNotesContext.updateNote}
      deleteNote={corporateNotesContext.deleteNote}
    />
  );
};

export default React.memo(Notes);
