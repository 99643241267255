import React from "react";
import styles from "./styles.module.css";
import { FullHeight } from "../../containers/FullHeight/FullHeight";
import { classNames } from "../../../helpers/string.helper";

interface IProps {
  primaryGreen?: boolean;
  primaryBlue?: boolean;
  gray?: boolean;
  black?: boolean;
  opacity?: boolean;
  className?: string;
}

const VerticalDivider = ({
  primaryGreen,
  primaryBlue,
  gray,
  black,
  opacity,
  className,
}: IProps) => {
  return (
    <FullHeight>
      <div
        className={classNames(styles, {
          primaryGreen,
          primaryBlue,
          gray,
          black,
          opacity,
          className,
        })}
      />
    </FullHeight>
  );
};

export default VerticalDivider;
