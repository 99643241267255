import React, { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./styles.module.css";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { CentralSearchInput } from "../../../../components/inputs/CentralSearchInput/CentralSearchInput";
import { usePerformCentralSearch } from "../../../../hooks/perform-central-search.hook";
import { CentralSearchContext } from "../../HomePage";
import { LastSearchContext } from "../../../../entities/last-search/state/last-search.context";
import { useLastSearchTerms } from "../../../../entities/last-search/hooks/last-search-terms.hook";
import { ILastSearch } from "../../../../entities/last-search/state/last-search.model";
import { autosuggestService } from "../../../../api/services/autosuggest.service";
import { IAutosuggestResponse } from "../../../../api/interfaces/autosuggest-response.interface";
import useDebounce from "../../../../hooks/debounce.hook";
import LastSearch from "./LastSearch";
import Autosuggest from "./Autosuggest";
import { useCloseOnClickOutside } from "../../../../hooks/componentRefs.hook";
import { useIsSearchPage } from "../../../../utils/route.util";

interface ICentralSearch {
  light?: boolean;
}

const CentralSearch = ({ light }: ICentralSearch) => {
  const [performCentralSearch] = usePerformCentralSearch();
  const { searchTerm, setSearchTerm } = useContext(CentralSearchContext);
  const lastSearchContext = useContext(LastSearchContext);
  const { lastSearchTerms } = useLastSearchTerms();
  const [active, setActive] = useState(false);
  const isSearchPage = useIsSearchPage();
  let match = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    lastSearchContext.fetchUserLastSearchTerms();
  }, []); // eslint-disable-line

  const debouncedSearchQuery = useDebounce(searchTerm, 300);
  const [isSearchTermSelected, setIsSearchTermSelected] = useState(searchTerm !== "");
  const [autosuggestedData, setAutosuggestedData] = useState<IAutosuggestResponse>(null);

  useEffect(() => {
    /**
     * Preventing autosuggest API calls, once the wanted search term is selected from the popup list.
     */
    if (isSearchTermSelected) {
      setIsSearchTermSelected(false);
      return;
    }

    debouncedSearchQuery &&
      autosuggestService
        .getAutosuggestions({ query: debouncedSearchQuery })
        .then((res: IAutosuggestResponse) => {
          active && setActive(false);
          setAutosuggestedData(res);
        });
  }, [debouncedSearchQuery]); // eslint-disable-line

  // Perform search if search term is entered and enter key clicked.
  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    // Remove whitespace from left and right if exist
    const trimmedSearchTerm = searchTerm?.trim() ?? "";

    // if (trimmedSearchTerm.length === 0) {
    //     return;
    // }

    active && setActive(false);
    setIsSearchTermSelected(true);
    setAutosuggestedData(null);

    if (trimmedSearchTerm.length > 0) {
      /**
       * Check if search term is already added to last search list.
       * We don't want to save the same search term multiple times.
       */
      const isSearchTermAlreadyAdded = lastSearchTerms.some(
        (lastSearchTerm: ILastSearch) => lastSearchTerm.term === trimmedSearchTerm,
      );

      !isSearchTermAlreadyAdded && lastSearchContext.saveLastSearchTerm(trimmedSearchTerm);
    }

    performCentralSearch(trimmedSearchTerm);

    if (isSearchPage) {
      return;
    }

    // Redirect to search results page
    history.push(`${match.path}/search`);
  };

  const onCTAClick = () => setActive(true);
  const onCloseIconClick = () => setActive(false);

  const onSearchTermClick = (searchTerm: string) => () => {
    setSearchTerm(searchTerm);
    performCentralSearch(searchTerm);

    setActive(false);
    setIsSearchTermSelected(true);
    setAutosuggestedData(null);

    if (isSearchPage) {
      return;
    }

    history.push(`${match.path}/search`);
  };

  const onSearchTermDelete = () => {
    setSearchTerm("");
    setAutosuggestedData(null);
    performCentralSearch("");
  };

  const [ref] = useCloseOnClickOutside(
    () => {
      if (!active && !autosuggestedData) {
        return;
      }

      setActive(false);
      setAutosuggestedData(null);
    },
    { capture: true },
  );

  return (
    <Flex
      elementRef={ref}
      column
      className={
        light ? styles.centralSearchInputContainerLight : styles.centralSearchInputContainer
      }
    >
      <CentralSearchInput
        type="text"
        onChange={setSearchTerm}
        onCTAClick={onCTAClick}
        value={searchTerm}
        active={active}
        onCloseIconClick={onCloseIconClick}
        onKeyUp={onEnterKeyPress}
        onDelete={onSearchTermDelete}
        light={light}
      />
      {active && lastSearchTerms && lastSearchTerms.length > 0 && (
        <LastSearch
          lastSearchTerms={lastSearchTerms}
          onLastSearchTermClick={onSearchTermClick}
        />
      )}
      {!isSearchTermSelected && debouncedSearchQuery && autosuggestedData && !active && (
        <Autosuggest autosuggestedData={autosuggestedData} />
      )}
    </Flex>
  );
};

export default CentralSearch;
