import React, { useContext, useEffect } from "react";
import { Spacing } from "../../../components/spacing/component";
import { CorporatesFiltersContext } from "../../../entities/corporates-filters/state/corporates-filters.context";
import { ExecutivesFiltersContext } from "../../../entities/executives-filters/state/executives-filters.context";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import HomeNavigationBar from "../navigation/HomeNavigationBar";
import AllResultsPage from "../allResults/AllResultsPage/AllResultsPage";
import CorporatesPage from "../corporates/CorporatesPage";
import ExecutivesPage from "../executives/ExecutivesPage";
import InterestsPage from "../interests/InterestsPage";
import OpportunitiesPage from "../opportunities/OpportunitiesPage";
import StartupsPage from "../startups/StartupsPage";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../hooks/session.hook";
import { useUserTags } from "../../../entities/custom-tags/hooks/user-tags.hook";
import { Container } from "@mui/material";

const SearchResultsPage = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  let match = useRouteMatch();
  const { isB2CUser } = useSession();

  const corporatesFiltersContext = useContext(CorporatesFiltersContext);
  const executivesFiltersContext = useContext(ExecutivesFiltersContext);
  const { userTags } = useUserTags();

  useEffect(() => {
    corporatesFiltersContext.fetchCorporatesFilters();
    executivesFiltersContext.fetchExecutivesFilters();
  }, [userTags]); // eslint-disable-line

  return (
    <>
      <Spacing ph={isSmallerScreen ? "page-sides-small" : "page-sides"}>
        <HomeNavigationBar />
      </Spacing>

      {/* <Spacing
                ph={isSmallerScreen ? "page-sides-small" : "page-sides"}
                pt="page-top"
            > */}
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Switch>
          <Route path={`${match.path}/all`}>
            <AllResultsPage />
          </Route>
          <Route path={`${match.path}/corporates`}>
            <CorporatesPage />
          </Route>
          <Route path={`${match.path}/executives`}>
            <ExecutivesPage />
          </Route>
          <Route path={`${match.path}/interests`}>
            <InterestsPage />
          </Route>
          <Route path={`${match.path}/opportunities`}>
            <OpportunitiesPage />
          </Route>
          <Route path={`${match.path}/startups`}>
            <StartupsPage />
          </Route>
          <Route path={`${match.path}`}>
            <Redirect
              to={isB2CUser ? `${match.path}/executives` : `${match.path}/corporates`}
            />
          </Route>
        </Switch>
      </Container>
      {/* </Spacing> */}
    </>
  );
};

export default SearchResultsPage;
