/**
 * @see https://react-leaflet.js.org/docs/example-react-control
 */
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

// Classes used by Leaflet to position controls.
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
} as const;

const MapCustomControl = (props: MapCustomControlProps): JSX.Element => {
  const { position, containerProps, children } = props;
  return (
    <div className={POSITION_CLASSES[position]}>
      <div className="leaflet-control" {...containerProps}>
        {children}
      </div>
    </div>
  );
};

export type MapCustomControlProps = {
  position: keyof typeof POSITION_CLASSES;
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  children: ReactNode;
};

MapCustomControl.defaultProps = {
  position: "topleft" as MapCustomControlProps["position"],
  containerProps: {},
  children: null,
};

export default MapCustomControl;
