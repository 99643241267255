import { useCallback, useEffect, useRef } from "react";

export const useCloseOnClickOutside = (
  onClose: () => void,
  options?: AddEventListenerOptions,
) => {
  const ref = useRef(null);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }

      onClose();
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener("click", clickListener, options);

    return () => document.removeEventListener("click", clickListener, options);
  }, [clickListener, options]);

  return [ref];
};

export const useCloseOnEscKey = (onClose: () => void) => {
  const ref = useRef(null);

  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener("keyup", escapeListener);

    return () => {
      document.removeEventListener("keyup", escapeListener);
    };
  }, [escapeListener]);

  return [ref];
};
