import { extension } from "../constants/hub";
import { EXPORT_TYPE } from "../shared/enums/export-type.enum";

export const exportBlob = (content: string, type: EXPORT_TYPE) => {
  const blob = new Blob([content]);
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", `result.${extension[type]}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(downloadUrl);
};
