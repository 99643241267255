import React, { useContext, useMemo, useState } from "react";
import { Circle, MapContainer as OpenStreetMap, useMap, ZoomControl } from "react-leaflet";
import { useMediaQuery, useTheme } from "@mui/material";
import styles from "../../../home/corporates/dossier/components/map/styles.module.css";
import { ICoordinates } from "../../../../shared/interfaces/coordinates.interface";
import { IExecutiveHubMarker } from "../../../../entities/corporates/interfaces/hub-marker.interface";
import MapSatelliteLayer from "../../../home/corporates/dossier/components/map/map-layers/MapSatelliteLayer";
import MapTransportLayer from "../../../home/corporates/dossier/components/map/map-layers/MapTransportLayer";
import MapCustomControl from "../../../home/corporates/dossier/components/map/MapCustomControl";
import SatelliteLayerToggle from "../../../home/corporates/dossier/components/map/map-controls/SatelliteLayerToggle";
import ExecutivesHubMapMarkers from "./ExecutivesHubMapMarkers";
import { getDefaultMapCoordinates } from "../../../../helpers/map.helper";
import { MapContext } from "../../../../context/MapContext";

interface IProps {
  width: string;
  height: string;
  center?: ICoordinates;
  zoom?: number | null;
  hubMarkers?: IExecutiveHubMarker[];
  showStats: boolean;
}

const ExecutivesHubMap = ({ width, height, center, zoom, hubMarkers, showStats }: IProps) => {
  const [satelliteLayer, setSatelliteLayer] = useState(false);
  const { state } = useContext(MapContext);
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const toggleSatelliteLayer = () => {
    setSatelliteLayer(!satelliteLayer);
  };

  const defaultMapCoordinates = useMemo(() => getDefaultMapCoordinates(), []);

  const FlyTo = (props: any): any => {
    const map = useMap();
    if (props?.location?.lat && props?.location?.lng) map.flyTo(props.location, 12);

    return null;
  };

  return (
    <OpenStreetMap
      center={state.center ?? center ?? defaultMapCoordinates}
      zoom={zoom ?? 6}
      minZoom={3}
      style={{
        height: height,
        width: width,
      }}
      tap={false}
      preferCanvas
      zoomControl={false}
      className="executives-map"
    >
      <FlyTo location={state.center} />
      {satelliteLayer ? <MapSatelliteLayer /> : <MapTransportLayer />}

      {state.center && (
        <Circle
          center={state.center ?? defaultMapCoordinates}
          radius={state.radius * 1000}
          color="#D5C599"
        />
      )}

      <MapCustomControl
        position="topright"
        containerProps={{
          style: {
            marginTop: "5px",
          },
        }}
      >
        <div
          className={`${styles.controlsContainer}  ${
            showStats && styles.controlsContainer__drop
          }`}
        >
          <SatelliteLayerToggle onClick={toggleSatelliteLayer} active={satelliteLayer} />
        </div>

        {upMd && <ZoomControl position="bottomright" />}
      </MapCustomControl>

      <ExecutivesHubMapMarkers hubMarkers={hubMarkers} />
    </OpenStreetMap>
  );
};

export default ExecutivesHubMap;
