import { QueryEntity } from "@datorama/akita";
import { LastDossierState, lastDossierStore, LastDossierStore } from "./last-dossier.store";

export class LastDossierQuery extends QueryEntity<LastDossierState> {
  constructor(protected store: LastDossierStore) {
    super(store);
  }
}

export const lastDossierQuery = new LastDossierQuery(lastDossierStore);
