export interface ITurnoverHistoric {
  year: number;
  turnover: number;
}

export function createTurnoverHistoric(params: Partial<ITurnoverHistoric>) {
  return {
    year: null,
    turnover: null,
    ...params,
  } as ITurnoverHistoric;
}
