import React from "react";
import styles from "./styles.module.css";

interface IProps {
  initialTag?: boolean;
  color: string;
  setSelectedColor?: (color: string) => void;
  closePopup?: () => void;
}

const ColoredTag = ({ initialTag = false, color, setSelectedColor, closePopup }: IProps) => {
  const onTagClick = () => {
    setSelectedColor && setSelectedColor(color);
    closePopup && closePopup();
  };

  return (
    <div
      style={{
        backgroundColor: color ?? "var(--immutable_background_white)",
        border: `${initialTag ? "1px" : "0"} solid var(--immutable_background_black)`,
      }}
      className={styles.coloredTag}
      onClick={onTagClick}
      role="button"
      tabIndex={0}
    />
  );
};

export default ColoredTag;
