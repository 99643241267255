import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import Magnifier from "../../../../assets/company-dossier/news-section/Magnifier.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../components/spacing/component";
import {
  IAutosuggestObject,
  IAutosuggestResponse,
} from "../../../../api/interfaces/autosuggest-response.interface";
import RLink from "../../../../components/link/RLink";

interface IProps {
  autosuggestedData: IAutosuggestResponse;
}

const Autosuggest = ({ autosuggestedData }: IProps) => {
  const { t } = useTranslation();

  const renderRow = (
    item: IAutosuggestObject,
    arrayLength: number,
    index: number,
    isCorporate: boolean,
  ) => {
    return (
      <Fragment key={index}>
        <RLink
          path={`/home/search/${isCorporate ? "corporate-dossier" : "executive-dossier"}/${
            item.id
          }`}
        >
          <Flex pointer className={styles.suggestedTerm}>
            <img src={Magnifier} alt="" />
            <Spacing pr="10" />
            <RText immutableBlack fontSize="15" noWrap ellipsis>
              {item.name}
            </RText>
          </Flex>
        </RLink>
        {index < arrayLength - 1 && <Spacing pt="4" />}
      </Fragment>
    );
  };

  return (
    <div className={styles.autosuggestContainer}>
      <Spacing pv="15" ph="25">
        <RText immutableBlack fontSize="13">
          {t("companies")}
        </RText>
      </Spacing>
      <div className={styles.autosuggestedTermsContainer}>
        {autosuggestedData?.corporate?.length > 0 ? (
          autosuggestedData.corporate.map((corporate: IAutosuggestObject, index: number) =>
            renderRow(corporate, autosuggestedData.corporate?.length, index, true),
          )
        ) : (
          <Flex>
            <Spacing pr="25" />
            <RText immutableBlack fontSize="15">
              {t("noResultsMatchCriteria")}
            </RText>
          </Flex>
        )}
      </div>

      <Spacing pv="15" ph="25">
        <RText immutableBlack fontSize="13">
          {t("executives")}
        </RText>
      </Spacing>
      <div className={styles.autosuggestedTermsContainer}>
        {autosuggestedData?.executive?.length > 0 ? (
          autosuggestedData.executive.map((executive: IAutosuggestObject, index: number) =>
            renderRow(executive, autosuggestedData.executive.length, index, false),
          )
        ) : (
          <Flex>
            <Spacing pr="25" />
            <RText immutableBlack fontSize="15">
              {t("noResultsMatchCriteria")}
            </RText>
          </Flex>
        )}
      </div>
      <Spacing pt="10" />
    </div>
  );
};

export default Autosuggest;
