import React from "react";
import { useTranslation } from "react-i18next";
import RNavigation, {
  INavigationItem,
} from "../../../../../../../components/navigation/RNavigation";

export enum MANAGEMENT_NAVIGATION_ITEM_TYPE {
  ALL,
  OWNERSHIP,
  BOARD,
  EXECUTIVES,
  OPERATIVE,
  OTHERS,
}

interface IProps {
  selected: MANAGEMENT_NAVIGATION_ITEM_TYPE;
  setSelected: (type: MANAGEMENT_NAVIGATION_ITEM_TYPE) => void;
  managementDataExist: any;
}

export default function Navigation({ selected, setSelected, managementDataExist }: IProps) {
  const { t } = useTranslation();

  const navigationItems: INavigationItem[] = [
    {
      exist: true,
      title: t("all"),
      type: MANAGEMENT_NAVIGATION_ITEM_TYPE.ALL,
    },
    {
      exist: Boolean(managementDataExist.ownership),
      title: t("ownership"),
      type: MANAGEMENT_NAVIGATION_ITEM_TYPE.OWNERSHIP,
    },
    {
      exist: Boolean(managementDataExist.board),
      title: t("board"),
      type: MANAGEMENT_NAVIGATION_ITEM_TYPE.BOARD,
    },
    {
      exist: Boolean(managementDataExist.executives),
      title: t("executivesCorporateManagement"),
      type: MANAGEMENT_NAVIGATION_ITEM_TYPE.EXECUTIVES,
    },
    {
      exist: Boolean(managementDataExist.operative),
      title: t("operative"),
      type: MANAGEMENT_NAVIGATION_ITEM_TYPE.OPERATIVE,
    },
    {
      exist: Boolean(managementDataExist.others),
      title: t("others"),
      type: MANAGEMENT_NAVIGATION_ITEM_TYPE.OTHERS,
    },
  ];

  const onSelectItem = (item: INavigationItem) => {
    if (!item.exist) {
      return;
    }

    setSelected(item.type as MANAGEMENT_NAVIGATION_ITEM_TYPE);
  };

  return (
    <RNavigation
      navigationItems={navigationItems}
      selectedItem={selected}
      onSelectItem={onSelectItem}
    />
  );
}
