import { Query } from "@datorama/akita";
import { HubState, HubStore, hubStore } from "./hub.store";

export class HubQuery extends Query<HubState> {
  constructor(protected store: HubStore) {
    super(store);
  }
}

export const hubQuery = new HubQuery(hubStore);
