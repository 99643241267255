import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { SessionContext } from "../state/akita/session/session.context";

const useAppColors = () => {
  const sessionContext = useContext(SessionContext);
  const [colors] = useObservable(sessionContext.colors$, sessionContext.getColors());

  return { colors };
};

export default useAppColors;
