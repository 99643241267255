import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { HubContext } from "../../../modules/hub/state/hub.context";
import { IHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";

interface ITopStartUpsHook {
  hubStartUpsFooterItems: IHubFooterItemData[];
  loadingTopStartUps: boolean;
}

export const useTopStartUps = (): ITopStartUpsHook => {
  const hubContext = useContext(HubContext);

  const [hubStartUpsFooterItems] = useObservable(hubContext.hubStartUpsFooterItems$, null);

  const [loadingTopStartUps] = useObservable(hubContext.loadingTopStartUps$, true);

  return {
    hubStartUpsFooterItems,
    loadingTopStartUps,
  };
};
