import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import AnimatedCircleIcon from "../../../../../../../components/animations/animated-circle-icon/AnimatedCircleIcon";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { ISecondLine } from "../../../../../../../entities/executive-dossier/models/foundation-reach.model";
import { abbreviateNumber } from "../../../../../../../helpers/number.helper";
import { formatIndustrySectorForTranslation } from "../../../../../../../helpers/translation.helper";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../../../hooks/session.hook";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import EntityInfo, { EntityType } from "../../../shared/EntityInfo";
import TableRow from "../../../shared/TableRow";
import styles from "./styles.module.css";

interface IProps {
  secondLineData: ISecondLine[];
}

export default function SecondLine({ secondLineData }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();

  return !secondLineData?.length ? (
    <></>
  ) : (
    <>
      <TableRow
        col1Data={t("company")}
        col2Data={t("promoter")}
        col3Data={t("promoterScore")}
        header
      />
      <Spacing pt="20" />
      {secondLineData.map((company, index) => {
        return (
          <Fragment key={company.company_id + index}>
            <TableRow
              col1Data={
                <EntityInfo
                  entityId={company.company_id?.toString()}
                  entityType={EntityType.CORPORATE}
                  entityName={company.company_name}
                  ratingScore={company.affinity_score}
                  subTitle={formatIndustrySectorForTranslation(company.industry_sector, t)}
                  headcount={
                    company.employee_count ? abbreviateNumber(company.employee_count) : null
                  }
                  turnover={company.turnover ? abbreviateNumber(company.turnover) : null}
                />
              }
              col2Data={
                <>
                  <RText fontSize={isSmallerScreen ? "11" : "16"}>
                    {company.person_full_name}
                  </RText>
                  <Spacing pb="5" />
                  <RText fontSize={isSmallerScreen ? "11" : "16"}>
                    {company.position ?? ""}
                  </RText>
                </>
              }
              col3Data={
                <AnimatedCircleIcon
                  value={company.person_promoter_score}
                  showValuePercentage={true}
                  percentageContainerStyle={{
                    left: isSmallerScreen ? "45px" : "58px",
                    backgroundColor: colors.backgroundWhiteColor,
                    color: colors.textDarkColor,
                  }}
                  innerStyle={{
                    border: `1px solid ${colors.backgroundBlackColor}`,
                    width: "60%",
                    height: "60%",
                  }}
                  className={
                    isSmallerScreen
                      ? styles.scoreIconContainer__Responsive
                      : styles.scoreIconContainer
                  }
                  trailColor={
                    isDarkTheme ? "var(--color_gray_light)" : colors.primaryLightColor
                  }
                >
                  <RText fontSize={isSmallerScreen ? "16" : "20"}>
                    {company.person_promoter_score?.toString()}
                  </RText>
                </AnimatedCircleIcon>
              }
            />

            {index < secondLineData.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </>
  );
}
