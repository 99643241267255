import { Observable } from "rxjs";
import { IExecutive } from "./executive.model";
import { ExecutivesQuery } from "./executives.query";
import { ExecutivesStore } from "./executives.store";
import { IEntityTag } from "../../custom-tags/state/custom-tag.model";
import { CustomTagsFacade } from "../../custom-tags/state/custom-tags.facade";
import { IExecutiveNote } from "../../executive-notes/state/executive-notes.model";

export interface IExecutivesContext {
  executives$: Observable<IExecutive[]>;
  activeExecutive$: Observable<IExecutive>;

  getActiveId(): string;

  setActiveExecutive(id: string): void;

  addExecutives(entities: IExecutive[]): void;

  getExecutivesFromIds(ids: string[]): IExecutive[];

  addTagToExecutive(executiveId: string, entityTag: IEntityTag): void;

  removeTagFromExecutive(executiveId: string, entityTag: IEntityTag): void;

  addNoteToExecutive(note: IExecutiveNote): void;

  removeNoteFromExecutive(noteId: number): void;

  updateNoteForExecutive(updatedNote: IExecutiveNote): void;

  getExecutive(id: string): IExecutive;
}

export class ExecutivesFacade implements IExecutivesContext {
  readonly executives$ = this._query.selectAll();
  readonly activeExecutive$ = this._query.selectActive();

  constructor(
    private _store: ExecutivesStore,
    private _query: ExecutivesQuery,
    private _customTagsFacade: CustomTagsFacade,
  ) {}

  getActiveId(): string {
    return this._query.getActiveId();
  }

  setActiveExecutive(id: string): void {
    this._store.setActive(id);
  }

  addExecutives(entities: IExecutive[]): void {
    this._store.upsertMany(entities);
  }

  getExecutivesFromIds(ids: string[]): IExecutive[] {
    return (
      ids
        ?.map((id) => this._query.getEntity(id) ?? null)
        .filter((entity) => entity !== null) ?? null
    );
  }

  addTagToExecutive(executiveId: string, entityTag: IEntityTag) {
    const executive = this.getExecutivesFromIds([executiveId])[0];

    const tags = [...executive.tags, entityTag];

    this._store.update(executiveId, {
      tags,
    });
  }

  removeTagFromExecutive(executiveId: string, entityTag: IEntityTag) {
    const executive = this.getExecutivesFromIds([executiveId])[0];

    const filteredTags = executive.tags.filter((et) => et.id !== entityTag.id);

    this._store.update(executiveId, {
      tags: filteredTags,
    });
  }

  addNoteToExecutive(note: IExecutiveNote) {
    const executive = this.getExecutive(this._query.getActiveId());

    if (!executive) {
      return;
    }

    const notes = [note, ...executive.notes];

    this._store.update(executive.id, {
      notes,
    });
  }

  updateNoteForExecutive(updatedNote: IExecutiveNote) {
    const executive = this.getExecutive(this._query.getActiveId());

    if (!executive) {
      return;
    }

    const updatedNotes = executive.notes.map((note) => {
      if (note.id === updatedNote.id) {
        return updatedNote;
      }

      return note;
    });

    this._store.update(executive.id, {
      notes: updatedNotes,
    });
  }

  removeNoteFromExecutive(noteId: number) {
    const executive = this.getExecutive(this._query.getActiveId());

    if (!executive) {
      return;
    }

    const filteredNotes = executive.notes.filter((note) => note.id !== noteId);

    this._store.update(executive.id, {
      notes: filteredNotes,
    });
  }

  getExecutive(id: string) {
    return this._query.getEntity(id) ?? null;
  }
}
