import { Document, Page, View, Image, Text, StyleSheet, Link } from "@react-pdf/renderer";
import { IExecutive } from "../../../../entities/executives/state/executive.model";
import { MenuItem } from "./ExecutiveDossier";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
  page: {
    padding: "30px 40px 50px 40px",
    marginBottom: 70,
    fontFamily: "Open Sans",
  },
  textXsm: { fontSize: 7 },
  textSm: { fontSize: 8 },
  textMd: { fontSize: 10 },
  textLg: { fontSize: 16, fontWeight: "bold" },
  mtSm: { marginTop: 8 },
  mtMd: { marginTop: 16 },
  mtXlg: { marginTop: 64 },
  mbSm: { marginBottom: 8 },
  mbMd: { marginBottom: 16 },
  mbLg: { marginBottom: 32 },
  lhMd: { lineHeight: 1.5 },
  mrXs: { marginRight: 4 },
  mrSm: { marginRight: 8 },
  mrMd: { marginRight: 16 },
  fontBold: { fontWeight: "bold" },
  headline: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 8,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    position: "relative",
  },
  col: { width: "50%" },
  textRight: { textAlign: "right" },
  textCenter: { textAlign: "center" },
  inline: {
    display: "flex",
    flexDirection: "row",
  },
  link: {
    color: "black",
  },
  logo: {
    width: 70,
    marginBottom: 16,
  },
  externalIconLg: {
    height: 12,
    width: 12,
  },
  imgSm: {
    width: 12,
    height: 12,
  },
  blackDot: {
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#000",
    position: "relative",
  },
  line: {
    width: 70,
    height: 1,
    backgroundColor: "#000",
  },
  pageNumber: {
    position: "absolute",
    bottom: 32,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 8,
  },
  hw: { width: "50%" },
  oneFourth: { width: "25%" },
  threeFourth: { width: "75%" },
  externalLg: {
    width: 12,
    height: 12,
  },
});

interface Props {
  activeExecutive: IExecutive;
  menuItems: MenuItem[];
}

const PDFDocument = ({ activeExecutive, menuItems }: Props) => {
  const { t } = useTranslation();
  const age = new Date().getFullYear() - new Date(activeExecutive.date_of_birth).getFullYear();

  const { management, shareholder } = activeExecutive;
  const activeManagement = management ? management.filter((val) => !val.to_date) : [];
  const activeControllingInvestments = shareholder
    ? shareholder.filter((val) => !val.to_date && val.share_perc >= 50)
    : [];
  const activeMinoritiesInvestments = shareholder
    ? shareholder.filter((val) => !val.to_date && val.share_perc < 50)
    : [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mbMd}>
          <Image src="/pdf/move.png" style={styles.logo} />
          <View style={styles.flex}>
            <Text style={[styles.textLg, styles.mrMd]}>{activeExecutive.full_name}</Text>
            <Link
              src={`https://sales-hero.ai/home/search/executive-dossier/${activeExecutive.id}`}
              style={[styles.externalLg, styles.link]}
            >
              <Image src="/pdf/external-link.png" style={styles.externalIconLg} />
            </Link>
          </View>
          <View style={[styles.mtMd, styles.flex]}>
            <View style={styles.hw}>
              <View style={[styles.mbSm, styles.flex]}>
                <Image src="/pdf/DateIcon.png" style={[styles.imgSm, styles.mrXs]} />
                <Text style={styles.textSm}>
                  {activeExecutive.date_of_birth} | {age} {t("years")}
                </Text>
              </View>

              <View style={styles.mbSm}>
                <Text style={styles.textSm}>
                  {activeExecutive.management[0].position || "-"}
                </Text>
                <Text style={styles.textSm}>
                  {activeExecutive.management[0].company_name || "-"}
                </Text>
              </View>

              <View style={styles.mbSm}>
                <Text style={styles.textSm}>
                  {activeExecutive.management[0].street || ""},{" "}
                  {activeExecutive.management[0].zip_code || ""},{" "}
                  {activeExecutive.management[0].city || ""}
                </Text>
              </View>
            </View>

            <View style={[styles.hw, styles.textCenter]}>
              <Text style={styles.textMd}>{t("successScore")}</Text>
              <Text style={styles.textLg}>{activeExecutive.promoter_score}%</Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={[styles.headline]}>{t("insights")}</Text>
          <View style={styles.flex}>
            {menuItems.map(
              (item: MenuItem) =>
                item.counter && (
                  <View style={[styles.flex, styles.mbSm, styles.hw]}>
                    <Link
                      src={`https://sales-hero.ai/home/search/executive-dossier/${activeExecutive.id}#${item.anchor}`}
                      style={[styles.hw, styles.link]}
                    >
                      <View style={styles.flex}>
                        <Image
                          src={`/pdf/${item.title}.png`}
                          style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                        <Text style={[styles.threeFourth, styles.textSm]}>
                          {t(item.title)}
                        </Text>
                      </View>
                    </Link>
                    <Text style={[styles.oneFourth, styles.textSm]}>{item.counter}</Text>
                  </View>
                ),
            )}
          </View>
        </View>
        {activeManagement.length && (
          <View style={[styles.mtMd]}>
            <Text style={[styles.headline]}>{t("management")}</Text>
            {activeManagement.map((company) => (
              <View style={[styles.mbSm]}>
                <Text style={[styles.textSm, styles.fontBold]}>
                  {company.company_name}, {company.position}
                </Text>
                <Text style={[styles.textXsm]}>
                  {t("from")} {company.from_date}
                </Text>
              </View>
            ))}
          </View>
        )}
        {(activeControllingInvestments.length || activeMinoritiesInvestments.length) && (
          <View style={[styles.mtMd]}>
            <Text style={[styles.headline]}>{t("shareholder")}</Text>
            {activeControllingInvestments.length && (
              <Text style={[styles.headline]}>{t("controlling")}</Text>
            )}
            {activeControllingInvestments.length &&
              activeControllingInvestments.map((company) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>{company.company_name}</Text>
                  <Text style={[styles.textSm]}>{company.share_perc}%</Text>
                  <Text style={[styles.textSm]}>
                    {t("industry")}: {company.industry_sector}
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("shareSince")} {company.from_date}
                  </Text>
                </View>
              ))}
            {activeMinoritiesInvestments.length && (
              <Text style={[styles.headline]}>{t("minorities")}</Text>
            )}
            {activeMinoritiesInvestments.length &&
              activeMinoritiesInvestments.map((company) => (
                <View style={[styles.mbSm]}>
                  <Text style={[styles.textSm, styles.fontBold]}>{company.company_name}</Text>
                  <Text style={[styles.textSm]}>{company.share_perc}%</Text>
                  <Text style={[styles.textSm]}>
                    {t("industry")}: {company.industry_sector}
                  </Text>
                  <Text style={[styles.textXsm]}>
                    {t("shareSince")} {company.from_date}
                  </Text>
                </View>
              ))}
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDFDocument;
