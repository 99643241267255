import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { IExecutiveTag } from "./executive-tags.model";

export interface ExecutiveTagsState extends EntityState<IExecutiveTag> {}

@StoreConfig({
  name: "executive-tags",
})
export class ExecutiveTagsStore extends EntityStore<ExecutiveTagsState> {}

export const executiveTagsStore = new ExecutiveTagsStore();
