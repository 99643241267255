import React, { useContext } from "react";
import Divider from "../../../components/dividers/Divider/Divider";
import { Spacing } from "../../../components/spacing/component";
import { useCorporatesFilterWithSearchUIRows } from "../../../entities/corporates-filters/hooks/corporates-filter-with-ui-rows.hook";
import { CorporatesFiltersContext } from "../../../entities/corporates-filters/state/corporates-filters.context";
import FilterRow from "../filters/FilterRow/FilterRow";
import { CentralSearchContext } from "../HomePage";

const CorporatesFilters = () => {
  const { filterWitUIRows, toggleFilterRowExpanded } = useCorporatesFilterWithSearchUIRows();
  const { corporatesFiltersOpen } = useContext(CentralSearchContext);
  const corporatesFiltersContext = useContext(CorporatesFiltersContext);

  return (
    <>
      {corporatesFiltersOpen && (
        <>
          <Divider />
          {filterWitUIRows &&
            filterWitUIRows.map((filterRow, index) => {
              return (
                <React.Fragment key={index}>
                  <Spacing pv="20">
                    <FilterRow
                      key={index}
                      filterRowIndex={index}
                      filterRow={filterRow}
                      allFiltersExpanded={corporatesFiltersOpen}
                      selectFilterValue={(...args) =>
                        corporatesFiltersContext.selectSearchFilterValue(...args)
                      }
                      toggleRowExpanded={toggleFilterRowExpanded}
                    />
                  </Spacing>
                  <Divider />
                </React.Fragment>
              );
            })}
        </>
      )}
    </>
  );
};

export default CorporatesFilters;
