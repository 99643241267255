/**
 * Returns an array with arrays of the given size.
 *
 * Taken from: https://ourcodeworld.com/articles/read/278/how-to-split-an-array-into-chunks-of-the-same-size-easily-in-javascript
 * while loop is selected for best performance
 *
 * @param arrayToSplit
 * @param chunkSize - Size of every group
 */
import { FILTERS_PER_ROW } from "../constants/filters";
import { IFilter } from "../shared/interfaces/filter.interface";
import { IFilterRow } from "../shared/interfaces/filters-row.interface";
import { IQueryRange } from "../shared/interfaces/query-range.interface";
import { IQueryRanges } from "../shared/interfaces/query-ranges.interface";

export function chunkArray(chunkSize: number, arrayToSplit?: any[] | null) {
  if (!arrayToSplit) return;

  const results = [];

  let arrayCopy = [...arrayToSplit];
  while (arrayCopy.length) {
    results.push(arrayCopy.splice(0, chunkSize));
  }

  return results;
}

export function extractFiltersIntoRows(filters: IFilter[] | null): IFilterRow[] {
  if (!filters) return [];

  const filterRows: IFilterRow[] = [];

  let filtersCopy = [...filters];
  while (filtersCopy.length) {
    const filterRow: IFilterRow = {
      rowExpanded: true,
      filters: filtersCopy.splice(0, FILTERS_PER_ROW),
    };

    filterRows.push(filterRow);
  }

  return filterRows;
}

export const areArraysOfStringsEqual = (p: string[], q: string[]): boolean => {
  if (p.length !== q.length) {
    return false;
  } else {
    for (let i = 0; i < p.length; i++) {
      if (p[i] !== q[i]) return false;
    }
  }

  return true;
};

export interface IObjectWithArrayOfStringsProps {
  [key: string]: string[];
}

export const areObjectsWithArrayOfStringsPropsEqual = (
  p: IObjectWithArrayOfStringsProps,
  q: IObjectWithArrayOfStringsProps,
): boolean => {
  const pKeys = Object.keys(p);
  const qKeys = Object.keys(q);

  if (pKeys.length !== qKeys.length) {
    return false;
  } else {
    for (let i = 0; i < pKeys.length; i++) {
      if (!areArraysOfStringsEqual(p[pKeys[i]], q[qKeys[i]])) {
        return false;
      }
    }
  }

  return true;
};

const areArraysOfQueryRangesEqual = (p: IQueryRange[], q: IQueryRange[]): boolean => {
  if (p.length !== q.length) {
    return false;
  } else {
    for (let i = 0; i < p.length; i++) {
      if (p[i].from !== q[i].from || p[i].to !== q[i].to) return false;
    }
  }

  return true;
};

const areObjectsWithArrayOfQueryRangesPropsEqual = (
  p: IQueryRanges,
  q: IQueryRanges,
): boolean => {
  const pKeys = Object.keys(p);
  const qKeys = Object.keys(q);

  if (pKeys.length !== qKeys.length) {
    return false;
  } else {
    for (let i = 0; i < pKeys.length; i++) {
      if (!areArraysOfQueryRangesEqual(p[pKeys[i]], q[qKeys[i]])) {
        return false;
      }
    }
  }

  return true;
};

export const areArraysOfObjectsWithArrayOfQueryRangesPropsEqual = (
  p: IQueryRanges[],
  q: IQueryRanges[],
): boolean => {
  if (p.length !== q.length) {
    return false;
  } else {
    for (let i = 0; i < p.length; i++) {
      if (!areObjectsWithArrayOfQueryRangesPropsEqual(p[i], q[i])) {
        return false;
      }
    }
  }

  return true;
};

export const removeDuplicateObjects = (objectArray: any[], property: string) => {
  return objectArray.filter((obj, index, arr) => {
    return arr.map((mapObj) => mapObj[property]).indexOf(obj[property]) === index;
  });
};
