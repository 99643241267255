import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ILastVisitedDossier } from "./last-dossier.model";

export interface LastDossierState extends EntityState<ILastVisitedDossier> {}

@StoreConfig({
  name: "last-dossier",
})
export class LastDossierStore extends EntityStore<LastDossierState> {}

export const lastDossierStore = new LastDossierStore();
