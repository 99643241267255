import { Observable } from "rxjs";
import { CorporatesApiService } from "../../../api/services/corporates-api.service";
import { ICorporate } from "../models/corporate.model";
import { CorporatesQuery } from "./corporates.query";
import { CorporatesStore } from "./corporates.store";
import { CustomTagsFacade } from "../../custom-tags/state/custom-tags.facade";
import { IEntityTag } from "../../custom-tags/state/custom-tag.model";
import { ICorporateNote } from "../../corporate-notes/state/corporate-notes.model";

export interface ICorporatesContext {
  corporates$: Observable<ICorporate[]>;
  activeCorporate$: Observable<ICorporate>;

  getActiveId(): string;

  setActiveCorporate(id: string): void;

  addCorporates(corporates: ICorporate[]): void;

  getCorporatesFromIds(ids: string[]): ICorporate[];

  addTagToCorporate(corporateId: string, entityTag: IEntityTag): void;

  removeTagFromCorporate(corporateId: string, entityTag: IEntityTag): void;

  addNoteToCorporate(note: ICorporateNote): void;

  removeNoteFromCorporate(noteId: number): void;

  updateNoteForCorporate(updatedNote: ICorporateNote): void;

  getCorporate(id: string): ICorporate;
}

export class CorporatesFacade implements ICorporatesContext {
  readonly corporates$ = this._query.selectAll();
  readonly activeCorporate$ = this._query.selectActive();

  constructor(
    private _store: CorporatesStore,
    private _query: CorporatesQuery,
    private _corporatesApiService: CorporatesApiService,
    private _customTagsFacade: CustomTagsFacade,
  ) {}

  getActiveId(): string {
    return this._query.getActiveId();
  }

  addTagToCorporate(corporateId: string, entityTag: IEntityTag) {
    const corporate = this.getCorporatesFromIds([corporateId])[0];
    if (corporate) {
      const tags = [...corporate.tags, entityTag];

      this._store.update(corporateId, {
        tags,
      });
    }
  }

  removeTagFromCorporate(corporateId: string, entityTag: IEntityTag) {
    const corporate = this.getCorporatesFromIds([corporateId])[0];
    if (corporate) {
      const filteredTags = corporate.tags.filter((et) => et.id !== entityTag.id);

      this._store.update(corporateId, {
        tags: filteredTags,
      });
    }
  }

  addNoteToCorporate(note: ICorporateNote) {
    const corporate = this.getCorporate(this._query.getActiveId());

    if (!corporate) {
      return;
    }

    const notes = [note, ...corporate.notes];

    this._store.update(corporate.id, {
      notes,
    });
  }

  updateNoteForCorporate(updatedNote: ICorporateNote) {
    const corporate = this.getCorporate(this._query.getActiveId());

    if (!corporate) {
      return;
    }

    const updatedNotes = corporate.notes.map((note) => {
      if (note.id === updatedNote.id) {
        return updatedNote;
      }

      return note;
    });

    this._store.update(corporate.id, {
      notes: updatedNotes,
    });
  }

  removeNoteFromCorporate(noteId: number) {
    const corporate = this.getCorporate(this._query.getActiveId());

    if (!corporate) {
      return;
    }

    const filteredNotes = corporate.notes.filter((note) => note.id !== noteId);

    this._store.update(corporate.id, {
      notes: filteredNotes,
    });
  }

  setActiveCorporate(id: string): void {
    this._store.setActive(id);
  }

  addCorporates(corporates: ICorporate[]): void {
    this._store.upsertMany(corporates);
  }

  getCorporatesFromIds(ids: string[]): ICorporate[] {
    return (
      ids
        ?.map((id) => this._query.getEntity(id) ?? null)
        .filter((entity) => entity !== null) ?? null
    );
  }

  getCorporate(id: string) {
    return this._query.getEntity(id) ?? null;
  }
}
