import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { SessionContext } from "../state/akita/session/session.context";

const useTagColors = () => {
  const sessionContext = useContext(SessionContext);
  const [tagColors] = useObservable(sessionContext.tagColors$, sessionContext.getTagColors());

  return { tagColors };
};

export default useTagColors;
