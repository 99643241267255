import BlackMinus from "./BlackMinus.svg";
import BluePlus from "./BluePlus.svg";
import LookALikeNoBorder from "./LookALikeNoBorder.svg";
import LookALikeWithBorder from "./LookALikeWithBorder.svg";
import LookALikeMinus from "./LookALikeMinus.svg";
import LookALikePlus from "./LookALikePlus.svg";

export {
  BlackMinus,
  BluePlus,
  LookALikeNoBorder,
  LookALikeWithBorder,
  LookALikeMinus,
  LookALikePlus,
};
