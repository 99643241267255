import React from "react";
import { ITag } from "../../../entities/custom-tags/state/custom-tag.model";
import styles from "./styles.module.css";

interface IProps {
  tags: ITag[];
  isSearchResult?: boolean;
}

const CorporatesSelectionTags = ({ tags, isSearchResult = false }: IProps) => {
  const selectionTags = tags.filter((t) => t && t?.type === "selection");

  if (selectionTags.length === 0) {
    return null;
  }

  if (isSearchResult) {
    return (
      <span>
        {selectionTags.map((tag) => (
          <div
            style={{
              display: "inline-flex",
              marginLeft: 5,
            }}
            key={tag.id}
          >
            <div className={styles.tag} style={{ backgroundColor: tag.color_code }} />
            <div
              className={styles.tag}
              style={{
                backgroundColor: tag.color_code,
                marginLeft: -5,
                opacity: 0.5,
              }}
            />
          </div>
        ))}
      </span>
    );
  }

  return (
    <span>
      <div
        style={{
          display: "inline-flex",
          marginLeft: 5,
        }}
      >
        <div
          className={styles.tag}
          style={{ backgroundColor: "var(--color_background_black)" }}
        />
        <div
          className={styles.tag}
          style={{
            backgroundColor: "var(--color_background_white)",
            border: "1px solid var(--color_background_black)",
            marginLeft: -5,
          }}
        />
      </div>
    </span>
  );
};

export default CorporatesSelectionTags;
