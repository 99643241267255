import React, { useContext, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { GeoJSON, MapContainer as OpenStreetMap, Marker, ZoomControl } from "react-leaflet";
import styles from "./styles.module.css";
import MapFullScreenIcon from "../../../../../../assets/MapFullScreenIcon.svg";
import RouteCarIcon from "../../../../../../assets/hub/map-icons/RouteCarIcon.svg";
import CloseIcon from "../../../../../../assets/CloseIcon.svg";
import { OPENROUTESERVICE_API_KEY } from "../../../../../../constants/urls";
import { getIconByName } from "../../../../../../assets/hub/map-icons/icons";
import L, { LatLngBoundsLiteral } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { useSession } from "../../../../../../hooks/session.hook";
import axios, { AxiosResponse } from "axios";
import HubMarkerPopup from "../../../../../hub/HubMain/HubMarkerPopup/HubMarkerPopup";
import { StyledPopup } from "./StyledPopup";
import { ICorporateMarkerData } from "../../../../../../entities/corporates/interfaces/corporate-marker-data.interface";
import MapCustomControl from "./MapCustomControl";
import MapTransportLayer from "./map-layers/MapTransportLayer";
import MapSatelliteLayer from "./map-layers/MapSatelliteLayer";
import { Spacing } from "../../../../../../components/spacing/component";
import SatelliteLayerToggle from "./map-controls/SatelliteLayerToggle";
import Spinner from "../../../../../../components/other/Spinner/Spinner";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import {
  IRouteResponse,
  IRouteSummary,
} from "../../../../../../entities/map-routing/models/route-response.model";
import RText from "../../../../../../components/fonts/RText/RText";
import { ICorporate } from "../../../../../../entities/corporates/models/corporate.model";
import {
  LookALikeNoBorder,
  LookALikeWithBorder,
} from "../../../../../../assets/company-dossier/map/icons";
import { useOpenFullscreenMapModal } from "../../../../../../hooks/corporates.hook";
import { mapCorporateIntoCorporateMarkerData } from "../../../../../../modules/hub/hooks/hub-markers.hook";
import {
  areCoordinatesDefault,
  calculateMapCenterForExecutive,
  calculateMapCenterFromCompanyCoordinates,
} from "../../../../../../helpers/map.helper";
import { ROUTE_COLOR } from "../../../../../../constants/map";
import { ICoordinates } from "../../../../../../shared/interfaces/coordinates.interface";
import { IExecutive } from "../../../../../../entities/executives/state/executive.model";
import { useOpenExecutiveFullscreenMapModal } from "../../../../../../entities/executive-dossier/hooks/executive-dossier.hook";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import ExecutivesHubMarkerPopup from "../../../../../executives-hub/ExecutivesHubMain/ExecutivesHubMarkerPopup/ExecutivesHubMarkerPopup";
import { mapExecutiveIntoExecutiveMarkerData } from "../../../../../../modules/executives-hub/hooks/executives-hub-markers.hook";
import { createDefaultPurpleIcon } from "../../../../../../helpers/mapMarker.helper";
import { useMediaQuery, useTheme } from "@mui/material";

interface IProps {
  locationCoordinates: ICoordinates;
  companyAddress?: string;
  corporateMarkerData: ICorporateMarkerData;
  corporateLookALikes?: ICorporate[];
  executiveLookALikes?: IExecutive[];
  satelliteLayer: boolean;
  toggleSatelliteLayer: () => void;
  corporate?: ICorporate;
}

const provider = new OpenStreetMapProvider();

export default function FullScreenMapModal({
  locationCoordinates,
  companyAddress,
  corporateMarkerData,
  corporateLookALikes,
  executiveLookALikes,
  satelliteLayer,
  toggleSatelliteLayer,
  corporate,
}: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const [route, setRoute] = useState(null);
  const [bounds, setBounds] = useState<LatLngBoundsLiteral>(null);
  const [start, setStart] = useState<ICoordinates>(null);
  const [waypoints, setWaypoints] = useState<{ [key: string]: number[] }>({});
  const [routeInfo, setRouteInfo] = useState<IRouteSummary>(null);
  const { currentUser, isB2CUser } = useSession();
  const isMounted = useRef(true);
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const calculateZoom = () => {
    // When we have no exact coordinates, make the map more zoomed out
    if (areCoordinatesDefault(locationCoordinates)) {
      return 6;
    }

    // Otherwise zoom into the company
    return 16;
  };

  useEffect(() => {
    getEndCoordinates(corporateMarkerData?.corporateId, companyAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!start) {
      getStartCoordinates();
    } else {
      generateRoute();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, waypoints]);

  // TODO: don't remove yet, we might need it to place custom element
  //  on these coordinates
  // /**
  //  * Selects the middle point of the route geometry
  //  * Used to position the route info approximately at
  //  * the middle of the route
  //  */
  // const calculateRouteMiddlePoint = (geometry: IRouteGeometry) => {
  //     const middlePointIndex = Math.floor(geometry.coordinates.length / 2);
  //     const middlePoint = geometry.coordinates[middlePointIndex];
  // };

  const getStartCoordinates = () => {
    if (!currentUser) {
      return;
    }

    provider
      .search({
        query: currentUser.address,
      })
      .then((geoSearchRes) => {
        const res = geoSearchRes[0];

        if (!res.raw) return;
        if (!isMounted.current) return;

        setStart({
          lat: Number.parseFloat(res.raw.lat),
          lng: Number.parseFloat(res.raw.lon),
        });
      })
      .catch((err) => console.warn(err));
  };

  const getEndCoordinates = (corporateId: string, corporateAddress: string) => {
    if (!corporateId || !corporateAddress) {
      setBounds([
        [locationCoordinates.lat, locationCoordinates.lng],
        [locationCoordinates.lat, locationCoordinates.lng],
      ]);

      return;
    }

    provider
      .search({
        query: corporateAddress,
      })
      .then((geoSearchRes) => {
        const res = geoSearchRes[0];

        if (!res.raw) return;
        if (!isMounted.current) return;

        setWaypoints((state) => ({
          ...state,
          [corporateId]: [Number.parseFloat(res.raw.lon), Number.parseFloat(res.raw.lat)],
        }));
      })
      .catch((err) => {
        if (!isMounted.current) return;
        setBounds([
          [locationCoordinates.lat, locationCoordinates.lng],
          [locationCoordinates.lat, locationCoordinates.lng],
        ]);
        console.warn("Error fetching destination coordinates", err);
      });
  };

  // const getExecutiveEndCoordinates = (
  //     lat: number,
  //     lng: number,
  //     executiveId: string
  // ) => {
  //     setWaypoints((state) => ({
  //         ...state,
  //         [executiveId]: [lng, lat],
  //     }));
  // };

  const generateRoute = () => {
    if (!start || !Object.keys(waypoints).length) {
      return;
    }

    setRoute(null);

    // TODO check why this call doesn't seem to get executed
    // const routeRes = await routingApiService.fetchRoute({
    //     coordinates: [
    //         [start.lng, start.lat],
    //         [end.lng, end.lat],
    //     ],
    // });

    axios
      .post(
        "https://api.openrouteservice.org/v2/directions/driving-car/geojson",
        {
          coordinates: [[start.lng, start.lat], ...Object.values(waypoints)],
        },
        {
          headers: {
            Authorization: OPENROUTESERVICE_API_KEY,
          },
        },
      )
      .then((routeRes: AxiosResponse<IRouteResponse>) => {
        if (!isMounted.current) return;
        const { bbox, geometry, properties } = routeRes.data.features[0];

        const bboxBounds = [bbox.slice(2, 4).reverse(), bbox.slice(0, 2).reverse()];

        setBounds(bboxBounds as LatLngBoundsLiteral);
        setRoute(geometry);
        setRouteInfo(properties.summary);
        // calculateRouteMiddlePoint(geometry); TODO: check method for explanation
      })
      .catch((err) => {
        if (!isMounted.current) return;
        if (!locationCoordinates) return;
        setBounds([
          [locationCoordinates.lat, locationCoordinates.lng],
          [locationCoordinates.lat, locationCoordinates.lng],
        ]);
        console.warn("Error calculating route", err);
      });
  };

  const getStartIcon = () => {
    return new L.Icon({
      iconUrl: getIconByName("STARTING_MARKER"),
      iconSize: new L.Point(30, 50),
    });
  };

  const getDestinationIcon = () => {
    // return new L.Icon({
    //     iconUrl: getIconByName("PURPLE_M_MARKER_L_STAR"),
    //     iconSize: new L.Point(70, 80),
    // });
    return createDefaultPurpleIcon();
  };

  const distanceToReadableString = (distanceInMeters: number) => {
    if (distanceInMeters < 1000) {
      return distanceInMeters.toFixed(0) + " m";
    }

    const kilometers = distanceInMeters / 1000;
    return kilometers.toFixed(2) + " km";
  };

  const durationToReadableString = (durationInSeconds: number) => {
    const minutes = durationInSeconds / 60;
    if (minutes < 60) {
      return minutes.toFixed(0) + " min.";
    }

    const hours = minutes / 60;
    return hours.toFixed(2) + " h";
  };

  const [toggleCorporateLookaLikes, setToggleCorporateLookaLikes] = useState(true);
  const onToggleCorporateLookALikes = () => {
    if (toggleCorporateLookaLikes) {
      /**
       * If user already added some look a like companies to route and after that he removed them from the map,
       * we want to show default route.
       */
      Object.keys(waypoints).length > 1 &&
        setWaypoints({
          [corporateMarkerData.corporateId]: waypoints[corporateMarkerData.corporateId],
        });
    }

    setToggleCorporateLookaLikes((toggle) => !toggle);
  };

  const [toggleExecutiveLookaLikes, setToggleExecutiveLookaLikes] = useState(true);
  const onToggleExecutiveLookALikes = () => setToggleExecutiveLookaLikes((toggle) => !toggle);

  const { openFullscreenMapModal, setOpenFullscreenMapModal } = useOpenFullscreenMapModal();

  const { openExecutiveFullscreenMapModal, setOpenExecutiveFullscreenMapModal } =
    useOpenExecutiveFullscreenMapModal();

  return (
    <Popup
      trigger={<img src={MapFullScreenIcon} alt="" className={styles.fullScreenIcon} />}
      open={openFullscreenMapModal || openExecutiveFullscreenMapModal}
      modal
      overlayStyle={{ zIndex: 1111 }}
      contentStyle={{
        width: "90%",
        height: "80vh",
        border: "none",
        borderRadius: "unset",
        padding: "0px",
      }}
      onClose={() => {
        setOpenFullscreenMapModal(false);
        setOpenExecutiveFullscreenMapModal(false);

        // Remove modal element from DOM after closing
        document.getElementById("popup-root")?.remove();
      }}
    >
      {(close: any) =>
        !bounds ? (
          <Flex justifyContentCenter alignItemsCenter>
            <Spinner />
          </Flex>
        ) : (
          <OpenStreetMap
            // center={locationCoordinates}
            zoom={calculateZoom()}
            minZoom={3}
            zoomControl={false}
            style={{ height: "100%", width: "100%" }}
            bounds={bounds}
            tap={false}
          >
            {upMd && <ZoomControl position="bottomright" />}
            {satelliteLayer ? <MapSatelliteLayer /> : <MapTransportLayer />}
            <MapCustomControl
              position="topright"
              containerProps={{
                style: {
                  marginTop: "5px",
                },
              }}
            >
              <div className={`${styles.controlsContainer}`}>
                {routeInfo && (
                  <div className={styles.routeInfo}>
                    <img className={styles.routeInfoIcon} src={RouteCarIcon} alt="" />

                    {/* <Spacing pl="8" /> */}

                    <RText immutableBlack bold fontSize="12">
                      {distanceToReadableString(routeInfo.distance)}
                    </RText>

                    {/* <Spacing pl="4" /> */}

                    <RText immutableBlack bold fontSize="12">
                      |
                    </RText>

                    {/* <Spacing pl="4" /> */}

                    <RText immutableBlack bold fontSize="12">
                      {durationToReadableString(routeInfo.duration)}
                    </RText>
                  </div>
                )}

                <Spacing pl="10" />
                <SatelliteLayerToggle onClick={toggleSatelliteLayer} active={satelliteLayer} />
                <Spacing pl="10" />

                {corporateLookALikes && (
                  <>
                    <div
                      className={styles.toggleLookALikesContainer}
                      onClick={onToggleCorporateLookALikes}
                    >
                      <img
                        src={
                          toggleCorporateLookaLikes ? LookALikeWithBorder : LookALikeNoBorder
                        }
                        alt=""
                      />
                    </div>
                    <Spacing pl="10" />
                  </>
                )}

                {isB2CUser && executiveLookALikes && (
                  <>
                    <div
                      className={styles.toggleLookALikesContainer}
                      onClick={onToggleExecutiveLookALikes}
                    >
                      <img
                        src={
                          toggleExecutiveLookaLikes ? LookALikeWithBorder : LookALikeNoBorder
                        }
                        alt=""
                      />
                    </div>
                    <Spacing pl="10" />
                  </>
                )}

                <div
                  className={`${styles.control} ${styles.closeModalContainer}`}
                  onClick={() => close()}
                >
                  <img src={CloseIcon} alt="" className={styles.closeModalIcon} />
                </div>
              </div>
            </MapCustomControl>
            {route && <GeoJSON pathOptions={{ color: ROUTE_COLOR }} data={route} />}
            {start && <Marker position={start} icon={getStartIcon()} />}
            {toggleCorporateLookaLikes &&
              corporateLookALikes?.map((corporate) => {
                return (
                  <Marker
                    key={corporate.id}
                    position={calculateMapCenterFromCompanyCoordinates(corporate)}
                    icon={
                      new L.Icon({
                        iconUrl: LookALikeWithBorder,
                        iconSize: new L.Point(40, 50),
                      })
                    }
                  >
                    <StyledPopup isSmallerScreen={isSmallerScreen}>
                      <HubMarkerPopup
                        markerCorporates={[mapCorporateIntoCorporateMarkerData(corporate)]}
                        isCorporateLookALike={true}
                        addToRouteData={{
                          waypoints,
                          getEndCoordinates,
                        }}
                      />
                    </StyledPopup>
                  </Marker>
                );
              })}
            {isB2CUser &&
              toggleExecutiveLookaLikes &&
              executiveLookALikes?.map((executive) => {
                return (
                  <Marker
                    key={executive.id}
                    position={calculateMapCenterForExecutive(executive, corporate)}
                    icon={
                      new L.Icon({
                        iconUrl: LookALikeWithBorder,
                        iconSize: new L.Point(40, 50),
                      })
                    }
                  >
                    <StyledPopup isSmallerScreen={isSmallerScreen}>
                      <ExecutivesHubMarkerPopup
                        markerExecutives={[mapExecutiveIntoExecutiveMarkerData(executive)]}
                        isExecutiveLookALike={true}
                        // addToRouteData={{
                        //     waypoints,
                        //     getExecutiveEndCoordinates,
                        // }}
                      />
                    </StyledPopup>
                  </Marker>
                );
              })}
            <Marker position={locationCoordinates} icon={getDestinationIcon()}>
              <StyledPopup isSmallerScreen={isSmallerScreen}>
                {corporateMarkerData && (
                  <HubMarkerPopup markerCorporates={[corporateMarkerData]} />
                )}
              </StyledPopup>
            </Marker>
          </OpenStreetMap>
        )
      }
    </Popup>
  );
}
