import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import RText from "../fonts/RText/RText";
import { Spacing } from "../spacing/component";
import { Flex } from "../containers/Flex/Flex";
import { ITag } from "../../entities/custom-tags/state/custom-tag.model";
import CreateTag from "./create-tag/CreateTag";
import useAppColors from "../../hooks/app-colors.hook";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../hooks/session.hook";
import { ReactComponent as AddIcon } from "../../assets/AddIcon.svg";
import tagsStyles from "./styles.module.css";
import { useUserTags } from "../../entities/custom-tags/hooks/user-tags.hook";
import { useCloseOnClickOutside } from "../../hooks/componentRefs.hook";

interface IProps {
  tag: ITag;
  onSetTag: (tag: ITag) => void;
}

const SingleTag = ({ tag, onSetTag }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();
  const { t } = useTranslation();

  const { userTags } = useUserTags();
  const [toggleTags, setToggleTags] = useState(false);

  const onToggleTags = () => {
    setToggleTags((visible) => !visible);
  };

  const handleSetTag = (newTag: ITag) => {
    onSetTag(newTag);
    onToggleTags();
  };

  const [ref] = useCloseOnClickOutside(
    () => {
      setTimeout(() => {
        onToggleTags();
      }, 0);
    },
    { capture: true },
  );

  return (
    <Flex style={{ position: "relative" }}>
      <Flex column pointer onClick={onToggleTags}>
        <Flex alignItemsCenter style={{ minWidth: 40 }}>
          {!tag ? (
            <Flex
              style={{
                border: "1px solid var(--color_background_black)",
              }}
              className={`${styles.defaultTag} ${
                isSmallerScreen && styles.defaultTag__Responsive
              }`}
              alignItemsCenter
              justifyContentCenter
            >
              <AddIcon color={colors.backgroundBlackColor} className={styles.addIcon} />
            </Flex>
          ) : (
            <div
              className={tagsStyles.defaultTag}
              style={{
                backgroundColor: tag.color_code,
              }}
            />
          )}
        </Flex>
        <Spacing pt="8" />
        <RText fontSize={isSmallerScreen ? "11" : "15"} className={styles.tagCompanyTitle}>
          {`${t("tag")}...`}
        </RText>
      </Flex>
      {toggleTags && (
        <Flex
          elementRef={ref}
          column
          style={{
            background: isDarkTheme
              ? "var(--immutable_background_black)"
              : "var(--immutable_background_white)",
            borderColor: isDarkTheme
              ? "var(--immutable_background_black)"
              : "var(--color_gray_dark)",
          }}
          className={styles.tagPopup}
        >
          <Flex alignItemsCenter className={styles.userTagsContentContainer}>
            <CreateTag createTag={handleSetTag} />
          </Flex>
          <Flex
            column
            style={{
              maxHeight: 300,
              overflow: "auto",
            }}
          >
            {userTags.map((tag: ITag) => {
              return (
                <Fragment key={tag.id}>
                  <Flex
                    alignItemsCenter
                    justifyContentSpaceBetween
                    className={styles.usersTagMainContainer}
                  >
                    <Flex
                      alignItemsCenter
                      pointer
                      className={tagsStyles.userTagsContentContainer}
                      onClick={() => handleSetTag(tag)}
                    >
                      <div
                        className={tagsStyles.defaultTag}
                        style={{ backgroundColor: tag.color_code }}
                      />
                      <Spacing pr="15" />
                      <RText fontSize="13">
                        {tag.name === "Client" ? t("client") : tag.name}
                      </RText>
                    </Flex>
                  </Flex>
                </Fragment>
              );
            })}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(SingleTag);
