import React, { useContext } from "react";
import { Flex } from "../../../components/containers/Flex/Flex";
import { Spacing } from "../../../components/spacing/component";
import CorporatesFilters from "./CorporatesFilters";
import CorporatesFiltersHeader from "./CorporatesFiltersHeader";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";

const CorporatesFiltersPanel = () => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex column>
      <CorporatesFiltersHeader />

      <Spacing pt={isSmallerScreen ? "12" : "24"} />

      <CorporatesFilters />
    </Flex>
  );
};

export default CorporatesFiltersPanel;
