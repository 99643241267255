import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as NavIconInterests } from "../../../assets/NavIconInterests.svg";
import { useInterestsSearchHits } from "../../../entities/interests-filters/hooks/interests-total-count.hook";
import FiltersHeader from "../filters/FiltersHeader/FiltersHeader";

const InterestsFiltersHeader = () => {
  const { t } = useTranslation();
  const { totalCount } = useInterestsSearchHits();

  return (
    <FiltersHeader
      title={t("interests")}
      Icon={NavIconInterests}
      resultsCount={totalCount}
      onMyFiltersClick={null}
      onClearAllClick={() => null}
      filtersOpened={null}
    />
  );
};

export default InterestsFiltersHeader;
