export interface IEmployeeCountsHistoric {
  year: number;
  count: number;
}

export function createEmployeeCountsHistoric(params: Partial<IEmployeeCountsHistoric>) {
  return {
    year: null,
    count: null,
    ...params,
  } as IEmployeeCountsHistoric;
}
