import ApiService from "./api.service";
import { IUploadContactResponse } from "../interfaces/upload-contact-response.interface";

class UploadApiService extends ApiService {
  public uploadContact(
    file: File,
    type: string,
    tag: number,
  ): Promise<IUploadContactResponse> {
    const data = new FormData();
    data.append("file", file);
    return this.post(`/file-upload-tagging/${type}/${tag}`, data);
  }
}

export const uploadApiServiceService = new UploadApiService();
