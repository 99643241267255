import { QueryEntity } from "@datorama/akita";
import {
  CorporateTagsState,
  CorporateTagsStore,
  corporateTagsStore,
} from "./corporate-tags.store";

export class CorporateTagsQuery extends QueryEntity<CorporateTagsState> {
  constructor(protected store: CorporateTagsStore) {
    super(store);
  }
}

export const corporateTagsQuery = new CorporateTagsQuery(corporateTagsStore);
