import { createContext } from "react";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { corporatesFacade } from "../../corporates/state/corporates.context";
import {
  CorporatesFiltersFacade,
  ICorporatesFiltersContext,
} from "./corporates-filters.facade";
import { corporatesFiltersQuery } from "./corporates-filters.query";
import { corporatesFiltersStore } from "./corporates-filters.store";

export const corporatesFiltersFacade = new CorporatesFiltersFacade(
  corporatesFiltersStore,
  corporatesFiltersQuery,
  filtersApiService,
  corporatesFacade,
);

export const CorporatesFiltersContext =
  createContext<ICorporatesFiltersContext>(corporatesFiltersFacade);
