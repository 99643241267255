import React, { FC, ReactNode, useContext } from "react";
import { ReactComponent as HeadcountPeopleIcon } from "../../../../../assets/executive-dossier/HeadcountPeopleIcon.svg";
import { ReactComponent as TurnoverArrowIcon } from "../../../../../assets/executive-dossier/TurnoverArrowIcon.svg";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../components/spacing/component";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../hooks/session.hook";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  headcount: string;
  turnover: string;
}

export default function HeadcountTurnoverCount({ headcount, turnover }: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme } = useSession();
  const { colors } = useAppColors();

  const CountContainer: FC<{ children: ReactNode }> = ({ children }) => (
    <Flex
      alignItemsCenter
      style={{
        padding: "0px 10px",
        height: isSmallerScreen ? "20px" : "30px",
        backgroundColor: isDarkTheme ? "var(--immutable_gray_dark)" : colors.primaryLightColor,
      }}
    >
      {children}
    </Flex>
  );

  return (
    <Flex alignItemsCenter>
      {headcount && (
        <>
          <CountContainer>
            <HeadcountPeopleIcon
              color={
                isDarkTheme ? "var(--color_gray_light)" : "var(--immutable_background_black)"
              }
              width={isSmallerScreen ? "18px" : "22px"}
              height={isSmallerScreen ? "18px" : "22px"}
            />
            <Spacing pr="15" />
            <RText fontSize={isSmallerScreen ? "12" : "15"}>{headcount}</RText>
          </CountContainer>
          <Spacing pr="5" />
        </>
      )}
      {turnover && (
        <CountContainer>
          <TurnoverArrowIcon
            color={
              isDarkTheme ? "var(--color_gray_light)" : "var(--immutable_background_black)"
            }
            width={isSmallerScreen ? "22px" : "30px"}
            height={isSmallerScreen ? "22px" : "30px"}
          />
          <Spacing pr="15" />
          <RText fontSize={isSmallerScreen ? "12" : "15"}>{turnover}</RText>
        </CountContainer>
      )}
    </Flex>
  );
}
