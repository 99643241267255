import { useContext } from "react";
import { CorporatesFiltersContext } from "../entities/corporates-filters/state/corporates-filters.context";
import { ExecutivesFiltersContext } from "../entities/executives-filters/state/executives-filters.context";
import { InterestsFiltersContext } from "../entities/interests-filters/state/interests-filters.context";
import { OpportunitiesFiltersContext } from "../entities/opportunities-filters/state/opportunities-filters.context";
import { StartupsFiltersContext } from "../entities/startups-filters/state/startups-filters.context";

export type UsePerformCentralSearchHook = [(searchString: string) => void];

export function usePerformCentralSearch(): UsePerformCentralSearchHook {
  const corporatesFiltersService = useContext(CorporatesFiltersContext);
  const executivesFiltersService = useContext(ExecutivesFiltersContext);
  const interestsFiltersService = useContext(InterestsFiltersContext);
  const opportunitiesFiltersService = useContext(OpportunitiesFiltersContext);
  const startupsFiltersService = useContext(StartupsFiltersContext);

  const performCentralSearch = async (searchTerm: string) => {
    await corporatesFiltersService.search(searchTerm);
    await executivesFiltersService.search(searchTerm);
    await interestsFiltersService.search(searchTerm);
    await opportunitiesFiltersService.search(searchTerm);
    await startupsFiltersService.search(searchTerm);

    // Fetch all filters on every search
    await corporatesFiltersService.fetchCorporatesFilters();
    await executivesFiltersService.fetchExecutivesFilters();
  };

  return [performCentralSearch];
}
