import { EntityUIQuery, QueryEntity } from "@datorama/akita";
import {
  InterestsFiltersState,
  interestsFiltersStore,
  InterestsFiltersStore,
  InterestsFiltersUIState,
} from "./interests-filters.store";

export class InterestsFiltersQuery extends QueryEntity<InterestsFiltersState> {
  ui: EntityUIQuery<InterestsFiltersUIState>;

  constructor(protected store: InterestsFiltersStore) {
    super(store);
    this.createUIQuery();
  }
}

export const interestsFiltersQuery = new InterestsFiltersQuery(interestsFiltersStore);
