import { Observable, Subscription } from "rxjs";
import { IExecutive } from "../../../entities/executives/state/executive.model";
import {
  ICompaniesExecutivesMapper,
  IExecutiveHubFooterItemData,
  IExecutiveHubFooterItemMapper,
} from "../../../shared/interfaces/hub-footer-item-data.interface";
import { ExecutivesHubStore } from "./executives-hub.store";
import { ExecutivesHubQuery } from "./executives-hub.query";
import { distinctUntilChanged, map, skip, tap } from "rxjs/operators";
import {
  areArraysOfObjectsWithArrayOfQueryRangesPropsEqual,
  areArraysOfStringsEqual,
  areObjectsWithArrayOfStringsPropsEqual,
  IObjectWithArrayOfStringsProps,
} from "../../../helpers/array.helper";
import { IExecutivesFiltersContext } from "../../../entities/executives-filters/state/executives-filters.facade";
import { IExecutivesContext } from "../../../entities/executives/state/executives.facade";
import { IHubApiService } from "../../../api/services/hub-api.service";
import { FiltersApiService } from "../../../api/services/filters-api.service";
import { combineQueries } from "@datorama/akita";
import { IDocCounts } from "../../../api/interfaces/doc-counts.interface";
import { isExecutiveClient, isExecutiveLead } from "../../../utils/corporates-filters.util";
import { IQueryRanges } from "../../../shared/interfaces/query-ranges.interface";
import { getSelectedFilters } from "../../../helpers/filters.helper";
import { ITopNews } from "../../../entities/corporates/interfaces/top-news.interface";
import { getExecutiveActiveCompany } from "../../../helpers/dossier.helper";
import { IExecutiveHubMarker } from "../../../entities/corporates/interfaces/hub-marker.interface";
import { mapExecutivesToHubMarkers } from "../hooks/executives-hub-markers.hook";
import { IHubMapExecutiveMarkers } from "../../../entities/executives/interfaces/executive-marker-data.interface";

export interface IExecutivesHubContext {
  firstLoad$: Observable<boolean>;
  hubExecutiveMarkers$: Observable<IExecutiveHubMarker[]>;
  loadingHubExecutives$: Observable<boolean>;
  hubErrorNoData$: Observable<boolean>;

  hubRolloverExecutiveId$: Observable<string>;

  hubNewsFooterItems$: Observable<IExecutiveHubFooterItemData[] | null>;
  loadingTopNews$: Observable<boolean>;

  hubLeadsFooterItems$: Observable<IExecutiveHubFooterItemData[] | null>;

  executivesPBIds$: Observable<string[]>;
  executivesHNWIIds$: Observable<string[]>;

  unfilteredHubExecutivesCount$: Observable<number>;

  setHubRolloverExecutive(executiveId: string): void;

  fetchHubExecutives(): Promise<void>;

  getHubNewsFooterItems(): IExecutiveHubFooterItemData[];

  getHubLeadsFooterItems(): IExecutiveHubFooterItemData[];

  getHubExecutiveMarkers(): IExecutiveHubMarker[];

  updateHubMapExecutiveMarkers(hubMapExecutiveMarkersData: IHubMapExecutiveMarkers): void;
}

export class ExecutivesHubFacade implements IExecutivesHubContext {
  private _selectedFiltersSubscription: Subscription;

  readonly firstLoad$ = this._query.select("firstLoad");

  readonly hubExecutiveMarkers$ = combineQueries([
    this._query.select("executiveIds"),
    this._executivesFacade.executives$,
  ]).pipe(
    map(([executiveIds, executives]) =>
      this._executivesFacade.getExecutivesFromIds(executiveIds),
    ),
    tap((hubExecutives: IExecutive[]) => {
      if (hubExecutives) {
        const doc_counts: Partial<IDocCounts> = {
          filtersWithoutValues: {},
        };

        doc_counts.filtersWithoutValues["tags_Client"] = 0;
        doc_counts.filtersWithoutValues["tags_Lead"] = 0;

        // Recalculate client and lead facets when user tags a company
        // in hub
        hubExecutives.forEach((executive) => {
          if (isExecutiveClient(executive)) {
            doc_counts.filtersWithoutValues["tags_Client"] += 1;
          }

          if (isExecutiveLead(executive)) {
            doc_counts.filtersWithoutValues["tags_Lead"] += 1;
          }
        });

        this._executivesFiltersFacade.updateHubTagsFilterDocCounts(doc_counts);
      }
    }),
    map((hubExecutives: IExecutive[]) => {
      let executiveMarkers: IExecutiveHubMarker[] = [];

      if (hubExecutives) {
        executiveMarkers = mapExecutivesToHubMarkers(hubExecutives);
        this._store.update({ loadingExecutives: false });
      }

      return executiveMarkers;
    }),
  );

  readonly unfilteredHubExecutivesCount$ = this._query.select("unfilteredHubExecutivesCount");

  readonly hubErrorNoData$ = this._query.select("hubErrorNoData");
  readonly loadingHubExecutives$ = this._query.select("loadingExecutives");

  readonly hubRolloverExecutiveId$ = this._query.select("rolloverExecutiveId");

  readonly hubNewsFooterItems$ = this._query.select("hubNewsFooterItems");
  readonly loadingTopNews$ = this._query.select("loadingTopNews");

  readonly hubLeadsFooterItems$ = this._query.select("hubLeadsFooterItems");

  readonly executivesHNWIIds$ = this._query.select("executivesHNWIIds");
  readonly executivesPBIds$ = this._query.select("executivesPBIds");

  readonly selectedFilters$ = this._executivesFiltersFacade.allWithHubUI$.pipe(
    map(getSelectedFilters),
    distinctUntilChanged((p, q) => {
      return (
        areObjectsWithArrayOfStringsPropsEqual(p.filtersWithValues, q.filtersWithValues) &&
        areArraysOfStringsEqual(p.filtersWithoutValues, q.filtersWithoutValues) &&
        areArraysOfObjectsWithArrayOfQueryRangesPropsEqual(p.ranges, q.ranges) &&
        areArraysOfStringsEqual(p.productTrendsFilters, q.productTrendsFilters)
      );
    }),
    skip(1), // to avoid initial value
  );

  constructor(
    private _store: ExecutivesHubStore,
    private _query: ExecutivesHubQuery,
    private _filtersApiService: FiltersApiService,
    private _hubApiService: IHubApiService,
    private _executivesFacade: IExecutivesContext,
    private _executivesFiltersFacade: IExecutivesFiltersContext,
  ) {
    this._selectedFiltersSubscription = this.selectedFilters$.subscribe(
      async ({ filtersWithValues, filtersWithoutValues, ranges, productTrendsFilters }) => {
        await this._performHubFiltering(
          filtersWithValues,
          filtersWithoutValues,
          ranges,
          productTrendsFilters,
        );
      },
    );
  }

  getHubNewsFooterItems = () => {
    const { hubNewsFooterItems } = this._query.getValue();

    return hubNewsFooterItems;
  };

  getHubLeadsFooterItems = () => {
    const { hubLeadsFooterItems } = this._query.getValue();

    return hubLeadsFooterItems;
  };

  setHubRolloverExecutive = (executiveId: string) => {
    this._store.update({
      rolloverExecutiveId: executiveId,
    });
  };

  private _mapTopNewsToHubFooterItems = (
    topNews: ITopNews[] | null,
    companiesWithExecutives: ICompaniesExecutivesMapper,
  ): IExecutiveHubFooterItemMapper[] | null => {
    if (!topNews) return null;

    let data: IExecutiveHubFooterItemMapper[] = [];

    topNews.forEach((newsItem) => {
      const foundCompanyId = Object.keys(companiesWithExecutives).find(
        (companyId) => companyId === newsItem.company.internal_company_id.toString(),
      );

      if (foundCompanyId) {
        data.push({
          companyAndExecutiveData: companiesWithExecutives[foundCompanyId],
          companyNewsTitle: newsItem.title,
        });
      }
    });

    return data;
  };

  private async _performHubFiltering(
    filters: IObjectWithArrayOfStringsProps = {},
    filtersWithoutValues: string[],
    ranges: IQueryRanges[],
    productTrendsFilters?: string[],
  ): Promise<{
    executiveIds: string[];
    totalNoFilters: number;
  }> {
    let executiveIds: string[] = null;
    let totalNoFilters = null;

    this._store.update({
      loadingExecutives: true,
      hubErrorNoData: false,
    });

    try {
      const request = {
        filters,
        filtersWithoutValues,
        ranges,
        productTrendsFilters,
        unfilteredExecutivesIds: this._query.getValue().unfilteredHubExecutivesIds,
      };

      const { executives, doc_counts, total_no_filters } =
        await this._filtersApiService.searchAndFilterHubExecutives(request);

      this._executivesFiltersFacade.updateHubFilterDocCounts(doc_counts);

      if (executives.length) {
        this._executivesFacade.addExecutives(executives);
      }

      executiveIds = executives.map(({ id }) => id);
      totalNoFilters = total_no_filters;

      this._store.update({
        executiveIds,
      });
    } catch (e) {
      if (e === "hubErrorNoData") {
        this._store.update({
          hubErrorNoData: true,
        });
      } else {
        console.warn("Error while performing corporates filtering: ", e);
      }
    }

    return { executiveIds, totalNoFilters };
  }

  async fetchHubExecutives() {
    const {
      executiveIds,
      // totalNoFilters,
    } = await this._performHubFiltering({}, [], []);

    // Potential clients
    this._store.update({
      unfilteredHubExecutivesIds: executiveIds,
      unfilteredHubExecutivesCount: executiveIds?.length ?? 0,
    });

    const executivesIds = executiveIds?.map((id) => +id) ?? [];

    if (!executivesIds.length) {
      return;
    }

    this._store.update({
      firstLoad: false,
    });

    const executives = this._executivesFacade.getExecutivesFromIds(executiveIds);
    const HNWIExecutivesIds = executives.filter((e) => e.wealth_score >= 4).map((e) => e.id);
    const PBExecutivesIds = executives.filter((e) => e.wealth_score === 3).map((e) => e.id);

    this._store.update({
      executivesHNWIIds: HNWIExecutivesIds,
      executivesPBIds: PBExecutivesIds,
    });

    const companiesWithExecutives = this._extractCompanyAndExecutiveIds(executives);
    this._fetchTopNews(companiesWithExecutives);

    this._fetchTopLeads(executives);
  }

  /**
   * News count for specific company in map marker popup
   */
  async fetchNewsCountForCompany(companyId: string) {
    try {
      const res = await this._hubApiService.fetchNewsCountForCompany(companyId);
      console.log("fetchNewsCountForCompany", JSON.stringify(res, null, 4));
    } catch (e) {
      console.warn("Error while fetching news count for company: ", e);
    }
  }

  private _fetchTopLeads(executives: IExecutive[]) {
    if (!executives?.length) {
      this._store.update({
        hubLeadsFooterItems: [],
      });

      return;
    }

    const hnwiTopLeads = [...executives]
      .filter((e) => e.wealth_score >= 4)
      .sort((a, b) => b.wealth_score - a.wealth_score)
      .slice(0, 5);

    const pbTopLeads = [...executives].filter((e) => e.wealth_score === 3).slice(0, 5);

    const formattedLeadsItems: IExecutiveHubFooterItemData[] = [
      ...hnwiTopLeads,
      ...pbTopLeads,
    ].map((executive) => {
      return {
        executive: {
          id: executive.id,
          name: executive.full_name,
          rating: executive.wealth_score,
          city: executive.city,
        },
        company: {},
      } as IExecutiveHubFooterItemData;
    });

    this._store.update({
      hubLeadsFooterItems: formattedLeadsItems,
    });
  }

  private async _fetchTopNews(companiesWithExecutives: ICompaniesExecutivesMapper) {
    this._store.update({
      loadingTopNews: true,
    });

    try {
      const corporateIds = Object.keys(companiesWithExecutives).map((id) => +id);

      const res = await this._hubApiService.fetchTopNews({
        companies: corporateIds,
      });

      const footerItemsWithNews = this._mapTopNewsToHubFooterItems(
        res.data?.news,
        companiesWithExecutives,
      );

      const executivesWithCompanyNews =
        this._mapExecutivesWithCompanyNews(footerItemsWithNews);

      this._store.update({
        hubNewsFooterItems: executivesWithCompanyNews,
      });
    } catch (e) {
      console.log("Error while fetching top news: ", e);
    } finally {
      this._store.update({
        loadingTopNews: false,
      });
    }
  }

  private _extractCompanyAndExecutiveIds(
    executives: IExecutive[],
  ): ICompaniesExecutivesMapper {
    if (!executives?.length) {
      return {};
    }

    const companiesWithExecutives: ICompaniesExecutivesMapper = {};

    executives.forEach((executive: IExecutive) => {
      const executiveActiveCompany = getExecutiveActiveCompany(
        executive?.management,
        executive?.main_company_id,
        executive?.shareholder,
      );

      if (executiveActiveCompany) {
        companiesWithExecutives[executiveActiveCompany.company_id] = {
          executiveId: executive?.id,
          companyId: executiveActiveCompany.company_id?.toString(),
          companyName: executiveActiveCompany.company_name,
        };
      }
    });

    return companiesWithExecutives;
  }

  private _mapExecutivesWithCompanyNews(
    footerItemsWithNews: IExecutiveHubFooterItemMapper[],
  ): IExecutiveHubFooterItemData[] {
    if (!footerItemsWithNews) return;
    return footerItemsWithNews.map((item) => {
      const { executiveId, companyId, companyName } = item.companyAndExecutiveData;

      const executive = this._executivesFacade.getExecutive(executiveId);

      return {
        executive: {
          id: executive?.id,
          name: executive?.full_name,
          rating: executive?.wealth_score,
        },
        company: {
          id: companyId,
          name: companyName,
          newsTitle: item.companyNewsTitle,
        },
      };
    });
  }

  getHubExecutiveMarkers() {
    const { executiveIds } = this._query.getValue();
    const hubExecutives = this._executivesFacade.getExecutivesFromIds(executiveIds);
    return !hubExecutives ? [] : mapExecutivesToHubMarkers(hubExecutives);
  }

  updateHubMapExecutiveMarkers = ({ hubExecutiveIds, showAll }: IHubMapExecutiveMarkers) => {
    const { unfilteredHubExecutivesIds, executiveIds } = this._query.getValue();

    if (showAll) {
      this._store.update({
        executiveIds: unfilteredHubExecutivesIds,
      });

      return;
    }

    if (!hubExecutiveIds) {
      hubExecutiveIds = executiveIds;
    }

    this._store.update({
      executiveIds: hubExecutiveIds,
    });
  };
}
