import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import styles from "./styles.module.css";
import {
  DecreaseIcon,
  IncreaseIcon,
} from "../../../../../../../assets/company-dossier/headcount/icons";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IEmployeeCountsHistoric } from "../../../../../../../entities/corporates/models/employee-counts-historic.model";
import {
  abbreviateNumber,
  formatNumberWithDotSeparator,
} from "../../../../../../../helpers/number.helper";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  if (!active) {
    return null;
  }

  const payloadValue = payload[0]?.value;
  const barValue =
    typeof payloadValue === "number"
      ? formatNumberWithDotSeparator(payloadValue)
      : payloadValue;

  return (
    <Flex column className={styles.customTooltip}>
      <RText fontSize={isSmallerScreen ? "11" : "16"}>{`${label}`}</RText>
      <Spacing pt="10" />
      <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.tooltipBarValue}>
        {`${t("count")}: ${barValue}`}
      </RText>
    </Flex>
  );
};

export const Chart = ({ chartData, infoData }: any) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 12}
          fill={colors.secondaryDarkColor}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {abbreviateNumber(value)}
        </text>
      </g>
    );
  };

  return (
    <>
      <Flex alignItemsCenter>
        <Flex column flex alignSelfStart>
          <Flex alignItemsCenter>
            <RText fontSize={isSmallerScreen ? "16" : "38"} bold>
              {abbreviateNumber(infoData.lastYear.count)}
            </RText>
            <Spacing pr="24" />
            <Flex column>
              <Flex>
                <RText fontSize={isSmallerScreen ? "13" : "18"}>
                  {infoData.lastYear.percentage}
                </RText>
                <Spacing pr="4" />
                <img src={infoData.lastYear.icon} alt="" />
              </Flex>
              <Divider />
            </Flex>
          </Flex>
          <Spacing pt="20" />
          {infoData.lastYear.year && (
            <RText fontSize={isSmallerScreen ? "11" : "16"}>
              {`${t("comparedTo")} ${infoData.lastYear.year}`}
            </RText>
          )}
        </Flex>
        <Spacing pr="20" />
        <Flex column flex alignSelfStart>
          <Flex alignItemsCenter>
            <RText fontSize={isSmallerScreen ? "16" : "38"} bold>
              {abbreviateNumber(infoData.lastTwoYears.count)}
            </RText>
            <Spacing pr="24" />
            <Flex column>
              <Flex>
                <RText fontSize={isSmallerScreen ? "13" : "18"}>
                  {infoData.lastTwoYears.percentage}
                </RText>
                <Spacing pr="4" />
                <img src={infoData.lastTwoYears.icon} alt="" />
              </Flex>
              <Divider />
            </Flex>
          </Flex>
          <Spacing pt="20" />
          {infoData.lastTwoYears.year && (
            <RText fontSize={isSmallerScreen ? "11" : "16"}>
              {`${t("comparedTo")} ${infoData.lastTwoYears.year}`}
            </RText>
          )}
        </Flex>
      </Flex>

      <Spacing pt={isSmallerScreen ? "30" : "80"} />

      <ResponsiveContainer width={"100%"} height={300}>
        <ComposedChart
          width={0}
          data={chartData}
          margin={{
            top: 20,
            right: 40,
            left: 10,
          }}
        >
          <XAxis dataKey="year" axisLine={{ stroke: colors.backgroundBlackColor }} />
          <YAxis
            tickFormatter={abbreviateNumber}
            axisLine={{ stroke: colors.backgroundBlackColor }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="count"
            barSize={16}
            fill={colors.secondaryDarkColor}
            background={{ fill: "#eee", radius: 12 }}
            radius={12}
            isAnimationActive={false}
          >
            <LabelList dataKey="count" content={renderCustomizedLabel} />
          </Bar>
          <Line
            type="linear"
            dataKey="count"
            stroke={colors.primaryDarkColor}
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

const calculatePercentage = (count1: number, count2: number) => {
  if (count2 === 0) {
    return count1 === 0 ? 0 : 100;
  }

  const result = (count1 / count2 - 1) * 100;

  return +result.toFixed(1);
};

const selectProperIcon = (percentage: number) => {
  if (percentage < 0) {
    return DecreaseIcon;
  }

  if (percentage > 0) {
    return IncreaseIcon;
  }

  return "";
};

export const renderInfoData = (
  lastThreeYearsChartData: Array<{ year: number; count: number }>,
) => {
  const infoData = {
    lastYear: {
      count: 0,
      percentage: "0%",
      icon: "",
      year: "",
    },
    lastTwoYears: {
      count: 0,
      percentage: "0%",
      icon: "",
      year: "",
    },
  };

  switch (lastThreeYearsChartData.length) {
    case 1: {
      const lastYearData = lastThreeYearsChartData[0];

      return {
        ...infoData,
        lastYear: {
          count: lastYearData?.count,
          percentage: "0%",
          icon: "",
          year: "",
        },
      };
    }
    case 2: {
      const lastYearData = lastThreeYearsChartData[1];
      const prevYearData = lastThreeYearsChartData[0];

      const percentage = calculatePercentage(lastYearData?.count, prevYearData?.count);

      return {
        ...infoData,
        lastYear: {
          count: lastYearData?.count,
          percentage: Math.abs(percentage) + "%",
          icon: selectProperIcon(percentage),
          year: prevYearData?.year,
        },
      };
    }
    case 3: {
      const lastYearData = lastThreeYearsChartData[2];
      const prevYearData = lastThreeYearsChartData[1];
      const prevTwoYearData = lastThreeYearsChartData[0];

      const prevYearPercentage = calculatePercentage(lastYearData?.count, prevYearData?.count);
      const prevTwoYearPercentage = calculatePercentage(
        lastYearData?.count,
        prevTwoYearData?.count,
      );

      return {
        ...infoData,
        lastYear: {
          count: lastYearData?.count,
          percentage: Math.abs(prevYearPercentage) + "%",
          icon: selectProperIcon(prevYearPercentage),
          year: prevYearData?.year,
        },
        lastTwoYears: {
          count: prevTwoYearData?.count,
          percentage: Math.abs(prevTwoYearPercentage) + "%",
          icon: selectProperIcon(prevTwoYearPercentage),
          year: prevTwoYearData?.year,
        },
      };
    }
    default:
      return null;
  }
};

interface IProps {
  chartData: IEmployeeCountsHistoric[] | null;
}

export default function Headcount({ chartData }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  if (!chartData || chartData.length === 0) {
    return null;
  }

  const lastThreeYearsChartData = chartData.slice(Math.max(chartData.length - 3, 0));
  const infoData = renderInfoData(lastThreeYearsChartData);

  return (
    <>
      <RText fontSize={isSmallerScreen ? "20" : "20"} bold>
        {t("headcount")}
      </RText>
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
      <Chart chartData={chartData} infoData={infoData} />
      <Spacing pt={isSmallerScreen ? "20" : "50"} />
    </>
  );
}
