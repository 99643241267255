import React from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../components/dividers/Divider/Divider";
import RText from "../../../../components/fonts/RText/RText";
import CorporatesSearchResultsListHeader from "../../../../components/lists/SearchResultsList/CorporatesSearchResultsListHeader/CorporatesSearchResultsListHeader";
import CorporatesSearchResultsListItem, {
  mapCorporatesSearchResponseIntoResultItems,
} from "../../../../components/lists/SearchResultsList/CorporatesSearchResultsListItem/CorporatesSearchResultsListItem";
import ExecutivesSearchResultsListHeader from "../../../../components/lists/SearchResultsList/ExecutivesSearchResultsListHeader/ExecutivesSearchResultsListHeader";
import ExecutivesSearchResultsListItem, {
  mapExecutivesSearchResponseIntoResultItems,
} from "../../../../components/lists/SearchResultsList/ExecutivesSearchResultsListItem/ExecutivesSearchResultsListItem";
import Spinner from "../../../../components/other/Spinner/Spinner";
import { Spacing } from "../../../../components/spacing/component";
import { ICorporate } from "../../../../entities/corporates/models/corporate.model";
import { IExecutive } from "../../../../entities/executives/state/executive.model";
import { useInterestsSearchResponse } from "../../../../entities/interests-filters/hooks/interests-search-response.hook";
import { useSession } from "../../../../hooks/session.hook";

const InterestsLimitedSearchResults = () => {
  const { t } = useTranslation();
  const { searchResults, loading } = useInterestsSearchResponse();
  const { isB2CUser } = useSession();

  if (!searchResults?.length) {
    if (loading) {
      return <Spinner />;
    }

    return (
      <Spacing pt="12">
        <RText fontSize="28">{t("noSearchResults")}</RText>
      </Spacing>
    );
  }

  const limitSearchResponseList = () => {
    if (searchResults.length > 5) {
      let arrayCopy = [...searchResults];
      return arrayCopy.slice(0, 5);
    }

    return searchResults;
  };

  return (
    <Spacing pt="12">
      {isB2CUser ? (
        <ExecutivesSearchResultsListHeader
          column1={t("name")}
          column2={t("wealthScore")}
          column3={t("company")}
        />
      ) : (
        <CorporatesSearchResultsListHeader
          column1={t("company")}
          column2={t("productAffinity")}
          column3={t("industry")}
        />
      )}

      <Spacing pt="12" />
      <Divider />

      {isB2CUser ? (
        <>
          {mapExecutivesSearchResponseIntoResultItems(
            limitSearchResponseList() as IExecutive[],
          ).map((item) => {
            return (
              <React.Fragment key={item.id}>
                <ExecutivesSearchResultsListItem key={item.id} item={item} />
                <Divider />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <>
          {mapCorporatesSearchResponseIntoResultItems(
            limitSearchResponseList() as ICorporate[],
          ).map((item) => {
            return (
              <React.Fragment key={item.id}>
                <CorporatesSearchResultsListItem key={item.id} item={item} />
                <Divider />
              </React.Fragment>
            );
          })}
        </>
      )}
    </Spacing>
  );
};

export default InterestsLimitedSearchResults;
