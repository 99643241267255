import React from "react";
import { classNames } from "../../../helpers/string.helper";
import styles from "./styles.module.css";

interface IProps {
  onClick?: () => void;
  children?: React.ReactNode;
  flex1?: boolean;
  alignSelfEnd?: boolean;
  collapsed?: boolean;
  alignItemsCenter?: boolean;
  displayFlex?: boolean;
}

export const FlexChild = ({
  onClick,
  children,
  flex1,
  collapsed,
  alignSelfEnd,
  alignItemsCenter,
  displayFlex,
}: IProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles, {
        flex1,
        collapsed,
        alignSelfEnd,
        alignItemsCenter,
        displayFlex,
      })}
      {...{ children }}
    />
  );
};
