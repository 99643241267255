import React, {
  MutableRefObject,
  useState,
  useLayoutEffect,
  KeyboardEventHandler,
} from "react";
import Downshift, {
  DownshiftState,
  StateChangeOptions,
  ControllerStateAndHelpers,
} from "downshift";
import { classNames } from "../../../../helpers/string.helper";
import styles from "./FilterAutosuggestElement.module.css";

interface IProps<T> {
  items: Array<T>;
  getDisplayValue: (item: T) => string;
  getSearchValue: (item: T) => string;
  inputChangeCallback?: (oldValue: string, newValue: string, items: Array<T>) => string;
  onChange?: (selectedItem: T | null, stateAndHelpers: ControllerStateAndHelpers<T>) => void;
  stateReducer?: (
    state: DownshiftState<T>,
    changes: StateChangeOptions<T>,
  ) => Partial<StateChangeOptions<T>>;
  actionRef?: MutableRefObject<ControllerStateAndHelpers<T>>;
  inputRef?: MutableRefObject<HTMLInputElement>;
  style?: React.CSSProperties;
  disabled?: boolean;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  inputPlaceHolder?: string;
  suggestionsFunction: (
    inputValue: string,
    items: Array<T>,
  ) => { suggestions: Array<T>; highlightedIdx: number };
}

const FilterAutosuggestElement = <T extends unknown>({
  items,
  getDisplayValue,
  getSearchValue,
  onChange,
  stateReducer,
  inputChangeCallback = (oldValue, newValue) => {
    // allow entering only digits which select available items
    if (newValue && !items.some((itm) => getSearchValue(itm)?.startsWith(newValue))) {
      return oldValue;
    }
    return newValue;
  },
  actionRef,
  inputRef,
  style,
  disabled,
  onKeyPress,
  inputPlaceHolder,
  suggestionsFunction,
}: IProps<T>) => {
  const [inputValue, setInputValueInternal] = useState("");
  const [suggestions, setSuggestions] = useState(new Array<T>());
  const [highlightedItemIndex, setHighlightedItemIndex] = useState<number>(null);
  const [isOpen, setIsOpen] = useState(false);

  // reset the state when items change
  useLayoutEffect(() => {
    setInputValueInternal("");
    setSuggestions([]);
    setHighlightedItemIndex(null);
  }, [items]);

  // callback to set input value, including recalculating suggestions
  // and highlighted item
  const setInputValue = (inputValue: string) => {
    setInputValueInternal(inputValue);
    const suggestionsObject = suggestionsFunction(inputValue, items);
    const suggestionsToSet = suggestionsObject.suggestions ?? [];
    const suggestionIdx = suggestionsObject.highlightedIdx;
    if (suggestionIdx !== -1) setHighlightedItemIndex(suggestionIdx);
    setSuggestions(suggestionsToSet);
  };

  // state reducer which calls the upstream reducer if applicable and then checks the
  // allowed key inputs and deselects the item if the input value changes
  const rdcr = (state: DownshiftState<T>, changes: StateChangeOptions<T>) => {
    let newChanges: Partial<StateChangeOptions<T>> = changes;
    if (stateReducer) {
      newChanges = stateReducer(state, changes);
    }
    let proposedState = { ...state, ...newChanges };
    if (newChanges.type === Downshift.stateChangeTypes.changeInput) {
      newChanges.inputValue = inputChangeCallback(
        state.inputValue,
        newChanges.inputValue,
        items,
      );
      proposedState.inputValue = newChanges.inputValue;
      if (
        proposedState.selectedItem &&
        getDisplayValue(proposedState.selectedItem) !== newChanges.inputValue
      ) {
        newChanges.selectedItem = null;
        proposedState.selectedItem = null;
      }
    }
    return newChanges;
  };

  return (
    <Downshift
      stateReducer={rdcr}
      inputValue={inputValue}
      highlightedIndex={highlightedItemIndex}
      onChange={onChange}
      isOpen={isOpen && suggestions.length > 0}
      itemToString={(item) => (item ? getDisplayValue(item) : "")}
      onStateChange={(options) => {
        if (options.hasOwnProperty("highlightedIndex")) {
          setHighlightedItemIndex(options.highlightedIndex);
        }
        if (options.hasOwnProperty("inputValue")) {
          setInputValue(options.inputValue);
        }
        if (options.hasOwnProperty("isOpen")) {
          setIsOpen(options.isOpen);
        }
      }}
    >
      {(downshift) => {
        const { getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen } =
          downshift;
        if (actionRef) {
          actionRef.current = downshift;
        }

        return (
          <div
            className={styles.mainContainer}
            style={{
              ...style,
              opacity: disabled ? 0 : 1,
              transition: "0.25s",
            }}
          >
            <div className={styles.inputContainer}>
              <input
                className={classNames(styles)}
                {...getInputProps({
                  ref: inputRef,
                  disabled,
                  onKeyPress,
                  placeholder: inputPlaceHolder,
                })}
              />
            </div>
            <ul className={styles.list} {...getMenuProps()}>
              {isOpen &&
                suggestions.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: `${getDisplayValue(item)}${index}`,
                      item,
                      index,
                      className:
                        highlightedIndex === index
                          ? `${styles.activeListItem} ${styles.listItem}`
                          : styles.listItem,
                    })}
                  >
                    {getDisplayValue(item)}
                  </li>
                ))}
            </ul>
          </div>
        );
      }}
    </Downshift>
  );
};

export default FilterAutosuggestElement;
