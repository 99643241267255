import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDropDown } from "../../../../assets/ArrowDropDown.svg";
import SearchResultsFor from "../../SearchResultsFor/SearchResultsFor";
import CorporatesFiltersPanel from "../../corporates/CorporatesFiltersPanel";
import ExecutivesFiltersPanel from "../../executives/ExecutivesFilterPanel";
import CorporatesLimitedSearchResults from "./CorporatesLimitedSearchResults";
import ExecutivesLimitedSearchResults from "./ExecutivesLimitedSearchResults";
import { Spacing } from "../../../../components/spacing/component";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import InterestsLimitedSearchResults from "./InterestsLimitedSearchResults";
import InterestsFiltersHeader from "../../interests/InterestsFiltersHeader";
import OpportunitiesFiltersHeader from "../../opportunities/OpportunitiesFiltersHeader";
import OpportunitiesLimitedSearchResults from "./OpportunitiesLimitedSearchResults";
import StartupsFiltersHeader from "../../startups/StartupsFiltersHeader";
import StartupsLimitedSearchResults from "./StartupsLimitedSearchResults";
import RLink from "../../../../components/link/RLink";
import { useSession } from "../../../../hooks/session.hook";
import useAppColors from "../../../../hooks/app-colors.hook";

const AllResultsPage = () => {
  const { t } = useTranslation();
  const { isB2CUser } = useSession();
  const { colors } = useAppColors();

  const renderAllResultsLink = (path: string) => (
    <Spacing p="20">
      <Flex alignItemsCenter>
        <ArrowDropDown color={colors.secondaryDarkColor} />
        <Spacing pl="8" />
        <RLink path={path}>
          <RText primaryBlue fontSize="16">
            {t("showAllResults")}
          </RText>
        </RLink>
      </Flex>
    </Spacing>
  );

  return (
    <>
      <SearchResultsFor />

      {isB2CUser === null ? null : isB2CUser ? (
        <>
          <ExecutivesFiltersPanel />
          <ExecutivesLimitedSearchResults />
          {renderAllResultsLink(`/home/search/executives`)}

          <Spacing pb="80" />

          <CorporatesFiltersPanel />
          <CorporatesLimitedSearchResults />
          {renderAllResultsLink(`/home/search/corporates`)}

          <Spacing pb="80" />
        </>
      ) : (
        <>
          <CorporatesFiltersPanel />
          <CorporatesLimitedSearchResults />
          {renderAllResultsLink(`/home/search/corporates`)}

          <Spacing pb="80" />

          <ExecutivesFiltersPanel />
          <ExecutivesLimitedSearchResults />
          {renderAllResultsLink(`/home/search/executives`)}

          <Spacing pb="80" />
        </>
      )}

      <InterestsFiltersHeader />
      <InterestsLimitedSearchResults />
      {renderAllResultsLink(`/home/search/interests`)}

      <Spacing pb="80" />

      <OpportunitiesFiltersHeader />
      <OpportunitiesLimitedSearchResults />
      {renderAllResultsLink(`/home/search/opportunities`)}

      {isB2CUser === false && (
        <>
          <Spacing pb="80" />

          <StartupsFiltersHeader />
          <StartupsLimitedSearchResults />
          {renderAllResultsLink(`/home/search/startups`)}
        </>
      )}
    </>
  );
};

export default AllResultsPage;
