export interface IShareholderPeople {
  capital: number;
  capital_currency: null;
  from_date: string;
  from_quality: number;
  full_name: string;
  industry_sector: string;
  person_id: number;
  position: string;
  relation_type: string;
  share_perc: number;
  to_date: string;
  to_quality: number;
  wealth_score: number;
}

export function createShareholderPeople(params: Partial<IShareholderPeople>) {
  return {
    capital: null,
    capital_currency: null,
    from_date: null,
    from_quality: null,
    full_name: null,
    industry_sector: null,
    person_id: null,
    position: null,
    relation_type: null,
    share_perc: null,
    to_date: null,
    to_quality: null,
    wealth_score: null,
    ...params,
  } as IShareholderPeople;
}
