import { EntityUIQuery, QueryEntity, QueryConfig, Order } from "@datorama/akita";
import {
  CorporatesFiltersState,
  CorporatesFiltersStore,
  corporatesFiltersStore,
  CorporatesFiltersUIState,
} from "./corporates-filters.store";

@QueryConfig({
  sortBy: "order",
  sortByOrder: Order.ASC,
})
export class CorporatesFiltersQuery extends QueryEntity<CorporatesFiltersState> {
  ui: EntityUIQuery<CorporatesFiltersUIState>;

  constructor(protected store: CorporatesFiltersStore) {
    super(store);
    this.createUIQuery();
  }
}

export const corporatesFiltersQuery = new CorporatesFiltersQuery(corporatesFiltersStore);
