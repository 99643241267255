import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ICorporateNote } from "./corporate-notes.model";
import { CorporateNotesStore } from "./corporate-notes.store";
import { CorporateNotesQuery } from "./corporate-notes.query";
import { corporateNotesApiService } from "./../../../api/services/corporate-notes.service";
import { ICorporatesContext } from "../../corporates/state/corporates.facade";

export interface ICorporateNotesContext {
  corporateNotes$: Observable<ICorporateNote[]>;

  addNote: (corporateId: number, newNote: string) => Promise<void>;
  updateNote: (noteId: number, updatedNote: string) => Promise<void>;
  deleteNote: (noteId: number) => Promise<void>;
}

export class CorporateNotesFacade implements ICorporateNotesContext {
  constructor(
    private _store: CorporateNotesStore,
    private _query: CorporateNotesQuery,
    private _corporatesFacade: ICorporatesContext,
  ) {}

  readonly corporateNotes$: Observable<ICorporateNote[]> =
    this._corporatesFacade.corporates$.pipe(
      map((corporates) => {
        return (
          corporates?.find(({ id }) => id === this._corporatesFacade.getActiveId())?.notes ??
          []
        );
      }),
    );

  addNote = async (corporateId: number, newNote: string): Promise<void> => {
    try {
      const res: ICorporateNote = await corporateNotesApiService.addNote({
        elastic_company_id: corporateId,
        note: newNote,
      });

      this._corporatesFacade.addNoteToCorporate(res);
    } catch (error) {
      console.warn("Error while adding new corporate note: ", error);
    }
  };

  updateNote = async (noteId: number, updatedNote: string): Promise<void> => {
    try {
      const res: ICorporateNote = await corporateNotesApiService.updateNote(noteId, {
        note: updatedNote,
      });

      this._corporatesFacade.updateNoteForCorporate(res);
    } catch (error) {
      console.warn("Error while updating corporate note: ", error);
    }
  };

  deleteNote = async (noteId: number): Promise<void> => {
    try {
      await corporateNotesApiService.deleteNote(noteId);

      this._corporatesFacade.removeNoteFromCorporate(noteId);
    } catch (error) {
      console.warn("Error while deleting corporate note: ", error);
    }
  };
}
