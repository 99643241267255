import { ReactComponent as FooterNavigationIcon } from "./FooterNavigationIcon.svg";
import { ReactComponent as FooterProductTrendsIcon } from "./FooterProductTrendsIcon.svg";
import { ReactComponent as FooterNewsIcon } from "./FooterNewsIcon.svg";
import { ReactComponent as FooterStartUpsIcon } from "./FooterStartUpsIcon.svg";
import { ReactComponent as FooterBankruptciesIcon } from "./FooterBankruptciesIcon.svg";
import { ReactComponent as GreenDot } from "./GreenDot.svg";
import { ReactComponent as GreenCircle } from "./GreenCircle.svg";

export {
  FooterNavigationIcon,
  FooterProductTrendsIcon,
  FooterNewsIcon,
  FooterStartUpsIcon,
  FooterBankruptciesIcon,
  GreenDot,
  GreenCircle,
};
