import ApiService from "./api.service";
import { IExecutiveNote } from "../../entities/executive-notes/state/executive-notes.model";

interface IExecutiveNoteRequest {
  elastic_person_id?: number;
  note: string;
}

class ExecutiveNotesApiService extends ApiService {
  public addNote(note: IExecutiveNoteRequest): Promise<IExecutiveNote> {
    return this.post(`/people-notes`, note);
  }

  public updateNote(noteId: number, note: IExecutiveNoteRequest): Promise<IExecutiveNote> {
    return this.put(`/people-notes/${noteId}`, note);
  }

  public deleteNote(noteId: number): Promise<void> {
    return this.delete(`/people-notes/${noteId}`);
  }
}

export const executiveNotesApiService = new ExecutiveNotesApiService();
