import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ExecutiveHubContext } from "../state/executives-hub.context";

interface IHubAnimationsHook {
  executivesPBCount: number;
  executivesHNWICount: number;
  unfilteredHubExecutivesCount: number;
}

export const useExecutivesHubAnimations = (): IHubAnimationsHook => {
  const executivesHubService = useContext(ExecutiveHubContext);

  const [executivesHNWIIds] = useObservable(executivesHubService.executivesHNWIIds$, []);

  const [executivesPBIds] = useObservable(executivesHubService.executivesPBIds$, []);

  const [unfilteredHubExecutivesCount] = useObservable(
    executivesHubService.unfilteredHubExecutivesCount$,
    null,
  );

  return {
    executivesHNWICount: executivesHNWIIds?.length ?? null,
    executivesPBCount: executivesPBIds?.length ?? null,
    unfilteredHubExecutivesCount,
  };
};
