import React, { useContext, useState } from "react";
import { MapContainer as OpenStreetMap, Marker } from "react-leaflet";
import styles from "./styles.module.css";
import FullScreenMapModal from "./FullScreenMapModal";
import { ICorporate } from "../../../../../../entities/corporates/models/corporate.model";
import { mapCorporateIntoCorporateMarkerData } from "../../../../../../modules/hub/hooks/hub-markers.hook";
import MapCustomControl from "./MapCustomControl";
import MapTransportLayer from "./map-layers/MapTransportLayer";
import MapSatelliteLayer from "./map-layers/MapSatelliteLayer";
import SatelliteLayerToggle from "./map-controls/SatelliteLayerToggle";
import { Spacing } from "../../../../../../components/spacing/component";
import { createDefaultPurpleIcon } from "../../../../../../helpers/mapMarker.helper";
import {
  areCoordinatesDefault,
  buildCompanyAddressForGeoCoding,
  calculateMapCenterFromCompanyCoordinates,
} from "../../../../../../helpers/map.helper";
import Spinner from "../../../../../../components/other/Spinner/Spinner";
import { IExecutive } from "../../../../../../entities/executives/state/executive.model";
import RText from "../../../../../../components/fonts/RText/RText";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { Flex } from "../../../../../../components/containers/Flex/Flex";

interface IProps {
  width: string;
  height: string;
  corporate?: ICorporate;
  corporateLookALikes?: ICorporate[];
  executiveLookALikes?: IExecutive[];
}

const DossierMap = ({
  width,
  height,
  corporate,
  corporateLookALikes,
  executiveLookALikes,
}: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const [satelliteLayer, setSatelliteLayer] = useState(false);

  const toggleSatelliteLayer = () => {
    setSatelliteLayer(!satelliteLayer);
  };

  const companyCoordinates = calculateMapCenterFromCompanyCoordinates(corporate);

  const calculateZoom = () => {
    // When we have no exact coordinates, make the map more zoomed out
    if (areCoordinatesDefault(companyCoordinates)) {
      return 6;
    }

    // Otherwise zoom into the company
    return 16;
  };

  if (!corporate) {
    return <Spinner />;
  }

  return !corporate?.geo_location || areCoordinatesDefault(companyCoordinates) ? (
    <Flex
      alignItemsCenter
      justifyContentCenter
      style={{
        height: height,
        width: width,
      }}
    >
      <RText fontSize={isSmallerScreen ? "12" : "16"}>{t("mapNotAvailable")}</RText>
    </Flex>
  ) : (
    <OpenStreetMap
      center={companyCoordinates}
      zoom={calculateZoom()}
      minZoom={3}
      style={{
        height: height,
        width: width,
        zIndex: 0,
      }}
      tap={false}
    >
      {satelliteLayer ? <MapSatelliteLayer /> : <MapTransportLayer />}

      <MapCustomControl
        position="topright"
        containerProps={{
          style: {
            marginTop: "5px",
          },
        }}
      >
        <div className={`${styles.controlsContainer}`}>
          <SatelliteLayerToggle onClick={toggleSatelliteLayer} active={satelliteLayer} />

          {companyCoordinates && (
            <>
              <Spacing pl="10" />

              <div className={styles.control}>
                <FullScreenMapModal
                  satelliteLayer={satelliteLayer}
                  toggleSatelliteLayer={toggleSatelliteLayer}
                  locationCoordinates={companyCoordinates}
                  companyAddress={buildCompanyAddressForGeoCoding(
                    corporate?.city,
                    corporate?.street,
                  )}
                  corporateMarkerData={mapCorporateIntoCorporateMarkerData(corporate)}
                  corporateLookALikes={corporateLookALikes}
                  executiveLookALikes={executiveLookALikes}
                  corporate={corporate}
                />
              </div>
            </>
          )}
        </div>
      </MapCustomControl>

      {companyCoordinates && (
        <Marker position={companyCoordinates} icon={createDefaultPurpleIcon()} />
      )}
    </OpenStreetMap>
  );
};

export default DossierMap;
