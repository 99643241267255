export interface IManagement {
  person_id: number;
  full_name: string;
  relation_type: string;
  position: string | null;
  procura: string | null;
  from_date: string | null;
  from_quality: number | null;
  to_date: string | null;
  to_quality: number | null;
  function_rank: number;
  promoter_score: number;
  function_interaction_rank: number;
  score: number;
  wealth_score: number;
}

export function createManagement(params: Partial<IManagement>) {
  return {
    person_id: null,
    full_name: null,
    relation_type: null,
    position: null,
    procura: null,
    from_date: null,
    from_quality: null,
    function_rank: null,
    promoter_score: null,
    function_interaction_rank: null,
    score: null,
    wealth_score: null,
    ...params,
  } as IManagement;
}
