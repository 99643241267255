import React from "react";
import ScoreFilter from "./ScoreFilter";
import RateFilter from "./RateFilter";
import TagFilter from "./TagFilter";
import { IFilter } from "./types";
import styles from "./styles.module.css";
import { Spacing } from "../spacing/component";
import { Flex } from "../containers/Flex/Flex";

interface IProps {
  filterValue: IFilter;
  setFilterValue: (filter: IFilter) => void;
  rateKey: "wealthRating" | "productAffinity";
  isExecutive?: boolean;
}

const Filter = ({ filterValue, setFilterValue, rateKey, isExecutive }: IProps) => {
  const handleFilterValue = (key: string, values: number[]) => {
    let newFilterValue = { ...filterValue, [key]: values };
    setFilterValue(newFilterValue);
  };

  return (
    <Flex>
      <Flex column className={styles.filterContainer}>
        <TagFilter
          values={filterValue.tagIds}
          setValues={(values: number[]) => handleFilterValue("tagIds", values)}
        />
        <Spacing pt="12" />
      </Flex>
      <Flex column className={styles.filterContainer}>
        <RateFilter
          values={filterValue[rateKey]}
          setValues={(values: number[]) => handleFilterValue(rateKey, values)}
        />
        <Spacing pt="12" />
      </Flex>
      {!isExecutive && (
        <Flex column className={styles.filterContainer}>
          <ScoreFilter
            title="Influencer"
            values={filterValue.influencerScore}
            setValues={(values: number[]) => handleFilterValue("influencerScore", values)}
          />
          <Spacing pt="12" />
        </Flex>
      )}
      <Flex column className={styles.filterContainer}>
        <ScoreFilter
          title="Promoter"
          values={filterValue.promoterScore}
          setValues={(values: number[]) => handleFilterValue("promoterScore", values)}
        />
        <Spacing pt="12" />
      </Flex>
    </Flex>
  );
};

export default Filter;
