import { createContext } from "react";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { corporatesFacade } from "../../corporates/state/corporates.context";
import { executivesFacade } from "../../executives/state/executive.context";
import { sessionFacade } from "./../../../state/akita/session/session.facade";
import { InterestsFiltersFacade, IInterestsFiltersContext } from "./interests-filters.facade";
import { interestsFiltersQuery } from "./interests-filters.query";
import { interestsFiltersStore } from "./interests-filters.store";

export const interestsFiltersFacade = new InterestsFiltersFacade(
  interestsFiltersStore,
  interestsFiltersQuery,
  filtersApiService,
  corporatesFacade,
  executivesFacade,
  sessionFacade,
);

export const InterestsFiltersContext =
  createContext<IInterestsFiltersContext>(interestsFiltersFacade);
