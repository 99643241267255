import React, { Fragment, useState } from "react";
import styles from "./styles.module.css";
import { Flex } from "../containers/Flex/Flex";
import { Spacing } from "../spacing/component";
import { useSession } from "../../hooks/session.hook";
import { useCloseOnClickOutside } from "../../hooks/componentRefs.hook";
import { ReactComponent as ChevronDownIcon } from "../../assets/ChevronDown.svg";
import { ReactComponent as ChevronUpIcon } from "../../assets/ChevronUp.svg";
import { IOption } from "./types";

interface IProps {
  options?: IOption[];
  Title: React.ReactType;
  SelectOption: React.ReactType;
  values: number[];
  handleValues: (values: number[]) => void;
}

const Select = ({ options, SelectOption, values, handleValues, Title }: IProps) => {
  const { isDarkTheme } = useSession();

  const [ref] = useCloseOnClickOutside(
    () => {
      setTimeout(() => {
        setShowDropdown(false);
      }, 0);
    },
    { capture: true },
  );

  const [showDropdown, setShowDropdown] = useState(false);

  const onToggleDropdown = () => {
    setShowDropdown((visible) => !visible);
  };

  const handleOption = (value: number) => {
    let newValues;
    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value);
    } else {
      newValues = [...values, value];
    }
    handleValues(newValues);
  };

  return (
    <Flex style={{ position: "relative" }}>
      <Flex alignItemsCenter pointer onClick={onToggleDropdown}>
        <Title />
        <Spacing pl="8" />
        {showDropdown ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Flex>
      {showDropdown && (
        <Flex
          elementRef={ref}
          column
          style={{
            background: isDarkTheme
              ? "var(--immutable_background_black)"
              : "var(--immutable_background_white)",
            borderColor: isDarkTheme
              ? "var(--immutable_background_black)"
              : "var(--color_gray_dark)",
          }}
          className={styles.dropdownPopup}
        >
          <Flex
            column
            style={{
              maxHeight: 300,
              overflow: "auto",
            }}
          >
            {options.map((item: IOption) => (
              <Fragment key={item.value}>
                <SelectOption
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  active={values.includes(item.value)}
                  handleOption={handleOption}
                />
                <Spacing pt="4" />
              </Fragment>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(Select);
