import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ICorporate } from "../entities/corporates/models/corporate.model";
import { CorporateDossierContext } from "../entities/corporate-dossier/corporate-dossier.context";
import {
  ICorporateData,
  IIndustrySectorCode,
  INews,
  IRelationshipIndicatorResponse,
  ISignals,
} from "../entities/corporate-dossier/corporate-dossier.model";

interface IIndustrySectorCodeHook {
  industrySectorCodeData: IIndustrySectorCode | null;
  fetchIndustrySectorCodeData: (sectorCode: string) => void;
  loadingIndustryCodeData: boolean;
}

export function useIndustrySectorCodeData(): IIndustrySectorCodeHook {
  const corporatesContext = useContext(CorporateDossierContext);

  const [industrySectorCodeData] = useObservable(
    corporatesContext.industrySectorCodeData$,
    null,
  );

  const fetchIndustrySectorCodeData = corporatesContext.fetchIndustrySectorCodeData;

  const [loadingIndustryCodeData] = useObservable(
    corporatesContext.loadingIndustryCodeData$,
    false,
  );

  return {
    industrySectorCodeData,
    fetchIndustrySectorCodeData,
    loadingIndustryCodeData,
  };
}

interface ICorporateLookALikesHook {
  corporateLookALikes: ICorporate[] | null;
  fetchCorporateLookALikes: (companyId: string) => Promise<boolean>;
  totalCorporateLookALikes: number | null;
}

export function useCorporateLookALikes(): ICorporateLookALikesHook {
  const corporatesContext = useContext(CorporateDossierContext);

  const [corporateLookALikes] = useObservable(corporatesContext.corporateLookALikes$, null);

  const fetchCorporateLookALikes = corporatesContext.fetchCorporateLookALikes;

  const [totalCorporateLookALikes] = useObservable(
    corporatesContext.totalCorporateLookALikes$,
    null,
  );

  return {
    corporateLookALikes,
    fetchCorporateLookALikes,
    totalCorporateLookALikes,
  };
}

interface ICorporateNewsHook {
  corporateNews: INews[] | null;
  fetchCorporateNews: (companyId: string, companyData: ICorporateData) => Promise<boolean>;
}

export function useCorporateNews(): ICorporateNewsHook {
  const corporatesContext = useContext(CorporateDossierContext);

  const [corporateNews] = useObservable(corporatesContext.corporateNews$, null);

  const fetchCorporateNews = corporatesContext.fetchCorporateNews;

  return { corporateNews, fetchCorporateNews };
}

interface ICorporateProductTrends {
  corporateProductTrends: ISignals[] | null;
  totalCorporateProductTrends: number | null;
  fetchCorporateProductTrends: (
    companyId: string,
    companyData: ICorporateData,
  ) => Promise<boolean>;
}

export function useCorporateProductTrends(): ICorporateProductTrends {
  const corporatesContext = useContext(CorporateDossierContext);

  const [corporateProductTrends] = useObservable(
    corporatesContext.corporateProductTrends$,
    null,
  );

  const [totalCorporateProductTrends] = useObservable(
    corporatesContext.totalCorporateProductTrends$,
    null,
  );

  const fetchCorporateProductTrends = corporatesContext.fetchCorporateProductTrends;

  return {
    corporateProductTrends,
    totalCorporateProductTrends,
    fetchCorporateProductTrends,
  };
}

interface IRelationshipIndicator {
  corporateRelationshipIndicator: IRelationshipIndicatorResponse | null;
  fetchCorporateRelationshipIndicator: (companyId: string) => Promise<boolean>;
}

export function useCorporateRelationshipIndicator(): IRelationshipIndicator {
  const corporatesContext = useContext(CorporateDossierContext);

  const [corporateRelationshipIndicator] = useObservable(
    corporatesContext.corporateRelationshipIndicator$,
    null,
  );

  const fetchCorporateRelationshipIndicator =
    corporatesContext.fetchCorporateRelationshipIndicator;

  return {
    corporateRelationshipIndicator,
    fetchCorporateRelationshipIndicator,
  };
}

interface IOpenFullscreenMapModal {
  openFullscreenMapModal: boolean;
  setOpenFullscreenMapModal: (open: boolean) => void;
}

export function useOpenFullscreenMapModal(): IOpenFullscreenMapModal {
  const corporatesContext = useContext(CorporateDossierContext);

  const [openFullscreenMapModal] = useObservable(
    corporatesContext.openFullscreenMapModal$,
    false,
  );

  return {
    openFullscreenMapModal,
    setOpenFullscreenMapModal: corporatesContext.setOpenFullscreenMapModal,
  };
}

interface ICorporateSideNavigationActiveAnchor {
  corporateSideNavigationActiveAnchor: string;
}

export function useCorporateSideNavigationActiveAnchor(): ICorporateSideNavigationActiveAnchor {
  const corporatesContext = useContext(CorporateDossierContext);

  const [corporateSideNavigationActiveAnchor] = useObservable(
    corporatesContext.corporateSideNavigationActiveAnchor$,
    "productTrends",
  );

  return {
    corporateSideNavigationActiveAnchor,
  };
}
