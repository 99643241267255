import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import enGb from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.css";
import {
  Magnifier as MagnifierIcon,
  Calendar as CalendarIcon,
} from "../../../../../../../assets/company-dossier/news-section/icons";
import { Spacing } from "../../../../../../../components/spacing/component";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RoundedButton from "../../../../../../../components/buttons/RoundedButton/RoundedButton";
import { INote, formatDate } from "./NotesList";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../../../hooks/session.hook";
import RText from "../../../../../../../components/fonts/RText/RText";

registerLocale("en-GB", enGb);

const CustomDatePickerInput = ({ value, onClick, onChange, placeholder }: any) => {
  const { colors } = useAppColors();

  return (
    <Flex onClick={onClick} className={styles.customDateInputContainer} pointer>
      <input value={value} onChange={onChange} placeholder={placeholder} />
      <CalendarIcon color={colors.backgroundBlackColor} className={styles.calendarIcon} />
    </Flex>
  );
};

type DateType = Date | null;

interface IProps {
  orginalNotesList: INote[];
  setNotes: (notes: INote[]) => void;
}

export default function Header({ orginalNotesList, setNotes }: IProps) {
  const { t } = useTranslation();
  const { isDarkTheme } = useSession();
  const { colors } = useAppColors();

  const [selectedFrom, setSelectedFrom] = useState<DateType>(null);
  const [selectedTo, setSelectedTo] = useState<DateType>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const onInputChange = (event: any) => setSearchQuery(event.target.value);

  const onSearchNews = () => {
    if (!searchQuery && !selectedFrom && !selectedTo) {
      setNotes([...orginalNotesList]);

      return;
    }

    const formattedDateFrom = selectedFrom && formatDate(selectedFrom);
    const formattedDateTo = selectedTo && formatDate(selectedTo);
    let filteredNotes: INote[] = [...orginalNotesList];

    if (searchQuery !== "") {
      filteredNotes = filteredNotes.filter((note: INote) =>
        note.title.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    if (formattedDateFrom && !formattedDateTo) {
      filteredNotes = filteredNotes.filter((note: INote) => note.date >= formattedDateFrom);
    }

    if (formattedDateTo && !formattedDateFrom) {
      filteredNotes = filteredNotes.filter((note: INote) => note.date <= formattedDateTo);
    }

    if (formattedDateFrom && formattedDateTo) {
      filteredNotes = filteredNotes.filter(
        (note: INote) => note.date >= formattedDateFrom && note.date <= formattedDateTo,
      );
    }

    setNotes([...filteredNotes]);
  };

  return (
    <>
      <RText fontSize="20" bold>
        {t("news")}
      </RText>
      <Spacing pb="60" />
      <Flex
        alignItemsCenter
        justifyContentSpaceBetween
        className={styles.notesHeaderContainer}
      >
        <Flex
          alignItemsCenter
          justifyContentSpaceBetween
          className={styles.searchNewsContainer}
          style={{
            backgroundColor: isDarkTheme
              ? "var(--immutable_gray_dark)"
              : "var(--color_gray_light)",
          }}
        >
          <input
            name={"searchNews"}
            className={styles.inputSearch}
            onChange={onInputChange}
            placeholder={t("searchNewsPlaceholder")}
          />
          <Spacing pl="12" />
          <MagnifierIcon color={colors.backgroundBlackColor} className={styles.searchIcon} />
        </Flex>
        <Flex style={{ gap: 30 }}>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={selectedFrom}
            onChange={(date: DateType) => setSelectedFrom(date)}
            customInput={<CustomDatePickerInput />}
            customInputRef={"dateFrom"}
            placeholderText={t("from")}
            popperPlacement="bottom-end"
            locale="en-GB"
          />
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={selectedTo}
            onChange={(date: DateType) => setSelectedTo(date)}
            customInput={<CustomDatePickerInput />}
            customInputRef={"dateTo"}
            placeholderText={t("to")}
            popperPlacement="bottom-end"
            locale="en-GB"
          />
          <RoundedButton
            text={t("search")}
            className={styles.searchButton}
            onClick={onSearchNews}
          />
        </Flex>
      </Flex>
    </>
  );
}
