import { Observable } from "rxjs";
import { tagsApiService } from "../../../api/services/tags.service";
import { ITag } from "./custom-tag.model";
import { CustomTagsQuery } from "./custom-tags.query";
import { CustomTagsStore } from "./custom-tags.store";
import TAG_TYPE from "../../../api/enums/tags.enum";

export interface ITagsContext {
  userTags$: Observable<ITag[]>;

  saveCustomTag: (name: string, color_code: string, type?: string) => Promise<ITag>;
  deleteCustomTag: (tagId: number) => Promise<void>;
  fetchTagsForCurrentUser: () => Promise<void>;
  getCustomTag: (tagId: number) => void;
}

export class CustomTagsFacade implements ITagsContext {
  userTags$: Observable<ITag[]> = this.query.selectAll();

  constructor(private store: CustomTagsStore, private query: CustomTagsQuery) { }

  async saveCustomTag(name: string, color_code: string, type?: string): Promise<ITag> {
    try {
      const res = await tagsApiService.saveCustomTag({
        name,
        color_code,
        type,
      });

      this.store.add(res);

      return res;
    } catch (error) {
      console.warn("Error while saving custom tag: ", error);
    }
  }

  async deleteCustomTag(tagId: number): Promise<void> {
    try {
      await tagsApiService.deleteCustomTag(tagId);

      this.store.remove(tagId);
    } catch (error) {
      console.warn("Error while deleting custom tag: ", error);
    }
  }

  getCustomTag(tagId: number) {
    return this.query.getEntity(tagId);
  }

  async fetchTagsForCurrentUser(): Promise<void> {
    try {
      const [companiesTagsRes, peopleTagsRes] = await Promise.all([
        tagsApiService.getAllTagsForCurrentUser(),
        tagsApiService.getPeopleTagsForCurrentUser(),
      ]);

      let id = 99999;
      const companiesTagsArr = companiesTagsRes.map((tag) => {
        if (!tag.type || tag.type === "") {
          tag.type = TAG_TYPE.COMPANIES;
        }
        if (!tag.id) {
          tag.id = id;
        }
        id++;
        return tag;
      });

      const peopleTagsArr = peopleTagsRes
        .filter((tag) => tag.type === undefined)
        .map((tag) => {
          if (!tag.type || tag.type === "") {
            tag.type = TAG_TYPE.PEOPLE;
          }
          if (!tag.id) {
            tag.id = id;
          }
          id++;
          return tag;
        });

      const arr = [...companiesTagsArr, ...peopleTagsArr];
      this.store.set(arr);
    } catch (error) {
      console.warn("Error while getting tags for current user: ", error);
    }
  }
}
