import React, { ReactNode, useContext } from "react";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import RText from "../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../components/spacing/component";
import useAppColors from "../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../hooks/session.hook";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  col1Data: string | ReactNode;
  col2Data: string | ReactNode;
  col3Data: string | ReactNode;
  col4Data?: string | ReactNode;
  header?: boolean;
}

const TableRow = ({ col1Data, col2Data, col3Data, col4Data, header }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme, isB2CUser } = useSession();
  const { colors } = useAppColors();

  return (
    <Flex
      alignItemsCenter
      style={
        header
          ? {
              height: isSmallerScreen ? "30px" : "50px",
              padding: "0px 15px",
              backgroundColor: isDarkTheme
                ? "var(--immutable_gray_dark)"
                : isB2CUser
                ? colors.primaryLightColor
                : "var(--color_gray_light)",
            }
          : {
              padding: "25px 15px",
            }
      }
    >
      <Flex column style={{ flex: 2 }}>
        {typeof col1Data === "string" ? (
          <RText
            bold={header}
            fontSize={isSmallerScreen ? "11" : "16"}
            textTooltip={col1Data}
            ellipsis
          >
            {col1Data}
          </RText>
        ) : (
          <>{col1Data}</>
        )}
      </Flex>
      <Spacing pr="10" />
      <Flex column flex>
        {typeof col2Data === "string" ? (
          <RText
            bold={header}
            fontSize={isSmallerScreen ? "11" : "16"}
            textTooltip={col2Data}
            ellipsis
          >
            {col2Data}
          </RText>
        ) : (
          <>{col2Data}</>
        )}
      </Flex>
      <Spacing pr="10" />
      <Flex column flex>
        {typeof col3Data === "string" ? (
          <RText
            bold={header}
            fontSize={isSmallerScreen ? "11" : "16"}
            textTooltip={col3Data}
            ellipsis
          >
            {col3Data}
          </RText>
        ) : (
          <>{col3Data}</>
        )}
      </Flex>
      {!!col4Data && (
        <>
          <Spacing pr="10" />
          <Flex column flex>
            {typeof col4Data === "string" ? (
              <RText
                bold={header}
                fontSize={isSmallerScreen ? "11" : "16"}
                textTooltip={col4Data}
                ellipsis
              >
                {col4Data}
              </RText>
            ) : (
              <>{col4Data}</>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default TableRow;
