import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { IExecutiveNote } from "../state/executive-notes.model";
import { ExecutiveNotesContext } from "../state/executive-notes.context";

interface IExecutiveNotesHook {
  executiveNotes: IExecutiveNote[];
}

export function useExecutiveNotes(): IExecutiveNotesHook {
  const executiveNotesService = useContext(ExecutiveNotesContext);

  const [executiveNotes] = useObservable(executiveNotesService.executiveNotes$, []);

  return {
    executiveNotes,
  };
}
