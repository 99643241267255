import React, { useContext, useEffect, useRef, useState } from "react";
import {
  //SideNavigation,
  IMenuItem,
} from "../components/side-navigation/SideNavigation";
import { CorporateDossierContext } from "../../../../../entities/corporate-dossier/corporate-dossier.context";
import { ICorporate } from "../../../../../entities/corporates/models/corporate.model";
import {
  SideNavHeadcountIcon,
  SideNavIndustryIcon,
  SideNavInvestmentsIcon,
  SideNavLookIcon,
  SideNavManagementIcon,
  SideNavNewsIcon,
  SideNavProductTrendsIcon,
  SideNavRelationshipIcon,
  SideNavShareholdersIcon,
  SideNavTurnoverIcon,
  SideNavTurnoverDarkIcon,
} from "../../../../../assets/company-dossier/sidenav/icons";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { formatIndustrySectorForTranslation } from "../../../../../helpers/translation.helper";
import { mapCorporateToDossierCorporateData } from "../../../../../helpers/dossier.helper";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import styles from "../styles.module.css";
import SideNavigationItemContent from "../components/side-navigation-item-content/SideNavigationItemContent";
import { useSession } from "../../../../../hooks/session.hook";
import axios from "axios";
import { SIDE_NAVIGATION_ITEM_TYPE } from "../components/side-navigation/side-navigation-item-type.enum";

interface IProps {
  activeCorporate: ICorporate;
  productTrends: string | null;
  news: string | null;
  mainIndustry: string | null;
  headcount: string | null;
  turnover: string | null;
  shareholders: string | null;
  investment: string | null;
  management: string | null;
  relationshipIndicator: string | null;
  lookALikes: string | null;
}

const CorporateDossierContentContainer = ({
  activeCorporate,
  productTrends,
  news,
  mainIndustry,
  headcount,
  turnover,
  shareholders,
  investment,
  management,
  relationshipIndicator,
  lookALikes,
}: IProps) => {
  const { t } = useTranslation();
  const { isDarkTheme } = useSession();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const [selectedItem, setSelectedItem] = useState<SIDE_NAVIGATION_ITEM_TYPE | null>(null);
  const isSwiss = window.location.pathname.includes("/ch/");

  const cancelToken = useRef(axios.CancelToken.source());

  const corporatesContext = useContext(CorporateDossierContext);

  const companyElasticData = [
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.MAIN_INDUSTRY,
      value: mainIndustry !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.HEADCOUNT,
      value: headcount !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.TURNOVER,
      value: turnover !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.SHAREHOLDERS,
      value: shareholders !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.INVESTMENT,
      value: investment !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.MANAGEMENT,
      value: management !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.EXTERNAL_LINKS,
      value: true,
    },
  ];

  const menuItems: IMenuItem[] = [
    {
      title: "productTrends",
      value: productTrends,
      Icon: SideNavProductTrendsIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.PRODUCT_TRENDS,
      anchor: "productTrends",
    },
    {
      title: "news",
      value: news,
      Icon: SideNavNewsIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.NEWS,
      anchor: "news",
    },
    {
      title: "factsheet",
      value: mainIndustry && formatIndustrySectorForTranslation(mainIndustry, t),
      Icon: SideNavIndustryIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.MAIN_INDUSTRY,
      anchor: "factsheet",
    },
    {
      title: "headcount",
      value: headcount,
      Icon: SideNavHeadcountIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.HEADCOUNT,
      anchor: "headcount",
    },
    {
      title: "turnover",
      value: turnover && `${turnover} ${isSwiss ? " CHF" : " €"}`,
      Icon: isDarkTheme ? SideNavTurnoverDarkIcon : SideNavTurnoverIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.TURNOVER,
      anchor: "turnover",
    },
    {
      title: "shareholders",
      value: shareholders,
      Icon: SideNavShareholdersIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.SHAREHOLDERS,
      anchor: "shareholders",
    },
    {
      title: "investment",
      value: investment,
      Icon: SideNavInvestmentsIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.INVESTMENT,
      anchor: "investment",
    },
    {
      title: "management",
      value: management,
      Icon: SideNavManagementIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.MANAGEMENT,
      anchor: "management",
    },
    {
      title: "relationshipIndicator",
      value: relationshipIndicator,
      Icon: SideNavRelationshipIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR,
      anchor: "relationshipIndicator",
    },
    {
      title: "lookALikes",
      value: lookALikes,
      Icon: SideNavLookIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.LOOK_A_LIKES,
      anchor: "lookALikes",
    },
  ];

  useEffect(
    () => () => {
      cancelToken.current.cancel("Navigating away from the requesting component");
    },
    [],
  );

  useEffect(() => {
    let isMounted = true;

    isMounted && setSelectedItem(null);

    if (!activeCorporate) {
      return;
    }

    const corporateData = mapCorporateToDossierCorporateData(activeCorporate);
    const resolveValue =
      <T extends unknown>(type: SIDE_NAVIGATION_ITEM_TYPE) =>
      (value: T) => ({ type, value });

    Promise.allSettled([
      corporatesContext
        .fetchCorporateProductTrends(
          activeCorporate.id,
          corporateData,
          cancelToken.current.token,
        )
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.PRODUCT_TRENDS)),
      corporatesContext
        .fetchCorporateNews(activeCorporate.id, corporateData, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.NEWS)),
      corporatesContext
        .fetchCorporateRelationshipIndicator(activeCorporate.id, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR)),
      corporatesContext
        .fetchCorporateLookALikes(activeCorporate.id, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.LOOK_A_LIKES)),
    ]).then((response) => {
      if (selectedItem !== null) {
        return;
      }

      const navigationItems = [...companyElasticData];
      response.forEach((item) => {
        if (item.status === "rejected") return;
        navigationItems.push(item.value);
      });
      const sortedNavigationItems = navigationItems.sort((a, b) => (a.type > b.type ? 1 : -1));

      for (let i = 0; i < sortedNavigationItems.length; i++) {
        const { type, value } = sortedNavigationItems[i];

        if (value) {
          corporatesContext.setCorporateSideNavigationActiveAnchor(menuItems[type].anchor);

          isMounted && setSelectedItem(type);
          return;
        }
      }
    });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCorporate.id]);

  // const onSetActiveItem = (item: IMenuItem) => (activeAnchor: string) => {
  //     if (activeAnchor === item.anchor) {
  //         setSelectedItem(item.type);
  //     }
  // };

  return (
    // <Flex>
    //     {/* {!isSmallerScreen && (
    //         <Flex column>
    //             <SideNavigation
    //                 menuItems={menuItems}
    //                 selectedItem={selectedItem}
    //                 onSetActiveItem={onSetActiveItem}
    //             />
    //         </Flex>
    //     )} */}

    //     {/* {!isSmallerScreen && <Spacing pr="70" />} */}

    // </Flex>

    <Flex grow column className={!isSmallerScreen && styles.rightColumn}>
      {activeCorporate && <SideNavigationItemContent activeCorporate={activeCorporate} />}
    </Flex>
  );
};

export default CorporateDossierContentContainer;
