import React from "react";
import { createShareholderCompanies } from "../../../../../../../entities/corporates/models/shareholders-companies.model";
import { IShareholder } from "../../../../../../../entities/executives/interfaces/shareholder.interface";
import CorporateInvestment from "../../../../../corporates/dossier/components/side-navigation-item-content/investment/Investment";

interface IProps {
  investmentData: IShareholder[];
}

export default function Investment({ investmentData }: IProps) {
  if (!investmentData || investmentData?.length === 0) {
    return null;
  }

  return (
    <CorporateInvestment
      shareholdersCompanies={investmentData.map(createShareholderCompanies) ?? []}
    />
  );
}
