import i18n from "i18next";

/**
 * Returns number with dot separator.
 *
 * Example:
 *  1000 => 1.000
 *  10000 => 10.000
 */
export function formatNumberWithDotSeparator(num: number) {
  /**
   * Should use replaceAll(",", ".") once it gets more browser support
   */
  return num.toLocaleString().replace(/,/g, ".");
}

export function isEven(num: number) {
  return num % 2 === 0;
}

/**
 * Returns abbreviate number for some huge number.
 * example:
 *      1234 => 1.2 K
 *      1234567 => 1.2 M
 *
 * Taken from:
 *      https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn/32638472
 */
export function abbreviateNumber(num: any, fixed = 0) {
  if (!num || num === 0) {
    return "0";
  }

  const precision = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1 ? num.toFixed(0 + precision) : (num / Math.pow(10, k * 3)).toFixed(1 + precision), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e =
      d + ["", ` ${i18n.t("k")}`, ` ${i18n.t("m")}`, ` ${i18n.t("b")}`, ` ${i18n.t("t")}`][k]; // append power

  return e;
}
