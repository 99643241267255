import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ICorporateTag } from "./corporate-tags.model";

export interface CorporateTagsState extends EntityState<ICorporateTag> {}

@StoreConfig({
  name: "corporate-tags",
})
export class CorporateTagsStore extends EntityStore<CorporateTagsState> {}

export const corporateTagsStore = new CorporateTagsStore();
