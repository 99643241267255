import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { ReactComponent as ChevronRight } from "../../../../../../assets/ChevronRight.svg";
import { Flex } from "../../../../../../components/containers/Flex/Flex";
import { FlexChild } from "../../../../../../components/containers/FlexChild/FlexChild";
import Divider from "../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../components/spacing/component";
import { IMenuItem } from "./SideNavigation";
import useAppColors from "../../../../../../hooks/app-colors.hook";

interface IProps {
  item: IMenuItem;
  selected: boolean;
  onSetActiveItem: (item: IMenuItem) => (to: string) => void;
}

const MenuItem = ({ item, selected, onSetActiveItem }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useAppColors();

  const [itemValue, setItemValue] = useState(item.value);

  useEffect(() => {
    setItemValue(item.value);
  }, [item]);

  const MenuItemIcon = item.Icon;

  return (
    <>
      <Link
        className={`
                    ${styles.menuItem}
                    ${!itemValue ? styles.disabled : ""}
                    ${selected ? styles.active : ""}
                `}
        onSetActive={onSetActiveItem(item)}
        containerId="contentContainerElement"
        to={itemValue ? item.anchor : ""}
        spy={true}
        smooth={"easeInOutQuart"}
        duration={500}
      >
        <Flex alignItemsCenter>
          <FlexChild displayFlex flex1 alignItemsCenter>
            <MenuItemIcon color={colors.backgroundBlackColor} className={styles.sideNavIcon} />
            <Spacing pl="28" />
            <Flex alignItemsCenter>
              <RText fontSize="16">
                {`
                                    ${t(item.title)}${itemValue ? ":" : ""}
                                `}
              </RText>
              <RText fontSize="16" className={styles.itemValue}>
                {itemValue ?? ""}
              </RText>
            </Flex>
          </FlexChild>
          <Spacing pr="10" />
          <ChevronRight color={colors.backgroundBlackColor} className={styles.rightIcon} />
          <Spacing pr="20" />
        </Flex>
      </Link>
      {selected ? <Divider black /> : <Divider />}
    </>
  );
};

export default MenuItem;
