import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { ExecutiveHubContext } from "../state/executives-hub.context";
import useDebounce from "../../../hooks/debounce.hook";

interface IHubRolloverExecutiveHook {
  hubRolloverExecutiveId: string;
}

export const useHubRolloverExecutive = (): IHubRolloverExecutiveHook => {
  const executivesHubService = useContext(ExecutiveHubContext);

  const [hubRolloverExecutiveId] = useObservable(
    executivesHubService.hubRolloverExecutiveId$,
    null,
  );
  const debouncedHubRolloverExecutiveId = useDebounce(hubRolloverExecutiveId, 300);

  return {
    hubRolloverExecutiveId: debouncedHubRolloverExecutiveId,
  };
};
