export interface IShareholder {
  capital_currency?: any;
  capital?: any;
  company_id: number;
  company_name: string;
  from_date: string;
  from_quality: number;
  industry_sector: string;
  position?: any;
  relation_type: string;
  share_perc?: any;
  to_date?: any;
  to_quality?: any;
  affinity_score?: number;
  employee_count: number;
  turnover: number;
  ee_score: number;
  city: string;
  country: string;
  legal_type: string;
  street: string;
  zip_code: number;
}

export function createShareholder(params: Partial<IShareholder>) {
  return {
    company_id: null,
    company_name: null,
    industry_sector: null,
    from_date: null,
    share_perc: null,
    affinity_score: null,
    employee_count: null,
    turnover: null,
    ee_score: null,
    ...params,
  } as IShareholder;
}
