import React, { Fragment, useContext } from "react";
import styles from "./styles.module.css";
import { Flex } from "../containers/Flex/Flex";
import { Spacing } from "../spacing/component";
import Divider from "../dividers/Divider/Divider";
import RText from "../fonts/RText/RText";
import { RELATIONSHIP_NAVIGATION_ITEM_TYPE } from "../../pages/home/corporates/dossier/components/side-navigation-item-content/relationship-indicator/Navigation";
import { INVESTMENT_NAVIGATION_ITEM_TYPE } from "../../pages/home/corporates/dossier/components/side-navigation-item-content/investment/Navigation";
import { MANAGEMENT_NAVIGATION_ITEM_TYPE } from "../../pages/home/corporates/dossier/components/side-navigation-item-content/management/Navigation";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";

type NavigationItemType =
  | INVESTMENT_NAVIGATION_ITEM_TYPE
  | MANAGEMENT_NAVIGATION_ITEM_TYPE
  | RELATIONSHIP_NAVIGATION_ITEM_TYPE;

export interface INavigationItem {
  title: string;
  type: NavigationItemType;
  exist: boolean;
}

interface IProps {
  navigationItems: INavigationItem[];
  selectedItem: NavigationItemType;
  onSelectItem: (item: INavigationItem) => void;
}

export default function RNavigation({ navigationItems, selectedItem, onSelectItem }: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <Flex>
      {navigationItems.map((item, index) => {
        return (
          <Fragment key={index}>
            <Flex
              column
              pointer={item.exist}
              onClick={() => onSelectItem(item)}
              className={styles.itemContainer}
            >
              <Spacing ph="8">
                <RText
                  fontSize={isSmallerScreen ? "13" : "16"}
                  className={`
                                        ${styles.navItemTitle}
                                        ${
                                          selectedItem === item.type
                                            ? styles.selectedNavItem
                                            : ""
                                        }
                                        ${!item.exist ? styles.disabled : ""}
                                    `}
                >
                  {item.title}
                </RText>
              </Spacing>
              <Spacing pt="12" />
              {selectedItem === item.type && (
                <Divider black className={styles.selectedDividerLine} />
              )}
            </Flex>
          </Fragment>
        );
      })}
    </Flex>
  );
}
