const https = `${process.env.REACT_APP_API_HTTPS}`;

export const API_URL_V1 = `${https}${process.env.REACT_APP_API_URL}/api/v1`;
export const API_LOGOS_URL = `${https}${process.env.REACT_APP_API_URL}/images/logos/`;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// If there is no map specified in the environment, revert back to the original OSM style
export const MAP_TRANSPORT_LAYER =
  process.env.REACT_APP_MAP_TRANSPORT_LAYER ??
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

const arcGISBaseUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/";

const arcGISLayers = {
  worldImagery: "World_Imagery",
  worldTerrain: "World_Terrain_Base",
  worldBoundariesPlaces: "Reference/World_Boundaries_and_Places",
};

const arcGISSuffix = "/MapServer/tile/{z}/{y}/{x}";

export const MAP_SATELLITE_LAYER =
  process.env.REACT_APP_MAP_SATELLITE_LAYER ??
  `${arcGISBaseUrl}${arcGISLayers.worldImagery}${arcGISSuffix}`;

export const MAP_CITIES_AND_PLACES_LAYER =
  process.env.REACT_APP_MAP_CITIES_AND_PLACES_LAYER ??
  `${arcGISBaseUrl}${arcGISLayers.worldBoundariesPlaces}${arcGISSuffix}`;

export const OPENROUTESERVICE_API_KEY = process.env.REACT_APP_OPENROUTESERVICE_API_KEY;
