import React from "react";
import { spaceWithName } from "../map";

interface Props {
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  ph?: string;
  pv?: string;
  p?: string;
  children?: React.ReactNode;
  className?: string;
  pointer?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const Spacing = ({
  pt,
  pb,
  pl,
  pr,
  ph,
  pv,
  p,
  children,
  className,
  pointer,
  style,
  onClick,
}: Props) => {
  if (p) {
    pt = pb = pl = pr = p;
  }
  if (ph) {
    pl = pr = ph;
  }
  if (pv) {
    pt = pb = pv;
  }

  return (
    <div
      style={{
        paddingTop: pt && spaceWithName(pt),
        paddingBottom: pb && spaceWithName(pb),
        paddingLeft: pl && spaceWithName(pl),
        paddingRight: pr && spaceWithName(pr),
        cursor: pointer && "pointer",
        ...style,
      }}
      onClick={onClick}
      {...{ className, onClick, children }}
    />
  );
};
