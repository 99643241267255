import React from "react";
import paragraphStyles from "./styles.module.css";
import { IFontProps } from "../../types/component.models";
import { classNames } from "../../../helpers/string.helper";

interface Props extends IFontProps {
  styles: any;
}

export const Paragraph = ({
  styles,
  children,
  className,
  fontSize,
  textTooltip,
  elementStyle,
  ...props
}: Props) => (
  <p
    style={{
      fontSize: fontSize && fontSize + "px",
      ...elementStyle,
    }}
    className={`
            ${classNames(paragraphStyles, props)}
            ${classNames(styles, { className })}
        `}
    title={textTooltip}
  >
    {children}
  </p>
);
