import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import { isEven } from "../../../../../../../helpers/number.helper";
import VerticalDivider from "../../../../../../../components/dividers/VerticalDivider/VerticalDivider";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  vatId: string;
  duns: number;
  registrationNum: string;
  registrationCity: string;
  registrationZip: number;
  purpose: string;
}

const CorporateInfo = ({
  vatId,
  duns,
  registrationNum,
  registrationCity,
  registrationZip,
  purpose,
}: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);

  const identifierItems = [
    {
      title: t("registrationNumber"),
      value: registrationNum ? registrationNum.toString() : "-",
    },
    {
      title: t("commercialRegister"),
      value: buildCommercialRegister(registrationZip, registrationCity),
    },
    {
      title: t("vatId"),
      value: vatId ?? "-",
    },
    {
      title: t("dunsNumber"),
      value: duns ? duns.toString() : "-",
    },
  ];

  return (
    <>
      <Divider />
      <Spacing pv="30">
        <RText fontSize={isSmallerScreen ? "13" : "20"}>{t("corporateMission")}</RText>
        <Spacing pt={isSmallerScreen ? "15" : "30"} />
        <RText fontSize={isSmallerScreen ? "11" : "16"} className={styles.text}>
          {purpose}
        </RText>
      </Spacing>
      <Divider />
      <Spacing pv="30">
        <RText fontSize={isSmallerScreen ? "13" : "20"}>{t("identifier")}</RText>
        <Spacing pt={isSmallerScreen ? "15" : "30"} />
        <Flex>
          <Flex column>
            {identifierItems.map(
              (item, index) =>
                isEven(index) && (
                  <Fragment key={index}>
                    {renderItemRow(item.title, item.value, true, isSmallerScreen)}
                    <Spacing pb={isSmallerScreen ? "10" : "20"} />
                  </Fragment>
                ),
            )}
          </Flex>
          <Spacing pr="50" />
          <Flex column>
            {identifierItems.map(
              (item, index) =>
                !isEven(index) && (
                  <Fragment key={index}>
                    {renderItemRow(item.title, item.value, false, isSmallerScreen)}
                    <Spacing pb={isSmallerScreen ? "10" : "20"} />
                  </Fragment>
                ),
            )}
          </Flex>
        </Flex>
      </Spacing>
    </>
  );
};

export default React.memo(CorporateInfo);

const buildCommercialRegister = (registrationZip: number, registrationCity: string) => {
  let commercialRegister = "-";

  if (registrationZip && registrationCity) {
    commercialRegister = registrationZip + " " + registrationCity;
  }

  return commercialRegister;
};

const renderItemRow = (
  title: string,
  value: string,
  showDivider: boolean,
  isSmallerScreen: boolean,
) => {
  return (
    <Flex justifyContentSpaceBetween alignItemsCenter className={styles.itemRowContainer}>
      <Flex column alignSelfStart className={styles.itemRowTextContainer}>
        <RText fontSize={isSmallerScreen ? "13" : "18"} noWrap>
          {title}
        </RText>
        <Spacing pt="15" />
        <RText fontSize={isSmallerScreen ? "11" : "16"} noWrap centered={value === "-"}>
          {value}
        </RText>
      </Flex>
      {showDivider && (
        <>
          <Spacing pr="50" />
          <VerticalDivider />
        </>
      )}
    </Flex>
  );
};
