import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { IExecutiveHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";
import { ExecutiveHubContext } from "../state/executives-hub.context";

interface ITopNewsHook {
  hubNewsFooterItems: IExecutiveHubFooterItemData[] | null;
  loadingTopNews: boolean;
}

export const useTopNews = (): ITopNewsHook => {
  const executiveHubContext = useContext(ExecutiveHubContext);

  const [hubNewsFooterItems] = useObservable(
    executiveHubContext.hubNewsFooterItems$,
    executiveHubContext.getHubNewsFooterItems(),
  );

  const [loadingTopNews] = useObservable(executiveHubContext.loadingTopNews$, true);

  return {
    hubNewsFooterItems,
    loadingTopNews,
  };
};
