import { EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";

export interface OpportunitiesFiltersState {}
export interface OpportunitiesFiltersUIState {
  searching: boolean;
  lastSearchTerm: string;
  nextSearchPage: number;
  searchResultTotalCount: number;
  searchResultIds: string[];
}

@StoreConfig({
  name: "opportunities-filters",
})
export class OpportunitiesFiltersStore extends EntityStore<OpportunitiesFiltersState> {
  ui: EntityUIStore<OpportunitiesFiltersUIState>;

  constructor() {
    super({ loading: null });

    this.createUIStore({
      searching: null,
      lastSearchTerm: "",
      nextSearchPage: 1,
      searchResultTotalCount: null,
      searchResultIds: null,
    });
  }
}

export const opportunitiesFiltersStore = new OpportunitiesFiltersStore();
