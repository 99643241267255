import { useCallback, useRef, useState } from "react";

export interface IClickHandlers {
  onMouseDown: (event: React.MouseEvent) => void;
  onMouseUp: (event: React.MouseEvent) => void;
  onMouseLeave: (event: React.MouseEvent) => void;
  onTouchStart: (event: React.TouchEvent) => void;
  onTouchEnd: (event: React.TouchEvent) => void;
}

const useClickHandlers = (
  onLongPress: () => void,
  onClick: () => void,
  onClear: () => void,
  { shouldPreventDefault = true, delay = 300 } = {},
): IClickHandlers => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const target = useRef<HTMLElement>();

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target?.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }

      timeout.current = setTimeout(() => {
        onLongPress();
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      onClear();
      timeout.current && clearTimeout(timeout.current);

      shouldTriggerClick && !longPressTriggered && onClick();
      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target.current?.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered, onClear],
  );

  return {
    onMouseDown: (event: React.MouseEvent) => start(event),
    onMouseUp: (event: React.MouseEvent) => clear(event),
    onMouseLeave: (event: React.MouseEvent) => clear(event, false),
    onTouchStart: (event: React.TouchEvent) => start(event),
    onTouchEnd: (event: React.TouchEvent) => clear(event),
  };
};

const isTouchEvent = (event: any) => {
  return "touches" in event;
};

const preventDefault = (event: any) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useClickHandlers;
