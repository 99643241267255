// Default colors for B2B and B2C users.

const b2bDefaultColors = {
  PRIMARY_DARK_COLOR: "#9ff5b9",
  PRIMARY_LIGHT_COLOR: "#dcfde5",
  SECONDARY_DARK_COLOR: "#0E67EF",
  SECONDARY_LIGHT_COLOR: "#dae0f1",
  TEXT_DARK_COLOR: "#0f0f0f",
  TEXT_LIGHT_COLOR: "#ffffff",
  HOVER_SELECTED_LIGHT_COLOR: "#f5f5f5",
  HOVER_SELECTED_DARK_COLOR: "#545454",
  BACKGROUND_BLACK_COLOR: "#0f0f0f",
  BACKGROUND_WHITE_COLOR: "#ffffff",
  DARK_MODE_BACKGROUND_WHITE_COLOR: "#2B2A2A",
  DARK_MODE_PRIMARY_DARK_COLOR: "#6AFC9D",
  DARK_MODE_PRIMARY_LIGHT_COLOR: "#306f4e",
  DARK_MODE_SECONDARY_DARK_COLOR: "#0E67EF",
  DARK_MODE_SECONDARY_LIGHT_COLOR: "#014d98",
};

const b2cDefaultColors = {
  PRIMARY_DARK_COLOR: "#d5c599",
  PRIMARY_LIGHT_COLOR: "#e9dfbd",
  SECONDARY_DARK_COLOR: "#d5c599",
  SECONDARY_LIGHT_COLOR: "#f5f5f5",
  TEXT_DARK_COLOR: "#0f0f0f",
  TEXT_LIGHT_COLOR: "#ffffff",
  HOVER_SELECTED_LIGHT_COLOR: "#faf9f5",
  HOVER_SELECTED_DARK_COLOR: "#424241",
  BACKGROUND_BLACK_COLOR: "#0f0f0f",
  BACKGROUND_WHITE_COLOR: "#ffffff",
  DARK_MODE_BACKGROUND_WHITE_COLOR: "#2B2A2A",
  DARK_MODE_PRIMARY_DARK_COLOR: "#d5c599",
  DARK_MODE_PRIMARY_LIGHT_COLOR: "#e9dfbd",
  DARK_MODE_SECONDARY_DARK_COLOR: "#d5c599",
  DARK_MODE_SECONDARY_LIGHT_COLOR: "#f5f5f5",
};

export { b2bDefaultColors, b2cDefaultColors };
