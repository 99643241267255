import React, { useContext, useEffect, useState } from "react";
import { IExecutiveMarkerData } from "../../../../entities/executives/interfaces/executive-marker-data.interface";
import { useTranslation } from "react-i18next";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
// import { useMap } from "react-leaflet";
import useAppColors from "../../../../hooks/app-colors.hook";
import { Flex } from "../../../../components/containers/Flex/Flex";
import { ReactComponent as ChevronLeftBlueIcon } from "../../../../assets/ChevronLeftBlue.svg";
import styles from "../../../hub/HubMain/HubMarkerPopup/styles.module.css";
import RText from "../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../components/spacing/component";
import { ReactComponent as ChevronRightBlueIcon } from "../../../../assets/ChevronRightBlue.svg";
import Divider from "../../../../components/dividers/Divider/Divider";
// import { ReactComponent as AddToRouteIcon } from "../../../../assets/hub/map-icons/AddToRouteIcon.svg";
import HubMarkerPopupExecutive from "./HubMarkerPopupExecutive/HubMarkerPopupExecutive";
import { useHubRolloverExecutive } from "../../../../modules/executives-hub/hooks/hub-rollover-executive";

interface IProps {
  markerExecutives: IExecutiveMarkerData[];
  isExecutiveLookALike?: boolean;
  // addToRouteData?: {
  //     waypoints: { [key: string]: number[] };
  //     getExecutiveEndCoordinates: (
  //         lat: number,
  //         lng: number,
  //         executiveId: string
  //     ) => void;
  // };
}

const ExecutivesHubMarkerPopup = ({
  markerExecutives,
  isExecutiveLookALike, // addToRouteData,
}: IProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  // const map = useMap();
  const { colors } = useAppColors();

  const { hubRolloverExecutiveId } = useHubRolloverExecutive();
  const [selectedExecutiveIndex, setSelectedExecutiveIndex] = useState(0);

  useEffect(() => {
    if (markerExecutives?.length <= 1) {
      return;
    }

    if (!hubRolloverExecutiveId) {
      setSelectedExecutiveIndex(0);
    } else {
      const rolloverExecutiveIndex = markerExecutives.findIndex(
        (marker) => marker.executiveId === hubRolloverExecutiveId,
      );

      setSelectedExecutiveIndex(rolloverExecutiveIndex !== -1 ? rolloverExecutiveIndex : 0);
    }
  }, [hubRolloverExecutiveId, markerExecutives]);

  const selectNextExecutive = () => {
    if (selectedExecutiveIndex === markerExecutives.length - 1) return;
    setSelectedExecutiveIndex(selectedExecutiveIndex + 1);
  };

  const selectPreviousExecutive = () => {
    if (selectedExecutiveIndex === 0) return;
    setSelectedExecutiveIndex(selectedExecutiveIndex - 1);
  };

  // const onAddToRoute = () => {
  //     if (!addToRouteData) {
  //         return;
  //     }
  //
  //     // // TODO: need to see how precise executive data is before adding to route
  //     //
  //     // const { waypoints, getEndCoordinates } = addToRouteData;
  //     // const executive = markerExecutives[selectedExecutiveIndex];
  //     //
  //     // // Prevent calling API if route is already calculated for given company.
  //     // if (waypoints.hasOwnProperty(executive.executiveId)) {
  //     //     return;
  //     // }
  //     //
  //     // getEndCoordinates(
  //     //     executive.lon,
  //     //     executive.lat,
  //     //     executive.executiveId,
  //     // );
  //
  //     map.closePopup();
  // };

  return (
    <Flex column>
      <Flex justifyContentSpaceBetween>
        <ChevronLeftBlueIcon
          color={colors.secondaryDarkColor}
          className={styles.companiesControl}
          onClick={selectPreviousExecutive}
        />
        <Flex alignItemsCenter>
          <RText immutableBlack bold fontSize={isSmallerScreen ? "11" : "12"}>{`${
            selectedExecutiveIndex + 1
          }`}</RText>
          <Spacing ph="2">|</Spacing>
          <RText immutableBlack fontSize={isSmallerScreen ? "11" : "12"}>
            {`${markerExecutives.length} ${t("executives")}`}
          </RText>
        </Flex>
        <ChevronRightBlueIcon
          color={colors.secondaryDarkColor}
          className={styles.companiesControl}
          onClick={selectNextExecutive}
        />
      </Flex>

      <Spacing pb="10" />

      <Divider immutableBlack />

      <Spacing pb="2" />

      <HubMarkerPopupExecutive markerExecutive={markerExecutives[selectedExecutiveIndex]} />

      {isExecutiveLookALike && (
        <div className={styles.addToRouteContainer}>
          <Divider />
          {/*<Spacing pv="10">*/}
          {/*    <Flex alignItemsCenter justifyContentCenter>*/}
          {/*        <Flex*/}
          {/*            pointer*/}
          {/*            alignItemsCenter*/}
          {/*            onClick={onAddToRoute}*/}
          {/*        >*/}
          {/*            <RText*/}
          {/*                fontSize={isSmallerScreen ? "11" : "15"}*/}
          {/*                primaryBlue*/}
          {/*                bold*/}
          {/*            >*/}
          {/*                {t("addToRoute")}*/}
          {/*            </RText>*/}
          {/*            <Spacing pr="2" />*/}
          {/*            <AddToRouteIcon*/}
          {/*                color={colors.secondaryDarkColor}*/}
          {/*                className={styles.addToRouteIcon}*/}
          {/*            />*/}
          {/*        </Flex>*/}
          {/*    </Flex>*/}
          {/*</Spacing>*/}
        </div>
      )}
    </Flex>
  );
};

export default ExecutivesHubMarkerPopup;
