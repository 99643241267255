import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { map } from "rxjs/operators";
import { corporatesTitleKeys, marketDriversTitleKeys } from "../../../constants/hub";
import { ICorporatesFilterWithUI } from "../../../helpers/filters.helper";
import { IHubFilterGroup } from "../../../shared/interfaces/hub-filter-group.interface";
import { CorporatesFiltersContext } from "../state/corporates-filters.context";

const extractFiltersIntoGroups = (
  filterWithUIs: ICorporatesFilterWithUI[],
): IHubFilterGroup[] => {
  const corporatesFilterGroup: IHubFilterGroup = {
    title: "corporates",
    count: 0,
    filters: [],
  };
  const marketDriversFilterGroup: IHubFilterGroup = {
    title: "marketDrivers",
    count: 0,
    filters: [],
  };
  // const newsFilterGroup: ICorporatesFilterGroup = {
  //     title: "news",
  //     count: 10,
  //     filters: [],
  // };

  filterWithUIs.forEach((filterWithUI) => {
    if (corporatesTitleKeys.includes(filterWithUI.filter.title_key)) {
      corporatesFilterGroup.filters.push(filterWithUI);
    }

    if (marketDriversTitleKeys.includes(filterWithUI.filter.title_key)) {
      marketDriversFilterGroup.filters.push(filterWithUI);
    }

    // if (
    //     newsTitleKeys.includes(filterWithUI.filter.title_key.toLowerCase())
    // ) {
    //     newsFilterGroup.filters.push(filterWithUI);
    // }
  });

  return [
    corporatesFilterGroup,
    marketDriversFilterGroup,
    // newsFilterGroup,
  ];
};

interface IFilterWithUIGroupsHook {
  filterWithUIGroups: IHubFilterGroup[];
  loadingCorporatesFilters: boolean;
}

export const useCorporatesFilterWithHubUIGroups = (): IFilterWithUIGroupsHook => {
  const corporatesFiltersService = useContext(CorporatesFiltersContext);

  const [loadingCorporatesFilters] = useObservable(corporatesFiltersService.loading$, null);

  const [filterWithUIGroups] = useObservable(
    corporatesFiltersService.allWithHubUI$.pipe(map(extractFiltersIntoGroups)),
    [],
  );

  return {
    filterWithUIGroups,
    loadingCorporatesFilters,
  };
};
