import { createContext, ReactNode, useState } from "react";

interface IMapContextProvider {
  children?: ReactNode;
}

interface ILatLng {
  lat: number;
  lng: number;
}

interface IMapState {
  radius: number | null;
  center: ILatLng | null;
}

export const MapContext = createContext(null);

export const MapContextProvider = ({ children }: IMapContextProvider) => {
  const [state, setState] = useState<IMapState>({
    radius: null,
    center: null,
  });

  const setRadius = (radius: number) => setState((prevState) => ({ ...prevState, radius }));
  const setCenter = (center: ILatLng | null) =>
    setState((prevState) => ({ ...prevState, center }));

  return (
    <MapContext.Provider
      value={{
        state,
        setRadius,
        setCenter,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
