import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { IExecutiveTag } from "../state/executive-tags.model";
import { ExecutiveTagsContext } from "../state/executive-tags.context";

interface IExecutiveTagsHook {
  all: IExecutiveTag[];
}

export function useExecutiveTags(): IExecutiveTagsHook {
  const executiveTagsContext = useContext(ExecutiveTagsContext);

  const [all] = useObservable(executiveTagsContext.all$, null);

  return { all };
}
