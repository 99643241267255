import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "../../../../../../../components/popups/popupDefaultOverride.styles.css";
import styles from "./styles.module.css";
import { ExclamationMarkIcon } from "../../../../../../../assets/company-dossier/relationship-indicator/icons";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { IExplanation } from "../../../../../../../entities/corporate-dossier/corporate-dossier.model";
import { renderUserMandateRange } from "../management/Management";

interface IProps {
  explanationData: IExplanation[];
}

const InfoTagPopup = ({ explanationData }: IProps) => {
  const { t } = useTranslation();

  if (!explanationData || !explanationData.length) return null;

  return (
    <Popup
      trigger={<img src={ExclamationMarkIcon} alt="" className={styles.exclamationMarkIcon} />}
      position="bottom center"
      offsetY={5}
      contentStyle={{
        width: "220px",
        border: "1px solid var(--color_background_black)",
        padding: "0px",
      }}
      arrowStyle={{
        stroke: "var(--color_background_black)",
        filter: "none",
      }}
    >
      <div className={styles.popupContent}>
        <Spacing p="20">
          <RText bold fontSize="16">
            {t("relationshipOverview")}
          </RText>
          <Spacing pt="15" />
          {explanationData.map((data, index) => {
            return (
              <Fragment key={index}>
                <>
                  {data.position && (
                    <RText bold fontSize="14">
                      {data.position}
                    </RText>
                  )}
                  {data.company_name && (
                    <RText bold fontSize="14">
                      {data.company_name}
                    </RText>
                  )}
                  <Spacing pt="5" />
                  <RText fontSize="14">
                    {renderUserMandateRange(
                      data.from === "Unknown" ? "" : data.from,
                      data.to === "Today" ? "" : data.to,
                      t,
                    )}
                  </RText>
                </>
                {index < explanationData.length - 1 && <Spacing pt="15" />}
              </Fragment>
            );
          })}
        </Spacing>
      </div>
    </Popup>
  );
};

export default InfoTagPopup;
