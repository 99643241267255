import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  FolderIcon,
  PersonIcon,
} from "../../../../../../../assets/company-dossier/relationship-indicator/icons";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import StarRating from "../../../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../../../components/spacing/component";
import { formatNumberWithDotSeparator } from "../../../../../../../helpers/number.helper";
import { IFirstLine } from "../../../../../../../entities/corporate-dossier/corporate-dossier.model";
import { RELATIONSHIP_NAVIGATION_ITEM_TYPE } from "./Navigation";
import InfoPopup from "./InfoPopup";
import RLink from "../../../../../../../components/link/RLink";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import ExecutivesTags from "../../../../../ExecutivesTags/ExecutivesTags";
import CorporatesTags from "../../../../../CorporatesTags/CorporatesTags";

interface IProps {
  selected: RELATIONSHIP_NAVIGATION_ITEM_TYPE;
  firstLineData: IFirstLine[];
  refetch: () => void;
}

export default function FirstLineRelationship({ selected, firstLineData, refetch }: IProps) {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();

  if (!firstLineData?.length) {
    return null;
  }

  const renderLine = (data: IFirstLine) => (
    <Flex alignItemsCenter className={styles.firstLineContainer}>
      <Flex>
        <div className={styles.blackDot} />
      </Flex>

      <Spacing pr="6" />
      <Flex flex alignItemsCenter className={styles.lineContainer}>
        <div className={styles.horizontalLine} />
        <InfoPopup explanationData={data.explanation1} />
        <div className={styles.horizontalLine} />
      </Flex>
      <Spacing pr="6" />

      <Flex className={styles.personContainer}>
        <RText fontSize={isSmallerScreen ? "11" : "14"} className={styles.personTypeText}>
          {t("promoter")}
        </RText>
        <Flex alignItemsCenter>
          <PersonIcon color={colors.backgroundBlackColor} className={styles.iconSize} />
          <Flex
            alignItemsCenter
            justifyContentCenter
            className={styles.promoterScoreContainer}
          >
            <RText white fontSize="12">
              {data.person1.promoter_score + "%"}
            </RText>
          </Flex>
        </Flex>
        <Flex column alignItemsCenter className={styles.personDescriptionContainer}>
          <ExecutivesTags
            executiveId={data.person1.id.toString()}
            entityTags={data.person1.tags}
            refetch={refetch}
          />
          <Spacing pt="10" />
          <RLink path={`/home/search/executive-dossier/${data.person1.id.toString()}`}>
            <RText
              fontSize={isSmallerScreen ? "11" : "14"}
              ellipsis
              textTooltip={data.person1.name}
            >
              {data.person1.name}
            </RText>
            <Spacing pt="10" />
            <RText
              fontSize={isSmallerScreen ? "11" : "14"}
              ellipsis
              textTooltip={data.person1.position}
            >
              {data.person1.position}
            </RText>
          </RLink>
        </Flex>
      </Flex>

      <Spacing pr="6" />
      <Flex
        flex
        alignItemsCenter
        className={`
                    ${styles.lineContainer}
                    ${styles.lineContainerBackgroundGreen}
                `}
      >
        <div className={`${styles.horizontalLine} ${styles.horizontalLineLarge}`} />
        <InfoPopup explanationData={data.explanation2} />
        <div className={`${styles.horizontalLine} ${styles.horizontalLineLarge}`} />
      </Flex>
      <Spacing pr="6" />

      {data.company && (
        <Flex className={styles.companyContainer}>
          <FolderIcon color={colors.backgroundBlackColor} className={styles.iconSize} />
          <Flex column alignItemsCenter className={styles.companyDescriptionContainer}>
            <CorporatesTags
              companyId={data.company.id.toString()}
              entityTags={data.company.tags}
              refetch={refetch}
            />
            <Spacing pt="10" />
            <RLink path={`/home/search/corporate-dossier/${data.company.id.toString()}`}>
              <RText
                fontSize={isSmallerScreen ? "11" : "14"}
                bold
                ellipsis
                textTooltip={data.company.name}
              >
                {data.company.name}
              </RText>
              <Spacing pt="10" />
              <Flex justifyContentCenter>
                <StarRating affinity={data.company.affinity} spacing="5" iconHeight="12" />
              </Flex>
            </RLink>
          </Flex>
        </Flex>
      )}

      {data.person3 && (
        <Flex className={styles.personContainer}>
          <Flex alignItemsCenter>
            <PersonIcon color={colors.backgroundBlackColor} className={styles.iconSize} />
          </Flex>
          <Flex column alignItemsCenter className={styles.personDescriptionContainer}>
            <ExecutivesTags
              executiveId={data.person3.id.toString()}
              entityTags={data.person3.tags}
              refetch={refetch}
            />
            <Spacing pt="10" />
            <RLink path={`/home/search/executive-dossier/${data.person3.id.toString()}`}>
              <RText
                fontSize={isSmallerScreen ? "11" : "14"}
                ellipsis
                textTooltip={data.person3.name}
              >
                {data.person3.name}
              </RText>
              <Spacing pt="10" />
              <Flex justifyContentCenter>
                <StarRating affinity={data.person3.wealth_score} spacing="6" iconHeight="14" />
              </Flex>
            </RLink>
          </Flex>
        </Flex>
      )}
    </Flex>
  );

  return (
    <>
      {selected === RELATIONSHIP_NAVIGATION_ITEM_TYPE.FIRST_LINE && (
        <>
          <Spacing pt="15" />
          <Flex justifyContentEnd>
            <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
              {`${formatNumberWithDotSeparator(firstLineData.length)} ${t("relations")}`}
            </RText>
          </Flex>
          <Spacing pt={isSmallerScreen ? "20" : "40"} />
        </>
      )}
      {firstLineData.map((data, index) => (
        <Fragment key={`first-line-data-${index}`}>
          {renderLine(data)}
          {index < firstLineData.length - 1 && <Spacing pt="150" />}
        </Fragment>
      ))}
    </>
  );
}
