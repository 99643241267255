export enum SIDE_NAVIGATION_ITEM_TYPE {
  PRODUCT_TRENDS,
  NEWS,
  EXTERNAL_LINKS,
  MAIN_INDUSTRY,
  HEADCOUNT,
  TURNOVER,
  SHAREHOLDERS,
  INVESTMENT,
  MANAGEMENT,
  RELATIONSHIP_INDICATOR,
  LOOK_A_LIKES,
}
