import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { useActiveCorporate } from "../../../../../../../entities/corporates/hooks/active.corporate.hook";
import { useIndustrySectorCodeData } from "../../../../../../../hooks/corporates.hook";
import CorporateInfo from "./CorporateInfo";
import Navigation from "./Navigation";
import NavItemContent from "./NavItemContent";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

export default function MainIndustry() {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { activeCorporate } = useActiveCorporate();
  const { industrySectorCodeData, fetchIndustrySectorCodeData, loadingIndustryCodeData } =
    useIndustrySectorCodeData();
  const fetchSectorCodeData = useCallback(fetchIndustrySectorCodeData, [
    fetchIndustrySectorCodeData,
  ]);

  if (!activeCorporate || !activeCorporate.industry_sector_codes) {
    return null;
  }

  const { vat_id, duns, registration_num, registration_city, registration_zip, purpose } =
    activeCorporate;

  // Sorting codes from lower to highest.
  const industrySectorCodes = [...activeCorporate.industry_sector_codes].sort();

  return (
    <>
      <RText fontSize="20" bold>
        {t("factsheet")}
      </RText>
      <Spacing pt="15" />
      <RText fontSize={isSmallerScreen ? "15" : "18"}>{t("industryClassification")}</RText>
      <Spacing pt="28" />
      <Navigation
        industrySectorCodes={industrySectorCodes}
        fetchIndustrySectorCodeData={fetchSectorCodeData}
      />
      <Spacing pb={isSmallerScreen ? "20" : "60"} />
      <NavItemContent
        industrySectorCodeData={industrySectorCodeData}
        loadingIndustryCodeData={loadingIndustryCodeData}
      />
      <CorporateInfo
        vatId={vat_id}
        duns={duns}
        registrationNum={registration_num}
        registrationCity={registration_city}
        registrationZip={registration_zip}
        purpose={purpose}
      />
      <Spacing pt="20" />
    </>
  );
}
