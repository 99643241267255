import { applyTransaction, arrayAdd, arrayToggle, combineQueries } from "@datorama/akita";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, skip, tap } from "rxjs/operators";
import { FiltersApiService } from "../../../api/services/filters-api.service";
import { RESULTS_PER_PAGE } from "../../../constants/filters";
import {
  areArraysOfObjectsWithArrayOfQueryRangesPropsEqual,
  areObjectsWithArrayOfStringsPropsEqual,
  IObjectWithArrayOfStringsProps,
} from "../../../helpers/array.helper";
import { getSelectedFilters, ICorporatesFilterWithUI } from "../../../helpers/filters.helper";
import { FILTER_TYPE } from "../../../shared/enums/filter-type.enum";
import { IQueryFilters } from "../../../shared/interfaces/query-filters.interface";
import { IQueryRanges } from "../../../shared/interfaces/query-ranges.interface";
import { IExecutive } from "../../executives/state/executive.model";
import { IExecutivesContext } from "../../executives/state/executives.facade";
import { ExecutivesFiltersQuery } from "./executives-filters.query";
import { ExecutivesFiltersStore } from "./executives-filters.store";
import { FILTER_CATEGORY } from "../../../api/enums/filter-category.enum";
import {
  getFilterIdFromCategory,
  ICorporatesFilter,
} from "../../corporates-filters/models/corporates-filter.model";
import { ICorporatesFilterUI } from "../../corporates-filters/state/corporates-filters.store";
import { hubMultiLevelFilterKeys } from "../../../constants/hub";
import { IDocCounts } from "../../../api/interfaces/doc-counts.interface";
import { EXPORT_TYPE } from "../../../shared/enums/export-type.enum";
import {
  SelectedValueTypes,
  areEqual,
} from "../../../shared/interfaces/selected-value-types.interface";
import { SELECTED_VALUES_TYPE } from "../../../shared/enums/selected-values-type.enum";

export interface IExecutivesFiltersContext {
  loading$: Observable<boolean>;
  all$: Observable<ICorporatesFilter[]>;
  allWithSearchUI$: Observable<ICorporatesFilterWithUI[]>;
  allWithHubUI$: Observable<ICorporatesFilterWithUI[]>;
  closedSearchRowIndexes$: Observable<number[]>;
  searching$: Observable<boolean>;
  searchResultTotalCount$: Observable<number>;
  searchResultIds$: Observable<string[]>;

  toggleFilterRowExpanded(index: number): void;

  getSelectedFilters(filters?: ICorporatesFilter[]): IObjectWithArrayOfStringsProps;

  getSelectedRangesAsArray(filters?: ICorporatesFilter[]): IQueryRanges[];

  getResultCount(): number;

  getLastSearchTerm(): string;

  getExecutives(executiveIds?: string[]): IExecutive[];

  selectHubFilter(filterId: string): void;

  selectHubFilterValue(filterId: string, filterValueId: string): void;

  selectSearchFilter(filterId: string): void;

  selectSearchFilterValue(filterId: string, filterValueId: SelectedValueTypes): void;

  deselectAllSearchFilterValues(): void;

  fetchExecutivesHubFilters(): Promise<void>;

  fetchExecutivesFilters(): Promise<void>;

  search(query: string): Promise<void>;

  filter(): Promise<void>;

  export(type: EXPORT_TYPE): Promise<void>;

  searchNextPage(): Promise<void>;

  assignTag(tagId: number): Promise<void>;

  getUIById(id: string): ICorporatesFilterUI;

  getAll(): ICorporatesFilter[];

  deselectMultiLevelHubFilter(filterId: string): void;

  deselectMultiLevelHubFilterValues(filterId: string): void;

  updateHubFilterDocCounts(doc_counts: IDocCounts): void;

  updateHubTagsFilterDocCounts(doc_counts: Partial<IDocCounts>): void;
}

export class ExecutivesFiltersFacade implements IExecutivesFiltersContext {
  private _allWithUI$ = combineQueries([
    this._query.selectAll(),
    this._query.ui.selectAll({ asObject: true }),
  ]);

  readonly loading$ = this._query.selectLoading();
  readonly all$ = this._query.selectAll();

  readonly allWithHubUI$ = this._allWithUI$.pipe(
    map(([filters, filterUIs]) =>
      filters
        .filter((filter) => filter.category === FILTER_CATEGORY.EXECUTIVES_HUB)
        .map((filter) => {
          return {
            filter,
            filterUI: {
              // TODO: why do I need to setup fallback values, why doesn't it work by default?
              selected: filterUIs[filter.id].hubSelected ?? false,
              selectedValues: filterUIs[filter.id].hubSelectedValues ?? [],
            },
          };
        }),
    ),
  );

  readonly allWithSearchUI$: Observable<ICorporatesFilterWithUI[]> = this._allWithUI$.pipe(
    map(([filters, filterUIs]) => {
      return filters
        .filter((filter) => filter.category === FILTER_CATEGORY.EXECUTIVES)
        .map((filter) => ({
          filter,
          filterUI: {
            selected: filterUIs[filter.id].searchSelected,
            selectedValues: filterUIs[filter.id].searchSelectedValues,
          },
        }));
    }),
  );

  readonly selectedFilters$ = this.allWithSearchUI$.pipe(
    map(getSelectedFilters),
    distinctUntilChanged(
      (p, q) =>
        areObjectsWithArrayOfStringsPropsEqual(p.filtersWithValues, q.filtersWithValues) &&
        areArraysOfObjectsWithArrayOfQueryRangesPropsEqual(p.ranges, q.ranges),
    ),
    skip(1), // to avoid initial value
    tap(({ filtersWithValues, ranges }) => {
      this.filter(filtersWithValues, ranges);
    }),
  );

  readonly closedSearchRowIndexes$ = this._query.ui.select("closedSearchRowIndexes");
  readonly searching$ = this._query.ui.select("searching");
  readonly searchResultTotalCount$ = this._query.ui.select("searchResultTotalCount");
  readonly searchResultIds$ = this._query.ui.select("searchResultIds");

  constructor(
    private _store: ExecutivesFiltersStore,
    private _query: ExecutivesFiltersQuery,
    private _filtersApiService: FiltersApiService,
    private _executivesService: IExecutivesContext,
  ) {
    this.selectedFilters$.subscribe();
  }

  toggleFilterRowExpanded(index: number): void {
    this._store.ui.update(({ closedSearchRowIndexes }) => ({
      closedSearchRowIndexes: arrayToggle(closedSearchRowIndexes, index),
    }));
  }

  getSelectedFilters(
    filters: ICorporatesFilter[] = this._query.getAll(),
  ): IObjectWithArrayOfStringsProps {
    return filters
      .map((filter) => ({
        filter,
        filterUI: this._query.ui.getEntity(filter.id),
      }))
      .reduce<IQueryFilters>((result, { filter, filterUI }) => {
        if (filterUI.id.includes("_hub_")) return result;

        if (filter.type === FILTER_TYPE.AUTOSUGGEST_RANGE) {
          const currentFilterValues: Array<string> = [];
          filterUI.searchSelectedValues.forEach((selectedValue) => {
            if (typeof selectedValue === "string" || selectedValue instanceof String) return;
            switch (selectedValue.type) {
              case SELECTED_VALUES_TYPE.REFERENCE:
                const referencedFilter = filter.values.find(
                  (filterValue) => filterValue.label === selectedValue.ref,
                );
                if (referencedFilter) {
                  currentFilterValues.push(referencedFilter.value_key);
                }
                return;
              default:
                break;
            }
          });
          if (currentFilterValues.length > 0) {
            result[filter.title_key] = currentFilterValues;
          }
        } else if (filter.type !== FILTER_TYPE.RANGE) {
          // Select all non-range types
          result[filter.title_key] = [];

          filter.values.forEach((value) => {
            if (filterUI.searchSelectedValues.indexOf(value.id) !== -1) {
              if (filter.title_key === "management.function_rank") {
                result[filter.title_key].push(value.label);
              } else {
                result[filter.title_key].push(value.value_key);
              }
            }
          });

          if (filter.type !== "affinity" && !result[filter.title_key].length) {
            delete result[filter.title_key];
          }
        }

        return result;
      }, {});
  }

  getSelectedRangesAsArray(
    filters: ICorporatesFilter[] = this._query.getAll(),
  ): IQueryRanges[] {
    return Array.from(
      filters
        .map((filter) => ({
          filter,
          filterUI: this._query.ui.getEntity(filter.id),
        }))
        .reduce<Set<IQueryRanges>>((result, { filter, filterUI }) => {
          let rangesForQueryObj: IQueryRanges = {};
          rangesForQueryObj[filter.title_key] = [];
          // Select all range types
          if (filter.type === FILTER_TYPE.RANGE) {
            filter.values.forEach((value) => {
              if (filterUI.searchSelectedValues.indexOf(value.label) !== -1) {
                rangesForQueryObj[filter.title_key].push({
                  to: value.to!,
                  from: value.from!,
                });
              }
            });
          } else if (filter.type === FILTER_TYPE.AUTOSUGGEST_RANGE) {
            filterUI.searchSelectedValues.forEach((selectedValue) => {
              if (typeof selectedValue === "string" || selectedValue instanceof String) return;
              if (selectedValue.type === SELECTED_VALUES_TYPE.RANGE) {
                rangesForQueryObj[filter.title_key].push({
                  to: selectedValue.to,
                  from: selectedValue.from,
                });
                return;
              }
            });
          }

          if (rangesForQueryObj[filter.title_key].length) {
            result.add(rangesForQueryObj);
          }

          return result;
        }, new Set<IQueryRanges>()),
    );
  }

  deselectAllSearchFilterValues() {
    const allFilters = this._query.getAll();

    allFilters.forEach((filter) => {
      this._store.ui.update(filter.id, (filter) => ({
        searchSelectedValues: [],
      }));
    });

    if (document.querySelector('input[name="from"]')) {
      (document.querySelector('input[name="from"]') as HTMLInputElement).value = "";
    }
    if (document.querySelector('input[name="to"]')) {
      (document.querySelector('input[name="to"]') as HTMLInputElement).value = "";
    }
  }

  getResultCount(): number {
    const { searchResultTotalCount } = this._query.ui.getValue();

    return searchResultTotalCount;
  }

  getLastSearchTerm(): string {
    const { lastSearchTerm } = this._query.ui.getValue();

    return lastSearchTerm ?? "";
  }

  getExecutives(executiveIds?: string[]): IExecutive[] {
    if (!executiveIds) {
      const { searchResultIds } = this._query.ui.getValue();
      executiveIds = searchResultIds;
    }

    return this._executivesService.getExecutivesFromIds(executiveIds);
  }

  getUIById(id: string): ICorporatesFilterUI {
    return this._query.ui.getEntity(id);
  }

  getAll(): ICorporatesFilter[] {
    return this._query.getAll();
  }

  selectHubFilter(filterId: string): void {
    if (hubMultiLevelFilterKeys.includes(filterId)) {
      this.deselectAllHubMultiLevelFiltersExceptCurrent(filterId);
    } else {
      this._store.ui.update(filterId, (filterUI) => ({
        hubSelected: !filterUI.hubSelected,
      }));
    }
  }

  /**
   * Only one multi-level filter on hub can be selected.
   * When user selects some multi-level filter, all others must be
   * deselected
   */
  private deselectAllHubMultiLevelFiltersExceptCurrent(filterId: string): void {
    const allFilters = this._query.getAll();

    allFilters.forEach((filter) => {
      const isMultiLevelFilter = hubMultiLevelFilterKeys.includes(filter.id);
      const isClickedFilter = filter.id === filterId;

      if (isMultiLevelFilter) {
        this._store.ui.update(filter.id, (filterUI) => ({
          hubSelected: isClickedFilter ? !filterUI.hubSelected : false,
        }));
      }
    });
  }

  deselectMultiLevelHubFilter(filterId: string): void {
    this._store.ui.update(filterId, () => ({
      hubSelected: false,
    }));
  }

  deselectMultiLevelHubFilterValues(filterId: string): void {
    this._store.ui.update(filterId, () => ({
      hubSelectedValues: [],
    }));
  }

  selectHubFilterValue(filterId: string, filterValueId: string): void {
    this._store.ui.update(filterId, (filter) => ({
      hubSelectedValues: arrayToggle(filter.hubSelectedValues, filterValueId, areEqual),
    }));
  }

  selectSearchFilter(filterId: string): void {
    this._store.ui.update(filterId, (filterUI) => ({
      searchSelected: !filterUI.searchSelected,
    }));
  }

  selectSearchFilterValue(filterId: string, filterValueId: SelectedValueTypes): void {
    this._store.ui.update(filterId, (filter) => ({
      searchSelectedValues: arrayToggle(filter.searchSelectedValues, filterValueId, areEqual),
    }));
  }

  async fetchExecutivesFilters(
    filters = this.getSelectedFilters(),
    ranges = this.getSelectedRangesAsArray(),
    query = this.getLastSearchTerm(),
  ) {
    try {
      const executivesFilters = await this._filtersApiService.fetchEntityFiltersWithCount(
        {
          query,
          filters,
          ranges,
        },
        FILTER_CATEGORY.EXECUTIVES,
      );

      this._store.upsertMany(executivesFilters);
    } catch (e) {
      console.warn("Error while fetching executives filters: ", e);
    }
  }

  async fetchExecutivesHubFilters() {
    this._store.setLoading(true);

    try {
      const filters = await this._filtersApiService.fetchHubFilters(
        {
          query: "",
        },
        FILTER_CATEGORY.EXECUTIVES,
      );

      this._store.upsertMany(filters);
    } catch (e) {
      console.warn("Error while fetching hub filters: ", e);
    } finally {
      this._store.setLoading(false);
    }
  }

  /**
   * Searches all executives with the specified string. Doesn't perform any filtering, only pure search
   * @param query
   */
  async search(query: string) {
    this._store.ui.update({
      searching: true,
      lastSearchTerm: query,
    });
    this.deselectAllSearchFilterValues();

    try {
      const page = 1;

      const { executives, total: searchResultTotalCount } =
        await this._filtersApiService.paginateExecutives({
          page,
          limit: RESULTS_PER_PAGE,
          query,
        });

      this._executivesService.addExecutives(executives);

      this._store.ui.update({
        lastSearchTerm: query,
        nextSearchPage: page + 1,
        searchResultTotalCount: searchResultTotalCount,
        searchResultIds: executives.map(({ id }) => id),
      });
    } catch (e) {
      console.warn("Error while performing search: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  /**
   * Runs the search with selected filters, for already specified search string
   */
  async filter(filters = this.getSelectedFilters(), ranges = this.getSelectedRangesAsArray()) {
    this._store.ui.update({ searching: true });

    await applyTransaction(async () => {
      try {
        // Reset page to 1, because filtering will return new results
        const page = 1;
        const query = this.getLastSearchTerm();

        const { executives, total: searchResultTotalCount } =
          await this._filtersApiService.paginateExecutives({
            page,
            limit: RESULTS_PER_PAGE,
            query,
            filters,
            ranges,
          });

        this._executivesService.addExecutives(executives);

        this._store.ui.update({
          nextSearchPage: page + 1,
          searchResultTotalCount: searchResultTotalCount,
          searchResultIds: executives.map(({ id }) => id),
        });

        await this.fetchExecutivesFilters(filters, ranges);
      } catch (e) {
        console.warn("Error while performing executives filtering: ", e);
      } finally {
        this._store.ui.update({ searching: false });
      }
    });
  }

  /**
   * export the search with selected filters and specified search string
   */
  async export(type: EXPORT_TYPE) {
    this._store.ui.update({ searching: true });

    try {
      const query = this.getLastSearchTerm();
      const filters = this.getSelectedFilters();
      const ranges = this.getSelectedRangesAsArray();
      const { searchResultTotalCount } = this._query.ui.getValue();
      const page = 1;

      await this._filtersApiService.exportExecutives(
        {
          page,
          limit: searchResultTotalCount,
          query,
          filters,
          ranges,
        },
        type,
      );
    } catch (e) {
      console.warn("Error while performing search: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  /**
   * assign the tag to the search with selected filters and specified search string
   */
  async assignTag(tagId: number) {
    this._store.ui.update({ searching: true });

    try {
      const query = this.getLastSearchTerm();
      const filters = this.getSelectedFilters();
      const ranges = this.getSelectedRangesAsArray();
      const { searchResultTotalCount } = this._query.ui.getValue();
      const page = 1;

      await this._filtersApiService.assignTagExecutives(
        {
          page,
          limit: searchResultTotalCount,
          query,
          filters,
          ranges,
        },
        tagId,
      );
    } catch (e) {
      console.warn("Error while performing search: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  /**
   * Used for pagination. Fetches the next page with already specified string and filters
   */
  async searchNextPage() {
    try {
      this._store.ui.update({ searching: true });

      const { nextSearchPage: page, searchResultIds } = this._query.ui.getValue();

      const query = this.getLastSearchTerm();

      const filters = this.getSelectedFilters();
      const ranges = this.getSelectedRangesAsArray();

      const { executives, total: searchResultTotalCount } =
        await this._filtersApiService.paginateExecutives({
          page,
          limit: RESULTS_PER_PAGE,
          query,
          filters,
          ranges,
        });

      this._executivesService.addExecutives(executives);

      this._store.ui.update({
        nextSearchPage: page + 1,
        searchResultTotalCount: searchResultTotalCount,
        searchResultIds: arrayAdd(
          searchResultIds,
          executives.map(({ id }) => id),
        ),
      });
    } catch (e) {
      console.warn("Error while performing search: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  updateHubFilterDocCounts(doc_counts: IDocCounts) {
    this._store.update(
      [
        ...Object.keys(doc_counts.filtersWithoutValues).map((title_key) =>
          getFilterIdFromCategory(FILTER_CATEGORY.EXECUTIVES_HUB, title_key),
        ),
      ],
      (filter) => ({
        doc_count: doc_counts.filtersWithoutValues[filter.title_key],
      }),
    );

    this._store.update(
      [
        ...Object.keys(doc_counts.filtersWithValues).map((title_key) =>
          getFilterIdFromCategory(FILTER_CATEGORY.EXECUTIVES_HUB, title_key),
        ),
      ],
      (filter) => {
        return {
          doc_count: doc_counts.filtersWithValues[filter.title_key].sum,
          values: filter.values.map((value) => ({
            ...value,
            doc_count: doc_counts.filtersWithValues[filter.title_key][value.id],
          })),
        };
      },
    );
  }

  updateHubTagsFilterDocCounts(doc_counts: Partial<IDocCounts>) {
    this._store.update(
      [
        ...Object.keys(doc_counts.filtersWithoutValues).map((title_key) =>
          getFilterIdFromCategory(FILTER_CATEGORY.EXECUTIVES_HUB, title_key),
        ),
      ],
      (filter) => ({
        doc_count: doc_counts.filtersWithoutValues[filter.title_key],
      }),
    );
  }
}
