import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IExecutiveNote } from "./executive-notes.model";
import { ExecutiveNotesStore } from "./executive-notes.store";
import { ExecutiveNotesQuery } from "./executive-notes.query";
import { executiveNotesApiService } from "./../../../api/services/executive-notes.service";
import { IExecutivesContext } from "../../executives/state/executives.facade";

export interface IExecutiveNotesContext {
  executiveNotes$: Observable<IExecutiveNote[]>;

  addNote: (executiveId: number, newNote: string) => Promise<void>;
  updateNote: (noteId: number, updatedNote: string) => Promise<void>;
  deleteNote: (noteId: number) => Promise<void>;
}

export class ExecutiveNotesFacade implements IExecutiveNotesContext {
  constructor(
    private _store: ExecutiveNotesStore,
    private _query: ExecutiveNotesQuery,
    private _executivesFacade: IExecutivesContext,
  ) {}

  readonly executiveNotes$: Observable<IExecutiveNote[]> =
    this._executivesFacade.executives$.pipe(
      map((executives) => {
        return (
          executives?.find(({ id }) => id === this._executivesFacade.getActiveId())?.notes ??
          []
        );
      }),
    );

  addNote = async (executiveId: number, newNote: string): Promise<void> => {
    try {
      const res = await executiveNotesApiService.addNote({
        elastic_person_id: executiveId,
        note: newNote,
      });

      this._executivesFacade.addNoteToExecutive(res);
    } catch (error) {
      console.warn("Error while adding new executive note: ", error);
    }
  };

  updateNote = async (noteId: number, updatedNote: string): Promise<void> => {
    try {
      const res = await executiveNotesApiService.updateNote(noteId, {
        note: updatedNote,
      });

      this._executivesFacade.updateNoteForExecutive(res);
    } catch (error) {
      console.warn("Error while updating executive note: ", error);
    }
  };

  deleteNote = async (noteId: number): Promise<void> => {
    try {
      await executiveNotesApiService.deleteNote(noteId);

      this._executivesFacade.removeNoteFromExecutive(noteId);
    } catch (error) {
      console.warn("Error while deleting executive note: ", error);
    }
  };
}
