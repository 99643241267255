import React from "react";
import { classNames } from "../../../helpers/string.helper";
import styles from "./styles.module.css";

interface IProps {
  size?: string;
  circular?: boolean;
  primaryGreen?: boolean;
  primaryGreenDark?: boolean;
  primaryBlue?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  componentStyle?: React.CSSProperties;
}

const ColoredContainer = ({
  size,
  circular,
  primaryBlue,
  primaryGreen,
  primaryGreenDark,
  onClick,
  children,
  className,
  componentStyle,
}: IProps) => {
  const componentSize = size
    ? { minWidth: `${size}px`, height: `${size}px` }
    : { minWidth: "40px", height: "40px" };

  if (componentStyle) {
    Object.assign(componentSize, componentStyle);
  }

  return (
    <div
      onClick={onClick}
      style={componentSize}
      className={classNames(styles, {
        circular,
        primaryBlue,
        primaryGreen,
        primaryGreenDark,
        className,
      })}
      {...{ children }}
    />
  );
};

export default ColoredContainer;
