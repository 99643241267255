import { EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";

export interface StartupsFiltersState {}
export interface StartupsFiltersUIState {
  searching: boolean;
  lastSearchTerm: string;
  nextSearchPage: number;
  searchResultTotalCount: number;
  searchResultIds: string[];
}

@StoreConfig({
  name: "startups-filters",
})
export class StartupsFiltersStore extends EntityStore<StartupsFiltersState> {
  ui: EntityUIStore<StartupsFiltersUIState>;

  constructor() {
    super({ loading: null });

    this.createUIStore({
      searching: null,
      lastSearchTerm: "",
      nextSearchPage: 1,
      searchResultTotalCount: null,
      searchResultIds: null,
    });
  }
}

export const startupsFiltersStore = new StartupsFiltersStore();
