import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import RText from "../fonts/RText/RText";
import { Spacing } from "../spacing/component";
import { Flex } from "../containers/Flex/Flex";
import { useUserTags } from "../../entities/custom-tags/hooks/user-tags.hook";
import { IEntityTag, ITag } from "../../entities/custom-tags/state/custom-tag.model";
import CreateTag from "./create-tag/CreateTag";
import UserTag from "./user-tag/UserTag";
import useAppColors from "../../hooks/app-colors.hook";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../hooks/session.hook";
import { ReactComponent as AddIcon } from "../../assets/AddIcon.svg";
import { useCloseOnClickOutside } from "../../hooks/componentRefs.hook";
import { AUTOMATION_TEST_KEY } from "../../constants/general";
import TAG_TYPE from "../../api/enums/tags.enum";

const automationMarker = { [AUTOMATION_TEST_KEY]: "hub-tag-btn" };
const automationMarkerAdd = { [AUTOMATION_TEST_KEY]: "hub-addtag-btn" };

interface IProps {
  entityId: string;
  entityTags?: IEntityTag[];
  addTagToEntity: (entityId: string, tagId: number) => void;
  removeTagFromEntity: (entityId: string, tagWrapper: IEntityTag) => void;
  hideTagLabel?: boolean;
  tagType: TAG_TYPE;
}

const Tags = ({
  entityId,
  entityTags,
  addTagToEntity,
  removeTagFromEntity,
  hideTagLabel = false,
  tagType,
}: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { colors } = useAppColors();
  const { isDarkTheme } = useSession();
  const { t } = useTranslation();

  const { userTags } = useUserTags();

  const [ref] = useCloseOnClickOutside(
    () => {
      setTimeout(() => {
        onToggleTags();
      }, 0);
    },
    { capture: true },
  );

  const [toggleTags, setToggleTags] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState<number | null>(null);

  const onToggleTags = () => {
    setToggleTags((visible) => !visible);
    selectedTagId && setSelectedTagId(null);
  };

  const onAddTagToEntity = (tag: ITag) => addTagToEntity(entityId, tag.id);

  const isTagAddedToSelected = (tagId: number) =>
    entityTags.some((et: IEntityTag) => et?.tag && et.tag.id === tagId);

  if (!entityTags) return null;

  return (
    <Flex style={{ position: "relative" }}>
      <Flex {...automationMarker} column pointer onClick={onToggleTags}>
        <Flex alignItemsCenter>
          {entityTags.length === 0 ? (
            <Flex
              {...automationMarkerAdd}
              style={{
                border: "1px solid var(--color_background_black)",
              }}
              className={`${styles.defaultTag} ${isSmallerScreen && styles.defaultTag__Responsive
                }`}
              alignItemsCenter
              justifyContentCenter
            >
              <AddIcon color={colors.backgroundBlackColor} className={styles.addIcon} />
            </Flex>
          ) : (
            entityTags.map((tag: IEntityTag, index: number) => (
              <Fragment key={`${tag.id}entity`}>
                <div
                  className={`${styles.defaultTag} ${isSmallerScreen && styles.defaultTag__Responsive
                    }`}
                  style={{
                    backgroundColor: tag.tag?.color_code ?? colors.backgroundWhiteColor,
                  }}
                />
                {index < entityTags.length - 1 && <Spacing pr="5" />}
              </Fragment>
            ))
          )}
        </Flex>
        {!hideTagLabel && (
          <>
            <Spacing pt="8" />
            <RText fontSize={isSmallerScreen ? "11" : "15"} className={styles.tagCompanyTitle}>
              {`${t("tag")}...`}
            </RText>
          </>
        )}
      </Flex>
      {toggleTags && (
        <Flex
          elementRef={ref}
          column
          style={{
            background: isDarkTheme
              ? "var(--immutable_background_black)"
              : "var(--immutable_background_white)",
            borderColor: isDarkTheme
              ? "var(--immutable_background_black)"
              : "var(--color_gray_dark)",
          }}
          className={styles.tagPopup}
        >
          <Flex alignItemsCenter className={styles.userTagsContentContainer}>
            <CreateTag createTag={onAddTagToEntity} />
          </Flex>
          <Flex
            column
            style={{
              maxHeight: 300,
              overflow: "auto",
            }}
          >
            {userTags
              .filter(
                (filteredTag: ITag) =>
                  tagType === TAG_TYPE.PEOPLE ? filteredTag.type !== TAG_TYPE.COMPANIES : filteredTag.type !== TAG_TYPE.PEOPLE


              )
              .map((tag: ITag) => (
                <Fragment key={`${tag.id}user`}>
                  <UserTag
                    entityId={entityId}
                    tag={tag}
                    entityTags={entityTags}
                    isSelected={selectedTagId === tag.id}
                    setSelectedTagId={setSelectedTagId}
                    isTagAddedToSelected={isTagAddedToSelected(tag.id)}
                    addTagToEntity={addTagToEntity}
                    removeTagFromEntity={removeTagFromEntity}
                    tagSelectedColor={getTagSelectedColor(
                      selectedTagId === tag.id,
                      isDarkTheme,
                    )}
                  />
                </Fragment>
              ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(Tags);

const getTagSelectedColor = (isSelected: boolean, isDarkTheme: boolean) => {
  if (!isSelected) {
    return "";
  }

  if (isDarkTheme) {
    return "var(--immutable_black_light)";
  }

  return "var(--color_gray_light)";
};
