import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  CategoryIcon,
  CategoryDarkIcon,
  ProductIcon,
  ProductDarkIcon,
  TrendIcon,
  TrendDarkIcon,
} from "../../../../../../../assets/company-dossier/product-trends/icons";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import {
  ISignals,
  ISubSignal,
} from "../../../../../../../entities/corporate-dossier/corporate-dossier.model";
import { useWindowSize } from "../../../../../../../hooks/window.hook";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../../../../hooks/app-colors.hook";
import { useSession } from "../../../../../../../hooks/session.hook";
import { ProductTrendPopup } from "./ProductTrendPopup";
import { ReactComponent as ExternalLinkIcon } from "../../../../../../../assets/company-dossier/product-trends/ExternalLink.svg";

const INTERNAL_SIGNAL_DESTINATIONS = ["northdata.de"];

interface IProps {
  productTrends: ISignals[] | null;
}

export default function ProductTrends({ productTrends }: IProps) {
  const { t } = useTranslation();

  if (!productTrends) {
    return null;
  }

  return (
    <>
      <RText fontSize="20" bold>
        {t("productTrends")}
      </RText>
      <Spacing pt="55" />
      {productTrends.length > 0 &&
        mapProductTrends(productTrends).map((item, index) => (
          <ProductTrendRow key={index} data={item} />
        ))}
    </>
  );
}

interface IProductTrendRowProps {
  data: IProductTrend;
}

const ProductTrendRow = ({ data }: IProductTrendRowProps) => {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isDarkTheme } = useSession();

  const windowSize = useWindowSize();
  const { colors } = useAppColors();

  const { trend, category, impuls, count, signals } = data;
  const lineWidth = getLineWidth(windowSize.width);

  const processedSignals = signals
    .map((signal) => {
      if (!signal) return null;
      let isExternal = false;
      const schemeIdx = signal.url.indexOf("://", 4);
      if (schemeIdx === -1) {
        const tokens = signal.url.split("/");
        isExternal = !(
          tokens.length &&
          INTERNAL_SIGNAL_DESTINATIONS.some((dest) => tokens[0].endsWith(dest))
        );
      } else {
        const pathIdx = signal.url.indexOf("/", schemeIdx + 14);
        if (pathIdx !== -1) {
          const destinationSubstring = signal.url?.substring(schemeIdx + 3, pathIdx);
          isExternal = !INTERNAL_SIGNAL_DESTINATIONS.some((dest) =>
            destinationSubstring.endsWith(dest),
          );
        }
      }
      if (!(isExternal || signal.snippet)) return null;
      return { external: isExternal, signal };
    })
    .filter((signal) => signal)
    .map(({ external, signal }) => {
      if (external) {
        return (
          <span className={styles.impulseLinkContainer}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.impulseLink}
              href={signal.url}
            >
              {t("readMore")} <ExternalLinkIcon />
            </a>
          </span>
        );
      }
      return <ProductTrendPopup signal={signal} />;
    });

  const productColumn = (
    Icon: React.FC<React.SVGProps<SVGSVGElement>>,
    text: string,
    value: string,
    count?: number,
  ) => (
    <Flex column>
      <Flex alignItemsCenter>
        <Icon
          color={colors.primaryDarkColor}
          className={`
                        ${styles.iconStyle}
                        ${!isDarkTheme ? styles.iconStyleLight : ""}
                    `}
        />
        {text === t("trend") && (
          <>
            <Spacing pr="5" />
            <div className={styles.horizontalLine} style={{ width: lineWidth + "px" }} />
          </>
        )}
        {text === t("category") && (
          <>
            <Spacing pr="5" />
            <Flex alignItemsCenter className={styles.toggleContainer}>
              <div className={styles.horizontalLine} style={{ width: lineWidth + "px" }} />
            </Flex>
          </>
        )}
      </Flex>
      <div
        style={{
          maxWidth: lineWidth + 40 + "px", // 40 = icon width + padding
          paddingTop: "10px",
        }}
      >
        <Flex column>
          <RText fontSize={isSmallerScreen ? "11" : "16"}>{text}</RText>
          <Spacing pt="5" />
          <Flex alignItemsCenter>
            {/* {count && (
                            <>
                                <RText
                                    fontSize={isSmallerScreen ? "11" : "16"}
                                    bold
                                >
                                    {formatNumberWithDotSeparator(count)}
                                </RText>
                                <Spacing pr="5" />
                            </>
                        )} */}
            <RText fontSize={isSmallerScreen ? "11" : "16"} bold>
              {value}
            </RText>
          </Flex>
          {text === t("impuls") &&
            ((processedSignals.length && processedSignals[0]) || (
              <span
                className={`${styles.impulseLinkContainer} ${styles.impulseLink} ${styles.impulseLinkDisabled}`}
              >
                {t("readMore")}
              </span>
            ))}
        </Flex>
      </div>
    </Flex>
  );

  return (
    <Fragment>
      <Flex className={styles.columnsContainer}>
        {productColumn(isDarkTheme ? TrendDarkIcon : TrendIcon, t("trend"), trend)}
        <Spacing pr="5" />
        {productColumn(isDarkTheme ? CategoryDarkIcon : CategoryIcon, t("category"), category)}
        <Spacing pr="5" />
        {productColumn(
          isDarkTheme ? ProductDarkIcon : ProductIcon,
          t("impuls"),
          impuls,
          count,
        )}
      </Flex>
      <Spacing pt="50" />
    </Fragment>
  );
};

interface IProductTrend {
  trend: string;
  category: string;
  impuls: string;
  signals: ISubSignal[];
  count: number;
}

const mapProductTrends = (productTrends: ISignals[]): IProductTrend[] => {
  return productTrends.map((item: ISignals) => {
    return {
      trend: item.affinity?.product?.cluster[0] ?? "",
      category: item.affinity?.signal?.cluster ?? "",
      impuls: item.affinity?.signal?.name ?? "",
      signals: item.signals,
      count: item.count,
    };
  });
};

/**
 * Gets the proper line width for product trends depending on screen size.
 *
 * Values are taken to best suit a particular screen width.
 */
const getLineWidth = (windowWidth: number) => {
  if (windowWidth < 1366) {
    return 200;
  } else if (windowWidth >= 1366 && windowWidth < 1440) {
    return 145;
  } else if (windowWidth >= 1440 && windowWidth <= 1536) {
    return 185;
  }

  return 280;
};
