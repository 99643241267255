import React, { useContext } from "react";
import RText from "../../../../../components/fonts/RText/RText";
import RLink from "../../../../../components/link/RLink";
import StarRating from "../../../../../components/other/StarRating/StarRating";
import { Spacing } from "../../../../../components/spacing/component";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
import HeadcountTurnoverCount from "./HeadcountTurnoverCount";

export enum EntityType {
  CORPORATE = "CORPORATE",
  EXECUTIVE = "EXECUTIVE",
}

interface IProps {
  entityId: string;
  entityType: EntityType;
  entityName: string;
  ratingScore: number;
  subTitle: string;
  headcount: string;
  turnover: string;
}

const EntityInfo = ({
  entityId,
  entityType,
  entityName,
  ratingScore,
  subTitle,
  headcount,
  turnover,
}: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);

  return (
    <>
      <StarRating affinity={ratingScore} spacing="6" iconHeight="14" />
      <Spacing pb="7" />
      <RLink
        path={
          entityType === EntityType.CORPORATE
            ? `/home/search/corporate-dossier/${entityId}`
            : `/home/search/executive-dossier/${entityId}`
        }
      >
        <RText bold fontSize={isSmallerScreen ? "11" : "16"}>
          {entityName}
        </RText>
      </RLink>
      <Spacing pb="7" />
      <RText fontSize={isSmallerScreen ? "11" : "16"}>{subTitle}</RText>
      <Spacing pb="10" />
      <HeadcountTurnoverCount headcount={headcount} turnover={turnover} />
    </>
  );
};

export default EntityInfo;
