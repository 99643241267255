import { IGeoLocation } from "../../../shared/interfaces/geo-location.interface";
import { ICorporatesHit, ICorporatesHitSource } from "../interfaces/corporates-hit.interface";
import { createContactInfo, IContactInfo } from "./contact-info.model";
import {
  createEmployeeCountsHistoric,
  IEmployeeCountsHistoric,
} from "./employee-counts-historic.model";
import { createManagement, IManagement } from "./management.model";
import { IEntityTag } from "../../custom-tags/state/custom-tag.model";
import {
  createShareholderCompanies,
  IShareholderCompanies,
} from "./shareholders-companies.model";
import { createTurnoverHistoric, ITurnoverHistoric } from "./turnover-historic.model";
import { ICorporateNote } from "../../corporate-notes/state/corporate-notes.model";
import { createShareholderPeople, IShareholderPeople } from "./shareholders-people.model";
import { tagEntityFromStringTag } from "../../../utils/tags.util";

export interface ICorporate {
  anniversary_date: string;
  milestone_year: number;
  id: string;
  country: string;
  region_lvl1: string;
  city: string;
  management: IManagement[];
  affinity_score: number;
  street: string;
  industry_sector: string;
  company_name: string;
  status: string;
  status_date: string;
  zip_code: number;
  contact_info: IContactInfo[];
  geo_location: IGeoLocation;
  industry_sector_codes: string[];
  employee_count: number;
  employee_counts_historic: IEmployeeCountsHistoric[];
  turnover: number;
  turnover_historic: ITurnoverHistoric[];
  vat_id: string;
  duns: number;
  registration_num: string;
  registration_city: string;
  registration_zip: number;
  purpose: string;
  founding_date_quality: number;
  founding_date: string;
  tags: IEntityTag[];
  success_score: number;
  promoter_score: number;
  shareholders_companies_in: IShareholderCompanies[];
  shareholders_companies_out: IShareholderCompanies[];
  notes: ICorporateNote[];
  shareholders_people: IShareholderPeople[];
  ee_score: number;
}

export function createCorporate({
  management,
  contact_info,
  geo_location,
  employee_counts_historic,
  turnover_historic,
  shareholders_companies_in,
  shareholders_companies_out,
  shareholders_people,
  ...params
}: Partial<ICorporate>) {
  return {
    id: null,
    country: null,
    region_lvl1: null,
    city: null,
    management: management?.map(createManagement),
    affinity_score: null,
    street: null,
    industry_sector: null,
    company_name: null,
    status: null,
    status_date: null,
    zip_code: null,
    contact_info: contact_info?.map(createContactInfo),
    geo_location: geo_location ?? null,
    industry_sector_codes: null,
    employee_count: null,
    employee_counts_historic: employee_counts_historic?.map(createEmployeeCountsHistoric),
    turnover: null,
    turnover_historic: turnover_historic?.map(createTurnoverHistoric),
    vat_id: null,
    duns: null,
    registration_num: null,
    registration_city: null,
    registration_zip: null,
    purpose: null,
    tags: [],
    success_score: null,
    promoter_score: null,
    shareholders_companies_in: shareholders_companies_in?.map(createShareholderCompanies),
    shareholders_companies_out: shareholders_companies_out?.map(createShareholderCompanies),
    notes: [],
    shareholders_people: shareholders_people?.map(createShareholderPeople),
    ee_score: null,
    ...params,
  } as ICorporate;
}

export function createCorporateFromHitSource({ company_id, ...source }: ICorporatesHitSource) {
  return createCorporate({
    ...source,
    id: company_id.toString(),
  });
}

export function createCorporateFromHit({ _source, tags }: ICorporatesHit) {
  const corporate = createCorporateFromHitSource(_source);
  if (tags) {
    corporate.tags = tags.map((tag) =>
      typeof tag === "string" ? tagEntityFromStringTag(tag) : tag,
    );
  }

  return corporate;
}
