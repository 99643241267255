import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { HubContext } from "../state/hub.context";

interface IHubNewsHook {
  cumulativeNewsCount: number | null;
}

export const useHubNews = (): IHubNewsHook => {
  const hubService = useContext(HubContext);

  const [cumulativeNewsCount] = useObservable(hubService.cumulativeNewsCount$, null);

  return {
    cumulativeNewsCount,
  };
};
