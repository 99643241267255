import { EXPORT_TYPE } from "../shared/enums/export-type.enum";

export const corporatesTitleKeys = [
  "tags_Client",
  "tags_Lead",
  "founding_date",
  "status_bankruptcies",
  "status_others",
];

export const executivesTitleKeys = ["tags_Client", "tags_Lead"];

export const marketDriversTitleKeys = [
  "affinity_score",
  "product_trends",
  "success_score",
  "promoter_score",
  "industry_sector",

  "wealth_score",
  "gender",
  "age",
];
export const newsTitleKeys = ["region_lvl1", "subscriptions"];

export const hubMultiLevelFilterKeys = [
  "companies_hub_product_trends",
  "companies_hub_affinity_score",
  "companies_hub_industry_sector",
  "companies_hub_success_score",
  "companies_hub_promoter_score",

  "people_hub_wealth_score",
  "people_hub_gender",
  "people_hub_promoter_score",
  "people_hub_age",
];

export const extension = {
  [EXPORT_TYPE.CSV]: "csv",
  [EXPORT_TYPE.XLS]: "xlsx",
};
