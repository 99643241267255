import { tagsApiService } from "../../../api/services/tags.service";
import { CorporateTagsQuery } from "./corporate-tags.query";
import { CorporateTagsStore } from "./corporate-tags.store";
import { ICorporatesContext } from "../../corporates/state/corporates.facade";
import { Observable } from "rxjs";
import { ICorporateTag } from "./corporate-tags.model";
import { map } from "rxjs/operators";
import { IEntityTag } from "../../custom-tags/state/custom-tag.model";

export interface ICorporateTagsContext {
  all$: Observable<ICorporateTag[]>;

  saveCompanyTag: (elasticCompanyId: string, tagId: number) => Promise<IEntityTag>;
  deleteCompanyTag: (elasticCompanyId: string, entityTag: IEntityTag) => Promise<void>;
  getCompanyTags: (elasticCompanyId: number) => void;
}

export class CorporateTagsFacade implements ICorporateTagsContext {
  constructor(
    private store: CorporateTagsStore,
    private query: CorporateTagsQuery,
    private corporatesFacade: ICorporatesContext,
  ) {}

  readonly all$ = this.corporatesFacade.corporates$.pipe(
    map((corporates) => {
      return corporates.map((c) => {
        const corporateTag: ICorporateTag = {
          id: c.id,
          tags: c.tags,
        };

        return corporateTag;
      });
    }),
  );

  saveCompanyTag = async (elasticCompanyId: string, tagId: number): Promise<IEntityTag> => {
    try {
      const res: IEntityTag = await tagsApiService.saveCompanyTag(+elasticCompanyId, tagId);

      this.corporatesFacade.addTagToCorporate(elasticCompanyId, res);
      return res;
    } catch (error) {
      console.warn("Error while saving custom tag: ", error);
    }
  };

  deleteCompanyTag = async (
    elasticCompanyId: string,
    entityTag: IEntityTag,
  ): Promise<void> => {
    try {
      await tagsApiService.deleteCompanyTag(entityTag.id);

      this.corporatesFacade.removeTagFromCorporate(elasticCompanyId, entityTag);
    } catch (error) {
      console.warn("Error while deleting custom tag: ", error);
    }
  };

  async getCompanyTags(elasticCompanyId: number): Promise<void> {
    try {
      const res = await tagsApiService.getCompanyTags(elasticCompanyId);

      this.store.upsertMany(res);
    } catch (error) {
      console.warn("Error while getting tags for current user: ", error);
    }
  }
}
