import { corporatesApiService } from "../../../api/services/corporates-api.service";
import { createContext } from "react";
import { filtersApiService } from "../../../api/services/filters-api.service";
import { hubApiService } from "../../../api/services/hub-api.service";
import { corporatesFiltersFacade } from "../../../entities/corporates-filters/state/corporates-filters.context";
import { corporatesFacade } from "../../../entities/corporates/state/corporates.context";
import { HubFacade, IHubContext } from "./hub.facade";
import { hubQuery } from "./hub.query";
import { hubStore } from "./hub.store";

export const hubFacade = new HubFacade(
  hubStore,
  hubQuery,
  filtersApiService,
  hubApiService,
  corporatesFacade,
  corporatesFiltersFacade,
  corporatesApiService,
);

export const HubContext = createContext<IHubContext>(hubFacade);
