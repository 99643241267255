import { createContext } from "react";
import { ICorporateNotesContext, CorporateNotesFacade } from "./corporate-notes.facade";
import { corporateNotesStore } from "./corporate-notes.store";
import { corporateNotesQuery } from "./corporate-notes.query";
import { corporatesFacade } from "../../corporates/state/corporates.context";

const corporateNotesFacade = new CorporateNotesFacade(
  corporateNotesStore,
  corporateNotesQuery,
  corporatesFacade,
);

export const CorporateNotesContext =
  createContext<ICorporateNotesContext>(corporateNotesFacade);
