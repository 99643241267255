import { useState } from "react";
import useClickHandlers, { IClickHandlers } from "./click-handlers";

export interface ILeftArrowHandler {
  moveLeft: boolean;
  setMoveLeft: (moveLeft: boolean) => void;
  fastForward: boolean;
  clickHandlers: IClickHandlers;
  averageFooterItemWidth: number | null;
}

export const useLeftArrowHandler = (): ILeftArrowHandler => {
  const [fastForward, setFastForward] = useState(false);
  const [moveLeft, setMoveLeft] = useState(false);
  const clickHandlers = useClickHandlers(
    () => setFastForward(true),
    () => setMoveLeft(true),
    () => setFastForward(false),
  );

  return {
    moveLeft,
    setMoveLeft,
    fastForward,
    clickHandlers,
    averageFooterItemWidth: getAverageFooterItemWidth(),
  };
};

export interface IRightArrowHandler {
  moveRight: boolean;
  setMoveRight: (moveRight: boolean) => void;
  fastBackward: boolean;
  clickHandlers: IClickHandlers;
  averageFooterItemWidth: number | null;
}

export const useRightArrowHandler = (): IRightArrowHandler => {
  const [fastBackward, setFastBackward] = useState(false);
  const [moveRight, setMoveRight] = useState(false);
  const clickHandlers = useClickHandlers(
    () => setFastBackward(true),
    () => setMoveRight(true),
    () => setFastBackward(false),
  );

  return {
    moveRight,
    setMoveRight,
    fastBackward,
    clickHandlers,
    averageFooterItemWidth: getAverageFooterItemWidth(),
  };
};

function getAverageFooterItemWidth() {
  const hubFooterItems = document.querySelectorAll(".hubFooterItemContainer");

  if (!hubFooterItems?.length) {
    return null;
  }

  let totalFooterItemsWidth = 0;
  hubFooterItems.forEach((element) => (totalFooterItemsWidth += element.clientWidth));

  return Math.ceil(totalFooterItemsWidth / hubFooterItems.length);
}
