import React, { Fragment } from "react";
import styles from "./styles.module.css";
import { ReactComponent as StarFull } from "../../../assets/RAffinityStarFull.svg";
import { ReactComponent as StarEmpty } from "../../../assets/RAffinityStarEmpty.svg";
import { Spacing } from "../../spacing/component";
import useAppColors from "../../../hooks/app-colors.hook";

interface IProps {
  affinity: number;
  spacing?: string;
  iconHeight?: string;
  emptyIconSize?: string;
  hideEmptyIcon?: boolean;
}

const StarRating = ({
  affinity,
  spacing,
  iconHeight,
  emptyIconSize,
  hideEmptyIcon = false,
  ...rest
}: IProps) => {
  const { colors } = useAppColors();

  const iconStyle: any = iconHeight
    ? { width: `${iconHeight}px`, height: `${iconHeight}px` }
    : { width: "22px", height: "22px" };

  iconStyle.color = colors.primaryDarkColor;

  const emptyIconStyle = emptyIconSize
    ? { width: `${emptyIconSize}px`, height: `${emptyIconSize}px` }
    : { width: "8px", height: "8px" };

  const emptyIcon = hideEmptyIcon ? null : <StarEmpty style={emptyIconStyle} />;

  const renderIcon = (index: number) => {
    return (
      <>
        {index > 0 && <Spacing pl={spacing ? spacing : "12"} />}
        <div className={styles.iconContainer} style={iconStyle}>
          {affinity > index ? <StarFull style={iconStyle} /> : emptyIcon}
        </div>
      </>
    );
  };

  return (
    <div {...rest} className={styles.component}>
      {new Array(5).fill(null).map((value, index) => (
        <Fragment key={index}>{renderIcon(index)}</Fragment>
      ))}
    </div>
  );
};

export default StarRating;
