export interface IContactInfo {
  contact_type: string;
  value: string;
}

export function createContactInfo(params: Partial<IContactInfo>) {
  return {
    contact_type: null,
    value: null,
    ...params,
  } as IContactInfo;
}
