import React from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteModel } from "../models/route/route.model";
import PageNotFound from "../pages/pagenotfound/PageNotFound";
import { sessionFacade } from "../state/akita/session/session.facade";

export interface RouteType extends RouteModel {
  exact?: boolean;
}

export const toRoute = ({ Component, path, title, exact }: RouteType, index?: number) => (
  <Route
    key={index}
    component={() => {
      document.title = title;
      return <Component />;
    }}
    {...{ exact, path }}
  />
);

export const toExactRoute = (model: RouteModel, index?: number) =>
  toRoute({ exact: true, ...model }, index);

export const toPrivateExactRoute = (model: RouteModel, index?: number) =>
  toPrivateRoute({ exact: true, ...model }, index);

export const toPrivateRoute = ({ Component, ...restOfModel }: RouteType, index?: number) => {
  return toRoute(
    {
      ...restOfModel,
      Component: () => {
        if (sessionFacade.getToken()) {
          return <Component />;
        } else {
          return <Redirect to="/login" />;
        }
      },
    },
    index,
  );
};

export const pageNotFoundFallbackRoute = <Route component={PageNotFound} key="PageNotFound" />;
