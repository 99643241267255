import React, { Fragment } from "react";
import { Element } from "react-scroll";
import styles from "./styles.module.css";
// import Divider from "../../../../../../components/dividers/Divider/Divider";
// import { Spacing } from "../../../../../../components/spacing/component";
import { INavItemContent } from "../../../../corporates/dossier/components/side-navigation-item-content/SideNavigationItemContent";
import { IPeopleManagement } from "../../../../../../entities/executives/interfaces/people-management.interface";
import { IShareholder } from "../../../../../../entities/executives/interfaces/shareholder.interface";
import {
  useEntrepreneursAndExecutives,
  useExecutiveFoundationReach,
  useExecutiveLookALikes,
  useExecutiveRelationshipIndicator,
} from "../../../../../../entities/executive-dossier/hooks/executive-dossier.hook";
import Career from "./career/Career";
import RelationshipIndicator from "./relationship-indicator/RelationshipIndicator";
import Investment from "./investment/Investment";
import { getInvestmentCount } from "../../../../corporates/dossier/components/side-navigation-item-content/investment/Investment";
import { useSession } from "../../../../../../hooks/session.hook";
import LookALikes from "./look-a-likes/LookALikes";
import EntrepreneursAndExecutives from "./entrepreneurs-and-executives/EntrepreneursAndExecutives";
import FoundationReach from "./foundation-reach/FoundationReach";

interface IProps {
  executiveName: string;
  careerData: IPeopleManagement[];
  investmentData: IShareholder[];
  executiveId: string;
}

export default function SideNavigationItemContent({
  executiveName,
  careerData,
  investmentData,
  executiveId,
}: IProps) {
  const { isB2CUser } = useSession();

  const { executiveRelationshipIndicator } = useExecutiveRelationshipIndicator();
  const investmentCount = getInvestmentCount(investmentData);
  const { executiveLookALikes, totalExecutiveLookALikes } = useExecutiveLookALikes();
  const { executiveFoundationReach, executiveFoundationReachCount } =
    useExecutiveFoundationReach();
  const { entrepreneursAndExecutives, totalEntrepreneursAndExecutives } =
    useEntrepreneursAndExecutives();

  const itemsContent: INavItemContent[] = [
    {
      exist: Boolean(careerData && careerData.length > 0),
      anchorName: "career",
      Component: <Career careerData={careerData} />,
    },
    {
      exist: Boolean(
        executiveRelationshipIndicator &&
          ((!isB2CUser && executiveRelationshipIndicator?.first_line?.length > 0) ||
            executiveRelationshipIndicator?.second_line?.length > 0),
      ),
      anchorName: "relationshipIndicator",
      Component: (
        <RelationshipIndicator
          executiveRelationshipIndicator={executiveRelationshipIndicator}
          executiveId={executiveId}
        />
      ),
    },
    {
      exist: Boolean(investmentCount > 0),
      anchorName: "investment",
      Component: <Investment investmentData={investmentData} />,
    },
  ];

  const b2cItemsContent: INavItemContent[] = [
    ...itemsContent,
    {
      exist: Boolean(totalExecutiveLookALikes > 0),
      anchorName: "lookALikes",
      Component: (
        <LookALikes
          executiveName={executiveName}
          totalExecutiveLookALikes={totalExecutiveLookALikes}
          executiveLookALikes={executiveLookALikes}
        />
      ),
    },
    {
      exist: Boolean(executiveFoundationReachCount),
      anchorName: "foundationReach",
      Component: <FoundationReach companies={executiveFoundationReach} />,
    },
    {
      exist: Boolean(totalEntrepreneursAndExecutives),
      anchorName: "entrepreneursAndExecutives",
      Component: <EntrepreneursAndExecutives people={entrepreneursAndExecutives} />,
    },
  ];

  const existingItemsList = isB2CUser
    ? b2cItemsContent.filter(({ exist }) => exist)
    : itemsContent.filter(({ exist }) => exist);

  return (
    <Element
      name="contentContainer"
      id="contentContainerElement"
      className={styles.contentContainer}
    >
      {existingItemsList.map((item, index) => (
        <Fragment key={item.anchorName}>
          <Element id={item.anchorName} name={item.anchorName}>
            {item.Component}
          </Element>
          {/* {index < existingItemsList.length - 1 ? (
                        <>
                            <Divider black className={styles.divider} />
                            <Spacing pt="20" />
                        </>
                    ) : (
                        <div style={{ height: "500px" }} />
                    )} */}
        </Fragment>
      ))}
    </Element>
  );
}
