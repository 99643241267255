import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { InterestsFiltersContext } from "../state/interests-filters.context";

interface UseInterestsSearchHitsHook {
  totalCount: number;
}

export function useInterestsSearchHits(): UseInterestsSearchHitsHook {
  const interestsFiltersService = useContext(InterestsFiltersContext);

  const [totalCount] = useObservable(
    interestsFiltersService.searchResultTotalCount$,
    interestsFiltersService.getResultCount(),
  );

  return { totalCount };
}
