import { ReactComponent as TrendIcon } from "./TrendIcon.svg";
import { ReactComponent as CategoryIcon } from "./CategoryIcon.svg";
import { ReactComponent as ProductIcon } from "./ProductIcon.svg";
import { ReactComponent as TrendDarkIcon } from "./TrendDarkIcon.svg";
import { ReactComponent as CategoryDarkIcon } from "./CategoryDarkIcon.svg";
import { ReactComponent as ProductDarkIcon } from "./ProductDarkIcon.svg";
import { ReactComponent as OpenIcon } from "./OpenIcon.svg";
import { ReactComponent as CloseIcon } from "./CloseIcon.svg";

export {
  TrendIcon,
  TrendDarkIcon,
  CategoryIcon,
  CategoryDarkIcon,
  ProductIcon,
  ProductDarkIcon,
  OpenIcon,
  CloseIcon,
};
