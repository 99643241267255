import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { IExecutiveNote } from "./executive-notes.model";

export interface ExecutiveNotesState extends EntityState<IExecutiveNote> {}

@StoreConfig({
  name: "executive-notes",
})
export class ExecutiveNotesStore extends EntityStore<ExecutiveNotesState> {}

export const executiveNotesStore = new ExecutiveNotesStore();
