import React, { useContext } from "react";
import styles from "./styles.module.css";
import Select from "./Select";
import { ResolutionContext } from "../../state/context/ResolutionContext/ResolutionContextProvider";
import { IActiveOption, IOption } from "./types";
import { useWindowSize } from "../../hooks/window.hook";
import { selectAffinityIconSize } from "../../helpers/icons.helper";
import StarRating from "../other/StarRating/StarRating";
import { ReactComponent as StarFull } from "../../assets/RAffinityStarFull.svg";
import useAppColors from "../../hooks/app-colors.hook";

interface IProps {
  values: number[];
  setValues: (values: number[]) => void;
}

const RateFilter = ({ values, setValues }: IProps) => {
  const { colors } = useAppColors();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const windowSize = useWindowSize();
  const starRatingIconSize = selectAffinityIconSize(windowSize.width);
  const options: IOption[] = [
    {
      value: 5,
    },
    {
      value: 4,
    },
    {
      value: 3,
    },
    {
      value: 2,
    },
    {
      value: 1,
    },
  ];

  const Title = () => (
    <StarFull
      style={{
        width: "18px",
        height: "18px",
        color: colors.primaryDarkColor,
      }}
    />
  );

  const Option = ({ value, active, handleOption }: IActiveOption) => (
    <div
      onClick={() => handleOption(value)}
      className={`${styles.dropdownOption} ${active && styles.dropdownOption__Active}`}
    >
      <StarRating
        affinity={value}
        spacing={starRatingIconSize.spacing}
        iconHeight={starRatingIconSize.height}
        emptyIconSize={isSmallerScreen ? "8" : "12"}
      />
    </div>
  );

  return (
    <Select
      values={values}
      handleValues={setValues}
      options={options}
      Title={Title}
      SelectOption={Option}
    />
  );
};

export default RateFilter;
