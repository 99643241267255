import { AxiosInstance } from "axios";
import { sessionFacade } from "../state/akita/session/session.facade";
import { getLngString } from "../localization/i18n";
import { getCurrentTenant } from "../utils/tenant.util";

export default function initializeApiInterceptors(httpClient: AxiosInstance): void {
  interceptRequest(httpClient);
  interceptResponse(httpClient);
}

function interceptRequest(httpClient: AxiosInstance): void {
  httpClient.interceptors.request.use(
    (config) => {
      const token = sessionFacade.getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      config.headers["X-AxeedMove-Domain"] = getCurrentTenant().tenant;

      config.params = {
        lng: getLngString(),
      };

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

function interceptResponse(httpClient: AxiosInstance): void {
  httpClient.interceptors.response.use(undefined, (error) => {
    if (!error.response) {
      if (error.message === "Network Error") {
        console.error("Network error - server is probably down");
      }

      console.error("Unkown error", error);
    }

    if (401 === error.response?.status) {
      sessionFacade.logout();
    }

    return error.response;
  });
}
