import { combineQueries } from "@datorama/akita";
import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { map } from "rxjs/operators";
import { extractFiltersIntoRows } from "../../../helpers/filters.helper";
import { IFilterWithUIRow } from "../../../shared/interfaces/filters-row.interface";
import { ExecutivesFiltersContext } from "../state/executives-filters.context";

interface IFilterWithUIRowsHook {
  filterWitUIRows: IFilterWithUIRow[];
  // loadingExecutivesFilters: boolean;
  toggleFilterRowExpanded(rowIndex: number): void;
}

export const useExecutivesFilterWithSearchUIRows = (): IFilterWithUIRowsHook => {
  const executivesFiltersContext = useContext(ExecutivesFiltersContext);

  // const [loadingExecutivesFilters] = useObservable(
  //     executivesFiltersService.loading$,
  //     null
  // );

  const [filterWitUIRows] = useObservable(
    combineQueries([
      executivesFiltersContext.allWithSearchUI$,
      executivesFiltersContext.closedSearchRowIndexes$,
    ]).pipe(
      map(([allWithSearchUI, openedSearchRowIndexes]) =>
        extractFiltersIntoRows(allWithSearchUI, openedSearchRowIndexes),
      ),
    ),
    [],
  );

  return {
    filterWitUIRows,
    // loadingExecutivesFilters,
    toggleFilterRowExpanded: (index: number) =>
      executivesFiltersContext.toggleFilterRowExpanded(index),
  };
};
