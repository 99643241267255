import { useObservable } from "@mindspace-io/react";
import { useContext } from "react";
import { ITag } from "../state/custom-tag.model";
import { CustomTagsContext } from "../state/custom-tags.context";

interface UserTagsHook {
  userTags: ITag[];
}

export function useUserTags(): UserTagsHook {
  const customTagsService = useContext(CustomTagsContext);

  const [userTags] = useObservable(customTagsService.userTags$, []);

  return {
    userTags,
  };
}
