import { arrayRemove } from "@datorama/akita";
import {
    createCorporatesFilterFromResponse,
    ICorporatesFilter,
} from "../../entities/corporates-filters/models/corporates-filter.model";
import {
    createCorporateFromHit,
    ICorporate,
} from "../../entities/corporates/models/corporate.model";
import { IFilterByProductTrendsResponse } from "../../entities/corporates/responses/filter-by-product-trends.response";
import {
    createExecutiveFromHit,
    IExecutive,
} from "../../entities/executives/state/executive.model";
import { IFilter } from "../../shared/interfaces/filter.interface";
import { IQueryFilters } from "../../shared/interfaces/query-filters.interface";
import { IQueryRanges } from "../../shared/interfaces/query-ranges.interface";
import {
    isBankruptcy,
    isClient,
    isExecutiveClient,
    isExecutiveLead,
    isLead,
    isStartup,
    isOther,
} from "../../utils/corporates-filters.util";
import { FILTER_CATEGORY } from "../enums/filter-category.enum";
import { EXPORT_TYPE } from "../../shared/enums/export-type.enum";
import { ICorporatesSearchResponse } from "../interfaces/corporates-search-response.interface";
import { IDocCounts } from "../interfaces/doc-counts.interface";
import { IExecutivesSearchResponse } from "../interfaces/executives-search-response.interface";
import { IPaginateFiltersRequest } from "../interfaces/paginate-fliters-request.interface";
import { IProductTrendsRequest } from "../interfaces/product-trends-request.interface";
import ApiService from "./api.service";
import { createCancelTokenHandlerWithEndpoints } from "../cancelTokenHandler";
import { t } from "../../localization/i18n";
import {
    formatGenderForTranslation,
    formatIndustrySectorForTranslation,
} from "../../helpers/translation.helper";
import { differenceInYears } from "date-fns";
import { exportBlob } from "../../helpers/blob.helper";
import ANNIVERSARIES_FILTER from "../enums/anniversaries-filter.enum";

const echobotFiltersWithoutValues = ["product_trends"];

function createEmptyDocCounts(): IDocCounts {
    return {
        filtersWithoutValues: {},
        productTrends: {},
        filtersWithValues: {
            affinity_score: {
                sum: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
            },
            industry_sector: {
                sum: 0,
            },
            success_score: {
                sum: 0,
                "0 - 20": 0,
                "20 - 40": 0,
                "40 - 60": 0,
                "60 - 80": 0,
                "80 - 100": 0,
            },
            promoter_score: {
                sum: 0,
                "0 - 20": 0,
                "20 - 40": 0,
                "40 - 60": 0,
                "60 - 80": 0,
                "80 - 100": 0,
            },

            wealth_score: {
                sum: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
            },
            gender: {
                sum: 0,
            },
            age: {
                sum: 0,
                "65+": 0,
                "55-64": 0,
                "45-54": 0,
                "35-44": 0,
                "25-34": 0,
                "18-24": 0,
            },
        },
    };
}

interface IFiltersAndRangesRequest {
    query?: string;
    filters?: IQueryFilters;
    ranges?: IQueryRanges[];
    unfilteredEntityIds?: string[];
    filteredEntityIds?: string[];
}

interface ISearchAndFilterRequest extends IFiltersAndRangesRequest {
    filtersWithoutValues?: string[];
    productTrendsFilters?: string[];
}

export class FiltersApiService extends ApiService {
    private _cancelTokenHandlerObject: ReturnType<typeof createCancelTokenHandlerWithEndpoints>;

    constructor() {
        super();
        this._cancelTokenHandlerObject = createCancelTokenHandlerWithEndpoints([
            `/search/${FILTER_CATEGORY.CORPORATES}/filters/aggregate`,
            `/search/${FILTER_CATEGORY.EXECUTIVES}/filters/aggregate`,

            `/search/${FILTER_CATEGORY.CORPORATES}`,
            `/search/${FILTER_CATEGORY.EXECUTIVES}`,

            `/search/${FILTER_CATEGORY.CORPORATES}/export/${EXPORT_TYPE.CSV}`,
            `/search/${FILTER_CATEGORY.EXECUTIVES}/export/${EXPORT_TYPE.CSV}`,
            `/search/${FILTER_CATEGORY.CORPORATES}/export/${EXPORT_TYPE.XLS}`,
            `/search/${FILTER_CATEGORY.EXECUTIVES}/export/${EXPORT_TYPE.XLS}`,

            `/hub/${FILTER_CATEGORY.CORPORATES}`,
            `/hub/${FILTER_CATEGORY.EXECUTIVES}`,

            `/hub/${FILTER_CATEGORY.CORPORATES}/filters/aggregate`,
            `/hub/${FILTER_CATEGORY.EXECUTIVES}/filters/aggregate`,
        ]);
    }

    private _fetchFiltersWithCount(
        category: FILTER_CATEGORY,
        req: IFiltersAndRangesRequest,
    ): Promise<IFilter[]> {
        const endpoint = `/search/${category}/filters/aggregate`;

        return this.post(endpoint, req, {
            cancelToken: this._cancelTokenHandlerObject[endpoint].handleRequestCancellation().token,
        });
    }

    private _fetchHubFilters(
        category: FILTER_CATEGORY,
        req: IFiltersAndRangesRequest,
    ): Promise<IFilter[]> {
        const endpoint = `/hub/${category}/filters/aggregate`;

        return this.post(endpoint, req, {
            cancelToken: this._cancelTokenHandlerObject[endpoint].handleRequestCancellation().token,
        });
    }

    async fetchEntityFiltersWithCount(
        req: IFiltersAndRangesRequest,
        category: FILTER_CATEGORY = FILTER_CATEGORY.CORPORATES,
    ): Promise<ICorporatesFilter[]> {
        try {
            const response = await this._fetchFiltersWithCount(category, req);
            const modifyAgeType = response
                .map((filters) => createCorporatesFilterFromResponse(filters, category))
                .map((filter) =>
                    filter.title_key === "founding_date"
                        ? {
                            ...filter,
                            type: "age_range",
                        }
                        : filter,
                );

            return modifyAgeType;
        } catch (e) {
            console.warn(e);
        }
    }

    async fetchHubFilters(
        req: IFiltersAndRangesRequest,
        category: FILTER_CATEGORY,
    ): Promise<ICorporatesFilter[]> {
        try {
            const response = await this._fetchHubFilters(category, req);

            return response.map((filters) => {
                // this sets the filters in hub context
                return createCorporatesFilterFromResponse(
                    filters,
                    category === FILTER_CATEGORY.CORPORATES
                        ? FILTER_CATEGORY.CORPORATES_HUB
                        : FILTER_CATEGORY.EXECUTIVES_HUB,
                );
            });
        } catch (e) {
            console.warn(e);
        }
    }

    private _searchAndFilter(
        category: FILTER_CATEGORY,
        request: IFiltersAndRangesRequest,
    ): Promise<any> {
        const endpoint = `/search/${category}`;
        return this.post(endpoint, request, {
            cancelToken: this._cancelTokenHandlerObject[endpoint].handleRequestCancellation().token,
        });
    }

    private _searchAndFilterExport(
        category: FILTER_CATEGORY,
        request: IPaginateFiltersRequest,
        type: EXPORT_TYPE,
    ): Promise<any> {
        const endpoint = `/search/${category}/export/${type}`;
        return this.post(endpoint, request, {
            cancelToken: this._cancelTokenHandlerObject[endpoint].handleRequestCancellation().token,
            responseType: "blob",
        });
    }

    private _assignTag(
        category: FILTER_CATEGORY,
        request: IPaginateFiltersRequest,
        tagId: number,
    ): Promise<any> {
        const endpoint = `/search/${category}/mass-tagging/${tagId}`;
        return this.post(endpoint, request);
    }

    private _searchAndFilterHub(
        request: IFiltersAndRangesRequest,
        category: FILTER_CATEGORY,
    ): Promise<any> {
        const endpoint = `/hub/${category}`;

        // If any ElasticSearch filter is selected, add original company ids
        // to the request.
        if (Object.keys(request.filters).length > 0 || request.ranges.length > 0) {
            if (category === FILTER_CATEGORY.CORPORATES) {
                request.filters = {
                    ...request.filters,
                    company_id: request.unfilteredEntityIds,
                };
            }

            if (category === FILTER_CATEGORY.EXECUTIVES) {
                request.filters = {
                    ...request.filters,
                    executive_id: request.unfilteredEntityIds,
                };
            }
        }

        return this.post(endpoint, request, {
            cancelToken: this._cancelTokenHandlerObject[endpoint].handleRequestCancellation().token,
        });
    }

    private _filterByProductTrends(
        req: IProductTrendsRequest,
    ): Promise<IFilterByProductTrendsResponse> {
        return this.post("/companies/signals/filter", req);
    }

    private async _filterCompanyIdsByProductTrends(companies: number[]): Promise<number[]> {
        const res = await this._filterByProductTrends({
            companies,
        });

        return res.data?.internal_company_ids?.map((id) => id) ?? [];
    }

    private async _filterCompaniesByProductTrends(
        companies: number[],
    ): Promise<IFilterByProductTrendsResponse> {
        return await this._filterByProductTrends({
            companies,
        });
    }

    private _filterByNews(req: IProductTrendsRequest): Promise<IFilterByProductTrendsResponse> {
        return this.post("/companies/news/filter", req);
    }

    private async _filterCompaniesByNews(companies: number[]): Promise<number[]> {
        const res = await this._filterByNews({
            companies,
        });

        return res.data?.internal_company_ids?.map((id) => id) ?? [];
    }

    async getCompanyAnniversaries(): Promise<ICorporatesSearchResponse> {
        return await this.get("/anniversaries")
    }

    async searchAndFilterHubCorporates({
        productTrendsFilters,
        filtersWithoutValues,
        ...request
    }: ISearchAndFilterRequest): Promise<{
        corporates: ICorporate[];
        doc_counts: IDocCounts;
        total_no_filters?: number;
        total?: number | any;
        filteredEntityIds: { [key: string]: string[] };
        anniversaries_filter?: ANNIVERSARIES_FILTER;
        tags_to_consider?: string[];
    }> {
        const res: ICorporatesSearchResponse = await this._searchAndFilterHub(
            request,
            FILTER_CATEGORY.CORPORATES,
        );

        if (Array.isArray(res) && res.length === 0) {
            throw new Error("hubErrorNoData");
        }

        const { tags_to_consider, anniversaries_filter } = res;

        let corporates = res.hits.hits.map(createCorporateFromHit);

        let corporateIds = corporates.map(({ id }) => +id);

        const doc_counts: IDocCounts = createEmptyDocCounts();
        const filteredEntityIds: {
            [key: string]: string[];
        } = {};

        if (productTrendsFilters?.length) {
            const res = await this._filterCompaniesByProductTrends(corporateIds);

            Object.keys(res.data.group_by_product_trend).forEach((trend) => {
                if (!doc_counts.productTrends[trend]) {
                    doc_counts.productTrends[trend] = 0;
                } else {
                    doc_counts.productTrends[trend] = res.data.group_by_product_trend[trend].length;
                }
            });

            const companyIdsForSelectedTrends: number[] = [];
            productTrendsFilters.forEach((selectedTrend) => {
                const companyIdsForSelectedTrend = res.data.group_by_product_trend[selectedTrend];
                companyIdsForSelectedTrends.push(...companyIdsForSelectedTrend);
            });

            // One company id can appear in multiple product trends, so
            // when merging a list of companies from several trends,
            // we need to remove duplicated ids
            corporateIds = [...new Set(companyIdsForSelectedTrends)];

            const corporatesMap = new Map<string, ICorporate>();

            corporates.forEach((corporate) => {
                corporatesMap.set(corporate.id, corporate);
            });

            corporates = corporateIds
                .map((id) => corporatesMap.get(id.toString()) ?? null)
                .filter((x) => x !== null);

            filtersWithoutValues.forEach((filterWithoutValues) => {
                doc_counts.filtersWithoutValues[filterWithoutValues] = corporates.length;
            });
        }

        doc_counts.filtersWithoutValues["tags_Client"] = 0;
        doc_counts.filtersWithoutValues["tags_Lead"] = 0;
        doc_counts.filtersWithoutValues["founding_date"] = 0;
        doc_counts.filtersWithoutValues["status_bankruptcies"] = 0;
        doc_counts.filtersWithoutValues["status_others"] = 0;

        filteredEntityIds["tags_Lead"] = [];
        filteredEntityIds["tags_Client"] = [];
        filteredEntityIds["founding_date"] = [];
        filteredEntityIds["status_bankruptcies"] = [];
        filteredEntityIds["status_others"] = [];

        corporates.forEach((corporate) => {
            if (
                typeof doc_counts.filtersWithValues.affinity_score[corporate.affinity_score] !==
                "undefined"
            ) {
                doc_counts.filtersWithValues.affinity_score[corporate.affinity_score]++;
                doc_counts.filtersWithValues.affinity_score["sum"]++;
            }

            const translatedIndustrySector = formatIndustrySectorForTranslation(
                corporate.industry_sector,
                t,
            );
            if (
                typeof doc_counts.filtersWithValues.industry_sector[translatedIndustrySector] ===
                "undefined"
            ) {
                doc_counts.filtersWithValues.industry_sector[translatedIndustrySector] = 1;
                doc_counts.filtersWithValues.industry_sector["sum"]++;
            } else {
                doc_counts.filtersWithValues.industry_sector[translatedIndustrySector]++;
                doc_counts.filtersWithValues.industry_sector["sum"]++;
            }

            doc_counts.filtersWithValues.success_score[
                this.getScoreRangeFromScoreValue(corporate.success_score)
            ]++;
            doc_counts.filtersWithValues.success_score["sum"]++;

            doc_counts.filtersWithValues.promoter_score[
                this.getScoreRangeFromScoreValue(corporate.promoter_score)
            ]++;
            doc_counts.filtersWithValues.promoter_score["sum"]++;

            if (isClient(corporate)) {
                doc_counts.filtersWithoutValues["tags_Client"] += 1;
            }

            if (isLead(corporate)) {
                doc_counts.filtersWithoutValues["tags_Lead"] += 1;
            }

            if (isStartup(corporate)) {
                doc_counts.filtersWithoutValues["founding_date"] += 1;
            }

            if (isBankruptcy(corporate)) {
                doc_counts.filtersWithoutValues["status_bankruptcies"] += 1;
            }

            if (isOther(corporate)) {
                doc_counts.filtersWithoutValues["status_others"] += 1;
                filteredEntityIds["status_others"].push(corporate.id);
            }
        });

        await Promise.allSettled(
            arrayRemove(echobotFiltersWithoutValues, productTrendsFilters).map(
                async (filterWithoutValues) => {
                    let idCount: number = 0;

                    const filteredCompaniesByProductTrends = await this._filterCompaniesByProductTrends(
                        corporateIds,
                    );

                    switch (filterWithoutValues) {
                        case "product_trends":
                            idCount =
                                filteredCompaniesByProductTrends.data?.internal_company_ids?.length ?? 0;
                            break;
                    }

                    doc_counts.filtersWithoutValues[filterWithoutValues] = idCount;

                    Object.keys(filteredCompaniesByProductTrends.data.group_by_product_trend).forEach(
                        (trend) => {
                            doc_counts.productTrends[trend] =
                                filteredCompaniesByProductTrends.data.group_by_product_trend[trend].length;
                        },
                    );
                },
            ),
        );

        return {
            corporates,
            doc_counts,
            total_no_filters: res.hits.total_no_filters ?? null,
            total: res.hits.total ?? null,
            filteredEntityIds,
            anniversaries_filter,
            tags_to_consider,
        };
    }

    async searchAndFilterHubExecutives({
        // productTrendsFilters, TODO: probably not needed for executives
        filtersWithoutValues,
        ...request
    }: ISearchAndFilterRequest): Promise<{
        executives: IExecutive[];
        doc_counts: IDocCounts;
        total_no_filters?: number;
        total?: number | any;
    }> {
        const res: IExecutivesSearchResponse = await this._searchAndFilterHub(
            request,
            FILTER_CATEGORY.EXECUTIVES,
        );

        if (Array.isArray(res) && res.length === 0) {
            throw new Error("hubErrorNoData");
        }

        let executives = res.hits.hits.map(createExecutiveFromHit);

        const doc_counts: IDocCounts = createEmptyDocCounts();

        doc_counts.filtersWithoutValues["tags_Client"] = 0;
        doc_counts.filtersWithoutValues["tags_Lead"] = 0;

        executives.forEach((executive) => {
            if (
                typeof doc_counts.filtersWithValues.wealth_score[executive.wealth_score] !==
                "undefined"
            ) {
                doc_counts.filtersWithValues.wealth_score[executive.wealth_score]++;
                doc_counts.filtersWithValues.wealth_score["sum"]++;
            }

            const translatedGender = formatGenderForTranslation(executive.gender, t);
            if (typeof doc_counts.filtersWithValues.gender[translatedGender] === "undefined") {
                doc_counts.filtersWithValues.gender[translatedGender] = 1;
                doc_counts.filtersWithValues.gender["sum"]++;
            } else {
                doc_counts.filtersWithValues.gender[translatedGender]++;
                doc_counts.filtersWithValues.gender["sum"]++;
            }

            doc_counts.filtersWithValues.promoter_score[
                this.getScoreRangeFromScoreValue(executive.promoter_score)
            ]++;
            doc_counts.filtersWithValues.promoter_score["sum"]++;

            if (executive.date_of_birth) {
                const executiveAgeInYears = differenceInYears(
                    new Date(),
                    new Date(executive.date_of_birth),
                );
                doc_counts.filtersWithValues.age[this.getAgeRangeFromAgeValue(executiveAgeInYears)]++;
                doc_counts.filtersWithValues.age["sum"]++;
            }

            if (isExecutiveClient(executive)) {
                doc_counts.filtersWithoutValues["tags_Client"] += 1;
            }

            if (isExecutiveLead(executive)) {
                doc_counts.filtersWithoutValues["tags_Lead"] += 1;
            }
        });

        return {
            executives,
            doc_counts,
            total_no_filters: res.hits.total_no_filters ?? null,
            total: res.hits.total,
        };
    }

    async paginateCorporates(
        request: IPaginateFiltersRequest,
    ): Promise<{ corporates: ICorporate[]; total: number }> {
        const res: ICorporatesSearchResponse = await this._searchAndFilter(
            FILTER_CATEGORY.CORPORATES,
            request,
        );

        return {
            corporates: res.hits.hits.map(createCorporateFromHit),
            total: res.hits.total.value,
        };
    }

    async exportCorporates(request: IPaginateFiltersRequest, type: EXPORT_TYPE) {
        const res = await this._searchAndFilterExport(FILTER_CATEGORY.CORPORATES, request, type);

        exportBlob(res, type);
    }

    async paginateExecutives(
        request: IPaginateFiltersRequest,
    ): Promise<{ executives: IExecutive[]; total: number }> {
        const res: IExecutivesSearchResponse = await this._searchAndFilter(
            FILTER_CATEGORY.EXECUTIVES,
            request,
        );

        return {
            executives: res.hits.hits.map(createExecutiveFromHit),
            total: res.hits.total.value,
        };
    }

    async assignTagExecutives(request: IPaginateFiltersRequest, tagId: number) {
        await this._assignTag(FILTER_CATEGORY.EXECUTIVES, request, tagId);
    }

    async assignTagCorporates(request: IPaginateFiltersRequest, tagId: number) {
        await this._assignTag(FILTER_CATEGORY.CORPORATES, request, tagId);
    }

    async exportExecutives(request: IPaginateFiltersRequest, type: EXPORT_TYPE) {
        const res = await this._searchAndFilterExport(FILTER_CATEGORY.EXECUTIVES, request, type);

        exportBlob(res, type);
    }

    getFilteredCompanyIdsByProductTrends(corporateIds: number[]) {
        return this._filterCompanyIdsByProductTrends(corporateIds);
    }

    getFilteredCompaniesByNews(corporateIds: number[]) {
        return this._filterCompaniesByNews(corporateIds);
    }

    /**
     * Promoter score and success score are grouped into ranges.
     * This returns a string representation of range in which
     * current score value fits
     * @param score - can be either promoter or success score
     */
    getScoreRangeFromScoreValue(score: number) {
        if (score > 0 && score <= 20) return "0 - 20";
        if (score > 20 && score <= 40) return "20 - 40";
        if (score > 40 && score <= 60) return "40 - 60";
        if (score > 60 && score <= 80) return "60 - 80";
        if (score > 80 && score <= 100) return "80 - 100";

        // If it doesn't fit into any group,
        // just return it as an empty string
        return "";
    }

    /**
     * Ages are grouped into ranges.
     * This returns a string representation of range in which
     * current age value fits
     * @param age
     */
    getAgeRangeFromAgeValue(age: number) {
        if (age >= 18 && age <= 24) return "18-24";
        if (age >= 25 && age <= 34) return "25-34";
        if (age >= 35 && age <= 44) return "35-44";
        if (age >= 45 && age <= 54) return "45-54";
        if (age >= 55 && age <= 64) return "55-64";
        if (age >= 65) return "65+";

        // If it doesn't fit into any group,
        // just return it as an empty string
        return "";
    }
}

export const filtersApiService = new FiltersApiService();
