export interface ILastSearch {
  id: number;
  term: string;
}

export function createLastSearch(params: Partial<ILastSearch>) {
  return {
    id: null,
    term: null,
    ...params,
  } as ILastSearch;
}
