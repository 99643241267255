import React, { createContext, useContext, useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Flex } from "../../components/containers/Flex/Flex";
import HubPage from "../hub/HubPage";
import CorporateDossier from "./corporates/dossier/CorporateDossier";
import ExecutiveDossier from "./executives/dossier/ExecutiveDossier";
import Header from "./header/Header";
import SearchResultsPage from "./SearchResultsPage/SearchResultsPage";
import { CustomTagsContext } from "../../entities/custom-tags/state/custom-tags.context";
import { LastDossierContext } from "../../entities/last-dossier/state/last-dossier.context";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useLocalStorage from "../../hooks/local-storage.hook";
import { useSession } from "../../hooks/session.hook";
import ExecutivesHubPage from "../executives-hub/ExecutivesHubPage";
import { setFavicon } from "../../helpers/icons.helper";
import { MapContextProvider } from "../../context/MapContext";

interface ICentralSearchContext {
  searchTerm: string;
  corporatesFiltersOpen: boolean;
  executivesFiltersOpen: boolean;

  setSearchTerm(searchTerm: string): void;

  toggleCorporatesFiltersOpen(): void;

  toggleExecutivesFiltersOpen(): void;
}

export const CentralSearchContext = createContext<ICentralSearchContext>({
  searchTerm: "",
  setSearchTerm: () => {},
  corporatesFiltersOpen: false,
  toggleCorporatesFiltersOpen: () => {},
  executivesFiltersOpen: false,
  toggleExecutivesFiltersOpen: () => {},
});

export const CentralSearchTermProvider = ({ children }: { children: JSX.Element }) => {
  const [searchTerm, setSearchTerm] = useLocalStorage("searchTerm", "");
  const [corporatesFiltersOpen, setCorporatesFiltersOpen] = useState(false);

  const toggleCorporatesFiltersOpen = () => {
    setCorporatesFiltersOpen(!corporatesFiltersOpen);
    localStorage.setItem("corporatesFiltersOpen", !corporatesFiltersOpen ? "1" : "0");
  };

  const [executivesFiltersOpen, setExecutivesFiltersOpen] = useState(false);

  const toggleExecutivesFiltersOpen = () => {
    setExecutivesFiltersOpen(!executivesFiltersOpen);
  };

  const customTagsContext = useContext(CustomTagsContext);
  const lastDossierContext = useContext(LastDossierContext);

  useEffect(() => {
    customTagsContext.fetchTagsForCurrentUser();
    lastDossierContext.fetchUserLastDossiers();
    if (localStorage.getItem("corporatesFiltersOpen") === "1") setCorporatesFiltersOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CentralSearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        corporatesFiltersOpen,
        toggleCorporatesFiltersOpen,
        executivesFiltersOpen,
        toggleExecutivesFiltersOpen,
      }}
    >
      {children}
    </CentralSearchContext.Provider>
  );
};

const HomePage: React.FC = () => {
  const match = useRouteMatch();
  const { isB2CUser } = useSession();

  useEffect(() => {
    setFavicon(isB2CUser);
  }, [isB2CUser]);

  return (
    <CentralSearchTermProvider>
      <>
        <Flex column fullWidth fullHeight>
          <Header />

          <Switch>
            <Route path={`${match.path}/search/executive-dossier/:id`}>
              <ExecutiveDossier />
            </Route>
            <Route path={`${match.path}/search/corporate-dossier/:id`}>
              <CorporateDossier />
            </Route>
            <Route path={`${match.path}/search`}>
              <SearchResultsPage />
            </Route>
            <Route path={`${match.path}`}>
              <MapContextProvider>
                {isB2CUser ? <ExecutivesHubPage /> : <HubPage />}
              </MapContextProvider>
            </Route>
          </Switch>
        </Flex>

        <ScrollToTop />
      </>
    </CentralSearchTermProvider>
  );
};

export default HomePage;
