import React from "react";
// import styles from "./styles.module.css";
// import "./styles.module.css";

const Spinner = () => {
  return (
    <div className="spinner bar">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
