import React, { useContext, useEffect, useState, useRef } from "react";
import { Flex } from "../../../../../components/containers/Flex/Flex";
import { ExecutiveDossierContext } from "../../../../../entities/executive-dossier/state/executive-dossier.context";
import {
  SideNavCareerIcon,
  SideNavFoundationReachIcon,
  SideNavEAndEIcon,
} from "../../../../../assets/executive-dossier/sidenav/icons";
import {
  SideNavInvestmentsIcon,
  SideNavRelationshipIcon,
  SideNavLookIcon,
} from "../../../../../assets/company-dossier/sidenav/icons";
import { ResolutionContext } from "../../../../../state/context/ResolutionContext/ResolutionContextProvider";
// import { Spacing } from "../../../../../components/spacing/component";
import SideNavigationItemContent from "../components/side-navigation-item-content/SideNavigationItemContent";
import { IExecutive } from "../../../../../entities/executives/state/executive.model";
import {
  // SideNavigation,
  IMenuItem,
  SIDE_NAVIGATION_ITEM_TYPE,
} from "../components/side-navigation/SideNavigation";
import styles from "../../../corporates/dossier/styles.module.css";
import { useSession } from "../../../../../hooks/session.hook";
import axios from "axios";

interface IProps {
  executiveId: string;
  activeExecutive: IExecutive;
  career: string | null;
  relationshipIndicator: string | null;
  investment: string | null;
  lookALikes: string | null;
  foundationReach: string | null;
  entrepreneursAndExecutives: string | null;
}

const ExecutiveDossierContentContainer = ({
  executiveId,
  activeExecutive,
  career,
  relationshipIndicator,
  investment,
  lookALikes,
  foundationReach,
  entrepreneursAndExecutives,
}: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const [selectedItem, setSelectedItem] = useState<SIDE_NAVIGATION_ITEM_TYPE | null>(null);
  const executiveDossierContext = useContext(ExecutiveDossierContext);
  const { isB2CUser } = useSession();
  const cancelToken = useRef(axios.CancelToken.source());

  const executiveElasticData = [
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.CAREER,
      value: career !== null,
    },
    {
      type: SIDE_NAVIGATION_ITEM_TYPE.INVESTMENT,
      value: investment !== null,
    },
  ];

  const menuItems: IMenuItem[] = [
    {
      title: "career",
      value: career,
      Icon: SideNavCareerIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.CAREER,
      anchor: "career",
    },
    {
      title: "relationshipIndicator",
      value: relationshipIndicator,
      Icon: SideNavRelationshipIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR,
      anchor: "relationshipIndicator",
    },
    {
      title: "investment",
      value: investment,
      Icon: SideNavInvestmentsIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.INVESTMENT,
      anchor: "investment",
    },
  ];

  const b2cMenuItems = [
    ...menuItems,
    {
      title: "executiveLookALikes",
      value: lookALikes,
      Icon: SideNavLookIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.LOOK_A_LIKES,
      anchor: "lookALikes",
    },
    {
      title: "foundationReach",
      value: foundationReach,
      Icon: SideNavFoundationReachIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.FOUNDATION_REACH,
      anchor: "foundationReach",
    },
    {
      title: "entrepreneursAndExecutives",
      value: entrepreneursAndExecutives,
      Icon: SideNavEAndEIcon,
      type: SIDE_NAVIGATION_ITEM_TYPE.ENTREPRENEURS_AND_EXECUTIVES,
      anchor: "entrepreneursAndExecutives",
    },
  ];

  useEffect(
    () => () => {
      cancelToken.current.cancel("Navigating away from the requesting component");
    },
    [],
  );

  useEffect(() => {
    let isMounted = true;

    isMounted && setSelectedItem(null);

    const resolveValue = (type: SIDE_NAVIGATION_ITEM_TYPE) => (value: boolean) => ({
      type,
      value,
    });

    Promise.allSettled([
      executiveDossierContext
        .fetchExecutiveRelationshipIndicator(executiveId, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR)),
      executiveDossierContext
        .fetchExecutiveLookALikes(executiveId, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.LOOK_A_LIKES)),
      executiveDossierContext
        .fetchExecutiveFoundationReach(executiveId, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.FOUNDATION_REACH)),
      executiveDossierContext
        .fetchEntrepreneursAndExecutives(executiveId, cancelToken.current.token)
        .then(resolveValue(SIDE_NAVIGATION_ITEM_TYPE.ENTREPRENEURS_AND_EXECUTIVES)),
    ]).then((response) => {
      if (selectedItem !== null) {
        return;
      }

      const navigationItems = [...executiveElasticData];
      response.forEach((item) => {
        if (item.status === "rejected") return;
        navigationItems.push(item.value);
      });
      const sortedNavigationItems = navigationItems.sort((a, b) => (a.type > b.type ? 1 : -1));

      for (let i = 0; i < sortedNavigationItems.length; i++) {
        const { type, value } = sortedNavigationItems[i];

        if (value) {
          executiveDossierContext.setExecutiveSideNavigationActiveAnchor(
            isB2CUser ? b2cMenuItems[type].anchor : menuItems[type].anchor,
          );

          isMounted && setSelectedItem(type);
          return;
        }
      }
    });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveId]);

  // const onSetActiveItem = (item: IMenuItem) => (activeAnchor: string) => {
  //     if (activeAnchor === item.anchor) {
  //         setSelectedItem(item.type);
  //     }
  // };

  return (
    <Flex>
      {/* {!isSmallerScreen && (
                <Flex column>
                    <SideNavigation
                        menuItems={isB2CUser ? b2cMenuItems : menuItems}
                        selectedItem={selectedItem}
                        onSetActiveItem={onSetActiveItem}
                    />
                </Flex>
            )} */}

      {/* {!isSmallerScreen && <Spacing pr="70" />} */}

      <Flex grow column className={!isSmallerScreen && styles.rightColumn}>
        {activeExecutive && (
          <SideNavigationItemContent
            executiveName={activeExecutive.full_name}
            careerData={activeExecutive.management}
            investmentData={activeExecutive.shareholder}
            executiveId={executiveId}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ExecutiveDossierContentContainer;
