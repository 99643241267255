import { IExecutiveHubFooterItemData } from "../../../shared/interfaces/hub-footer-item-data.interface";
import { Store, StoreConfig } from "@datorama/akita";

export interface ExecutivesHubState {
  firstLoad: boolean;

  executiveIds: string[];
  loadingExecutives: boolean;
  hubErrorNoData: boolean;

  rolloverExecutiveId: string;

  // TODO: needed only for corporates hub?
  // hubProductTrendsFooterItems: IHubFooterItemData[];
  // loadingTopProductTrends: boolean;

  // TODO: needed only for corporates hub?
  // cumulativeNewsCount: number | null;
  // loadingCumulativeNewsCount: boolean; // TODO: not used anywhere? shuold it be used to show a spinner?

  hubNewsFooterItems: IExecutiveHubFooterItemData[] | null;
  loadingTopNews: boolean;

  executivesPBIds: string[];
  executivesHNWIIds: string[];

  unfilteredHubExecutivesIds: string[];
  unfilteredHubExecutivesCount: number | null;

  hubLeadsFooterItems: IExecutiveHubFooterItemData[] | null;
}

const createInitialState = (): ExecutivesHubState => {
  return {
    firstLoad: true,
    executiveIds: null,
    loadingExecutives: null,
    hubErrorNoData: false,

    rolloverExecutiveId: null,

    // hubProductTrendsFooterItems: null,
    // loadingTopProductTrends: true,

    // cumulativeNewsCount: null,
    // loadingCumulativeNewsCount: false,

    hubNewsFooterItems: null,
    loadingTopNews: true,

    executivesPBIds: null,
    executivesHNWIIds: null,

    unfilteredHubExecutivesIds: null,
    unfilteredHubExecutivesCount: null,

    hubLeadsFooterItems: null,
  };
};

@StoreConfig({
  name: "executives-hub",
})
export class ExecutivesHubStore extends Store<ExecutivesHubState> {
  constructor() {
    super(createInitialState());
  }
}

export const executivesHubStore = new ExecutivesHubStore();
