import {
  ICorporateData,
  IIndustrySectorCode,
  INewsResponse,
  IProductTrendsResponse,
  IRelationshipIndicatorResponse,
} from "../../entities/corporate-dossier/corporate-dossier.model";
import { ICorporatesSearchResponse } from "../interfaces/corporates-search-response.interface";
import ApiService from "./api.service";
import { ICorporatesHitSource } from "../../entities/corporates/interfaces/corporates-hit.interface";
import { FILTER_CATEGORY } from "../enums/filter-category.enum";
import { RELATIONS_COUNT } from "../enums/relationship-indicator.enum";
import { ICorporatesLatestSignalRequest } from "../interfaces/corporates-latest-signal-request.interface";
import { CancelToken } from "axios";

export class CorporatesApiService extends ApiService {
  getIndustrySectorCodeData(sectorCode: string): Promise<IIndustrySectorCode> {
    return this.get(`/industry-sectors/${sectorCode}`);
  }

  getCorporateLookALikes(
    type: FILTER_CATEGORY,
    corporateId: string,
    cancelToken?: CancelToken,
  ): Promise<ICorporatesSearchResponse> {
    return this.get(`/look-a-likes/${type}/${corporateId}`, {
      cancelToken,
    });
  }

  getById(id: string): Promise<ICorporatesHitSource> {
    return this.get(`/companies/${id}`);
  }

  getCorporateNews(
    companyId: string,
    companyData: ICorporateData,
    cancelToken?: CancelToken,
  ): Promise<INewsResponse> {
    return this.post(`/companies/${companyId}/news`, companyData, {
      cancelToken,
    });
  }

  getCorporateProductTrends(
    companyId: string,
    companyData: ICorporateData,
    cancelToken?: CancelToken,
  ): Promise<IProductTrendsResponse> {
    return this.post(`/companies/${companyId}/signals`, companyData, {
      cancelToken,
    });
  }

  getCorporateRelationshipIndicator(
    type: FILTER_CATEGORY,
    companyId: string,
    count: RELATIONS_COUNT, // if TRUE only relations count will be returned in response
    cancelToken?: CancelToken,
  ): Promise<IRelationshipIndicatorResponse> {
    return this.get(`/relationship-explorer/${type}/${companyId}/${count}`, { cancelToken });
  }

  // TODO Create response interface
  getCorporatesLatestSignal(req: ICorporatesLatestSignalRequest): Promise<any> {
    return this.post(`/companies/signals/latest`, req);
  }

  // TODO Create response interface
  getCorporatesProductTrendsCount(req: ICorporatesLatestSignalRequest): Promise<any> {
    return this.post(`/companies/signals/batch/count`, req);
  }
}

export const corporatesApiService = new CorporatesApiService();
