import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import { ReactComponent as ChevronLeftIcon } from "../../../assets/ChevronLeftBlue.svg";
import { Flex } from "../../../components/containers/Flex/Flex";
import { Spacing } from "../../../components/spacing/component";
import RText from "../../../components/fonts/RText/RText";
import GoTo from "./GoTo/GoTo";
import { useIsDossier, useIsHub } from "../../../utils/route.util";
import { ResolutionContext } from "../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../hooks/session.hook";

const Toolbar = () => {
  const { t } = useTranslation();
  const history = useHistory<any>();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser } = useSession();
  const isDossier = useIsDossier();
  const isHub = useIsHub();

  const renderDefaultToolbar = () => {
    return (
      <>
        {isDossier || isHub ? null : <div />}

        <GoTo />

        {isDossier &&
          (history.location.state?.fromSearchResults ? (
            <Flex
              className={styles.searchResults}
              alignItemsCenter
              pointer
              onClick={() => history.goBack()}
            >
              <ChevronLeftIcon color={isB2CUser ? "#d5c599" : "#5379bf"} />
              <Spacing pr="10" />
              <RText fontSize="15">{t("searchResults")}</RText>
            </Flex>
          ) : (
            <div />
          ))}
      </>
    );
  };

  const renderSmallScreenToolbar = () => {
    return (
      <>
        <GoTo />

        {isDossier || isHub ? null : <div />}

        {isDossier &&
          (history.location.state?.fromSearchResults ? (
            <Flex
              className={styles.searchResults}
              alignItemsCenter
              pointer
              onClick={() => history.goBack()}
            >
              <ChevronLeftIcon color={isB2CUser ? "#d5c599" : "#5379bf"} />
              <Spacing pr="10" />
              <RText fontSize="15">{t("searchResults")}</RText>
            </Flex>
          ) : (
            <div />
          ))}
      </>
    );
  };

  return (
    <>
      <Spacing
        // pl={padding}
        // pr={padding}
        style={{
          backgroundColor: "var(--color_background_white)",
        }}
      >
        <Flex
          alignItemsCenter
          className={
            isSmallerScreen ? styles.toolbarContent__Responsive : styles.toolbarContent
          }
        >
          {isSmallerScreen ? renderSmallScreenToolbar() : renderDefaultToolbar()}
        </Flex>
      </Spacing>
    </>
  );
};

export default Toolbar;
