import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { ICorporateNote } from "./corporate-notes.model";

export interface CorporateNotesState extends EntityState<ICorporateNote> {}

@StoreConfig({
  name: "corporate-notes",
})
export class CorporateNotesStore extends EntityStore<CorporateNotesState> {}

export const corporateNotesStore = new CorporateNotesStore();
