import { useContext, useEffect, useState } from "react";
// import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./styles.module.css";
import { ReactComponent as NavIconExecutives } from "../../../../assets/NavIconExecutives.svg";
import { ReactComponent as PDFFile } from "../../../../assets/PDFFile.svg";
import { Flex } from "../../../../components/containers/Flex/Flex";
import RText from "../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../components/spacing/component";
import Divider from "../../../../components/dividers/Divider/Divider";
import Score from "../../corporates/dossier/components/score/Score";
import PersonInfo from "./components/person-info/PersonInfo";
import { abbreviateNumber } from "../../../../helpers/number.helper";
import {
  useEntrepreneursAndExecutives,
  useExecutiveFoundationReach,
  useExecutiveLookALikes,
  useExecutiveRelationshipIndicator,
} from "../../../../entities/executive-dossier/hooks/executive-dossier.hook";
import { getRelationshipIndicatorCount } from "../../corporates/dossier/components/side-navigation-item-content/relationship-indicator/RelationshipIndicator";
import { corporatesApiService } from "../../../../api/services/corporates-api.service";
import { ICorporatesHitSource } from "../../../../entities/corporates/interfaces/corporates-hit.interface";
import { IRouteParams } from "../../../../shared/interfaces/route-params.interface";
import { ExecutiveDossierContext } from "../../../../entities/executive-dossier/state/executive-dossier.context";
import {
  createCorporateFromHitSource,
  ICorporate,
} from "../../../../entities/corporates/models/corporate.model";
import { useActiveExecutive } from "../../../../entities/executives/hook/active.executive.hook";
import { getInvestmentCount } from "../../corporates/dossier/components/side-navigation-item-content/investment/Investment";
import { LastDossierContext } from "../../../../entities/last-dossier/state/last-dossier.context";
import { FILTER_CATEGORY } from "../../../../api/enums/filter-category.enum";
import Notes from "./components/notes/Notes";
import DossierMap from "../../corporates/dossier/components/map/DossierMap";
import ExecutiveDossierContentContainer from "./ExecutiveDossierContentContainer/ExecutiveDossierContentContainer";
import { getExecutiveActiveCompany } from "../../../../helpers/dossier.helper";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import useAppColors from "../../../../hooks/app-colors.hook";
import { useSession } from "../../../../hooks/session.hook";
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material"; // CircularProgress
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";
import { KeyboardArrowDown } from "@mui/icons-material";
import { IMenuItem } from "./components/side-navigation/SideNavigation";
import {
  SideNavInvestmentsIcon,
  SideNavRelationshipIcon,
} from "../../../../assets/company-dossier/sidenav/icons";
import { SideNavCareerIcon } from "../../../../assets/executive-dossier/sidenav/icons";
import Toolbar from "../../Toolbar/Toolbar";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface MenuItem {
  title: string;
  Icon: any;
  anchor: string;
  counter?: string | number | null;
}

export default function ExecutiveDossier() {
  const { t } = useTranslation();
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { id } = useParams<IRouteParams>();
  const { colors } = useAppColors();
  const {
    isB2CUser,
    currentUser: { export_people_permission },
  } = useSession();
  const exportPermission = export_people_permission && export_people_permission === "yes";

  const executiveDossierContext = useContext(ExecutiveDossierContext);
  const lastDossierContext = useContext(LastDossierContext);

  const { activeExecutive } = useActiveExecutive();

  const [activeCorporate, setActiveCorporate] = useState<ICorporate>(null);
  const [visibility, setVisibility] = useState(false);
  // const [pdfState, setPdfState] = useState<any>({ career: true, relationships: true, investment: true })
  // const [pdfStateCheckAll, setPdfStateCheckAll] = useState<boolean>(true)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAnchor, setCurrentAnchor] = useState<string | null>(null);
  const handleDropdownOpen = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const selector = document.querySelector(`div[name=${currentAnchor}]`);
    if (currentAnchor && selector) {
      selector.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [currentAnchor]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo(0, 0);
      executiveDossierContext.fetchExecutiveData(id);
      setVisibility(false);
      setTimeout(() => setVisibility(true));
    }

    return () => {
      isMounted = false;
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const executiveActiveCompany =
    activeExecutive &&
    getExecutiveActiveCompany(
      activeExecutive.management,
      activeExecutive.main_company_id,
      activeExecutive.shareholder,
    );

  useEffect(() => {
    let isMounted = true;

    executiveActiveCompany &&
      corporatesApiService
        .getById(executiveActiveCompany.company_id.toString())
        .then((res: ICorporatesHitSource) => {
          isMounted && setActiveCorporate(createCorporateFromHitSource(res));
        })
        .catch((e) => console.log(e));

    return () => {
      isMounted = false;
    };
  }, [executiveActiveCompany]);

  const { executiveRelationshipIndicator } = useExecutiveRelationshipIndicator();
  const relationshipIndicatorCount = getRelationshipIndicatorCount(
    executiveRelationshipIndicator,
    isB2CUser,
  );

  const investmentCount = getInvestmentCount(activeExecutive?.shareholder);

  const { executiveLookALikes, totalExecutiveLookALikes } = useExecutiveLookALikes();

  const { executiveFoundationReachCount } = useExecutiveFoundationReach();
  const { totalEntrepreneursAndExecutives } = useEntrepreneursAndExecutives();

  if (!activeExecutive) return null;

  lastDossierContext.saveLastDossier({
    dossier_id: +activeExecutive.id,
    dossier_title: activeExecutive.full_name,
    type: FILTER_CATEGORY.EXECUTIVES,
  });

  // const handleCheckboxChange = (e: any) => {
  //     setPdfState((prevState: any) => {
  //         const newState = { ...prevState, [e.target.name]: !pdfState[e.target.name] }

  //         if (Object.values(newState).includes(false)) { setPdfStateCheckAll(false) }
  //         if (!Object.values(newState).includes(false)) { setPdfStateCheckAll(true) }

  //         return newState
  //     })
  // }

  // const handleCheckboxAll = () => {
  //     if (pdfStateCheckAll) {
  //         setPdfStateCheckAll(false)
  //         setPdfState({ career: false, relationships: false, investment: false })
  //     } else {
  //         setPdfStateCheckAll(true)
  //         setPdfState({ career: true, relationships: true, investment: true })
  //     }
  // }

  const menuItems: MenuItem[] = [
    {
      title: "career",
      // value: relationshipIndicator,
      Icon: SideNavCareerIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR,
      anchor: "career",
      counter:
        activeExecutive.management && activeExecutive.management.length > 0
          ? abbreviateNumber(activeExecutive.management.length)
          : null,
    },
    {
      title: "relationshipIndicator",
      // value: relationshipIndicator,
      Icon: SideNavRelationshipIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.RELATIONSHIP_INDICATOR,
      anchor: "relationshipIndicator",
      counter:
        relationshipIndicatorCount && +relationshipIndicatorCount > 0
          ? abbreviateNumber(+relationshipIndicatorCount)
          : null,
    },
    {
      title: "investment",
      // value: investment,
      Icon: SideNavInvestmentsIcon,
      // type: SIDE_NAVIGATION_ITEM_TYPE.INVESTMENT,
      anchor: "investment",
      counter: investmentCount > 0 ? abbreviateNumber(investmentCount) : null,
    },
  ];

  const handleDropdownClose = (name: string) => {
    setAnchorEl(null);
    const namesList = menuItems.map((item: Partial<IMenuItem>) => item.anchor);

    if (name && namesList.includes(name)) {
      setCurrentAnchor(name);
    }
  };

  const renderDefaultMainInfo = () => {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <NavIconExecutives
                  color={colors.backgroundBlackColor}
                  className={
                    isSmallerScreen ? styles.executiveIconSmaller : styles.executiveIcon
                  }
                />
              </Grid>
              <Grid item>
                <RText bold fontSize={isSmallerScreen ? "20" : "25"}>
                  {t("personDossier")}
                </RText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Notes executiveId={activeExecutive.id} />
              </Grid>
              {exportPermission && (
                <Grid item>
                  {!activeExecutive && <CircularProgress />}
                  {activeExecutive ? (
                    <PDFDownloadLink
                      document={
                        <PDFDocument activeExecutive={activeExecutive} menuItems={menuItems} />
                      }
                      fileName={`MOVE, Dossier of ${activeExecutive.full_name}.pdf`}
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PDFFile height={32} style={{ marginRight: 8 }} />
                      <RText fontSize={isSmallerScreen ? "15" : "18"}>
                        {t("downloadDossier")}
                      </RText>
                    </PDFDownloadLink>
                  ) : null}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Spacing pt="25" />
        <Divider black />

        <Spacing pt="25" />

        <Flex wrap>
          <Flex
            column
            className={`
                            ${isSmallerScreen ? styles.leftColumnSmaller : styles.leftColumn}
                            ${styles.columnSection}
                        `}
            style={{ minWidth: "unset" }}
          >
            {activeExecutive && (
              <PersonInfo
                id={activeExecutive.person_id.toString()}
                fullName={activeExecutive.full_name}
                city={activeExecutive.city ?? ""}
                executivePosition={executiveActiveCompany?.position}
                activeCorporate={activeCorporate}
                personBirthday={activeExecutive.date_of_birth}
                wealthScore={activeExecutive.wealth_score}
              />
            )}
          </Flex>
          <Spacing pr={isSmallerScreen ? "20" : "70"} />
          <Flex
            column
            className={`
                            ${isSmallerScreen ? styles.rightColumnSmaller : styles.rightColumn}
                            ${styles.columnSection}
                        `}
          >
            <DossierMap
              height="130px"
              width="100%"
              corporate={activeCorporate}
              executiveLookALikes={
                executiveLookALikes ? [...executiveLookALikes].slice(0, 5) : null
              }
            />
            <Score
              promoterScore={activeExecutive.promoter_score}
              wealthScore={activeExecutive.wealth_score}
              foundationReachScore={executiveFoundationReachCount}
              eAndEScore={activeExecutive.ee_score}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          sx={{ position: "sticky", top: 20, zIndex: 1000 }}
        >
          <Grid item sx={{ display: { xs: "none", md: "block" } }}>
            <Toolbar />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              endIcon={<KeyboardArrowDown />}
              sx={{ ".MuiButton-endIcon": { marginLeft: 9 } }}
              onClick={handleDropdownClick}
            >
              Insights
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={handleDropdownOpen}
              onClose={handleDropdownClose}
            >
              {menuItems.map((item, index) => {
                const { title, Icon, anchor, counter } = item;
                const ItemIcon = Icon;

                if (!counter) return null;

                return (
                  <MenuItem
                    onClick={() => handleDropdownClose(anchor)}
                    key={title}
                    sx={{
                      gap: 2,
                      borderBottom:
                        index !== menuItems.length - 1 && "1px solid var(--color_gray_light)",
                      py: 1.25,
                    }}
                  >
                    <ListItemIcon>
                      <ItemIcon style={{ width: 36, height: 36 }} />
                    </ListItemIcon>
                    <ListItemText>{t(title)}</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                      {counter}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
        </Grid>

        <Divider />

        <Spacing pt="15" />
        {renderDefaultMainInfo()}

        {/* <Divider black /> */}
        <Spacing pt="25" />

        {!visibility ? null : (
          <ExecutiveDossierContentContainer
            executiveId={id}
            activeExecutive={activeExecutive}
            career={
              activeExecutive.management && activeExecutive.management.length > 0
                ? abbreviateNumber(activeExecutive.management.length)
                : null
            }
            relationshipIndicator={
              relationshipIndicatorCount && +relationshipIndicatorCount > 0
                ? abbreviateNumber(+relationshipIndicatorCount)
                : null
            }
            investment={investmentCount > 0 ? abbreviateNumber(investmentCount) : null}
            lookALikes={
              totalExecutiveLookALikes > 0 ? abbreviateNumber(totalExecutiveLookALikes) : null
            }
            foundationReach={
              executiveFoundationReachCount > 0
                ? abbreviateNumber(executiveFoundationReachCount)
                : null
            }
            entrepreneursAndExecutives={
              totalEntrepreneursAndExecutives > 0
                ? abbreviateNumber(totalEntrepreneursAndExecutives)
                : null
            }
          />
        )}
      </Container>
    </>
  );
}
