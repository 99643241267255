import {
  ICorporateHubMarker,
  IExecutiveHubMarker,
} from "../entities/corporates/interfaces/hub-marker.interface";
import {
  generateMarkerIconBasedOnMarkerData,
  getCorrectPath,
  MarkerIconSize,
  StarIconSize,
} from "../pages/home/corporates/dossier/components/marker-icon/marker.icons";
import L from "leaflet";
import { sessionFacade } from "../state/akita/session/session.facade";

/**
 * Checks if any of the companies in the marker is hovered one in sidebar.
 *
 * @param hubMarker
 * @param hubRolloverCorporateId - id of the company in sidebar over which
 *        user is currently hovering
 */
export const isPurpleMarker = (
  hubMarker: ICorporateHubMarker,
  hubRolloverCorporateId: string,
): boolean => {
  const rolloverCorporate = hubMarker.corporatesData.find(
    (c) => c.corporateId === hubRolloverCorporateId,
  );

  return !!rolloverCorporate;
};

/**
 * Checks if any of the executives in the marker is hovered one in sidebar.
 *
 * @param hubMarker
 * @param hubRolloverExecutiveId - id of the executive in sidebar over which
 *        user is currently hovering
 */
export const isPurpleExecutiveMarker = (
  hubMarker: IExecutiveHubMarker,
  hubRolloverExecutiveId: string,
): boolean => {
  const rolloverCorporate = hubMarker.executivesData.find(
    (e) => e.executiveId === hubRolloverExecutiveId,
  );

  return !!rolloverCorporate;
};

/**
 * Returns marker size based on the number of companies contained
 * in that marker.
 *
 *  Companies in marker
 *  - 1    -> small icon
 *  - 2-10 -> medium icon
 *  - 10+  -> large icon
 *
 * @param numOfCompanies
 */
export const getMarkerIconSize = (numOfCompanies: number): MarkerIconSize => {
  if (numOfCompanies === 1) {
    return MarkerIconSize.S_MARKER;
  } else if (numOfCompanies >= 2 && numOfCompanies <= 10) {
    return MarkerIconSize.M_MARKER;
  } else {
    return MarkerIconSize.L_MARKER;
  }
};

/**
 * Returns star icon size based on the average affinity of all companies
 * inside that marker.
 *
 *  Affinity
 *  - 0-2 -> small star
 *  - 3-4 -> medium star
 *  - 5   -> large star
 *
 * @param affinity
 */
export const getStarIconSize = (affinity: number): StarIconSize => {
  if (affinity <= 2) {
    return StarIconSize.S_STAR;
  } else if (affinity >= 3 && affinity <= 4) {
    return StarIconSize.M_STAR;
  } else {
    return StarIconSize.L_STAR;
  }
};

/**
 * Creates the medium sized purple marker icon which doesn't follow
 * marker size, star size and star color rules like marker on Hub do.
 *
 * This icon is used on dossier map as company location
 * and on FullScreenMapModal as destination icon.
 */
export const createDefaultPurpleIcon = (): L.DivIcon => {
  const { markerPath, starPath } = getCorrectPath(
    MarkerIconSize.M_MARKER,
    StarIconSize.M_STAR,
  );

  let iconOptions = {
    mapIconUrl: generateMarkerIconBasedOnMarkerData(),
    markerStrokeColor: "#ffffff",
    markerFillColor: "#9E00C6",
    starFillColor: "#ffffff",
    strokeWidth: 0.5,
    markerPath: markerPath,
    starPath: starPath,
  };

  if (sessionFacade.isB2CUser()) {
    iconOptions = {
      ...iconOptions,
      markerFillColor: "#d5c599",
      starFillColor: "#000000",
    };
  }

  return new L.DivIcon({
    className: "leaflet-data-marker",
    html: L.Util.template(iconOptions.mapIconUrl, iconOptions),
    iconSize: [80, 80],
    popupAnchor: [0, -10],
  });
};

/**
 * Decides on which icon should be shown on Hub as company marker
 *
 * @param hubMarker - data for marker and companies within it
 * @param hubRolloverCorporateId - company in sidebar over which user is
 *        currently hovering
 */
// TODO: maybe we can improve performance if we create the icons
//  when IHubMarker data is being created and put the icon as one of
//  it's properties. This way it will already exist in the data
//  and won't have to call this method for each marker
export const createHubDivIconFromMarkerData = (
  hubMarker: ICorporateHubMarker,
  hubRolloverCorporateId: string,
): L.DivIcon => {
  // Get the number of companies in marker
  const numOfCompanies = hubMarker.corporatesData.length;

  // Calculate average value of affinity for all companies in marker
  let affinitySum = 0;
  hubMarker.corporatesData.forEach((c) => (affinitySum += c.affinity));
  const affinityAvg = Math.ceil(affinitySum / numOfCompanies);

  // Get all parts of the icon name
  const markerIconSize = getMarkerIconSize(numOfCompanies);
  const starIconSize = getStarIconSize(affinityAvg);
  const isPurple = isPurpleMarker(hubMarker, hubRolloverCorporateId);

  const { markerPath, starPath } = getCorrectPath(markerIconSize, starIconSize);

  let starFill = "#ffffff";
  if (numOfCompanies === 1) {
    const corporateTags = hubMarker.corporatesData[0].tags;

    if (corporateTags && corporateTags.length > 0) {
      // Fills star with last tagged color
      const currentTag = corporateTags[corporateTags.length - 1];
      if (currentTag && currentTag?.color_code) {
        starFill = currentTag.color_code;
      }
    }
  }

  const iconOptions = {
    mapIconUrl: generateMarkerIconBasedOnMarkerData(),
    markerStrokeColor: "#ffffff",
    markerFillColor: isPurple ? "#9E00C6" : "#000000",
    starFillColor: starFill,
    strokeWidth: isPurple ? 2 : 0.5,
    markerPath: markerPath,
    starPath: starPath,
  };

  return new L.DivIcon({
    className: "leaflet-data-marker",
    html: L.Util.template(iconOptions.mapIconUrl, iconOptions),
    iconSize: [80, 80],
    popupAnchor: [0, -10],
  });
};

export const createExecutiveHubDivIconFromMarkerData = (
  hubMarker: IExecutiveHubMarker,
  hubRolloverCorporateId: string,
): L.DivIcon => {
  // Get the number of companies in marker
  const numOfExecutives = hubMarker.executivesData.length;

  // Get all parts of the icon name
  const markerIconSize = getMarkerIconSize(numOfExecutives);
  // TODO: use some score which will represent "affinity" for executives
  const starIconSize = getStarIconSize(5);
  const isPurple = isPurpleExecutiveMarker(hubMarker, hubRolloverCorporateId);

  const { markerPath, starPath } = getCorrectPath(markerIconSize, starIconSize);

  let starFill = isPurple ? "#000000" : "#d5c599";
  if (numOfExecutives === 1) {
    const executiveTags = hubMarker.executivesData[0].tags;

    if (executiveTags?.length) {
      // Fills star with last tagged color
      starFill = executiveTags[executiveTags.length - 1].color_code;
    }
  }

  const iconOptions = {
    mapIconUrl: generateMarkerIconBasedOnMarkerData(),
    markerStrokeColor: "#ffffff",
    markerFillColor: isPurple ? "#d5c599" : "#000000",
    starFillColor: starFill,
    strokeWidth: isPurple ? 2 : 0.5,
    markerPath: markerPath,
    starPath: starPath,
  };

  return new L.DivIcon({
    className: "leaflet-data-marker",
    html: L.Util.template(iconOptions.mapIconUrl, iconOptions),
    iconSize: [80, 80],
    popupAnchor: [0, -10],
  });
};
