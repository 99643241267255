import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import Divider from "../../../components/dividers/Divider/Divider";
import RText from "../../../components/fonts/RText/RText";
import CorporatesSearchResultsListHeader from "../../../components/lists/SearchResultsList/CorporatesSearchResultsListHeader/CorporatesSearchResultsListHeader";
import CorporatesSearchResultsListItem, {
  mapCorporatesSearchResponseIntoResultItems,
} from "../../../components/lists/SearchResultsList/CorporatesSearchResultsListItem/CorporatesSearchResultsListItem";
import Spinner from "../../../components/other/Spinner/Spinner";
import { Spacing } from "../../../components/spacing/component";
import { useCorporatesSearchResponse } from "../../../entities/corporates-filters/hooks/corporates-search-response.hook";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ILocationState } from "../../../shared/interfaces/location.interface";

const CorporatesSearchResults = () => {
  const { t } = useTranslation();

  const { searchResultTotalCount, searchResults, fetchMore, loading } =
    useCorporatesSearchResponse();

  const location = useLocation<ILocationState>();

  if (!searchResults?.length) {
    if (loading) {
      return <Spinner />;
    }

    return (
      <Spacing pt="48">
        <RText fontSize="28">{t("noSearchResults")}</RText>
      </Spacing>
    );
  }

  return (
    <Spacing pt="48">
      <CorporatesSearchResultsListHeader
        column1={t("company")}
        column2={t("productAffinity")}
        column3={t("industry")}
      />

      <Spacing pt="12" />

      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={fetchMore}
        hasMore={searchResults.length < searchResultTotalCount}
        loader={<Spinner key={searchResults.length + 1} />}
      >
        <Divider />
        {mapCorporatesSearchResponseIntoResultItems(searchResults).map((item) => {
          return (
            <React.Fragment key={item.id}>
              {location.state && item.id === location.state.companyId && (
                <div
                  style={{
                    backgroundColor: "var(--color_background_black)",
                    padding: "2px 8px",
                    color: "var(--color_background_white)",
                    borderRadius: "4px",
                    display: "inline-block",
                    marginTop: "-10px",
                    marginLeft: "10px",
                  }}
                >
                  {location.state.tagName}
                </div>
              )}
              <CorporatesSearchResultsListItem key={item.id} item={item} />
              <Divider />
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </Spacing>
  );
};

export default CorporatesSearchResults;
