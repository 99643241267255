import { EntityStore, EntityUIStore, StoreConfig } from "@datorama/akita";

export interface InterestsFiltersState {}
export interface InterestsFiltersUIState {
  searching: boolean;
  lastSearchTerm: string;
  nextSearchPage: number;
  searchResultTotalCount: number;
  searchResultIds: string[];
}

@StoreConfig({
  name: "interests-filters",
})
export class InterestsFiltersStore extends EntityStore<InterestsFiltersState> {
  ui: EntityUIStore<InterestsFiltersUIState>;

  constructor() {
    super({ loading: null });

    this.createUIStore({
      searching: null,
      lastSearchTerm: "",
      nextSearchPage: 1,
      searchResultTotalCount: null,
      searchResultIds: null,
    });
  }
}

export const interestsFiltersStore = new InterestsFiltersStore();
