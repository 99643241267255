import React from "react";
import styles from "./styles.module.css";
import { useLngString } from "../../../../localization/i18n";
import { AUTOMATION_TEST_KEY } from "../../../../constants/general";
// import { changeLanguage } from 'i18next'

import Select, { Theme, CSSObjectWithLabel, OnChangeValue } from "react-select";

interface Option {
  value: string;
  label: string;
}

const automationMarker = { [AUTOMATION_TEST_KEY]: "hdr-language-switch-btn" };

const handleLanguageChange = (lang: string) => {
  window.sessionStorage.setItem("i18nextLng", lang);
  // changeLanguage(lang)
  window.location.reload();
};

const LanguageSelect = () => {
  const options: Option[] = [
    { value: "en", label: "EN" },
    { value: "de", label: "DE" },
    { value: "fr", label: "FR" },
    { value: "it", label: "IT" },
  ];

  const defaultValue =
    options.find(
      (item: Option) => item.value === window.sessionStorage.getItem("i18nextLng"),
    ) || options[0];

  const styles = {
    control: (styles: CSSObjectWithLabel, { menuIsOpen }: { menuIsOpen: boolean }) => ({
      ...styles,
      borderRadius: "10px",
      width: 85,
      boxShadow: "unset",
      border: 0,
      ...{ borderRadius: menuIsOpen ? "10px 10px 0 0" : "10px" },
    }),
    menu: (base: CSSObjectWithLabel) => ({
      ...base,
      marginTop: 0,
      borderRadius: "0 0 10px 10px",
    }),
  };

  const handleChange = (option: OnChangeValue<Option, false>) => {
    const { value } = option;

    handleLanguageChange(value);
  };

  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions
      onChange={handleChange}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={styles}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "var(--color_primary_light)",
          primary50: "var(--color_primary_dark)",
        },
      })}
    />
  );
};

const lngSwitch = {
  en: {
    label: "EN",
    onClick: () => {
      handleLanguageChange("en");
    },
  },
  de: {
    label: "DE",
    onClick: () => {
      handleLanguageChange("de");
    },
  },
};

const LanguageSwitcher = () => {
  const currentLngSwitch = useLngString() === "en" ? lngSwitch["de"] : lngSwitch["en"];

  if (window.location.pathname.includes("/ch/")) return <LanguageSelect />;

  return (
    <button {...automationMarker} className={styles.button} onClick={currentLngSwitch.onClick}>
      {currentLngSwitch.label}
    </button>
  );
};

export default LanguageSwitcher;
