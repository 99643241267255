import { IFilterValue } from "../../../shared/interfaces/filter-value.interface";

export interface ICorporatesFilterValue {
  id: string;
  label: string;
  value_key: string;
  to?: string;
  from?: string;
  doc_count?: number;
}

export function createCorporatesFilterValue(params: Partial<ICorporatesFilterValue>) {
  return {
    id: null,
    label: null,
    to: null,
    from: null,
    doc_count: null,
    ...params,
  } as ICorporatesFilterValue;
}

export function createCorporatesFilterValueFromResponse(params: IFilterValue) {
  return createCorporatesFilterValue({
    ...params,
    id: params.label,
  });
}
