import { ICorporatesFilterWithUI } from "../../../helpers/filters.helper";
import { IHubFilterGroup } from "../../../shared/interfaces/hub-filter-group.interface";
import { executivesTitleKeys, marketDriversTitleKeys } from "../../../constants/hub";
import { useContext } from "react";
import { useObservable } from "@mindspace-io/react";
import { map } from "rxjs/operators";
import { ExecutivesFiltersContext } from "../state/executives-filters.context";

const extractFiltersIntoGroups = (
  filterWithUIs: ICorporatesFilterWithUI[],
): IHubFilterGroup[] => {
  const executivesFilterGroup: IHubFilterGroup = {
    title: "executivesFilterGroup",
    count: 0,
    filters: [],
  };
  const marketDriversFilterGroup: IHubFilterGroup = {
    title: "marketDrivers",
    count: 0,
    filters: [],
  };

  filterWithUIs.forEach((filterWithUI) => {
    if (executivesTitleKeys.includes(filterWithUI.filter.title_key)) {
      executivesFilterGroup.filters.push(filterWithUI);
    }

    if (marketDriversTitleKeys.includes(filterWithUI.filter.title_key)) {
      marketDriversFilterGroup.filters.push(filterWithUI);
    }
  });

  return [executivesFilterGroup, marketDriversFilterGroup];
};

interface IFilterWithUIGroupsHook {
  filterWithUIGroups: IHubFilterGroup[];
  loadingExecutivesFilters: boolean;
}

export const useExecutivesFilterWithHubUIGroups = (): IFilterWithUIGroupsHook => {
  const executivesFiltersService = useContext(ExecutivesFiltersContext);

  const [loadingExecutivesFilters] = useObservable(executivesFiltersService.loading$, null);

  const [filterWithUIGroups] = useObservable(
    executivesFiltersService.allWithHubUI$.pipe(map(extractFiltersIntoGroups)),
    [],
  );

  return {
    filterWithUIGroups,
    loadingExecutivesFilters,
  };
};
