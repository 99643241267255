export const classNames = (styles: any, object?: any) => {
  const componentStyles = styles.component;
  const appendedStyles =
    object &&
    Object.keys(object)
      .filter((key) => object[key])
      .map((key) => (key === "className" ? object[key] : styles[key]))
      .reduce(
        (previous, current) => (previous ? `${previous} ${current}` : current),
        undefined,
      );

  let classNames;

  if (componentStyles) classNames = componentStyles;

  if (appendedStyles) {
    if (classNames) {
      classNames = classNames + " ";
    }
    classNames = classNames + appendedStyles;
  }

  return classNames;
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
