import { ICorporateTag } from "../../entities/corporate-tags/state/corporate-tags.model";
import { ITag, IEntityTag } from "../../entities/custom-tags/state/custom-tag.model";
import ApiService from "./api.service";

interface ISaveCustomTagQuery {
  name: string;
  color_code?: string;
  type?: string;
}

class TagsApiService extends ApiService {
  public saveCustomTag(query: ISaveCustomTagQuery): Promise<ITag> {
    return this.post(`/tags`, query);
  }

  public getCustomTag(tagId: number): Promise<ITag> {
    return this.get(`/tags/${tagId}`);
  }

  public updateCustomTag(tagId: number, name: string): Promise<ITag> {
    return this.put(`/tags/${tagId}`, { name });
  }

  public deleteCustomTag(tagId: number): Promise<any> {
    return this.delete(`/tags/${tagId}`);
  }

  public getAllTagsForCurrentUser(): Promise<ITag[]> {
    return this.get(`/me/tags`);
  }

  public getPeopleTagsForCurrentUser(): Promise<ITag[]> {
    return this.get(`/me/people-tags`);
  }

  public saveCompanyTag(elasticCompanyId: number, tagId: number): Promise<IEntityTag> {
    return this.post(`/companies-tags/${elasticCompanyId}/${tagId}`, {});
  }

  public getCompanyTags(elasticCompanyId: number): Promise<ICorporateTag[]> {
    return this.get(`/companies-tags/${elasticCompanyId}`);
  }

  public deleteCompanyTag(companyTagId: number): Promise<any> {
    return this.delete(`/companies-tags/${companyTagId}`);
  }

  public savePersonTag(elasticPersonId: number, tagId: number): Promise<IEntityTag> {
    return this.post(`/people-tags/${elasticPersonId}/${tagId}`, {});
  }

  public getPeopleTags(elasticPersonId: number): Promise<ICorporateTag[]> {
    return this.get(`/people-tags/${elasticPersonId}`);
  }

  public deletePersonTag(elasticPersonId: number): Promise<any> {
    return this.delete(`/people-tags/${elasticPersonId}`);
  }
}

export const tagsApiService = new TagsApiService();
