import { arrayAdd } from "@datorama/akita";
import { Observable } from "rxjs";
import { FiltersApiService } from "../../../api/services/filters-api.service";
import { RESULTS_PER_PAGE } from "../../../constants/filters";
import { InterestsFiltersQuery } from "./interests-filters.query";
import { InterestsFiltersStore } from "./interests-filters.store";
import { ICorporatesContext } from "../../corporates/state/corporates.facade";
import { ICorporate } from "../../corporates/models/corporate.model";
import { IQueryRanges } from "../../../shared/interfaces/query-ranges.interface";
import { IObjectWithArrayOfStringsProps } from "../../../helpers/array.helper";
import { IExecutivesContext } from "../../executives/state/executives.facade";
import { ISessionFacade } from "../../../state/akita/session/session.facade";
import { IExecutive } from "../../executives/state/executive.model";

export interface IInterestsFiltersContext {
  loading$: Observable<boolean>;
  searching$: Observable<boolean>;
  searchResultTotalCount$: Observable<number>;
  searchResultIds$: Observable<string[]>;

  getLastSearchTerm(): string;

  getCorporates(corporateIds?: string[]): ICorporate[];

  getExecutives(executiveIds?: string[]): IExecutive[];

  search(query: string): Promise<void>;

  searchNextPage(): Promise<void>;

  getResultCount(): number;

  getResults(): ICorporate[] | IExecutive[];
}

export class InterestsFiltersFacade implements IInterestsFiltersContext {
  readonly loading$ = this._query.selectLoading();
  readonly searching$ = this._query.ui.select("searching");
  readonly searchResultTotalCount$ = this._query.ui.select("searchResultTotalCount");
  readonly searchResultIds$ = this._query.ui.select("searchResultIds");

  constructor(
    private _store: InterestsFiltersStore,
    private _query: InterestsFiltersQuery,
    private _filtersApiService: FiltersApiService,
    private _corporatesService: ICorporatesContext,
    private _executivesService: IExecutivesContext,
    private _sessionService: ISessionFacade,
  ) {}

  getResultCount(): number {
    const { searchResultTotalCount } = this._query.ui.getValue();

    return searchResultTotalCount;
  }

  getResults(): ICorporate[] | IExecutive[] {
    const { searchResultIds } = this._query.ui.getValue();

    if (this._sessionService.isB2CUser()) {
      return this.getExecutives(searchResultIds);
    } else {
      return this.getCorporates(searchResultIds);
    }
  }

  getLastSearchTerm(): string {
    const { lastSearchTerm } = this._query.ui.getValue();

    return lastSearchTerm ?? "";
  }

  getCorporates(corporateIds?: string[]): ICorporate[] {
    if (!corporateIds) {
      const { searchResultIds } = this._query.ui.getValue();
      corporateIds = searchResultIds;
    }

    return this._corporatesService.getCorporatesFromIds(corporateIds);
  }

  getExecutives(executiveIds?: string[]): IExecutive[] {
    if (!executiveIds) {
      const { searchResultIds } = this._query.ui.getValue();
      executiveIds = searchResultIds;
    }

    return this._executivesService.getExecutivesFromIds(executiveIds);
  }

  async search(query: string) {
    this._store.ui.update({
      searching: true,
      lastSearchTerm: query,
    });

    try {
      const page = 1;

      if (this._sessionService.isB2CUser()) {
        const { executives, searchResultTotalCount } = await this._getExecutivesSearchResults(
          query,
          page,
        );

        this._executivesService.addExecutives(executives);

        this._store.ui.update({
          lastSearchTerm: query,
          nextSearchPage: page + 1,
          searchResultTotalCount: searchResultTotalCount,
          searchResultIds: executives.map(({ id }) => id),
        });
      } else {
        const { corporates, searchResultTotalCount } = await this._getCorporatesSearchResults(
          query,
          page,
        );

        this._corporatesService.addCorporates(corporates);

        this._store.ui.update({
          lastSearchTerm: query,
          nextSearchPage: page + 1,
          searchResultTotalCount: searchResultTotalCount,
          searchResultIds: corporates.map(({ id }) => id),
        });
      }
    } catch (e) {
      console.warn("Error while performing search for interests: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  async searchNextPage() {
    try {
      this._store.ui.update({ searching: true });

      const { nextSearchPage: page, searchResultIds } = this._query.ui.getValue();

      const query = this.getLastSearchTerm();

      if (this._sessionService.isB2CUser()) {
        const { executives, searchResultTotalCount } = await this._getExecutivesSearchResults(
          query,
          page,
        );

        this._executivesService.addExecutives(executives);

        this._store.ui.update({
          nextSearchPage: page + 1,
          searchResultTotalCount: searchResultTotalCount,
          searchResultIds: arrayAdd(
            searchResultIds,
            executives.map(({ id }) => id),
          ),
        });
      } else {
        const { corporates, searchResultTotalCount } = await this._getCorporatesSearchResults(
          query,
          page,
        );

        this._corporatesService.addCorporates(corporates);

        this._store.ui.update({
          nextSearchPage: page + 1,
          searchResultTotalCount: searchResultTotalCount,
          searchResultIds: arrayAdd(
            searchResultIds,
            corporates.map(({ id }) => id),
          ),
        });
      }
    } catch (e) {
      console.warn("Error while performing next page search for interests: ", e);
    } finally {
      this._store.ui.update({ searching: false });
    }
  }

  private async _getCorporatesSearchResults(query: string, page: number) {
    const filters = {
      tags: ["Client"],
    } as IObjectWithArrayOfStringsProps;
    const ranges = [] as IQueryRanges[];

    const results = await this._filtersApiService.paginateCorporates({
      page,
      limit: RESULTS_PER_PAGE,
      query,
      filters,
      ranges,
    });

    return {
      corporates: results.corporates,
      searchResultTotalCount: results.total,
    };
  }

  private async _getExecutivesSearchResults(query: string, page: number) {
    const filters = {
      tags: ["Client"],
    } as IObjectWithArrayOfStringsProps;
    const ranges = [] as IQueryRanges[];

    const results = await this._filtersApiService.paginateExecutives({
      page,
      limit: RESULTS_PER_PAGE,
      query,
      filters,
      ranges,
    });

    return {
      executives: results.executives,
      searchResultTotalCount: results.total,
    };
  }
}
