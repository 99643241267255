import React, { useEffect, useRef, useState } from "react";
import { Spacing } from "../../spacing/component";
import styles from "./styles.module.css";
import { ReactComponent as ChevronRightBlueIcon } from "../../../assets/ChevronRightBlue.svg";
import useAppColors from "../../../hooks/app-colors.hook";

interface IProps {
  children?: React.ReactNode;
  customStyles?: any;
  showArrows?: boolean;
}

const HorizontallyScrollable = ({ children, customStyles, showArrows }: IProps) => {
  const { colors } = useAppColors();
  const ref = useRef<HTMLHeadingElement>();
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", () => {
        setScrollLeft(ref.current.scrollLeft);
      });
    }
  }, [ref]);

  const handleScrollRight = () => (ref.current.scrollLeft -= 70);
  const handleScrollLeft = () => (ref.current.scrollLeft += 70);

  return (
    <>
      {showArrows && scrollLeft > 0 && (
        <>
          <Spacing pl="20" />
          <ChevronRightBlueIcon
            color={colors.secondaryDarkColor}
            style={{
              transform: "rotate(180deg)",
              cursor: "pointer",
            }}
            onClick={handleScrollRight}
          />
          <Spacing pl="20" />
        </>
      )}
      <div
        className={styles.horizontallyScrollable}
        style={{ ...customStyles }}
        {...{ children }}
        ref={ref}
      />
      {showArrows && (
        <>
          <Spacing pl="20" />
          <ChevronRightBlueIcon
            color={colors.secondaryDarkColor}
            style={{ cursor: "pointer" }}
            onClick={handleScrollLeft}
          />
        </>
      )}
    </>
  );
};

export default HorizontallyScrollable;
