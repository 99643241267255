import React from "react";
import { useTranslation } from "react-i18next";
import Divider from "../../../../components/dividers/Divider/Divider";
import RText from "../../../../components/fonts/RText/RText";
import CorporatesSearchResultsListHeader from "../../../../components/lists/SearchResultsList/CorporatesSearchResultsListHeader/CorporatesSearchResultsListHeader";
import CorporatesSearchResultsListItem, {
  mapCorporatesSearchResponseIntoResultItems,
} from "../../../../components/lists/SearchResultsList/CorporatesSearchResultsListItem/CorporatesSearchResultsListItem";
import Spinner from "../../../../components/other/Spinner/Spinner";
import { Spacing } from "../../../../components/spacing/component";
import { ICorporate } from "../../../../entities/corporates/models/corporate.model";
import { useStartupsSearchResponse } from "../../../../entities/startups-filters/hooks/startups-search-response.hook";

const StartupsLimitedSearchResults = () => {
  const { t } = useTranslation();
  const { searchResults, loading } = useStartupsSearchResponse();

  if (!searchResults?.length) {
    if (loading) {
      return <Spinner />;
    }

    return (
      <Spacing pt="12">
        <RText fontSize="28">{t("noSearchResults")}</RText>
      </Spacing>
    );
  }

  const limitSearchResponseList = (): ICorporate[] => {
    if (searchResults.length > 5) {
      let arrayCopy = [...searchResults];
      return arrayCopy.slice(0, 5);
    }

    return searchResults;
  };

  return (
    <Spacing pt="12">
      <CorporatesSearchResultsListHeader
        column1={t("company")}
        column2={t("productAffinity")}
        column3={t("industry")}
      />

      <Spacing pt="12" />

      <Divider />

      {mapCorporatesSearchResponseIntoResultItems(limitSearchResponseList()).map((item) => {
        return (
          <React.Fragment key={item.id}>
            <CorporatesSearchResultsListItem key={item.id} item={item} />
            <Divider />
          </React.Fragment>
        );
      })}
    </Spacing>
  );
};

export default StartupsLimitedSearchResults;
