import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Flex } from "../../../../../../../components/containers/Flex/Flex";
import Divider from "../../../../../../../components/dividers/Divider/Divider";
import RText from "../../../../../../../components/fonts/RText/RText";
import { Spacing } from "../../../../../../../components/spacing/component";
import { ResolutionContext } from "../../../../../../../state/context/ResolutionContext/ResolutionContextProvider";

interface IProps {
  industrySectorCodes: string[];
  fetchIndustrySectorCodeData: (sectorCode: string) => void;
}

export default function Navigation({
  industrySectorCodes,
  fetchIndustrySectorCodeData,
}: IProps) {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const firstSectorCode = industrySectorCodes[0];
  const [selected, setSelected] = useState(firstSectorCode);

  useEffect(() => {
    const selectedSectorCode = industrySectorCodes.find(
      (sectorCode) => sectorCode === selected,
    );

    selectedSectorCode && fetchIndustrySectorCodeData(selectedSectorCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return industrySectorCodes.length > 1 ? (
    <>
      <Flex>
        {industrySectorCodes.map((sectorCode: string, index: any) => {
          return (
            <Flex key={index} flex column>
              <div className={styles.itemContainer} onClick={() => setSelected(sectorCode)}>
                <RText
                  fontSize={isSmallerScreen ? "13" : "20"}
                  className={`
                                        ${styles.sectorCodeTitle}
                                        ${
                                          selected === sectorCode ? styles.selectedNavItem : ""
                                        }
                                    `}
                >
                  {sectorCode}
                </RText>
                <Spacing pt="12" />
                {selected === sectorCode && (
                  <Divider black className={styles.selectedDividerLine} />
                )}
              </div>
            </Flex>
          );
        })}
      </Flex>
      <Divider />
    </>
  ) : (
    <RText fontSize={isSmallerScreen ? "13" : "20"}>{firstSectorCode}</RText>
  );
}
