import React, { useContext, useEffect } from "react";
import styles from "./styles.module.css";
import { IExecutive } from "../../../../entities/executives/state/executive.model";
import { buildCompanyAddress, formatZipCode } from "../../../../helpers/map.helper";
import { Flex } from "../../../containers/Flex/Flex";
import RText from "../../../fonts/RText/RText";
import { Spacing } from "../../../spacing/component";
import { ITag } from "../../../../entities/custom-tags/state/custom-tag.model";
import ExecutivesTags from "../../../../pages/home/ExecutivesTags/ExecutivesTags";
import RLink from "../../../link/RLink";
import { ResolutionContext } from "../../../../state/context/ResolutionContext/ResolutionContextProvider";
import { useSession } from "../../../../hooks/session.hook";
import StarRating from "../../../other/StarRating/StarRating";
import { useWindowSize } from "../../../../hooks/window.hook";
import { selectAffinityIconSize } from "../../../../helpers/icons.helper";
import { getExecutiveActiveCompany } from "../../../../helpers/dossier.helper";
import { IPeopleManagement } from "../../../../entities/executives/interfaces/people-management.interface";
import { IShareholder } from "../../../../entities/executives/interfaces/shareholder.interface";
import { tagEntityFromStringTag } from "../../../../utils/tags.util";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../../../hooks/local-storage.hook";

export enum SEARCH_RESULT_TYPE {
  CORPORATE,
  EXECUTIVE,
}

export interface IResultItem {
  id: string;
  title: string;
  textLine1?: string;
  textLine2?: string;
  affinity: number;
  type: string;
  typeLine2?: string;
  searchResultType: SEARCH_RESULT_TYPE;
  promoterScore?: number;
  tags: ITag[];
}

export const buildCompanyCityAndZipCodeString = (city: string, zipCode?: number) => {
  if (!city || city === "null") return;

  if (!zipCode) return city;
  else return `${formatZipCode(zipCode)} ${city}`;
};

const buildExecutivesCityString = (city: string) => {
  if (!city || city === "null") return "";
  else return city;
};

const extractCompanyAddressFromManagement = (
  activeCompany: IPeopleManagement | IShareholder | null,
) => {
  if (!activeCompany) {
    return "";
  }

  return buildCompanyAddress(activeCompany.street, activeCompany.zip_code, activeCompany.city);
};

export const mapExecutivesSearchResponseIntoResultItems = (
  executives: IExecutive[],
): IResultItem[] => {
  return executives.map((executive) => {
    const activeCompany = getExecutiveActiveCompany(
      executive.management,
      executive.main_company_id,
      executive.shareholder,
    );

    return {
      id: executive.id,
      title: executive.full_name,
      textLine1: "",
      textLine2: buildExecutivesCityString(executive.city),
      affinity: executive.wealth_score,
      promoterScore: executive.promoter_score,
      type: activeCompany?.company_name ?? "",
      typeLine2: extractCompanyAddressFromManagement(activeCompany),
      searchResultType: SEARCH_RESULT_TYPE.EXECUTIVE,
      tags: executive.tags.map((t) =>
        typeof t === "string" ? tagEntityFromStringTag(t).tag : t.tag,
      ),
    };
  });
};

interface IProps {
  item: IResultItem;
}

const ExecutivesSearchResultsListItem = ({ item }: IProps) => {
  const { isSmallerScreen } = useContext(ResolutionContext);
  const { isB2CUser } = useSession();
  const windowSize = useWindowSize();
  const history = useHistory();
  const [scrollY, setScrollY] = useLocalStorage("scrollY", "");

  const starRatingIconSize = selectAffinityIconSize(windowSize.width);

  useEffect(() => {
    if (scrollY) {
      window.scroll(0, Number(scrollY));

      setScrollY("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <div className={`${styles.component} ${isSmallerScreen && styles.component__Responsive}`}>
      <Spacing p="20">
        <Flex>
          <RLink
            path={{
              pathname: `/home/search/executive-dossier/${item.id}`,
              state: { fromSearchResults: true },
            }}
            className={styles.company}
            onClick={() => setScrollY(document.documentElement.scrollTop)}
          >
            <Flex column justifyContentCenter>
              <Flex alignItemsCenter>
                <RText fontSize={isSmallerScreen ? "18" : "24"}>{item.title}</RText>
              </Flex>
              <Spacing pb="12" />
              <RText fontSize={isSmallerScreen ? "11" : "16"}>{item.textLine1 || ""}</RText>
              <Spacing pb="4" />
              <RText fontSize={isSmallerScreen ? "11" : "16"}>{item.textLine2 || ""}</RText>
            </Flex>
          </RLink>

          <div className={`${styles.tags} ${isSmallerScreen && styles.tags__Responsive}`}>
            <ExecutivesTags executiveId={item.id} />
          </div>

          {!isB2CUser ? (
            <div className={styles.value}>
              <Flex className={styles.affinityContainer}>
                <RText fontSize={isSmallerScreen ? "18" : "28"}>
                  {item.promoterScore ? item.promoterScore.toString() + "%" : ""}
                </RText>
              </Flex>
            </div>
          ) : (
            <div
              className={`${styles.value} ${isSmallerScreen && styles.valueB2C__Responsive}`}
            >
              <Flex
                justifyContentCenter
                alignItemsCenter
                className={styles.affinityContainerB2C}
              >
                <StarRating
                  affinity={item.affinity}
                  spacing={starRatingIconSize.spacing}
                  iconHeight={starRatingIconSize.height}
                  emptyIconSize={isSmallerScreen ? "8" : "12"}
                />
              </Flex>
            </div>
          )}

          <div className={styles.industry}>
            <RText fontSize={isSmallerScreen ? "13" : "16"}>{item.type}</RText>
            <Spacing pb="8" />
            <RText fontSize={isSmallerScreen ? "9" : "12"}>{item.typeLine2 || ""}</RText>
          </div>
        </Flex>
      </Spacing>
    </div>
  );
};

export default ExecutivesSearchResultsListItem;
